import React, { useState, useEffect } from 'react'
import { Accordion, AccordionItem, Button, Card, CardBody, CardHeader, Col, Collapse, Table, Form, Label, Input, Container, Row, Modal, ModalBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import Flatpickr from "react-flatpickr";
import TableRows from "./TableRow";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import Loader from "../../../Components/Common/Loader";

export const Storage = () => {
  const [rowsData, setRowsData] = useState([]);
  const [data, setData] = useState([]);
  const [storage_types, setStorageTypes] = useState([]);
  const [storage_warehouses, setStorageWareHouses] = useState([]);
  const [storage_reservation, setStorageReservation] = useState([]);
  const [storage_jobunits, setStorageJobUnits] = useState([]);
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [taxs, setTaxes] = useState([]);
  const [showdiscount, SetDiscountDisplay] = useState(false);
  const [showEndDate, setShowEndDate] = useState(false);
  const [discounttype, setDiscountType] = useState('');
  const [editstorageid, setEditStorageId] = useState('');
  const [showPicker, setShowPicker] = useState(false);
  const [storagetype, setStorageType] = useState();
  const [storagelocation, setStorageLocation] = useState();
  const [from_date, setFromDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [to_date, setToDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [showreserves, setShowReserveStorage] = useState(false);
  const [storagereserves, setStorageReserveData] = useState(false);
  const [storageunit, setStorageUnit] = useState(false);
  const [disableEndDate, setDisableEndDate] = useState(true);
  const [AddinvoiceItem, setInvoiceAddNew] = useState(false);
  const [invoicepid, setProductId] = useState("");
  const [invoicedesc, setInvoiceDesc] = useState("");
  const [invoiceprice, setInvoicePrice] = useState("");
  const [Addnewpayment, setAddPayment] = useState(false);
  const [invoicename, setInvoiceName] = useState("");
  const [producttype, setProductType] = useState("");
  const [invoicetax, setInvoiceTax] = useState("");
  const [invoiceamt, setInvoiceAmount] = useState("");
  const [invoiceqty, setInvoiceQuantity] = useState("");
  const [selectedinvoice, setSelectedInvoiceData] = useState([]);
  const [selectedpayment, setSelectedPaymentData] = useState([]);
  const [modal, setOpenModal] = useState(false);
  const [paymentmodal, setPaymentModal] = useState(false);
  const [expandedinvoiceId, setExpandedInvoiceId] = useState('');
  const [expandedpaymentId, setExpandedPaymentId] = useState('');
  const [paymentmode, setPaymentMode] = useState('');
  const [paymentdate, setPaymentDate] = useState('');
  const [paymentdesc, setPaymentDesc] = useState('');
  const [paymentamount, setPaymentAmount] = useState('');
  const [paymentItems, setPaymentItems] = useState('');
  const [isLoader, setLoader] = useState(true);
  const [org_date, setOrg_Date] = useState([]);


  const params = useParams();
  const job_id = params.id;
  var total_payments = 0;
  var total_excl_tax = 0;
  var total_incl_tax = 0;
  var rate_percent = 0;
  var total_tax = 0;
  var total_after_discount = 0;

  const [lead_id, setLeadId] = useState()


  useEffect(() => {
    getData();
  }, [])

  function getData() {
    var postData = {
      "job_id": job_id,
    }
    // setLeadId(res.job.customer_id)
    axios.post(`api/list-jobs/${job_id}`).then((res) => {
      console.log(res.crmlead.id)
      setLeadId(res.crmlead.id)
    })
    axios.post('api/storagetabcontent', postData).then((res) => {
      console.log(res, "res1")
      setData(res);
      setOrg_Date(res.organisation_settings);

      setInclTax(res.totalAmount)
      setDiscountAmt(res?.invoice?.discount === undefined ? 0 : res.invoice.discount)
      setStorageTypes(res.storage_type_list);
      setStorageWareHouses(res.storage_warehouses);
      setStorageReservation(res.storage_reservation);
      setStorageJobUnits(res.storage_job_units);
      setInvoiceItems(res.invoice_items);
      setPaymentItems(res.payment_items);
      setTaxes(res.taxs);
      setStorageType(res.storage_type_list[0].id);
      setStorageLocation(res.storage_warehouses[0].id)
      if (res.storage_reservation.length > 0 && res.storage_reservation[0]?.to_date == "0000-00-00") {
        setDisableEndDate(false);
        setShowEndDate(false);
      }
      if (data) {
        setTimeout(function () {
          setLoader(false);
        }, 2000);
      }
    })
  }

  const addTableRows = () => {
    const rowsInput = {
    }
    setRowsData([...rowsData, rowsInput])
    console.log(rowsData);
  }

  const addInvoiceRow = () => {
    if (AddinvoiceItem == true) {
      setInvoiceName();
      setInvoiceDesc();
      setInvoicePrice();
      setInvoiceAmount();
      setInvoiceQuantity();
      setInvoiceAddNew(false);
    } else {
      setInvoiceAddNew(true);
    }
  }

  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  }

  const SelectDiscount = (val) => {
    if (val == "cancel") {
      setDiscountType("");
    } else if (val == "save") {
      console.log("continue");
    }
    else {
      setDiscountType(val);
    }
  }

  const handleToggle = (e) => {
    setShowPicker(e.target.checked);
  };

  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);
  }

  const EditStorage = (val) => {
    setEditStorageId(val);
  }

  const DeleteStorage = (val) => {
    axios.get("api/delete-storage-job/" + val).then((res) => {
      if (res.status == "success") {
        toast.success(res.message);
        getData();
      }
    })
  }

  const DeleteUnits = (data) => {
    var postData = {
      "id": data
    }
    axios.post("/api/ajaxDestroyStorageReservation", postData).then((res) => {
      if (res.error == 0) {
        toast.success(res.message);
        getData();
      } else {
        toast.error("Something went wrong");
      }
    })
  }

  const UpdateStorage = (val) => {
    val.if_end_date = showEndDate.toString();
    if (showPicker) {
      console.log(showPicker);
      val.if_end_date = "true";
      val.to_date = moment(new Date()).format("YYYY-MM-DD");
      console.log(val.to_date);
    }
    var postData = {
      "id": val.id,
      "datepicker_to": val.to_date,
      "datepicker_from": val.from_date,
      "volume_update": val.storage_volume,
      "update_allocation": val.allocation_status,
      "if_end_date": val.if_end_date,
      "unit_id": val.unit_id
    }
    axios.post("/api/updateReserves", postData).then((res) => {
      if (res.error == 0) {
        toast.success(res.message);
        getData();
      }
    })
  }

  const findStorage = () => {
    var postData = {
      "storage_unit_id": storagetype,
      "storage_location": storagelocation,
      "from_date": from_date,
      "to_date": to_date
    }
    axios.post("api/find-available-storage-units", postData).then((res) => {
      if (res.data.length > 0) {
        toast.success(res.message);
        setShowReserveStorage(true)
        setStorageReserveData(res.data);
      } else {
        toast.warning(res.message);
        setShowReserveStorage(false)
        setStorageReserveData([]);
      }
    })
  }

  const SaveReservation = () => {
    var postData = {
      "lead_id": 0,
      "job_id": job_id,
      "unit_id": storageunit || storagereserves[0].id,
      "from_date": from_date,
      "to_date": to_date,
      "allocation_status": "Active",
      "enable_date": showEndDate,
    }
    console.log(postData);
    axios.post("/api/ajaxSaveStorageReservation", postData).then((res) => {
      if (res.error == 0) {
        toast.success(res.message);
        getData();
        setShowReserveStorage(false)
        setStorageReserveData();
      } else {
        toast.error("Something went wrong");
      }
    })
  }

  const [discountamt, setDiscountAmt] = useState()
  const [discountVal, setDiscountVal] = useState()
  const [discountAmtString, setdiscountAmtString] = useState()
  // const [discount,setDiscount]=useState("")
  // const [depositamt, setDepositAmt] = useState(data?.quote?.deposit_required);

  const [afterDiscountVal, setAfterDiscountVal] = useState([])
  const [inclTax, setInclTax] = useState()


  const submitDiscount = () => {
    if (total_excl_tax === 0) {
      toast.error("Total must be greater than 0", { theme: "light" });
    }
    else {
      SetDiscountDisplay(true)
      if (discounttype === "Percent") {
        setAfterDiscountVal((parseFloat(total_excl_tax) - (parseInt(discountVal) / 100 * parseFloat(total_excl_tax))).toFixed(2))
      }
      else if (discounttype === "Fixed") {
        setAfterDiscountVal((total_excl_tax - parseInt(discountVal)).toFixed(2))
      }
      console.log(discountVal)
      setDiscountAmt(discountVal)
      setdiscountAmtString(discounttype === "Percent" ? discountVal + "%" : "$" + discountVal)
      setDiscountType('')
      console.log(discountamt)
      const form = {
        invoice_id: data?.invoice?.id,
        lead_id,
        // quote_id: quoteid,
        discount: parseInt(discountamt === null || undefined ? 0 : discountVal),
        discount_type: discounttype,
        // crm_opportunity_id: oppid,
        sys_job_type: 'Moving'
      }
      console.log(form, "type")
      axios.post("api/ajaxSaveInvoiceDiscount", form).then((res) => {
        if (res.error == 0) {
          toast.success(res.message);
          console.log(res)
          setInclTax(res.balance)
          setDiscountType('');

        }
        else {
          toast.error("Something went wrong");
        }
      })
    }
  }

  const SaveInvoice = () => {
    var postData = {
      "job_id": job_id,
      "invoice_id": data?.invoice?.id,
      "product_id": invoicepid,
      "name": invoicename,
      "description": invoicedesc,
      "tax_id": invoicetax,
      "unit_price": invoiceprice,
      "quantity": invoiceqty,
      "type": producttype,
      "amount": invoiceamt,
      "sys_job_type": "Moving_Storage"
    }
    console.log(postData)
    if (postData.name === "" || postData.name === undefined) {
      toast.error("Enter the Item Name", { theme: "light" });
    }
    else if (postData.unit_price === "" || postData.unit_price === undefined) {
      toast.error("Enter the Unit Price", { theme: "light" });
    }
    else if (postData.quantity === "" || postData.quantity === undefined) {
      toast.error("Enter the Quantity", { theme: "light" });
    }

    else {
      axios.post("api/ajaxSaveInvoice", postData).then((res) => {
        if (res.error == 0) {
          toast.success(res.message);
          getData();
          addInvoiceRow();
        }
        else {
          toast.error("Something went wrong");
        }
      })
    }
  }
  console.log(data);
  const SavePayment = () => {
    setLoader(true);
    if (!data?.invoice) {
      toast.success("Add Item First");
      return;
    }
    var postData = {
      "job_id": job_id,
      "invoice_id": data?.invoice?.id,
      "gateway": paymentmode,
      "description": paymentdesc,
      "amount": paymentamount,
      "paid_on": paymentdate
      // "sys_job_type": "Moving_Storage"
    }
    axios.post("api/ajaxSavePayment", postData).then((res) => {
      if (res.error == 0) {
        toast.success(res.message);
        getData();
        t_addnewpayment();
      }
      else {
        toast.error("Something went wrong");
      }
    })
    .catch((error) => {
      console.error(error);
      toast.error("An error occurred while fetching data.", {
        theme: "error",
      });
    })
    .finally(() => {
      setLoader(false);
    });
  }

  const UpdatePayment = (val) => {
    console.log(val);
    setLoader(true);
    var postData = {
      "id": val.id,
      "job_id": job_id,
      "invoice_id": data?.invoice.id,
      "gateway": val.gateway,
      "description": val.remarks,
      "amount": val.amount,
      "paid_on": val.paid_on,
      // "paid_on": moment(val.paid_on).format("YYYY-MM-DD"),
      // "sys_job_type": "Moving_Storage"
    }
    axios.post("api/ajaxUpdatePayment", postData).then((res) => {
      if (res.error == 0) {
        toast.success(res.message);
        getData();
        t_editPayment();
      }
      else {
        toast.error("Something went wrong");
      }
    })
    .catch((error) => {
      console.error(error);
      toast.error("An error occurred while fetching data.", {
        theme: "error",
      });
    })
    .finally(() => {
      setLoader(false);
    });
  }

  const deleteInvoice = () => {
    console.log(selectedinvoice);
    var postData = {
      "job_id": job_id,
      "invoice_id": selectedinvoice.invoice_id,
      "id": selectedinvoice.id
    }
    axios.post("api/ajaxDestroyInvoiceItem", postData).then((res) => {
      if (res.error == 0) {
        toast.success(res.message);
        getData();
        t_deleteInvoice();
      }
      else {
        toast.error("Something went wrong");
      }
    })
  }

  const deletePayment = () => {
    console.log(selectedpayment);
    var postData = {
      "job_id": job_id,
      "invoice_id": selectedpayment.invoice_id,
      "id": selectedpayment.id,
      "sys_job_type": "Moving_Storage"
    }
    axios.post("api/ajaxDestroyPaymentItem", postData).then((res) => {
      if (res.error == 0) {
        toast.success(res.message);
        getData();
        t_deletePayment();
      }
      else {
        toast.error("Something went wrong");
      }
    })
  }

  const t_deleteInvoice = (val = "") => {
    if (val != "") {
      setSelectedInvoiceData(val);
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  }

  const t_deletePayment = (val = "") => {
    if (val != "") {
      setSelectedPaymentData(val);
      setPaymentModal(true);
    } else {
      setPaymentModal(false);
    }
  }

  const t_editinvoice = (val) => {
    //getData();
    setExpandedInvoiceId(val);
  }

  const t_editPayment = (val) => {
    console.log(val);
    setExpandedPaymentId(val);
  }

  const t_addnewpayment = (val) => {
    //getData();
    if (Addnewpayment == true) {
      setPaymentAmount();
      setPaymentDate();
      setPaymentDesc();
      setPaymentMode();
      setAddPayment(false);
    } else {
      setAddPayment(true);
    }
  }

  const EditInvoice = (val) => {
    var postData = {
      "id": val.id,
      "job_id": job_id,
      "invoice_id": val.invoice_id,
      "product_id": val.product_id,
      "name": val.item_name,
      "description": val.item_summary,
      "tax_id": val.tax_id,
      "unit_price": val.unit_price,
      "quantity": val.quantity,
      "type": val.type,
      "amount": val.amount,
    }
    axios.post("api/ajaxUpdateInvoice", postData).then((res) => {
      if (res.error == 0) {
        toast.success(res.message);
        getData();
        t_editinvoice();
      }
      else {
        toast.error("Something went wrong");
      }
    })
  }
  console.log(invoiceItems, "invoiceItems");
  return (
    <div>
      {isLoader && <><div
        style={{
          position: 'absolute',
          // top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(255, 255, 255, 0.8)',
          // display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 9999,
        }}
      >
        <Loader />
      </div></>}
      <Card>
        <CardHeader>
          <h5>Reservation</h5>
        </CardHeader>
        <CardBody>
          {disableEndDate && <div className="form-check mb-2">
            <Input className="form-check-input" type="checkbox" id="formCheck1" onClick={() => {
              if (showEndDate) {
                setShowEndDate(false);
              } else {
                setShowEndDate(true);
              }
            }} />
            <Label className="form-check-label" for="formCheck1">
              <b>Enable End Date</b>
            </Label>
          </div>}
          <Row className='hstack gap-1 mt-3'>
            <Col lg={3} className='mt-3'>
              <Label><h6>Storage Type</h6></Label>
              <select className="form-select mb-3" aria-label="Default select example" onChange={(e) => {
                setStorageType(e.target.value);
              }} value={storagetype}>
                {storage_types?.map((res) => {
                  return (<option key={res.id} value={res.id}>{res.name}</option>)
                })}
              </select>
            </Col>
            <Col lg={3} className='mt-3'>
              <Label><h6>Location</h6></Label>
              <select className="form-select mb-3" aria-label="Default select example" onChange={(e) => {
                setStorageLocation(e.target.value);
              }} value={storagelocation}>
                {storage_warehouses?.map((res) => {
                  return (<option key={res.id} value={res.id}>{res.warehouse_name}</option>)
                })}
              </select>
            </Col>
            <Col lg={2}>
              <Label><h6>Start Date</h6></Label>
              <Flatpickr
                className="form-control"
                options={{
                  dateFormat: "Y-m-d",
                  defaultDate: [moment(new Date()).format("YYYY-MM-DD")]
                }}
                onChange={(selectedDates) => {
                  setFromDate(moment(selectedDates[0]).format('YYYY-MM-DD'));
                }}
                value={from_date}
              />
            </Col>
            {showEndDate && <Col lg={2}>
              <Label><h6>End Date</h6></Label>
              <Flatpickr
                className="form-control"
                options={{
                  dateFormat: "Y-m-d",
                  defaultDate: [moment(new Date()).format("YYYY-MM-DD")]
                }}
                onChange={(selectedDates) => {
                  setToDate(moment(selectedDates[0]).format('YYYY-MM-DD'));
                }}
                value={from_date}
              />
            </Col>}
          </Row>
          <Row className='mt-2'>
            <Col lg={9} className='text-muted'>
              <p > *Use the search when you want to add a new reservation <br />Enter the parameters for the search and click on the Search</p>
            </Col>
            <Col lg={3} className='hstack gap-3'>
              <Button className='btn btn-success' onClick={() => { findStorage() }}>Search</Button>
              <Button className='btn btn-dark'>Reset</Button>

            </Col>
          </Row>
          <Table className='mt-5 table-bordered' >
            <thead className='bg-soft-purple'>
              <tr>
                <th width="20%">Storage Job Number</th>
                <th width="18%">Move In</th>
                <th width="18%">Move Out</th>
                <th>Status</th>
                <th>Volume</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {storage_reservation.length > 0 && storage_reservation.map((res) => {
                return (
                  <>
                    <tr key={res.id}>
                      <td>{console.log(res)} <Link to={"/storage/view-storage-job/" + res.id} className="fw-medium link-primary"><span className="badge badge-soft-info fs-12">{res.storage_job_number}</span></Link></td>
                      <td>{res.from_date}</td>
                      <td>{res.to_date}</td>
                      <td>{res.allocation_status}</td>
                      <td>{res.storage_volume} cbm</td>
                      <td className='hstack gap-2'>
                        <button className="btn btn-sm btn-soft-info edit-list" onClick={() => EditStorage(res.id)}>
                          <i className="bx bxs-pencil fs-12 pt-1"></i>
                        </button>
                        <button className="btn btn-sm btn-soft-danger edit-list" onClick={() => DeleteStorage(res.storage_job_number)}>
                          <i className="bx bxs-trash fs-12 pt-1"></i>
                        </button>
                      </td>
                    </tr>
                    {editstorageid == res.id && <tr key={res.id} className='edit_row'>
                      <td><Input className='form-control' disabled value={res.storage_job_number} onChange={(e) => {
                        const updatedLegs = [...storage_reservation];
                        const index = updatedLegs.findIndex((task) => task.id === res.id);
                        updatedLegs[index].storage_job_number = e.target.value;
                        setStorageReservation(updatedLegs);
                      }} /></td>
                      <td><Flatpickr
                        className="form-control"
                        options={{
                          dateFormat: "Y-m-d",
                          defaultDate: [res.from_date]
                        }}
                        onChange={(e) => {
                          const updatedLegs = [...storage_reservation];
                          const index = updatedLegs.findIndex((task) => task.id === res.id);
                          updatedLegs[index].from_date = e.target.value;
                          setStorageReservation(updatedLegs);
                        }}
                      /></td>
                      <td>{res.to_date != "0000-00-00" && <Flatpickr
                        className="form-control"
                        options={{
                          dateFormat: "Y-m-d",
                          defaultDate: [res.to_date]
                        }}
                        onChange={(e) => {
                          const updatedLegs = [...storage_reservation];
                          const index = updatedLegs.findIndex((task) => task.id === res.id);
                          updatedLegs[index].to_date = e.target.value;
                          setStorageReservation(updatedLegs);
                        }}
                      />}
                        {res.to_date == "0000-00-00" && <> <Input type='checkbox' onChange={handleToggle} /> <b>Add End Date</b>
                          {showPicker && (<Flatpickr
                            className="form-control mt-2"
                            options={{
                              dateFormat: "Y-m-d",
                              defaultDate: [moment(new Date()).format("YYYY-MM-DD")]
                            }}
                            onChange={(e) => {
                              const updatedLegs = [...storage_reservation];
                              const index = updatedLegs.findIndex((task) => task.id === res.id);
                              updatedLegs[index].to_date = e.target.value;
                              setStorageReservation(updatedLegs);
                            }}
                          />)}</>}
                      </td>
                      <td>
                        <select className='form-control' value={res.allocation_status} onChange={(e) => {
                          const updatedLegs = [...storage_reservation];
                          const index = updatedLegs.findIndex((task) => task.id === res.id);
                          updatedLegs[index].allocation_status = e.target.value;
                          setStorageReservation(updatedLegs);
                        }}>
                          <option value="Reserved">Reserved</option>
                          <option value="Active">Active</option>
                          <option value="Hold">Hold</option>
                          <option value="Closed">Closed</option>
                        </select>
                      </td>
                      <td><Input type='number' step="0.00" value={res.storage_volume} onChange={(e) => {
                        const updatedLegs = [...storage_reservation];
                        const index = updatedLegs.findIndex((task) => task.id === res.id);
                        updatedLegs[index].storage_volume = e.target.value;
                        setStorageReservation(updatedLegs);
                      }} /></td>
                      <td className='hstack gap-2'>
                        <button className="btn btn-sm btn-light edit-list" onClick={() => EditStorage()}>
                          Cancel
                        </button>
                        <button className="btn btn-sm btn-success edit-list" onClick={() => UpdateStorage(res)}>
                          Save
                        </button>
                      </td>
                    </tr>}
                  </>
                )
              })}
              {storage_reservation && storage_reservation.length == 0 &&
                <tr>
                  <td colSpan={6} className='text-center'> No Reservations found!</td>
                </tr>}
            </tbody>
          </Table>
          <Table className='mt-5 table-bordered' >
            <thead className='bg-soft-purple'>
              <tr>
                <th>Storage Unit</th>
                <th>Location</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {storage_jobunits && storage_jobunits.length > 0 &&
                storage_jobunits.map((res) => {
                  return (<tr key={res.id}>
                    <td>{res.serial_number} - {res.storage_type_name}</td>
                    <td>{res.warehouse_name}</td>
                    <td><Button className='btn btn-sm btn-soft-danger' onClick={() => DeleteUnits(res.id)}><i className="bx bxs-trash fs-12"></i></Button></td>
                  </tr>)
                })}
              {showreserves && <tr>
                <td width="45%">
                  <select className='form-control' onChange={(e) => {
                    setStorageUnit(e.target.value);
                  }}>
                    {storagereserves.map((res) => {
                      return (<option key={res.id} value={res.id}>{res.serial_number} - {res.name}</option>)
                    })}
                  </select>
                </td>
                <td width="30%">
                  <select className='form-control'>
                    {storagereserves.map((res) => {
                      return (<option key={res.warehouse_id}>{res.warehouse_name}</option>)
                    })}
                  </select>
                </td>
                <td width="25%">
                  <button id="s_cancel_discount_btn" type="button" className="btn btn-light" onClick={() => setShowReserveStorage(false)}> Cancel</button>
                  <button id="s_save_discount_btn" type="button" className="btn btn-success mx-2" onClick={() => SaveReservation()}> Reserve</button>
                </td>
              </tr>
              }
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <h5>Invoice</h5>
        </CardHeader>
        <CardBody>
          <p className='text-muted'>Add an invoice in this section only if you want the Storage invoice seperate from the Removals job invoice</p>
          <Table className='table-bordered mt-4'>
            <thead className='bg-soft-purple'>
              <tr>
                <th>Item Name & Description</th>
                <th>Tax</th>
                <th>Unit Price</th>
                <th>Qty</th>
                <th>Total Inc Tax</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {invoiceItems.map((res) => {
                { total_excl_tax = parseFloat(total_excl_tax) + (parseFloat(res.unit_price) * parseFloat(res.quantity)) }
                { total_tax = parseFloat(total_tax) + parseFloat(res.amount) - (parseFloat(res.unit_price) * parseFloat(res.quantity)) }
                return (<>{<tr key={res.id}>
                  <td>
                    <span>{res.item_name}</span><br />
                    <span dangerouslySetInnerHTML={{ __html: res.item_summary }}></span>
                  </td>
                  <td >{taxs.map((tax) => (res.tax_id == tax.id) ? tax.tax_name : "")}</td>
                  <td className='text-end' >{data?.global?.currency_symbol} {parseFloat(res.unit_price).toFixed(2)}</td>
                  <td  className='text-end'>{res.quantity.toFixed(2)}</td>
                  <td className='text-end'>{data?.global?.currency_symbol} {parseFloat(res.amount).toFixed(2)}</td>
                  <td>
                    <div className='hstack gap-2 justify-content-center'>
                      <button className="btn btn-sm btn-soft-info edit-list" onClick={() => t_editinvoice(res.id)}>
                        <i className="bx bxs-pencil fs-12 pt-1"></i>
                      </button>
                      <button className="btn btn-sm btn-soft-danger edit-list" onClick={() => t_deleteInvoice(res)}>
                        <i className="bx bxs-trash fs-12 pt-1"></i>
                      </button>
                    </div>
                  </td>
                </tr>}
                  {(expandedinvoiceId == res.id) && <tr key={res.id}>
                    <td>
                      <span> <Input
                        type="text"
                        list="data"
                        value={res.item_name}
                        onChange={(e) => {
                          const enteredValue = e.target.value.split(",");
                          const updateddata = [...invoiceItems];
                          const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                          if (enteredValue.length > 1) {
                            const invoiceData = data?.products?.find((val) => val.id === parseInt(enteredValue[1]));
                            if (invoiceData) {
                              updateddata[taskIndex].item_name = invoiceData.name;
                              updateddata[taskIndex].item_summary = invoiceData.description;
                              updateddata[taskIndex].tax_id = invoiceData.tax_id;
                              updateddata[taskIndex].unit_price = parseFloat(invoiceData.price).toFixed(2);
                              updateddata[taskIndex].quantity = 1;
                              updateddata[taskIndex].amount = parseFloat(invoiceData.total_amount).toFixed(2);
                            }
                          } else {
                            updateddata[taskIndex].item_name = enteredValue[0];
                          }
                          setInvoiceItems(updateddata);
                        }}
                      />
                      </span>
                      <datalist id="data">
                        {data?.products.map((item, key) => (
                          <option key={key} value={item.name} data-id={item.id}>
                            {item.item_name}
                          </option>

                        ))}
                      </datalist>
                      <br />
                      <span><Input value={res.item_summary} type="text" placeholder="Description" onChange={(e) => {
                        const updateddata = [...invoiceItems];
                        const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                        updateddata[taskIndex].item_summary = e.target.value;
                        setInvoiceItems(updateddata);
                      }} /></span>
                    </td>
                    <td><select className="form-select" value={res.tax_id} onChange={(e) => {
                      const updateddata = [...invoiceItems];
                      const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                      updateddata[taskIndex].tax_id = e.target.value;
                      setInvoiceItems(updateddata);
                    }}>
                      <option value="0"></option>
                      {data?.taxs.map((res) => {
                        return (<option key={res.id} value={res.id}>{res.tax_name}</option>)
                      })}
                    </select></td>
                    <td><Input type="number" value={res.unit_price} step={0.00} onChange={(e) => {
                      const updateddata = [...invoiceItems];
                      const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                      updateddata[taskIndex].unit_price = parseFloat(e.target.value).toFixed(2);
                      var tax_amount = data.taxs.map((val) => (
                        (res.tax_id == val.id) ? val : ""));
                      if (tax_amount.length > 0) {
                        tax_amount = tax_amount[0];
                      }
                      var totalAmount = (parseFloat(updateddata[taskIndex].unit_price).toFixed(2) * parseFloat(updateddata[taskIndex].quantity)).toFixed(2) * (1 + parseFloat(tax_amount.rate_percent).toFixed(2) / 100);
                      updateddata[taskIndex].amount = parseFloat(totalAmount).toFixed(2);
                      setInvoiceItems(updateddata);
                    }} /></td>
                    <td><Input type="number" value={res.quantity} step={0.00} onChange={(e) => {
                      const updateddata = [...invoiceItems];
                      const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                      updateddata[taskIndex].quantity = e.target.value;
                      var tax_amount = data.taxs.map((val) => (
                        (res.tax_id == val.id) ? val : ""));
                      if (tax_amount.length > 0) {
                        tax_amount = tax_amount[0];
                      }
                      var totalAmount = (parseFloat(updateddata[taskIndex].unit_price).toFixed(2) * parseFloat(updateddata[taskIndex].quantity)).toFixed(2) * (1 + parseFloat(tax_amount.rate_percent).toFixed(2) / 100);
                      updateddata[taskIndex].amount = parseFloat(totalAmount).toFixed(2);
                      setInvoiceItems(updateddata);
                    }} /></td>
                    <td><Input type="number" value={res.amount} step={0.00} onChange={(e) => {
                      const updateddata = [...invoiceItems];
                      const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                      updateddata[taskIndex].amount = e.target.value;
                      var tax_amount = data.taxs.map((val) => (
                        (res.tax_id == val.id) ? val : ""));
                      if (tax_amount.length > 0) {
                        tax_amount = tax_amount[0];
                      }
                      var unitPrice = ((parseFloat(updateddata[taskIndex].amount).toFixed(2) / parseFloat(updateddata[taskIndex].quantity)).toFixed(2)) / (1 + parseFloat(tax_amount.rate_percent).toFixed(2) / 100);
                      updateddata[taskIndex].unit_price = parseFloat(unitPrice).toFixed(2);
                      setInvoiceItems(updateddata);
                    }} /></td>
                    <td>
                      <div className='hstack gap-2'>
                        <button className="btn btn-sm btn-light edit-list" onClick={() => t_editinvoice()}>
                          Cancel
                        </button>
                        <button className="btn btn-sm btn-success edit-list" onClick={() => EditInvoice(res)}>
                          Update
                        </button>
                      </div>
                    </td>
                  </tr>}
                  {invoiceItems && invoiceItems.length <= 0 && <tr>
                    <td>No record available!</td>
                  </tr>}</>)
              })}
              {AddinvoiceItem &&
                <tr>
                  <td>
                    <span> <Input
                      type="text"
                      list="data"
                      value={invoicename}
                      onChange={(e) => {
                        const { value } = e.target;
                        const dataList = document.getElementById("data");
                        const selectedValue = e.target.value;
                        const selectedItem = [...dataList.querySelectorAll('option')].find(option => option.value === selectedValue);
                        const enteredValue = selectedItem ? selectedItem.value : "";
                        const itemId = selectedItem ? selectedItem.dataset.id : "";
                        if (itemId) {
                          const invoiceData = data?.products?.find((val) => val.id === parseInt(itemId));
                          console.log(invoiceData, "invoiceData");
                          if (invoiceData) {
                            setProductId(invoiceData.id);
                            setProductType(invoiceData.product_type);
                            setInvoiceName(invoiceData.name);
                            setInvoiceDesc(invoiceData.description);
                            setInvoiceTax(invoiceData.tax_id);
                            setInvoicePrice(parseFloat(invoiceData.price).toFixed(2));
                            setInvoiceQuantity(1);
                            setInvoiceAmount(parseFloat(invoiceData.total_amount).toFixed(2));
                          }
                        } else {
                          setInvoiceName(enteredValue);
                        }
                      }}
                    />
                    </span>
                    <datalist id="data">
                      {data?.products.map((item) => (
                        <option key={item.id} value={item.name} data-id={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </datalist>
                    <br />
                    <span><Input type="text" value={invoicedesc} placeholder="Description" onChange={(e) => {
                      setInvoiceDesc(e.target.value);
                    }} /></span>
                  </td>
                  <td><select className="form-select" value={invoicetax} onChange={(e) => {
                    var tax_amount = data.taxs.map((val) => (
                      (e.target.value == val.id) ? val : ""));
                    var taxrate = (tax_amount.length > 0 && tax_amount[0] != "") ? tax_amount[0].rate_percent : 0;
                    var totalAmount = (parseFloat(invoiceprice).toFixed(2) * parseFloat(invoiceqty)).toFixed(2) * (1 + parseFloat(taxrate).toFixed(2) / 100);
                    setInvoiceAmount(parseFloat(totalAmount).toFixed(2));
                    setInvoiceTax(e.target.value);
                  }}>
                    <option value="0"></option>
                    {data?.taxs.map((res) => {
                      return (<option key={res.id} value={res.id}>{res.tax_name}</option>)
                    })}
                  </select></td>
                  <td><Input type="number" value={invoiceprice} onChange={(e) => {
                    setInvoicePrice(e.target.value);
                    var tax_amount = data.taxs.map((val) => (
                      (invoicetax == val.id) ? val : ""));
                    if (tax_amount.length > 0) {
                      tax_amount = tax_amount[0];
                    }
                    console.log(e.target.value);
                    console.log(invoiceqty);
                    console.log(invoicetax);

                    var totalAmount = (parseFloat(e.target.value).toFixed(2) * parseFloat(invoiceqty).toFixed(2)) * (1 + parseFloat(tax_amount.rate_percent).toFixed(2) / 100);
                    setInvoiceAmount(parseFloat(totalAmount).toFixed(2));
                  }} step={0.00} /></td>
                  <td><Input type="number" value={invoiceqty} step={0.00} onChange={(e) => {
                    setInvoiceQuantity(e.target.value);
                    var tax_amount = data.taxs.map((val) => (
                      (invoicetax == val.id) ? val : ""));
                    if (tax_amount.length > 0) {
                      tax_amount = tax_amount[0];
                    }
                    var totalAmount = (parseFloat(invoiceprice).toFixed(2) * parseFloat(e.target.value)).toFixed(2) * (1 + parseFloat(tax_amount.rate_percent).toFixed(2) / 100);
                    setInvoiceAmount(parseFloat(totalAmount).toFixed(2));
                  }} /></td>
                  <td><Input type="number" value={invoiceamt} step={0.00} onChange={(e) => {
                    setInvoiceAmount(e.target.value);
                    var tax_amount = data.taxs.map((val) => (
                      (invoicetax == val.id) ? val : ""));
                    if (tax_amount.length > 0) {
                      tax_amount = tax_amount[0];
                    }
                    var unitPrice = ((parseFloat(e.target.value).toFixed(2) / parseFloat(invoiceqty)).toFixed(2)) / (1 + parseFloat(tax_amount.rate_percent || 0).toFixed(2) / 100);
                    setInvoicePrice(parseFloat(unitPrice).toFixed(2));
                  }} /></td>
                  <td>
                    <div className='hstack gap-2'>
                      <button className="btn btn-sm btn-light edit-list" onClick={addInvoiceRow}>
                        Cancel
                      </button>
                      <button className="btn btn-sm btn-success edit-list" onClick={() => SaveInvoice()}>
                        Save
                      </button>
                    </div>
                  </td>
                </tr>}
            </tbody>
          </Table>
          <Button className="btn btn-brown" onClick={addInvoiceRow} >+</Button>
          <Row>
            <Col lg={6}></Col>
            <Col lg={6}>
              <Table className="table-bordered ">
                <tbody>
                  <tr>
                    <th className='bg-soft-purple'><span>Total (excl tax)</span></th>
                    <td className='text-end'><span id="grand_total_tax">{data?.global?.currency_symbol}{total_excl_tax.toFixed(2)}</span></td>
                  </tr>
                  <tr>
                    <th className='bg-soft-purple'><span>Discount {(discounttype) ? "- " + discounttype : ""}</span>
                      <UncontrolledDropdown className="dropdown d-inline-block">
                        <DropdownToggle className="btn fs-18" tag="button">
                          <i className="ri-more-fill"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem className='edit-item-btn' onClick={() => SelectDiscount("Fixed")}>
                            <i className="ri-money-dollar-circle-line align-bottom me-2 text-muted"></i>Fixed
                          </DropdownItem>
                          <DropdownItem className='remove-item-btn' onClick={() => SelectDiscount("Percent")}>
                            <i className="ri-percent-fill align-bottom me-2"></i>Percent
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </th>
                    <td className='text-end'>{discounttype == "" &&
                      // <span id="grand_total_incl_tax">{showdiscount ? discountAmtString : (`$ ${discountamt?.toFixed(2)}`)}</span>
                      <span id="grand_total_incl_tax">{showdiscount ? discountAmtString : (`${discountamt?.toFixed(2)}`)}%</span>
                    }
                      {discounttype &&
                        <span>
                          <div className="input-group input-group-sm">
                            <input type="number" id="s_discount_value_field" value={discountVal} className="form-control mb-2" onChange={(e) => {
                              console.log(e.target.value);
                              setDiscountVal(e.target.value)
                              // data.invoice.discount = e.target.value;
                            }} />
                          </div>
                          <div className="d-flex justify-content-start align-items-center m-t-10">
                            <button id="s_cancel_discount_btn" type="button" className="btn btn-light" onClick={() => SelectDiscount("cancel")}> Cancel</button>
                            <button id="s_save_discount_btn" type="button" className="btn btn-success mx-2" onClick={() => submitDiscount()}> Save</button>
                          </div>
                        </span>}
                    </td>
                  </tr>
                  <tr>
                    <th className='bg-soft-purple'><span>Total (excl tax) after discount</span></th>
                    <td className='text-end'><span id="grand_total_incl_tax">{data?.global?.currency_symbol}{parseFloat(afterDiscountVal == 0 ? total_excl_tax : afterDiscountVal).toFixed(2)}</span></td>
                  </tr>
                  <tr>
                    <th className='bg-soft-purple'><span>Tax</span></th>
                    <td className='text-end'><span id="grand_total_incl_tax">{data?.global?.currency_symbol}{total_tax.toFixed(2)}</span></td>
                  </tr>
                  {/* <tr>
                    <th className='bg-soft-purple'><span>Total (incl tax)</span></th>
                    <td><span id="grand_total_incl_tax">{showdiscount?"":data?.global?.currency_symbol}{(inclTax).toFixed(2)}</span></td>
                  </tr> */}
                  <tr>
                    <th className='bg-soft-purple'><span>Total (incl tax)</span></th>
                    <td className='text-end'>
                      <span id="grand_total_incl_tax">
                        {showdiscount ? "" : (data?.global?.currency_symbol + (inclTax ? inclTax.toFixed(2) : '0.00'))}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <span className="badge badge-soft-success badge-border fs-14 mt=3">PAYMENTS</span>
        </CardHeader>
        <CardBody>
          <Table className='table-bordered mt-4'>
            <thead className='bg-soft-purple'>
              <tr>
                <th>Payment Method</th>
                <th>Reference / Notes</th>
                <th>Paid on</th>
                <th>Amount</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {paymentItems && paymentItems.map((res) => {
                total_payments = parseFloat(total_payments) + parseFloat(res.amount);
                return (<>{<tr key={res.id}>
                  <td>{res.gateway}</td>
                  <td>{res.remarks}</td>
                  {/* <td>{moment(res.paid_on).format(data?.global?.date_format)}</td> */}
                  <td>{moment(res.paid_on).format(org_date.date_picker_format)}</td>
                  <td className='text-end'>{data?.global?.currency_symbol}{parseFloat(res.amount).toFixed(2)}</td>
                  <td>
                    <div className='hstack gap-2 justify-content-center'>
                      <Button className="btn btn-sm btn-soft-info edit-list" onClick={() => t_editPayment(res.id)}>
                        <i className="bx bxs-pencil fs-12 pt-1"></i>
                      </Button>
                      <Button
                        className=" btn btn-sm btn-soft-danger fs-13 pt-1" onClick={() => t_deletePayment(res)}>
                        <i className="bx bxs-trash fs-12"></i>
                      </Button>
                    </div>
                  </td>
                </tr>}
                  {(expandedpaymentId == res.id) && <tr>
                    <td>
                      <select className='form-control' value={res.gateway} onChange={(e) => {
                        const updateddata = [...data.payment_items];
                        const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                        updateddata[taskIndex].gateway = e.target.value;
                        setPaymentItems(updateddata);
                      }}>
                        <option></option>
                        {data.payment_methods.map((res) => {
                          return (<option key={res.id} value={res.name}>{res.name}</option>)
                        })}
                      </select>
                    </td>
                    <td>
                      <textarea className='form-control' value={res.remarks} onChange={(e) => {
                        const updateddata = [...data.payment_items];
                        const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                        updateddata[taskIndex].remarks = e.target.value;
                        setPaymentItems(updateddata);
                      }}></textarea>
                    </td>
                    <td>
                      {/* <Flatpickr
                        className="form-control"
                        options={{
                          dateFormat: "Y-m-d",
                          defaultDate: [moment(res.paid_on).format("YYYY-MM-DD")]
                        }} onChange={(selectedDate) => {
                          const updateddata = [...data.payment_items];
                          const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                          updateddata[taskIndex].paid_on = moment(selectedDate[0]).format("YYYY-MM-DD");
                          setPaymentItems(updateddata);
                        }} /> */}
                         <Flatpickr
                        className="form-control"
                        options={{
                          dateFormat: "d/m/Y",
                          defaultDate: [moment(res.paid_on).format("DD/MM/YYYY")]
                        }} onChange={(selectedDate) => {
                          const updateddata = [...data.payment_items];
                          const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                          updateddata[taskIndex].paid_on = moment(selectedDate[0]).format("DD/MM/YYYY");
                          setPaymentItems(updateddata);
                        }} />
                    </td>
                    <td>
                      <Input className='form-control' value={res.amount} onChange={(e) => {
                        console.log(parseFloat(e.target.value).toFixed(2));
                        const updateddata = [...data.payment_items];
                        const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                        updateddata[taskIndex].amount = e.target.value;
                        console.log(updateddata[taskIndex]);
                        setPaymentItems(updateddata);
                      }} />
                    </td>
                    <td>
                      <div className='hstack gap-2'>
                        <button className="btn btn-sm btn-light edit-list" onClick={() => t_editPayment()}>
                          Cancel
                        </button>
                        <button className="btn btn-sm btn-success edit-list" onClick={() => UpdatePayment(res)}>
                          Update
                        </button>
                      </div>
                    </td>
                  </tr>}
                </>)
              })
              }
              {data.payment_items && data.payment_items.length <= 0 && <tr>
                <td>No record available!</td>
              </tr>}
              {Addnewpayment &&
                <tr>
                  <td>
                    <select className='form-control' onChange={(e) => {
                      setPaymentMode(e.target.value);
                    }}>
                      <option></option>
                      {data.payment_methods.map((res) => {
                        return (<option key={res.id} value={res.name}>{res.name}</option>)
                      })}
                    </select>
                  </td>
                  <td>
                    <textarea className='form-control' onChange={(e) => {
                      setPaymentDesc(e.target.value)
                    }}></textarea>
                  </td>
                  <td>
                      <Flatpickr
                      className="form-control"
                      options={{
                        dateFormat: "d/m/Y",
                        defaultDate: [moment(new Date()).format("DD/MM/YYYY")]
                      }} onChange={(selectedDates) => {
                        setPaymentDate(moment(selectedDates[0]).format('DD/MM/YYYY'))
                      }} />
                  </td>
                  <td >
                    <Input className='form-control' onChange={(e) => {
                      setPaymentAmount(e.target.value);
                    }} />
                  </td>
                  <td>
                    <div className='hstack gap-2'>
                      <button className="btn btn-sm btn-light edit-list" onClick={() => t_addnewpayment()}>
                        Cancel
                      </button>
                      <button className="btn btn-sm btn-success edit-list" onClick={() => SavePayment()}>
                        Save
                      </button>
                    </div>
                  </td>
                </tr>}
            </tbody>
          </Table>
          <Button className="btn btn-brown" onClick={() => t_addnewpayment()} >+</Button>
        </CardBody>
        <Row>
          <Col lg={6}></Col>
          <Col lg={6}>
            <Table className="table-bordered ">
              <tbody>
                <tr>
                  <th className='bg-soft-purple'><span>Payments</span></th>
                  <td className='text-end'><span id="grand_total_tax">{data?.global?.currency_symbol}{(total_payments).toFixed(2)}</span></td>
                </tr>
                <tr>
                  <th className='bg-soft-purple'><span>Balance</span></th>
                  <td className='text-end'><span id="grand_total_incl_tax">{data?.global?.currency_symbol}{(parseFloat(total_payments) - parseFloat(data.totalAmount)).toFixed(2)}</span></td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card>
      <Modal
        id="showModal"
        className="modal-dialog-edit"
        isOpen={modal}
        toggle={() => t_deleteInvoice}
        centered>
        <ModalBody className="py-2 px-3">
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/wdqztrtx.json"
              trigger="loop"
              colors="primary:#912a4e,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                You will not be able to recover the deleted invoice item!
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              data-bs-dismiss="modal"
              onClick={() => t_deleteInvoice()}>
              Cancel
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={() => deleteInvoice()}
            >
              Ok
            </button>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        id="showModal"
        className="modal-dialog-edit"
        isOpen={paymentmodal}
        toggle={() => t_deletePayment}
        centered>
        <ModalBody className="py-2 px-3">
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/wdqztrtx.json"
              trigger="loop"
              colors="primary:#912a4e,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                You will not be able to recover the deleted payment item!
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              data-bs-dismiss="modal"
              onClick={() => t_deletePayment()}>
              Cancel
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={() => deletePayment()}
            >
              Ok
            </button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}
