import React, { useState, useCallback, useEffect, useMemo } from 'react';
import {
    Card, CardBody, CardHeader, Col, Container, Row, Label, Input, Accordion, AccordionItem, Collapse, Form, FormFeedback, Modal, ModalHeader, ModalBody, ModalFooter, Nav, Button,
    NavItem, NavLink, TabContent, TabPane, UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu, Progress,
    DropdownItem,
} from 'reactstrap';

import * as moment from "moment";
import { Link } from 'react-router-dom';
import axios from 'axios';
import Flatpickr from "react-flatpickr";
import classnames from "classnames";

// import Components
import BreadCrumb from '../../Components/Common/BreadCrumb';

import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../Components/Common/TableContainer";
import Loader from "../../Components/Common/Loader";
import { toast, ToastContainer } from 'react-toastify';
import DeleteModal from "../../Components/Common/DeleteModal";
import 'react-toastify/dist/ReactToastify.css';

// Import Images
import dummyImg from "../../assets/images/users/user-dummy-img.jpg";


const Backloading = () => {

    const [contact, setContact] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [data, setData] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [createdDateStart, setCreatedDateStart] = useState(null);
    const [createdDateEnd, setCreatedDateEnd] = useState(null);
    const [vehicles, setVehicles] = useState([]);
    const [status, setStatus] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [selectedVehicleId, setSelectedVehicleId] = useState("");
    const [selectedStatusId, setSelectedStatusId] = useState();
    const [selectedDriverId, setSelectedDriverId] = useState();
    const [tripName, setTripName] = useState("");
    const [tripNotes, setTripNotes] = useState("");
    const [fromCity, setFromCity] = useState("");
    const [toCity, setToCity] = useState();
    const [col1, setcol1] = useState(false);
    const [id, setId] = useState(null);
    const [org_date, setOrg_Date] = useState([]);

    const t_col1 = () => {
        setcol1(!col1);

    };
    const loadData = () => {
        setisLoading(true);
        axios.get("/api/backloading")
            .then(res => {
                console.log(res.organisation_settings   );
                
                setData(res);
                setVehicles(res.vehicles);
                setStatus(res.status);
                setDrivers(res.drivers);
                setOrg_Date(res.organisation_settings);
                setCreatedDateEnd(moment().format(res.organisation_settings?.date_picker_format));
                setCreatedDateStart(moment().subtract(1, 'months').format(res.organisation_settings?.date_picker_format));
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                setisLoading(false);
            })
    }
   
    useEffect(() => {
        console.log(org_date);
        loadData();
        
        
    }, [])
    // Customber Column
    const columns = useMemo(
        () => [
            {
                Header: "Id",
                accessor: "id",
                id: '#',
            },
            {
                Header: "Trip",
                accessor: "name",
                filterable: false,
                Cell: ({ value, row }) => {
                    // 'value' is the name of the trip, 'row' contains the row data
                    return (
                        <Link to={`/backloadingdetails/${row.original.id}`}>
                            {value}
                        </Link>
                    );
                },
            },
            {
                Header: "Dates",
                accessor: "dates",
                filterable: false,
            },
            {
                Header: "Capacity Loading",
                accessor: "capacity_loading",
                filterable: false,
                Cell: ({ value }) => {
                    const percentage = Number(value);
                    return (
                        <Progress color="primary" value={percentage}> {`${percentage}%`}</Progress>
                    )
                }
            },
            {
                Header: "Jobs",
                accessor: "all_jobs",
                filterable: false,
                Cell: ({ value }) => {
                    // Assuming "value" is an array of job objects
                    // Extract the job numbers and display them as a comma-separated string
                    const jobNumbers = value?.map((job) => job.job_number);
                    return <div className='hstack gap-1'>
                        {jobNumbers?.map((number, index) => (
                            <Button key={index} className="btn btn-info ">
                                <Link to={"/listjobs/listjobsdetail/" + number} className='text-white' >   {number}</Link>

                            </Button>
                        ))}
                    </div>;
                },
            },
            {
                Header: "Action",
                Cell: (cellProps) => {
                    return (
                        <ul className="list-inline hstack gap-2 mb-0">


                            <li className="list-inline-item edit">
                                <Link
                                    to="#"
                                    className="text-danger d-inline-block remove-item-btn"
                                    onClick={() => {
                                        const orderData = cellProps.row.original;
                                        const orderId = orderData.id;
                                        onClickDelete(orderId);
                                    }}
                                >
                                    <i className="ri-delete-bin-5-fill fs-16"></i>
                                </Link>
                            </li>
                        </ul>
                    );
                },
            },
        ],
        []
    );
    const [deleteModal, setDeleteModal] = useState(false);
    const onClickDelete = (id) => {
        setId(id);
        setDeleteModal(true);
    };

    const handleReset=()=>{
        setCreatedDateEnd(moment().format(org_date.date_picker_format));
        setCreatedDateStart(moment().subtract(1, 'months').format(org_date.date_picker_format));
        setSelectedVehicleId("")
        setSelectedStatusId("")
        loadData()
    }

    const handleSubmit = (e) => {

        e.preventDefault();
        setIsEdit(false);
        const formData = {
            trip_name: tripName,
            start_city: fromCity,
            finish_city: toCity,
            start_date: createdDateStart,
            finish_date: createdDateEnd,
            vehicle_id: selectedVehicleId,
            driver_id: selectedDriverId,
            trip_notes: tripNotes

        };
        if (tripName === "") {
            toast.error("Trip name is required")
        }
        else if (fromCity === "") {
            toast.error("Enter From city details")
        }
        else if (toCity === "") {
            toast.error("Enter To city details")
        }
        else {
            axios.post("api/create-new-trip", formData)
                .then(res => {
                    console.log(res);
                    tog_standard();
                    toast.success(res.message);
                })
            clearFields();
        }


    }
    const clearFields = () => {
        setSelectedVehicleId(vehicles[0].id);
        setSelectedDriverId(drivers[0].id)
        setTripName();
        setFromCity();
        setToCity();
        setTripNotes();
        setCreatedDateEnd(moment().format(org_date.date_picker_format));
        setCreatedDateStart(moment().subtract(7, 'months').format(org_date.date_picker_format));
    }
    const [modal_standard, setmodal_standard] = useState(false);
    function tog_standard() {
        setmodal_standard(!modal_standard);
        setSelectedVehicleId(vehicles[0].id);
        setSelectedDriverId(drivers[0].id)
    }
    const handleDeleteOrder = () => {
        const formdata = {
            'trip_id': id
        }
        axios.post("api/delete-backloading-trip", formdata)
            .then(res => {
                loadData();
                setDeleteModal(false);
                toast.success(res.message)
            })

    };

    const handleApply = (event) => {
        event.preventDefault();
        const selectedDateFormat = org_date?.date_format;
        const filterData = {
            'vehicle_id': selectedVehicleId,
            'status': selectedStatusId,
            'created_date_start': createdDateStart,
            'created_date_end': createdDateEnd,
            'selected_date_format': selectedDateFormat
        }
        let arr = [];
        axios.post("api/ajax-backloading-data", filterData)
            .then(res => {
                console.log(res);
                arr.push(res);
                console.log(arr)
                setData(res.data);

            })
    }
    document.title = "Backloading | Onexfort";
    // console.log(org_date.date_picker_format,"createdDateEnd");
    return (
        <React.Fragment>

            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteOrder}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Backloading" pageTitle="Onexfort" />
                    <Accordion id="default-accordion-example">
                        <AccordionItem>
                            <h2 className="accordion-header" id="headingOne">
                                <button
                                    className={classnames("accordion-button fw-semibold", { collapsed: !col1 })} type="button" onClick={t_col1} style={{ cursor: "pointer" }} >
                                    Search Filters
                                </button>
                            </h2>
                            <Collapse isOpen={col1} className="accordion-collapse" id="collapseOne" >
                                <div className="accordion-body">

                                    <Row>
                                        <Col lg={2}>
                                            <div className="mb-3">
                                                <Label className="form-label"> Start Date</Label>
                                                <Flatpickr
                                                    className="form-control" placeholder='Start Date'
                                                    options={{
                                                        dateFormat: org_date?.date_format,
                                                    }}
                                                    value={createdDateStart}
                                                    onChange={(selectedDates) => {
                                                        if (selectedDates?.length === 1) {
                                                            setCreatedDateStart(moment(selectedDates[0]).format(org_date?.date_picker_format));
                                                        } else {
                                                            setCreatedDateStart(null);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={2}>
                                            <div className="mb-3">
                                                <Label className="form-label"> End Date</Label>
                                                <Flatpickr
                                                    className="form-control"
                                                    value={createdDateEnd}
                                                    options={{
                                                        dateFormat: org_date?.date_format,
                                                    }}
                                                    onChange={(selectedDates) => {
                                                        if (selectedDates?.length === 1) {
                                                            setCreatedDateEnd(moment(selectedDates[0]).format(org_date?.date_picker_format));
                                                        } else {
                                                            setCreatedDateEnd(null);
                                                        }
                                                    }} />

                                            </div>
                                        </Col>
                                        <Col lg={2}>
                                            <div className="mb-3">
                                                <Label for="leadStatusinput" className="form-label">Vehicle</Label>
                                                <select
                                                    value={selectedVehicleId}
                                                    onChange={(event) => setSelectedVehicleId(event.target.value)}
                                                    className="form-select "
                                                    aria-label="Default select example"
                                                >
                                                    <option value={""}>None selected</option>
                                                    {vehicles?.map((item) => (
                                                        <option key={item.id} value={item.id}>
                                                            {item.vehicle_name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </Col>
                                        <Col lg={2}>
                                            <div className="mb-3">
                                                <Label for="leadStatusinput" className="form-label">Status</Label>
                                                <select
                                                    value={selectedStatusId}
                                                    onChange={(event) => setSelectedStatusId(event.target.value)}
                                                    className="form-select "
                                                    aria-label="Default select example"
                                                >
                                                    <option value={""}>None selected</option>
                                                    {status?.map((item) => (
                                                        <option key={item.id} value={item.list_option}>
                                                            {item.list_option}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="text-start">
                                                <button type="submit" className="btn form-btn-marg btn-primary" onClick={handleApply}>Apply</button>
                                                <button type="submit" className="btn form-btn-marg btn-primary" onClick={handleReset}>Reset</button>
                                            </div>

                                        </Col>
                                    </Row>
                                </div>
                            </Collapse>
                        </AccordionItem>
                    </Accordion>
                    <Card>
                        <CardHeader>
                            <div className="d-flex align-items-center flex-wrap gap-2">
                                <div className="flex-grow-1">
                                    <button
                                        className="btn btn-brown add-btn"
                                        onClick={() => {
                                            setIsEdit(false);
                                            tog_standard();
                                        }}
                                    >
                                        <i className="ri-add-fill me-1 align-bottom"></i> Add New Trip
                                    </button>
                                </div>

                            </div>
                        </CardHeader>
                        <CardBody className="pt-0">
                            <div>
                                {
                                    data?.length > 0 ? (

                                        <TableContainer
                                            columns={columns}
                                            data={(data || [])}
                                            isGlobalFilter={true}
                                            isAddUserList={false}
                                            customPageSize={8}
                                            className="custom-header-css"
                                            divClass="table-responsive table-card mb-3"
                                            tableClass="align-middle table-nowrap"
                                            theadClass="table-light"

                                            isContactsFilter={true}
                                            SearchPlaceholder="Search for contact..."
                                        />
                                    ) : ("No records found!")
                                }

                                {isLoading && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            background: 'rgba(255, 255, 255, 0.8)',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            zIndex: 9999,
                                        }}
                                    >
                                        <Loader />
                                    </div>
                                )}

                            </div>

                            <Modal id="showModal" size="lg" isOpen={modal_standard} centered>
                                <ModalHeader className="bg-soft-info p-3" >
                                    {!!isEdit ? "Edit Trip" : "Add New Trip"}
                                </ModalHeader>

                                <Form className="tablelist-form" onSubmit={(e) => {
                                    e.preventDefault();
                                    return false;
                                }}>
                                    <ModalBody>
                                        <Input type="hidden" id="id-field" />
                                        <Row className="g-3">
                                            <Col lg={12}>

                                                <div>
                                                    <Label
                                                        htmlFor="name-field"
                                                        className="form-label"
                                                    >
                                                        Trip Name
                                                    </Label>
                                                    <Input
                                                        name="name"
                                                        id="customername-field"
                                                        className="form-control"
                                                        placeholder="Enter Name"
                                                        type="text"
                                                        value={tripName}
                                                        onChange={(e) => {
                                                            setTripName(e.target.value)
                                                        }}

                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div>
                                                    <Label
                                                        htmlFor="company_name-field"
                                                        className="form-label"
                                                    >
                                                        From City
                                                    </Label>
                                                    <Input
                                                        name="company"
                                                        id="company_name-field"
                                                        className="form-control"
                                                        type="text"
                                                        value={fromCity}
                                                        onChange={(e) => {
                                                            setFromCity(e.target.value)
                                                        }}

                                                    />


                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div>
                                                    <Label
                                                        htmlFor="designation-field"
                                                        className="form-label"
                                                    >
                                                        To City
                                                    </Label>

                                                    <Input
                                                        name="designation"
                                                        id="designation-field"
                                                        className="form-control"
                                                        type="text"
                                                        value={toCity}
                                                        onChange={(e) => {
                                                            setToCity(e.target.value)
                                                        }}
                                                    />


                                                </div>
                                            </Col>

                                            <Col lg={6}>
                                                <Label className="form-label">Estimate Start Date</Label>
                                                <Flatpickr
                                                    className="form-control" placeholder='Start Date'
                                                    options={{
                                                        dateFormat: "d/m/Y",
                                                    }}
                                                    value={createdDateStart}
                                                    onChange={(selectedDates) => {
                                                        if (selectedDates?.length === 1) {
                                                            setCreatedDateStart(moment(selectedDates[0]).format('DD/MM/YYYY'));
                                                        } else {
                                                            setCreatedDateStart(null);
                                                        }
                                                    }}
                                                />
                                            </Col>

                                            <Col lg={6}>
                                                <Label className="form-label">Estimate Finish Date</Label>
                                                <Flatpickr
                                                    className="form-control"
                                                    value={createdDateEnd}
                                                    options={{
                                                        dateFormat: "d/m/Y",
                                                    }}
                                                    onChange={(selectedDates) => {
                                                        if (selectedDates?.length === 1) {
                                                            setCreatedDateEnd(moment(selectedDates[0]).format('DD/MM/YYYY'));
                                                        } else {
                                                            setCreatedDateEnd(null);
                                                        }
                                                    }}
                                                />
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label for="leadStatusinput" className="form-label">Vehicle</Label>
                                                    <select
                                                        value={selectedVehicleId}
                                                        onChange={(event) => setSelectedVehicleId(event.target.value)}
                                                        className="form-select "
                                                        aria-label="Default select example"
                                                    >
                                                        {vehicles?.map((item) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.vehicle_name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label for="leadStatusinput" className="form-label">Driver</Label>
                                                    <select
                                                        value={selectedDriverId}
                                                        onChange={(event) => setSelectedDriverId(event.target.value)}
                                                        className="form-select "
                                                        aria-label="Default select example"
                                                    >
                                                        {drivers?.map((item) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <Label htmlFor="VertimeassageInput" className="form-label">Trip Notes</Label>
                                                <textarea className="form-control" id="VertimeassageInput" rows="3"
                                                    value={tripNotes}
                                                    onChange={(e) => {
                                                        setTripNotes(e.target.value)
                                                    }}>
                                                </textarea>
                                            </Col>
                                        </Row>
                                    </ModalBody>
                                    <ModalFooter>
                                        <div className="hstack gap-2 justify-content-end">
                                            <button type="button" className="btn btn-light" onClick={() => { tog_standard(); }} > Cancel </button>
                                            <button type="submit" className="btn btn-success" id="add-btn" onClick={handleSubmit}> {!!isEdit ? "Update Trip" : "Create Trip"} </button>
                                        </div>
                                    </ModalFooter>
                                </Form>
                            </Modal>
                            <ToastContainer closeButton={false} limit={1} />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Backloading;
