import { Card, CardBody, Col, Row, CardHeader, Label, Input, } from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo } from "react";
//Import actions
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';

export const EnableAutoQuote = () => {
  document.title="Enable Auto Quote | Onexfort";
  const [dataObj, setDataObj] = useState({
    'id': null,
    'isQuoteEnabled': false,
    'googleApi': null,
    'isSendEmail': false,
    'initialOpStatus': null,
    'quoteSuccessful': null,
    'quoteFailed': null,
    'quoteEmailTemplate': null,
    'failEmailTemplate': null,
    'tax': null,
    'sendFailEmailTo': null,
    'itemProduct': null,
    'isRedirect': false,
    'isSendSms': false,
    'quoteSmsTemplate': null
  })
  const [pipelineStatus, setPipelineStatus] = useState([]);
  const [emailTemplate, setEmailTemplate] = useState([]);
  const [tax, setTax] = useState([]);
  const [users, setUsers] = useState([]);
  const [products, setProducts] = useState([]);
  const [smsTemplate, setSmsTemplate] = useState([]);

  const loadData = () => {
    axios.get("api/enableAutoQuote")
      .then(res => {
        console.log(dataObj);
        console.log(res);
        setPipelineStatus(res.pipelineStatus);
        setEmailTemplate(res.emailTemplates);
        setTax(res.taxes);
        setUsers(res.users);
        setProducts(res.products);
        setSmsTemplate(res.smsTemplates);
        setDataObj({
          'id': res.data.id,
          'isQuoteEnabled': (res.data.auto_quote_enabled === "Y") ? true : false,
          'googleApi': res.data.tenant_google_api_key,
          'isSendEmail': (res.data.send_auto_quote_email_to_customer === "Y") ? true : false,
          'initialOpStatus': res.data.initial_op_status_id,
          'quoteSuccessful': res.data.quoted_op_status_id,
          'quoteFailed': res.data.failed_op_status_id,
          'quoteEmailTemplate': res.data.quote_email_template_id,
          'failEmailTemplate': res.data.fail_email_template_id,
          'tax': res.data.tax_id_for_quote,
          'sendFailEmailTo': res.data.send_quote_fail_email_to,
          'itemProduct': res.data.quote_line_item_product_id,
          'isRedirect': (res.data.redirect_to_inven_form_after_quote_payment === "Y") ? true : false,
          'isSendSms': (res.data.send_auto_quote_sms_to_customer === "Y") ? true : false,
          'quoteSmsTemplate': res.data.quote_sms_template_id

        })

      })
      .catch(err => {
        console.log(err);
      })
  }
  useEffect(() => {
    loadData();

  }, [])

  const handleChange = (event, field) => {
    const { type, checked, value } = event.target;

    if (type === 'checkbox') {
      setDataObj((prevDataObj) => ({
        ...prevDataObj,
        [field]: checked,
      }));
    } else {
      setDataObj((prevDataObj) => ({
        ...prevDataObj,
        [field]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      "id": 4,
      "auto_quote_enabled": dataObj.isQuoteEnabled,
      'tenant_google_api_key': dataObj.googleApi,
      'send_auto_quote_email_to_customer': dataObj.isSendEmail,
      'initial_op_status_id': dataObj.initialOpStatus,
      'quoted_op_status_id': dataObj.quoteSuccessful,
      'failed_op_status_id': dataObj.quoteFailed,
      'quote_email_template_id': dataObj.quoteEmailTemplate,
      'fail_email_template_id': dataObj.failEmailTemplate,
      'tax_id_for_quote': dataObj.tax,
      'send_quote_fail_email_to': dataObj.sendFailEmailTo,
      'quote_line_item_product_id': dataObj.itemProduct,
      'redirect_to_inven_form_after_quote_payment': dataObj.isRedirect,
      'send_auto_quote_sms_to_customer': dataObj.isSendSms,
      'quote_sms_template_id': dataObj.quoteSmsTemplate
    }
    console.log(formData);
    axios.post("api/saveAutoQuoteData", formData)
      .then(res => {
        console.log("mm")
      })
      .catch(err => {
        console.log(err);
      })
  }
  return (
    <div>
      <Card>
        <CardHeader>
          <h5>Auto Quote - Removal Opportunities</h5>

        </CardHeader>
        <CardBody>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <Label className="form-label">Auto Quote Enabled?</Label>
                <div className="form-check form-switch form-switch-lg mb-3" dir="ltr">
                  <Input type="checkbox" className="form-check-input" id="customSwitchsizelg"
                    checked={dataObj.isQuoteEnabled}
                    onChange={(event) => handleChange(event, 'isQuoteEnabled')}

                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label for="initialstatus" className="form-label">Google Api</Label>
                  <Input value={dataObj.googleApi}
                    onChange={(event) => handleChange(event, 'googleApi')}
                  ></Input>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Label className="form-label">Send Auto Quote Email to Customer?</Label>
                <div className="form-check form-switch form-switch-lg" dir="ltr">
                  <Input type="checkbox" className="form-check-input" id="customSwitchsizelg"
                    checked={dataObj.isSendEmail}
                    onChange={(event) => handleChange(event, 'isSendEmail')}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label for="initialstatus" className="form-label">Initial Opportunity Status</Label>
                  <select id="initialstatus" className="form-select" value={dataObj.initialOpStatus} data-choices data-choices-sorting="true"
                    onChange={(event) => handleChange(event, 'initialOpStatus')}
                  >
                    {pipelineStatus.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.pipeline_status}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label for="successstatus" className="form-label">Successfully Quoted Opportunity Status</Label>
                  <select id="successstatus" className="form-select" value={dataObj.quoteSuccessful} data-choices
                    data-choices-sorting="true"
                    onChange={(event) => handleChange(event, 'quoteSuccessful')}
                  >
                    {pipelineStatus.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.pipeline_status}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label for="failedstatus" className="form-label">Quoting Failed Opportunity Status</Label>
                  <select id="failedstatus" className="form-select" value={dataObj.quoteFailed}
                    onChange={(event) => handleChange(event, 'quoteFailed')}
                    data-choices data-choices-sorting="true" >
                    {pipelineStatus.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.pipeline_status}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label for="emailtemplate" className="form-label">Quote Email Template</Label>
                  <select id="emailtemplate" className="form-select" value={dataObj.quoteEmailTemplate}
                    onChange={(event) => handleChange(event, 'quoteEmailTemplate')}
                    data-choices data-choices-sorting="true" >
                    {emailTemplate.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.email_template_name}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label for="failemailtemp" className="form-label">Failure Email Template</Label>
                  <select id="failemailtemp" className="form-select" value={dataObj.failEmailTemplate}
                    onChange={(event) => handleChange(event, 'failEmailTemplate')}
                    data-choices data-choices-sorting="true" >
                    {emailTemplate.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.email_template_name}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label for="taxinquote" className="form-label">Tax Used In Quote</Label>
                  <select id="taxinquote" className="form-select" value={dataObj.tax}
                    onChange={(event) => handleChange(event, 'tax')}
                    data-choices data-choices-sorting="true" >
                    {tax.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.tax_name} - {item.rate_percent}%
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label for="sendemail" className="form-label">Send Failure Email to</Label>
                  <select id="sendemail" className="form-select" value={dataObj.sendFailEmailTo}
                    onChange={(event) => handleChange(event, 'sendFailEmailTo')}
                    data-choices data-choices-sorting="true" >
                    {users.map((item) => (
                      <option key={item.id} value={item.email}>
                        {item.name} ({item.email})
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label for="quotelineitem" className="form-label">Quote Line item Product</Label>
                  <select id="quotelineitem" className="form-select" value={dataObj.itemProduct}
                    onChange={(event) => handleChange(event, 'itemProduct')}
                    data-choices data-choices-sorting="true" >
                    {products.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}

                  </select>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label className="form-label">Redirect to Inventory Form After Quote Payment</Label>
                  <div className="form-check form-switch form-switch-lg mb-3" dir="ltr">
                    <Input type="checkbox" className="form-check-input" id="radioredirect"
                      checked={dataObj.isRedirect}
                      onChange={(event) => handleChange(event, 'isRedirect')}
                    />
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label className="form-label">Send Auto Quote SMS to Customer</Label>
                  <div className="form-check form-switch form-switch-lg mb-3" dir="ltr">
                    <Input type="checkbox" className="form-check-input" id="radiosendsms"
                      checked={dataObj.isSendSms}
                      onChange={(event) => handleChange(event, 'isSendSms')}
                    />
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label for="smstemplate" className="form-label">Quote SMS Template</Label>
                  <select id="smstemplate" className="form-select" value={dataObj.quoteSmsTemplate}
                    onChange={(event) => handleChange(event, 'quoteSmsTemplate')}
                    data-choices data-choices-sorting="true" >
                    {smsTemplate.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.sms_template_name}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
            </Row>
            <hr></hr>
            <div className="text-center">
              <button type="submit" className="btn btn-primary">Save</button>
            </div>
          </form>
        </CardBody>
      </Card>
    </div >
  )
}
