import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from 'axios';
import Loader from "../../Components/Common/Loader";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    CardHeader,
    Collapse,
    Button,
    Label,
    Modal, ModalBody
} from "reactstrap";

import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Flatpickr from "react-flatpickr";
import TableContainer from "../../Components/Common/TableContainer";

import * as moment from "moment";

const BackloadingDetailsPage = () => {
    const [companieslist, setCompaniesList] = useState([]);
    const [jobdetails, setJobDetails] = useState(null);
    const [contact, setContactId] = useState(null);
    const [expandedoppId, setExpandedoppId] = useState(null);
    const [status, setStatus] = useState("");
    const [order, setOrder] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [modal, setModal] = useState(false);
    // const [deleteopportunity, handleOpportunity] = useState("");
    const [statuses, setStatuses] = useState("");
    const [contacts, setContacts] = useState("");
    const [leadinfo, setLeadInfo] = useState("");
    const [leadTypes, setLeadTypes] = useState([]);
    const [estjobdate, setEstJobdate] = useState(null);
    const [description, setDescription] = useState("");
    const [companyname, setCompanyName] = useState("");
    const [users, setUsers] = useState("");
    const [time, setTime] = useState(moment(new Date()).format("HH:mm:ss"));
    const [opp, setOpp] = useState("");
    const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
    const [createdDateStart, setCreatedDateStart] = useState();
    const [createdDateEnd, setCreatedDateEnd] = useState();
    const [selectedVehicleId, setSelectedVehicleId] = useState();
    const [vehicles, setVehicles] = useState([]);
    const [orderList, setOrderList] = useState([]);
    // const [users, setUsers] = useState([]);
    const [selectedStatusId, setSelectedStatusId] = useState();
    const [selectedUser, setSelectedUser] = useState("");
    const [searchTableData, setSearchTableData] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [isLoadingTable, setisLoadingTable] = useState(false);

    const getJobDetails = () => {
        console.log("ss")
    }
    const defaultdate = () => {
        let d = new Date(),
            months = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ];
        let h = d.getHours() % 12 || 12;
        let ampm = d.getHours() < 12 ? "AM" : "PM";
        return (
            d.getDate() +
            " " +
            months[d.getMonth()] +
            ", " +
            d.getFullYear() +
            ", " +
            h +
            ":" +
            d.getMinutes() +
            " " +
            ampm
        ).toString();
    };
    const [date, setDate] = useState(defaultdate());
    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
            setOrder(null);
        } else {
            setModal(true);
            setDate(defaultdate());
        }
    }, [modal]);

    const handleOrderClicks = () => {
        setOrder("");
        setIsEdit(false);
        toggle();
    };

    const checkedAll = useCallback(() => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".orderCheckBox");
        if (checkall.checked) {
            ele.forEach((ele) => {
                ele.checked = true;
            });
        } else {
            ele.forEach((ele) => {
                ele.checked = false;
            });
        }
        deleteCheckbox();
    }, []);

    const deleteCheckbox = () => {
        const ele = document.querySelectorAll(".orderCheckBox:checked");
        ele.length > 0
            ? setIsMultiDeleteButton(true)
            : setIsMultiDeleteButton(false);
        setSelectedCheckBoxDelete(ele);
    };

    const [deleteModal, setDeleteModal] = useState(false);
    const [unassignData, setUnassignData] = useState();
    const [assignData, setAssignData] = useState();
    const [assignModal, setAssignModal] = useState(false);

    const handleUnassignJobs = (data) => {
        setDeleteModal(true);
        console.log(data)
        const unassignTableData = { "job_id": data.job_id, "leg_id": data.leg_id, trip_id: parseInt(trip_id) }
        setUnassignData(unassignTableData)
        console.log(unassignTableData)
        // setTableId(id)
    }

    const handleAssignJobs = (data) => {
        console.log(data)
        setAssignModal(true)
        const assignTableData = { "job_id": data.job_id, "leg_id": data.leg_id, trip_id: parseInt(trip_id) }
        console.log(assignTableData)
        setAssignData(assignTableData)
    }

    const handleOrderClick = useCallback(
        (arg) => {
            const order = arg;
            setOrder({
                _id: order._id,
                orderId: order.orderId,
                customer: order.customer,
                product: order.product,
                orderDate: order.orderDate,
                ordertime: order.ordertime,
                amount: order.amount,
                payment: order.payment,
                status: order.status,
            });

            setIsEdit(true);
            toggle();
        },
        [toggle]
    );

    const handleOpportunity = (data) => {
        var postData = {
            lead_id: data.lead_id,
            opp_id: data.crm_opportunity_id,
        };
        console.log(data);
        axios
            .post("/api/crm-leads/ajaxDestroyOpportunity", postData)
            .then((response) => {

                getJobDetails(response);
                toast.success(response.message, { theme: "light" });
            });

    };

    const [addopp, setAddOpp] = useState(false);

    const add_opp = () => {
        setAddOpp(!addopp);

    };

    const handleApply = () => {
        setisLoadingTable(true)
        const filterData = {
            trip_id,
            'created_date_start': createdDateStart,
            'created_date_end': createdDateEnd
        }

        axios.post("/api/get-search-jobs", searchData)
            .then((res) => {
                console.log(res);
                setSearchTableData(res.data)
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setisLoadingTable(false);
            });

    }
    const Updateopportunity = (data) => {
        // event.preventDefault();
        console.log(data);
        let formData = {
            lead_id: data.lead_id,
            opp_id: data.id,
            company_id: data.company_id,
            op_type: "Moving",
            op_status: data.op_status,
            est_job_date: data.est_job_date,
            job_start_time: data.job_start_time,
            contact_id: data.contact_id,
            user_id: data.user_id,
            lead_info: data.lead_info,
            notes: data.notes,
        };


        axios.post("/api/crm-leads/ajaxUpdateOpportunity", formData).then((res) => {
            console.log(res);
            getJobDetails(res);
            handleoppEdit();
            toast.success("Task updated successfully", { theme: "light" });
        });
        // 
        axios.get("/api/get-trip-jobs", formData)
            .then((res) => {
                console.log(res);
            })
            .catch((error) => {
                console.error(error);
            })
    };

    const resetTaskFields = () => {
        setDescription("");
        setSelectedUser(users[0].id);
        setTime(moment(new Date()).format("HH:mm:ss"));
    }
    const resetOppFields = () => {
        setCompanyName(companieslist[0].id);
        setStatuses(status[0].pipeline_status);
        setEstJobdate(moment(new Date()).format("DD/MM/YYYY"));
        setSelectedUser(users[0].id);
        setLeadInfo(leadTypes[0].options);
        setDescription("")
    }



    const columns = useMemo(
        () => [
            {
                Header: "Job#",
                accessor: "job_value",
                filterable: false,
                Cell: ({ value, row }) => {
                    console.log(row.original.job_id)
                    // 'value' is the name of the trip, 'row' contains the row data
                    return (
                        <Link to={`/listjobs/listjobsdetail/${row.original.job_id}`}>
                            {value}
                        </Link>
                    );
                },
            },
            {
                Header: "Leg#",
                accessor: "leg_number",
                filterable: false,
            },
            {
                Header: "Name",
                accessor: "name",
                filterable: false,
            },
            {
                Header: "CBM",
                accessor: "cbm",
                filterable: false,
            },
            {
                Header: "Job Date",
                accessor: "job_date",
                filterable: false,
            },

            {
                Header: "Pickup Suburb",
                accessor: "pickup_suburb",
                filterable: false,
            },
            {
                Header: "Drop Off Suburb",
                accessor: "delivery_suburb",
                filterable: false,
            },
            {
                Header: "Job Status",
                accessor: "job_status",
                filterable: false,
            },
            {
                Header: "Payment Status",
                accessor: "payment_status",
                filterable: false,
            },
            {
                Header: "Action",
                Cell: (cellProps) => {
                    const { id } = cellProps.row.original;
                    const data = cellProps.row.original
                    return (
                        <button className="btn btn-sm btn-soft-info edit-list" onClick={() => {
                            // const orderData = cellProps.row.original;
                            handleUnassignJobs(data);
                        }} >Unassign Job
                        </button>
                    );
                },
            }
        ],
        [handleOrderClick, checkedAll]
    );

    const searchColumns = useMemo(
        () => [
            {
                Header: "Job#",
                accessor: "job_value",
                filterable: false,
                Cell: ({ value, row }) => {
                    console.log(row.original.job_id)
                    // 'value' is the name of the trip, 'row' contains the row data
                    return (
                        <Link to={`/listjobs/listjobsdetail/${row.original.job_id}`}>
                            {value}
                        </Link>
                    );
                },
            },
            {
                Header: "Leg#",
                accessor: "leg_number",
                filterable: false,
            },
            {
                Header: "Name",
                accessor: "name",
                filterable: false,
            },
            {
                Header: "CBM",
                accessor: "cbm",
                filterable: false,
            },
            {
                Header: "Job Date",
                accessor: "job_date",
                filterable: false,
            },

            {
                Header: "Pickup Suburb",
                accessor: "pickup_suburb",
                filterable: false,
            },
            {
                Header: "Drop Off Suburb",
                accessor: "delivery_suburb",
                filterable: false,
            },
            {
                Header: "Job Status",
                accessor: "job_status",
                filterable: false,
            },
            {
                Header: "Payment Status",
                accessor: "payment_status_text",
                filterable: false,
            },
            {
                Header: "Action",
                Cell: (cellProps) => {
                    const { id } = cellProps.row.original;
                    const data = cellProps.row.original
                    return (
                        <button className="btn btn-sm btn-soft-info edit-list" onClick={() => {
                            // const orderData = cellProps.row.original;
                            handleAssignJobs(data);
                        }} >Assign Job
                        </button>
                    );
                },
            }
        ],
        [handleOrderClick, checkedAll]
    );


    const handleForm2Submit = (event) => {
        event.preventDefault();

        let formData = {
            lead_id: jobdetails.job.customer_id,
            company_id: companyname,
            op_type: "Moving",
            op_status: statuses,
            est_job_date: estjobdate,
            job_start_time: time,
            contact_id: contact,
            user_id: selectedUser,
            lead_info: leadinfo,
            notes: description,
        };
        axios.post("/api/crm-leads/ajaxStoreOpportunity", formData)
            .then((res) => {
                getJobDetails(res);
                handleoppEdit();
                add_opp();
                console.log(res);
                toast.success("Opportunity has been added!")
                resetOppFields();
            })

    };

    ////
    const res = ""
    const params = useParams();
    const trip_id = parseInt(params.id);

    const [tripDetails, setTripDetails] = useState([])
    const [tableData, setTableData] = useState([])

    const handleoppEdit = (tripId) => {
        setExpandedoppId(tripId);
    };

    const onCloseClick = () => {
        if (deleteModal === true) {
            setDeleteModal(false)
        }
        else if (assignModal === true) {
            setAssignModal(false)
        }
    }

    const searchData = {
        trip_id,
        created_date_start: createdDateStart,
        created_date_end: createdDateEnd
    }


    const loadData = () => {
        axios.post(`api/trip-assign-job-page/${trip_id}`)
            .then(res => {
                setTripDetails(res.trip)
            })
            .catch(err =>
                console.log(err))
            .finally(() => {
                setisLoading(false);
            });
        axios.post(`/api/get-trip-jobs/${trip_id}`)
            .then((res) => {
                console.log(res);
                setTableData(res.data)
                setOrderList(res.data);
            })
            .catch((error) => {
                console.error(error);
            })

        axios.post("/api/get-search-jobs", searchData)
            .then((res) => {
                console.log(res);
                setSearchTableData(res.data)
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setisLoadingTable(false);
            });
    }

    console.log(tableData)
    const [isWaybillGenerated, setIsWaybillGenerated] = useState(false);
    console.log(isWaybillGenerated)

    const generateWayBill = () => {
        axios.post(`api/generate-waybill/${trip_id}`)
            .then((res) => {
                console.log(res);
                if (res.error === 0) {
                    setIsWaybillGenerated(true);
                }
            })
            .catch((error) => {
                console.error(error);
            })
    }


    const downloadWayBill = () => {
        if (isWaybillGenerated === false) {
            toast.warning("Waybill not yet generated. Click on 'Generate waybill' button to generate the waybill")
        }
        else {
            axios.post(`api/download-waybill/${trip_id}`,)
                .then((res) => {
                    window.open(res.url, "_blank");
                })
                .catch((error) => {
                    console.error(error);
                })
        }
    }

    const onDeleteClick = async () => {
        // var orderIds = deleteItemId;
        // axios.get(`/api/products/delete/"}${orderIds}`)
        axios.post("api/trip-unassign-job", unassignData)
            .then(response => {
                console.log(response.message);
                toast.success(response.message, { theme: "light" });
                loadData();
                setDeleteModal(false)
            })
            .catch(error => {
                console.error(error);
            });

    };

    const onAssignClick = async () => {
        // var orderIds = deleteItemId;
        // axios.get(`/api/products/delete/"}${orderIds}`)
        axios.post("api/trip-assign-job", assignData)
            .then(response => {
                console.log(response.message);
                toast.success(response.message, { theme: "light" });
                loadData();
                setAssignModal(false)
            })
            .catch(error => {
                console.error(error);
            });
    };

    useEffect(() => {
        setisLoading(true);
        setisLoadingTable(true)
        setCreatedDateStart(moment().subtract(1, 'months').format('DD/MM/YYYY'))
        setCreatedDateEnd(moment().format("DD/MM/YYYY"))
        loadData()
    }, [])
    console.log(tripDetails)
    return (
        <div className="page-content">
            <Modal isOpen={deleteModal} toggle={onCloseClick} centered={true}>
                <ModalBody className="py-3 px-5">
                    <div className="mt-2 text-center">
                        <script src="https://cdn.lordicon.com/bhenfmcm.js"></script>
                        <lord-icon
                            src="https://cdn.lordicon.com/lfqzieho.json"
                            trigger="hover"
                            colors="primary:#121331"
                            style={{ width: "100px", height: "100px" }}>
                        </lord-icon>

                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you sure ?</h4>
                            <p className="text-muted mx-4 mb-0">
                                You Want to Unassign This Job!
                            </p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button
                            type="button"
                            className="btn w-sm btn-light"
                            data-bs-dismiss="modal"
                            onClick={onCloseClick}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn w-sm btn-danger "
                            id="delete-record"
                            onClick={onDeleteClick}
                        >
                            Ok
                        </button>
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={assignModal} toggle={onCloseClick} centered={true}>
                <ModalBody className="py-3 px-5">
                    <div className="mt-2 text-center">
                        <script src="https://cdn.lordicon.com/bhenfmcm.js"></script>
                        <lord-icon
                            src="https://cdn.lordicon.com/lfqzieho.json"
                            trigger="hover"
                            colors="primary:#121331"
                            style={{ width: "100px", height: "100px" }}>
                        </lord-icon>

                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you sure ?</h4>
                            <p className="text-muted mx-4 mb-0">
                                You Want to assign This Job!
                            </p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button
                            type="button"
                            className="btn w-sm btn-light"
                            data-bs-dismiss="modal"
                            onClick={onCloseClick}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn w-sm btn-danger "
                            id="delete-record"
                            onClick={onAssignClick}
                        >
                            Ok
                        </button>
                    </div>
                </ModalBody>
            </Modal>
            <Container fluid>
                <Row>
                    <Col xl={3}>
                        <Card>
                            <CardHeader>
                                <div className="d-flex">
                                    <h5 className="card-title flex-grow-1 mb-0">
                                        Edit Trip</h5>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <div className="header-elements-inline">
                                    <div className="page-title d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            {/* <button className="btn bg-dark btn-icon btn-md txt-white">FM</button> */}
                                            <span className="fs-15 fw-medium">
                                                {tripDetails.trip_name}
                                            </span>
                                        </div>
                                        <UncontrolledDropdown className="dropdown d-inline-block">
                                            <DropdownToggle className="btn fs-18" tag="button">
                                                <i className="ri-more-2-line"></i>
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu-end">
                                                <DropdownItem
                                                    className="edit-item-btn"
                                                    onClick={() => {
                                                        handleoppEdit(tripDetails.id);
                                                    }}
                                                >
                                                    <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                                                    Edit
                                                </DropdownItem>
                                                <DropdownItem
                                                    className="remove-item-btn text-danger"
                                                    onClick={() => {
                                                        handleOpportunity(res);
                                                    }}
                                                >
                                                    <i className="ri-delete-bin-fill align-bottom me-2 text-danger"></i>
                                                    Delete
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                    <div>
                                        {expandedoppId === res.id && (
                                            <div className="collapse-section">
                                                Company
                                                <div>
                                                    <select
                                                        className="form-select"
                                                        value={res.company_id}
                                                        onChange={(e) => {
                                                            const updatedTasks = [
                                                                ...jobdetails.opportunities,
                                                            ];
                                                            const taskIndex = updatedTasks.findIndex(
                                                                (task) => task.id === res.id
                                                            );
                                                            updatedTasks[taskIndex].company_id =
                                                                e.target.value;
                                                            setJobDetails({
                                                                ...jobdetails,
                                                                opportunities: updatedTasks,
                                                            });
                                                        }}
                                                    >
                                                        {jobdetails?.companies_list?.map((res) => (
                                                            <option key={res.id} value={res.id}>
                                                                {res.company_name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                Job Type
                                                <div>
                                                    <select className="form-select" disabled value={res.op_type}>
                                                        <option>Moving</option>
                                                    </select>
                                                </div>
                                                <div>
                                                    <label
                                                        htmlFor="statusSelect"
                                                        className="form-label"
                                                    >
                                                        Status
                                                    </label>
                                                    <select
                                                        id="statusSelect"
                                                        className="form-select mb-3"
                                                        value={res.op_status}
                                                        onChange={(e) => {

                                                            const updatedTasks = [
                                                                ...jobdetails.opportunities,
                                                            ];
                                                            const taskIndex = updatedTasks.findIndex(
                                                                (task) => task.id === res.id
                                                            );
                                                            updatedTasks[taskIndex].op_status =
                                                                e.target.value;
                                                            setJobDetails({
                                                                ...jobdetails,
                                                                opportunities: updatedTasks,
                                                            });
                                                        }}
                                                    >
                                                        {jobdetails?.op_status?.map((res) => (
                                                            <option
                                                                key={res.id}
                                                                value={res.pipeline_status}
                                                            >
                                                                {res.pipeline_status}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                Estimated Job date
                                                <div className="pd-field">
                                                    <Flatpickr
                                                        className="form-control"
                                                        options={{
                                                            dateFormat: "Y-m-d",
                                                            defaultDate: [res.est_job_date],
                                                        }}
                                                        value={res.est_job_date}
                                                        onChange={(selectedDates) => {
                                                            if (selectedDates && selectedDates.length > 0) {
                                                                res.est_job_date = moment(
                                                                    selectedDates[0]
                                                                ).format("YYYY-MM-DD");
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                Estimated Start Time
                                                <div className="pd-field">
                                                    <Flatpickr
                                                        className="form-control"
                                                        options={{
                                                            enableTime: true,
                                                            noCalendar: true,
                                                            dateFormat: "H:i",
                                                            defaultTime: [res.job_start_time],
                                                        }}
                                                        value={res.job_start_time}
                                                        onChange={(selectedDates) => {
                                                            if (selectedDates && selectedDates.length > 0) {
                                                                res.job_start_time = moment(
                                                                    selectedDates[0]
                                                                ).format("H:m:s");
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                Users
                                                <div>
                                                    <select
                                                        className="form-select"
                                                        value={res.user_id}
                                                        onChange={(e) => {
                                                            console.log(res);
                                                            const updatedTasks = [
                                                                ...jobdetails.opportunities,
                                                            ];
                                                            const taskIndex = updatedTasks.findIndex(
                                                                (task) => task.id === res.id
                                                            );
                                                            updatedTasks[taskIndex].user_id =
                                                                e.target.value;
                                                            setJobDetails({
                                                                ...jobdetails,
                                                                opportunities: updatedTasks,
                                                            });
                                                        }}
                                                    >
                                                        {jobdetails?.users?.map((val) => (
                                                            <option key={val.id} value={val.id}>
                                                                {val.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                Contact
                                                <div>
                                                    <select
                                                        className="form-select"
                                                        value={res.contact_id}
                                                        onChange={(e) => {
                                                            const updatedTasks = [
                                                                ...jobdetails.opportunities,
                                                            ];
                                                            const taskIndex = updatedTasks.findIndex(
                                                                (task) => task.id === res.id
                                                            );
                                                            updatedTasks[taskIndex].contact_id =
                                                                e.target.value;

                                                            setJobDetails({
                                                                ...jobdetails,
                                                                opportunities: updatedTasks,
                                                            });
                                                        }}
                                                    >
                                                        {jobdetails?.contact?.map((res) => (
                                                            <option key={res.id} value={res.id}>
                                                                {res.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                Lead info
                                                <div>
                                                    <select
                                                        className="form-select"
                                                        value={res.lead_info}
                                                        onChange={(e) => {
                                                            console.log(e.target.value)
                                                            const updatedTasks = [
                                                                ...jobdetails.opportunities,
                                                            ];
                                                            const taskIndex = updatedTasks.findIndex(
                                                                (task) => task.id === res.id
                                                            );
                                                            updatedTasks[taskIndex].lead_info =
                                                                e.target.value;

                                                            setJobDetails({
                                                                ...jobdetails,
                                                                opportunities: updatedTasks,
                                                            });
                                                        }}
                                                    >
                                                        {leadTypes?.map((res) => (
                                                            <option key={res.id} value={res.options}>
                                                                {res.options}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div>
                                                    <label htmlFor="notesInput" className="form-label">
                                                        Notes
                                                    </label>
                                                    <textarea
                                                        className="form-control"
                                                        id="notesInput"
                                                        style={{ overflow: "hidden" }}
                                                        value={res.notes}
                                                        onChange={(e) => {
                                                            const updatedTasks = [
                                                                ...jobdetails.opportunities,
                                                            ];
                                                            const oppindex = updatedTasks.findIndex((opp) => opp.id == res.id);
                                                            console.log(updatedTasks[oppindex]);
                                                            updatedTasks[oppindex].notes = e.target.value;
                                                            setJobDetails({
                                                                ...jobdetails,
                                                                opportunities: updatedTasks,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                <div className="hstack gap-2 mt-2">
                                                    <Button
                                                        className="btn btn-light"
                                                        onClick={() => {
                                                            handleoppEdit();
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        className="btn btn-info"
                                                        onClick={() => {
                                                            Updateopportunity(res);
                                                        }}
                                                    >
                                                        Update
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='p-3' style={{ backgroundColor: "#eef7fb" }}>
                                        <Row >
                                            <Col xl={5}>
                                                <p className="mb-0 text-muted">{tripDetails.start_city}</p>
                                            </Col>
                                            <Col xl={3}>
                                                <span>-</span>
                                            </Col>
                                            <Col xl={4}>
                                                <p className="mb-0 text-muted">{tripDetails.finish_city}</p>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3' >
                                            <Col xl={5}>
                                                <p className="mb-0 text-muted">{tripDetails.start_date}</p>
                                            </Col>
                                            <Col xl={3}>
                                                <span>-</span>
                                            </Col>
                                            <Col xl={4}>
                                                <p className="mb-0 text-muted">{tripDetails.finish_date}</p>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col xl={5}>
                                                <p className="mb-0 text-muted">{tripDetails.license_plate_number}</p>
                                            </Col>
                                            <Col xl={3}>
                                                <span>-</span>
                                            </Col>
                                            <Col xl={4}>
                                                <p className="mb-0 text-muted">{tripDetails.vehicle_name}</p>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <p className='text-center'>{`${tripDetails.first_name} ${tripDetails.last_name}`}</p>
                                        </Row>

                                    </div>
                                    {isLoading &&
                                        (
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    width: '100%',
                                                    height: '100%',
                                                    background: 'rgba(255, 255, 255, 0.8)',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    zIndex: 9999,
                                                }}
                                            >
                                                <Loader />
                                            </div>
                                        )}
                                </div>

                            </CardBody>
                        </Card>
                    </Col>

                    <Col xl={9}>
                        <Card className='p-3'>
                            <div className='mt-3 mb-3'>
                                <h6>Assigned Jobs</h6>
                            </div>
                            <div className='d-flex align-items-center justify-content-between p-1 pb-3'>
                                <h5>Jobs</h5>
                                <div className='d-flex'>
                                    <div>
                                        <button className='d-flex btn btn-primary ' onClick={generateWayBill}><i className='ri-clipboard-fill px-1'></i><p>Generate Waybill</p></button>
                                    </div>

                                    <div className='mx-3'>
                                        <button className="btn btn-primary" onClick={downloadWayBill}><i className='ri-file-download-fill px-1'></i>Download</button>
                                    </div>
                                </div>
                            </div>
                            <TableContainer
                                columns={columns}
                                data={orderList || []}
                                isGlobalFilter={true}
                                isAddUserList={false}
                                customPageSize={50}
                                divClass="table-responsive table-card mb-1"
                                tableClass="align-middle table-nowrap"
                                theadClass="table-light text-muted text-uppercase"
                                handleOrderClick={handleOrderClicks}
                                isOrderFilter={true}
                                SearchPlaceholder="Search for Job ID, customer, Payment status or something..."
                            />
                            <div>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Card className='p-3'>
                    <Row className='mt-3'>
                        <Col lg={4}>
                            <div className="mb-3">
                                <Label className="form-label"> Start Date</Label>
                                <Flatpickr
                                    className="form-control" placeholder='Start Date'
                                    options={{
                                        dateFormat: "d/m/Y",
                                    }}
                                    value={createdDateStart}
                                    onChange={(selectedDates) => {
                                        if (selectedDates.length === 1) {
                                            setCreatedDateStart(moment(selectedDates[0]).format('DD/MM/YYYY'));
                                        } else {
                                            setCreatedDateStart(null);
                                        }
                                    }}
                                />
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="mb-3">
                                <Label className="form-label"> End Date</Label>
                                <Flatpickr
                                    className="form-control"
                                    value={createdDateEnd}
                                    options={{
                                        dateFormat: "d/m/Y",
                                    }}
                                    placeholder='End Date'
                                    onChange={(selectedDates) => {
                                        if (selectedDates.length === 1) {
                                            setCreatedDateEnd(moment(selectedDates[0]).format('DD/MM/YYYY'));
                                        } else {
                                            setCreatedDateEnd(null);
                                        }
                                    }} />

                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="text-start">
                                <button type="submit" className="btn form-btn-marg btn-primary" onClick={handleApply}>Apply</button>
                                <button type="submit" className="btn form-btn-marg btn-primary">Reset</button>
                            </div>

                        </Col>
                        <div>
                            <TableContainer
                                columns={searchColumns}
                                data={searchTableData || []}
                                isGlobalFilter={true}
                                isAddUserList={false}
                                customPageSize={5}
                                divClass="table-responsive table-card mb-1"
                                tableClass="align-middle table-nowrap"
                                theadClass="table-light text-muted text-uppercase"
                                handleOrderClick={handleOrderClicks}
                                isOrderFilter={true}
                                SearchPlaceholder="Search for Job ID, customer, Payment status or something..."
                            />
                            {isLoadingTable &&
                                (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            background: 'rgba(255, 255, 255, 0.8)',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            zIndex: 9999,
                                        }}
                                    >
                                        <Loader />
                                    </div>
                                )}
                        </div>
                    </Row>
                </Card>

            </Container>
            <ToastContainer closeButton={false} limit={1} theme="light" />
        </div>
    )
}

export default BackloadingDetailsPage
