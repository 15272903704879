import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Accordion, AccordionItem, Button, Card, CardBody, CardHeader, Col, Collapse, Table, Form, Label, Input, Container, Row, Modal, ModalBody, Spinner } from 'reactstrap';
import TableRows from "./TableRow";
import Flatpickr from "react-flatpickr";
import axios from 'axios';
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addNewInvoice, deleteInvoice } from '../../../store/actions';
import { ClipLoader } from 'react-spinners';
import { jsx } from '@emotion/react'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import moment from 'moment';
import classnames from "classnames";
import api from "../../../config";
import Loader from "../../../Components/Common/Loader";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import StripePayment from '../../../Components/SetupStripe';
import StoragePayment from '../../../Components/StorageStripe'
const override = jsx`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const stripePromise = loadStripe('pk_test_E0qVMK2JSQXGItx8GHNrlqX9');

export const Invoice = ({data, onChangeData}) => {
  const params = useParams();
  const storage_id = params.id;
  const job_id = data?.storage?.job_id;
  var total_excl_tax = 0;
  var total_tax = 0;
  var total_payments = 0;

  //rowsdata
  const [isLoader, setLoader] = useState(true);
  const [rowsData, setRowsData] = useState([]);
  const [postData, setData] = useState([]);
  const [invoice, setInvoiceList] = useState([]);
  const [invoicecharges, setInvoiceCharges] = useState([]);
  const [expandedinvoiceId, setExpandedInvoiceId] = useState('');
  const [expandedpaymentId, setExpandedPaymentId] = useState('');
  const [selectedinvoice, setSelectedInvoiceData] = useState([]);
  const [selectedcharges, setSelectedChargesData] = useState([]);
  const [selectedpayment, setSelectedPaymentData] = useState([]);
  const [Addnewpayment, setAddPayment] = useState(false);
  const [paymentmodal, setPaymentModal] = useState(false);
  const [emailmodal, setEmailModal] = useState(false);
  const [modal, setOpenModal] = useState(false);
  const [Addnewinvoice, setAddInvoice] = useState(false);
  const [invoicename, setInvoiceName] = useState("");
  const [producttype, setProductType] = useState("");
  const [invoicepid, setProductId] = useState("");
  const [invoicedesc, setInvoiceDesc] = useState("");
  const [invoiceprice, setInvoicePrice] = useState("");
  const [invoicetax, setInvoiceTax] = useState("");
  const [invoiceamt, setInvoiceAmount] = useState("");
  const [invoiceqty, setInvoiceQuantity] = useState("");
  const [expandedInvoiceChargeId, setEditCharges] = useState("");
  const [addnewCharges, setAddNewCharges] = useState("");
  const [newcharge, setNewCharge] = useState("");
  const [newchargesAmt, setChargesAmt] = useState("");
  const [newchargetax, setNewChargeTax] = useState(0);
  const [newchargeunit, setNewChargeUnit] = useState("");
  const [newchargeqty, setNewChargeQty] = useState(1);
  const [newchargeamt, setNewChargeAmt] = useState("");
  const [newchargetype, setNewChargetype] = useState("");
  const [newchargepid, setNewChargepid] = useState("");
  const [discounttype, setDiscountType] = useState('');
  const [discountvalue, setDiscountValue] = useState('');
  const [paymentmode, setPaymentMode] = useState('');
  const [paymentdate, setPaymentDate] = useState('');
  const [paymentdesc, setPaymentDesc] = useState('');
  const [paymentamount, setPaymentAmount] = useState('');
  const [paymentItems, setPaymentItems] = useState('');
  const [joblegs, setJobLegs] = useState('');
  const [expandedjobLegid, setEditJobLeg] = useState('');
  const [installmentPayment, setInstallmentPayment] = useState(false);
  // recurring
  const [isChecked, setIsChecked] = useState(false);
   const [frequency, setFrequency] = useState([]);
   const [recurring, setRecurring] = useState([]);
   const [billing_frequency, setBillingFrequency] = useState([]);
   const [billing_start_date, setBillingStartDate] = useState([]);
   const [billing_end_date, setBillingEndDate] = useState([]);
   const [recurring_details, setRecurringDetails] = useState([]);
   const [payment_invoice_number, setPaymentInvoiceNumber]= useState([]);
   const [payment_file_original_name, setPaymentFileOriginalName] = useState([]);
   const [payment_due_date, setPaymentDueDate] = useState([]);
  const [col1, setcol1] = useState(true);
  //const isEdit = true;
  const t_col1 = () => {
    setcol1(!col1);
  };
  const [col2, setcol2] = useState(false);
  const t_col2 = () => {
    setBillingFrequency("Weekly");
    setcol2(!col2);
  };
   const [col3, setcol3] = useState(false);
  const t_col3 = () => {
    setcol3(!col3);
  };
  const [col4, setcol4] = useState(false);
  const t_col4 = () => {
    setcol4(!col4);
  };
  const t_editinvoice = (val) =>{
    onChangeData();
    setExpandedInvoiceId(val);
  }

  const t_editCharges = (data) =>{
    setEditCharges(data)
  }

  const t_deleteItem = (val, data = "") =>{
  if(val == "invoice"){
    setSelectedInvoiceData(data);
    setSelectedChargesData();
    setOpenModal(true);
  }else if(val == "charges"){
    setSelectedChargesData(data);
    setSelectedInvoiceData();
    setOpenModal(true);
  }else{
    setSelectedChargesData();
    setSelectedInvoiceData();
    setOpenModal(false);
  }
  }
  const send_emailToCustomer = (val) =>{
    onChangeData();
    setEmailModal(true);
  }
  const cancel_emailToCustomer = (val) =>{
    onChangeData();
    setEmailModal(false);
  }

  const t_editPayment = (val) =>{
    console.log(val);
    setExpandedPaymentId(val);
  }

  const t_deletePayment = (val = "") =>{
    if(val != ""){
    setSelectedPaymentData(val);
    setPaymentModal(true);
  }else{
    setPaymentModal(false);
  }
  }

  const t_addnewpayment= (val) =>{
    //getData();
    if(Addnewpayment == true){
      setPaymentAmount();
      setPaymentDate();
      setPaymentDesc();
      setPaymentMode();
      setAddPayment(false);
    }else{
      setAddPayment(true);
    }
  }
   const Email_invoice_to_customer = (val) =>{
    var postData = {
      "id":data?.invoice.id,
      "job_id": job_id,
      "invoice_id": data?.invoice.id,
    }
    axios.post("api/ajaxInvoiceTemplate", postData).then((res)=>{
      if(res.error == 0){
        toast.success(res.message);
        onChangeData();
        setEmailModal(false);
      }
      else{
        toast.error("Something went wrong");
      }
    })
   }

  const SelectDiscount = (val) =>{
    if(val == "cancel"){
      data.invoice.discount = 0;
      setDiscountType("");
    }else if(val == "save"){
      setDiscountType("");
    }
    else{
      setDiscountType(val);
    }
  }
  const CancelStripe = (val) =>{
    var postData = {
      "job_id": job_id,
      "invoice_id": data?.invoice.id,
      "storage_id":storage_id,
    }
    axios.post("api/cancelStripSetup", postData).then((res)=>{
      if(res.error == 0){
        toast.success(res.message);
        onChangeData();
        t_editPayment();
      }
      else{
        toast.error("Something went wrong");
      }
    })
  }

  const UpdatePayment = (val) =>{
    console.log(val);
    var postData = {
      "id":val.id,
      "job_id": job_id,
      "invoice_id": data?.invoice.id,
      "gateway":val.gateway,
      "description":val.remarks,
      "amount":val.amount,
      "paid_on":moment(val.paid_on).format("YYYY-MM-DD"),
      "sys_job_type": "Moving_Storage"
    }
    axios.post("api/ajaxUpdatePayment", postData).then((res)=>{
      if(res.error == 0){
        toast.success(res.message);
        onChangeData();
        t_editPayment();
      }
      else{
        toast.error("Something went wrong");
      }
    })
  }

  const SavePayment = () =>{
    var postData = {
      "job_id": job_id,
      "invoice_id": data?.invoice.id,
      "gateway":paymentmode,
      "description":paymentdesc,
      "amount":paymentamount,
      "storage_id":storage_id,
      "paid_on":paymentdate,
    }
    axios.post("api/ajaxSaveStoragePayment", postData).then((res)=>{
      if(res.error == 0){
        toast.success(res.message);
        onChangeData();
        t_addnewpayment();
      }
      else{
        toast.error("Something went wrong");
      }
    })
  }

  const deletePayment = () =>{
    console.log(selectedpayment);
    var postData = {
      "job_id": job_id,
      "invoice_id": selectedpayment.invoice_id,
      "id":selectedpayment.id,
      "sys_job_type":"Moving_Storage"
    }
    axios.post("api/ajaxDestroyPaymentItem", postData).then((res)=>{
      if(res.error == 0){
        toast.success(res.message);
        onChangeData();
        t_deletePayment();
      }
      else{
        toast.error("Something went wrong");
      }
    })
  }

  const addTableRows = () => {
    if(Addnewinvoice){
      setInvoiceName();
      setInvoiceDesc();
      setInvoicePrice();
      setInvoiceAmount();
      setInvoiceQuantity();
      setAddInvoice(false);
    }else{
      setAddInvoice(true);
    }
  }

  const addNewCharges = () =>{
    if(addnewCharges){
      setNewCharge();
      setChargesAmt();
      setNewChargeTax();
      setNewChargeUnit();
      setNewChargeAmt();
      setNewChargetype();
      setNewChargepid();
      setAddNewCharges(false)
    }else{
      setAddNewCharges(true);
    }

  }

  useEffect(()=>{
    getData();
    setFrequency(data?.billing_frequency);
    setRecurring(data?.storage?.recurring);
    setBillingFrequency(data?.storage?.billing_frequency);
    const start_date = data?.storage?.billing_start_date;
    const parsedfromDate = moment(start_date, 'DD/MM/YYYY').toDate();
    const formattedfromDate = moment(parsedfromDate).format('DD MMM, YYYY');
    setBillingStartDate(formattedfromDate);
    const end_date = data?.storage?.billing_end_date;
    const parsedendDate = moment(end_date, 'DD/MM/YYYY').toDate();
    const formattedendDate = moment(parsedendDate).format('DD MMM, YYYY');
    setBillingEndDate(formattedendDate);
  }, [data])

  function getData(){
    console.log(data);
    setData(data);
    setInvoiceList(data?.invoice_items);
    setInvoiceCharges(data?.invoice_charges);
    setPaymentItems(data?.payment_items);
    setJobLegs(data?.job_legs);
    if (data) {
      setTimeout(function () {
        setLoader(false);
      }, 2000);
    }
}

  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  }
  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);
  }
  const handleInvoiceSelect = (data, value) =>{
    console.log(value)
  }

  const InvoiceChargeUpdate = (data, price) =>{
    var postDatas = {
      "invoice_id": data.invoice_id.toString(),
      "price": price
    }
    axios.post("api/ajaxCalculateChargePrice", postDatas).then((res)=>{
      const updateddata =[...invoicecharges];
      const taskIndex = updateddata.findIndex((task) => task.id === data.id);
      var tax_amount = postData.taxs.map((val) => (
      (data.tax_id == val.id) ? val.rate_percent  : ""));
      updateddata[taskIndex].unit_price = res;

      console.log((1+ tax_amount[0] / 100));
      updateddata[taskIndex].amount = parseFloat((updateddata[taskIndex].unit_price * updateddata[taskIndex].quantity) * (1+ tax_amount[0] / 100)).toFixed(2);
      setInvoiceCharges(updateddata);
    })
  }
  const EditInvoice = (val) =>{
    var postData = {
      "id":val.id,
      "job_id": job_id,
      "storage_id": storage_id,
      "product_id":val.product_id,
      "name":val.item_name,
      "description":val.item_summary,
      "tax_id":val.tax_id,
      "unit_price":val.unit_price,
      "quantity":val.quantity,
      "type":val.type,
      "amount":val.amount,
    }
    axios.post("api/ajaxUpdateStorageInvoice", postData).then((res)=>{
      if(res.error == 0){
        toast.success(res.message);
        onChangeData();
        t_editinvoice();
      }
      else{
        toast.error("Something went wrong");
      }
    })
  }
  const SaveInvoice = () =>{
    var postData = {
      "job_id": job_id,
      "storage_id": storage_id,
      "product_id":invoicepid,
      "name":invoicename,
      "description":invoicedesc,
      "tax_id":invoicetax,
      "unit_price":invoiceprice,
      "quantity":invoiceqty,
      "type":producttype,
      "amount":invoiceamt,
    }
    if (postData.name === "" || postData.name === undefined || postData.name === null) {
      toast.error("Enter the Item Name", { theme: "light" });
    }
    else if (postData.unit_price === "" || postData.unit_price === undefined || postData.unit_price === null) {
      toast.error("Enter the Unit Price", { theme: "light" });
    }
    else if (postData.quantity === '' || postData.quantity === undefined || postData.quantity === null) {
      toast.error("Enter the Quantity", { theme: "light" });
    }
    else if (postData.description === "" || postData.description === undefined || postData.description === null) {
      toast.error("Enter the Description", { theme: "light" });
    }
    else {
    axios.post("api/ajaxSaveStorageInvoice", postData).then((res)=>{
      if(res.error == 0){
        toast.success(res.message);
        onChangeData();
        addTableRows();
      }
      else{
        toast.error("Something went wrong");
      }
    })
  }
  }

  const deleteInvoice = () =>{
    console.log(selectedinvoice);
  if(selectedinvoice && selectedinvoice !=""){
    var postData = {
      "job_id": job_id,
      "invoice_id": selectedinvoice?.invoice_id,
      "id":selectedinvoice.id,
      "storage_id":storage_id
    }
    axios.post("api/ajaxDestroyStorageInvoiceItem", postData).then((res)=>{
      if(res.error == 0){
        toast.success(res.message);
        onChangeData();
        t_deleteItem();
      }
      else{
        toast.error("Something went wrong");
      }
    })
  }else if(selectedcharges && selectedcharges !=""){
    var postData = {
      "job_id": job_id,
      "invoice_id": selectedcharges.invoice_id,
      "id":selectedcharges.id,
      "storage_id":storage_id
    }
    axios.post("api/ajaxDestroyStorageInvoiceItem", postData).then((res)=>{
      if(res.error == 0){
        toast.success(res.message);
        onChangeData();
        t_deleteItem();
      }
      else{
        toast.error("Something went wrong");
      }
    })
  }
  }

  const UpdateCharges = (data) =>{
    var postData = {
      "id": data.id,
      "job_id": job_id,
      "storage_id": storage_id,
      "product_id": data.product_id,
      "name": data.item_name,
      "tax_id": data.tax_id,
      "unit_price": data.unit_price,
      "quantity": data.quantity,
      "type": data.type,
      "amount": data.amount
    }
    axios.post("/api/ajaxUpdateStorageInvoice", postData).then((res)=>{
      console.log(res);
      if(res.error == 0){
        toast.success(res.message);
        onChangeData();
        t_editCharges()
      }else{
        toast.error("Something went wrong");
      }
    })
  }

  const SaveCharges = (data) =>{
    if(newchargepid == "" || newcharge == "" || newchargetax == "" || newchargeunit == "" || newchargeqty == "" || newchargetype =="" || newchargeamt == ""){
      toast.error("Please fill all the necessary details");
      return false;
    }
    var sendData = {
      "job_id": job_id,
      "storage_id": storage_id,
      "product_id": newchargepid,
      "name": newcharge,
      "tax_id": newchargetax,
      "unit_price": newchargeunit,
      "quantity": newchargeqty,
      "type": newchargetype,
      "amount": newchargeamt
    }
    axios.post("/api/ajaxSaveStorageInvoice", sendData).then((res)=>{
      console.log(res);
      if(res.error == 0){
        toast.success(res.message);
        onChangeData();
        addNewCharges()
      }else{
        toast.error("Something went wrong");
      }
    })
  }

  const UpdateJobLegs = (value)=>{
    console.log(value);
    var postData = {
      "id": value.id,
      "job_id": value.job_id,
      "actual_start_time": value.actual_start_time,
      "actual_finish_time": value.actual_finish_time,
      "emp_travel_time": value.emp_travel_time
    }
    axios.post("api/ajaxUpdateActualhours", postData).then((res)=>{
      console.log(res);
      if(res.error == 0){
        toast.success(res.message);
        onChangeData();
        setEditJobLeg();
      }else{
        toast.error("Something went wrong");
      }
    })
  }

  function calculateTimeDuration(actualStartTime, actualFinishTime) {
    if (actualStartTime && actualFinishTime) {
      const start = moment(actualStartTime);
      const end = moment(actualFinishTime);
      const minutes = end.diff(start, 'minutes') / 60;
      let totalHours = Math.ceil(minutes * 4) / 4;
      totalHours = totalHours.toFixed(2);
      return parseFloat(totalHours);
    } else {
      return 0;
    }
  }

  const UpdateInstallment = () =>{
    if(installmentPayment){
      var sendData = {
        "invoice_id": postData.invoice.id.toString(),
        "installment_value": "N"
      }
      setInstallmentPayment(false);
      axios.post("api/ajaxUpdateInstallment", sendData).then((res)=>{
        if(res.status == 0){
          toast.success(res.msg);
        }else{
          toast.error("Something went wrong");
        }
      })
    }else{
      var sendData = {
        "invoice_id": postData.invoice.id.toString(),
        "installment_value": "Y"
      }
      setInstallmentPayment(true);
      axios.post("api/ajaxUpdateInstallment", sendData).then((res)=>{
        if(res.status == 0){
          toast.success(res.msg);
        }else{
          toast.error("Something went wrong");
        }
      })
    }
  }
  const SaveRecurring = () => {
    if(billing_start_date === undefined || billing_start_date === "" || billing_start_date === "Invalid date") {
      toast.error("Please Select Billing Start Date");
      return;
    }else if (billing_frequency === undefined || billing_frequency === '' || billing_frequency === 'null' || billing_frequency === null) {
      toast.error("Please Select Billing Frequency");
      return;
    }
    const start_date = moment(billing_start_date, 'DD MMM, YYYY').format('YYYY-MM-DD');
    let end_date;
    if (billing_end_date !== null && billing_end_date !== undefined && billing_end_date !== "" && billing_end_date !== "Invalid date") {
      end_date = moment(billing_end_date, 'DD MMM, YYYY').format('YYYY-MM-DD');
    } else {
      end_date = "";
    }
    var postData = {
      "job_id": job_id,
      "invoice_id": data?.invoice.id,
      "storage_id" : storage_id,
      "recurring": recurring,
      "billing_frequency": billing_frequency,
      "billing_start_date": start_date,
      "billing_end_date": end_date,
    };
    var invoiceData = {
      "job_id": job_id,
      "invoice_id": data?.invoice.id,
      "storage_id" : storage_id,
    };
    var pdfData = {
      "job_id": job_id,
      "type" : 'Moving_Storage',
      "storage_id" : storage_id,
    };
    const ajaxEndpoint = "api/ajaxRecurringSave";
    const givenDate = new Date(start_date);
    const currentDate = new Date();

    if (currentDate.toDateString() === givenDate.toDateString()) {
      axios.post(ajaxEndpoint, postData).then((res) => {
      if (res.error === 0) {
        toast.success(res.message);
          axios.post("api/ajaxUpdateStorageRegenrateInvoice", invoiceData).then((res)=>{
            if(res.error == 0){
              toast.success(res.message);
              axios.post("api/ajaxgenerateStorageInvoice", pdfData).then((res)=>{
                if(res.error == 0){
                  toast.success(res.message);
                  onChangeData();
                }else{
                  toast.error("Something went wrong");
                }
              })
            }else{
              toast.error("Something went wrong");
            }
          })
        t_col4();
        onChangeData();
      } else {
        toast.error(res.message);
      }
    });
    } else {
      axios.post(ajaxEndpoint, postData).then((res) => {
      if (res.error === 0) {
        toast.success(res.message);
        onChangeData();
          t_col4();
      } else {
        toast.error(res.message);
      }
    });
    }
  };
  const UpdateRecurring = () => {
    console.log(billing_end_date);
    if(billing_start_date === undefined || billing_start_date === "" || billing_start_date === "Invalid date") {
      toast.error("Please Select Billing Start Date");
      return;
    }else if (billing_frequency === undefined || billing_frequency === '' || billing_frequency === 'null' || billing_frequency === null) {
      toast.error("Please Select Billing Frequency");
      return;
    }
    const start_date = moment(billing_start_date, 'DD MMM, YYYY').format('YYYY-MM-DD');
    let end_date;
    if (billing_end_date !== null && billing_end_date !== undefined && billing_end_date !== "" && billing_end_date !== "Invalid date") {
      console.log('test');
      end_date = moment(billing_end_date, 'DD MMM, YYYY').format('YYYY-MM-DD');
    } else {
      end_date = "";
    }
    var postData = {
      "job_id": job_id,
      "invoice_id": data?.invoice.id,
      "storage_id" : storage_id,
      "recurring": recurring,
      "billing_frequency": billing_frequency,
      "billing_start_date": start_date,
      "billing_end_date": end_date,
    };
    var invoiceData = {
      "job_id": job_id,
      "invoice_id": data?.invoice.id,
      "storage_id" : storage_id,
    };
    var pdfData = {
      "job_id": job_id,
      "type" : 'Moving_Storage',
      "storage_id" : storage_id,
    };
    const ajaxEndpoint = "api/ajaxRecurringSave";
    const givenDate = new Date(start_date);
    const currentDate = new Date();

    if (currentDate.toDateString() === givenDate.toDateString()) {
      axios.post(ajaxEndpoint, postData).then((res) => {
      if (res.error === 0) {
        toast.success(res.message);
          axios.post("api/ajaxUpdateStorageRegenrateInvoice", invoiceData).then((res)=>{
            if(res.error == 0){
              toast.success(res.message);
              axios.post("api/ajaxgenerateStorageInvoice", pdfData).then((res)=>{
                if(res.error == 0){
                  toast.success(res.message);
                  onChangeData();
                  t_col4();
                  t_col2();
                }else{
                  toast.error("Something went wrong");
                }
              })
            }else{
              toast.error("Something went wrong");
            }
          })
          t_col2();
          t_col4();
          onChangeData();
      } else {
        toast.error(res.message);
      }
    });
    } else {
      axios.post(ajaxEndpoint, postData).then((res) => {
      if (res.error === 0) {
        toast.success(res.message);
        onChangeData();
          t_col2();
      } else {
        toast.error(res.message);
      }
    });
    }
  };
  useEffect(() => {
    if (data?.storage?.recurring === "Y") {
      setcol1(col1);
  }
  else{
    setcol1(!col1);
  }
  }, []);
  const GenerateInvoice = () => {
    var postData = {
      "job_id": job_id,
      "invoice_id": data?.invoice.id,
      "storage_id" : storage_id,
      "recurring": recurring,
      "billing_frequency": null,
      "billing_start_date": null,
      "billing_end_date": null,
    };
    var invoiceData = {
      "job_id": job_id,
      "invoice_id": data?.invoice.id,
      "storage_id" : storage_id,
    };
    var pdfData = {
      "job_id": job_id,
      "type" : 'Moving_Storage',
      "storage_id" : storage_id,
    };
    const ajaxEndpoint = "api/ajaxRecurringSave";

    if (recurring === 'N') {
      axios.post(ajaxEndpoint, postData).then((res) => {
      if (res.error === 0) {
        toast.success(res.message);
          axios.post("api/ajaxUpdateStorageRegenrateInvoice", invoiceData).then((res)=>{
            if(res.error == 0){
              toast.success(res.message);
              axios.post("api/ajaxgenerateStorageInvoice", pdfData).then((res)=>{
                if(res.error == 0){
                  toast.success(res.message);
                  onChangeData();
                  //t_col4();
                }else{
                  toast.error("Something went wrong");
                }
              })
            }else{
              toast.error("Something went wrong");
            }
          })
          onChangeData();
          //t_col4();
      } else {
        toast.error(res.message);
      }
    });
    }
  };
  const handleClear = () => {
  setBillingEndDate(null); // Clear the value
};
const finishDateClear = () => {
  setBillingEndDate(null); // Clear the value
};
const item_id = data?.invoice_items?.map((res) => res.id);
const recurringValue = data?.storage?.recurring;
const displayValue = recurringValue === "Y" ? "ON" : recurringValue === "N" ? "OFF" : "";
  return (<>
    <div >
      {isLoader && <><div
        style={{
          position: 'absolute',
          // top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(255, 255, 255, 0.8)',
          // display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 9999,
        }}
      >
        <Loader />
      </div></>}
      <Card  >
        <div className='m-3'>
          {/* <h5> Invoice #321 - 0</h5> */}
          <Table className='table-bordered mt-3'>
            <thead className='bg-soft-purple'>
              <tr>
                <th>Item Name & Description</th>
                <th>Tax</th>
                <th>Unit Price</th>
                <th>QTY</th>
                <th>Total Inc Tax</th>
                <th></th>

              </tr>
            </thead>
            <tbody>
            {invoice?.map((res)=>{
              total_excl_tax += (res.unit_price*res.quantity);
              total_tax += res.amount - (res.unit_price*res.quantity);
               return ( <>{(expandedinvoiceId != res.id) && <tr key={res.id}>
               <td>
                <span>{res.item_name}</span><br/>
                <span>{res.item_summary}</span>
               </td>
               <td>{postData.taxs.map((val) => (
              (res.tax_id == val.id) ? val.tax_name  : ""))}</td>
               <td style={{ textAlign: 'right' }}>{postData?.global.currency_symbol} {parseFloat(res.unit_price).toFixed(2)}</td>
               <td style={{ textAlign: 'right' }}>{parseFloat(res.quantity).toFixed(2)}</td>
                <td style={{ textAlign: 'right' }}>{postData?.global.currency_symbol} {parseFloat(res.amount).toFixed(2)}</td>
               <td>
                 <div className='hstack gap-2'>
                   <button className="btn btn-sm btn-soft-info edit-list" onClick={()=> t_editinvoice(res.id)}>
                     <i className="bx bxs-pencil fs-12 pt-1"></i>
                   </button>
                   <button className="btn btn-sm btn-soft-danger edit-list" onClick={()=> t_deleteItem("invoice", res)}>
                     <i className="bx bxs-trash fs-12 pt-1"></i>
                   </button>
                 </div>
               </td>
             </tr>}
             {(expandedinvoiceId == res.id) && <tr key={res.id}>
                  <td>
      <span>
        <Input
          type="text"
          list="data"
          value={res.item_name}
          onChange={(e) => {
            const enteredValue = e.target.value.split(",");
            const updateddata = [...invoice];
            const taskIndex = updateddata.findIndex((task) => task.id === res.id);

            if (enteredValue.length > 1) {
              const invoiceData = postData?.products?.find((val) => val.id === parseInt(enteredValue[1]));

              if (invoiceData.length > 0) {
                updateddata[taskIndex].item_name = invoiceData[0].name;
                updateddata[taskIndex].item_summary = invoiceData[0].description;
                updateddata[taskIndex].tax_id = invoiceData[0].tax_id;
                updateddata[taskIndex].unit_price = parseFloat(invoiceData[0].price).toFixed(2);
                updateddata[taskIndex].quantity = 1;
                updateddata[taskIndex].amount = parseFloat(invoiceData[0].total_amount).toFixed(2);
              }
            } else {
              updateddata[taskIndex].item_name = enteredValue[0];
            }

            setInvoiceList(updateddata);
          }}
        />
      </span>
      <datalist id="data">
        {postData?.products.map((item, key) => (
          <option key={key} value={item.name} data-id={item.id}>
            {item.name}
          </option>
        ))}
      </datalist>
              <br/>
              <span><Input value={res.item_summary} type="text" placeholder="Description" onChange={(e)=>{
                const updateddata =[...invoice];
                const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                updateddata[taskIndex].item_summary = e.target.value;
                setInvoiceList(updateddata);
              }} /></span>
             </td>
             <td><select className="form-select" value={res.tax_id} onChange={(e)=>{
                const updateddata =[...invoice];
                const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                updateddata[taskIndex].tax_id = e.target.value;

                var tax_amount = postData.taxs.map((val) => (
                (e.target.value == val.id) ? val  : ""));
                var taxrate = (tax_amount.length > 0 && tax_amount[0] != "") ? tax_amount[0].rate_percent : 0;
                var totalAmount = (parseFloat(updateddata[taskIndex].unit_price).toFixed(2) * parseFloat(updateddata[taskIndex].quantity)).toFixed(2) * (1 + parseFloat(taxrate).toFixed(2) / 100);
                updateddata[taskIndex].amount = parseFloat(totalAmount).toFixed(2);
                setInvoiceList(updateddata);
              }}>
                <option value="0"></option>
              {postData?.taxs.map((res) =>{
                return(<option key={res.id} value={res.id}>{res.tax_name}</option>)
              })}
              </select></td>
             <td><Input type="number" value={res.unit_price} step={0.00} onChange={(e)=>{
                const updateddata = [...invoice];
                const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                updateddata[taskIndex].unit_price = e.target.value;
                var tax_amount = postData.taxs.map((val) => (
                  (res.tax_id == val.id) ? val  : ""));
                  if(tax_amount.length > 0){
                  tax_amount = tax_amount[0];
                  }
                var totalAmount = (parseFloat(updateddata[taskIndex].unit_price).toFixed(2) * parseFloat(updateddata[taskIndex].quantity)).toFixed(2) * (1 + parseFloat(tax_amount.rate_percent).toFixed(2) / 100);
                updateddata[taskIndex].amount = parseFloat(totalAmount).toFixed(2);
                setInvoiceList(updateddata);
              }}/></td>
             <td><Input type="number" value={res.quantity} step={0.00} onChange={(e)=>{
                const updateddata =[...invoice];
                const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                updateddata[taskIndex].quantity = e.target.value;
                var tax_amount = postData.taxs.map((val) => (
                  (res.tax_id == val.id) ? val  : ""));
                  if(tax_amount.length > 0){
                  tax_amount = tax_amount[0];
                  }
                var totalAmount = (parseFloat(updateddata[taskIndex].unit_price).toFixed(2) * parseFloat(updateddata[taskIndex].quantity)).toFixed(2) * (1 + parseFloat(tax_amount.rate_percent).toFixed(2) / 100);
                updateddata[taskIndex].amount = parseFloat(totalAmount).toFixed(2);
                setInvoiceList(updateddata);
              }}/></td>
             <td><Input type="number" value={res.amount} step={0.00} onChange={(e)=>{
                const updateddata =[...invoice];
                const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                updateddata[taskIndex].amount = e.target.value;
                var tax_amount = postData.taxs.map((val) => (
                  (res.tax_id == val.id) ? val  : ""));
                  if(tax_amount.length > 0){
                  tax_amount = tax_amount[0];
                  }
                var unitPrice = ((parseFloat(updateddata[taskIndex].amount).toFixed(2) / parseFloat(updateddata[taskIndex].quantity)).toFixed(2)) / (1 + parseFloat(tax_amount.rate_percent).toFixed(2) / 100);
                updateddata[taskIndex].unit_price = parseFloat(unitPrice).toFixed(2);
                setInvoiceList(updateddata);
              }}/></td>
             <td>
               <div className='hstack gap-2'>
               <button className="btn btn-sm btn-light edit-list" onClick={()=> t_editinvoice()}>
                   Cancel
                 </button>
                 <button className="btn btn-sm btn-success edit-list" onClick={()=> EditInvoice(res)}>
                   Update
                 </button>
               </div>
             </td>
           </tr>}</>)
            })}
            {(Addnewinvoice) && <tr>
             <td>
              <span>
                <Input
                  type="text"
                  list="data"
                  value={invoicename}
                  onChange={(e) => {
                    const { value } = e.target;
                    const dataList = document.getElementById("data");
                    const selectedValue = e.target.value;
                    const selectedItem = [...dataList.querySelectorAll('option')].find(option => option.value === selectedValue);
                    const enteredValue = selectedItem ? selectedItem.value : "";
                    const itemId = selectedItem ? selectedItem.dataset.id : "";
                    if (itemId) {
                      const invoiceData = postData?.products?.find((val) => val.id === parseInt(itemId));
                      console.log(invoiceData, "invoiceData");
                      if (invoiceData) {
                        setProductId(invoiceData.id);
                        setProductType(invoiceData.product_type);
                        setInvoiceName(invoiceData.name);
                        setInvoiceDesc(invoiceData.description);
                        setInvoiceTax(invoiceData.tax_id);
                        setInvoicePrice(parseFloat(invoiceData.price).toFixed(2));
                        setInvoiceQuantity(1);
                        setInvoiceAmount(parseFloat(invoiceData.total_amount).toFixed(2));
                      }
                    } else {
                      setInvoiceName(enteredValue);
                    }
                  }}
                />
              </span>
              <datalist id="data">
                {postData?.products.map((item) => (
                  <option key={item.id} value={item.name} data-id={item.id}>
                    {item.name}
                  </option>
                ))}
              </datalist>
              <br/>
              <span><Input type="text" value={invoicedesc} placeholder="Description" onChange={(e)=>{
                setInvoiceDesc(e.target.value);
              }} /></span>
             </td>
             <td><select className="form-select" value={invoicetax} onChange={(e)=>{
              var tax_amount = postData.taxs.map((val) => (
                (e.target.value == val.id) ? val  : ""));
                var taxrate = (tax_amount.length > 0 && tax_amount[0] != "") ? tax_amount[0].rate_percent : 0;
                var totalAmount = (parseFloat(invoiceprice).toFixed(2) * parseFloat(invoiceqty)).toFixed(2) * (1 + parseFloat(taxrate).toFixed(2) / 100);
                setInvoiceAmount(parseFloat(totalAmount).toFixed(2));
                setInvoiceTax(e.target.value);
              }}>
                <option value="0"></option>
              {postData?.taxs?.map((res) =>{
                return(<option key={res.id} value={res.id}>{res.tax_name}</option>)
              })}
              </select></td>
             <td><Input type="number" value={invoiceprice} onChange={(e)=>{
                setInvoicePrice(e.target.value);
                var tax_amount = postData.taxs.map((val) => (
                  (invoicetax == val.id) ? val  : ""));
                  if(tax_amount.length > 0){
                  tax_amount = tax_amount[0];
                  }
                var totalAmount = (parseFloat(e.target.value).toFixed(2) * parseFloat(invoiceqty).toFixed(2)) * (1 + parseFloat(tax_amount.rate_percent).toFixed(2) / 100);
                setInvoiceAmount(parseFloat(totalAmount).toFixed(2));
              }} step={0.00}/></td>
             <td><Input type="number" value={invoiceqty} step={0.00} onChange={(e)=>{
                setInvoiceQuantity(e.target.value);
                var tax_amount = postData.taxs.map((val) => (
                  (invoicetax == val.id) ? val  : ""));
                  if(tax_amount.length > 0){
                  tax_amount = tax_amount[0];
                  }
                var totalAmount = (parseFloat(invoiceprice).toFixed(2) * parseFloat(e.target.value)).toFixed(2) * (1 + parseFloat(tax_amount.rate_percent).toFixed(2) / 100);
                setInvoiceAmount(parseFloat(totalAmount).toFixed(2));
              }}/></td>
             <td><Input type="number" value={invoiceamt} step={0.00} onChange={(e)=>{
                setInvoiceAmount(e.target.value);
                  var tax_amount = postData.taxs.map((val) => (
                  (invoicetax == val.id) ? val  : ""));
                  if(tax_amount.length > 0){
                  tax_amount = tax_amount[0];
                  }
                  var unitPrice = ((parseFloat(e.target.value).toFixed(2) / parseFloat(invoiceqty)).toFixed(2)) / (1 + parseFloat(tax_amount.rate_percent).toFixed(2) / 100);
                setInvoicePrice(parseFloat(unitPrice).toFixed(2));
              }}/></td>
             <td>
               <div className='hstack gap-2'>
               <button className="btn btn-sm btn-light edit-list" onClick={addTableRows}>
                   Cancel
                 </button>
                 <button className="btn btn-sm btn-success edit-list" onClick={()=> SaveInvoice()}>
                   Save
                 </button>
               </div>
             </td>
           </tr>}
            </tbody>
          </Table>
          <button className="btn btn-primary" onClick={addTableRows} >+</button>
        </div>
        <div className='m-3 hstack gap-3'>
          <h5> Charges</h5> <Button className='btn btn-soft-dark'>Recalculate</Button>
        </div>
        <div className='m-3'>
          <Table className='table-bordered'>
            <tbody>
            {invoicecharges?.map((res)=>{
              total_excl_tax += (res.unit_price*res.quantity);
              total_tax += res.amount - (res.unit_price*res.quantity);
              return(<>
              {(expandedInvoiceChargeId != res.id) &&  <tr key={res.id}>
                <td>
                 <span>{res.item_name}</span><br/>
                 <span>{res.item_summary}</span>
                </td>
                <td>
                  {postData.taxs.map((val) => (
                  (res.tax_id == val.id) ? val.tax_name  : ""))}
                </td>
                <td style={{ textAlign: 'right' }}>{postData?.global.currency_symbol} {parseFloat(res.unit_price).toFixed(2)}</td>
                <td>{(res.quantity).toFixed(2)}</td>
                <td style={{ textAlign: 'right' }}>{postData?.global.currency_symbol} {parseFloat(res.amount).toFixed(2)}</td>
                <td>
                  <div className='hstack gap-2'>
                    <Button className="btn btn-sm btn-soft-info edit-list" onClick={(e)=>t_editCharges(res.id)}>
                      <i className="bx bxs-pencil fs-12 pt-1"></i>
                    </Button>
                    <Button className=" btn btn-sm btn-soft-danger fs-13 pt-1" onClick={(e)=>t_deleteItem("charges",res)}>
                      <i className="bx bxs-trash fs-12"></i>
                    </Button>
                  </div>
                </td>
              </tr>}
              {(expandedInvoiceChargeId == res.id) && <tr>
              <td>
                <select className='form-control' value={res.item_name} onChange={(e)=>{
                   const selectedIndex = e.target.selectedIndex;
                   const selectedOption = e.target.options[selectedIndex];
                   const price = selectedOption.getAttribute('data-price');
                const updateddata =[...invoicecharges];
                const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                updateddata[taskIndex].item_name = e.target.value;
                InvoiceChargeUpdate(res, price, taskIndex)
                var tax_amount = postData.taxs.map((val) => (
                (res.tax_id == val.id) ? val.rate_percent  : ""));
                updateddata[taskIndex].amount = parseFloat((res.unit_price * res.quantity) * (1+ tax_amount[0] / 100)).toFixed(2);
                setInvoiceCharges(updateddata);
              }}>
                  <option value="0"></option>
                  {postData.charges.map((res)=>{
                    return(<option key={res.id} data-price={res.price} value={res.name}>{res.name}</option>)
                  })}
                </select>
              </td>
              <td>
              <select className='form-control' value={res.tax_id} onChange={(e)=>{
                const updateddata =[...invoicecharges];
                const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                updateddata[taskIndex].tax_id = e.target.value;
                setInvoiceCharges(updateddata);
              }}>
                <option value="0"></option>
                {postData.taxs.map((res)=>{
                  return(<option key={res.id} value={res.id}>{res.tax_name}</option>)
                })}
              </select>
              </td>
              <td>
              <Input type='number' className='form-control' value={res.unit_price} onChange={(e)=>{
                const updateddata =[...invoicecharges];
                const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                updateddata[taskIndex].unit_price = e.target.value;
                var tax_amount = postData.taxs.map((val) => (
                  (res.tax_id == val.id) ? val.rate_percent  : ""));
                  console.log(tax_amount);
                updateddata[taskIndex].amount = parseFloat((res.unit_price * res.quantity) * (1+ tax_amount[0] / 100)).toFixed(2);
                setInvoiceCharges(updateddata);
              }}/>
              </td>
              <td>
                <Input type='number' className='form-control' value={res.quantity} disabled/>
              </td>
              <td>
                <Input type='number' className='form-control' value={res.amount} disabled/> 
              </td>
              <td>
                <Button className='btn btn-light py-1 mx-1' onClick={()=>t_editCharges()}>Cancel</Button>
                <Button className='btn btn-success py-1 mx-1 my-1' onClick={()=> UpdateCharges(res)}>Update</Button>
              </td>
            </tr>}
            </>)
            })}
            {addnewCharges && <tr>
              <td>
                <select className='form-control' onChange={(e)=>{
                   const selectedIndex = e.target.selectedIndex;
                   const selectedOption = e.target.options[selectedIndex];
                   const price = selectedOption.getAttribute('data-price');
                   const type = selectedOption.getAttribute('data-type');
                   const pid = selectedOption.getAttribute('data-pid');
                   setNewChargepid(pid);
                   setNewChargetype(type);
                setNewCharge(e.target.value);
                var postDatas = {
                  "invoice_id": postData.invoice.id.toString(),
                  "price": price
                }
                axios.post("api/ajaxCalculateChargePrice", postDatas).then((res)=>{
                  var tax_amount = postData.taxs.map((val) => (
                    (newchargetax == val.id) ? val.rate_percent  : ""));
                    setNewChargeUnit(parseFloat(res).toFixed(2));
                    var chargemamount = parseFloat((parseFloat(res).toFixed(2) * newchargeqty) * (1+ tax_amount[0] / 100)).toFixed(2);
                    setNewChargeAmt(chargemamount);
                })
              }} >
                  <option value="0"></option>
                  {postData.charges.map((res)=>{
                    return(<option key={res.id} data-price={res.price} data-pid={res.id} data-type={res.product_type} value={res.name}>{res.name}</option>)
                  })}
                </select>
              </td>
              <td>
              <select className='form-control' onChange={(e)=>{
                var tax_amount = postData.taxs.map((val) => (
                  (e.target.value == val.id) ? val.rate_percent  : ""));
                  setNewChargeTax(e.target.value)
                var chargemamount = parseFloat((newchargeunit * newchargeqty) * (1+ tax_amount[0] / 100)).toFixed(2);
                setNewChargeAmt(chargemamount);
              }}>
                <option value="0"></option>
                {postData.taxs.map((res)=>{
                  return(<option key={res.id} value={res.id}>{res.tax_name}</option>)
                })}
                </select>
              </td>
              <td>
                <Input className='form-control' type='number' value={newchargeunit}/>
              </td>
              <td>
              <Input className='form-control' type='number' value={newchargeqty} disabled/>
              </td>

              <td>{postData?.global.currency_symbol} {newchargeamt}</td>
              <td>
                <Button className='btn btn-light py-1 mx-1' onClick={()=>addNewCharges()}>Cancel</Button>
                <Button className='btn btn-success py-1 mx-1 my-1' onClick={()=> SaveCharges()}>Save</Button>
              </td>
            </tr>
              
            }
              <TableRows rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange={handleChange} />
            </tbody>
          </Table>
          <Button className="btn btn-brown" onClick={()=>addNewCharges()} >+</Button>
        </div>
        <Row>
          <Col lg={6}></Col>
          <Col lg={6}>
            <Table className="table-bordered ">
              <tbody>
                <tr>
                  <th className='bg-soft-purple'><span>Total (excl tax)</span></th>
                  <td style={{ textAlign: 'right' }}><span id="grand_total_excl_tax">{postData?.global?.currency_symbol} {total_excl_tax}</span></td>
                </tr>
                <tr>
                <th className='bg-soft-purple'><span>Discount {(discounttype) ? "- "+discounttype : ""}</span>
                    <UncontrolledDropdown className="dropdown d-inline-block">
                      <DropdownToggle className="btn fs-18" tag="button">
                        <i className="ri-more-fill"></i>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem className='edit-item-btn' onClick={()=>SelectDiscount("Fixed")}>
                          <i className="ri-money-dollar-circle-line align-bottom me-2 text-muted"></i>Fixed
                        </DropdownItem>
                        <DropdownItem className='remove-item-btn' onClick={()=>SelectDiscount("Percent")}>
                          <i className="ri-percent-fill align-bottom me-2"></i>Percent
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    </th>
                    <td style={{ textAlign: 'right' }}>{discounttype == "" &&
                      <span id="grand_total_incl_tax">{data?.invoice?.discount}%</span>}
                      {discounttype && 
                      <span>
                        <div className="input-group input-group-sm">    
                        <input type="number" id="s_discount_value_field" className="form-control mb-2" onChange={(e)=>{
                          console.log(e.target.value);
                          setDiscountValue(e.target.value);
                        }}/> 
                    </div>
                    <div className="d-flex justify-content-start align-items-center m-t-10">
                        <button id="s_cancel_discount_btn" type="button" className="btn btn-light" onClick={()=>SelectDiscount("cancel")}> Cancel</button>
                        <button id="s_save_discount_btn" type="button" className="btn btn-success mx-2" onClick={()=>SelectDiscount("save")}>Save</button>
                    </div>
                      </span>}
                    </td>
                </tr>
                <tr>
                  <th className='bg-soft-purple'><span>Total (excl tax) after discount</span></th>
                  <td style={{ textAlign: 'right' }}><span id="grand_total_excl_tax">{postData?.global?.currency_symbol} {(discounttype == "percent") ? parseFloat(total_excl_tax - (discountvalue/100 * total_excl_tax)).toFixed(2) : parseFloat(total_excl_tax - discountvalue).toFixed(2)}</span></td>
                </tr>
                <tr>
                  <th className='bg-soft-purple'><span>Tax</span></th>
                  <td style={{ textAlign: 'right' }}><span id="grand_total_tax">{postData?.global?.currency_symbol} {parseFloat(total_tax).toFixed(2)}</span></td>
                </tr>
                <tr>
                  <th className='bg-soft-purple'><span>Total (incl tax)</span></th>
                  <td style={{ textAlign: 'right' }}><span id="grand_total_incl_tax">{postData?.global?.currency_symbol} {parseFloat((discounttype == "percent") ? parseFloat(total_tax + total_excl_tax - (discountvalue/100 * total_excl_tax)).toFixed(2) : parseFloat(total_tax + total_excl_tax - discountvalue).toFixed(2)).toFixed(2)}</span></td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card>
       RECURRING INVOICE & PAYMENT SETUP
       {data?.storage?.recurring === 'N' && data?.invoice?.id === 0 && data?.invoice_items?.map((res) => (
  res.id > 0 && (
    <Accordion id="default-accordion-example" key={res.id}>
      <AccordionItem>
        <Collapse isOpen={col4} className="accordion-collapse" id="collapseOne">
          <Row className="m-3 mb-0" >
            <Label>Recurring </Label>
            <Col lg={2} className="hstack gap-3">
              <Label className="form-check-Label" for="flexSwitchCheckRightDisabled">Off</Label>
              <span className="form-check form-switch form-switch-lg mt-2 mb-2">
                <Label className="form-check-Label pad-left" for="flexSwitchCheckRightDisabled">On</Label>
                <Input className={classnames("form-check-Input", { collapsed: !col3 })} onClick={t_col3} type="checkbox" role="switch" id="flexSwitchCheckRightDisabled" value="Y" checked={recurring === 'Y'} onChange={(e) => setRecurring(e.target.checked ? 'Y' : 'N')} />
              </span>
            </Col>
          </Row>
          <Collapse isOpen={col3} className="accordion-collapse" id="collapseOne">
            <div id="auto_topup_div" className="m-3 mt-0">
              <Label className="mt-1"> Billing Frequency</Label>
              <div>
                <select className="form-select" value={billing_frequency} onChange={(e) => setBillingFrequency(e.target.value)}>
                  {frequency?.map((res) => (
                    <option key={res} value={res}>{res}</option>
                  ))}
                </select>
              </div>
              <Label className="mt-3"> Billing Start Date</Label>
              <Flatpickr
                className="form-control" placeholder='Start Date' value={billing_start_date} onChange={(date) => setBillingStartDate(moment(date[0]).format('DD MMM, YYYY'))}
                options={{
                  dateFormat: "d M, Y",
                  enableTime: false
                }}
              />
             <Label className="mt-3">Billing Finish Date</Label>{billing_end_date && (
                                          <button onClick={finishDateClear}>Clear</button>
                                        )}
              <Flatpickr
                className="form-control" placeholder='Start Date' value={billing_end_date} onChange={(date) => setBillingEndDate(moment(date[0]).format('DD MMM, YYYY'))}
                options={{
                  dateFormat: "d M, Y",
                  enableTime: false,
                  allowInput: true,
                  allowClear: true,
                }}
              />

              <div className="form-actions" style={{ paddingBottom: '10px' }}>
                <div className="row">
                  <div className="col-md-12 m-3 hstack gap-3">
                    <button type="button" className="btn btn-success m-r-10" id="topup_setting_btn" onClick={() => SaveRecurring()}>Save</button>
                    <button type="button" className="btn btn-success m-r-10" id="topup_setting_btn" onClick={() => t_col4()}>Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </Collapse>
        </Collapse>
      </AccordionItem>
    </Accordion>
  )
))}
      <Accordion id="default-accordion-example">
  <AccordionItem>
    {data?.invoice?.id === 0 && data?.storage?.billing_start_date !== '' && item_id > 0 || data?.invoice?.id > 0 ? (
      <Row className="m-3 mb-0">
        <div id="auto_topup_div" style={{ paddingLeft: '10px' }}>
          <Row>
            <Col lg={6} className="hstack gap-3 mt-3">
              <div>Recurring: {displayValue}</div>
            </Col>
            {(data?.invoice?.id === 0 && data?.storage?.recurring === 'Y' && data?.storage?.billing_start_date.length > 0) || (data?.storage?.recurring === 'Y' && data?.invoice?.id > 0) ? (
              <>
                <Col lg={6} className="hstack gap-3 mt-3">
                  <div>Billing Frequency: {data?.storage?.billing_frequency}</div>
                </Col>
                <Col lg={6} className="hstack gap-3 mt-3">
                  <div>Billing Start Date: {data?.storage?.billing_start_date}</div>
                </Col>
                <Col lg={6} className="hstack gap-3 mt-3">
                  <div>Next Billing Date: {data?.storage?.next_billing_date}</div>
                </Col>
                <Col lg={6} className="hstack gap-3 mt-3">
                  <div>Billing Finish Date: {data?.storage?.billing_end_date}</div>
                </Col>
                <Col lg={6} className="hstack gap-3 mt-3">
                  <div>Stripe Customer Id: {data?.storage?.stripe_one_off_customer_id}</div>
                </Col>
              </>
            ) : null}
          </Row>
          <div className="form-actions" style={{ paddingBottom: '10px' }}>
            <div className="row">
              <div className="col-md-12 hstack gap-3">
                <Col lg={6} className="hstack gap-3 mt-3">
                  {data?.storage?.stripe_one_off_customer_id === null && data?.storage?.recurring === 'Y' && data?.storage?.billing_start_date !== '' && (
                    <>
                    <Elements stripe={stripePromise}>
                    <StripePayment  button_text={"Setup Stripe Payment"} storage_id={storage_id} balance={parseFloat(postData?.totalAmount - total_payments).toFixed(2)} email={data.crm_contact_email} total={postData?.totalAmount} onChangeData={onChangeData} invoice={postData.invoice} />
                    </Elements>
                    </>
                  )}
                </Col>
                <Col lg={6} className="hstack gap-3 mt-3">
                  <button type="button" className="btn btn-success m-r-10" id="topup_setting_btn" onClick={() => t_col2()}>Edit</button>
                </Col>
              </div>
            </div>
          </div>
        </div>
      </Row>
    ) : null}
  </AccordionItem>
</Accordion>
       <Accordion id="default-accordion-example">
            <AccordionItem>
             <Collapse isOpen={col2} className="accordion-collapse" id="collapseOne">
              <Row className="m-3 mb-0" >
              <Label>Recurring </Label>
              
                <Col lg={2} className="hstack gap-3">
                  <Label className="form-check-Label " for="flexSwitchCheckRightDisabled">Off</Label>
                  <span className="form-check form-switch form-switch-lg  mt-2 ">
                    <Label className="form-check-Label pad-left" for="flexSwitchCheckRightDisabled">On</Label>
                    <Input className={classnames("form-check-Input", { collapsed: !col1 })} onClick={t_col1} type="checkbox" role="switch" id="flexSwitchCheckRightDisabled" value="Y" checked={recurring == 'Y'} onChange={(e) => setRecurring(e.target.checked ? 'Y' : 'N')} />
                  </span>
                </Col>
              </Row>
              <Collapse isOpen={col1} className="accordion-collapse" id="collapseOne">
                <div id="auto_topup_div" className="m-3 mt-0">
                  <Label className="mt-1"> Billing Frequency</Label>
                 
                                <div>
                                    {<select className="form-select "
                                    value={billing_frequency}
                                    onChange={(e) => {
                                        setBillingFrequency(e.target.value);}}>
                                    {frequency?.map((res) => (
                                    <option key={res} value={res}>
                                    {res}
                                    </option>
                                    ))}
                                    </select>}
                                </div>
                                <Label className="mt-3"> Billing Start Date</Label>
                               
                                <Flatpickr
                                    className="form-control  " placeholder='Start Date' value={billing_start_date} onChange={(date) => setBillingStartDate(moment(date[0]).format('DD MMM, YYYY'))}
                                        options={{
                                            dateFormat: "d M, Y",
                                            enableTime: false
                                            }}
                                />
                                  <Label className="mt-3">Billing Finish Date</Label> {billing_end_date && (
                                          <button onClick={handleClear}>Clear</button>
                                        )}
                                
                                <Flatpickr
                                    className="form-control  " placeholder='Start Date' value={billing_end_date} onChange={(date) => setBillingEndDate(moment(date[0]).format('DD MMM, YYYY'))}
                                        options={{
                                            dateFormat: "d M, Y",
                                            enableTime: false,
                                            allowInput: true,
                                            allowClear: true,
                                            }}
                                />

                </div>
              </Collapse>
              <div className="form-actions" style={{ paddingBottom: '10px' }}>
                    <div className="row">
                      <div className="col-md-12 m-3 hstack gap-3">
                        <button type="button" className="btn btn-success m-r-10" id="topup_setting_btn" onClick={() => UpdateRecurring()}>Update</button>
                        <button type="button" className="btn btn-success m-r-10" id="topup_setting_btn" onClick={()=> t_col2()}>Cancel</button>
                        {data?.storage?.stripe_one_off_customer_id != null && (
  <Button size="sm" className='btn btn-primary' onClick={()=> CancelStripe()}>Cancel Stripe Payment</Button>
)}
                      </div>
                    </div>
                  </div>
            </Collapse>
            </AccordionItem>
          </Accordion>
      <Card>
        <div className='m-3 hstack gap-3'>
          <span className="badge badge-soft-success badge-border fs-14">Invoices & Payments</span>
          {data?.invoice?.id > 0 && data?.invoice?.file_original_name === null || data?.invoice?.id == 0 && data?.storage?.recurring === 'N' && data?.invoice_items?.map((res) => (
          res.id > 0 && (
          <Button key={res.id} className='btn btn-light' onClick={() =>GenerateInvoice()}><i className='bx bx-clipboard'/>Generate Invoice</Button>
          )))}
        </div>
        <div className='m-3 table-responsive'>
        <Table className='table-bordered mt-4'>
            <thead className='bg-soft-purple'>
              <tr>
                <th>Invoice No</th>
                <th>Invoice PDF</th>
                <th>Due Date</th>
                <th>Payment Method</th>
                <th>Reference / Notes</th>
                <th>Paid On</th>
                <th>Amount</th>
                <th>Operations</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
            {data?.payment_items && data?.payment_items?.length > 0 && data?.payment_items?.map((res)=>{
              return(<>{<tr key={res.id}>
              <td>{res.invoice_number}</td>
                <td><a href={api.api.API_URL + `invoice-files/${res.file_original_name}`} target="_blank">
                  {res.file_original_name}
                </a></td>
                <td>{res?.due_date}</td>
                <td> {res.gateway}</td>
                <td>{res.remarks}</td>
                <td>{res.paid_on}</td>
                <td style={{ textAlign: 'right' }}>$ {res.amount ? res.amount : 0.00}</td>
                <td>
                {res.paid_on === null && data?.storage?.stripe_one_off_customer_id === null && data?.storage?.recurring === 'N' ? (
                  <Elements stripe={stripePromise}>
                  <StoragePayment  button_text={"Add Stripe Payment"} storage_id={storage_id} balance={parseFloat(postData?.totalAmount - total_payments).toFixed(2)} email={data.crm_contact_email} total={postData?.totalAmount} onChangeData={onChangeData} invoice={postData.invoice} />
                  </Elements>
                // <Button size="sm" className='btn btn-primary'>Add Stripe Payment</Button>
                 ) : res.paid_on === null && data?.storage?.recurring === 'Y' ? (
                  <Elements stripe={stripePromise}>
                    <StoragePayment  button_text={"Add Stripe Payment"} storage_id={storage_id} balance={parseFloat(postData?.totalAmount - total_payments).toFixed(2)} email={data.crm_contact_email} total={postData?.totalAmount} onChangeData={onChangeData} invoice={postData.invoice} />
                    </Elements>
                // <Button size="sm" className='btn btn-primary'>Add Stripe Payment</Button>
                ) : null}
                {/*res.paid_on == null && res.stripe_one_off_customer_id == null && res.recurring == 'N' && (
                <Button size="sm" className='btn btn-primary'>Add Stripe Payment</Button>
                )*/}
                </td>
                <td>
                  <div className='hstack gap-2'>
                  <Button size="sm" color="light" onClick={()=>t_addnewpayment()}> Add Payment </Button>
                  <Button size="sm" color="light" onClick={()=>send_emailToCustomer()}> Email Invoice To Customer </Button>
                  </div>
                </td>
              </tr>}
              {(expandedpaymentId == res.id) && <tr>
                  <td>
                    <select className='form-control' value={res.gateway} onChange={(e)=>{
                    const updateddata =[...data.payment_items];
                    const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                    updateddata[taskIndex].gateway = e.target.value;
                    setPaymentItems(updateddata);
                    }}>
                      <option></option>
                      {data.payment_methods.map((res)=>{
                        return (<option key={res.id} value={res.name}>{res.name}</option>)
                      })}
                    </select>
                  </td>
                  <td>
                    <textarea className='form-control' value={res.remarks} onChange={(e)=>{
                    const updateddata =[...data.payment_items];
                    const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                    updateddata[taskIndex].remarks = e.target.value;
                    setPaymentItems(updateddata);
                    }}></textarea>
                    </td>
                  <td>
                  <Flatpickr
                  className="form-control"
                  options={{
                  dateFormat: "Y-m-d",
                  defaultDate: [moment(res.paid_on).format("YYYY-MM-DD")]
                  }} onChange={(selectedDate)=>{
                    const updateddata =[...data.payment_items];
                    const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                    updateddata[taskIndex].paid_on = moment(selectedDate[0]).format("YYYY-MM-DD");
                    setPaymentItems(updateddata);
                    }} />
                  </td>
                  <td>
                    <Input className='form-control' value={res.amount} onChange={(e)=>{
                      console.log(parseFloat(e.target.value).toFixed(2));
                    const updateddata =[...data.payment_items];
                    const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                    updateddata[taskIndex].amount = e.target.value;
                    console.log(updateddata[taskIndex]);
                    setPaymentItems(updateddata);
                    }}/>
                  </td>
                  <td>
                    <div className='hstack gap-2'>
                    <button className="btn btn-sm btn-light edit-list" onClick={()=>t_editPayment()}>
                        Cancel
                      </button>
                      <button className="btn btn-sm btn-success edit-list" onClick={()=> UpdatePayment(res)}>
                        Update
                      </button>
                    </div>
                  </td>
                </tr>} 
            </>)
            })
            }
              {data.payment_items && data.payment_items.length <= 0 && <tr>
                <td>No record available!</td>
              </tr>}
              {Addnewpayment &&
                <tr>
                <td>
                    <Input className='form-control' onChange={(e)=>{
                      setPaymentInvoiceNumber(e.target.value);
                    }} readOnly/>
                  </td>
                  <td>
                    <Input className='form-control' onChange={(e)=>{
                      setPaymentFileOriginalName(e.target.value);
                    }} readOnly/>
                  </td>
                  <td>
                  <Flatpickr
                  className="form-control"
                  options={{
                  dateFormat: "Y-m-d",
                  defaultDate: [moment(new Date()).format("YYYY-MM-DD")]
                  }} onChange={(selectedDates) => {
                    setPaymentDueDate(moment(selectedDates[0]).format('YYYY-MM-DD'))}} />
                  </td>
                  <td>
                    <select className='form-control' onChange={(e)=>{
                      setPaymentMode(e.target.value);
                    }}>
                      <option></option>
                      {data.payment_methods.map((res)=>{
                        return (<option key={res.id} value={res.name}>{res.name}</option>)
                      })}
                    </select>
                  </td>
                  <td>
                    <textarea className='form-control' onChange={(e)=>{
                      setPaymentDesc(e.target.value)
                    }}></textarea>
                    </td>
                  <td>
                  <Flatpickr
                  className="form-control"
                  options={{
                  dateFormat: "Y-m-d",
                  defaultDate: [moment(new Date()).format("YYYY-MM-DD")]
                  }} onChange={(selectedDates) => {
                    setPaymentDate(moment(selectedDates[0]).format('YYYY-MM-DD'))}} />
                  </td>
                  <td>
                    <Input className='form-control' onChange={(e)=>{
                      setPaymentAmount(e.target.value);
                    }}/>
                  </td>
                  <td>
                    <div className='hstack gap-2'>
                    <button className="btn btn-sm btn-light edit-list" onClick={()=>t_addnewpayment()}>
                        Cancel
                      </button>
                      <button className="btn btn-sm btn-success edit-list" onClick={()=> SavePayment()}>
                        Save
                      </button>
                    </div>
                  </td>
                </tr>}
            </tbody>
        </Table>
        </div>
      </Card>
      <Modal
        id="showModal"
        className="modal-dialog-edit"
        isOpen={modal}
        toggle={() => t_deleteItem()}
        centered
      > 
      <ModalBody className="py-2 px-3">
      <div className="mt-2 text-center">
      <lord-icon
        src="https://cdn.lordicon.com/wdqztrtx.json"
        trigger="loop"
        colors="primary:#912a4e,secondary:#f06548"
        style={{ width: "100px", height: "100px" }}
      ></lord-icon>
      <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
        <h4>Are you sure ?</h4>
        <p className="text-muted mx-4 mb-0">
        You will not be able to recover the deleted invoice item!
        </p>
      </div>
      </div>
      <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
      <button
        type="button"
        className="btn w-sm btn-light"
        data-bs-dismiss="modal"
        onClick={()=>t_deleteItem()}>
      Cancel
      </button>
      <button
        type="button"
        className="btn w-sm btn-danger "
        id="delete-record"
        onClick={()=>deleteInvoice()}
      >
      Ok 
      </button>
      </div>
      </ModalBody>
      </Modal>
      <Modal
        id="showModal"
        className="modal-dialog-edit"
        isOpen={emailmodal}
        toggle={() =>send_emailToCustomer()}
        centered
      > 
      <ModalBody className="py-2 px-3">
      <div className="mt-2 text-center">
      <lord-icon
        src="https://cdn.lordicon.com/wdqztrtx.json"
        trigger="loop"
        colors="primary:#912a4e,secondary:#f06548"
        style={{ width: "100px", height: "100px" }}
      ></lord-icon>
      <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
        <h4>Are you sure ?</h4>
        <p className="text-muted mx-4 mb-0">
        You want to email this invoice to the customer?
        </p>
      </div>
      </div>
      <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
      <button
        type="button"
        className="btn w-sm btn-light"
        data-bs-dismiss="modal"
        onClick={()=>cancel_emailToCustomer()}>
      Cancel
      </button>
      <button
        type="button"
        className="btn w-sm btn-danger "
        id="delete-record"
        onClick={()=>Email_invoice_to_customer()}
      >
      Ok 
      </button>
      </div>
      </ModalBody>
      </Modal>
      <Modal
        id="showModal"
        className="modal-dialog-edit"
        isOpen={paymentmodal}
        toggle={() => t_deletePayment}
        centered> 
        <ModalBody className="py-2 px-3">
          <div className="mt-2 text-center">
          <lord-icon
          src="https://cdn.lordicon.com/wdqztrtx.json"
          trigger="loop"
          colors="primary:#912a4e,secondary:#f06548"
          style={{ width: "100px", height: "100px" }}
          ></lord-icon>
          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
            <h4>Are you sure ?</h4>
            <p className="text-muted mx-4 mb-0">
            You will not be able to recover the deleted payment item!
            </p>
          </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button
              type="button"
              className="btn w-sm btn-light"
              data-bs-dismiss="modal"
              onClick={()=>t_deletePayment()}>
          Cancel
          </button>
          <button
          type="button"
          className="btn w-sm btn-danger "
          id="delete-record"
          onClick={()=>deletePayment()}
          >
          Ok 
          </button>
          </div>
          </ModalBody>
        </Modal>
      <ToastContainer closeButton={false} limit={1} theme="light" />
    </div>
    </>
  )
}
