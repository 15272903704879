import React, { useEffect, useState, useRef } from 'react';
import { Container, Card, Modal, ModalHeader, ModalBody, Label, Button, Row, Col, Table, Input } from 'reactstrap';
import "../../assets/scss/pages/_backloading-schedule.scss";
import * as moment from "moment";
import Flatpickr from "react-flatpickr";
import angle1 from "../../assets/images/backloading/angle1.png";
import angle2 from "../../assets/images/backloading/angle2.png";
import arrow from "../../assets/images/backloading/arrow.png";
import calendar from "../../assets/images/backloading/calendar.png";
import car from "../../assets/images/backloading/car.png";
import axios from 'axios';

// import Components
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { SketchPicker } from "react-color";


const Backloading = () => {
    const [modal_assignModal, setmodal_assignModal] = useState(false);
    const [modal_createTrip, set_createTrip] = useState(false);

    const [currentDate, setCurrentDate] = useState(new Date());
    const [formattedDate, setFormattedDate] = useState('');
    const flatpickrInstance = useRef(null);


    const [createdDateStart, setCreatedDateStart] = useState(null);
    const [createdDateEnd, setCreatedDateEnd] = useState(null);

    const [status, setStatus] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [selectedVehicleId, setSelectedVehicleId] = useState();
    const [selectedStatusId, setSelectedStatusId] = useState();
    const [selectedDriverId, setSelectedDriverId] = useState();
    const [selectedTripId, setSelectedTripId] = useState();
    const [tripName, setTripName] = useState("");
    const [tripType, setTripType] = useState("");
    const [tripNotes, setTripNotes] = useState("");
    const [fromCity, setFromCity] = useState("");
    const [toCity, setToCity] = useState();

    const decreaseDate = () => {
        const previousDate = new Date(currentDate);
        previousDate.setDate(currentDate.getDate() - 1);
        setCurrentDate(previousDate);
    };

    const increaseDate = () => {
        const nextDate = new Date(currentDate);
        nextDate.setDate(currentDate.getDate() + 1);
        setCurrentDate(nextDate);
    };

    const formatDateToDdMmYyyy = (date) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        const formatter = new Intl.DateTimeFormat('en-GB', options);
        return formatter.format(date);
    };

    useEffect(() => {
        // Update the formatted date state whenever currentDate changes
        setFormattedDate(formatDateToDdMmYyyy(currentDate));
    }, [currentDate]);

    function tog_assignModal() {
        setmodal_assignModal(!modal_assignModal);
    }
    function tog_editModal(trip_id) {
        setmodal_assignModal(!modal_assignModal);
        setSelectedTripId(trip_id);
    }
    function toggle_createTrip() {
        set_createTrip(!modal_createTrip);
    }

    const [data, setData] = useState([]);
    const [tripId, setTripId] = useState();
    const [vehicles, setVehicles] = useState([]);
    const [vehicleGroups, setVehicleGroups] = useState([]);
    const loadData = () => {
        axios.get(`api/ajax-vehiclebackloading-data?created_date_start=${formattedDate}`)
            .then(res => {
                setData(res.data);
                setVehicleGroups(res.vehiclegroups);
                setVehicles(res.vehicles);
                setDrivers(res.drivers);
                setStatus(res.status);
                setTripId(res.data[0].id)
                console.log(res);
            })
            .catch(err => {
                console.log(err);
            });
    };
    useEffect(() => {
        loadData()
        setCreatedDateEnd(moment().format("DD/MM/YYYY"));
        setCreatedDateStart(moment().subtract(7, 'months').format('DD/MM/YYYY'));
    }, [formattedDate])

    //colorpicker
    const [colorCust, setcolorCust] = useState("rgba(95, 208, 243, 1)");
    const [display_Cust, setdisplay_Cust] = useState(false);
    function handleCust() {
        setdisplay_Cust(!display_Cust);
    }
    const onSwatchHover_Cust = (color) => {
        const format1 =
            "rgba(" +
            color.rgb.r +
            "," +
            color.rgb.g +
            "," +
            color.rgb.b +
            "," +
            color.rgb.a +
            ")";
        setcolorCust(format1);
    };

    //hover-dropdown
    const [showDropdown, setShowDropdown] = useState(false);

    const handleMouseEnter = () => {
        setShowDropdown(true);
    };

    const handleMouseLeave = () => {
        setShowDropdown(false);
    };

    const [responseData, setResponseData] = useState([]);
    const handleApply = () => {
        console.log(tripId)
        const filterData = {
            'trip_id': tripId,
            'created_date_start': createdDateStart,
            'created_date_end': createdDateEnd
        }
        axios.post("/api/get-search-jobs", filterData)
            .then(res => {
                console.log(res);
                setResponseData(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleAdd = () => {
        const formData = {
            "trip_name": tripName,
            "trip_type": tripType,
            "start_city": fromCity,
            "finish_city": toCity,
            "trip_status": selectedStatusId,
            "palette": null,
            "start_date": createdDateStart,
            "finish_date": createdDateEnd,
            "vehicle_id": selectedVehicleId,
            "vehicle_name": null,
            "driver_id": selectedDriverId,
            "trip_notes": tripNotes,
        }
        axios.post("/api/create-new-backloading-trip", formData)
            .then(res => {
                console.log(res)
            })
            .then(err => {
                console.log(err);
            })
    }
    const assignJob = (job_id, leg_id) => {
        const formDetails = {
            "trip_id": selectedTripId,
            "job_id": job_id,
            "leg_id": leg_id
        };
        axios.post("api/trip-assign-job", formDetails)
            .then(res => {
                console.log(res);
                tog_editModal();
                setSelectedTripId();
                loadData();
            })
            .then(err => {
                console.log(err);
            })
    }
    document.title = "Backloading Schedule | Onexfort";
    return (
        <React.Fragment>
            <Modal
                size="xl"
                isOpen={modal_assignModal}
                toggle={() => {
                    tog_assignModal();
                }}
            >
                <ModalHeader>
                    <h5>Assign Jobs</h5>
                </ModalHeader>
                <hr></hr>
                <ModalBody>
                    <Label className="form-label mb-0">Date Range</Label>
                    <Row>
                        <Col lg={4}>
                            <div className="mb-3">
                                <Label className="form-label"> Start Date</Label>
                                <Flatpickr
                                    className="form-control" placeholder='Start Date'
                                    options={{
                                        dateFormat: "d/m/Y",
                                    }}
                                    value={createdDateStart}
                                    onChange={(selectedDates) => {
                                        if (selectedDates.length === 1) {
                                            setCreatedDateStart(moment(selectedDates[0]).format('DD/MM/YYYY'));
                                        } else {
                                            setCreatedDateStart(null);
                                        }
                                    }}
                                />
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="mb-3">
                                <Label className="form-label"> End Date</Label>
                                <Flatpickr
                                    className="form-control"
                                    value={createdDateEnd}
                                    options={{
                                        dateFormat: "d/m/Y",
                                    }}
                                    onChange={(selectedDates) => {
                                        if (selectedDates.length === 1) {
                                            setCreatedDateEnd(moment(selectedDates[0]).format('DD/MM/YYYY'));
                                        } else {
                                            setCreatedDateEnd(null);
                                        }
                                    }} />

                            </div>
                        </Col>
                        <Col lg={4} className='hstack gap-2'>
                            <Button className="btn btn-success" onClick={handleApply}>Apply</Button>
                            <Button className="btn btn-dark">Reset</Button>
                        </Col>
                    </Row>
                    <Table className='table-bordered mt-3'>
                        <thead>
                            <tr>
                                <th>Job # </th>
                                <th>Leg #</th>
                                <th>Name</th>
                                <th>CBM</th>
                                <th>Job Date</th>
                                <th>Pickup Suburb</th>
                                <th>Drop off Suburb</th>
                                <th>Job States</th>
                                <th>Payment</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {responseData.map((item) => (
                                <tr key={item.job_id}>
                                    <td >{item.job_id}</td>
                                    <td>{item.leg_number}</td>
                                    <td>{item.name}</td>
                                    <td>{item.cbm}</td>
                                    <td>{item.job_date}</td>
                                    <td>{item.pickup_suburb}</td>
                                    <td>{item.delivery_suburb}</td>
                                    <td>{item.job_status}</td>
                                    <td>{item.payment_status_text}</td>
                                    <td><Button className='btn btn-primary'
                                        onClick={() => assignJob(item.job_id, item.leg_id)}
                                    > Assign job</Button></td>

                                </tr>
                            ))}

                        </tbody>
                    </Table>


                </ModalBody>
            </Modal>
            <Modal size="lg"
                isOpen={modal_createTrip}
                toggle={() => {
                    toggle_createTrip();
                }}>
                <ModalHeader>
                    <h5>Create New Trip</h5>
                </ModalHeader>
                <hr></hr>
                <ModalBody>
                    <Row className=''>
                        <Col lg={6}>
                            <Label>Trip Name</Label>
                            <Input className='Form-control'
                                value={tripName}
                                onChange={(e) => {
                                    setTripName(e.target.value)
                                }}
                            ></Input>
                        </Col>
                        <Col lg={6}>
                            <Label>Trip Type</Label>
                            <Input className='Form-control'
                                value={tripType}
                                onChange={(e) => {
                                    setTripType(e.target.value)
                                }}></Input>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col lg={6}>
                            <Label>From City</Label>
                            <Input className='Form-control'
                                value={fromCity}
                                onChange={(e) => {
                                    setFromCity(e.target.value)
                                }}
                            ></Input>
                        </Col>
                        <Col lg={6}>
                            <Label>To City</Label>
                            <Input className='Form-control'
                                value={toCity}
                                onChange={(e) => {
                                    setToCity(e.target.value)
                                }}
                            ></Input>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col lg={6}>
                            <Label>Trip Status</Label>
                            <select
                                value={selectedStatusId}
                                onChange={(event) => setSelectedStatusId(event.target.value)}
                                className="form-select "
                                aria-label="Default select example"
                            >
                                {status?.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.list_option}
                                    </option>
                                ))}
                            </select>
                        </Col>
                        <Col lg={6}>
                            <Label>Color Picker</Label>
                            <div
                                className="monolith-colorpicker"
                                onClick={handleCust}
                            >
                                <i
                                    style={{
                                        height: "28px",
                                        width: "28px",
                                        background: colorCust,
                                        display: "block"
                                    }}
                                />
                            </div>
                            {display_Cust ? (
                                <SketchPicker
                                    color="#fff"
                                    value={colorCust}
                                    width="160px"
                                    onChangeComplete={onSwatchHover_Cust}
                                />
                            ) : null}
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col lg={6}>
                            <Label><h6>Estimated Start Date</h6></Label>
                            <Flatpickr
                                className="form-control"
                                options={{
                                    dateFormat: "d/m/Y",
                                }}
                                value={createdDateStart}
                                onChange={(selectedDates) => {
                                    if (selectedDates.length === 1) {
                                        setCreatedDateStart(moment(selectedDates[0]).format('DD/MM/YYYY'));
                                    } else {
                                        setCreatedDateStart(null);
                                    }
                                }}
                            />
                        </Col>
                        <Col lg={6}>
                            <Label><h6>Estimated Finish Date</h6></Label>
                            <Flatpickr
                                className="form-control"
                                value={createdDateEnd}
                                options={{
                                    dateFormat: "d/m/Y",
                                }}
                                onChange={(selectedDates) => {
                                    if (selectedDates.length === 1) {
                                        setCreatedDateEnd(moment(selectedDates[0]).format('DD/MM/YYYY'));
                                    } else {
                                        setCreatedDateEnd(null);
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col lg={6}>
                            <Label>Vehicle</Label>
                            <select
                                value={selectedVehicleId}
                                onChange={(event) => setSelectedVehicleId(event.target.value)}
                                className="form-select"
                                aria-label="Default select example"
                            >
                                {vehicles?.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.vehicle_name}
                                    </option>
                                ))}
                            </select>
                        </Col>
                        <Col lg={6}>
                            <Label>Driver</Label>
                            <select
                                value={selectedDriverId}
                                onChange={(event) => setSelectedDriverId(event.target.value)}
                                className="form-select "
                                aria-label="Default select example"
                            >
                                {drivers?.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col lg={12}>
                            <Label>Trip Notes</Label>
                            <textarea className="form-control" id="VertimeassageInput" rows="3"
                                value={tripNotes}
                                onChange={(e) => {
                                    setTripNotes(e.target.value)
                                }}>
                            </textarea>
                        </Col>
                    </Row>
                    <div className='mt-2 hstack gap-2 justify-content-end'>
                        <Button className='btn btn-dark'>Cancel</Button>
                        <Button className='btn btn-success' onClick={handleAdd}>Create Trip</Button>
                    </div>
                </ModalBody>
            </Modal>

            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Backloading" pageTitle="Onexfort" />
                    <Card className='p-2'>
                        <div className="container ">
                            <div className="row  d-flex  justify-content-center">
                                <div className="header-item1" style={{ width: 'fit-content' }}>
                                    <ul >
                                        <li><a href="#" onClick={decreaseDate}><i className='bx bx-left-arrow-circle fs-20'></i></a></li>
                                    </ul>
                                </div>
                                <div className="header-item2 align-items-center text-center" style={{ width: 'fit-content' }}>

                                    <h4 className='m-1'>
                                        <span className='text-primary'>
                                            <span>{formattedDate} </span>
                                        </span>
                                    </h4>
                                    <Flatpickr
                                        ref={flatpickrInstance}
                                        className='m-1 flatpickr'
                                        value={currentDate}
                                        onChange={(date) => setCurrentDate(date[0])}
                                        options={{
                                            dateFormat: 'D, d F Y',
                                            onOpen: (selectedDates, dateStr, instance) => {
                                                flatpickrInstance.current = instance;
                                            },
                                        }}
                                    />

                                </div>
                                <div className="header-item1" style={{ width: 'fit-content' }}>
                                    <ul >
                                        <li><a href="#" onClick={increaseDate}><i className='bx bx-right-arrow-circle fs-20'></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Card>
                    {/* <!-- table-box-start --> */}
                    <Card>
                        <div className="table-area">
                            <div className="container-fluid">
                                <div className="table-box-wrap">
                                    <div className="table-box-main">
                                        <div className="table-header bg-primary">
                                            <div className="row">
                                                <div className="col-lg-3 col-8  right-border">
                                                    <div className="table-header-lft">
                                                        <h5>Vehicles</h5>
                                                    </div>
                                                </div>
                                                <div className="col-lg-9 col-4">
                                                    <div className="table-header-rht">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {Object.entries(vehicleGroups)?.map(([key, vehicle]) => (

                                            <>
                                                {console.log(vehicle)}
                                                <div className="table-subheader" key={vehicle.id}>
                                                    <div className="row">

                                                        <div className="col-lg-3 col-8 " >
                                                            <div className="table-subheader-lft">
                                                                <h6>
                                                                    <span><img src="images/arrow.png" alt="" /></span>

                                                                    <span >{key}</span>

                                                                </h6>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-9 col-4">
                                                            <div className="table-subheader-rht">

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>


                                                {vehicle?.map((res, index) => {

                                                    return (
                                                        <div className="table-item-wrap border-inner" key={index} >
                                                            <div className="row"   >
                                                                <div className="col-lg-3 right-border">
                                                                    <div className="table-item-left">
                                                                        <h6><span><img src={car} alt="" /></span> {res.vehicle_name}</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 d-flex justify-content-center align-items-center flex-column ">
                                                                    {data?.map((item) => {
                                                                        console.log(data)
                                                                        if (item.vehicle_id === res.id) {
                                                                            return (
                                                                                <React.Fragment key={item.id}>

                                                                                    <div className="table-item-rht p-1">
                                                                                        <div className="trip-type-box-wrap">
                                                                                            <div className="trip-type-cnt" style={{ backgroundColor: item.trip_colour }}>

                                                                                                <div className="trip-type-cnt-left"
                                                                                                    onMouseEnter={handleMouseEnter}
                                                                                                    onMouseLeave={handleMouseLeave}
                                                                                                >
                                                                                                    <p>{item.trip_name}</p>

                                                                                                </div>

                                                                                                <div className="trip-type-cnt-right">
                                                                                                    <ul>
                                                                                                        {
                                                                                                            item.all_jobs?.map(item => (
                                                                                                                <li key={item.job_id}>{item.job_number}</li>
                                                                                                            ))
                                                                                                        }

                                                                                                    </ul>
                                                                                                </div>
                                                                                                <div className="assign_job_space">
                                                                                                    <p className="assign_jobs_pencil"><i className="bx bxs-pencil text-primary" onClick={() => tog_editModal(item.id)} aria-hidden="true"></i></p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="trip-inner">
                                                                                                <span>{item.capacity_loading} %</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <Card className='dropdown-hover'>
                                                                                            {showDropdown && (
                                                                                                <div className="dropdown  hover-drop-content">
                                                                                                    <div className='p-2'>
                                                                                                        <h6> {item.trip_name}</h6>
                                                                                                        <Label className='mt-2 text-muted'>Loaded {item.total_jobs_cbm}m3
                                                                                                            of {item.cubic_capacity}m3</Label>
                                                                                                    </div>
                                                                                                    <div className='bg-soft-purple p-2'>
                                                                                                        <div className='pt-2 '>
                                                                                                            <h6 className='mb-2 bg-soft-purple'> Waybill No #{item.waybill_number}</h6>
                                                                                                            <p className='mb-2'> {item.start_city} - {item.finish_city}</p>
                                                                                                            <p className='mb-2'> {item.start_date} - {item.finish_date}</p>
                                                                                                            <p className='mb-2'> {item.license_plate_number} -  {item.vehicle_name}</p>
                                                                                                            <p className='mb-2'>{item.first_name} {item.last_name}</p>
                                                                                                            <p className='mb-2'>{item.notes}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )}
                                                                                        </Card>
                                                                                    </div>


                                                                                </React.Fragment>

                                                                            );
                                                                        } else {
                                                                            return (
                                                                                null
                                                                            ); // If no match, return null to skip rendering for this data item
                                                                        }
                                                                    })}
                                                                </div>
                                                                <div className='col-lg-1'>
                                                                    <Button className='btn btn-light text-primary m-3 ' onClick={() => toggle_createTrip()}>+</Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                    // }

                                                })}

                                                {data?.map((item) => {
                                                    if (item.vehicle_id === vehicle.id) {
                                                        return (
                                                            <React.Fragment key={item.id}>
                                                                <div className="col-lg-8 d-flex justify-content-center align-items-center">
                                                                    <div className="table-item-rht">
                                                                        <div className="trip-type-box-wrap">
                                                                            <div className="trip-type-cnt" style={{ backgroundColor: item.trip_colour }}>

                                                                                <div className="trip-type-cnt-left"
                                                                                    onMouseEnter={handleMouseEnter}
                                                                                    onMouseLeave={handleMouseLeave}
                                                                                >
                                                                                    <p>{item.trip_name}</p>

                                                                                </div>

                                                                                <div className="trip-type-cnt-right">
                                                                                    <ul>
                                                                                        {
                                                                                            item.all_jobs?.map(item => (
                                                                                                <li key={item.job_id}>{item.job_number}</li>
                                                                                            ))
                                                                                        }

                                                                                    </ul>
                                                                                </div>
                                                                                <div className="assign_job_space">
                                                                                    <p className="assign_jobs_pencil"><i className="bx bxs-pencil text-primary" onClick={() => tog_assignModal()} aria-hidden="true"></i></p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="trip-inner">
                                                                                <span>{item.capacity_loading} %</span>
                                                                            </div>
                                                                        </div>
                                                                        <Card className='dropdown-hover'>
                                                                            {showDropdown && (
                                                                                <div className="dropdown  hover-drop-content">
                                                                                    <div className='p-2'>
                                                                                        <h6> {item.trip_name}</h6>
                                                                                        <Label className='mt-2 text-muted'>Loaded {item.total_jobs_cbm}m3
                                                                                            of {item.cubic_capacity}m3</Label>
                                                                                    </div>
                                                                                    <div className='bg-soft-purple p-2'>
                                                                                        <div className='pt-2 '>
                                                                                            <h6 className='mb-2 bg-soft-purple'> Waybill No #{item.waybill_number}</h6>
                                                                                            <p className='mb-2'> {item.start_city} - {item.finish_city}</p>
                                                                                            <p className='mb-2'> {item.start_date} - {item.finish_date}</p>
                                                                                            <p className='mb-2'> {item.license_plate_number} -  {item.vehicle_name}</p>
                                                                                            <p className='mb-2'>{item.first_name} {item.last_name}</p>
                                                                                            <p className='mb-2'>{item.notes}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </Card>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-1'>
                                                                    <Button className='btn btn-light text-primary m-3 ' onClick={() => toggle_createTrip()}>+</Button>
                                                                </div>
                                                            </React.Fragment>

                                                        );
                                                    } else {
                                                        return (
                                                            null
                                                        );
                                                    }

                                                })}

                                            </>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default Backloading;
