import React, { useState, useEffect } from 'react';
import { Card, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { LeadAverageCharts } from './DashboardCrmCharts';
import { useSelector, useDispatch } from "react-redux";
import { getLeadsAverageChartsData } from '../../store/dashboardCRM/action';
import axios from 'axios';

const LeadsAverage = (props) => {
    const dispatch = useDispatch();

    const [chartData, setchartData] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState("");
    const [dropdownOptions, setDropdownOptions] = useState([]);
    
    useEffect(() => {
    const today = new Date();
    const currentMonth = today.toLocaleString('default', { month: 'long' });
    const previousMonths = [
      today.getMonth() - 1,
      today.getMonth() - 2,
      today.getMonth() - 3,
    ];

    setSelectedMonth(currentMonth);

    const options = previousMonths.map((month) => {
      const date = new Date(today.getFullYear(), month);
      return {
        label: date.toLocaleString('default', { month: 'long' }),
        value: date.toLocaleString('default', { month: 'long' }),
      };
    });

    dispatch(getLeadsAverageChartsData(currentMonth));
    setDropdownOptions([
      {
        label: currentMonth,
        value: currentMonth,
      },
      ...options,
    ]);
  }, [dispatch]);

  const onChangeChartPeriod = (pType) => {
    setSelectedMonth(pType);
    dispatch(getLeadsAverageChartsData(pType));
    let month = {
        "month": pType
    }
    axios.post("api/get-average-job-size-leads", month)
    .then((response) => {
        setchartData(response.average_lead_data);
        console.log(response.average_lead_data);
    });
  };
    useEffect(() => {
        setchartData(props.data);
    }, [props.data]);

    return (
        <React.Fragment>
                        <div className="flex-shrink-0">
                            <UncontrolledDropdown className="card-header-dropdown">
                                <DropdownToggle tag="a" className="text-reset dropdown-btn" role="button">
                                <span className="fw-semibold text-uppercase fs-12">Sort by: </span>
                                <span className="text-muted">
                                {selectedMonth.charAt(0).toUpperCase() + selectedMonth.slice(1)}
                                <i className="mdi mdi-chevron-down ms-1"></i>
                                </span>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-start">
                                    {dropdownOptions.map((option) => (
                                        <DropdownItem
                                        key={option.value}
                                        onClick={() => {
                                            onChangeChartPeriod(option.value);
                                        }}
                                        className={selectedMonth.toLowerCase() === option.value ? 'active' : ''}
                                        >
                                        {option.label}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    <div className="card-body pb-0">
                        <div id="sales-forecast-chart" className="apex-charts" dir="ltr">
                         <LeadAverageCharts series={chartData} dataColors='[“#a3be8c”,  "#ebcb8b", "#d08770", "#b48ead", "#88c0d0", "#bf616a", "#d8dee", "#eceff4", "#8fbcbb","#c3e4fd","#e6f2d9","#fdf2c5","#f9d4bb","#e3cee1","#d2eaf0","#f9cccc","#f3f4f6","#f8f9fb","#d5eeeb"]'/>
                        </div>
                    </div>
        </React.Fragment>
    );
};

export default LeadsAverage;