import React from "react";
import "../../assets/scss/pages/_opportunities.scss";

import { useEffect, useState, useMemo, useCallback } from "react";

import axios from "axios";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Label,
  Input,
  Accordion,
  AccordionItem,
  Collapse,
  Form,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
// import Components
// import OpportunityModal from "./OpportunityModal";
import BreadCrumb from "../../Components/Common/BreadCrumb";

import { get_cookie } from "../../helpers/get_cookie";

import * as moment from "moment";
import { Link } from "react-router-dom";
import Select from "react-select";
import classnames from "classnames";
import Flatpickr from "react-flatpickr";
import TableContainer from "../../Components/Common/TableContainer";
import DeleteModal from "../../Components/Common/DeleteModal";
// import "../scss/config/saas/custom.scss";
import { isEmpty } from "lodash";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import GoogleAutocomplete from "react-google-autocomplete";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import actions
import {
  getOrders as onGetOrders,
  addNewOrder as onAddNewOrder,
  updateOrder as onUpdateOrder,
  deleteOrder as onDeleteOrder,
} from "../../store/ecommerce/action";

import Loader from "../../Components/Common/Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CleaningOpportunitites = () => {
  let [data, setData] = useState([]);
  const [pickcommercial, setpickcommercial] = useState();
  let [dataa, setDataa] = useState([]);
  const [modalcleaning, setModalcleaning] = useState(false);
  const [modal, setModal] = useState(false);
  const [leadTypes, setLeadTypes] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [opportunitytype, setOpportunityType] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedChoices, setSelectedChoices] = useState({});
  const [companieslist, setCompaniesList] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const [orgName, setOrgName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [residentialsuburb, setResidentialSuburb] = useState("");
  const [Namecleaningopp, setNamecleaningopp] = useState("");
  const [addresscleaning, setaddresscleaning] = useState("");
  const [dropresplace, setdropresplace] = useState();
  const [dropoffaddress, setDropoffAddress] = useState("");
  const [place, setplace] = useState();
  const [dropplace, setdropplace] = useState();
  const [estjobdate, setEstJobdate] = useState(null);
  const [leadinfo, setLeadInfo] = useState("none");
  const [bedrooms, setBedrooms] = useState(0);
  const [bathroom, setbathroom] = useState(0);
  const [companyname, setCompanyName] = useState("none");
  const [single, setSingle] = useState(0);
  const [double, setDouble] = useState(1);
  const [optype, setOpType] = useState("");
  const [type, setType] = useState("");
  const [pickupcontactname, setPickupContactName] = useState("");
  const [statusfilter, setstatusfilter] = useState([]);
  const [pickupemail, setpickupemail] = useState("");
  const [pickupmobile, setPickupMobile] = useState("");
  const [customerid, setCustomerid] = useState("none");
  const [opp, setOpp] = useState("");
  const [countrycode, setCountrycode] = useState("");
  const [tableData, setTableData] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [usermultiselect, setusermultiselect] = useState();
  const [multistatusfilter, setmultistatusfilter] = useState();
  const [userfilter, setuserfilter] = useState();
  const dispatch = useDispatch();
  const [createdDateStart, setCreatedDateStart] = useState(null);
  const [createdDateEnd, setCreatedDateEnd] = useState(null);
  const [removalDateStart, setRemovalDateStart] = useState(null);
  const [removalDateEnd, setRemovalDateEnd] = useState(null);
  const [listoptions, setlistoptions] = useState(null);
  const [questions, setquestions] = useState();
  const [shifts, setshifts] = useState();

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    const user = JSON.parse(get_cookie("authUser"));
    var tenant_id = 30;
    var datavalue = {
      tenant_id: tenant_id,
    };
    setisLoading(true);

    axios
      .get("/api/cleaning/data", tenant_id)
      .then((res) => {
        console.log(res);
        setData(res.data);
        setOrderList(res.data);
        console.log(res.data);
        setOpp(res.opportunity_number);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setisLoading(false);
      });

    axios
      .get("/api/cleaninglistdata")
      .then((response) => {
        console.log(response);
        setLeadTypes(response.lead_type);
        setstatusfilter(response?.status);
        setuserfilter(response?.users);
        setshifts(response?.shifts);
        setlistoptions(response?.listoptions);
        setquestions(response.listoptions);
        console.log(userfilter, "userfilteruserfilteruserfilter");
        if (response?.lead_type.length > 0) {
          setLeadInfo(response?.lead_type[0].id);
        }

        setOpportunityType(response.opportunity_type);
        if (response?.opportunity_type.length > 0) {
          setOpType(response?.opportunity_type[0].id);
        }
        setCompaniesList(response.companies_list);
        if (response?.companies_list.length > 0) {
          setCompanyName(response?.companies_list[0].id);
        }

        setCustomers(response.customers);
        if (response?.customers.length > 0) {
          setCustomerid(response?.customers[0].id);
        }
      })
      .catch((error) => {
        console.error("Error fetching lead types:", error);
      });

    axios
      .get("/api/crm-leads/countryCode")
      .then((response) => {
        console.log(response);

        setCountrycode(response.country_code);
      })
      .catch((error) => {
        console.error("Error fetching lead types:", error);
      });

    axios
      .get("/api/get-display-field-opp-data")
      .then((response) => {
        setTableData(response.displayColoumn);
      })
      .catch((error) => {
        console.error("Error fetching lead types:", error);
      });
  }, []);

 

  const orders = data;

  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [dateRange, setDateRange] = useState([]);
  const [startDate, setstartDate] = useState(null);
  const [endDate, setendDate] = useState(null);
  const [creatstartDate, setcreatstartDate] = useState(null);
  const [createndDate, setcreatendDate] = useState(null);



  const isOrderSuccess = true;

  const handleDeleteOrder = () => {
    if (order) {
      dispatch(onDeleteOrder(order._id));
      setDeleteModal(false);
    }
  };
  const [from_date, setFromDate] = useState(null);
  const [end_date, setEndDate] = useState(null);
  const [col1, setcol1] = useState(false);

  const t_col1 = () => {
    setcol1(!col1);
  };

  const testfunc = (data) => {
    console.log(data);
  };

  const handleDateRangeChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      const formattedStartDate = moment(selectedDates[0]).format("DD/MM/YYYY");
      const formattedEndDate = moment(selectedDates[1]).format("DD/MM/YYYY");
      setcreatstartDate(formattedStartDate);
      setcreatendDate(formattedEndDate);
    } else {
      setcreatstartDate(null);
      setcreatendDate(null);
    }
  };

  const handleRemovalDateChange = (selectedRemovalDates) => {
    if (selectedRemovalDates.length === 2) {
      console.log(selectedRemovalDates);
      const formattedStartDate = moment(selectedRemovalDates[0]).format(
        "DD/MM/YYYY"
      );
      const formattedEndDate = moment(selectedRemovalDates[1]).format(
        "DD/MM/YYYY"
      );
      setRemovalDateStart(selectedRemovalDates[0]);
      setRemovalDateEnd(selectedRemovalDates[1]);
    } else {
      setRemovalDateStart(null);
      setRemovalDateEnd(null);
    }
  };
  console.log(removalDateStart, "1234");
  const flatpickrOptions = {
    mode: "range",
    dateFormat: "Y-m-d",
    onChange: handleRemovalDateChange,
  };

  const handleanotherDateRangeChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      const formattedStartDate = moment(selectedDates[0]).format("DD/MM/YYYY");
      const formattedEndDate = moment(selectedDates[1]).format("DD/MM/YYYY");
      setstartDate(formattedStartDate);
      setendDate(formattedEndDate);
    } else {
      setstartDate(null);
      setendDate(null);
    }
  };

  useEffect(() => {}, [orders]);

  // validation
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      orderId: (order && order.orderId) || "",
      customer: (order && order.customer) || "",
      product: (order && order.product) || "",
      orderDate: (order && order.orderDate) || "",
      // ordertime: (order && order.ordertime) || '',
      amount: (order && order.amount) || "",
      payment: (order && order.payment) || "",
      status: (order && order.status) || "",
    },
    validationSchema: Yup.object({
      orderId: Yup.string().required("Please Enter order Id"),
      customer: Yup.string().required("Please Enter Customer Name"),
      product: Yup.string().required("Please Enter Product Name"),
      amount: Yup.string().required("Please Enter Total Amount"),
      payment: Yup.string().required("Please Enter Payment Method"),
      status: Yup.string().required("Please Enter Delivery Status"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateOrder = {
          _id: order ? order._id : 0,
          orderId: values.orderId,
          customer: values.customer,
          product: values.product,
          orderDate: date,
          // ordertime: values.ordertime,
          amount: values.amount,
          payment: values.payment,
          status: values.status,
        };
        // update order
        dispatch(onUpdateOrder(updateOrder));
        validation.resetForm();
      } else {
        const newOrder = {
          _id: Math.floor(Math.random() * (30 - 20)) + 20,
          orderId: values["orderId"],
          customer: values["customer"],
          product: values["product"],
          orderDate: date,
          // ordertime: values["ordertime"],
          amount: values["amount"],
          payment: values["payment"],
          status: values["status"],
        };
        // save new order
        dispatch(onAddNewOrder(newOrder));
        validation.resetForm();
      }
      toggle();
    },
  });

  useEffect(() => {
    //  setOrder(orders);
  }, [orders]);

  useEffect(() => {
    if (!isEmpty(orders)) {
      // setOrder(orders);
      // setIsEdit(false);
    }
  }, [orders]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
      setDate(defaultdate());
    }
  }, [modal]);

  const handleOrderClicks = () => {
    setOrder("");
    setIsEdit(false);
    toggle();
  };

  const handleOrderClick = useCallback(
    (arg) => {
      const order = arg;
      setOrder({
        _id: order._id,
        orderId: order.orderId,
        customer: order.customer,
        product: order.product,
        orderDate: order.orderDate,
        ordertime: order.ordertime,
        amount: order.amount,
        payment: order.payment,
        status: order.status,
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".orderCheckBox");
    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      dispatch(onDeleteOrder(element.value));
      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".orderCheckBox:checked");
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  const [selectedCity, setSelectedcity] = useState();
  const [selectedSuburb, setselectedSuburb] = useState();
  const [selectedPostcode, setselectedPostcode] = useState();
  const [commercialselectedCity, setcommercialselectedCity] = useState();
  const [commercialselectedSuburb, setcommercialselectedSuburb] = useState();
  const [commercialselectedPostcode, setcommercialselectedPostcode] =
    useState();

  //dropoff

  const [dropselectedCity, setdropselectedCity] = useState();
  const [dropselectedSuburb, setdropselectedSuburb] = useState();
  const [dropselectedPostcode, setdropselectedPostcode] = useState();
  const [resdropselectedCity, setresdropselectedCity] = useState();
  const [resdropselectedSuburb, setresdropselectedSuburb] = useState();
  const [resdropselectedPostcode, setresdropselectedPostcode] = useState();

  const handlePlaceSelect = (place) => {
    var checked = document.getElementById("is_suburb").checked;

    if (!checked) {
      var commerselectedCity =
        place &&
        place.address_components.find((component) =>
          component.types.includes("locality")
        )?.long_name;
      setcommercialselectedCity(commerselectedCity);

      var commerselectedSuburb =
        place &&
        place.address_components.find((component) =>
          component.types.includes("administrative_area_level_2")
        )?.long_name;
      setcommercialselectedSuburb(commerselectedSuburb);

      var commerselectedPostcode =
        place &&
        place.address_components.find((component) =>
          component.types.includes("postal_code")
        )?.long_name;
      setcommercialselectedPostcode(commerselectedPostcode);

      setpickcommercial(place.formatted_address);
    } else {
      var commerselectedSuburb =
        place &&
        place.address_components.find((component) =>
          component.types.includes("administrative_area_level_2")
        )?.long_name;

      const suburb = place.formatted_address.split(",")[0].trim();

      setcommercialselectedSuburb(suburb);
      setpickcommercial(suburb);
    }

   
  };



  const [selection, setSelection] = useState(0);

  const handleRadioChange = (value) => {
    setSelection(value);
  };



  

  const residentialdropoffselect = (place) => {
    const addressComponents = place?.address_components;
  
    const streetNumberComponent = addressComponents?.find((component) =>
      component.types.includes("street_number")
    );
    const streetRouteComponent = addressComponents?.find((component) =>
      component.types.includes("route")
    );
    const suburbComponent = addressComponents?.find((component) =>
      component.types.includes("locality")
    );
    const stateComponent = addressComponents?.find((component) =>
      component.types.includes("administrative_area_level_1")
    );
    const postcodeComponent = addressComponents?.find((component) =>
      component.types.includes("postal_code")
    );
    const unitComponent = place?.addressComponents?.find((component) =>
  component.types.includes("subpremise")
);
const country = place?.addressComponents?.find((component) =>
  component.types.includes("country")
);


  
    let selectedStreetNumber = "";
    if (streetNumberComponent) {
      selectedStreetNumber = streetNumberComponent.long_name;
    }
    let selectedStreetRoute = "";
    if (streetRouteComponent) {
      selectedStreetRoute = streetRouteComponent.long_name;
    }
    let selectedSuburb = "";
    if (suburbComponent) {
      selectedSuburb = suburbComponent.long_name;
    }
    let countryplace = "";
    if (country) {
      countryplace = country.long_name;
    }
  
    let selectedUnitNumber = "";
    if (unitComponent) {
      selectedUnitNumber = unitComponent.long_name;
    }

    let selectedState = "";
    if (stateComponent) {
      selectedState = stateComponent.short_name;
    }
    let selectedPostcode = "";
    if (postcodeComponent) {
      selectedPostcode = postcodeComponent.long_name;
    }
  
    setdropresplace(`${selectedStreetNumber} ${selectedStreetRoute} ${selectedUnitNumber} ${selectedSuburb} ${selectedState}
    ${countryplace} ${selectedPostcode} `);
    setresdropselectedCity(`${selectedStreetNumber} ${selectedStreetRoute} ${selectedUnitNumber} ${countryplace}  `);
    setresdropselectedSuburb(selectedSuburb + ", " + selectedState);
    setresdropselectedPostcode(selectedPostcode);
  };


  const [carpet, setCarpet] = useState('');

  const handleRadiocarpetChange = (value) => {
    setCarpet(value);
  };


  // Customber Column
  console.log("orderList", orderList);
  const columns = useMemo(
    () => [
      {
        Header: "Opp#",
        tableId: "opp",
        accessor: "opportunity_number",
        filterable: false,
      },
      {
        Header: "Lead",
        tableId: 2,
        accessor: "name",
        filterable: false,
        Cell: (cell) => {
          console.log("cell", cell.cell.row.original.job);
          console.log("cell.cell.row", cell.cell.row);
          return (
            <Link to="/opportunitydetails" className="fw-medium link-primary">
              {cell.value}
            </Link>
          );
          const lead_id = cell.cell.row.original.job.lead_id;
          const job_id = cell.cell.row.original.job.opp_id;
          const opp = setOpp;
          return (
            <Link
              to={`/opportunitydetails/${lead_id}/${job_id}`}
              className="fw-medium link-primary"
            >
              {cell.cell.row.original.name}
            </Link>
          );
        },
      },
   
      {
        Header: "Mobile",
        tableId: 5,
        accessor: "mobile",
        filterable: false,
      },

      {
        Header: "Suburb",
        tableId: 27,
        accessor: "pickup_suburb",
        filterable: false,
      },
      {
        Header: "address",
        tableId: 28,
        accessor: "delivery_suburb",
        filterable: false,
      },
      
      {
        Header: "Lead Info",
        tableId: 6,
        accessor: "lead_info",
        filterable: false,
      },
      {
        Header: "Company",
        tableId: 7,
        accessor: "company",
        filterable: false,
      },
      {
        Header: "Type",
        tableId: 8,
        accessor: "type",
        filterable: false,
      },
      {
        Header: "Job Date",
        tableId: 9,
        accessor: "job_date",
      },

      {
        Header: "Created Date",
        tableId: 10,
        accessor: "created_date",
      },
      {
        Header: "Status",
        tableId: 11,
        accessor: "status",
        filterable: false,
        Cell: (cell) => {
          const { value } = cell;
          const { status_colour } = cell.row.original;

          let badgeClassName = "badge text-uppercase ";

          switch (value) {
            case "Lost":
              badgeClassName += `badge-soft-warning`;
              break;
            case "Cancelled":
              badgeClassName += `badge-soft-danger`;
              break;
            case "Inprogress":
              badgeClassName += `badge-soft-secondary`;
              break;
            case "Pickups":
              badgeClassName += `badge-soft-info`;
              break;
            case "Returns":
              badgeClassName += `badge-soft-primary`;
              break;
            case "Delivered":
              badgeClassName += `badge-soft-success`;
              break;
            default:
              badgeClassName += `badge-soft-warning`;
          }

          if (status_colour) {
            badgeClassName += ` ${status_colour}`;
          }

          return <span className={`badge ${badgeClassName}`}>{value}</span>;
        },
      },

      // {
      //   Header: "User",
      //   tableId: 12,
      //   accessor: "user_name",
      //   filterable: false,
      // },
      {
        Header: "tags",
        tableId: 13,
        accessor: "tags",
        filterable: false,
      },
      {
        Header: "Lead Status",
        accessor: "lead_status",
        tableId: 14,
        Cell: (row) => <div dangerouslySetInnerHTML={{ __html: row.value }} />,
      },
    ],
    [handleOrderClick, checkedAll]
  );

  console.log(tableData);

  const [selectedShiftId, setSelectedShiftId] = useState();

  const filteredColumns = columns.filter((column) => {
    if (column.tableId === "opp") {
      return true;
    }
    const matchingDisplay = tableData.find(
      (displayObj) => displayObj.id === column.tableId
    );
    return matchingDisplay && matchingDisplay.display === 1;
  });

  console.log(filteredColumns);

  const defaultdate = () => {
    let d = new Date(),
      months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    let h = d.getHours() % 12 || 12;
    let ampm = d.getHours() < 12 ? "AM" : "PM";
    return (
      d.getDate() +
      " " +
      months[d.getMonth()] +
      ", " +
      d.getFullYear() +
      ", " +
      h +
      ":" +
      d.getMinutes() +
      " " +
      ampm
    ).toString();
  };

  const [date, setDate] = useState(defaultdate());

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const handleIncrement = () => {
    setBedrooms((prevValue) => prevValue + 1);
  };

  const handleDecrement = () => {
    if (bedrooms > 0) {
      setBedrooms((prevValue) => prevValue - 1);
    }
  };

  const handleIncrementbathroom = () => {
    setbathroom((prevValue) => prevValue + 1);
  };

  const handleDecrementbathroom = () => {
    if (bathroom > 0) {
      setbathroom((prevValue) => prevValue - 1);
    }
  };

  // console.log(bedrooms,"  const [bedrooms, setBedrooms] = useState(0);")

  const handleValidTime = (time) => {
    const time1 = new Date(time);
    const getHour = time1.getUTCHours();
    const getMin = time1.getUTCMinutes();
    const getTime = `${getHour}:${getMin}`;
    var meridiem = "";
    if (getHour >= 12) {
      meridiem = "PM";
    } else {
      meridiem = "AM";
    }
    const updateTime =
      moment(getTime, "hh:mm").format("hh:mm") + " " + meridiem;
    return updateTime;
  };

  const handleMulti = (selectedOptions) => {
    setmultistatusfilter(selectedOptions);
  };

  const userhandleMulti = (selectedOptions) => {
    setusermultiselect(selectedOptions);
  };
  const uniqueQuestions = Array.from(
    new Set(listoptions?.map((option) => option.cleaning_question))
  );
  const alternatingLayout = uniqueQuestions.reduce((acc, question, index) => {
    if (index % 2 === 0) {
      acc.push({ left: question });
    } else {
      acc[acc.length - 1].right = question;
    }
    return acc;
  }, []);

  const handleChoiceChange = (question, choiceId) => {
    setSelectedChoices((prevChoices) => ({
      ...prevChoices,
      [question]: choiceId,
    }));
  };



  const handleSubmit = (event) => {
    const questions = [];
    const replies = [];

    alternatingLayout.forEach((pair) => {
      if (pair.left && selectedChoices[pair.left]) {
        questions.push(pair.left);
        replies.push(selectedChoices[pair.left]);
      }
      if (pair.right && selectedChoices[pair.right]) {
        questions.push(pair.right);
        replies.push(selectedChoices[pair.right]);
      }
    });


   


    event.preventDefault();
    console.log(estjobdate);
    console.log(optype);
    console.log(selectedCity, "selectedcity");

    let formData = {
      est_job_date: estjobdate,
      company_id: companyname,
      op_type: "Cleaning",
    };

    if (activeTab === "1") {
      if (leadinfo === "none") {
        toast.error("Please select a leadinfo", { theme: "light" });
        return; // Exit the function early if leadinfo is not selected
      }
      if (estjobdate === null) {
        //         // If estjobdate is not selected, set it to today's date
        setEstJobdate(moment().format("DD/MM/YYYY"));
        return;
      }
      if (companyname === "none") {
        toast.error("Please select a companyname", { theme: "light" });
        return; // Exit the function early if companyname is not selected
      }

      if (email !== "") {
        const emailPattern = /^[A-Za-z0-9._+-]+@[A-Za-z._-]+\.[A-Za-z]{2,}$/;
        const isValid = emailPattern.test(email);
        if (!isValid) {
          toast.error("Invalid email", { theme: "light" });
          return;
        }
      }

      formData = {
        ...formData,
        lead_name: Namecleaningopp,
        mobile: pickupcontactname,
        email: email,
        lead_info: leadinfo,
        type: "Residential",
        address: addresscleaning,
        bedrooms: bedrooms,
        bathrooms: bathroom,
        est_job_date: estjobdate,
        op_type: "Cleaning",
        question: questions,
        reply: replies,
        address: resdropselectedCity,
        suburb: resdropselectedSuburb,
        postcode: resdropselectedPostcode,
        preferred_time_range: selectedShiftId,
        stories:selection,
        carpeted:carpet
      };
    }

    axios
      .post("/api/cleaning/CleaningStore", formData)
      .then((res) => {
        console.log("hai");
        console.log(res);
        setModal(false);
        if (res.error == 0) {
          toast.success(res.message);
          window.location.href = `/opportunitydetails/${res.id}/${res.opportunity_id}`;
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => {
        console.error(error);
        // Handle the error appropriately, display error messages, or take necessary actions
      });
  };

  const handleDateChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      const startDateWithoutTime = new Date(selectedDates[0]);
      // startDateWithoutTime.setHours(0, 0, 0, 0);
      const endDateWithoutTime = new Date(selectedDates[1]);
      // endDateWithoutTime.setHours(0, 0, 0, 0);

      setCreatedDateStart(startDateWithoutTime);
      setCreatedDateEnd(endDateWithoutTime);
    } else {
      setCreatedDateStart(null);
      setCreatedDateEnd(null);
    }
  };

  const filter = () => {
    // Format createdDateStart and createdDateEnd without time
    let jobstartdate = null;
    if (createdDateStart) {
      jobstartdate = moment(createdDateStart).format("DD/MM/YYYY");
    } else {
      jobstartdate = null;
    }

    let jobenddate = null;
    if (createdDateEnd) {
      jobenddate = moment(createdDateEnd).format("DD/MM/YYYY");
    }

    // Format removalDateStart and removalDateEnd without time
    let createdate = null;
    if (removalDateStart) {
      createdate = moment(removalDateStart).format("DD/MM/YYYY");
    }

    let createend = null;
    if (moment(removalDateEnd).isValid()) {
      createend = moment(removalDateEnd).format("DD/MM/YYYY");
    }

    const selectedStatusValues = multistatusfilter?.map(
      (option) => option.label
    ); // Use .label to get the pipeline_status
    const formattedStatusValues = selectedStatusValues?.join(",");

    const selectedUserIds = usermultiselect?.map((option) => option.value);
    const formattedUserIds = selectedUserIds?.join(",");

    // Rest of your filter logic
    // ...

    const formData = {
      job_date_start: jobstartdate,
      job_date_end: jobenddate,
      create_date_start: createdate,
      creat_end_date: createend,
      Opportunity_status: formattedStatusValues,
      user_id: formattedUserIds,
      sorting_order: selectedSortingOrder,
      sort_descending: hideDeletedArchived,
    };
    const apiUrl = "api/cleaning/data";
    axios
      .get(apiUrl, { params: formData })
      .then((res) => {
        console.log(res.data);
        setOrderList(res.data);
      })
      .catch((error) => {
        console.error(error);
        toast.error("An error occurred while fetching data.", {
          theme: "light",
        });
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  const sortingOrderArray = {
    created_at: "Created Date",
    op_status: "Status",
    lead_id: "Lead",
    value: "Value",
  };
  const [selectedSortingOrder, setSelectedSortingOrder] = useState(
    Object.keys(sortingOrderArray)[0]
  );

  const handleSortingOrderChange = (event) => {
    setSelectedSortingOrder(event.target.value);
  };

  const [hideDeletedArchived, setHideDeletedArchived] = useState(1);
  const handleCheckboxChange = (event) => {
    const newValue = hideDeletedArchived === 0 ? 1 : 0;
    setHideDeletedArchived(newValue);
  };

  const [cleaningAddress, setcleaningAddress] = useState();
  const [cleaningSuburb, setcleaningSuburb] = useState();
  const [cleaningPostcode, setcleaningPostcode] = useState();

  const handlecleaningaddress = (place) => {
    console.log(
      place,
      "handlecleaningaddresshandlecleaningaddresshandlecleaningaddresshandlecleaningaddress"
    );

    const addressComponents = place?.address_components;

    const streetNumberComponent = addressComponents?.find((component) =>
      component.types.includes("street_number")
    );
    const streetRouteComponent = addressComponents?.find((component) =>
      component.types.includes("route")
    );
    const suburbComponent = addressComponents?.find((component) =>
      component.types.includes("locality")
    );
    const stateComponent = addressComponents?.find((component) =>
      component.types.includes("administrative_area_level_1")
    );
    const postcodeComponent = addressComponents?.find((component) =>
      component.types.includes("postal_code")
    );
    const unitComponent = place?.addressComponents?.find((component) =>
      component.types.includes("subpremise")
    );
    const country = place?.addressComponents?.find((component) =>
      component.types.includes("country")
    );

    let selectedStreetNumber = "";
    if (streetNumberComponent) {
      selectedStreetNumber = streetNumberComponent.long_name;
    }
    let selectedStreetRoute = "";
    if (streetRouteComponent) {
      selectedStreetRoute = streetRouteComponent.long_name;
    }
    let selectedSuburb = "";
    if (suburbComponent) {
      selectedSuburb = suburbComponent.long_name;
    }
    let countryplace = "";
    if (country) {
      countryplace = country.long_name;
    }

    let selectedUnitNumber = "";
    if (unitComponent) {
      selectedUnitNumber = unitComponent.long_name;
    }

    let selectedState = "";
    if (stateComponent) {
      selectedState = stateComponent.short_name;
    }
    let selectedPostcode = "";
    if (postcodeComponent) {
      selectedPostcode = postcodeComponent.long_name;
    }

    setcleaningAddress(
      `${selectedStreetNumber} ${selectedStreetRoute} ${selectedUnitNumber} ${countryplace}  `
    );
    setcleaningSuburb(selectedSuburb + ", " + selectedState);
    setcleaningPostcode(selectedPostcode);
  };

  useEffect(() => {
    filter();
  }, [
    createdDateStart,
    createdDateEnd,
    removalDateStart,
    removalDateEnd,
    multistatusfilter,
    usermultiselect,
    selectedSortingOrder,
    hideDeletedArchived,
  ]);

  const resetTable = () => {
    setCreatedDateStart(null);
    setCreatedDateEnd(null);
    setRemovalDateStart(null);
    setRemovalDateEnd(null);
    setmultistatusfilter([]);
    setusermultiselect([]);
    setSelectedSortingOrder("created_at"); // Set to default value
    setHideDeletedArchived(1); // Set to default value

    // After resetting the state, trigger the filter function
    filter();
  };

  // orderList?.sort((a, b) => a.job.job_number - b.job.job_number);
  document.title = "Opporunities | Onexfort";
  return (
    <React.Fragment>
      <div className="page-content">
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteOrder}
          onCloseClick={() => setDeleteModal(false)}
        />

        <DeleteModal
          show={deleteModalMulti}
          onDeleteClick={() => {
            deleteMultiple();
            setDeleteModalMulti(false);
          }}
          onCloseClick={() => setDeleteModalMulti(false)}
        />
        <Container fluid>
          <BreadCrumb title="Opportunities" pageTitle="Onexfort" />
          <Accordion id="default-accordion-example">
            <AccordionItem>
              <h2 className="accordion-header" id="headingOne">
                <button
                  className={classnames("accordion-button fw-semibold", {
                    collapsed: !col1,
                  })}
                  type="button"
                  onClick={t_col1}
                  style={{ cursor: "pointer" }}
                >
                  Search Filters
                </button>
              </h2>
              <Collapse
                isOpen={col1}
                className="accordion-collapse"
                id="collapseOne"
              >
                <div className="accordion-body">
                  <Row>
                    <Col lg={4} md={6}>
                      <div className="mb-3">
                        <label
                          htmlFor="multiselect"
                          className="form-label text-muted"
                        >
                          Opportunity Status
                        </label>
                        <Select
                          id="multiselect"
                          value={multistatusfilter}
                          isMulti
                          onChange={(selectedOption) => {
                            handleMulti(selectedOption);
                          }}
                          options={statusfilter?.map((item) => ({
                            value: item.id,
                            label: item.pipeline_status,
                          }))}
                          maxMenuHeight={200}
                        />
                      </div>
                    </Col>
                    <Col lg={4} md={6}>
                      <div className="mb-3">
                        <Label
                          htmlFor="choices-multiple-default"
                          className="form-label text-muted"
                        >
                          Users
                        </Label>
                        <Select
                          id="multiselect"
                          value={usermultiselect}
                          isMulti
                          onChange={(selectedOption) => {
                            userhandleMulti(selectedOption);
                          }}
                          options={userfilter?.map((item) => ({
                            value: item.id,
                            label: item.name,
                          }))}
                          maxMenuHeight={200}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label for="dateRangeInput" className="form-label">
                          Created Date
                        </Label>
                        <Flatpickr
                          className="form-control"
                          options={flatpickrOptions}
                          value={[removalDateStart, removalDateEnd]}
                        />
                      </div>
                    </Col>

                    <Col lg={2}>
                      <div className="mb-3">
                        <Label for="dateRangeInput" className="form-label">
                          Job Date
                        </Label>
                        <Flatpickr
                          className="form-control"
                          options={{
                            mode: "range",
                            dateFormat: "Y-m-d",
                          }}
                          value={[createdDateStart, createdDateEnd]}
                          onChange={handleDateChange}
                        />
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="mb-3">
                        <Label
                          htmlFor="choices-multiple-default"
                          className="form-label text-muted"
                        >
                          sorting Order
                        </Label>
                        <select
                          className="form-control"
                          name="sorting_order"
                          id="sorting_order"
                          value={selectedSortingOrder}
                          onChange={handleSortingOrderChange}
                        >
                          {Object.keys(sortingOrderArray).map((key) => (
                            <option key={key} value={key}>
                              {sortingOrderArray[key]}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="form-check form-radio-pad form-check-right mb-2">
                        <Input
                          className="form-check-input"
                          type="checkbox"
                          name="formCheckboxRight"
                          id="formCheckboxRight1"
                          checked={hideDeletedArchived}
                          onChange={handleCheckboxChange}
                        />
                        <Label
                          className="form-check-label"
                          for="formCheckboxRight1"
                        >
                          Descending
                        </Label>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="text-start">
                        <button
                          type="submit"
                          onClick={filter}
                          className="btn form-btn-marg btn-primary"
                        >
                          Apply
                        </button>
                        <button
                          type="submit"
                          onClick={resetTable}
                          className="btn form-btn-marg btn-primary"
                        >
                          Reset
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Collapse>
            </AccordionItem>
          </Accordion>
          <Row>
            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className="card-header border-0">
                  <Row className="align-items-center gy-3">
                    <div className="col-sm">
                      <h5 className="card-title mb-0">Search Filters</h5>
                    </div>
                    <div className="col-sm-auto">
                      <div className="d-flex gap-1 flex-wrap">
                        <button
                          type="button"
                          className="btn btn-brown add-btn"
                          id="create-btn"
                          onClick={() => {
                            setIsEdit(false);
                            toggle();
                          }}
                        >
                          <i className="ri-add-line align-bottom me-1"></i>{" "}
                          Create Opportunity
                        </button>{" "}
                        {isMultiDeleteButton && (
                          <button
                            className="btn btn-soft-danger"
                            onClick={() => setDeleteModalMulti(true)}
                          >
                            <i className="ri-delete-bin-2-line"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  </Row>
                </CardHeader>

                <CardBody className="pt-0">
                  <div>
                    <TableContainer
                      columns={filteredColumns}
                      data={orderList || []}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={50}
                      divClass="table-responsive table-card mb-1"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light text-muted text-uppercase"
                      handleOrderClick={handleOrderClicks}
                      isOrderFilter={true}
                      SearchPlaceholder="Search for order ID, customer, order status or something..."
                    />
                    {isLoading && (
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          background: "rgba(255, 255, 255, 0.8)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          zIndex: 9999,
                        }}
                      >
                        <Loader />
                      </div>
                    )}
                  </div>

                  <Modal
                    id="showModal"
                    className="modal-dialog-edit modal-xl"
                    isOpen={modal}
                    toggle={toggle}
                    centered
                  >
                    <ModalHeader
                      className="p-3 light-grey-background"
                      toggle={toggle}
                    >
                      {!!isEdit ? "Edit Order" : "New Opportunity"}
                    </ModalHeader>
                    <TabPane tabId="2">
                      <div>
                        {" "}
                        <h4 className="mt-3">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Residential</h4>
                      </div>
                      <Form
                        className="tablelist-form"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          handleSubmit(e);
                        }}
                      >
                        <CardBody className="p-4">
                          <Row>
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="customer"
                                  className="form-label"
                                >
                                  Name
                                </Label>
                                <Input
                                  type="text"
                                  value={Namecleaningopp}
                                  onChange={(e) =>
                                    setNamecleaningopp(e.target.value)
                                  }
                                  className="form-control"
                                  id="cleaningoppname"
                                  defaultValue=""
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="jobContactName"
                                  className="form-label"
                                >
                                  Mobile
                                </Label>
                                <Input
                                  type="text"
                                  value={pickupcontactname}
                                  onChange={(e) =>
                                    setPickupContactName(e.target.value)
                                  }
                                  className="form-control"
                                  id="jobContactName"
                                  defaultValue=""
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="emailInput"
                                  className="form-label"
                                >
                                  Email
                                </Label>
                                <Input
                                  type="email"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  className="form-control"
                                  id="emailInput"
                                  defaultValue=""
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="mobileJobContact"
                                  className="form-label"
                                >
                                  Cleaning Address
                                </Label>
                               
                                <GoogleAutocomplete
                                  apiKey="AIzaSyB2SMtaVBlqC5v72gqS716BX8R5oXklaFc"
                                  value={dropresplace}
                                  onChange={(e) =>
                                    setdropresplace(e.target.value)
                                  }
                                  onPlaceSelected={(place) =>
                                    residentialdropoffselect(place)
                                  }
                                  options={{
                                    types: ["geocode"],
                                    componentRestrictions: {
                                      country: "au",
                                    },
                                  }}
                                  style={{
                                    width: "530px",
                                    height: "40px",
                                    border: "1px solid #ccc",
                                    padding: "10px",
                                    borderRadius: "5px",
                                    position: "relative",
                                    zIndex: 9999,
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div>
                                <Label
                                  style={{ fontSize: "12px", color: "#999" }}
                                >
                                  Single Storey or Double?
                                </Label>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div>
                                <Label
                                  style={{ fontSize: "12px", color: "#999" }}
                                >
                                  Do you require carpet steam Cleaning?
                                </Label>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={3}>
                              <div className="form-check form-radio-info mb-3">
                              <input
                              value={0}
                              onChange={(e) => handleRadioChange(Number(e.target.value))}
                              className="form-check-input"
                              type="radio"
                              name="singleDoubleRadio"
                              id="single"
                              checked={selection === 0}
                            />
                                <label
                                  className="form-check-label"
                                  htmlFor="formradioRight10"
                                >
                                  Single
                                </label>
                              </div>
                            </Col>

                            <Col md={3}>
                              <div className="form-check form-radio-info mb-3">
                                  <input
                                  value={1}
                                  onChange={(e) => handleRadioChange(Number(e.target.value))}
                                  className="form-check-input"
                                  type="radio"
                                  name="singleDoubleRadio"
                                  id="double"
                                  checked={selection === 1}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="formradioRight11"
                                >
                                  Double
                                </label>
                              </div>
                            </Col>

                            <Col md={3}>
                              <div className="form-check form-radio-info mb-3">
                              <input
            value="Y"
            onChange={(e) => handleRadiocarpetChange(e.target.value)}
            className="form-check-input"
            type="radio"
            name="yesNoRadio"
            id="yes"
            checked={carpet === 'Y'}
          />
                                <label
                                  className="form-check-label"
                                  htmlFor="formradioRight12"
                                >
                                  Yes
                                </label>
                              </div>
                            </Col>

                            <Col md={3}>
                              <div className="form-check form-radio-info mb-3">
                              <input
            value="N"
            onChange={(e) => handleRadiocarpetChange(e.target.value)}
            className="form-check-input"
            type="radio"
            name="yesNoRadio"
            id="no"
            checked={carpet === 'N'}
          />
                                <label
                                  className="form-check-label"
                                  htmlFor="formradioRight13"
                                >
                                  No
                                </label>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={12}>
                              <div>
                                <Label
                                  style={{ fontSize: "12px", color: "#999" }}
                                >
                                  How Many rooms on your property?
                                </Label>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={2}>
                              <button
                                type="button"
                                className="round-button"
                                onClick={handleIncrement}
                              >
                                +
                              </button>
                            </Col>
                            <Col md={2}>
                              <Label
                                style={{ fontSize: "12px", color: "#065f53" }}
                              >
                               {bedrooms} Bedrooms
                              </Label>
                            </Col>
                            <Col md={2}>
                              <button
                                type="button"
                                className="round-buttonbtn"
                                onClick={handleDecrement}
                              >
                                -
                              </button>
                            </Col>

                            <Col md={2}>
                              <button
                                type="button"
                                className="round-button"
                                onClick={handleIncrementbathroom}
                              >
                                +
                              </button>
                            </Col>
                            <Col md={2}>
                              <Label
                                style={{ fontSize: "12px", color: "#065f53" }}
                              >
                              {bathroom}  Bathrooms
                              </Label>
                            </Col>
                            <Col md={2}>
                              <button
                                type="button"
                                className="round-buttonbtn"
                                onClick={handleDecrementbathroom}
                              >
                                -
                              </button>
                            </Col>
                          </Row>

                          <hr className="green-hr" />

                          <Row>
                            <div>
                              {alternatingLayout.map((pair, index) => (
                                <Row key={index}>
                                  <Col md={6}>
                                    {pair.left && (
                                      <>
                                        <label
                                          htmlFor={`question-${pair.left}`}
                                          className="form-label"
                                        >
                                          {pair.left}
                                        </label>
                                        <select
                                          id={`question-${pair.left}`}
                                          value={
                                            selectedChoices[pair.left] || ""
                                          }
                                          onChange={(e) =>
                                            handleChoiceChange(
                                              pair.left,
                                              e.target.value
                                            )
                                          }
                                          className="form-select mb-3"
                                          style={{ borderColor: "#00a859" }}
                                        >
                                          <option value="">
                                            Select a choice
                                          </option>
                                          {listoptions
                                            .filter(
                                              (option) =>
                                                option.cleaning_question ===
                                                pair.left
                                            )
                                            .map((option) => (
                                              <option
                                                key={option.id}
                                                value={option.choice}
                                              >
                                                {option.choice}
                                              </option>
                                            ))}
                                        </select>
                                      </>
                                    )}
                                  </Col>
                                  <Col md={6}>
                                    {pair.right && (
                                      <>
                                        <label
                                          htmlFor={`question-${pair.right}`}
                                          className="form-label"
                                        >
                                          {pair.right}
                                        </label>
                                        <select
                                          id={`question-${pair.right}`}
                                          value={
                                            selectedChoices[pair.right] || ""
                                          }
                                          onChange={(e) =>
                                            handleChoiceChange(
                                              pair.right,
                                              e.target.value
                                            )
                                          }
                                          className="form-select mb-3"
                                          style={{ borderColor: "#00a859" }}
                                        >
                                          <option value="">
                                            Select a choice
                                          </option>
                                          {listoptions
                                            .filter(
                                              (option) =>
                                                option.cleaning_question ===
                                                pair.right
                                            )
                                            .map((option) => (
                                              <option
                                                key={option.id}
                                                value={option.choice}
                                              >
                                                {option.choice}
                                              </option>
                                            ))}
                                        </select>
                                      </>
                                    )}
                                  </Col>
                                </Row>
                              ))}
                            </div>
                          </Row>

                        
                          <hr className="green-hr" />
                          <Row>
                            <Col md={6}>
                              <Label htmlFor="customer" className="form-label">
                                Estimated Job Time
                              </Label>
                              <Flatpickr
                                id="dateInput"
                                className="form-control"
                                value={estjobdate}
                                onChange={(date) =>
                                  setEstJobdate(
                                    moment(date[0]).format("DD/MM/YYYY")
                                  )
                                }
                                options={{
                                  dateFormat: "d/m/Y",
                                  enableTime: false,
                                }}
                              />
                            </Col>
                            <Col md={6}>
                              <Label htmlFor="customer" className="form-label">
                                Choose an available start time
                              </Label>
                              <select
                                value={selectedShiftId}
                                onChange={(e) =>
                                  setSelectedShiftId(e.target.value)
                                }
                                className="form-select mb-3"
                              >
                                <option value="none">none</option>
                                {shifts && shifts.length > 0 ? (
                                  shifts?.map((shift) => (
                                    <option
                                      key={shift.id}
                                      value={shift.shift_display_start_time}
                                    >
                                      {shift.shift_display_start_time}
                                    </option>
                                  ))
                                ) : (
                                  <option value="" disabled>
                                    No shifts found
                                  </option>
                                )}
                              </select>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <Label htmlFor="company" className="form-label">
                                Company
                              </Label>
                              <select
                                value={companyname}
                                onChange={(e) => setCompanyName(e.target.value)}
                                className="form-select mb-3"
                              >
                                <option value="none">none</option>
                                {companieslist && companieslist.length > 0 ? (
                                  companieslist.map((company) => (
                                    <option key={company.id} value={company.id}>
                                      {company.company_name}
                                    </option>
                                  ))
                                ) : (
                                  <option value="" disabled>
                                    No companies found
                                  </option>
                                )}
                              </select>
                            </Col>
                            <Col md={6}>
                              <Label htmlFor="leadInfo" className="form-label">
                                Lead Info
                              </Label>
                              <select
                                value={leadinfo}
                                onChange={(e) => setLeadInfo(e.target.value)}
                                className="form-select mb-3"
                              >
                                <option value="">none</option>
                                {leadTypes.length > 0 ? (
                                  leadTypes.map((leadType, index) => (
                                    <option key={index} value={leadType.name}>
                                      {leadType.options}
                                    </option>
                                  ))
                                ) : (
                                  <option value="" disabled>
                                    No lead types found
                                  </option>
                                )}
                              </select>
                            </Col>
                          </Row>

                          {/* Rest of the form fields go here */}
                        </CardBody>

                        <div className="modal-footer">
                          <div className="hstack gap-2 justify-content-end">
                            <button
                              type="button"
                              className="btn btn-light"
                              onClick={() => {
                                setModal(false);
                              }}
                            >
                              Cancel
                            </button>
                            <button type="submit" className="btn btn-brown">
                              {!!isEdit ? "Update" : "Create Opportunity"}
                            </button>
                          </div>
                        </div>
                      </Form>
                    </TabPane>
                  </Modal>

                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default CleaningOpportunitites;
