import React, { useState, useCallback, useEffect, useMemo } from 'react';
import {
    Card, CardBody, CardHeader, Col, Container, Row, Label, Input, Accordion, AccordionItem, Collapse, Form, FormFeedback, Modal, ModalHeader, ModalBody, ModalFooter, Nav,
    NavItem, NavLink, TabContent, TabPane, UncontrolledDropdown,

} from 'reactstrap';
import moment from 'moment';
import Flatpickr from "react-flatpickr";
import classnames from "classnames";
import Select from "react-select";
// import Components
import BreadCrumb from '../../Components/Common/BreadCrumb';
import axios from 'axios';
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import DeleteModal from "../../Components/Common/DeleteModal";

import TableContainer from "../../Components/Common/TableContainer";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { get_cookie } from '../../helpers/get_cookie';
import { Link } from 'react-router-dom';

const VehicleUnavailability = () => {
    const [col1, setcol1] = useState(false);
    const [createdDateStart, setCreatedDateStart] = useState(null);
    const [createdDateEnd, setCreatedDateEnd] = useState(null);
    const [contact, setContact] = useState([]);
    const [data, setData] = useState([]);
    const [vehicleOptions, setVehicleOptions] = useState([]);
    const t_col1 = () => {
        setcol1(!col1);
        getData();

    };

    //state variables for form data
    const [id, setId] = useState();
    const [vehicleId, setVehicleId] = useState(null);
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [fromTime, setFromTime] = useState();
    const [toTime, setToTime] = useState();
    const [reason, setReason] = useState("");

    const [isEdit, setIsEdit] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    const [dateStart, setDateStart] = useState(null);
    const [dateEnd, setDateEnd] = useState(null);


    const [modal, setModal] = useState(false);
    const [modal_standard, setmodal_standard] = useState(false);
    const [org_date, setOrg_Date] = useState([]);

    function tog_standard() {
        setmodal_standard(!modal_standard);
        setChangeToDate(moment().toDate())
    }

    const [selectedVehicle, setselectedVehicle] = useState(null);
    const [filteredVehicleValue,setFilteredVehicleValue] = useState([])

    function handleVehicleMulti(selectedOptions) {
        console.log(selectedOptions)
        let filteredVal=selectedOptions.map((e)=>{return {value:e.value}})
        console.log(filteredVal)
        setFilteredVehicleValue(filteredVal)
        setselectedVehicle(selectedOptions);
    }

    const today = moment().toDate();
    const yesterday = moment().subtract(1, 'day').toDate();
    const [changeToDate,setChangeToDate]= useState(today)
    console.log(changeToDate)

    const addUnavailabilityBtn=()=>{
            setIsEdit(false);
            tog_standard();
            getData();
            setChangeToDate(moment().subtract(1, 'days')._d)
    }
    const disablePreviousDates = (date) => {
        return date < yesterday;
    };
    const disableToDate = (date) => {
        return date < changeToDate;
    };    
    console.log(moment(fromDate).toDate())
    const disableToDateEdit = (date) => {
        const disableFromDate = moment(fromDate).toDate()
        return date < disableFromDate;
    };    
        

    // Today I will be working in vehicle unavailability page on fixing the date issues
    //fetching data table api 
    const user = JSON.parse(get_cookie("authUser"));
    var tenant_id = user.tenant_id;
    useEffect(() => { loadTable() }, [])
    const loadTable = () => {
        
        axios.post("api/vehicle-unavailability/data", tenant_id)
            .then(res => {
                console.log(res);
                setData(res);
            })
            .catch(err => {
                console.log(err);
            })
        getData();
    }

    const getData = () => {
        setFromDate(moment().format('YYYY-MM-DD'));
        setToDate("");
        setReason("")
        setFromTime(moment().format('H:mm:ss'));
        setToTime(moment().format('H:mm:ss'));
        
        axios.get("api/vehicle-unavailability", tenant_id)
            .then(res => {
                setVehicleOptions(res.vehicles);
                setVehicleId(res.vehicles[0].id);
                setCreatedDateEnd(moment().format(res.organisation_settings?.date_picker_format));
                setCreatedDateStart(moment().subtract(1, 'months').format(res.organisation_settings?.date_picker_format));
                setOrg_Date(res.organisation_settings);
                console.log(res);
            })
            .catch(err => {
                console.log(err);
            });
    }
    const handleOptionChange = (event) => {
        setVehicleId(event.target.value);
    };

    const handleFromTimeChange = (selectedDates) => {
        const date = selectedDates[0];
        const timeString = date.toLocaleTimeString("en-US", {
            hour12: false,
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
        });
        setFromTime(timeString);
        console.log(fromTime);
    };
    const handleToTimeChange = (selectedDates) => {
        const date = selectedDates[0];
        const timeString = date.toLocaleTimeString("en-US", {
            hour12: false,
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
        });
        setToTime(timeString);
        console.log(toTime);
    };
    const handleSubmit = () => {
        const formData = {
            "id":orderId,
            "vehicle_id": vehicleId,
            "from_date": moment(fromDate).format('DD/MM/YYYY'),
            "from_time": fromTime,
            "to_date":moment(toDate).format('DD/MM/YYYY'),
            "to_time": toTime,
            "reason": reason
        };
        console.log(formData)

        const fromMoment = moment(fromDate, 'YYYY-MM-DD');
        const toMoment = moment(toDate, 'YYYY-MM-DD');

        if (formData.to_date===undefined || formData.to_date==="Invalid date" ){
            toast.error("Enter 'To date' value ")
        }
        else {
        if (!isEdit){
        axios.post("api/vehicle-unavailabilityStore", formData)
            .then(res => {
                console.log(res);
                tog_standard();
                loadTable();
                toast.success("Vehicle unavailability added successfully!")
            })
        }
        else if (isEdit) {
            axios.post("api/vehicle-unavailabilityUpdate", formData)
                .then(res => {
                    console.log(res);
                    tog_standard();
                    loadTable();
                })
        }
    }
    }
    const handleReset=()=>{
        setCreatedDateEnd(moment().format(org_date.date_picker_format));
        setCreatedDateStart(moment().subtract(1, 'months').format(org_date.date_picker_format));
        setselectedVehicle(null)
        loadTable()
    }
    console.log(toDate)
    const handleDeleteOrder = () => {
        axios.get(`${"api/vehicle-unavailabilityDestroy/"}${id}`)
            .then(res => {
                console.log("deleted");
                loadTable();
                setDeleteModal(false);

            })
            .catch(err => {
                console.log(id)
            })
    };

    const handleEdit = (orderId) => {
        axios.get(`${"api/vehicle-unavailabilityEdit/"}${orderId}`)
            .then(res => {
                console.log(res);
                setFromDate(moment(res.data.from_date, 'DD/MM/YYYY').format('YYYY-MM-DD'));
                setToDate(moment(res.data.to_date, 'DD/MM/YYYY').format('YYYY-MM-DD'));
                setFromTime(res.data.from_time);
                setToTime(res.data.to_time);
                setVehicleId(res.options.id);
                setReason(res.data.reason);

            })
            .catch(err => {
                console.log(err);
            })
    }
    //delete Conatct
    const handleApply = (event) => {
        event.preventDefault();
        const selectedDateFormat = org_date?.date_format;
        console.log(dateEnd, dateStart, selectedVehicle)
        let filterData={
            'from_date':createdDateStart,
            'to_date':createdDateEnd,
            'vehicle':filteredVehicleValue,
            'tenant_id':tenant_id,
            'selected_date_format': selectedDateFormat
        }
        console.log(filterData)
        axios.post("api/vehicle-unavailability/data",filterData)
            .then(res => {
                console.log(res);
                setData(res);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
            setContact(null);
        } else {
            setModal(true);
            setTag([]);
            setAssignTag([]);
        }
    }, [modal]);

    // Delete Data

    const onClickDelete = (order) => {
        setId(order);
        console.log(order)
        setDeleteModal(true);
    };

    // Date & Time Format

    const dateFormat = () => {
        var d = new Date(),
            months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return (d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear());
    };


    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            // img: (contact && contact.img) || '',
            name: (contact && contact.name) || '',
            company: (contact && contact.company) || '',
            designation: (contact && contact.designation) || '',
            email: (contact && contact.email) || '',
            phone: (contact && contact.phone) || '',
            lead_score: (contact && contact.lead_score) || '',
            tags: (contact && contact.tags) || [],
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Name"),
            company: Yup.string().required("Please Enter Company"),
            designation: Yup.string().required("Please Enter Designation"),
            email: Yup.string().required("Please Enter Email"),
            phone: Yup.string().required("Please Enter Phone"),
            lead_score: Yup.string().required("Please Enter lead_score"),
        }),
        onSubmit: (values) => {
            if (isEdit) {
                const updateContact = {
                    _id: contact ? contact._id : 0,
                    // img: values.img,
                    name: values.name,
                    company: values.company,
                    designation: values.designation,
                    email: values.email,
                    phone: values.phone,
                    lead_score: values.lead_score,
                    last_contacted: dateFormat(),
                    // time: timeFormat(),
                    tags: assignTag,
                };
                // update Contact 
                
                validation.resetForm();
            } else {
                const newContact = {
                    _id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
                    // img: values["img"],
                    name: values["name"],
                    company: values["company"],
                    designation: values["designation"],
                    email: values["email"],
                    phone: values["phone"],
                    lead_score: values["lead_score"],
                    last_contacted: dateFormat(),
                    // time: timeFormat(),
                    tags: assignTag,
                };
                // save new Contact
                validation.resetForm();
            }
            toggle();
        },
    });


    // Delete Multiple
    const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

    const deleteCheckbox = () => {
        const ele = document.querySelectorAll(".contactCheckBox:checked");
        ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
        setSelectedCheckBoxDelete(ele);
    };

    const [orderId,setOrderId]=useState()

    // Customber Column
    const columns = useMemo(
        () => [
            {
                Header: <input type="checkbox" id="checkBoxAll" className="form-check-input" />,
                Cell: (cellProps) => {
                    return <input type="checkbox" className="contactCheckBox form-check-input" value={cellProps.row.original._id} onChange={() => deleteCheckbox()} />;
                },
                id: '#',
            },
            {
                Header: "Vehicle",
                accessor: "vehicle_name",
                filterable: false,

            },
            {
                Header: "From Date",
                accessor: "f_date",
                filterable: false,
            },

            {
                Header: "To Date",
                accessor: "t_date",
                filterable: false,
            },

            {
                Header: "From Time",
                accessor: "from_time",
                filterable: false,
            },

            {
                Header: "To Time",
                accessor: "to_time",
                filterable: false,
            },
            {
                Header: "Reason",
                accessor: "reason",
                filterable: false,

            },
            {
                Header: "Action",
                Cell: (cellProps) => {
                    return (
                        <ul className="list-inline hstack gap-2 mb-0">

                            <li className="list-inline-item">
                                <button
                                    className="btn btn-sm btn-soft-info edit-list"
                                    onClick={() => {
                                        const orderData = cellProps.row.original;
                                        const orderId = orderData.id;
                                        setOrderId(orderId)
                                        setIsEdit(true);
                                        tog_standard();
                                        getData();
                                        handleEdit(orderId);
                                    }
                                }
                                >
                                    <i className="bx bxs-pencil fs-12 pt-1"></i>
                                </button>
                            </li>
                            <li className="list-inline-item edit">
                                <Link
                                    to="#"
                                    className="text-danger d-inline-block remove-item-btn"
                                    onClick={() => {
                                        const orderData = cellProps.row.original;
                                        const orderId = orderData.id;
                                        onClickDelete(orderId);
                                    }}
                                >
                                    <i className="ri-delete-bin-5-fill fs-16"></i>
                                </Link>
                            </li>
                        </ul>
                    );
                },
            },
        ],
        []
    );


    const [tag, setTag] = useState([]);
    const [assignTag, setAssignTag] = useState([]);
    const isFlatpickerDisabled =true

    document.title = "Vehicle Unavailability | Onexfort";
    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteOrder}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="vehicle Unavailability" pageTitle="Onexfort" />
                    <Accordion id="default-accordion-example">
                        <AccordionItem>
                            <h2 className="accordion-header" id="headingOne">
                                <button
                                    className={classnames("accordion-button fw-semibold", { collapsed: !col1 })} type="button" onClick={t_col1} style={{ cursor: "pointer" }} >
                                    Search Filters
                                </button>
                            </h2>
                            <Collapse isOpen={col1} className="accordion-collapse" id="collapseOne" >
                                <div className="accordion-body">

                                    <Row>
                                        <Col lg={2}>
                                            <div className="mb-3">
                                                <Label for="createdDateinput" className="form-label">Start Date</Label>
                                                <Flatpickr
                                                    className="form-control" placeholder='Start Date'
                                                    options={{
                                                        dateFormat: org_date?.date_format,
                                                    }}
                                                    value={createdDateStart}
                                                    onChange={(selectedDates) => {
                                                        if (selectedDates.length === 1) {
                                                            setCreatedDateStart(moment(selectedDates[0]).format(org_date?.date_picker_format));
                                                        } else {
                                                            setCreatedDateStart(null);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={2}>
                                            <div className="mb-3">
                                                <Label for="createdDateinput" className="form-label">End Date</Label>
                                                <Flatpickr
                                                    className="form-control" placeholder='End Date'
                                                    options={{
                                                        dateFormat: org_date?.date_format,
                                                    }}
                                                    value={createdDateEnd}
                                                    onChange={(selectedDates) => {
                                                        if (selectedDates.length === 1) {
                                                            setCreatedDateEnd(moment(selectedDates[0]).format(org_date?.date_picker_format));
                                                        } else {
                                                            setCreatedDateEnd(null);
                                                        }
                                                    }}
                                                    
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="mb-3">
                                                <Label for="leadStatusinput" className="form-label">Vehicle</Label>
                                                <Select
                                                    value={selectedVehicle}
                                                    isMulti={true}
                                                    onChange={handleVehicleMulti}
                                                    options={vehicleOptions.map((option, index) => ({
                                                        value: option.id,
                                                        label: option.vehicle_name
                                                    }))}

                                                />
                                            </div>
                                        </Col>

                                        <Col lg={4}>
                                            <div className="text-start">
                                                <button type="submit" className="btn form-btn-marg btn-primary"
                                                    onClick={handleApply}>Apply</button>
                                                <button type="submit" className="btn form-btn-marg btn-primary" onClick={handleReset} >Reset</button>
                                            </div>

                                        </Col>
                                    </Row>
                                </div>
                            </Collapse>
                        </AccordionItem>
                    </Accordion>
                    <Card>
                        <CardHeader>
                            <div className="d-flex align-items-center flex-wrap gap-2">
                                <div className="flex-grow-1">
                                    <button
                                        className="btn btn-brown add-btn"
                                        onClick={addUnavailabilityBtn}
                                    >
                                        <i className="ri-add-fill me-1 align-bottom"></i> Add Unavailability
                                    </button>
                                </div>

                            </div>
                        </CardHeader>
                        <CardBody className="pt-0">
                            <div>
                                <TableContainer
                                    columns={columns}
                                    data={(data || [])}
                                    isGlobalFilter={true}
                                    isAddUserList={false}
                                    customPageSize={8}
                                    className="custom-header-css"
                                    divClass="table-responsive table-card mb-3"
                                    tableClass="align-middle table-nowrap"
                                    theadClass="table-light"
                                    isContactsFilter={true}
                                    SearchPlaceholder="Search for contact..."
                                />

                            </div>

                            <Modal id="showModal" isOpen={modal_standard} centered>
                                <ModalHeader className="bg-soft-info p-3" >
                                    {!!isEdit ? "Edit Vehicle" : "Add Vehicle Unavailability"}
                                </ModalHeader>

                                <Form className="tablelist-form" onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSubmit();
                                    return false;
                                }}>
                                    <ModalBody>
                                        <Input type="hidden" id="id-field" />
                                        <Row className="g-3">
                                            <Col lg={12}>
                                                <Label for="leadStatusinput" className="form-label">Vehicle</Label>

                                                {isEdit?(<select className="form-select mb-3" aria-label="Default select example"
                                                    onChange={handleOptionChange}
                                                    disabled={true}
                                                >
                                                    {vehicleOptions.map((option, index) => (
                                                        <option key={option.id} value={option.id}>{option.vehicle_name}</option>
                                                    ))}
                                                </select>):(<select className="form-select mb-3" aria-label="Default select example"
                                                    onChange={handleOptionChange}
                                                >
                                                    {vehicleOptions.map((option, index) => (
                                                        <option key={option.id} value={option.id}>{option.vehicle_name}</option>
                                                    ))}
                                                </select>)}

                                            </Col>
                                            <Col lg={6}>
                                                <Label className="form-label">From Date</Label>
                                                {isEdit?(<Flatpickr
                                                    className="form-control"
                                                    value={fromDate}
                                                    disabled={isFlatpickerDisabled}
                                                />):(<Flatpickr
                                                    className="form-control"
                                                    value={fromDate}
                                                    onChange={(selectedDates) => {
                                                        console.log(selectedDates)
                                                        const selectedDate = selectedDates[0];
                                                        setFromDate(moment(selectedDate).format('YYYY-MM-DD'));
                                                        setChangeToDate(moment(selectedDate).toDate())
                                                    }}
                                                    options={{
                                                        disable: [
                                                            disablePreviousDates,
                                                        ],
                                                    }}
                                                />)}
                                            </Col>
                                            <Col lg={6}>
                                                <div>
                                                    <Label className="form-label ">From Time</Label>
                                                    <Flatpickr
                                                        className="form-control"
                                                        placeholder='03:05:00' 
                                                        value={fromTime}
                                                        options={{
                                                            enableTime: true,
                                                            enableSeconds: true,
                                                            noCalendar: true,
                                                            dateFormat: "H:i:S",
                                                            onChange: handleFromTimeChange,
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <Label className="form-label">To Date</Label>
                                                {isEdit?(<Flatpickr
                                                    className="form-control"
                                                    options={{
                                                        dateFormat: "Y-m-d",
                                                        disable: [
                                                            disableToDateEdit,
                                                        ]                                                        
                                                    }}
                                                    value={toDate}
                                                    onChange={(selectedDates) => {
                                                        const selectedDate = selectedDates[0];
                                                        console.log(selectedDate)
                                                        setToDate(moment(selectedDate).format('YYYY-MM-DD'));
                                                    }}
                                                    
                                                />):(<Flatpickr
                                                    className="form-control"
                                                    options={{
                                                        disable: [
                                                            disableToDate,
                                                        ]
                                                    }}
                                                    value={toDate}
                                                    onChange={(selectedDates) => {
                                                        const selectedDate = selectedDates[0];
                                                        setToDate(moment(selectedDate).format('YYYY-MM-DD'));
                                                    }}
                                                />)}
            
                                            </Col>
                                            <Col lg={6}>
                                                <div>
                                                    <Label className="form-label">To Time</Label>
                                                    <Flatpickr
                                                        className="form-control"
                                                        placeholder='03:05:00'
                                                        value={toTime}
                                                        options={{
                                                            enableTime: true,
                                                            enableSeconds: true,
                                                            noCalendar: true,
                                                            dateFormat: "H:i:S",
                                                            onChange: handleToTimeChange,
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <Label htmlFor="VertimeassageInput" className="form-label">Reason</Label>
                                                <textarea className="form-control" id="VertimeassageInput" rows="3"
                                                    onChange={
                                                        (e) => setReason(e.target.value)}
                                                    value={reason}

                                                    placeholder="Enter your reason">
                                                </textarea>
                                            </Col>
                                        </Row>
                                    </ModalBody>
                                    <ModalFooter className='justify-content-center'>
                                        <div className="hstack gap-2 ">
                                            <button type="button" className="btn btn-danger" onClick={() => { tog_standard(); }} > Close </button>
                                            <button type="submit" className="btn btn-success" id="add-btn" > {!!isEdit ? "Update" : "Create"} </button>
                                        </div>
                                    </ModalFooter>
                                </Form>
                            </Modal>
                            <ToastContainer closeButton={false} limit={1} />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default VehicleUnavailability;
