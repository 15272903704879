import { Card, CardBody, Col, Row } from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "../../../assets/scss/pages/vehicle.scss"

import {
  CardHeader,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  ModalFooter,

} from "reactstrap";
import DeleteModal from "../../../Components/Common/DeleteModal";
import { get_cookie } from '../../../helpers/get_cookie';
import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SketchPicker } from 'react-color';

export const RemovalSettingsVehicles = () => {
  document.title="Vehicles | Onexfort";
  const [modal_large, setmodal_large] = useState(false);
  function tog_large() {
    setmodal_large(!modal_large);
  }

  const [data, setData] = useState([]);
  //fetching data table api 
  const user = JSON.parse(get_cookie("authUser"));
  var tenant_id = user.tenant_id;

  useEffect(() => {
    loadTable();
  }, [])

  const loadTable = () => {
    setisLoading(true);
    axios.get("/api/vehicles/data", tenant_id)

      .then((res) => {
        console.log(res.data);
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setisLoading(false);
      })
  }

  const [vehicleName, setVehicleName] = useState("");
  const [vehicleDescription, setVehicleDescription] = useState("");
  const [fuelType, setFuelType] = useState("");
  const [category, setCategory] = useState("");
  const [licenseNum, setLicenseNum] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [model, setModel] = useState("");
  const [modelYear, setModelYear] = useState("");
  const [payload, setPayload] = useState("");
  const [cubicCapacity, setCubicCapacity] = useState("");
  const [vehicleColor, setVehicleColor] = useState("");
  const [vehicleGroups, setVehicleGroups] = useState("");
  const paletteColors = [
              ["#000","#444","#666","#999","#ccc","#eee","#F3F3F3","#fff"],
              ["#f00","#f90","#ff0","#0f0","#0ff","#00f","#90f","#f0f"],
              ["#F4CCCC","#FCE5CD","#FFF2CC","#D9EAD3","#D0E0E3","#CFE2F3","#D9D2E9","#EAD1DC"],
              ["#EA9999","#F9CB9C","#FFE599","#B6D7A8","#A2C4C9","#9FC5E8","#B4A7D6","#D5A6BD"],
              ["#E06666","#F6B26B","#FFD966","#93C47D","#76A5AF","#6FA8DC","#8E7CC3","#C27BA0"],
              ["#c00","#E69138","#F1C232","#6AA84F","#45818E","#3D85C6","#674EA7","#A64D79"],
              ["#900","#B45F06","#BF9000","#38761D","#134F5C","#0B5394","#351C75","#741B47"],
              ["#600","#783F04","#7F6000","#274E13","#0C343D","#073763","#20124D","#4C1130"]
 ]
 const [displayColorPicker, setDisplayColorPicker] = useState(false);
 const [color, setColor] = useState({ r: 241, g: 112, b: 19, a: 1 });
 const [colour,setColour]=useState()
 const [colors,setColors] =useState();
  const [isLoading, setisLoading] = useState(false);

  const [active, setAcitve] = useState(false);
  const [id, setId] = useState(null);
  const [errorClass, setErrorClass] = useState("");

  const [isActive, setIsActive] = useState(false);

  const handleCheckboxChange = () => {
    // setIsActive(prevIsActive => !prevIsActive);
    setIsActive(prevIsActive => (prevIsActive === "Y" ? "N" : "Y"));
  };

  const exportButton=()=>{
    axios.get("/api/moving-settings/vehicles/export")
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      })
      // .finally(() => {
      //   setisLoading(false);
      // })
  }

  //store
  const handleSubmit = (e) => {
    setIsEdit(false);
    e.preventDefault();

    const formData = {
      vehicle_name: vehicleName,
      vehicle_description: vehicleDescription,
      fuel_type: fuelType,
      category: category,
      license_plate_number: licenseNum,
      manufacturer: manufacturer,
      model: model,
      model_year: modelYear,
      payload: payload,
      cubic_capacity: cubicCapacity,
      vehicle_colour: vehicleColor,
      active: isActive
    };
    if (!isEdit) {
      if (formData.vehicle_name === "") {
        toast.error('Enter Name', { theme: "light" });
        setErrorClass("nameError")
      }
      else if (formData.vehicle_description === "") {
        toast.error('Enter Description', { theme: "light" });
        setErrorClass("descriptionError")

      }
      else if (formData.fuel_type === "") {
        toast.error('Enter Fuel Type', { theme: "light" });
        setErrorClass("fuelError")
      }

      else if (formData.license_plate_number === "") {
        toast.error('Enter License Plate Number', { theme: "light" });
        setErrorClass("licenseError")
      }
      else if (formData.manufacturer === "") {
        toast.error('Enter Manufacturer', { theme: "light" });
        setErrorClass("manufacturerError")
      }
      else if (formData.model === "") {
        toast.error('Enter Model', { theme: "light" });
        setErrorClass("modelError")
      }
      else if (formData.model_year === "") {
        toast.error('Enter Model Year', { theme: "light" });
        setErrorClass("modelYearError")
      }
      else if (formData.payload === "") {
        toast.error('Enter Payload', { theme: "light" });
        setErrorClass("payloadError")
      }
      else if (formData.cubic_capacity === "") {
        toast.error('Enter Cubic Capacity', { theme: "light" });
        setErrorClass("cubicCapacityError")
      }
      else if (formData.vehicle_colour === "") {
        toast.error('Enter Vehicle Colour', { theme: "light" });
        setErrorClass("vehicleColorError")
      }
      else {
        axios.post('api/vehicles/store', formData)
          .then(response => {
            // Handle successful response
            loadTable();
            tog_large();
            console.log(response.data);
            resetForm();
          })
          .catch(error => {
            // Handle error
            console.error(error);
            console.log(formData);
          });
      }
    }
    else {
      if (formData.vehicle_name === "") {
        toast.error('Enter Name', { theme: "light" });
      }
      else if (formData.vehicle_description === "") {
        toast.error('Enter Description', { theme: "light" });

      }
      else if (formData.fuel_type === "") {
        toast.error('Enter Fuel Type', { theme: "light" });
      }

      else if (formData.license_plate_number === "") {
        toast.error('Enter License Plate Number', { theme: "light" });
      }
      else if (formData.manufacturer === "") {
        toast.error('Enter Manufacturer', { theme: "light" });
      }
      else if (formData.model === "") {
        toast.error('Enter Model', { theme: "light" });
      }
      else if (formData.model_year === "") {
        toast.error('Enter Model Year', { theme: "light" });
      }
      else if (formData.payload === "") {
        toast.error('Enter Payload', { theme: "light" });
      }
      else if (formData.cubic_capacity === "") {
        toast.error('Enter Cubic Capacity', { theme: "light" });
      }
      else if (formData.vehicle_colour === "") {
        toast.error('Enter Vehicle Colour', { theme: "light" });
      }
      else {
        axios.post(`${"api/vehicles/update/"}${id}`, formData)
          .then(response => {
            console.log(response);
            loadTable();
            tog_large();
          })
          .catch(err => {
            console.log(err);
          })
      }
    }

  };
  const isTableSuccess = true;

  // Reset form inputs to their initial values
  const resetForm = () => {
    setVehicleName("");
    setVehicleDescription("");
    setFuelType("");
    setLicenseNum("");
    setCubicCapacity("");
    setManufacturer("");
    setCategory("");
    setPayload("");
    setModel("");
    setModelYear("");
    setVehicleColor();
    setAcitve(false);
  };

  const [isEdit, setIsEdit] = useState(false);
  //delete Conatct
  const [deleteModal, setDeleteModal] = useState(false);
  const onClickDelete = (id) => {
    setId(id);
    setDeleteModal(true);
  };

  // Customber Column
  const columns = useMemo(
    () => [

      {
        Header: "Name",
        accessor: "vehicle_name",
        filterable: false,

      },
      {
        Header: "License Plate",
        accessor: "license_plate_number",
        filterable: false,
      },

      {
        Header: "Vehicle in Tons",
        accessor: "payload",
        filterable: false,
      },
      {
        Header: "Active",
        accessor: "active",
        filterable: false,
      },

      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item">
                <button
                  className="btn btn-sm btn-soft-info edit-list"
                  onClick={() => {
                    const orderData = cellProps.row.original;
                    const orderId = orderData.id;
                    setId(orderId);
                    console.log(orderId);
                    setIsEdit(true);
                    tog_large(true);
                    axios.get(`${"api/vehicles/edit/"}${orderId}`)
                      .then(res => {
                        console.log(res);
                        setVehicleName(res.template.vehicle_name);
                        setVehicleDescription(res.template.vehicle_description);
                        setFuelType(res.template.fuel_type);
                        setCategory(res.template.category);
                        setLicenseNum(res.template.license_plate_number);
                        setManufacturer(res.template.manufacturer);
                        setModel(res.template.model);
                        setModelYear(res.template.model_year);
                        setPayload(res.template.payload);
                        setCubicCapacity(res.template.cubic_capacity);
                        setVehicleColor(res.template.vehicle_colour);
                        console.log(res.template.vehicle_colour);
                        setIsActive(res.template.active);
                      })
                  }}
                >
                  <i className="bx bxs-pencil fs-12 pt-1"></i>
                  
                </button>
              </li>
              <li className="list-inline-item edit">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    const orderData = cellProps.row.original;
                    const orderId = orderData.id;
                    onClickDelete(orderId);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  const handleDeleteOrder = () => {

    axios.get(`${"api/vehicles/destroy/"}${id}`)
      .then(res => {
        loadTable();
        setDeleteModal(false);
      })

  };
const styles = {
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
        width: '199%',
      },
      color: {
        width: '357px',
        height: '14px',
        borderRadius: '2px',
        background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    };
  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);
  const handleClick = () => {
      setDisplayColorPicker(!displayColorPicker);
    };
    const handleClose = () => {
      setDisplayColorPicker(false);
    };
    const handleChange = (newColor) => {
    setVehicleColor(newColor.hex);
    setColor(newColor.rgb);
    const { r, g, b } = newColor.rgb;
    const rgbValue = `rgb(${r}, ${g}, ${b})`;
    setColor(newColor.rgb)
    setColour(rgbValue)
    };
  return (
    <div>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Card>
        <CardHeader>
          <div className="d-flex align-items-center flex-wrap gap-2">
            <div className="flex-grow-1">
              <button
                className="btn btn-brown add-btn"
                onClick={() => {
                  tog_large(true);
                  setIsEdit(false);
                  resetForm()
                }
                }
              >
                <i className="ri-add-fill me-1 align-bottom"></i> Add Vehicle
              </button>
            </div>
            <div className="flex-shrink-0">
              <div className="hstack text-nowrap gap-2">
                <button className="btn btn-soft-primary" onClick={() =>{exportButton(); setIsExportCSV(true)} }>Export To Excel</button>
              </div>
            </div>
          </div>
        </CardHeader>
      </Card>
      <Col xxl={12}>
        <Card id="contactList">
          <CardBody className="pt-0">
            <div>


              <TableContainer
                columns={columns}
                data={(data || [])}
                isGlobalFilter={true}
                isAddUserList={false}
                customPageSize={50}
                className="custom-header-css"
                divClass="table-responsive table-card mb-3"
                tableClass="align-middle table-nowrap"
                theadClass="table-light"
                isContactsFilter={true}
                SearchPlaceholder="Search for contact..."
              />
              {isLoading && (
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'rgba(255, 255, 255, 0.8)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 9999,
                  }}
                >
                  <Loader />
                </div>
              )}
            </div>
            <ToastContainer closeButton={false} limit={1} />
          </CardBody>
        </Card>
        <Modal
          className="mt-5"
          size="lg"
          isOpen={modal_large}

        >
          <Card className="mb-0">
            <form onSubmit={handleSubmit}>
              <ModalHeader className="modalHeading">
                <h5 className="text-primary">
                  {isEdit ? "Update Vehicle" : "Create Vehicle"}
                </h5>
              </ModalHeader>
              <CardBody>
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="vehicleName" className="form-label">Vehicle Name</Label>
                      <Input type="text" className={`form control ${errorClass === "nameError" ? "errorActive" : ""}`} placeholder="Enter vehicle name"
                        value={vehicleName}
                        onChange={
                          (e) => setVehicleName(e.target.value)}
                        id="vehicleName" />
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="mb-3">
                      <Label for="vehicleDescription" className="form-label">Vehicle Description</Label>
                      <Input type="text" className={`form control ${errorClass === "descriptionError" ? "errorActive" : ""}`} placeholder="Description"
                        value={vehicleDescription}
                        onChange={
                          (e) => setVehicleDescription(e.target.value)}
                        id="vehicleDescription" />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="fuelType" className="form-label">Fuel Type</Label>
                      <Input type="text" className={`form control ${errorClass === "fuelError" ? "errorActive" : ""}`} placeholder="Enter fuel type"
                        value={fuelType}
                        onChange={
                          (e) => setFuelType(e.target.value)}
                        id="fuelType" />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="licensePlateNum" className="form-label">License Plate Number</Label>
                      <Input type="tel" className={`form control ${errorClass === "licenseError" ? "errorActive" : ""}`} placeholder=""
                        value={licenseNum}
                        onChange={
                          (e) => setLicenseNum(e.target.value)}
                        id="licensePlateNum" />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="manufacturer" className="form-label" >Manufacturer</Label>
                      <Input type="text" className={`form control ${errorClass === "manufacturerError" ? "errorActive" : ""}`} placeholder=""
                        value={manufacturer}
                        onChange={
                          (e) => setManufacturer(e.target.value)}
                        id="manufacturer" />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="model" className="form-label">Model</Label>
                      <Input type="text" className={`form control ${errorClass === "modelError" ? "errorActive" : ""}`} placeholder="Model"
                        value={model}
                        onChange={
                          (e) => setModel(e.target.value)}
                        id="model" />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="modelYear" className="form-label">Model Year</Label>
                      <Input type="text" className={`form control ${errorClass === "modelYearError" ? "errorActive" : ""}`} placeholder="Year"
                        value={modelYear} onChange={
                          (e) => setModelYear(e.target.value)}
                        id="modelYear" />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="capacityInTons" className="form-label">Capacity in Tons</Label>
                      <Input type="text" className={`form control ${errorClass === "payloadError" ? "errorActive" : ""}`} placeholder="Capacity in tons"
                        value={payload}
                        onChange={
                          (e) => setPayload(e.target.value)}
                        id="capacityInTons" />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="cubicCapacity" className="form-label">Cubic Capacity</Label>
                      <Input type="text" className={`form control ${errorClass === "cubicCapacityError" ? "errorActive" : ""}`} placeholder="Cubic Capacity"
                        value={cubicCapacity}
                        onChange={
                          (e) => setCubicCapacity(e.target.value)}
                        id="cubicCapacity" />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="colourPicker" className="form-label">Vehicle Colour</Label>
                        <Col md={6}>
                          <div>
                          <div>
                            <div style={styles.swatch} onClick={handleClick}>
                              <div style={styles.color} />
                            </div>
                            {displayColorPicker && (
                              <div style={styles.popover}>
                                <div style={styles.cover} onClick={handleClose} />
                                <SketchPicker color={color} value={color} onChange={handleChange} />
                              </div>
                            )}
                          </div>   
                          </div>
                        </Col>
                      <Input type="text" className={`form control ${errorClass === "vehicleColorError" ? "errorActive" : ""}`} placeholder="Colour Picker"
                        value={vehicleColor}
                        onChange={
                          (e) => setVehicleColor(e.target.value)}
                        id="colourPicker" />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="vehicleGroups" className="form-label">Vehicle Groups</Label>
                      <select id="vehicleGroups" className="form-select" data-choices data-choices-sorting="true"
                        value={vehicleGroups}
                        onChange={(e) => setVehicleGroups(e.target.value)}
                      >
                        <option>Adelaide</option>
                        <option>Melbourne</option>
                      </select>
                    </div>
                  </Col>
                  <Col md={9}><div className="form-check mb-2">
                    <Input className="form-check-input" type="checkbox" id="checkActive"  checked={isActive === "Y"} onChange={handleCheckboxChange} />
                    <Label className="form-check-label" for="checkActive">
                      Active
                    </Label>
                    
                  </div>
                  </Col>

                </Row>
              </CardBody>
              <ModalFooter>
                <button type="submit" className="btn btn-success">
                  {!!isEdit
                    ? "Update"
                    : "Save"}</button>
                <button type="button" className="btn btn-danger" onClick={() => {
                  tog_large(false);
                  setErrorClass("")
                }} >Cancel</button>
              </ModalFooter>
            </form>
          </Card>
        </Modal>
      </Col>
    </div >
  )
}
