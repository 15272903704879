import { Card, CardBody, Col, NavLink, Row, } from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Button } from 'reactstrap';
import { Checklist } from "../TableRows";
import { isEmpty } from "lodash";
import "../../../assets/scss/pages/_settings.scss";
import {
  CardHeader,
  Label,
  Table,
} from "reactstrap";
import Select from "react-select";
//Import actions
import {
  getContacts as onGetContacts,
  addNewContact as onAddNewContact,
  updateContact as onUpdateContact,
  deleteContact as onDeleteContact,
} from "../../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteModal from "../../../Components/Common/DeleteModal";
import {
  getOrders as onGetOrders,
  addNewOrder as onAddNewOrder,
  updateOrder as onUpdateOrder,
  deleteOrder as onDeleteOrder,
} from "../../../store/ecommerce/action";
import Loader from "../../../Components/Common/Loader";


export const ListTypeOptions = () => {
  document.title="List Type And Options | Onexfort";
  const [modal, setModal] = useState(false);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setContact(null);
    } else {
      setModal(true);
      setTag([]);
      setAssignTag([]);
    }
  }, [modal]);

  const SingleOptions = [
    { value: 'Choices 1', label: 'Choices 1' },
    { value: 'Choices 2', label: 'Choices 2' },
    { value: 'Choices 3', label: 'Choices 3' },
    { value: 'Choices 4', label: 'Choices 4' }
  ];

  // Vertical Nav Tabs

  const [selectedSingle, setSelectedSingle] = useState(null);

  function handleSelectSingle(selectedSingle) {
    setSelectedSingle(selectedSingle);
  }

  const [contact, setContact] = useState([]);
  const dispatch = useDispatch();
  const { crmcontacts, isContactCreated, isContactSuccess, error } = useSelector((state) => ({
    crmcontacts: state.Crm.crmcontacts,
    isContactCreated: state.Crm.isContactCreated,
    isContactSuccess: state.Crm.isContactSuccess,
    error: state.Crm.error,
  }));

  useEffect(() => {
    setContact(crmcontacts);
  }, [crmcontacts]);

  useEffect(() => {
    if (!isEmpty(crmcontacts)) {
      setContact(crmcontacts);
      setIsEdit(false);
    }
  }, [crmcontacts]);


  const [isEdit, setIsEdit] = useState(false);

  const dateFormat = () => {
    var d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return (d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear());
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // img: (contact && contact.img) || '',
      name: (contact && contact.name) || '',
      company: (contact && contact.company) || '',
      designation: (contact && contact.designation) || '',
      email: (contact && contact.email) || '',
      phone: (contact && contact.phone) || '',
      lead_score: (contact && contact.lead_score) || '',
      tags: (contact && contact.tags) || [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      company: Yup.string().required("Please Enter Company"),
      designation: Yup.string().required("Please Enter Designation"),
      email: Yup.string().required("Please Enter Email"),
      phone: Yup.string().required("Please Enter Phone"),
      lead_score: Yup.string().required("Please Enter lead_score"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateContact = {
          _id: contact ? contact._id : 0,
          // img: values.img,
          name: values.name,
          company: values.company,
          designation: values.designation,
          email: values.email,
          phone: values.phone,
          lead_score: values.lead_score,
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // update Contact 
        dispatch(onUpdateContact(updateContact));
        validation.resetForm();
      } else {
        const newContact = {
          _id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          // img: values["img"],
          name: values["name"],
          company: values["company"],
          designation: values["designation"],
          email: values["email"],
          phone: values["phone"],
          lead_score: values["lead_score"],
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // save new Contact
        dispatch(onAddNewContact(newContact));
        validation.resetForm();
      }
      toggle();
    },
  });

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".contactCheckBox:checked");
    ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);
  const [data, setData] = useState([]);
  const [selectedListId, setSelectedListId] = useState(null)
  const [selectedListTypeId, setSelectedListTypeId] = useState();
  const [listTypetName, setListTypeName] = useState([]);
  const [newCheckInput, setNewCheckInput] = useState("");
  const [rowsCheck, setRowsCheck] = useState([]);
  const [inputValue2, setInputValue2] = useState("");
  const [tempValue, setTempValue] = useState("");
  const [groupid, setGroupId] = useState(null);
  const [updateId, setUpdateId] = useState(null);
  const [group_name, setGroupName] = useState([]);
  const [isLoading, setisLoading] = useState(false);

  const loadTable = () => {
    setisLoading(true);
    axios.get('/api/list-type-options/data')
      .then(response => {
        setGroupName(response.listtypes);
        // setGroupId(response.listtypes);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setisLoading(false);
      })
  }

  useEffect(() => {
    loadTable();
  }, []);

  // 
  const addChecklist = () => {
    const rowsInput = {
      listTypes: '',
      listOptions: '',
      listTypetName: '',
    }
    setRowsCheck([...rowsCheck, rowsInput])
  }

  const handleChange = (index, event, tableType, inputType) => {
    const { value } = event.target;
    if (tableType === 'table2') {
      setInputValue2(value);
      if (inputType === 'checklist') {
        setListTypeName((prevChecklistNames) => {
          const updatedChecklistNames = [...prevChecklistNames];
          updatedChecklistNames[index].list_option = value;
          return updatedChecklistNames;
        });
      }
    }
  };

  //
  const handleNewChange = (index, event, tableType, inputType) => {
    if (tableType === "table2") {
      if (inputType === "checklistid") {
        setSelectedListTypeId(event.target.value);
      }
      else if (inputType === "checklistname") {
        setNewCheckInput(event.target.value);
      }
    }
  };

  //Edit
  const handleEdit = (index, item, tableType) => {
    setTempValue(item.list_name);
    if (tableType === 'table2') {
      setGroupId(item.list_type_id);
      setUpdateId(item.id)
      setListTypeName((prevChecklistNames) => {
        const updatedChecklistNames = [...prevChecklistNames];
        updatedChecklistNames[index].isEditing = true;
        return updatedChecklistNames;
      });
    }
  };

  //edit-> save
  const handleSave = (index, tableType) => {
    if (tableType === "table2") {
      let data = {
        "list_type_id": selectedListTypeId,
        "update_id": updateId,
        "list_option": inputValue2
      }
      axios.post("/api/list-type-options/ajaxUpdateListOption", data)
        .then(res => {
          toast.success(res.success, { theme: "light" });
          setListTypeName((prevChecklistNames) => {
            const updatedChecklistNames = [...prevChecklistNames];
            updatedChecklistNames[index].isEditing = false;
            return updatedChecklistNames;
          })
          loadListType();
        })
        .catch(err => {
          console.log(err);
        })
    }

  };

  //edit ->cancel
  const handleCancel = (index, tableType) => {
    if (tableType === "table2") {
      loadListType();
      setListTypeName((prevChecklistNames) => {
        const updatedChecklistNames = [...prevChecklistNames];
        updatedChecklistNames[index].isEditing = false;
        return updatedChecklistNames;
      });
    }
  };

  // 
  const handleDelete = (index, item, tableType) => {
    if (tableType === "table2") {
      let data = {
        "id": item.id,
        "selected_list_id": item.list_type_id
      }
      axios.post("/api/list-type-options/ajaxDestroyListOption", data)
        .then(res => {
          toast.success(res.success, { theme: "light" });
          loadListType();
        })
        .catch(err => {
          console.log(err);
        })
    }
  }

  //create->row
  const saveTableRow = (index, tableType) => {
    if (tableType === "table2") {
      if (newCheckInput === '') {
        toast.error("The List Options field is required.", { theme: "light" });
        return; // Stop form submission if orgTime is empty
      }
      let data = {
        "list_type_id": selectedListTypeId,
        "list_option": newCheckInput
      }
      axios.post("/api/list-type-options/ajaxCreateListOption", data)
        .then(res => {
          toast.success(res.success, { theme: "light" });
          const updatedRows = [...rowsCheck];
          updatedRows.splice(index, 1);
          setRowsCheck([...updatedRows]);
          loadListType();
        })
        .catch(err => {
          console.log(err);
        })
    }
  };

  //cancel ->row
  const deleteTableRows = (index, tableType) => {
    if (tableType === "table2") {
      const updatedRows = [...rowsCheck];
      updatedRows.splice(index, 1);
      setRowsCheck([...updatedRows]);
    }

  }

  //load list type based on selected value(filter)
  const loadListType = () => {
    if (selectedListId === '' || selectedListId === null) {
      toast.error("Select the List Type.", { theme: "light" });
      return; // Stop form submission if servicing_city is empty
    }
    const data = {
      "list_type_val": selectedListId
    }
    setSelectedListTypeId(selectedListId);
    setisLoading(true);
    axios.post("/api/list-type-options/ajaxLoadListOptions", data)
      .then(res => {
        setListTypeName(res.listoptions);
      })
      .catch(err => {
        console.log(err);
      }).finally(() => {
        setisLoading(false);
      })
  }

  //Delete
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  const handleDeleteContact = () => {
    if (contact) {
      dispatch(onDeleteContact(contact._id));
      setDeleteModal(false);
    }
  };

  const onClickDelete = (itemId) => {
    setDeleteItemId(itemId);
    setDeleteModal(true);
  };


  const handleDeleteOrder = async () => {
    if (deleteItemId) {
      var orderIds = deleteItemId;
      axios.get(`${"api/list-type-options/ajaxDestroyListOption/"}${orderIds}`)
        .then(response => {
          toast.success(response.success, { theme: "light" });
          loadListType();
          setDeleteModal(false)
        })
        .catch(error => {
          console.error(error);
        });
    }
  };

  return (
    <div>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Card>
        <CardHeader>
          <h5 className="mb-0 text-primary">List Types:</h5>
        </CardHeader>
        <CardBody>

          <Table className="table table-bordered table-nowrap align-middle mb-3">
            <thead className="bg-light">
              <tr>
                <th>
                  List Type
                </th>
              </tr>
            </thead>
            <tbody>
              {group_name.map((item, index) => (
                <tr key={item.id}>
                  <td className="fw-medium">{item.list_name}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <h5 className="mb-0 text-primary" style={{ marginTop: '30px' }}> List Options:  </h5>
          <hr style={{color: '#757575'}}/>
          <div className="live-preview mb-3">
            <Row className="g-3">
              <Col md={8}>
                <Row>
                  <Col md={4}>
                    <div>
                      <Label htmlFor="iconInput" className="form-label mt-3">Choose List Type</Label>
                    </div>
                  </Col>
                  <Col md={8}>
                    <div>
                      <select className="form-select mt-2" aria-label="Default select example"
                        onChange={(event) => setSelectedListId(event.target.value)} style={{ width: '70%' }}
                      >
                        <option>Select List Type</option>
                        {group_name.map((item) => (
                          <option key={item.id} value={item.id}>{item.list_name}</option>
                        ))}
                      </select>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={4}></Col>
            </Row>
            <br />

            <Button className="btn btn-brown" onClick={() => loadListType()}> Load List Options </Button>
          </div>
          <Table className="table table-bordered   align-middle mb-3">
            <thead className="bg-light">
              <tr>
                <th className="listTypeHead" >ListType</th>
                <th>List Options</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="mb-3">
              {listTypetName.map((item, index) => (
                <tr key={item.id}>
                  <td className="fw-medium">
                    {item.isEditing ? (
                      <select
                        value={selectedListTypeId}
                        onChange={(event) => setSelectedListTypeId(event.target.value)}
                        className="form-select "
                        aria-label="Default select example"
                      >
                        {group_name.map((groupItem) => (
                          <option key={groupItem.id} value={groupItem.id}>
                            {groupItem.list_name}
                          </option>
                        ))}
                      </select>
                    ) : (
                      item.list_name
                    )}
                  </td>
                  <td className="fw-medium">
                    {item.isEditing ? (
                      <input
                        type="text"
                        value={item.list_option}
                        onChange={(event) => handleChange(index, event, "table2", "checklist")}
                        className="form-control"
                      />
                    ) : (
                      item.list_option
                    )}
                  </td>
                  <td>
                    <div className="hstack gap-3 flex-wrap">
                      {item.isEditing ? (
                        <>
                          <button
                            className="btn btn-success"
                            onClick={() => handleSave(index, "table2")}
                          >
                            Update
                          </button>
                          <button
                            className="btn btn-primary"
                            onClick={() => handleCancel(index, "table2")}
                          >
                            Cancel
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="btn btn-sm btn-soft-info edit-list "
                            onClick={() => handleEdit(index, item, "table2")}
                          >
                            <i className="bx bxs-pencil fs-12 pt-1"></i>
                          </button>
                          <button
                            className="btn btn-sm btn-soft-danger  "
                            onClick={() => onClickDelete(item.id, "table2")}
                          >
                            <i className="ri-delete-bin-5-fill fs-16"></i>
                          </button>
                        </>

                      )}
                    </div>
                  </td>
                </tr>
              ))}
              {rowsCheck.map((data, index) => (
                <tr key={index}>
                  <td>
                    <select
                      value={selectedListTypeId}
                      onChange={(event) => { handleNewChange(index, event, "table2", "checklistid") }}
                      className="form-select "
                      name="listTypes"
                      aria-label="Default select example"
                    >
                      {group_name.map((groupItem) => (
                        <option key={groupItem.id} value={groupItem.id}>
                          {groupItem.list_name}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <input
                      type="text"
                      onChange={(event) => handleNewChange(index, event, "table2", "checklistname")}
                      className="form-control"
                    />
                  </td>

                  <td>
                    <div className="hstack gap-2">
                      <button className="btn btn-success" onClick={() => saveTableRow(index, "table2")}>
                        Save
                      </button>
                      <button className="btn btn-primary" onClick={() => deleteTableRows(index, "table2")}>
                        Cancel
                      </button>
                    </div>
                  </td>
                </tr>
              ))}

            </tbody>
            <button className="btn btn-primary mt-3" onClick={addChecklist} >+</button>
          </Table>
          {isLoading && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(255, 255, 255, 0.8)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999,
              }}
            >
              <Loader />
            </div>
          )}
        </CardBody>
      </Card>
      <ToastContainer closeButton={false} limit={1} theme="light" />
    </div>
  )
}
