import React, { useState, useEffect, useContext, useRef } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    CardHeader,
    Collapse,
    Button,
    Input,
    TabPane,
    TabContent,
    Nav,
    NavLink,
    NavItem,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';


import classnames from "classnames";
import { Link } from "react-router-dom";
import { Activity } from "./JobTabPages/Activity";
import { Operations } from "./JobTabPages/Operations";
import { Invoice } from "./JobTabPages/Invoice";
import { Inventory } from "./JobTabPages/Inventory";
import { Storage } from "./JobTabPages/Storage";
import { useParams } from "react-router-dom";
import { getJobDetails } from '../../store/actions';
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ListJobsDetail = () => {
    const flatpickrRef = useRef(null);
    const flatpickrtaskdate = useRef(null);
    const flatpickrtasktime = useRef(null);
    const dispatch = useDispatch();
    const params = useParams();
    const job_id = params.id;
    var refreshData = React.createContext();
    const { jobDetail, isLoader } = useSelector((state) => ({
        jobDetail: state.Jobs.appList,
        isLoader: state.Mailbox.isLoader,
    }));
    //const refreshData = React.createContext(dispatch(getJobDetails(job_id)));
    const [componentToShow, setComponentToShow] = useState(null);
    const [customActiveTab, setcustomActiveTab] = useState("Activity");
    const [tags, setTags] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [companyname, setCompanyName] = useState("");
    const [cubicmeters, setCubicMeters] = useState(0);
    const [companystatus, setCompanyStatus] = useState('');
    const [pickupdate, setPickupdate] = useState(null);
    const [pickupaddress, setPickupaddress] = useState('');
    const [pickupsuburb, setPickupSubUrb] = useState('');
    const [pickuppostcode, setPickupPostCode] = useState('');
    const [pickupaccessins, setPickupAccessIns] = useState('');
    const [pickupbedrooms, setPickupBedrooms] = useState('');
    const [dropoffaddress, setDropoffaddress] = useState('');
    const [dropoffsuburb, setDropoffSubUrb] = useState('');
    const [dropoffpostcode, setDropoffPostCode] = useState('');
    const [dropoffaccessins, setDropoffAccessIns] = useState('');
    const [dropoffbedrooms, setDropoffBedrooms] = useState('');
    const [taskdescription, setTaskdescription] = useState('');
    const [Taskuser, setTaskUser] = useState('');
    const [Taskdate, setTaskdate] = useState(null);
    const [Tasktime, setTasktime] = useState(null);
    const [expandedTaskId, setExpandedTaskId] = useState(null);
    const [expandedContactId, setExpandedContactId] = useState(null);
    const [contactname, setContactName] = useState('');
    const [contacttitle, setContactTitle] = useState('');
    const [contactdetail, setContactDetail] = useState([]);
    const [contacttype, setContactType] = useState([]);
    const [jobDetails, setJobDetails] = useState([]);
    const [newContact, setNewContact] = useState([]);
    const [isPushed, setIsPushed] = useState(false);
    const [org_date, setOrg_Date] = useState([]);

    const handleClick = (component) => {
        setComponentToShow(component);
        if (customActiveTab !== component) {
            setcustomActiveTab(component);
        }
    };
    useEffect(() => {
        dispatch(getJobDetails(job_id));
    }, [dispatch])

    useEffect(() => {
        setJobDetails(jobDetail);
        console.log(jobDetail)
        if (Array.isArray(jobDetail.contact_types)) {
            setNewContact([{ "contacttype": jobDetail?.contact_types[0].list_option, "contactdetail": "" }])

        }
        console.log(isPushed)
        if (Array.isArray(jobDetail.contact) && !isPushed) {

            console.log("pushed")

            jobDetail.contact.map(contact => {

                contact.contact_detail_data.push({ "detail_type": jobDetail.contact_types[0].list_option });

            });
            setIsPushed(true)
            // 
        }
    }, [jobDetail, jobDetails]);

    useEffect(() => {
        if (jobDetails !== undefined && jobDetails.tags !== undefined && jobDetails.tags !== "") {
            const parsedTags = jobDetails.tags; // Assuming jobDetails.tags is already an object
            flatpickrRef.current?.flatpickr?.setDate(jobDetails.job.job_date);
            flatpickrtaskdate.current?.flatpickr?.setDate(new Date());
            flatpickrtasktime.current?.flatpickr?.setDate(new Date());
            setTags((typeof parsedTags == "string") ? JSON.parse(parsedTags) : parsedTags);
            setCompanyName(jobDetails.companies.id);
            setCubicMeters(jobDetails.job.total_cbm);
            setCompanyStatus(jobDetails.job.job_status);
            setPickupdate(moment(new Date(jobDetails.job.job_date)).format('YYYY-MM-DD'));
            setPickupaddress(jobDetails.job.pickup_address);
            setPickupSubUrb(jobDetails.job.pickup_suburb);
            setPickupPostCode(jobDetails.job.pickup_post_code);
            setPickupAccessIns(jobDetails.job.pickup_access_restrictions);
            setPickupBedrooms(jobDetails.job.pickup_bedrooms);
            setDropoffaddress(jobDetails.job.drop_off_address);
            setDropoffSubUrb(jobDetails.job.delivery_suburb);
            setDropoffPostCode(jobDetails.job.drop_off_post_code);
            setDropoffAccessIns(jobDetails.job.drop_off_access_restrictions);
            setDropoffBedrooms(jobDetails.job.drop_off_bedrooms);
            setOrg_Date(jobDetails.organisation_settings);
        }
    }, [jobDetails]);
    console.log(jobDetails.job,"pickupaddress");
    const handleChangeData = () => {

    }
    const renderComponent = () => {
        switch (componentToShow) {
            case 'Activity':
                return <Activity data={jobDetails} onChangeData={() => dispatch(getJobDetails(job_id))} />;
            case 'Operations':
                return <Operations data={jobDetails} onChangeData={() => dispatch(getJobDetails(job_id))} />;
            case 'Invoice':
                return <Invoice data={jobDetails} onChangeData={() => dispatch(getJobDetails(job_id))} dispatch={dispatch} />;
            case 'Inventory':
                return <Inventory data={jobDetails} onChangeData={() => dispatch(getJobDetails(job_id))} />;
            case 'Storage':
                return <Storage data={jobDetails} onChangeData={() => dispatch(getJobDetails(job_id))} />;

            default:
                return <Activity data={jobDetails} onChangeData={() => dispatch(getJobDetails(job_id))} />;
        }
    }
    const [addTag, setaddTag] = useState(true);
    const [showJobDetails, setshowjobDetails] = useState(false);
    const [showPickUp, setPickUP] = useState(false);
    const [showDropoff, setDropoff] = useState(false);
    const [showaddTask, setaddtask] = useState(false);
    const [showaddContact, setaddcontact] = useState(false);
    const [showeditTask, seteditTask] = useState(false);
    const [isAddEnabled, setIsAddEnabled] = useState(false)

    const t_addTag = () => {
        setaddTag(!addTag);
        setIsAddEnabled(!isAddEnabled)
    };
    const t_jobDetails = () => {
        setshowjobDetails(!showJobDetails);
    };
    const t_PickUp = () => {
        setPickUP(!showPickUp);
    };
    const t_Dropoff = () => {
        setDropoff(!showDropoff);
    };
    const t_addtask = () => {
        setaddtask(!showaddTask);
    };
    const t_addcontact = () => {
        setaddcontact(!showaddContact);

    };
    console.log(jobDetail)



    const UpdateTask = (data) => {
        console.log(data);
        let formData = {
            lead_id: data.lead_id,
            task_id: data.id,
            description: data.description,
            task_date: data.task_date,
            task_time: data.task_time,
            user_assigned_id: data.user_assigned_id,
        };
        axios.post('/api/ajaxUpdateTask', formData).then((res) => {
            dispatch(getJobDetails(job_id));
            handleTaskEdit();
            toast.success('Task updated successfully', { theme: "light" });

        })

    };
    const handleTaskEdit = (taskId) => {
        setExpandedTaskId(taskId);
    };
    const handleContactEdit = (taskId) => {
        setExpandedContactId(taskId);
    };
    const handleTaskDelete = (data) => {
        var postData = {
            "lead_id": data.lead_id,
            "task_id": data.id,
        }
        axios.post("/api/ajaxDestroyTask", postData).then((res) => {
            dispatch(getJobDetails(job_id));
            toast.success(res.message, { theme: "light" });
        })
        //seteditTask(!showeditTask);
    };
    const handleContactDelete = (data) => {
        var postData = {
            "lead_id": data.lead_id,
            "contact_id": data.id,
        }
        axios.post("/api/ajaxDestroyContact", postData).then((res) => {
            dispatch(getJobDetails(job_id));
            toast.success(res.message, { theme: "light" });
        })
        //seteditTask(!showeditTask);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && inputValue.trim() !== '') {
            event.preventDefault();
            setTags([...tags, inputValue.trim()]);
            setInputValue('');
        }
    }

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    }

    const removeTag = (index) => {
        const updatedTags = [...tags];
        updatedTags.splice(index, 1);
        setTags(updatedTags);
    }

    const save_tags = () => {
        var data = {
            "id": job_id,
            "items": tags,
        }
        axios.post('/api/moving/ajaxSaveTag', data).then((res) => {
            // const parsedTags = JSON.parse(res.jobtags.replace(/'/g, ''));
            // setTags(parsedTags);
            t_addTag();
            toast.success('Tags saved successfully', { theme: "light" });

        })
    }
    const save_jobDetails = () => {
        var data = {
            "company_id": companyname,
            "total_cbm": cubicmeters,
            "job_status": companystatus,
            "job_id": job_id
        }
        axios.post('/api/ajaxUpdateJobDetail', data).then((res) => {
            dispatch(getJobDetails(job_id));
            setshowjobDetails(!showJobDetails);
            toast.success('Job Details have been updated', { theme: "light" });

        })
    }
    const save_PickUp = () => {
        var data = {
            "job_date": pickupdate,
            "pickup_address": pickupaddress,
            "pickup_suburb": pickupsuburb,
            "pickup_postcode": pickuppostcode,
            "pickup_access_restrictions": pickupaccessins,
            "pickup_bedrooms": pickupbedrooms,
            "job_id": job_id
        }
        console.log(data)
        if (data.pickup_address === "" || data.pickup_address === undefined) {
            toast.error("Enter the Pickup Address", { theme: "light" });
        }
        else if (data.pickup_suburb === "" || data.pickup_suburb === undefined) {
            toast.error("Enter the Suburb Value", { theme: "light" });
        }
        else if (data.pickup_postcode === "" || data.pickup_postcode === undefined) {
            toast.error("Enter the Pickup Postcode", { theme: "light" });
        }
        else if (data.pickup_access_restrictions === "" || data.pickup_access_restrictions === undefined) {
            toast.error("Enter the Access Instruction", { theme: "light" });
        }
        else {
            axios.post('api/ajaxUpdateJobPickup', data).then((res) => {
                dispatch(getJobDetails(job_id));
                setPickUP(!showPickUp);
                toast.success('Pickup details have been updated', { theme: "light" });
            })
        }
    }
    const save_Dropoff = () => {
        var data = {
            "drop_off_address": dropoffaddress,
            "delivery_suburb": dropoffsuburb,
            "drop_off_postcode": dropoffpostcode,
            "drop_off_access_restrictions": dropoffaccessins,
            "drop_off_bedrooms": dropoffbedrooms,
            "job_id": job_id
        }
        if (data.drop_off_address === "" || data.drop_off_address === undefined) {
            toast.error("Enter the Delivery Address", { theme: "light" });
        }
        else if (data.delivery_suburb === "" || data.delivery_suburb === undefined) {
            toast.error("Enter the Suburb Value", { theme: "light" });
        }
        else if (data.drop_off_postcode === "" || data.drop_off_postcode === undefined) {
            toast.error("Enter the Postcode", { theme: "light" });
        }
        else if (data.drop_off_access_restrictions === "" || data.drop_off_access_restrictions === undefined) {
            toast.error("Enter the Access Instruction", { theme: "light" });
        }
        else {
            axios.post('/api/ajaxUpdateJobDropoff', data).then((res) => {
                dispatch(getJobDetails(job_id));
                setDropoff(!showDropoff);
                toast.success('Dropoff details have been updated', { theme: "light" });

            })
        }
    }
    const Add_newTask = () => {
        var taskdate = (Taskdate == "" || Taskdate == null) ? moment(new Date()).format("DD/MM/YYYY") : Taskdate;
        var tasktime = (Tasktime == "" || Tasktime == null) ? moment(new Date()).format("H:mm") : Tasktime;
        var taskuser = (Taskuser == "" || Taskuser == null) ? jobDetails?.users[0]?.id : Taskuser;
        var data = {
            "description": taskdescription,
            "task_date": taskdate,
            "task_time": tasktime,
            "user_assigned_id": taskuser,
            "lead_id": jobDetails?.job?.customer_id,
        }
        if (taskdescription == "") {
            toast.error("Task description must be entered", { theme: "light" });
            return false;
        }
        axios.post('/api/ajaxStoreTask', data).then((res) => {
            dispatch(getJobDetails(job_id));
            t_addtask();
            toast.success('Task have been added successfully', { theme: "light" });

        })
    }

    const Add_newContact = () => {
        console.log(newContact);
        let contactDetail = [];
        let contactType = [];
        newContact.map(item => {
            contactDetail.push(item.contactdetail);
            contactType.push(item.contacttype)
        })
        if (contactdetail != "") {
            contactDetail.push(contactdetail);
        }
        if (contacttype != "") {
            contactType.push(contacttype);
        }
        var data = {
            "name": contactname,
            "description": contacttitle,
            "contact_detail": contactDetail,
            "contact_detail_type": contactType,
            "lead_id": jobDetails.job.customer_id,

        }
        if (contactname == "") {
            toast.error("Contact name must be entered", { theme: "light" });
            return false;
        } else if (contacttitle == "") {
            toast.error("Contact title must be entered", { theme: "light" });
            return false;
        }
        else if (data.contact_detail == "") {
            toast.error("Contact detail must be entered", { theme: "light" });
            return false;
        }
        axios.post('/api/ajaxStoreContact', data).then((res) => {
            dispatch(getJobDetails(job_id));
            //t_addtask();
            toast.success(res.message, { theme: "light" });
            setContactTitle("");
            setContactName("");
            setNewContact([{ "contacttype": jobDetails.contact_types[0].list_option, "contactdetail": "" }])
            t_addcontact();
        })
    }
    const Update_Contact = (data) => {
        var contact_detail = [];
        var contact_type = [];
        console.log(data);
        data?.contact_detail_data?.map((res) => {
            console.log(res);
            contact_detail.push(res.detail);
            contact_type.push(res.detail_type);
        })
        if (contactdetail != "") {
            contact_detail.push(contactdetail);
        }
        if (contacttype != "") {
            contact_type.push(contacttype);
        }
        var data = {
            "name": data.name,
            "description": data.description,
            "contact_detail": contact_detail,
            "contact_detail_type": contact_type,
            "lead_id": data.lead_id,
            "contact_id": data.id,
        }
        if (data.name == "") {
            toast.error("Contact name must be entered", { theme: "light" });
            return false;
        } else if (data.description == "") {
            toast.error("Contact title must be entered", { theme: "light" });
            return false;
        }
        axios.post('/api/ajaxUpdateContact', data).then((res) => {
            dispatch(getJobDetails(job_id));
            //t_addtask();
            handleContactEdit(data.id);
            toast.success(res.message, { theme: "light" });

        })
    }
    const onChangeInInput = (e) => {
        console.log(e);
    };
    return (
        <div className="page-content">
            <Container fluid>
                <Row>
                    <Col xl={3}>
                        <Card>
                            <div className="text-center lead_title">
                                <h4 className="font-weight-semibold mb-1 view_blade_1_person_name">
                                    <Link to={"/customerdetails/" + jobDetails?.crmlead?.id} className="fw-medium link-primary"> {jobDetails?.crmlead?.name}</Link>

                                    {/* {jobDetails?.crmlead?.name} */}
                                    {/* {(jobDetails?.contact?.length > 0) && jobDetails?.contact[0]?.name} */}
                                </h4>
                                <p style={{ fontWeight: 500, fontSize: '14px' }}>
                                    {jobDetails?.crmlead?.lead_type} <br />
                                    <span >Job # {jobDetails?.job?.job_number}</span>
                                </p>
                            </div>
                            <CardBody>
                                <Button className="btn btn-success btn-sm" onClick={t_addTag}>+ Tag</Button>
                                <div className="hstack gap-2">
                                    {
                                        isAddEnabled ? (
                                            null
                                        ) : (
                                            tags?.map((tag, index) => (
                                                <div
                                                    className="badge mt-3 fs-6"
                                                    style={{
                                                        color: "#000",
                                                        backgroundColor: "#F9DADA",
                                                        cursor: "pointer",
                                                    }}
                                                    key={index}
                                                >
                                                    # {tag}
                                                </div>
                                            ))
                                        )
                                    }
                                </div>

                                <Collapse isOpen={isAddEnabled} id="collapseWithicon">
                                    <div className="tags-section">
                                        {tags && tags?.map((tag, index) => (
                                            <span key={index} className="tags">{tag}<i className="ri-close-fill" onClick={() => removeTag(index)}></i></span>
                                        ))}
                                    </div>
                                    <div className="mb-0 mt-3">
                                        <Input className="form-control" placeholder="Add Tags..." value={inputValue} onKeyDown={handleKeyDown}
                                            onChange={handleInputChange} />
                                        <div className="hstack gap-2 mt-2">
                                            <Button className="btn btn-light" onClick={t_addTag} >Cancel</Button>
                                            <Button className="btn btn-info" onClick={() => { save_tags(); }}>Save</Button>
                                        </div>
                                    </div>
                                </Collapse>
                            </CardBody>

                        </Card>

                        <Card>
                            <CardHeader>
                                <div className="d-flex">
                                    <h5 className="card-title flex-grow-1 mb-0">
                                        <i className="mdi mdi-truck-fast-outline align-middle me-1 text-muted"></i>
                                        JOB DETAILS
                                    </h5>

                                </div>
                            </CardHeader>
                            <CardBody>
                                <Collapse isOpen={!showJobDetails} id="collapseWithicon">
                                    <div className="edit-icon">
                                        <button className="show_update_job_detail_btn btn btn-icon" onClick={t_jobDetails}><i className="ri-pencil-line fs-22"></i></button>
                                    </div>
                                    <div className="">
                                        <h5 className="fs-16 mt-2 mb-2">{(jobDetails && jobDetails.companies) ? jobDetails.companies.company_name : ""}</h5>
                                        <p className="text-muted mb-2">{(jobDetails && jobDetails.job) ? jobDetails.job.total_cbm : ""} cbm</p>
                                        <Link to="#" className={((jobDetails && jobDetails.job) ? (jobDetails.job.job_status == "Completed'") : "") ? "badge badge-soft-success fs-11"
                                            : "badge badge-soft-warning fs-14"}>
                                            {(jobDetails && jobDetails.job) ? jobDetails.job.job_status : ""}
                                        </Link>
                                    </div>
                                    <hr></hr>
                                    <div style={{ borderLeft: '3px solid #89dd88' }} className="card-body job_left_panel_body1">
                                        <div className="job-label-txt">
                                            Fixed Price:
                                            <table className="left_panel_table">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            Total:
                                                        </td>
                                                        <td style={{ textAlign: 'right', paddingLeft: '100px' }}>
                                                            <span id="totalInvoiceAmount">${parseFloat(jobDetails.totalAmount).toFixed(2)}</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Payments:
                                                        </td>
                                                        <td style={{ textAlign: 'right', paddingLeft: '100px' }}>
                                                            <span id="totalPaidAmount">${parseFloat(jobDetails.paidAmount).toFixed(2)}</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Balance:
                                                        </td>
                                                        <td style={{ textAlign: 'right', paddingLeft: '100px' }}>
                                                            <span id="totalBalanceAmount">${parseFloat(jobDetails.totalAmount - jobDetails.paidAmount).toFixed(2)}</span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div id="payment_status">
                                            {((jobDetails.payment_status == "Paid")) ?
                                                <Link to="#" className="badge badge-soft-success fs-11">
                                                    {jobDetails.payment_status}
                                                </Link> : <Link to="#" className="badge badge-soft-warning fs-11">
                                                    {jobDetails.payment_status}
                                                </Link>}
                                        </div>
                                    </div>

                                </Collapse>
                                <Collapse isOpen={showJobDetails} id="collapseWithicon">
                                    <div className="mb-0 mt-3">
                                        Company
                                        <div>
                                            <select className="form-select" value={companyname} onChange={(e) => {
                                                setCompanyName(e.target.value);
                                            }}>
                                                {jobDetails.company_list?.map((res) => (
                                                    <option key={res.id} value={res.id}>
                                                        {res.company_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        Total Cubic Meters
                                        <div>
                                            <Input className="form-control" type="number" value={cubicmeters} placeholder="0.00"
                                                onChange={(e) => {
                                                    setCubicMeters(e.target.value);
                                                }} />
                                        </div>
                                        Status
                                        <div>
                                            <select className="form-select"
                                                value={companystatus}
                                                onChange={(e) => {
                                                    setCompanyStatus(e.target.value);
                                                }}>
                                                {jobDetails.job_status?.map((res) => (
                                                    <option key={res.options} value={res.options}>
                                                        {res.options}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        {/* <Input className="form-control" placeholder="Add Tags..." value={inputValue} onKeyDown={handleKeyDown}
                                onChange={handleInputChange} /> */}
                                        <div className="hstack gap-2 mt-2">
                                            <Button className="btn btn-light" onClick={t_jobDetails} >Cancel</Button>
                                            <Button className="btn btn-info" onClick={() => { save_jobDetails(); }}>Update</Button>
                                        </div>
                                    </div>
                                </Collapse>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader>
                                <div className="d-flex">
                                    <h5 className="card-title flex-grow-1 mb-0">
                                        PICK UP
                                    </h5>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Collapse isOpen={!showPickUp} id="collapsepickup">
                                    <div className="edit-icon">
                                        <button className="show_update_job_detail_btn btn btn-icon" onClick={t_PickUp}><i className="ri-pencil-line fs-22"></i></button>
                                    </div>
                                    <div className="">
                                        {(jobDetails && jobDetails.job && jobDetails.job.pickup_suburb) ?
                                            <><p className="text-muted mb-0">{jobDetails.job.pickup_address}</p>
                                                <h6 className="fs-14 mb-2">{jobDetails.job.pickup_suburb} {jobDetails.job.pickup_post_code}</h6>
                                                {/* <p className="float-right date-color">{moment(jobDetails.job.job_date).format(jobDetails.global.date_format)}</p> */}
                                                <p className="float-right date-color">
                                                    {/* {moment(jobDetails.job.job_date).format('DD-MM-YYYY')} */}
                                                    {moment(jobDetails.job.job_date).format(org_date?.date_picker_format)}
                                                </p>
                                                <table className="left_panel_table">
                                                    <tbody>
                                                        {(jobDetails.job.pickup_bedrooms) ?
                                                            <><tr>
                                                                <td>Bedrooms:</td>
                                                                <td>{jobDetails.job.pickup_bedrooms}</td>
                                                            </tr></> : ""}
                                                        <tr>
                                                            <td>{jobDetails.job.pickup_access_restrictions}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </>
                                            : <><p className="muted">No pickup</p></>
                                        }
                                    </div>
                                </Collapse >
                                <Collapse isOpen={showPickUp} id="collapsepickup">
                                    Job Date
                                    <div className="pd-field">
                                        <Flatpickr
                                            className="form-control"
                                            options={{
                                                dateFormat: "Y-m-d",
                                                defaultDate: moment().format('YYYY-MM-DD'),
                                            }} ref={flatpickrRef} onChange={(selectedDates) => {
                                                console.log(selectedDates[0])
                                                if (selectedDates && selectedDates.length > 0) {
                                                    setPickupdate(moment(selectedDates[0]).format('YYYY-MM-DD'));
                                                }
                                            }} />
                                        Address
                                        <div className="pd-field">
                                            <Input className="form-control" type="text" value={pickupaddress} placeholder="Pickup address"
                                                onChange={(e) => {
                                                    setPickupaddress(e.target.value);
                                                }} />
                                        </div>
                                        Suburb
                                        <div className="pd-field">
                                            <Input className="form-control" type="text" value={pickupsuburb} placeholder="Suburb"
                                                onChange={(e) => {
                                                    setPickupSubUrb(e.target.value);
                                                }} />
                                        </div>
                                        Postcode
                                        <div className="pd-field">
                                            <Input className="form-control" type="text" value={pickuppostcode} placeholder="Postcode"
                                                onChange={(e) => {
                                                    setPickupPostCode(e.target.value);
                                                }} />
                                        </div>
                                        Access Instruction
                                        <div className="pd-field">
                                            <Input className="form-control" type="text" value={pickupaccessins} placeholder="Access instruction"
                                                onChange={(e) => {
                                                    setPickupAccessIns(e.target.value);
                                                }} />
                                        </div>
                                        Bedrooms
                                        <div className="pd-field">
                                            <Input className="form-control" type="number" value={pickupbedrooms} placeholder="Bedrooms"
                                                onChange={(e) => {
                                                    setPickupBedrooms(e.target.value);
                                                }} />
                                        </div>
                                        <div className="hstack gap-2 mt-2">
                                            <Button className="btn btn-light" onClick={t_PickUp} >Cancel</Button>
                                            <Button className="btn btn-info" onClick={() => { save_PickUp(); }}>Update</Button>
                                        </div>
                                    </div>
                                </Collapse>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader>
                                <h5 className="card-title mb-0">
                                    <i className="ri-map-pin-line align-middle me-1 text-muted"></i>{" "}
                                    DROP OFF
                                </h5>
                            </CardHeader>
                            <CardBody>
                                <Collapse isOpen={!showDropoff} id="collapsedropoff">
                                    <div className="edit-icon">
                                        <button className="show_update_job_detail_btn btn btn-icon" onClick={t_Dropoff}><i className="ri-pencil-line fs-22"></i></button>
                                    </div>
                                    <ul className="list-unstyled vstack gap-2 fs-13 mb-0">
                                        {(jobDetails && jobDetails.job && jobDetails.job.delivery_suburb) ?
                                            <><li>{jobDetails.job.drop_off_address}</li>
                                                <li>{jobDetails.job.delivery_suburb}</li>
                                                <li>{jobDetails.job.drop_off_post_code}</li>
                                                <table className="left_panel_table">
                                                    <tbody>
                                                        {(jobDetails.job.drop_off_bedrooms) ?
                                                            <tr>
                                                                <td>Bedrooms:</td>
                                                                <td>{jobDetails.job.drop_off_bedrooms}</td>
                                                            </tr> : ''}
                                                        <tr>
                                                            <td>{jobDetails.job.drop_off_access_restrictions}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </> :
                                            <><p className="muted">No dropoff</p></>
                                        }
                                    </ul>
                                </Collapse>
                                <Collapse isOpen={showDropoff} id="collapsedropoff">
                                    <div className="collapse-section">
                                        Address
                                        <div className="pd-field">
                                            <Input className="form-control" type="text" value={dropoffaddress} placeholder="Delivery address"
                                                onChange={(e) => {
                                                    setDropoffaddress(e.target.value);
                                                }} />
                                        </div>
                                        Suburb
                                        <div className="pd-field">
                                            <Input className="form-control" type="text" value={dropoffsuburb} placeholder="Suburb"
                                                onChange={(e) => {
                                                    setDropoffSubUrb(e.target.value);
                                                }} />
                                        </div>
                                        Postcode
                                        <div className="pd-field">
                                            <Input className="form-control" type="text" value={dropoffpostcode} placeholder="Postcode"
                                                onChange={(e) => {
                                                    setDropoffPostCode(e.target.value);
                                                }} />
                                        </div>
                                        Access Instruction
                                        <div className="pd-field">
                                            <Input className="form-control" type="text" value={dropoffaccessins} placeholder="Access instruction"
                                                onChange={(e) => {
                                                    setDropoffAccessIns(e.target.value);
                                                }} />
                                        </div>
                                        Bedrooms
                                        <div className="pd-field">
                                            <Input className="form-control" type="number" value={dropoffbedrooms} placeholder="Bedrooms"
                                                onChange={(e) => {
                                                    setDropoffBedrooms(e.target.value);
                                                }} />
                                        </div>
                                        <div className="hstack gap-2 mt-2">
                                            <Button className="btn btn-light" onClick={t_Dropoff} >Cancel</Button>
                                            <Button className="btn btn-info" onClick={() => { save_Dropoff(); }}>Update</Button>
                                        </div>
                                    </div>
                                </Collapse>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardHeader>
                                <div className="d-flex justify-content-between align-items-center">
                                    <h5 className="mb-0">
                                        <i className="ri-map-pin-line align-middle me-1 text-muted"></i>
                                        TASKS {jobDetails.tasks?.length}
                                    </h5>
                                    <button className="show_update_job_detail_btn btn btn-icon" onClick={t_addtask}>
                                        <i className="ri-add-line fs-22"></i>
                                    </button>
                                </div>
                            </CardHeader>
                            <CardBody >
                                <Collapse isOpen={showaddTask} id="collapsepickup">
                                    <div className="collapse-section">
                                        Task Description
                                        <div className="pd-field">
                                            <Input className="form-control" type="text" value={taskdescription} placeholder="Task Description"
                                                onChange={(e) => {
                                                    setTaskdescription(e.target.value);
                                                }} />
                                        </div>
                                        Date
                                        <div className="pd-field">
                                            <Flatpickr
                                                className="form-control"
                                                options={{
                                                    dateFormat: "Y-m-d",
                                                    defaultDate: moment().format('YYYY-MM-DD'),
                                                }} ref={flatpickrtaskdate} onChange={(selectedDates) => {
                                                    console.log(selectedDates[0])
                                                    if (selectedDates && selectedDates.length > 0) {
                                                        setTaskdate(moment(selectedDates[0]).format('YYYY-MM-DD'));
                                                    }
                                                }} />
                                        </div>
                                        Time
                                        <div className="pd-field">
                                            <Flatpickr
                                                className="form-control"
                                                options={{
                                                    enableTime: true,
                                                    noCalendar: true,
                                                    dateFormat: "H:i",
                                                    defaultDate: moment().format('H:m')
                                                }} ref={flatpickrtasktime} onChange={(selectedDates) => {
                                                    console.log(selectedDates[0])
                                                    if (selectedDates && selectedDates.length > 0) {
                                                        setTasktime(moment(selectedDates[0]).format('H:m'));
                                                    }
                                                }} />
                                        </div>
                                        Assign User
                                        <div>
                                            <select className="form-select"
                                                value={Taskuser}
                                                onChange={(e) => {
                                                    setTaskUser(e.target.value);
                                                }}>
                                                {jobDetails.users?.map((res) => (
                                                    <option key={res.id} value={res.id}>
                                                        {res.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="hstack gap-2 mt-2">
                                            <Button className="btn btn-light" onClick={t_addtask} >Cancel</Button>
                                            <Button className="btn btn-info" onClick={() => { Add_newTask() }}>Save</Button>
                                        </div>
                                    </div>
                                </Collapse>
                                {jobDetails.tasks?.map((res) => (
                                    <div className="header-elements-inline" key={res.id}>
                                        <div className="page-title d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center">
                                                <button className="btn bg-dark btn-icon btn-md txt-white">FM</button>
                                                <span className="fs-15 fw-medium">{res.description}<br />
                                                    {/* <small className="d-block text-muted fs-12">{res.task_date}</small> */}
                                                    <small className="d-block text-muted fs-12">{moment(res.task_date).format(org_date?.date_picker_format)}</small>
                                                </span>
                                            </div>
                                            <UncontrolledDropdown className="dropdown d-inline-block">
                                                <DropdownToggle className="btn fs-18" tag="button">
                                                    <i className="ri-more-2-line"></i>
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-end">
                                                    <DropdownItem className='edit-item-btn' onClick={() => { handleTaskEdit(res.id) }}>
                                                        <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>Edit
                                                    </DropdownItem>
                                                    <DropdownItem className='remove-item-btn text-danger' onClick={() => { handleTaskDelete(res) }}>
                                                        <i className="ri-delete-bin-fill align-bottom me-2 text-danger"></i>Delete
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                        <div>
                                            {expandedTaskId === res.id && (
                                                <div className="collapse-section">
                                                    Task Description
                                                    <div className="pd-field">
                                                        <Input className="form-control" type="text" value={res.description} placeholder="Task Description"
                                                            onChange={(e) => {
                                                                const updatedTasks = [...jobDetails.tasks];
                                                                const taskIndex = updatedTasks.findIndex((task) => task.id === res.id);
                                                                updatedTasks[taskIndex].description = e.target.value;
                                                                setJobDetails({ ...jobDetails, tasks: updatedTasks });
                                                            }} />
                                                    </div>
                                                    Date
                                                    <div className="pd-field">
                                                        <Flatpickr
                                                            className="form-control"
                                                            options={{
                                                                dateFormat: "Y-m-d",
                                                                defaultDate: [res.task_date]
                                                            }}
                                                            value={res.task_date}
                                                            onChange={(selectedDates) => {
                                                                if (selectedDates && selectedDates.length > 0) {
                                                                    res.task_date = moment(selectedDates[0]).format('YYYY-MM-DD')
                                                                }
                                                            }} />
                                                    </div>
                                                    Time
                                                    <div className="pd-field">
                                                        <Flatpickr
                                                            className="form-control"
                                                            options={{
                                                                enableTime: true,
                                                                noCalendar: true,
                                                                dateFormat: "H:i",
                                                            }} value={res.task_time} onChange={(selectedDates) => {
                                                                if (selectedDates && selectedDates.length > 0) {
                                                                    res.task_time = moment(selectedDates[0]).format('H:m:s');
                                                                }
                                                            }} />
                                                    </div>
                                                    Assign User
                                                    <div>
                                                        <select className="form-select"
                                                            value={res.user_assigned_id}
                                                            onChange={(e) => {
                                                                const updatedTasks = [...jobDetails.tasks];
                                                                const taskIndex = updatedTasks.findIndex((task) => task.id === res.id);
                                                                updatedTasks[taskIndex].user_assigned_id = e.target.value;
                                                                setJobDetails({ ...jobDetails, tasks: updatedTasks });
                                                            }}
                                                        // onChange={(e) => {
                                                        //     res.user_assigned_id = e.target.value
                                                        // }}
                                                        >
                                                            {jobDetails.users?.map((res) => (
                                                                <option key={res.id} value={res.id}>
                                                                    {res.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="hstack gap-2 mt-2">
                                                        <Button className="btn btn-light" onClick={() => { handleTaskEdit() }} >Cancel</Button>
                                                        <Button className="btn btn-info" onClick={() => { UpdateTask(res) }}>Save</Button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                ))}
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader>
                                <div className="d-flex justify-content-between align-items-center">
                                    <h5 className="mb-0">
                                        <i className="ri-secure-payment-line align-bottom me-1 text-muted"></i>{" "}
                                        CONTACTS
                                    </h5>
                                    <button className="show_update_job_detail_btn btn btn-icon" onClick={t_addcontact}>
                                        <i className="ri-add-line fs-22"></i>
                                    </button>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Collapse isOpen={showaddContact} id="collapsepickup">
                                    <div className="collapse-section">
                                        Name
                                        <div className="pd-field">
                                            <Input className="form-control" type="text" value={contactname} placeholder="Contact Name"
                                                onChange={(e) => {
                                                    setContactName(e.target.value);
                                                }} />
                                        </div>
                                        Title
                                        <div className="pd-field">
                                            <Input className="form-control" type="text" value={contacttitle} placeholder="Contact Title"
                                                onChange={(e) => {
                                                    setContactTitle(e.target.value)
                                                }} />
                                        </div>
                                        Contact Detail
                                        {newContact?.map(function (data, index) {
                                            return (
                                                <div className="input-group mgb-10" key={index}>
                                                    <Input type="text" className="form-control contact_detail" placeholder="Phone, email or URL" value={data.contactdetail}
                                                        onChange={(e) => {
                                                            const updatedStorageDetails = [...newContact];
                                                            // const taskIndex = updatedStorageDetails.findIndex((contact, id) => id == index);
                                                            updatedStorageDetails[index].contactdetail = e.target.value;
                                                            console.log(index)
                                                            if (newContact.length == index + 1) {
                                                                updatedStorageDetails.push({ "contacttype": jobDetails.contact_types[0].list_option, "contactdetail": "" })
                                                                console.log(newContact)

                                                            }
                                                            setNewContact(updatedStorageDetails);

                                                        }} />
                                                    <div className="input-group-append">
                                                        <select className="form-control form-control-uniform" value={data.contacttype} onChange={(e) => {
                                                            const updatedStorageDetails = [...newContact];

                                                            updatedStorageDetails[index].contacttype = e.target.value;
                                                            console.log(updatedStorageDetails)
                                                            setNewContact(updatedStorageDetails);

                                                        }}>
                                                            {jobDetails?.contact_types?.map((res) => (
                                                                <option key={res.list_option} value={res.list_option}>
                                                                    {res.list_option}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>)
                                        })}
                                        <div className="hstack gap-2 mt-2">
                                            <Button className="btn btn-light" onClick={t_addcontact} >Cancel</Button>
                                            <Button className="btn btn-info" onClick={() => { Add_newContact() }}>Save</Button>
                                        </div>
                                    </div>
                                </Collapse>

                                {jobDetails.contact?.map((res) => (
                                    <><div key={res.id}>
                                        <div className="page-title d-flex align-items-center justify-content-between">
                                            <div className=" align-items-center mb-2 contact-sec-top">
                                                <div className="">
                                                    <h6 className=" mb-1">{res.name}</h6>
                                                </div>
                                                <div className="">
                                                    <p className="mb-0 text-muted">{res.description?.substring(0, 25)}</p>
                                                </div>
                                            </div>
                                            <UncontrolledDropdown className="dropdown d-inline-block">
                                                <DropdownToggle className="btn fs-18" tag="button">
                                                    <i className="ri-more-2-line"></i>
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-end">
                                                    <DropdownItem className='edit-item-btn' onClick={() => { handleContactEdit(res.id); }}>
                                                        <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>Edit
                                                    </DropdownItem>
                                                    <DropdownItem className='remove-item-btn text-danger' onClick={() => { handleContactDelete(res); }}>
                                                        <i className="ri-delete-bin-fill align-bottom me-2 text-danger"></i>Delete
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                        {expandedContactId === res.id && (<div className="collapse-section">
                                            Name
                                            <div className="pd-field">
                                                <Input className="form-control" type="text" value={res.name} placeholder="Contact Name"
                                                    onChange={(e) => {
                                                        const updatedTasks = [...jobDetails.contact];
                                                        const taskIndex = updatedTasks.findIndex((contact) => contact.id === res.id);
                                                        updatedTasks[taskIndex].name = e.target.value;
                                                        setJobDetails({ ...jobDetails, contact: updatedTasks });
                                                    }} />
                                            </div>
                                            Title
                                            <div className="pd-field">
                                                <Input className="form-control" type="text" value={res.description} placeholder="Contact Title"
                                                    onChange={(e) => {
                                                        const updatedTasks = [...jobDetails.contact];
                                                        const taskIndex = updatedTasks.findIndex((contact) => contact.id === res.id);
                                                        updatedTasks[taskIndex].description = e.target.value;
                                                        setJobDetails({ ...jobDetails, contact: updatedTasks });
                                                    }} />
                                            </div>
                                            Contact Detail
                                            {res?.contact_detail_data.map(function (data, index) {
                                                return (
                                                    <div className="input-group mgb-10" key={index}>
                                                        <Input type="text" className="form-control contact_detail" placeholder="Phone, email or URL" value={data.detail}
                                                            onChange={(e) => {
                                                                const updatedContactDetail = res.contact_detail_data.map((item, id) => {
                                                                    if (id === index) {
                                                                        return { ...item, detail: e.target.value };
                                                                    }
                                                                    return item;
                                                                });
                                                                const updatedStorageDetails = { ...jobDetails };
                                                                const taskIndex = updatedStorageDetails.contact.findIndex((contact) => contact.id === res.id);
                                                                updatedStorageDetails.contact[taskIndex].contact_detail_data = updatedContactDetail;
                                                                setJobDetails(updatedStorageDetails);
                                                                if (index == res.contact_detail_data.length - 1) {
                                                                    res.contact_detail_data.push({ "detail_type": jobDetails.contact_types[0].list_option, "detail": "" })
                                                                }

                                                            }} />
                                                        <div className="input-group-append">
                                                            <select className="form-control form-control-uniform" value={data.detail_type} onChange={(e) => {
                                                                const updatedContactDetail = res.contact_detail_data.map((item) => {
                                                                    if (item.id === data.id) {
                                                                        return { ...item, detail_type: e.target.value };
                                                                    }
                                                                    return item;
                                                                });
                                                                const updatedStorageDetails = { ...jobDetails };
                                                                const taskIndex = updatedStorageDetails.contact.findIndex((contact) => contact.id === res.id);
                                                                updatedStorageDetails.contact[taskIndex].contact_detail_data = updatedContactDetail;
                                                                setJobDetails(updatedStorageDetails);
                                                            }}>
                                                                {jobDetails.contact_types?.map((res) => (
                                                                    <option key={res.list_option} value={res.list_option}>
                                                                        {res.list_option}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>);
                                            })}
                                            {/* <div className="input-group mgb-10">
                                                <Input type="text" className="form-control contact_detail" placeholder="Phone, email or URL" value={res.detail}
                                                    onChange={(e) => {
                                                        setContactDetail(e.target.value);
                                                    }} />
                                                <div className="input-group-append">
                                                    <select className="form-control form-control-uniform" value={contacttype} onChange={(e) => {
                                                        setContactType(e.target.value);
                                                    }}>
                                                        {jobDetails.contact_types?.map((res) => (
                                                            <option key={res.list_option} value={res.list_option}>
                                                                {res.list_option}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div> */}
                                            <div className="hstack gap-2 mt-2">
                                                <Button className="btn btn-light" onClick={() => { handleContactEdit() }}>Cancel</Button>
                                                <Button className="btn btn-info" onClick={() => { Update_Contact(res); }}>Update</Button>
                                            </div>
                                        </div>
                                        )}
                                        <div className="align-items-center mb-2 contact-sec-bottom">
                                            <div className="">
                                                <h6 className=" mb-1">Name</h6>
                                                <p className="mb-0 text-muted">{res.name}</p>
                                            </div>
                                            <div className="">
                                                <h6 className=" mb-1">Description</h6>
                                                <p className="mb-0 text-muted">{res.description?.substring(0, 25)}</p>
                                            </div>
                                            {res.contact_detail_data.map(function (data) {
                                                return (
                                                    <div className="flex-shrink-0" key={data.id}>
                                                        <h6 className="mb-1">{data.detail_type}</h6>
                                                        <p className="mb-0 text-muted ">{data.detail}</p>
                                                    </div>);
                                            })}
                                        </div>
                                    </div><div>
                                        </div></>
                                ))}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl={9}>
                        <Card>
                            <CardHeader>
                                <div className="row align-items-center">
                                    <div className="col">
                                        <Nav
                                            className="nav-tabs-custom card-header-tabs border-bottom-0"
                                            role="tablist"
                                        >
                                            <NavItem className="flex-grow-1">
                                                <NavLink
                                                    href="#"
                                                    className={classnames({
                                                        active: customActiveTab === "Activity",
                                                    })}
                                                    onClick={() => { handleClick("Activity") }}

                                                >
                                                    <h5 className="text-primary text-center" >Activity</h5>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="flex-grow-1">
                                                <NavLink
                                                    href="#"
                                                    className={classnames({
                                                        active: customActiveTab === "Operations",
                                                    })}
                                                    onClick={() => { handleClick("Operations") }}
                                                >
                                                    <h5 className="text-primary text-center">Operations</h5>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="flex-grow-1">
                                                <NavLink
                                                    href="#"
                                                    className={classnames({
                                                        active: customActiveTab === "Invoice",
                                                    })}
                                                    onClick={() => { handleClick("Invoice") }}
                                                >
                                                    <h5 className="text-primary text-center">Invoice</h5>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="flex-grow-1">
                                                <NavLink
                                                    href="#"
                                                    className={classnames({
                                                        active: customActiveTab === "Inventory",
                                                    })}
                                                    onClick={() => { handleClick("Inventory") }}
                                                >
                                                    <h5 className="text-primary text-center">Inventory</h5>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="flex-grow-1">
                                                <NavLink
                                                    href="#"
                                                    className={classnames({
                                                        active: customActiveTab === "Storage",
                                                    })}
                                                    onClick={() => { handleClick("Storage") }}
                                                >
                                                    <h5 className="text-primary text-center">Storage</h5>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </div>
                                </div>
                            </CardHeader>
                        </Card>
                        {renderComponent()}



                    </Col>
                </Row>
                <ToastContainer closeButton={false} limit={1} theme="light" />
            </Container>
        </div>
    )
}

export default React.memo(ListJobsDetail);