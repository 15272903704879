
import { Container, Row, Col, Card, CardBody, CardHeader, Nav, NavItem, } from 'reactstrap';
import StorageTabControl from './TabComponents/StorageTabControl';
import StorageTabContent from './TabComponents/StorageTabContent';
import { StorageSettingsTabPages } from './Constants/StorageSettingsConstants';
import React, { useState,useRef, useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
// import Components
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { get_cookie } from '../../helpers/get_cookie';

function StorageSettings() {

    const [activeTab, setActiveTab] = useState(StorageSettingsTabPages.StorageWarehouses);
    document.title = "Storage Settings | Onexfort";
    return (
        <div className="page-content">

            <Container fluid>

                <BreadCrumb title="Storage Settings" pageTitle="" />
                <Row>
                    <Col md="3">
                        <StorageTabControl activeTabSetter={setActiveTab} activeTab={activeTab} />
                    </Col>
                    <Col md="9">
                        <StorageTabContent activeTab={activeTab} />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default StorageSettings
