import React, { useState, useEffect } from 'react';
import { Container, Card, Modal, ModalHeader, ModalBody, Label, Button, Row, Col, Table, Input } from 'reactstrap';
import "../../assets/scss/pages/_backloading-schedule.scss";
import Flatpickr from "react-flatpickr";
import angle1 from "../../assets/images/backloading/angle1.png";
import angle2 from "../../assets/images/backloading/angle2.png";
import arrow from "../../assets/images/backloading/arrow.png";
import calendar from "../../assets/images/backloading/calendar.png";
import car from "../../assets/images/backloading/car.png";
import axios from 'axios';
import api from "../../config";
import Xmark from "../../assets/images/x-mark.png";
import New from "../../assets/images/new.png";
// import Components
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { SketchPicker } from "react-color";
import moment from "moment";
import { useRef } from 'react';
const Calendar = () => {
    const [modal_assignModal, setmodal_assignModal] = useState(false);
    const [modal_createTrip, set_createTrip] = useState(false);

    function tog_assignModal() {
        setmodal_assignModal(!modal_assignModal);
    }
    function toggle_createTrip() {
        set_createTrip(!modal_createTrip);
    }

    //colorpicker
    const flatpickrRef = useRef(null);
    const [colorCust, setcolorCust] = useState("rgba(95, 208, 243, 1)");
    const [display_Cust, setdisplay_Cust] = useState(false);
    const [data, setData] = useState([]);
    const [created_date_start, setCreatedDateStart] = useState(new Date());
const originalDate = created_date_start;
          const dateObj = new Date(originalDate);
          const monthFullName = dateObj.toLocaleString('en-US', { month: 'long' });
    useEffect(() => {
    var postData = {
      "created_date_start": created_date_start
    }
    axios.post("/api/ajax-storage-calendar", postData)
      .then((res) => {
        setData(res);
        console.log(res);
      });
  }, []);
    function handleCust() {
        setdisplay_Cust(!display_Cust);
      }
      const onSwatchHover_Cust = (color) => {
        const format1 =
          "rgba(" +
          color.rgb.r +
          "," +
          color.rgb.g +
          "," +
          color.rgb.b +
          "," +
          color.rgb.a +
          ")";
          setcolorCust(format1);
      };

    //hover-dropdown
    const [showDropdown, setShowDropdown] = useState(false);

    const handleMouseEnter = () => {
        setShowDropdown(true);
    };

    const handleMouseLeave = () => {
        setShowDropdown(false);
    };
    const changeDate = (selectedDates, dateStr, instance) => {
    setCreatedDateStart(selectedDates[0]);
    handleDateSelect(selectedDates[0]);
  };

  const handleDateSelect = (selectedDate) => {
    var postData = {
      "created_date_start": selectedDate,
    };
    axios.post("/api/ajax-storage-calendar", postData)
      .then((res) => {
        setData(res);
        console.log(res);
      });
  };
  const handleCalendarClick = () => {
  if (flatpickrRef.current) {
    flatpickrRef.current.flatpickr.open();
  }
};
const handleNextMonth = () => {
    const nextMonth = new Date(created_date_start);
    nextMonth.setMonth(created_date_start.getMonth() + 1);
    setCreatedDateStart(nextMonth);
    var postData = {
      "created_date_start": nextMonth
    }
    axios.post("/api/ajax-storage-calendar", postData)
      .then((res) => {
        setData(res);
        console.log(res);
      });
  };

  const handlePrevMonth = () => {
    const prevMonth = new Date(created_date_start);
    prevMonth.setMonth(created_date_start.getMonth() - 1);
    setCreatedDateStart(prevMonth);
    var postData = {
      "created_date_start": prevMonth
    }
    axios.post("/api/ajax-storage-calendar", postData)
      .then((res) => {
        setData(res);
        console.log(res);
      });
  };
    document.title = "Storage Calendar | Onexfort";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Backloading" pageTitle="Onexfort" />
                    {<Card className='p-2'>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-3 col-md-3">
                                </div>
                                <div className="col-lg-5 col-md-5">
                                    <div className="header-item2">
                                        <h4><span className='text-primary'></span> {monthFullName} <span onClick={handleCalendarClick}><img src={calendar} alt="" /></span>
                                        </h4>
                                        <Flatpickr
                                          ref={flatpickrRef}
                                          className="form-control"
                                          placeholder='Start Date'
                                          value={created_date_start}
                                          onChange={changeDate}
                                          options={{
                                            dateFormat: "d M, Y",
                                            enableTime: false
                                          }}
                                          //onClose={handleDateSelect}
                                         style={{marginTop:'10px',width:'150px',marginLeft:'-15px'}}/>
                                         <div className="header-item1" style={{marginLeft: '147px',marginTop: '-42px'}}>
                                        <ul>
                                            <li><a href="#" onClick={handlePrevMonth}><img src={angle1} alt="" /></a></li>
                                            <li><a href="#" onClick={handleNextMonth}><img src={angle2} alt="" /></a></li>
                                        </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>}
                    {/* <!-- table-box-start --> */}
                    <Card>
                        <div className="table-area">
                            <div className="container-fluid">
                                <div className="table-box-wrap">
                                    <div className="table-box-main">
                                        <div className="table-header bg-primary">
                                            <div className="row">
                                                <div className="col-lg-3 col-8" style={{paddingRight:'0px',borderRight: '1px solid #d2dae2'}}>
                                                    <div className="table-header-lft">
                                                        <h5>Warehouses</h5>
                                                    </div>
                                                </div>
                                                <div className="col-lg-9 col-md-12" style={{ paddingLeft: '0px' }}>
                                                <table className="date-container" style={{ width: '100%', tableLayout: 'fixed' }}>
                                                  <tbody>
                                                    <tr>
                                                      {data?.storage_unit_alloc_today_month?.map((storage) => {
                                                        const day = new Date(storage.date).getDate();
                                                        return (
                                                          <td className="table-header-rht" key={storage.date} style={{ width: `${100 / data.storage_unit_alloc_today_month.length}%`, minWidth: '50px', textAlign: 'center' }}>
                                                            <span style={{ color: 'white', display: 'inline-block', width: '100%' }}>{day}</span>
                                                          </td>
                                                        );
                                                      })}
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                        </div>
                                        {data?.storage_warehouse_units?.map((res) => {
                                          return (
                                            <React.Fragment key={res.warehouse_name}>
                                              <div className="table-subheader">
                                                <div className="row">
                                                  <div className="col-lg-3 col-8">
                                                    <div className="table-subheader-lft">
                                                      <h6>
                                                        <span>
                                                          <img src="images/arrow.png" alt="" />
                                                        </span>
                                                        {res.warehouse_name}
                                                      </h6>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-9 col-4">
                                                    <div className="table-subheader-rht">{/* Content for the right section */}</div>
                                                  </div>
                                                </div>
                                              </div>
                                              {res?.storage_units?.map((unit) => {
                                                  const allocatedValues = data?.storage_unit_alloc_today_month?.filter((month) => {
                                                    return month.unit_ids.includes(unit.id);
                                                  }).map((month) => month.allocated);
                                                  return (
                                                    <div className="table-item-wrap border-inner" key={unit?.name}>
                                                      <div className="row">
                                                        <div className="col-lg-3" style={{paddingRight:'0px',borderRight: "1px solid #d2dae2"}}>
                                                          <div className="table-item-left table-item-left10">
                                                            <h6>
                                                              <span>
                                                                <img src={car} alt="" />
                                                              </span>
                                                              {unit?.name}
                                                            </h6>
                                                          </div>
                                                        </div>
                                                        <div className="col-lg-9" style={{paddingLeft:'0px'}}>
                                                        <div className="table-item-rht">
                                                          <table className="table-container" style={{ display: 'table', width: '100%', tableLayout: 'fixed' }}>
                                                            <tbody>
                                                              <tr>
                                                                {data?.storage_unit_alloc_today_month?.map((month) => {
                                                                  const hasAllocatedValues = month.unit_ids.includes(unit.id) && allocatedValues.length > 0;

                                                                  return (
                                                                    <td className="table-header-rht" key={month.date} style={{ borderRight: '1px solid #d2dae2', width: `${100 / data.storage_unit_alloc_today_month.length}%`, textAlign: 'center' ,paddingBottom:'46px'}}>
                                                                      {hasAllocatedValues ? <img src={Xmark} className="img img-responsive img-powered-by-stripe" alt="Connect Xmark" style={{ width: '20px', height: '20px' }} /> : <img src={New} className="img img-responsive img-powered-by-stripe" alt="Connect Xmark" style={{ width: '20px', height: '20px' }} />}
                                                                    </td>
                                                                  );
                                                                })}
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                        </div>
                                                      </div>
                                                      </div>
                                                    </div>
                                                  );
                                                })}
                                            </React.Fragment>
                                          );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Calendar;
