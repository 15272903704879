import { Card, CardBody, Col, Row, CardHeader, Table } from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';

export const InventoryDefinition = () => {
  document.title="Inventory Definition | Onexfort";
  const [inventory, setInventory] = useState([]);
  const [rowsData, setRowsData] = useState([]);
  const [groupName, setGroupName] = useState([]);
  const [groupId,setGroupId]=useState();
  const [selectedChecklistId, setSelectedChecklistId] = useState()
  const [updateId, setUpdateId] = useState();
  //list table
  const loadTable = () => {
    axios.get("/api/inventoryDefinitions")
      .then(res => {
        console.log(res);
        setInventory(res);
        
      })
      .catch(err => {
        console.log(err);
      })
  }
  useEffect(() => {
    loadTable();
  }, [])


  const addTableRows = () => {
    const rowsInput = {
      groupName: '',
    }
    setRowsData([...rowsData, rowsInput]);
    axios.get("/api/inventoryGroups")
      .then(res => {
        console.log(res[0]);
        setGroupName(res);
        setSelectedChecklistId(res[0].group_id + "," + res[0].group_name);

      })
      .catch(err => {
        console.log(err)
      })
  }
  const [cbmValue, setCbmValue] = useState();
  const [inputValue, setInputValue] = useState();


  //Edit
  const handleEdit = (index, item) => {
  console.log(item)
    setUpdateId(item.id);
    setInputValue(item.item_name);
    setCbmValue(item.cbm);
    setSelectedChecklistId(item.group_id + "," + item.group_name)
    setSpecialItemValue(item.special_item);
    //setTempValue(item.group_name);
    setInventory((prevGroupNames) => {
      const updatedGroupNames = [...prevGroupNames];
      console.log(updatedGroupNames)
      updatedGroupNames[index].isEditing = true;
      return updatedGroupNames;
    });
    axios.get("/api/inventoryGroups")
      .then(res => {
        console.log(res[0]);
        setGroupName(res);
        // setSelectedChecklistId(res[0].group_id + "," + res[0].group_name);
        console.log(selectedChecklistId);

      })
      .catch(err => {
        console.log(err)
      })
  };

  //edit-> change
  const handleChange = (index, event, inputType) => {
    const { value } = event.target;
    console.log(value)
    if (inputType === "itemname") {
      setInputValue(value);
      setInventory((prevGroupNames) => {
        const updatedGroupNames = [...prevGroupNames];
        updatedGroupNames[index].item_name = value;
        return updatedGroupNames;
      });
    }
    else if (inputType === "cbm") {
      setCbmValue(value);
      setInventory((prevGroupNames) => {
        const updatedGroupNames = [...prevGroupNames];
        updatedGroupNames[index].cbm = value;
        return updatedGroupNames;
      });
    }
    console.log(value);
  };

  //edit-> save
  const handleSave = (index) => {
    let data = {
      "item_name": inputValue,
      "group_id": selectedChecklistId,
      "cbm": cbmValue,
      "special_item":specialItemValue ,
      "updateid": updateId
    }
    console.log(data);
    axios.post("/api/ajaxUpdateInventoryDefinition", data)
      .then(res => {
        console.log("successfully edited");
        loadTable()
        // setGroupName((prevGroupNames) => {
        //   const updatedGroupNames = [...prevGroupNames];
        //   updatedGroupNames[index].isEditing = false;
        //   return updatedGroupNames;
        // });

      })
      .catch(err => {
        console.log(err);

      })
  };

  //Add new row
  const [newInput, setNewInput] = useState({});
  const [newCbm, setNewCbm] = useState();
  const [specialItem, setSpecialItem] = useState();
  const [newGroupNames, setNewGroupNames] = useState([]);
  const [specialItemValue, setSpecialItemValue] = useState(inventory.map(item => item.special_item));
  const handleNewChange = (index, event, inputType) => {

    if (inputType === "itemname") {
      const { value } = event.target;
      setNewInput((prevNewInput) => ({
        ...prevNewInput,
        item_name: value,
      }));
      setNewGroupNames((prevNewGroupNames) => {
        const updatedNewGroupNames = [...prevNewGroupNames];
        updatedNewGroupNames[index] = value;
        return updatedNewGroupNames;
      });
    }
    else if (inputType === "cbm") {
      const { value } = event.target;
      setNewCbm(event.target.value);
      setNewInput((prevNewInput) => ({
        ...prevNewInput,
        cbm: value,
      }));
    }
  };

  const handleCancel = (index) => {
    loadTable();
    setInventory((prevGroupNames) => {
      const updatedGroupNames = [...prevGroupNames];
      updatedGroupNames[index].isEditing = false;
      return updatedGroupNames;
    });
  };

  const saveTableRow = (index) => {
    console.log(selectedChecklistId.split(","));
    var groupDetails = selectedChecklistId.split(",");
    console.log(groupDetails);
    let data = {
      "item_name": newInput.item_name,
      "group_id": groupDetails[0],
      "group_name": groupDetails[1],
      "cbm": newCbm,
      "special_item": specialItem
    }

    console.log(data.item_name)

    if (data.item_name === "" || data.item_name === undefined) {
      toast.error("Enter the Item Name", { theme: "light" });
    }
    else if (data.cbm === "" || data.cbm === undefined) {
      toast.error("Enter the CBM Value", { theme: "light" });
    }
    else {
      axios.post("/api/ajaxCreateInventoryDefinition", data)
        .then(res => {
          const updatedRows = [...rowsData];
          updatedRows.splice(index, 1);
          setRowsData([...updatedRows]);
          loadTable();

          //clear input field
          setNewGroupNames((prevGroupNames) => {
            const updatedGroupNames = [...prevGroupNames];
            updatedGroupNames[index] = '';
            return updatedGroupNames;
          });
        })
        .catch(err => {
          console.log(err);

        })
    }
    console.log(newInput.item_name, newCbm, selectedChecklistId, specialItem);
  }

  //cancel ->row
  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
    setNewGroupNames((prevGroupNames) => {
      const updatedGroupNames = [...prevGroupNames];
      updatedGroupNames[index] = '';
      return updatedGroupNames;
    });
  }
  return (
    <div>
      <Card>
        <CardHeader>
          <h5 className="mb-0">Inventory Definitions</h5>
        </CardHeader>
        <CardBody>
          <div className="live-preview">
            <Row>
              <Col xl={12}>
                <div className="table-responsive mt-4 mt-xl-0">
                  <Table className="table table-bordered table-nowrap align-middle mb-3">
                    <thead>
                      <tr>
                        <th scope="col">Group</th>
                        <th scope="col">Item Name</th>
                        <th scope="col">CBM (m3)</th>
                        <th scope="col">Special Item?</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {inventory?.map((item, index) => (
                        <tr key={item.id}>
                          <td className="fw-medium">
                            {item.isEditing ? (
                              <select
                                value={selectedChecklistId}
                                onChange={(event) => {
                                  setSelectedChecklistId(event.target.value);
                                }}
                                className="form-select"
                                aria-label="Default select example"
                              >
                                {groupName?.map((groupItem) => (
                                  <option key={groupItem.id} value={groupItem.group_id + "," + groupItem.group_name}>
                                    {groupItem.group_name}
                                  </option>
                                ))}
                              </select>
                            ) : (
                              item.group_name
                            )}
                          </td>
                          <td className="fw-medium">
                            {item.isEditing ? (
                              <input
                                type="text"
                                value={item.item_name}
                                onChange={(event) => handleChange(index, event, "itemname")}
                                className="form-control"
                              />
                            ) : (
                              item.item_name
                            )}
                          </td>
                          <td className="fw-medium">
                            {item.isEditing ? (
                              <input
                                type="text"
                                value={item.cbm}
                                onChange={(event) => handleChange(index, event, "cbm")}
                                className="form-control"
                              />
                            ) : (
                              item.cbm
                            )}
                          </td>
                          <td className="fw-medium">
                            {item.isEditing ? (
                              <select
                                value={specialItemValue}
                                onChange={(event) => {
                                  console.log(event.target.value);                     
                                  setSpecialItemValue(event.target.value);                     
                                }}
                                className="form-select "
                                aria-label="Default select example"
                              >
                                <option value="Yes"> Yes </option>
                                <option value="No">No </option>

                              </select>
                            ) : (
                              item.special_item
                            )}
                          </td>
                          <td>
                            <div className="hstack gap-3 flex-wrap">
                              {item.isEditing ? (
                                <>
                                  <button
                                    className="btn btn-success"
                                    onClick={() => handleSave(index)}
                                  >
                                    Save
                                  </button>
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => handleCancel(index)}
                                  >
                                    Cancel
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    className="btn btn-sm btn-soft-info edit-list "
                                    onClick={() => handleEdit(index, item)}
                                  >
                                    <i className="bx bxs-pencil fs-12 pt-1"></i>
                                  </button>
                                  <button
                                    className="btn btn-sm btn-soft-danger  "
                                  //onClick={() => handleDelete(index, item)}
                                  >
                                    <i className="ri-delete-bin-5-fill fs-16"></i>
                                  </button>
                                </>

                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                      {rowsData.map((data, index) => (
                        <tr key={index}>
                          <td>
                            <select
                              value={selectedChecklistId}
                              onChange={(event) => {
                                console.log(event.target.value);
                                setSelectedChecklistId(event.target.value)
                              }}
                              className="form-select "
                              aria-label="Default select example"
                            >
                              {groupName.map((groupItem) => (
                                <option key={groupItem.id} value={groupItem.group_id + "," + groupItem.group_name}>
                                  {groupItem.group_name}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <input
                              type="text"
                              //value={itemName[index] || ''}
                              onChange={(event) => handleNewChange(index, event, "itemname")}
                              name="groupName"
                              className="form-control"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              //value={cbm[index] || ''}
                              onChange={(event) => handleNewChange(index, event, "cbm")}
                              name="groupName"
                              className="form-control"
                            />
                          </td>
                          <td>
                            <select
                              value={specialItem}
                              onChange={(event) => setSpecialItem(event.target.value)}
                              className="form-select "
                              aria-label="Default select example"
                            >
                              <option> Yes </option>
                              <option>No </option>

                            </select>
                          </td>
                          <td>
                            <div className="hstack gap-2">
                              <button className="btn btn-success" onClick={() => saveTableRow(index)}
                              >
                                Save
                              </button>
                              <button className="btn btn-primary" onClick={() => deleteTableRows(index)}
                              >
                                Cancel
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}

                    </tbody>
                  </Table>
                  <button className="btn btn-primary" onClick={addTableRows} >+</button>
                </div>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
      <ToastContainer closeButton={false} limit={1} />
    </div>
  )
}
