import { Card, CardBody, Col, Row, Collapse } from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import TableRows from "../TableRows";
import {
  CardHeader,
  Label,
  Input,
  Table,
} from "reactstrap";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';

export const LocalMovesHourlySettings = () => {
  document.title="Local Moves Hourly Settings | Onexfort";
  const [dataObj, setDataObj] = useState({});
  const [truckData, setTruckData] = useState([]);
  const [depotLocation, setDepotLocation] = useState([]);
  const [region, setRegion] = useState([]);

  const [selectedRegionId, setSelectedRegionId] = useState();
  const [newSelectedRegionId, setNewSelectedRegionId] = useState();

  const loadData = () => {
    axios.get("api/hourlySettings")
      .then(res => {
        console.log(res);
        setTruckData(res.truckSizes);
        setDepotLocation(res.depotLocations);
        setRegion(res.pricingRegions);
        setNewSelectedRegionId(res.pricingRegions[0].id);
        setDataObj({
          'isUse': (res.pricingAdditional.use_hourly_pricing_local_moves === "Y") ? true : false,
          'tier1': res.pricingAdditional.local_move_excess_minutes_tier1,
          'tier2': res.pricingAdditional.local_move_excess_minutes_tier2,
          'minPrice': res.pricingAdditional.hourly_pricing_min_pricing_percent,
          'minHours': res.pricingAdditional.hourly_pricing_min_hours,
          'isPickupSuburb': (res.pricingAdditional.hourly_pricing_include_depot_pickup === "Y") ? true : false,
          'isDropoffSuburb': (res.pricingAdditional.hourly_pricing_include_drop_off_depot === "Y") ? true : false,
          'isLoadingTime': (res.pricingAdditional.hourly_pricing_include_loading_time === "Y") ? true : false,
          'isUnloadingTime': (res.pricingAdditional.hourly_pricing_include_unloading_time === "Y") ? true : false,
          'isPickupToDropoff': (res.pricingAdditional.hourly_pricing_include_pickup_drop_off === "Y") ? true : false,
          'isBookingFee': (res.pricingAdditional.hourly_pricing_has_booking_fee === "Y") ? true : false,
          'bookingFee': res.pricingAdditional.hourly_pricing_booking_fee,
          'isFixedAmount': (res.pricingAdditional.is_deposit_for_hourly_pricing_fixed_amt === "Y") ? true : false,
          'depositAmount': res.pricingAdditional.deposit_amount_hourly_pricing,
          'depositPercent': res.pricingAdditional.deposit_percent_hourly_pricing,
          'id': res.pricingAdditional.id
        })
        if (res.pricingAdditional.hourly_pricing_has_booking_fee === "Y") {

          setcoll1(false);
        }
        else {
          setcoll1(true);
        }
      })
  }

  useEffect(() => {
    loadData();
    console.log("hie")

  }, [])


  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      "use_hourly_pricing_local_moves": dataObj.isUse,
      "local_move_excess_minutes_tier1": dataObj.tier1,
      "local_move_excess_minutes_tier2": dataObj.tier2,
      "hourly_pricing_min_pricing_percent": dataObj.minPrice,
      "hourly_pricing_min_hours": dataObj.minHours,
      "hourly_pricing_include_depot_pickup": dataObj.isPickupSuburb,
      "hourly_pricing_include_drop_off_depot": dataObj.isDropoffSuburb,
      "hourly_pricing_include_loading_time": dataObj.isLoadingTime,
      "hourly_pricing_include_unloading_time": dataObj.isUnloadingTime,
      "hourly_pricing_include_pickup_drop_off": dataObj.isPickupToDropoff,
      "hourly_pricing_has_booking_fee": dataObj.isBookingFee,
      "hourly_pricing_booking_fee": dataObj.bookingFee,
      "is_deposit_for_hourly_pricing_fixed_amt": dataObj.isFixedAmount,
      "deposit_amount_hourly_pricing": dataObj.depositAmount,
      "deposit_percent_hourly_pricing": dataObj.depositPercent + "00",
      "id": dataObj.id

    }
    axios.post("api/saveHourlySettingsData", formData)
      .then(res => {

      })
      .catch(err => {
        console.log(err);
      })
    console.log(dataObj);
  }
  const [isInputsDisabled, setIsInputsDisabled] = useState(false);
  const [isBookingDisabled, setIsBookingDisabled] = useState(false);
  //toggle collapse
  const [coll1, setcoll1] = useState(true);

  const handleChange = (event, field) => {
    const { type, checked, value } = event.target;
    if (type === 'checkbox') {
      setDataObj((prevDataObj) => ({
        ...prevDataObj,
        [field]: checked,
      }));
    } else {
      setDataObj((prevDataObj) => ({
        ...prevDataObj,
        [field]: value,
      }));
    }
    if (field === "isBookingFee") {
      console.log(dataObj.isBookingFee);
      if (dataObj.isBookingFee == false) {
        setcoll1(false);
        setIsBookingDisabled(false);
      }
      else {
        setcoll1(true);
      }

    }
    if (field === "isUse" && dataObj.isUse === true) {

      setIsInputsDisabled(true);
    }
    else {
      setIsInputsDisabled(false)
    }
  };

  // input change for truck table
  const handleTruckChange = (index, event, inputType) => {
    const { value } = event.target;
    if (inputType == 'mincbm') {
      setTruckData((prevChecklistNames) => {
        const updatedChecklistNames = [...prevChecklistNames];
        updatedChecklistNames[index].min_cbm = value;
        return updatedChecklistNames;
      });
    }
    else if (inputType == 'maxcbm') {
      setTruckData((prevChecklistNames) => {
        const updatedChecklistNames = [...prevChecklistNames];
        updatedChecklistNames[index].max_cbm = value;
        return updatedChecklistNames;
      });
    }
    else if (inputType == 'trucksize') {
      setTruckData((prevChecklistNames) => {
        const updatedChecklistNames = [...prevChecklistNames];
        updatedChecklistNames[index].truck_size_in_ton = value;
        return updatedChecklistNames;
      });
    }
    else if (inputType == 'loading') {
      setTruckData((prevChecklistNames) => {
        const updatedChecklistNames = [...prevChecklistNames];
        updatedChecklistNames[index].loading_mins = value;
        return updatedChecklistNames;
      });
    }
    else if (inputType == 'unloading') {
      setTruckData((prevChecklistNames) => {
        const updatedChecklistNames = [...prevChecklistNames];
        updatedChecklistNames[index].unloading_mins = value;
        return updatedChecklistNames;
      });
    }
    else if (inputType == 'hourlyrate') {
      setTruckData((prevChecklistNames) => {
        const updatedChecklistNames = [...prevChecklistNames];
        updatedChecklistNames[index].hourly_rate = value;
        return updatedChecklistNames;
      });
    }
  }

  const handleEdit = (index, item, tableType) => {
    if (tableType === "table1") {
      setTruckData((prevGroupNames) => {
        const updatedGroupNames = [...prevGroupNames];
        updatedGroupNames[index].isEditing = true;
        return updatedGroupNames;
      });
    }
    else if (tableType === "table2") {
      setDepotLocation((prevGroupNames) => {
        const updatedGroupNames = [...prevGroupNames];
        updatedGroupNames[index].isEditing = true;
        return updatedGroupNames;
      });
    }

  }

  const handleCancel = (index, tableType) => {
    if (tableType === "table1") {
      setTruckData((prevChecklistNames) => {
        const updatedChecklistNames = [...prevChecklistNames];
        updatedChecklistNames[index].isEditing = false;
        return updatedChecklistNames;
      });
      loadData();
    }
    else if (tableType === "table2") {
      setDepotLocation((prevChecklistNames) => {
        const updatedChecklistNames = [...prevChecklistNames];
        updatedChecklistNames[index].isEditing = false;
        return updatedChecklistNames;
      });
      loadData();
    }

  }
  const handleDelete = (index, item, tableType) => {
    if (tableType === "table1") {
      var postData = {
        "id": item.id,
      };
      axios.post('api/ajaxDestroyTruckSize', postData)
        .then(res => {
          loadData();
        })
        .catch(err => {
          console.log(err);

        })
    }
    else if (tableType === "table2") {
      var postData = {
        "id": item.id,
      };
      axios.post('api/ajaxDestroyDepotLocation', postData)
        .then(res => {
          loadData();
        })
        .catch(err => {
          console.log(err);
        })
    }

  }

  const [t1MinCbm, setT1MinCbm] = useState();
  const [t1MaxCbm, setT1MaxCbm] = useState();
  const [truckSize, setTruckSize] = useState();
  const [loading, setLoading] = useState();
  const [unloading, setUnloading] = useState();
  const [hourlyRate, setHourlyRate] = useState();
  const [depotSuburb, setDepotSuburb] = useState();

  const resetRow = () => {
    setT1MaxCbm(null);
    setT1MinCbm(null);
    setTruckSize(null);
    setLoading(null);
    setUnloading(null);
    setHourlyRate(null);
  }
  const resetRegions = () => {
    setNewSelectedRegionId(region.id[0]);
    setDepotSuburb(null);
  }

  const handleSave = (index, item, tableType) => {
    if (tableType === "table1") {
      let data = {
        'local_moves_id': item.id,
        'min_cbm': item.min_cbm,
        'max_cbm': item.max_cbm,
        'truck_size_in_ton': item.truck_size_in_ton,
        'loading_mins': item.loading_mins,
        'unloading_mins': item.unloading_mins,
        'hourly_rate': item.hourly_rate,
      }
      axios.post('api/ajaxUpdateTruckSize', data)
        .then(res => {
          loadData();
        })
        .catch(err => {
          console.log(err);
        })
    }
    else if (tableType === "table2") {
      let data = {
        "depot_location_id": item.id,
        "region_id": selectedRegionId,
        "depot_suburb": item.depot_suburb
      }
      axios.post('api/ajaxUpdatedepotLocation', data)
        .then(res => {
          loadData();
        })
        .catch(err => {
          console.log(err);
        })
    }

  }
  //rowsdata
  const [rowsData, setRowsData] = useState([]);
  const addTableRows = () => {
    const rowsInput = {
      newFromRegionId: '',
      newToRegionId: '',
      suburbName: ''

    }
    setRowsData([...rowsData, rowsInput])
  }
  //Regionrowsdata
  const [regionRowsData, setRegionRowsData] = useState([]);
  const addRegionTableRows = () => {
    const rowsInput = {
      newFromRegionId: '',
      newToRegionId: '',
      suburbName: ''

    }
    setRegionRowsData([...regionRowsData, rowsInput])
  }
  //save
  const saveTableRow = (index, tableType) => {
    if (tableType === "table1") {
      let data = {
        'min_cbm': t1MinCbm,
        'max_cbm': t1MaxCbm,
        'truck_size_in_ton': truckSize,
        'loading_mins': loading,
        'unloading_mins': unloading,
        'hourly_rate': hourlyRate,
      }
      console.log(data)
      if (data.min_cbm === "" || data.min_cbm === undefined) {
        toast.error("Enter the Minimum Volume", { theme: "light" });
      }
      else if (data.max_cbm === "" || data.max_cbm === undefined) {
        toast.error("Enter the Maximum Volume", { theme: "light" });
      }
      else if (data.truck_size_in_ton === "" || data.truck_size_in_ton === undefined) {
        toast.error("Enter the Truck Size", { theme: "light" });
      }
      else if (data.loading_mins === "" || data.loading_mins === undefined) {
        toast.error("Enter the Loading Time", { theme: "light" });
      }
      else if (data.unloading_mins === "" || data.unloading_mins === undefined) {
        toast.error("Enter the Unloading Time", { theme: "light" });
      }
      else if (data.hourly_rate === "" || data.hourly_rate === undefined) {
        toast.error("Enter the Hourly Rate", { theme: "light" });
      }
      else {
        axios.post("/api/ajaxCreateTruckSize", data)
          .then(res => {
            const rows = [...rowsData];
            rows.splice(index, 1);
            setRowsData(rows);
            resetRow();
          })
          .catch(err => {
            const rows = [...rowsData];
            rows.splice(index, 1);
            setRowsData(rows);
            loadData();
          })
      }
    }
    else if (tableType === "table2") {
      let data = {
        "region_id": newSelectedRegionId,
        "depot_suburb": depotSuburb
      }
      if (data.depot_suburb === "" || data.depot_suburb === undefined) {
        toast.error("Enter the Depot Suburb", { theme: "light" });
      }
      else {
        axios.post("/api/ajaxCreatedepotLocation", data)
          .then(res => {
            const rows = [...regionRowsData];
            rows.splice(index, 1);
            setRegionRowsData(rows);
            resetRegions();
            loadData();

          })
          .catch(err => {
            const rows = [...regionRowsData];
            rows.splice(index, 1);
            setRegionRowsData(rows);
            loadData();
          })
      }
    }

  }

  // remove extra row
  const deleteTableRows = (index, tableType) => {
    if (tableType === "table1") {
      const rows = [...rowsData];
      rows.splice(index, 1);
      setRowsData(rows);
      resetRow();
    }
    else if (tableType === "table2") {
      console.log();
    }
  }
  const handleT2Change = (index, event,) => {
    const { value } = event.target;
    setDepotLocation((prevChecklistNames) => {
      const updatedChecklistNames = [...prevChecklistNames];
      updatedChecklistNames[index].depot_suburb = value;
      return updatedChecklistNames;
    });
  }

  return (
    <div>
      <Card>
        <CardHeader>
          <h5>Local Moves Hourly Settings</h5>
        </CardHeader>
        <CardBody>
          <form onSubmit={handleSubmit}>
            <div className="form-check form-check-right form-switch mb-3 " dir="ltr">
              <Input type="checkbox" className="form-check-input" id="customSwitchsizelg" checked={dataObj.isUse}
                onChange={(event) => handleChange(event, 'isUse')} />
              <Label className="form-check-label" for="customSwitchsizelg">Use Hourly Pricing for Local Move?</Label>
            </div>
            <Row className="mb-3">
              <Col lg={4} >
                <Label htmlFor="tier1input" className="form-label">Excess Minutes - Tier 1:     From 0 to </Label>
              </Col>
              <Col lg={8} className="mb-3" >
                <Input type="text" className="form-control" id="tier1input" value={dataObj.tier1} placeholder=""
                  onChange={(event) => handleChange(event, 'tier1')} disabled={isInputsDisabled} />
              </Col>
              <Col lg={4} >
                <Label htmlFor="tier2input" className="form-label">Excess Minutes - Tier 2:     From 0 to </Label>
              </Col>
              <Col lg={8} className="mb-3"  >
                <Input type="text" className="form-control" id="tier2input" value={dataObj.tier2} placeholder=" "
                  onChange={(event) => handleChange(event, 'tier2')} disabled={isInputsDisabled} />
              </Col>
              <Col lg={4} >
                <Label htmlFor="minpriceinp" className="form-label">Min Price as a percent of the  max quote price </Label>
              </Col>
              <Col lg={8} className="mb-3"  >
                <Input type="text" className="form-control" id="minpriceinp" value={dataObj.minPrice} placeholder=""
                  onChange={(event) => handleChange(event, 'minPrice')} disabled={isInputsDisabled} />
              </Col>
              <Col lg={4} >
                <Label htmlFor="invoicehrsinp" className="form-label">Minimum hours for invoice </Label>
              </Col>
              <Col lg={8} className="mb-3" >
                <Input type="text" className="form-control" id="invoicehrsinp" value={dataObj.minHours >= 0 ? dataObj.minHours : 0}
                  onChange={(event) => handleChange(event, 'minHours')} disabled={isInputsDisabled} />
              </Col>
            </Row>
            <hr></hr>
            <h5>Include In The Price Calculation:</h5>
            <hr></hr>
            <Row className="mb-3">
              <Col lg={6} >
                <div className="form-check form-check-right form-switch mb-3 " dir="ltr">
                  <Input type="checkbox" className="form-check-input " id="customSwitchsizelg" checked={dataObj.isPickupSuburb}
                    onChange={(event) => handleChange(event, 'isPickupSuburb')} />
                  <Label className="form-check-label" for="customSwitchsizelg">Time from Depot to Pickup Suburb</Label>
                </div>
              </Col>
              <Col lg={6} >
                <div className="form-check form-check-right form-switch mb-3 " dir="ltr">
                  <Input type="checkbox" className="form-check-input" id="customSwitchsizelg" checked={dataObj.isDropoffSuburb}
                    onChange={(event) => handleChange(event, 'isDropoffSuburb')} />
                  <Label className="form-check-label" for="customSwitchsizelg">Time from Drop off Suburb to Depot</Label>
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={4} >
                <div className="form-check form-check-right form-switch mb-3 " dir="ltr">
                  <Input type="checkbox" className="form-check-input" id="customSwitchsizelg" checked={dataObj.isLoadingTime}
                    onChange={(event) => handleChange(event, 'isLoadingTime')} />
                  <Label className="form-check-label" for="customSwitchsizelg">Loading Time </Label>
                </div>
              </Col>
              <Col lg={8} >
                <div className="form-check form-check-right form-switch mb-3 " dir="ltr">
                  <Input type="checkbox" className="form-check-input" id="customSwitchsizelg" checked={dataObj.isUnloadingTime}
                    onChange={(event) => handleChange(event, 'isUnloadingTime')} />
                  <Label className="form-check-label" for="customSwitchsizelg">Unloading Time</Label>
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={4} >
                <div className="form-check form-check-right form-switch mb-3 " dir="ltr">
                  <Input type="checkbox" className="form-check-input" id="customSwitchsizelg" checked={dataObj.isPickupToDropoff}
                    onChange={(event) => handleChange(event, 'isPickupToDropoff')} />
                  <Label className="form-check-label" for="customSwitchsizelg">Time from Pickup Suburb to Drop off Suburb</Label>
                </div>
              </Col>
            </Row>
            <hr></hr>
            <Row className="mb-3">
              <Col lg={4} >
                <div className="form-check form-check-right form-switch mb-3 " dir="ltr">
                  <Input type="checkbox" className="form-check-input" id="customSwitchsizelg" checked={dataObj.isBookingFee}
                    onChange={(event) => handleChange(event, 'isBookingFee')} />
                  <Label className="form-check-label" for="customSwitchsizelg">Use Booking Fee Instead of Deposit?</Label>
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={3} >
                <Label htmlFor="iconInput" className="form-label">Booking Fee</Label>
              </Col>
              <Col lg={3} >
                <div className="form-icon">
                  <Input type="number" className="form-control form-control-icon" value={dataObj.bookingFee}
                    onChange={(event) => handleChange(event, 'bookingFee')} disabled={isBookingDisabled}
                    id="iconInput" placeholder="" />
                  <i className="bx bx-dollar"></i>
                </div>
              </Col>
            </Row>
            <Collapse isOpen={coll1} id="collapseExample">
              <Row className="mb-3">
                <Col lg={4} >
                  <div className="form-check form-check-right form-switch mb-3 " dir="ltr">
                    <Input type="checkbox" className="form-check-input" id="customSwitchsizelg" checked={dataObj.isFixedAmount}
                      onChange={(event) => handleChange(event, 'isFixedAmount')} />
                    <Label className="form-check-label" for="customSwitchsizelg">Is Deposit a Fixed Amount? </Label>
                  </div>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col lg={6}>
                  <div>
                    <Label htmlFor="iconInput" className="form-label">Deposit Amount</Label>
                    <div className="form-icon">
                      <Input type="number" className="form-control form-control-icon" value={dataObj.depositAmount}
                        onChange={(event) => handleChange(event, 'depositAmount')}
                        id="iconInput" placeholder="" disabled={!dataObj.isFixedAmount} />
                      <i className="bx bx-dollar"></i>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div>
                    <Label htmlFor="iconInput" className="form-label">Deposit Percent</Label>
                    <div className="form-icon">
                      <Input type="number" className="form-control form-control-icon" value={dataObj.depositPercent}
                        onChange={(event) => handleChange(event, 'depositPercent')}
                        id="iconInput" placeholder="" disabled={dataObj.isFixedAmount} />
                      <i className="bx bx-dollar"></i>
                    </div>
                  </div>
                </Col>
              </Row>
            </Collapse>

            <hr></hr>
            <Col md={12}>
              <div className="text-left mb-3">
                <button type="submit" className="btn btn-primary">Save</button>
              </div>
            </Col>
          </form>
          <h5>Truck Size Based Rates: </h5>

          <Row className="mb-3">
            <Col xl={12}>
              <div className="table-responsive mt-4 mt-xl-0">
                <Table className="table table-bordered table-nowrap align-middle mb-3">
                  <thead>
                    <tr>
                      <th scope="col">Min Volume (m3)</th>
                      <th scope="col">Max Volume (m3)</th>
                      <th scope="col">Truck Size (tons)</th>
                      <th scope="col">Loading Time (mins)</th>
                      <th scope="col">Unloading Time (mins)</th>
                      <th scope="col">Hourly Rate</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {truckData.map((item, index) => (
                      <tr key={item.id}>
                        <td className="fw-medium">
                          {item.isEditing ? (
                            <input
                              type="text"
                              value={item.min_cbm}
                              onChange={(event) => handleTruckChange(index, event, "mincbm")}
                              className="form-control"
                            />
                          ) : (
                            item.min_cbm
                          )}
                        </td>
                        <td className="fw-medium">
                          {item.isEditing ? (
                            <input
                              type="text"
                              value={item.max_cbm}
                              onChange={(event) => handleTruckChange(index, event, "maxcbm")}
                              className="form-control"
                            />
                          ) : (
                            item.max_cbm
                          )}
                        </td>
                        <td className="fw-medium">
                          {item.isEditing ? (
                            <input
                              type="text"
                              value={item.truck_size_in_ton}
                              onChange={(event) => handleTruckChange(index, event, "trucksize")}
                              className="form-control"
                            />
                          ) : (
                            "$", item.truck_size_in_ton
                          )}
                        </td>
                        <td className="fw-medium">
                          {item.isEditing ? (
                            <input
                              type="text"
                              value={item.loading_mins}
                              onChange={(event) => handleTruckChange(index, event, "loading")}
                              className="form-control"
                            />
                          ) : (
                            item.loading_mins
                          )}
                        </td>
                        <td className="fw-medium">
                          {item.isEditing ? (
                            <input
                              type="text"
                              value={item.unloading_mins}
                              onChange={(event) => handleTruckChange(index, event, "unloading")}
                              className="form-control"
                            />
                          ) : (
                            item.unloading_mins
                          )}
                        </td>
                        <td className="fw-medium">
                          {item.isEditing ? (
                            <input
                              type="text"
                              value={item.hourly_rate}
                              onChange={(event) => handleTruckChange(index, event, "hourlyrate")}
                              className="form-control"
                            />
                          ) : (
                            item.hourly_rate
                          )}
                        </td>

                        <td>
                          <div className="hstack gap-3 flex-wrap">
                            {item.isEditing ? (
                              <>
                                <button
                                  className="btn btn-success"
                                  onClick={() => handleSave(index, item, "table1")}
                                >
                                  Save
                                </button>
                                <button
                                  className="btn btn-primary"
                                  onClick={() => handleCancel(index, "table1")}
                                >
                                  Cancel
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  className="btn btn-sm btn-soft-info edit-list "
                                  onClick={() => handleEdit(index, item, "table1")}
                                >
                                  <i className="bx bxs-pencil fs-12 pt-1"></i>
                                </button>
                                <button
                                  className="btn btn-sm btn-soft-danger  "
                                  onClick={() => handleDelete(index, item, "table1")}
                                >
                                  <i className="ri-delete-bin-5-fill fs-16"></i>
                                </button>
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                    {rowsData.map((item, index) => (
                      <tr key={index}>

                        <td>
                          <input
                            type="text"
                            value={t1MinCbm}
                            onChange={(event) => setT1MinCbm(event.target.value)}
                            name="regionName"
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={t1MaxCbm}
                            onChange={(event) => setT1MaxCbm(event.target.value)}
                            name="regionName"
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={truckSize}
                            onChange={(event) => setTruckSize(event.target.value)}
                            name="regionName"
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={loading}
                            onChange={(event) => setLoading(event.target.value)}
                            name="suburbName"
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={unloading}
                            onChange={(event) => setUnloading(event.target.value)}
                            name="suburbName"
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={hourlyRate}
                            onChange={(event) => setHourlyRate(event.target.value)}
                            name="suburbName"
                            className="form-control"
                          />
                        </td>
                        <td>
                          <div className="hstack gap-2">
                            <button className="btn btn-success" onClick={() => saveTableRow(index, "table1")}
                            >
                              Save
                            </button>
                            <button className="btn btn-primary" onClick={() => deleteTableRows(index)}
                            >
                              Cancel
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}

                  </tbody>
                </Table>
                <button className="btn btn-primary" onClick={addTableRows}
                >+</button>
              </div>
            </Col>
          </Row>
          <h5>Regional Depots: </h5>
          <Row className="mb-3">
            <Col xl={12}>
              <div className="table-responsive mt-4 mt-xl-0">
                <Table className="table table-bordered table-nowrap align-middle mb-3">
                  <thead>
                    <tr>
                      <th scope="col">Region </th>
                      <th scope="col">Depot Suburb</th>
                    </tr>
                  </thead>
                  <tbody>
                    {depotLocation?.map((item, index) => (
                      <tr key={item.id}>
                        <td className="fw-medium">
                          {item.isEditing ?
                            <select
                              value={selectedRegionId}
                              onChange={(event) => setSelectedRegionId(event.target.value)}
                              className="form-select "
                              aria-label="Default select example"
                            >
                              {region?.map((res) => (
                                <option key={res.id} value={res.id}>
                                  {res.region_name}
                                </option>
                              ))}
                            </select>
                            :
                            region?.map((res) =>
                              (res.id == item.region_id) ? res.region_name : ""
                            )
                          }
                        </td>
                        <td className="fw-medium">
                          {item.isEditing ? (
                            <input
                              type="text"
                              value={item.depot_suburb}
                              onChange={(event) => handleT2Change(index, event, "region")}
                              className="form-control"
                            />
                          ) : (
                            item.depot_suburb
                          )}
                        </td>

                        <td>
                          <div className="hstack gap-3 flex-wrap">
                            {item.isEditing ? (
                              <>
                                <button
                                  className="btn btn-success"
                                  onClick={() => handleSave(index, item, "table2")}
                                >
                                  Save
                                </button>
                                <button
                                  className="btn btn-primary"
                                  onClick={() => handleCancel(index, "table2")}
                                >
                                  Cancel
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  className="btn btn-sm btn-soft-info edit-list "
                                  onClick={() => handleEdit(index, item, "table2")}
                                >
                                  <i className="bx bxs-pencil fs-12 pt-1"></i>
                                </button>
                                <button
                                  className="btn btn-sm btn-soft-danger  "
                                  onClick={() => handleDelete(index, item, "table2")}
                                >
                                  <i className="ri-delete-bin-5-fill fs-16"></i>
                                </button>
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                    {regionRowsData.map((data, index) => (
                      <tr key={index}>
                        <td>
                          <select
                            value={newSelectedRegionId}
                            onChange={(event) => setNewSelectedRegionId(event.target.value)}
                            className="form-select "
                            aria-label="Default select example"
                          >
                            {region?.map((res) => (
                              <option key={res.id} value={res.id}>
                                {res.region_name}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td>
                          <input
                            type="text"
                            value={depotSuburb}
                            onChange={(event) => setDepotSuburb(event.target.value)}
                            name="regionName"
                            className="form-control"
                          />
                        </td>

                        <td>
                          <div className="hstack gap-2">
                            <button className="btn btn-success" onClick={() => saveTableRow(index, "table2")}
                            >
                              Save
                            </button>
                            <button className="btn btn-primary" onClick={() => deleteTableRows(index, "table1")}
                            >
                              Cancel
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <button className="btn btn-primary" onClick={addRegionTableRows}
                >+</button>
              </div>
            </Col>
          </Row>

        </CardBody>
      </Card>
      <ToastContainer closeButton={false} limit={1} />
    </div>
  )
}
