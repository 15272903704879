import { Card, CardBody, Col, Row } from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { isEmpty } from "lodash";
import {
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalFooter,

} from "reactstrap";
import { Link } from "react-router-dom";
import DeleteModal from "../../../Components/Common/DeleteModal";
//Import actions
import {
  getContacts as onGetContacts,
  addNewContact as onAddNewContact,
  updateContact as onUpdateContact,
  deleteContact as onDeleteContact,
} from "../../../store/actions";
//redux
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../../Components/Common/TableContainer";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import Loader from "../../../Components/Common/Loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export const PropertyCategoryOption = () => {
  document.title="Property Category Options | Onexfort";
  const [modal_prop_option, setmodal_prop_option] = useState(false);
  function tog_prop_option() {
    setmodal_prop_option(!modal_prop_option);
  }

  const [contact, setContact] = useState([]);

  const dispatch = useDispatch();
  const { crmcontacts, isContactCreated, isContactSuccess, error } = useSelector((state) => ({
    crmcontacts: state.Crm.crmcontacts,
    isContactCreated: state.Crm.isContactCreated,
    isContactSuccess: state.Crm.isContactSuccess,
    error: state.Crm.error,
  }));

  const [data, setData] = useState([]);
  const [id, setId] = useState(null);
  const [isEdit, setIsEdit] = useState(false);


  //List Tbale
  useEffect(() => {
    loadTable();
  }, []);

  const loadTable = () => {
    axios.get("/api/property-category-options/data")

      .then((res) => {
        console.log(res.data);
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
  }
  const [options, setOptions] = useState("");
  const [cateogryId, setCategoryId] = useState("");
  const [m3Value, setM3Value] = useState();
  const [otherValue, setOtherValue] = useState("");
  const [active, setActive] = useState(false);
  const [categories, setCategories] = useState([]);
  const [updateCategory,setUpdateCategory]=useState([]);



  //form submit
  const handleSubmit = (e) => {
    setIsEdit(false);
    e.preventDefault();
    const selectedOption = e.target.propertyGroup;
    const selectedCategoryId = selectedOption.options[selectedOption.selectedIndex].value;


    const formData = {
      options: options,
      category_id: selectedCategoryId,
      m3_value: m3Value,
      other_value: otherValue,
      active: active
    };

    console.log(m3Value)

    if (!isEdit) {
      if (formData.options===""){
        toast.error('Enter Category Options',{theme:"light"});
      }
      else if (formData.m3_value===null){
        toast.error('Enter CBM Value',{theme:"light"});
      }
      else if (formData.other_value===""){
        toast.error('Enter Value',{theme:"light"}); 
      }
      else{
      axios.post('api/property-category-options/store', formData)
        .then(response => {
          loadTable();
          tog_prop_option();
          console.log(response.data);
          resetForm();
        })
        .catch(error => {
          console.error(error);
          console.log(formData);
        });
      }
    }
    else {
      axios.post(`${"api/property-category-options/update/"}${id}`, formData)
      .then(response => {
        loadTable();
        tog_prop_option();
        console.log(response.data);
        resetForm();
      })
    }
  }

  const handleCategoryOptions = () => {
    setIsEdit(false);
    tog_prop_option(true);
    if(!isEdit){
      axios.get("/api/property-category-options/create")
      .then(res => {
        console.log(res);
        const categoryOptions = res.map(item => item.category);

        setCategories(res);
        console.log(categoryOptions);
      })
      .catch(err => {
        console.log(err);
      });
    }
  
  };

  //reset
  const resetForm = () => {
    setOptions("");
    setM3Value(null);
    setOtherValue("");
    setCategories([]);
  }
  //delete Conatct
  const [deleteModal, setDeleteModal] = useState(false);
  const [modal, setModal] = useState(false);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setContact(null);
    } else {
      setModal(true);
      setTag([]);
      setAssignTag([]);
    }
  }, [modal]);

  const onClickDelete = (id) => {
    setId(id);
    console.log(id);
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {

    axios.get(`${"api/property-category-options/destroy/"}${id}`)
    .then(res=>{
      console.log("pressed")
      
      setDeleteModal(false);
      loadTable();
    })
    .catch(err=>{
      console.log(err)
    })
  
  };
  // Date & Time Format
  const dateFormat = () => {
    var d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return (d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear());
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // img: (contact && contact.img) || '',
      name: (contact && contact.name) || '',
      company: (contact && contact.company) || '',
      designation: (contact && contact.designation) || '',
      email: (contact && contact.email) || '',
      phone: (contact && contact.phone) || '',
      lead_score: (contact && contact.lead_score) || '',
      tags: (contact && contact.tags) || [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      company: Yup.string().required("Please Enter Company"),
      designation: Yup.string().required("Please Enter Designation"),
      email: Yup.string().required("Please Enter Email"),
      phone: Yup.string().required("Please Enter Phone"),
      lead_score: Yup.string().required("Please Enter lead_score"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateContact = {
          _id: contact ? contact._id : 0,
          // img: values.img,
          name: values.name,
          company: values.company,
          designation: values.designation,
          email: values.email,
          phone: values.phone,
          lead_score: values.lead_score,
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // update Contact 
        dispatch(onUpdateContact(updateContact));
        validation.resetForm();
      } else {
        const newContact = {
          _id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          // img: values["img"],
          name: values["name"],
          company: values["company"],
          designation: values["designation"],
          email: values["email"],
          phone: values["phone"],
          lead_score: values["lead_score"],
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // save new Contact
        dispatch(onAddNewContact(newContact));
        validation.resetForm();
      }
      toggle();
    },
  });


  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  
  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".contactCheckBox:checked");
    ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  const column = useMemo(
    () => [
      {
        Header: <input type="checkbox" id="checkBoxAll" className="form-check-input" onClick={() => checkedAll()} />,
        Cell: (cellProps) => {
          return <input type="checkbox" className="contactCheckBox form-check-input" value={cellProps.row.original._id} onChange={() => deleteCheckbox()} />;
        },
        id: '#',
      },
      {
        Header: "Property Category",
        accessor: "property_category",
        filterable: false,

      },
      {
        Header: "Property Category Option",
        accessor: "options",
        filterable: false,
      },

      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item">
                <button
                  className="btn btn-sm btn-soft-info edit-list"
                  onClick={() => {
                    const orderData = cellProps.row.original;
                    const orderId = orderData.id;
                    setId(orderId);
                    console.log(orderId);
                    setIsEdit(true);
                    tog_prop_option(true);
                    axios.get(`${"api/property-category-options/edit/"}${orderId}`)
                      .then(res => {
                        console.log(res.c);
                        setOptions(res.row.options);
                        setM3Value(res.row.m3_value);
                        setOtherValue(res.row.other_value);
                        axios.get("/api/property-category-options/create")
                          .then(res => {
                            console.log(res);
                            setCategories(res);
                          })
                      })
                      .catch(err => {
                        console.log(err)
                      })
                  }}
                >
                  <i className="bx bxs-pencil fs-12 pt-1"></i>
                </button>
              </li>
              <li className="list-inline-item edit">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    const orderData = cellProps.row.original;
                    const orderId = orderData.id;
                    onClickDelete(orderId);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    [checkedAll]
  );

  const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);

  return (
    <div>
       <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Card>
        <CardHeader>
          <h4 className="card-title mb-0 flex-grow-1">Search Filters</h4>
        </CardHeader>
        <CardBody>
          <div id="table-gridjs">
            <div className="d-flex align-items-center flex-wrap gap-2 mb-3">
              <div className="flex-grow-1">
                <button
                  className="btn btn-brown add-btn"
                  onClick={() => {
                    setIsEdit(false);
                    handleCategoryOptions();
                    resetForm()
                  }}
                >
                  <i className="ri-add-fill me-1 align-bottom"></i> Add Property Category Options
                </button>
              </div>
            </div>
            <div>
              <TableContainer
                columns={column}
                data={(data || [])}
                isGlobalFilter={true}
                isAddUserList={false}
                customPageSize={8}
                className="custom-header-css"
                divClass="table-responsive table-bordered table-card mb-3"
                tableClass="align-middle table-nowrap"
                theadClass="table-light"
                isContactsFilter={true}
                SearchPlaceholder="Search for contact..."
              />
            </div>
            <ToastContainer closeButton={false} limit={1} />
          </div>
        </CardBody>
      </Card>
      <Modal
        className="mt-5"
        size="lg"
        isOpen={modal_prop_option}
        toggle={() => {
          tog_prop_option();

        }}
      >
        <Card className="mb-0">
          <form onSubmit={handleSubmit}>
            <ModalHeader>
              <h5>
                {!!isEdit ? "Update Property Category Options" : "Create Property Category Options"}
              </h5>

            </ModalHeader>
            <CardBody>
              <Row>
                <Col md={7}>
                  <div className="mb-3">
                    <Label className="form-label">Property Category Option</Label>
                    <Input type="text" className="form-control"
                      value={options}
                      onChange={
                        (e) => setOptions(e.target.value)} />
                  </div>
                </Col>
                <Col md={7}>

                  <div className="mb-3">
                    <label htmlFor="propertyGroup" className="form-label">Property Category</label>
                    <select id="propertyGroup" className="form-select" data-choices data-choices-sorting="true">
                      {categories.map((category) => (
                        <option key={category.id} value={category.id}>{category.category}</option>
                      ))}
                    </select>
                  </div>

                </Col>
                <Col md={7}>
                  <div className="mb-3">
                    <Label className="form-label ">CBM (m3)</Label>
                    <Input type="number" className="form-control"
                      value={m3Value}
                      onChange={
                        (e) => setM3Value(e.target.value)} />
                  </div>
                </Col>
                <Col md={7}>
                  <div className="mb-3">
                    <Label className="form-label">Other Value</Label>
                    <Input type="text" className="form-control"
                      value={otherValue}
                      onChange={
                        (e) => setOtherValue(e.target.value)} />
                  </div>
                </Col>
                <Col md={9}><div className="form-check mb-2">
                  <Input className="form-check-input" type="checkbox" id="checkActive" />
                  <Label className="form-check-label" for="checkActive">
                    Active
                  </Label>
                </div>
                </Col>
              </Row>
            </CardBody>
            <ModalFooter>
              <button type="submit" className="btn btn-success">
                {!!isEdit
                  ? "Update"
                  : "Save"}</button>
              <button type="button" className="btn btn-danger" onClick={() => {
                tog_prop_option();
              }} >Cancel</button>
            </ModalFooter>
          </form>
        </Card>
      </Modal>
    </div>
  )
}
