import { Card, CardBody, Col, CardHeader, Table, Row, } from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import DeleteModal from "../../../Components/Common/DeleteModal";

import { toast, ToastContainer } from 'react-toastify';

export const OHSChecklist = () => {
  document.title="OHS Checklist | Onexfort";
  const [checkList, setCheckList] = useState([]);
  const [id, setId] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [tempValue, setTempValue] = useState("");

  //list table
  const loadTable = () => {
    axios.get("/api/ohschecklist")
      .then(res => {
        console.log(res);
        setCheckList(res.data);
      })
      .catch(err => {
        console.log(err);
      })
  }

  useEffect(() => {
    loadTable();
  }, [])

  //rowsdata
  const [rowsData, setRowsData] = useState([]);

  //Edit
  const handleEdit = (index, item) => {
    setId(item.id);
    setTempValue(item.checklist);
    setCheckList((prev) => {

      const updatedNames = [...prev];
      updatedNames[index].isEditing = true;
      return updatedNames;
    });
  };
  useEffect(() => {
    console.log(tempValue);
  }, [tempValue]);

  //edit-> save
  const handleSave = (index) => {

    let data = {
      "checklist": inputValue,
      "local_moves_id": id
    }

    axios.post("/api/ajaxUpdateChecklist", data)
      .then(res => {
        console.log("successfully edited");
        setCheckList((prev) => {
          const updatedNames = [...prev];
          updatedNames[index].isEditing = false;
          return updatedNames;
        });

      })
      .catch(err => {
        console.log(err);

      })
  };
  //edit ->cancel
  const handleCancel = (index) => {
    console.log(tempValue)
    loadTable();
    setCheckList((prev) => {
      const updatedNames = [...prev];
      updatedNames[index].isEditing = false;
      return updatedNames;
    });
  };
  const handleDelete = (index, item) => {
    
  }

  const handleChange = (index, event) => {

    const { value } = event.target;
    setInputValue(value);
    setCheckList((prev) => {
      const updatedNames = [...prev];
      updatedNames[index].checklist = value;
      return updatedNames;
    });
  };

  const [newGroupNames, setNewGroupNames] = useState([]);
  const addTableRows = () => {
    const rowsInput = {
      groupName: '',
    }
    setRowsData([...rowsData, rowsInput])
  }

  //Add new row
  const [newInput, setNewInput] = useState({});
  const handleNewChange = (index, event) => {
    const { value } = event.target;
    setNewInput((prevNewInput) => ({
      ...prevNewInput,
      checklist: value,
    }));
    setNewGroupNames((prevNewGroupNames) => {
      const updatedNewGroupNames = [...prevNewGroupNames];
      updatedNewGroupNames[index] = value;
      return updatedNewGroupNames;
    });
  };

  //save->row
  const saveTableRow = (index) => {
    const updatedRowsData = [...rowsData];
    updatedRowsData[index].checklist = newGroupNames[index];
    setRowsData(updatedRowsData);

    console.log(updatedRowsData[index].checklist)

    if (updatedRowsData[index].checklist==="" || updatedRowsData[index].checklist===undefined ){
      toast.error('Enter Checklist Name',{theme:"light"}); 
    }

    else{
    axios.post("/api/ajaxCreateChecklist", newInput)
      .then(res => {
        console.log("successfully edited");
        const updatedRows = [...rowsData];
        updatedRows.splice(index, 1);
        setRowsData([...updatedRows]);
        loadTable();
        //clear input field
        setNewGroupNames((prev) => {
          const updatedNames = [...prev];
          updatedNames[index] = '';
          return updatedNames;
        });
      })
      .catch(err => {
        console.log(err);

      })
    }
  };

  //cancel ->row
  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
    setNewGroupNames((prev) => {
      const updatedNames = [...prev];
      updatedNames[index] = '';
      return updatedNames;
    });
  }

  const [deleteModal, setDeleteModal] = useState(false);

  const handleDeleteOrder = () => {
    
    let data = {
      "id": id
    }
    axios.post("/api/ajaxDestroyChecklist", data)
      .then(res => {
        console.log("successfully deleted");
        loadTable();
        setDeleteModal(false)
      })
      .catch(err => {
        console.log(err);
      })

  };

  return (
    <div>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Card>
        <CardHeader>
          <h5 className="mb-0">Checklist</h5>
        </CardHeader>
        <CardBody>
          <div className="live-preview">
            <Row>
              <Col xl={12}>
                <div className="table-responsive mt-4 mt-xl-0">

                  <Table className="table table-bordered table-nowrap align-middle mb-3">
                    <thead>
                      <tr>
                        <th scope="col">Checklist</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {checkList.map((item, index) => (
                        <tr key={item.id}>
                          <td className="fw-medium">
                            {item.isEditing ? (
                              <input
                                type="text"
                                value={item.checklist}
                                onChange={(event) => handleChange(index, event)}
                                className="form-control"
                              />
                            ) : (
                              item.checklist
                            )}
                          </td>
                          <td>
                            <div className="hstack gap-3 flex-wrap">
                              {item.isEditing ? (
                                <>
                                  <button
                                    className="btn btn-success"
                                    onClick={() => handleSave(index)}
                                  >
                                    Save
                                  </button>
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => handleCancel(index)}
                                  >
                                    Cancel
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    className="btn btn-sm btn-soft-info edit-list "
                                    onClick={() => handleEdit(index, item)}
                                  >
                                    <i className="bx bxs-pencil fs-12 pt-1"></i>
                                  </button>
                                  <button
                                    className="btn btn-sm btn-soft-danger  "
                                    onClick={() => {setId(item.id);setDeleteModal(true)}}
                                  >
                                    <i className="ri-delete-bin-5-fill fs-16"></i>
                                  </button>
                                </>

                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                      {rowsData.map((data, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              type="text"
                              value={newGroupNames[index] || ''}
                              onChange={(event) => handleNewChange(index, event)}
                              name="groupName"
                              className="form-control"
                            />
                          </td>
                          <td>
                            <div className="hstack gap-2">
                              <button className="btn btn-success" onClick={() => saveTableRow(index)}>
                                Save
                              </button>
                              <button className="btn btn-primary" onClick={() => deleteTableRows(index)}>
                                Cancel
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}

                    </tbody>
                  </Table>
                  <button className="btn btn-primary" onClick={addTableRows} >+</button>
                </div>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
      <ToastContainer closeButton={false} limit={1} />
    </div>
  )
}
