import PrismCode from "../../../Components/Common/Prism";
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, Alert, UncontrolledAlert, TabContent, TabPane, UncontrolledTooltip } from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo, useRef  } from "react";
import img13 from "../../../assets/images/small/img-13.png";
import { Button } from 'reactstrap';
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "../../../assets/scss/pages/_settings.scss";
import DeleteModal from "../../../Components/Common/DeleteModal";
import {
  CardHeader,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  ModalFooter,
  Table,
  FormFeedback
} from "reactstrap";
//Import actions
import {
  getContacts as onGetContacts,
  addNewContact as onAddNewContact,
  updateContact as onUpdateContact,
  deleteContact as onDeleteContact,
} from "../../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../../Components/Common/TableContainer";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import Loader from "../../../Components/Common/Loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import {
  getOrders as onGetOrders,
  addNewOrder as onAddNewOrder,
  updateOrder as onUpdateOrder,
  deleteOrder as onDeleteOrder,
} from "../../../store/ecommerce/action";
//reloading the page
import { jsx } from '@emotion/react'
import { ClipLoader } from 'react-spinners';
import {api} from  "../../../config";


//reloading the page
const override = jsx`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export const Companies = () => {
   document.title="Companies | Onexfort";
  const [modal, setModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoading, setisLoading] = useState(false);


  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      tog_Company();
      setContact(null);
    } else {
      setModal(true);
      setTag([]);
      setAssignTag([]);
    }
  }, [modal]);

  const [rowsData, setRowsData] = useState([]);
  const addTableRows = () => {
    const rowsInput = {
      fullName: '',
      emailAddress: '',
      salary: ''
    }
    setRowsData([...rowsData, rowsInput])
  }

  const [contact, setContact] = useState([]);
  const dispatch = useDispatch();

  //react
  const isContactSuccess = true;
  const error = null;
  const [data, setData] = useState([]);
  const crmcontacts = data;

  const loadTable = () => {
    setisLoading(true);
    axios.get('/api/companies/data')
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setisLoading(false);
      })
  }

  useEffect(() => {
    loadTable();
  }, []);


  //input field values
  const [company_name, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [contact_name, setContactName] = useState("");
  const [phone, setPhone] = useState("");
  const [sms_number, setSmsNumber] = useState("");
  const [address, setAddress] = useState("");
  const [abn, setAbn] = useState("");
  const [payment_terms, setPaymentTerms] = useState("");
  const [customer_sign_off_checklist, setCustomerSign] = useState("");
  const [customer_pre_job_checklist, setCustomerPre] = useState("");
  const [work_order_instructions, setWorkOrderInstructions] = useState("");
  const [pod_instructions, setPodInstructions] = useState("");
  const [defaultInterest, setDefaultInterest] = useState("N");
  const [activeInterest, setActiveInterest] = useState("N");
  const [imagesel, setImage] = useState("");
  const [id, setId] = useState("");
  const [shouldShowIdField, setShouldShowIdField] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [uploadedImage, setUploadedImage] = useState("");

  

  //checkbox code
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    if (name === "default") {
      setDefaultInterest(checked ? "Y" : "N");
    } else if (name === "active") {
      setActiveInterest(checked ? "Y" : "N");
    }
  };
  //submit
  const handleSubmit = (event) => {
    event.preventDefault();
    //reloading the page
    setIsUpdating(true);
    if (company_name === '') {
      toast.error("The Company name field is required.", { theme: "light" });
      setisLoading(false);
      return; // Stop form submission if orgTime is empty
    } else if (email === '') {
      toast.error("The company email field is required.", { theme: "light" });
      setisLoading(false);
      return; // Stop form submission if orgDate is empty
    } else if (email !== '') {
      const emailPattern = /^[A-Za-z0-9._+-]+@[A-Za-z._-]+\.[A-Za-z]{2,}$/;
      const isValid = emailPattern.test(email);
      if (!isValid) {
        toast.error('Invalid email', { theme: "light" });
        setisLoading(false);
        return;
      }
    } else if (phone === '') {
      toast.error("The company phone field is required.", { theme: "light" });
      setisLoading(false);
      return; // Stop form submission if orgDate is empty
    } 
    console.log(uploadedImage, "upload");
    const formData = new FormData();
    formData.append('company_name',company_name);
    formData.append('email', email);
    formData.append('contact_name', contact_name);
    formData.append('phone',phone);
    formData.append('sms_number',sms_number);
    formData.append('address',address);
    formData.append('abn',abn);
    formData.append('payment_terms',payment_terms);
    formData.append('customer_sign_off_checklist',customer_sign_off_checklist);
    formData.append('customer_pre_job_checklist',customer_pre_job_checklist);
    formData.append('work_order_instructions',work_order_instructions);
    formData.append('pod_instructions',pod_instructions);
    formData.append('default1',defaultInterest);
    formData.append('active',activeInterest);
    formData.append('id',id);
    formData.append('image', uploadedImage);
    const companyId = id;
    if(!isEdit){
      axios.post('/api/companies/store', formData)
      .then(response => {
        toast.success(response.success,{theme:"light"});
        loadTable();
        setModal_Company(false)
        if(response.data == "success")
        {
          setCompanyName("");
          setEmail("");
          setContactName("");
          setPhone("");
          setSmsNumber("");
          setAddress("");
          setAbn("");
          setPaymentTerms("");
          setCustomerSign("");
          setCustomerPre("");
          setWorkOrderInstructions("");
          setPodInstructions("");
          setDefaultInterest("");
          setActiveInterest("");
          setImage("");
          setId("");

        }
        else{
          toast.success("Something went worng......",{theme:"light"});
        }
      })
      .catch(error => { 
        console.error(error);
      })
      .finally(()=>{
        // setIsImageSelected(false); //to avoid showing the selected image on img src after click save button
        //image selection
        setSelectedImage(null);
        setUploadedImage(null);
        setIsImageSelected(false);
        fileInputRef.current.value = null; // Clear the input field value
        setIsUpdating(false); //reloading the page
      })
    }else{
      axios.post(`${"api/companies/update/"}${companyId}`, formData)
      .then(response => {
        const successMessage = response.success; // Extract the success message
        toast.success(response.success, { theme: "light" });
        loadTable();
        // setModal_Company(false);
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setModal_Company(false);
        setIsUpdating(false); //reloading the page
        //image selection
        setSelectedImage(null);
        setUploadedImage(null);
        setIsImageSelected(false);
        fileInputRef.current.value = null; // Clear the input field value
      });
    }
  }
  //react
  useEffect(() => {
    setContact(crmcontacts);
  }, [crmcontacts]);

  useEffect(() => {
    if (!isEmpty(crmcontacts)) {
      setContact(crmcontacts);
      setIsEdit(false);
    }
  }, [crmcontacts]);


  const [isEdit, setIsEdit] = useState(false);
    // Delete Data
  const handleDeleteContact = () => {
    if (contact) {
      dispatch(onDeleteContact(contact._id));
      setDeleteModal(false);
    }
  };
  const onClickDelete = (contact) => {
    setContact(contact);
    setDeleteModal(true);
  };
  //delete Conatct
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const [modal_large, setModal_Company] = useState(false);
  function tog_Company() {
    setModal_Company(!modal_large);
  }

  // Add Data
  const handleContactClicks = () => {
    setContact("");
    setIsEdit(false);
    toggle();
  };


  // Date & Time Format

  const dateFormat = () => {
    var d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return (d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear());
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // img: (contact && contact.img) || '',
      name: (contact && contact.name) || '',
      company: '',
      designation: '',
      email: (contact && contact.email) || '',
      phone: (contact && contact.phone) || '',
      lead_score: (contact && contact.lead_score) || '',
      tags: (contact && contact.tags) || [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      company: Yup.string().required("Please Enter Company"),
      designation: Yup.string().required("Please Enter Designation"),
      email: Yup.string().required("Please Enter Email"),
      phone: Yup.string().required("Please Enter Phone"),
      lead_score: Yup.string().required("Please Enter lead_score"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateContact = {
          _id: contact ? contact._id : 0,
          // img: values.img,
          name: values.name,
          company: values.company,
          designation: values.designation,
          email: values.email,
          phone: values.phone,
          lead_score: values.lead_score,
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // update Contact 
        dispatch(onUpdateContact(updateContact));
        validation.resetForm();
      } else {
        const newContact = {
          _id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          // img: values["img"],
          name: values["name"],
          company: values["company"],
          designation: values["designation"],
          email: values["email"],
          phone: values["phone"],
          lead_score: values["lead_score"],
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // save new Contact
        dispatch(onAddNewContact(newContact));
        validation.resetForm();
      }
      toggle();
    },
  });

  // Update Data
  const handleContactClick = useCallback((arg) => {
    const contact = arg;

    setContact({
      _id: contact._id,
      // img: contact.img,
      name: contact.name,
      company: contact.company,
      email: contact.email,
      designation: contact.designation,
      phone: contact.phone,
      lead_score: contact.lead_score,
      last_contacted: contact.date,
      // time: contact.time,
      tags: contact.tags,
    });

    setIsEdit(true);
    toggle();
  }, [toggle]);

  const [order, setOrder] = useState([]);



  const handleOrderClick = useCallback((arg) => {
    
    const order = arg;
    setOrder({
      _id: order._id,
      orderId: order.orderId,
      customer: order.customer,
      product: order.product,
      orderDate: order.orderDate,
      ordertime: order.ordertime,
      amount: order.amount,
      payment: order.payment,
      status: order.status
    });

    setIsEdit(true);
    toggle();
  }, [toggle]);

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      dispatch(onDeleteContact(element.value));
      setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".contactCheckBox:checked");
    ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };
  const handleDeleteOrder = () => {
    var orderIds = selectedOrderId;
    axios.get(`${"api/companies/destroy/"}${orderIds}`)
      .then(response => {
        toast.success(response.message,{theme:"light"});
        loadTable();
        setDeleteModal(false)
      })
      .catch(error => { 
        console.error(error);
      });
};

  //react
  const smscolumn = useMemo(
    () => [

      {
        Header: "Company Name",
        accessor: "company_name",
        filterable: false,

      },
      {
        Header: "Address",
        accessor: "address",
        filterable: false,
      },
      {
        Header: "Contact Name",
        accessor: "contact_name",
        filterable: false,
      },
      {
        Header: "ABN",
        accessor: "abn",
        filterable: false,
      },

      {
        Header: "Default",
        accessor: "default1",
        filterable: false,
      },

      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">

              <li className="list-inline-item">
                <button className="btn btn-sm btn-soft-info edit-list" onClick={() => {
                  const orderData = cellProps.row.original;
                  const orderId = orderData.id; // Assuming 'id' is the property containing the ID
                  tog_Company();
                  setIsEdit(true);
                  axios.get(`${"api/companies/edit/"}${orderId}`)
                  .then(response => {
                      setId(response.companydatas.id);
                      setCompanyName(response.companydatas.company_name || '');
                      setEmail(response.companydatas.email || '');
                      setContactName(response.companydatas.contact_name || '');
                      setPhone(response.companydatas.phone || '');
                      setSmsNumber(response.companydatas.sms_number ||'');
                      setAddress(response.companydatas.address || '');
                      setAbn(response.companydatas.abn|| '');
                      setPaymentTerms(response.companydatas.payment_terms || '');
                      setCustomerSign(response.companydatas.customer_sign_off_checklist || '');
                      setCustomerPre(response.companydatas.customer_pre_job_checklist || '');
                      setWorkOrderInstructions(response.companydatas.work_order_instructions || '');
                      setPodInstructions(response.companydatas.pod_instructions || '');
                      setDefaultInterest(response.companydatas.default1 ||'');
                      setActiveInterest(response.companydatas.active || '');
                      setImage(response.companydatas.logo  || '');
                    })
                    .catch(error => {
                        console.error(error);
                    });
                }} ><i className="bx bxs-pencil fs-12 pt-1"></i>
                </button>
              </li>
              <li className="list-inline-item edit">
              <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                      const orderData = cellProps.row.original;
                       setSelectedOrderId(orderData.id);
                      onClickDelete(selectedOrderId);
                  }}
              >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
              </Link>
              </li>

            </ul>
          );
        },
      },
    ],
    [handleContactClick, checkedAll]
  );
//react
  const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);
  
  //image selection
  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageSelected, setIsImageSelected] = useState(false);

  const handleButtonClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };
  
  const handleFileInputChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
    const objectUrl = URL.createObjectURL(selectedFile);
    setUploadedImage(selectedFile);
    setSelectedImage(objectUrl);
    setIsImageSelected(true);
    }
  };

  const handleRemoveClick = () => {
    setSelectedImage(null);
    setUploadedImage(null);
    setIsImageSelected(false);
    fileInputRef.current.value = null; // Clear the input field value
  };

  const handleChangeClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  return (
    <div>
      <DeleteModal
      show={deleteModal}
      orderId={selectedOrderId}
      onDeleteClick={handleDeleteOrder}
      onCloseClick={() => setDeleteModal(false)}
    />
      <Card>
        <CardHeader>
          <div className="d-flex align-items-center flex-wrap gap-2">
            <div className="flex-grow-1">
            <button
              className="btn btn-brown add-btn"
              onClick={() => {
                setIsEdit(false);
                tog_Company();
                setCompanyName("");
                setEmail("");
                setContactName("");
                setPhone("");
                setSmsNumber("");
                setAddress("");
                setAbn("");
                setPaymentTerms("");
                setCustomerSign("");
                setCustomerPre("");
                setWorkOrderInstructions("");
                setPodInstructions("");
                setDefaultInterest("");
                setActiveInterest("");
                setImage("");
                setId("");
                //image selection
                setSelectedImage(null);
                setUploadedImage(null);
                setIsImageSelected(false);
                if (fileInputRef.current) {
                  fileInputRef.current.value = null; // Clear the input field value
                }
              }}
            >
            <i className="ri-add-fill me-1 align-bottom"></i> Add Company
            </button>
            </div>
            <div className="flex-shrink-0">
              <div className="hstack text-nowrap gap-2">
                {isMultiDeleteButton && <button className="btn btn-danger"
                  onClick={() => setDeleteModalMulti(true)}
                ><i className="ri-delete-bin-2-line"></i></button>}
              </div>
            </div>
          </div>
        </CardHeader>
      </Card>
      <Col xxl={12}>
        <Card id="contactList">
          <CardBody className="pt-0">
            <div>
              {isContactSuccess && crmcontacts.length ? (
                <TableContainer
                  columns={smscolumn}
                  data={(crmcontacts || [])}
                  isGlobalFilter={true}
                  isAddUserList={false}
                  customPageSize={8}
                  className="custom-header-css"
                  divClass="table-responsive table-card mb-3"
                  tableClass="align-middle table-nowrap"
                  theadClass="table-light"
                  handleContactClick={handleContactClicks}
                  isContactsFilter={true}
                  SearchPlaceholder="Search for company..."
                />
              ) : (<Loader />)
              }

            </div>

            <Modal id="showModal" isOpen={modal_large} size="xl"
              toggle={() => {
                tog_Company();

              }} centered>
              <ModalHeader className="bg-soft-info p-3" >
                {!!isEdit ? "Edit Company" : "Add Company"}
              </ModalHeader>

              <Form className="tablelist-form">
                <ModalBody>
                  <Input type="hidden" id="id-field" />
                  <Row className="g-3">
                    <Col md={6} style={{ display: shouldShowIdField ? 'block' : 'none' }}>
                      <div className="mb-3">
                        <Label for="warehouseName" className="form-label">
                          Id
                        </Label>
                        <Input
                          type="text"
                          value={id}
                          onChange={(e) => setId(e.target.value)}
                          className="form-control"
                          placeholder=""
                          id="warehouseName"
                          readOnly
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div>
                        <Label
                          htmlFor="name-field"
                          className="form-label"
                        >
                          Company Name
                        </Label>
                        <Input
                          name="name"
                          id="customername-field"
                          className="form-control"
                          placeholder="Enter Company Name"
                          type="text"
                          validate={{
                            required: { value: true },
                          }}
                          value={company_name}
                          onChange={(e) => setCompanyName(e.target.value)}
                          onBlur={validation.handleBlur}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div>
                        <Label
                          htmlFor="company_name-field"
                          className="form-label"
                        >
                          Email
                        </Label>
                        <Input
                          name="company"
                          id="company_name-field"
                          className="form-control"
                          placeholder="Enter Email"
                          type="email"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={(e) => setEmail(e.target.value)}
                          onBlur={validation.handleBlur}
                          value={email}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div>
                        <Label
                          htmlFor="designation-field"
                          className="form-label"
                        >
                          Contact Name
                        </Label>

                        <Input
                          name="designation"
                          id="designation-field"
                          className="form-control"
                          placeholder="Enter Contact Name"
                          type="text"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={(e) => setContactName(e.target.value)}
                          onBlur={validation.handleBlur}
                          value={contact_name}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div>
                        <Label
                          htmlFor="email_id-field"
                          className="form-label"
                        >
                          Phone
                        </Label>
                        <Input
                          name="email"
                          id="email_id-field"
                          className="form-control"
                          placeholder="Enter Phone"
                          type="number"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={(e) => setPhone(e.target.value)}
                          onBlur={validation.handleBlur}
                          value={phone}
                        />
                        </div>
                    </Col>
                    <Col lg={6}>
                      <div>
                        <Label
                          htmlFor="phone-field"
                          className="form-label"
                        >
                          SMS Number
                        </Label>

                        <Input
                          name="phone"
                          id="phone-field"
                          className="form-control"
                          placeholder="Enter SMS Number"
                          type="number"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={(e) => setSmsNumber(e.target.value)}
                          onBlur={validation.handleBlur}
                          value={sms_number}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div>
                        <Label
                          htmlFor="lead_score-field"
                          className="form-label"
                        >
                          Address
                        </Label>

                        <Input
                          name="address"
                          id="lead_score-field"
                          className="form-control"
                          placeholder="Enter Address"
                          type="text"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={(e) => setAddress(e.target.value)}
                          onBlur={validation.handleBlur}
                          value={address}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <Label>ABN</Label>
                      <Input type="text" placeholder="Enter ABN" 
                      onChange={(e) => setAbn(e.target.value)} 
                      value={abn}></Input>
                    </Col>
                    <Col lg={12}>
                      <Label>Terms and Conditions</Label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={payment_terms}
                        config={{
                          // placeholder: 'Enter Terms And Conditions',
                        }}
                        onReady={(editor) => {
                          // You can store the "editor" and use it when needed.
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setPaymentTerms(data);
                        }}
                        />
                    </Col>
                    <Col lg={12}>
                      <Label>Customer Sign-off Checklist</Label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={customer_sign_off_checklist}
                        config={{
                          // Additional configuration options can be added here
                        }}
                        onReady={(editor) => {
                          // You can store the "editor" and use it when needed.
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setCustomerSign(data);
                        }}
                        />
                    </Col>
                    <Col lg={12}>
                      <Label>Customer Pre-job Checklist</Label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={customer_pre_job_checklist}
                        config={{
                          // Additional configuration options can be added here
                        }}
                        onReady={(editor) => {
                          // You can store the "editor" and use it when needed.
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setCustomerPre(data);
                        }}
                        />
                    </Col>
                    <Col lg={12}>
                      <Label>Work Order Instructions</Label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={work_order_instructions}
                        config={{
                          // Additional configuration options can be added here
                        }}
                        onReady={(editor) => {
                          // You can store the "editor" and use it when needed.
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setWorkOrderInstructions(data);
                        }}
                        />
                    </Col>
                    <Col lg={12}>
                      <Label>POD Instructions</Label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={pod_instructions}
                        config={{
                          // Additional configuration options can be added here
                        }}
                        onReady={(editor) => {
                          // You can store the "editor" and use it when needed.
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setPodInstructions(data);
                        }}
                        />
                    </Col>
                    <Col lg={6}>
                      <div className="form-check mb-2">
                        <Input
                          className="form-check-input"
                          type="checkbox"
                          id="interest1"
                          name="default"
                          value="default"
                          onChange={handleCheckboxChange}
                          checked={defaultInterest === "Y"}
                        />
                        <Label className="form-check-label" htmlFor="interest1">
                          Default
                        </Label>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="form-check mb-2">
                        <Input
                          className="form-check-input"
                          type="checkbox"
                          id="interest2"
                          name="active"
                          value="active"
                          onChange={handleCheckboxChange}
                          checked={activeInterest === "Y"}
                        />
                        <Label className="form-check-label" htmlFor="interest2">
                          Active
                        </Label>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label htmlFor="choices-single-default" className="form-label">
                        Company Logo
                        </Label>
                        <Row className="mb-2">
                          <Col md={4}>
                            <div className="img-container">
                              {imagesel  && imagesel!=null  ? (
                                  <img
                                  className="img-thumbnail"
                                  alt="Company Logo"
                                  src={
                                    isImageSelected
                                      ? selectedImage
                                      : api.WEB_URL+"user-uploads/company-logo/"+imagesel
                                  }
                                />
                                  ) : (
                                  <>
                                    {isImageSelected ? (
                                      <img
                                        className="img-thumbnail"
                                        alt="Company Logo"
                                        src={selectedImage}
                                      />
                                    ) : (
                                      <>
                                        <img
                                          className="img-thumbnail"
                                          alt="Company Logo"
                                          src={img13}
                                        />
                                        <div className="img-text">Company Logo</div>
                                      </>
                                    )}
                                  </>
                                )}
                            </div>
                          </Col>
                        </Row>
                        {isImageSelected ? (
                          <div>
                            <button
                              className="btn btn-primary change-button"
                              onClick={handleChangeClick}
                            >
                              Change
                            </button>
                            <button
                              className="btn btn-danger remove-button"
                              onClick={handleRemoveClick}
                            >
                              Remove
                            </button>
                          </div>
                        ) : (
                          <button
                            className="btn btn-info"
                            onClick={handleButtonClick}
                          >
                            Select image
                          </button>
                        )}
                        <input
                          type="file"
                          style={{ display: 'none' }}
                          ref={fileInputRef}
                          onChange={handleFileInputChange}
                        />
                      </div>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  {/* for toast message design */}
                  <ToastContainer closeButton={false} limit={1} theme="light" />
                   {/* reloading the page */}
                  {/* {isUpdating && (
                    <div className="spinner-overlay">
                      <ClipLoader css={override} size={35} color={'#123abc'} loading={true} />
                    </div>
                  )} */}
                  <div className="hstack gap-2 justify-content-end">
                    <button type="submit" className="btn btn-success" onClick={handleSubmit} disabled={isUpdating} id="add-btn" > {!isEdit ? "Save" : "Update"} </button>
                    <button type="button" className="btn btn-light" onClick={() => { setModal_Company(false); }} > Cancel </button>
                  </div>
                </ModalFooter>
              </Form>
            </Modal>
            <ToastContainer closeButton={false} limit={1} />
            
          </CardBody>
        </Card>
      </Col>
    </div>
  )
}
