import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { Inventory } from "../../Opportunities/OpportunityTabPages/Inventory";
// import { Activities } from "../../Opportunities/OpportunityTabPages/Activities";
import { Jobpage } from "../CustomerTabPages/jobpage";
import { Customerdetails } from "../CustomerTabPages/customerdetail";
import { Storage } from "../../Opportunities/OpportunityTabPages/Storage";
import { Estimate } from "../../Opportunities/OpportunityTabPages/Estimate";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getCustomerDetails } from '../../../store/actions';
import { useParams } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    CardHeader,
    Collapse,
    Button,
    Input,
    TabPane,
    Label,
    TabContent,
    Nav,
    NavLink,
    NavItem
} from "reactstrap";
const CustomerDetails =()=>{
    const [customActiveTab, setcustomActiveTab] = useState("Activity");
    const [componentToShow, setComponentToShow] = useState(null);
    const dispatch = useDispatch();
    const [expandedContactId, setExpandedContactId] = useState(null);
    const [addcontact,setContact ] = useState(false);
    const [contactdetail, setContactDetail] = useState([]);
    const [showaddContact, setaddcontact] = useState(false);
    const [contactname, setContactName] = useState('');
    const [contacttitle, setContactTitle] = useState('');
    const [contacttype, setContactType] = useState([]);
    const [customerDetails, setCustomerDetails] = useState([]);
    const params = useParams();
    const lead_id = params.id;
    const [data, setData] = useState([]);
    useEffect(() => {
     getData();
    }, []);
    const getData = () => {
        axios.get("api/view-customer-leads/"+ lead_id)
        .then((res) => {
            setCustomerDetails(res);
           console.log(res);
        });
     }
    const handleContactEdit = (taskId) => {
        setExpandedContactId(taskId);
    };
    const handleClick = (component) => {

        setComponentToShow(component);
        if (customActiveTab !== component) {
            setcustomActiveTab(component);
        }

    };
    const handleContactDelete = (data) => {
        var postData = {
            "lead_id": data.lead_id,
            "contact_id": data.id,
        }
        axios.post("/api/ajaxDestroyContact", postData).then((res) =>{
            t_addcontact();
            toast.success(res.message,{theme:"light"});
        })
    };
    const renderComponent = () => {
        switch (componentToShow) {
            case 'Customerdetails':
                return <Customerdetails data={customerDetails} onChangeData={() => getData()}/>;
            case 'Jobpage':
                return <Jobpage data={customerDetails} onChangeData={() => getData()}/>;
         
            default:
                return <Customerdetails data={customerDetails} onChangeData={() => getData()}/>;
        }
    }
    const t_addcontact = () => {
        setaddcontact(!showaddContact);
    };
     const Add_newContact = () =>{
        var data = {
            "name":contactname,
            "description": contacttitle,
            "contact_detail": contactdetail,
            "contact_detail_type": "Mobile",
            "lead_id": lead_id,

         } 
         if(contactname == ""){
            toast.error("Contact name must be entered", {theme:"light"});
            return false;
         }else if(contacttitle == ""){
            toast.error("Contact title must be entered", {theme:"light"});
            return false;
         }
         else if(contactdetail == ""){
            toast.error("Contact detail must be entered", {theme:"light"});
            return false;
         }
         axios.post('/api/ajaxStoreContact', data).then((res)=>{
            handleContactEdit(data.id);
            t_addcontact();
            toast.success(res.message,{theme:"light"});
    
         })
       }
       const Update_Contact = (data) =>{
        var contactdetail = [];
        var contacttype = [];
        data?.contact_detail_data?.map((res) =>{
            contactdetail.push(res.detail);
            contacttype.push(res.detail_type);
        })
        var data = {
            "name":data.name,
            "description": data.description,
            "contact_detail": contactdetail,
            "contact_detail_type": contacttype,
            "lead_id": data.lead_id,
            "contact_id":data.id,
         } 
         if(data.name == ""){
            toast.error("Contact name must be entered", {theme:"light"});
            return false;
         }else if(data.description == ""){
            toast.error("Contact title must be entered", {theme:"light"});
            return false;
         }
         axios.post('/api/ajaxUpdateContact', data).then((res)=>{
            handleContactEdit(data.id);
            toast.success(res.message,{theme:"light"});
    
         })
       }
return(
    <div className="page-content">
            <h5>{customerDetails?.crmlead?.lead_type + ' Customer'}</h5>
           <Container fluid>
                <Row>
                    <Col xl={3}>
                     

                         <Card>
                            <CardHeader>
                            <div className="d-flex justify-content-between align-items-center">
                            <h5 className="mb-0">
                                <i className="ri-secure-payment-line align-bottom me-1 text-muted"></i>{" "}
                                CONTACTS
                                </h5>
                            <button className="show_update_job_detail_btn btn btn-icon" onClick={t_addcontact}>
                            <i className="ri-add-line fs-22"></i>
                            </button>
                            </div>
                            </CardHeader>
                            <CardBody>
                            <Collapse isOpen={showaddContact} id="collapsepickup">
                            <div className="collapse-section">
                            Name
                            <div className="pd-field">
                                <Input className="form-control" type="text" value={contactname} placeholder="Contact Name"
                                onChange={(e) => {
                                setContactName(e.target.value);}} />   
                            </div>
                            Title
                            <div className="pd-field">
                                <Input className="form-control" type="text" value={contacttitle} placeholder="Contact Title"
                                onChange={(e) => {
                                setContactTitle(e.target.value)}} />   
                            </div>
                            Contact Detail
                            <div className="input-group mgb-10">
                            <Input type="text" className="form-control contact_detail" placeholder="Phone, email or URL" value={contactdetail} 
                            onChange={(e) => {
                                setContactDetail( e.target.value)}}/>
                            <div className="input-group-append">                    
                                <select className="form-control form-control-uniform" value={contacttype} onChange={(e) => {
                                    setContactType(e.target.value);
                                }}>
                                {customerDetails?.contact_types?.map((res) => (
                                <option key={res.list_option} value={res.list_option}>
                                {res.list_option}
                                </option>
                                ))}
                                </select>
                                </div>
                             </div>
                            <div className="hstack gap-2 mt-2">
                                <Button className="btn btn-light" onClick={t_addcontact}>Cancel</Button>
                                <Button className="btn btn-info"  onClick={() => {Add_newContact()}}>Save</Button>
                            </div>
                            </div>
                            </Collapse>
                            {customerDetails?.contacts?.map((res) => (
                            <><div key={res.id}>
                                    <div className="page-title d-flex align-items-center justify-content-between">
                                        <div className=" align-items-center mb-2 contact-sec-top">
                                            <div className="">
                                                <h6 className=" mb-1">{res.name}</h6>
                                            </div>
                                            <div className="">
                                                <p className="mb-0 text-muted">{res.description?.substring(0, 25)}</p>
                                            </div>
                                        </div>
                                        <UncontrolledDropdown className="dropdown d-inline-block">
                                            <DropdownToggle className="btn fs-18" tag="button">
                                                <i className="ri-more-2-line"></i>
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu-end">
                                                <DropdownItem className='edit-item-btn' onClick={() => { handleContactEdit(res.id); } }>
                                                    <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>Edit
                                                </DropdownItem>
                                                <DropdownItem className='remove-item-btn text-danger' onClick={() => { handleContactDelete(res); } }>
                                                    <i className="ri-delete-bin-fill align-bottom me-2 text-danger"></i>Delete
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                    {expandedContactId === res.id && (<div className="collapse-section">
                                Name
                                <div className="pd-field">
                                <Input className="form-control" type="text" value={res.name} placeholder="Contact Name"
                                onChange={(e) => {
                                    const updatedTasks = [...customerDetails.contacts];
                                    const taskIndex = updatedTasks.findIndex((contact) => contact.id === res.id);
                                    updatedTasks[taskIndex].name = e.target.value;
                                    setCustomerDetails({ ...customerDetails, contact: updatedTasks });
                                    }} />
                                </div>
                                Title
                                <div className="pd-field">
                                <Input className="form-control" type="text" value={res.description} placeholder="Contact Title"
                                onChange={(e) => {
                                    const updatedTasks = [...customerDetails.contacts];
                                    const taskIndex = updatedTasks.findIndex((contact) => contact.id === res.id);
                                    updatedTasks[taskIndex].description = e.target.value;
                                    setCustomerDetails({ ...customerDetails, contact: updatedTasks });
                                }} />
                                </div>
                                Contact Detail
                                {res?.contact_detail_data?.map(function (data) {
                                    return (
                                <div className="input-group mgb-10" key={data.id}> 
                                <Input type="text" className="form-control contact_detail" placeholder="Phone, email or URL" value={data.detail}
                                    onChange={(e) => {
                                        const updatedContactDetail = res.contact_detail_data.map((item) => {
                                        if (item.id === data.id) {
                                            return { ...item, detail: e.target.value };
                                        }
                                        return item;
                                    });
                                    const updatedCustomerDetails = { ...customerDetails};
                                    const taskIndex = updatedCustomerDetails.contacts.findIndex((contact) => contact?.id === res?.id);
                                    updatedCustomerDetails.contacts[taskIndex].contact_detail_data = updatedContactDetail;
                                    setCustomerDetails(updatedCustomerDetails);
                                    }} />
                                <div className="input-group-append">
                                    <select className="form-control form-control-uniform" value={data.detail_type} onChange={(e) => {
                                    const updatedContactDetail = res.contact_detail_data.map((item) => {
                                        if (item.id === data.id) {
                                            return { ...item, detail_type: e.target.value };
                                        }
                                        return item;
                                    });
                                    const updatedCustomerDetails = { ...customerDetails };
                                    const taskIndex = updatedCustomerDetails.contact.findIndex((contact) => contact.id === res.id);
                                    updatedCustomerDetails.contact[taskIndex].contact_detail_data = updatedContactDetail;
                                    setCustomerDetails(updatedCustomerDetails);
                                    }}>
                                    {customerDetails.contact_types?.map((res) => (
                                    <option key={res.list_option} value={res.list_option}>
                                    {res.list_option}
                                    </option>
                                    ))}
                                    </select>
                                    </div>
                                    </div>
                                    );
                                    })}
                                <div className="input-group mgb-10">
                                <Input type="text" className="form-control contact_detail" placeholder="Phone, email or URL" value={res.detail}
                                onChange={(e) => {
                                    setContactDetail(e.target.value);
                                }} />
                                <div className="input-group-append">
                                <select className="form-control form-control-uniform" value={contacttype} onChange={(e) => {
                                    setContactType(e.target.value);
                                }}>
                                {customerDetails?.contact_types?.map((res) => (
                                <option key={res.list_option} value={res.list_option}>
                                {res.list_option}
                                </option>
                                ))}
                                </select>
                                </div>
                                </div>
                                <div className="hstack gap-2 mt-2">
                                <Button className="btn btn-light" onClick={() => {handleContactEdit()}}>Cancel</Button>
                                <Button className="btn btn-info" onClick={() => { Update_Contact(res); } }>Update</Button>
                                </div>
                                </div>
                                )}
                                    <div className="align-items-center mb-2 contact-sec-bottom">
                                        <div className="">
                                            <h6 className=" mb-1">Name</h6>
                                            <p className="mb-0 text-muted">{res.name}</p>
                                        </div>
                                        <div className="">
                                            <h6 className=" mb-1">Description</h6>
                                            <p className="mb-0 text-muted">{res?.description?.substring(0, 25)}</p>
                                        </div>
                                        {res?.contact_detail_data?.map(function (data) {
                                            return (
                                                <div className="flex-shrink-0" key={data.id}>
                                                    <h6 className="mb-1">{data.detail_type}</h6>
                                                    <p className="mb-0 text-muted ">{data.detail}</p>
                                                </div>);
                                        })}
                                    </div>
                                </div><div>
                            </div></>
                            ))}
                            </CardBody>
                        </Card>
                    </Col>
                           
                    <Col xl={9}>
                        <Card>
                            <CardHeader>
                                <div className="row align-items-center">
                                    <div className="col">
                                        <Nav
                                            className="nav-tabs-custom card-header-tabs border-bottom-0"
                                            role="tablist"
                                        >
                                            <NavItem className="flex-grow-1">
                                                <NavLink
                                                    href="#"
                                                    className={classnames({
                                                        active: customActiveTab === "Customerdetails",
                                                    })}
                                                    onClick={() => { handleClick("Customerdetails") }}
                                                >
                                                    <h5 className="text-primary text-center" >Jobs/Opportunities</h5>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="flex-grow-1">
                                                <NavLink
                                                    href="#"
                                                    className={classnames({
                                                        active: customActiveTab === "Jobpage",
                                                    })}
                                                    onClick={() => { handleClick("Jobpage") }}
                                                >
                                                    <h5 className="text-primary text-center">Customer Details</h5>
                                                </NavLink>
                                            </NavItem>
                                         
                                        </Nav>
                                    </div>
                                </div>
                            </CardHeader>
                        </Card>
                        {renderComponent()}

                    </Col>
                </Row>
            </Container>
        </div>
)


}
export default CustomerDetails