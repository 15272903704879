import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Card, CardHeader, CardBody, Col, Label,
  Input, Modal, Row,
} from "reactstrap";

import { Link } from 'react-router-dom'
// import Components
import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";
import { toast, ToastContainer } from 'react-toastify';
import { isEmpty } from "lodash";
import {

} from "reactstrap";
// Import Images
import * as Yup from "yup";
import { useFormik } from "formik";
//redux
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import axios from 'axios';
//Import actions
import {
  getContacts as onGetContacts,
  addNewContact as onAddNewContact,
  updateContact as onUpdateContact,
  deleteContact as onDeleteContact,
} from "../../../store/actions";
import { get_cookie } from '../../../helpers/get_cookie';
import 'react-toastify/dist/ReactToastify.css';
// google map autocomplete(only cities)
import GoogleAutocomplete from 'react-google-autocomplete';

export const StorageWarehouse = () => {
  document.title = "Storage Warehouse | Onexfort";
  const isContactSuccess = true;
  const error = null;
  const [contact, setContact] = useState([]);

  // add large modals
  const [modal_large, setmodal_large] = useState(false);
  function tog_large() {
    setmodal_large(!modal_large);
  }

  const [isEdit, setIsEdit] = useState(false);
  // Delete Data
  const handleDeleteContact = () => {
    if (contact) {
      dispatch(onDeleteContact(contact._id));
      setDeleteModal(false);
    }
  };

  const onClickDelete = (contact) => {
    setContact(contact);
    setDeleteModal(true);
  };

  // Add Data
  const handleContactClicks = () => {
    setContact("");
    setIsEdit(false);
    toggle();
  };

  const [data, setData] = useState([]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // img: (contact && contact.img) || '',
      name: (contact && contact.name) || '',
      company: (contact && contact.company) || '',
      designation: (contact && contact.designation) || '',
      email: (contact && contact.email) || '',
      phone: (contact && contact.phone) || '',
      lead_score: (contact && contact.lead_score) || '',
      tags: (contact && contact.tags) || [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      company: Yup.string().required("Please Enter Company"),
      designation: Yup.string().required("Please Enter Designation"),
      email: Yup.string().required("Please Enter Email"),
      phone: Yup.string().required("Please Enter Phone"),
      lead_score: Yup.string().required("Please Enter lead_score"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateContact = {
          _id: contact ? contact._id : 0,
          // img: values.img,
          name: values.name,
          company: values.company,
          designation: values.designation,
          email: values.email,
          phone: values.phone,
          lead_score: values.lead_score,
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // update Contact 
        dispatch(onUpdateContact(updateContact));
        validation.resetForm();
      } else {
        const newContact = {
          _id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          // img: values["img"],
          name: values["name"],
          company: values["company"],
          designation: values["designation"],
          email: values["email"],
          phone: values["phone"],
          lead_score: values["lead_score"],
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // save new Contact
        dispatch(onAddNewContact(newContact));
        validation.resetForm();
      }
      toggle();
    },
  });


  const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);


  // Checked All 
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  // Date && Time Format

  const dateFormat = () => {
    var d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return (d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear());
  };
  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      dispatch(onDeleteContact(element.value));
      setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".contactCheckBox:checked");
    ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  const dispatch = useDispatch();
  const crmcontacts = data;

  useEffect(() => {
    setContact(crmcontacts);
  }, [crmcontacts]);

  useEffect(() => {
    if (!isEmpty(crmcontacts)) {
      setContact(crmcontacts);
      setIsEdit(false);
    }
  }, [crmcontacts]);

  //delete Conatct
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const [modal, setModal] = useState(false);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setContact(null);
    } else {
      setModal(true);
      setTag([]);
      setAssignTag([]);
    }
  }, [modal]);
  // Update Data
  const handleContactClick = useCallback((arg) => {
    const contact = arg;

    setContact({
      _id: contact._id,
      // img: contact.img,
      name: contact.name,
      company: contact.company,
      email: contact.email,
      designation: contact.designation,
      phone: contact.phone,
      lead_score: contact.lead_score,
      last_contacted: contact.date,
      // time: contact.time,
      tags: contact.tags,
    });

    setIsEdit(true);
    toggle();
  }, [toggle]);


  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState([]);
  const [countryCode, setCountryCode] = useState([]);


  const loadSettings = () => {
    axios.get("/api/storage-warehouses/data")
      .then((res) => {
        setData(res.data);
      })
      .catch(error => {
        console.log(error);
      });
    axios.get("/api/crm-leads/countryCode")
      .then((res) => {
        console.log(res);
        setCountryCode(res.country_code);
      })
      .catch(error => {
        console.log(error);
      });

  }
  console.log("data:", data);

  useEffect(() => {
    loadSettings();
  }, []);


  const [warehouse_name, setWarehouseName] = useState("");
  const [description, setDescription] = useState("");
  const [address, setAddress] = useState("");
  const [suburb, setSuburb] = useState("");
  const [state, setState] = useState("");
  const [postcode, setPostcode] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [contact_person, setContactperson] = useState("");
  const [active, setActive] = useState("");
  const [id, setId] = useState("");
  //toggle
  const [isChecked, setIsChecked] = useState("Y");


  //Savs and Update Button
  const handleSubmit = (event) => {
    event.preventDefault();

    if (warehouse_name === '') {
      toast.error("The Warehouse Name field is required.", { theme: "light" });
      return; // Stop form submission if warehouse_name is empty
    } else if (isNaN(postcode) || postcode === '') {
      toast.error("The Postcode must be a number.", { theme: "light" });
      return;
    } else if (isNaN(phone) || phone === '') {
      toast.error("The Phone must be a number.", { theme: "light" });
      return;
    } else if (email === '') {
      toast.error("The Email field is required.", { theme: "light" });
      return;
    } else if (email !== '') {
      const emailPattern = /^[A-Za-z0-9._+-]+@[A-Za-z._-]+\.[A-Za-z]{2,}$/;
      const isValid = emailPattern.test(email);
      if (!isValid) {
        toast.error('Invalid email', { theme: "light" });
        return;
      }
    }
    var formData = {
      "warehouse_name": warehouse_name,
      "description": description,
      "address": address,
      "suburb": suburb,
      "state": state,
      "postal_code": postcode,
      "phone": phone,
      "email": email,
      "contact_person": contact_person,
      "active": isChecked,
      "id": id,
    }
    console.log("formData", formData);
    if (!isEdit) {
      axios.post("api/storage-warehouses/store", formData)
        .then(response => {
          toast.success(response.message, { theme: "light" });
          loadSettings();
          tog_large(false);
          setWarehouseName("");
          setDescription("");
          setAddress("");
          setSuburb("");
          setState("");
          setPostcode("");
          setPhone("");
          setEmail("");
          setContactperson("");
          setActive("");
          setId("");
        })
        .catch(error => {
          console.error(error);
        });
    } else {
      axios.post("api/storage-warehouses/update", formData)
        .then(response => {
          toast.success(response.message, { theme: "light" });
          loadSettings();
          tog_large(false);
        })
        .catch(error => {
          console.error(error);
        });
    }

  }
  const handleOrderClick = useCallback((arg) => {
    const order = arg;

    tog_large(true);
    setOrder({
      _id: order._id,
      orderId: order.orderId,
      customer: order.customer,
      product: order.product,
      orderDate: order.orderDate,
      ordertime: order.ordertime,
      amount: order.amount,
      payment: order.payment,
      status: order.status
    });

    setIsEdit(true);
    toggle();
  }, [toggle]);

  // Customber Column
  const columns = useMemo(
    () => [
      {
        Header: <input type="checkbox" id="checkBoxAll" className="form-check-input" onClick={() => checkedAll()} />,
        Cell: (cellProps) => {
          return <input type="checkbox" className="contactCheckBox form-check-input" value={cellProps.row.original._id} onChange={() => deleteCheckbox()} />;
        },
        id: '#',
      },
      {
        Header: "Warehouse",
        accessor: "warehouse_name",
        filterable: false,

      },
      {
        Header: "Address",
        accessor: "address",
        filterable: false,
      },

      {
        Header: "Active",
        accessor: "active",
        filterable: false,
      },

      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item">
                <button className="btn btn-sm btn-soft-info edit-list" onClick={() => {
                  const orderData = cellProps.row.original;
                  const orderId = orderData.id; // Assuming 'id' is the property containing the ID
                  setIsEdit(true);
                  tog_large(true);
                  // Use orderId as a parameter in the Axios URL
                  axios.get(`${"api/storage-warehouses/edit/"}${orderId}`)
                    .then(response => {
                      console.log(response);
                      setId(response.storage_warehouse.id);
                      setWarehouseName(response.storage_warehouse.warehouse_name);
                      setDescription(response.storage_warehouse.description);
                      setAddress(response.storage_warehouse.address);
                      setSuburb(response.storage_warehouse.suburb);
                      setState(response.storage_warehouse.state);
                      setPostcode(response.storage_warehouse.postal_code);
                      setPhone(response.storage_warehouse.phone);
                      setEmail(response.storage_warehouse.email);
                      setContactperson(response.storage_warehouse.contact_person);
                      setIsChecked(response.storage_warehouse.active);
                    })
                    .catch(error => {
                      console.error(error);
                    });
                }} ><i className="bx bxs-pencil fs-12 pt-1"></i>
                </button>
              </li>
              <li className="list-inline-item edit">
                {/*<Link
                  to="#"
                  className=" btn btn-sm btn-soft-danger fs-13 pt-1"

                >
                  <i className="bx bxs-trash fs-12"></i>
                </Link>*/}
              </li>

            </ul>
          );
        },
      },
    ],
    [handleContactClick, checkedAll]
  );

  const [activeInterest, setActiveInterest] = useState("N");
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (name === "active") {
      setActiveInterest(checked ? "Y" : "N");
    }
  };

  const [value, setValue] = useState(null);

  //toggle
  const handleToggle = () => {
    setIsChecked(isChecked === 'Y' ? 'N' : 'Y');
  };

  const handlePlaceSelect = (place) => {
    const selectedCity = place && place.formatted_address;
    console.log(selectedCity);
  };

  // const [selectedCity, setSelectedcity] = useState();
  // const [selectedSuburb, setselectedSuburb] = useState();
  // const [selectedPostcode, setselectedPostcode] = useState();
  const residentialplace = (place) => {
    var ressubpremise =
      place &&
      place.address_components.find((component) =>
        component.types.includes("subpremise")
      )?.long_name;
    var resstreet_number =
      place &&
      place.address_components.find((component) =>
        component.types.includes("street_number")
      )?.long_name;
    var resroute =
      place &&
      place.address_components.find((component) =>
        component.types.includes("route")
      )?.long_name;
    const val =
      (ressubpremise ? ressubpremise + ", " : "") +
      (resstreet_number ? resstreet_number + ", " : "") +
      (resroute ? resroute : "");
      setAddress(val);
    var respickSuburb =
      place &&
      place.address_components.find((component) =>
        component.types.includes("locality")
      )?.long_name;
    setSuburb(respickSuburb);

    var respickPostcode =
      place &&
      place.address_components.find((component) =>
        component.types.includes("postal_code")
      )?.long_name;
    setPostcode(respickPostcode);

    var resState =
      place &&
      place.address_components.find((component) =>
        component.types.includes("administrative_area_level_1")
      )?.long_name;
    setState(resState);
  };
  return (
    <div>
      <Card>
        <CardHeader>
          <div className="d-flex align-items-center flex-wrap gap-2">
            <div className="flex-grow-1">
              <button
                className="btn btn-brown add-btn"
                onClick={() => {
                  tog_large(true);
                  //toggle
                  setIsChecked('Y');
                  setIsEdit(false);
                  setWarehouseName("");
                  setDescription("");
                  setAddress("");
                  setSuburb("");
                  setState("");
                  setPostcode("");
                  setPhone("");
                  setEmail("");
                  setContactperson("");
                  setActive("");
                  setId("");

                }
                }
              >
                <i className="ri-add-fill me-1 align-bottom"></i> Add Storage Warehouse
              </button>
            </div>

          </div>
        </CardHeader>
      </Card>
      <Col xxl={12}>
        <Card id="contactList">
          <CardBody className="pt-0">
            <div>
              {isContactSuccess && crmcontacts.length ? (
                <TableContainer
                  columns={columns}
                  data={(crmcontacts || [])}
                  isGlobalFilter={true}
                  isAddUserList={false}
                  customPageSize={50}
                  className="custom-header-css"
                  divClass="table-responsive table-card mb-3"
                  tableClass="align-middle table-nowrap"
                  theadClass="table-light"
                  handleContactClick={handleContactClicks}
                  isContactsFilter={true}
                  SearchPlaceholder="Search for contact..."
                />
              ) : (<Loader />)
              }

            </div>

            <Modal
              className="mt-5"
              size="lg"
              isOpen={modal_large}
              toggle={() => {
                tog_large();
              }}
            >

              <Card>
                <CardHeader>
                  <h5 className="mb-0">
                    {!!isEdit ? "Edit Storage Warehouse" : "Create Storage Warehouse"}</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                  <h5 className="mb-3">Storage Warehouse Detail</h5>
                    <Input type="hidden" value={id} onChange={(e) => setId(e.target.value)} className="form-control" placeholder="" id="warehouseName" />
                    <Col md={6}>
                      <div className="mb-3 ">
                        <Label for="warehouseName" className="form-label">Warehouse Name</Label>
                        <Input type="text" value={warehouse_name} onChange={(e) => setWarehouseName(e.target.value)} className="form-control" placeholder="" id="warehouseName" />
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="mb-3">
                        <Label for="description" className="form-label">Description</Label>
                        <textarea
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          className="form-control"
                          rows="3"
                        >
                        </textarea>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label for="address" className="form-label">Address</Label>
                        <GoogleAutocomplete
                          apiKey="AIzaSyB2SMtaVBlqC5v72gqS716BX8R5oXklaFc"
                          value={address}
                          onPlaceSelected={(place) =>
                            residentialplace(place)
                          }
                          onChange={(e)=> setAddress(e.target.value)}
                          options={{
                            types: ["geocode"],
                            componentRestrictions: {
                              country: countryCode,
                            },
                          }}
                          style={{
                            width: "370px",
                            height: "38px",
                            border: "1px solid #ccc",
                            padding: "10px",
                            borderRadius: "5px",
                            position: "relative",
                            zIndex: 9999,
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label for="suburb" className="form-label">Suburb</Label>
                        <Input type="text" value={suburb} onChange={(e) => setSuburb(e.target.value)} className="form-control" placeholder="" id="suburb" />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label for="state" className="form-label">State</Label>
                        <Input type="text" value={state} onChange={(e) => setState(e.target.value)} className="form-control" placeholder="" id="state" />
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="mb-3">
                        <Label for="postCode" className="form-label">Post Code</Label>
                        <Input type="text" value={postcode} onChange={(e) => setPostcode(e.target.value)} className="form-control" placeholder="" id="postCode" />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label for="phone" className="form-label">Phone</Label>
                        <Input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} className="form-control" placeholder="" id="phone" />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label for="email" className="form-label">Email</Label>
                        <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="" id="email" />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label for="contactPerson" className="form-label">Contact Person</Label>
                        <Input type="text" value={contact_person} onChange={(e) => setContactperson(e.target.value)} className="form-control" placeholder="" id="contactPerson" />
                      </div>
                    </Col>

                    <Col md={12}>
                      <Row>
                        <Col md={9}>
                          <div>
                            <div className="form-check mb-2" style={{ paddingLeft: '0px' }}>
                              <Label className="form-check-label" for="checkActive">
                                Active
                              </Label>
                            </div>
                            <div className="form-check form-switch form-switch-lg">
                              <Input
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckRightDisabled"
                                value="Y"
                                name="active"
                                checked={isChecked === 'Y'}
                                onChange={handleToggle}

                              />
                            </div>
                          </div>
                        </Col>
                        <div className="hstack gap-2 justify-content-end">
                          <button type="submit" onClick={handleSubmit} className="btn btn-success">
                            {!!isEdit
                              ? "Update"
                              : "Save"}</button>
                          <button type="submit" className="btn btn-primary" onClick={() =>
                            tog_large(false)}>Cancel</button>
                        </div>
                      </Row>
                    </Col>
                    <Row>

                    </Row>
                  </Row>
                </CardBody>
              </Card>

            </Modal>
            <ToastContainer closeButton={false} limit={1} />
          </CardBody>
        </Card>
      </Col>
    </div>
  )
}
