import { Card, CardBody, CardHeader, Col, Container, Row , Accordion, AccordionItem,Collapse,Label, Input} from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import React, { useState,useRef, useCallback, useEffect, useMemo } from 'react';
import Select from "react-select";
import Loader from "../../../Components/Common/Loader";

// Import Table Data
import { BaseExample } from '../GridTablesData';
import axios from 'axios';
import { get_cookie } from '../../../helpers/get_cookie';
import Flatpickr from "react-flatpickr";
import classnames from "classnames";
import * as moment from "moment";
import TableContainer from "../../../Components/Common/TableContainer";
import { useSelector, useDispatch } from "react-redux";
import { toast, ToastContainer } from 'react-toastify';
import {
  getContacts as onGetContacts,
  addNewContact as onAddNewContact,
  updateContact as onUpdateContact,
  deleteContact as onDeleteContact,
} from "../../../store/actions";
import { isEmpty } from "lodash";
import { Link } from "react-router-dom";

const CustResidential = () => {
    const isContactSuccess = true;
    const [contact_person, setContactperson] = useState("");
    const [contact, setContact] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);
 const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
 const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [modal, setModal] = useState(false);
   const dispatch = useDispatch();
    //data table
    const [col1, setcol1] = useState(false);
    const [lead_status,setLeadStatus] = useState([]);
    const [sorting_order_array,setSortingOrderArray] = useState([]);
    const [lead_status_id, setLeadstatusid] = useState("");
     const [sorting_order_id, setSortingOrderid] = useState("");
     const [createdDateStart, setCreatedDateStart] = useState(null);
    const [createdDateEnd, setCreatedDateEnd] = useState(null);
    const [sort_descending, setSortdescending] = useState("");
    const [org_date, setOrg_Date] = useState([]);

    const t_col1 = () => {
        setcol1(!col1);

    };
   const [data, setData] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".contactCheckBox:checked");
    ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };
  const handleContactClicks = () => {
    setContact("");
    setIsEdit(false);
    toggle();
  };
  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setContact(null);
    } else {
      setModal(true);
      setTag([]);
      setAssignTag([]);
    }
  }, [modal]);
 const crmcontacts = data;

  useEffect(() => {
    setContact(crmcontacts);
  }, [crmcontacts]);

  useEffect(() => {
    if (!isEmpty(crmcontacts)) {
      setContact(crmcontacts);
      setIsEdit(false);
    }
  }, [crmcontacts]);
  const handleContactClick = useCallback((arg) => {
    const contact = arg;

    setContact({
      _id: contact._id,
      // img: contact.img,
      name: contact.name,
      company: contact.company,
      email: contact.email,
      designation: contact.designation,
      phone: contact.phone,
      lead_score: contact.lead_score,
      last_contacted: contact.date,
      // time: contact.time,
      tags: contact.tags,
    });

    setIsEdit(true);
    toggle();
  }, [toggle]);
  
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);
    //end of data table content
     const loadTable =() =>{
         setisLoading(true);
      const postData = {
            created_date_start:null,
            created_date_end:null,
            lead_status:null,
            sorting_order:null,
            sort_descending:null,
        };
      axios.post("/api/crm-leads/residentialData", postData)
        .then((res) => {
          setOrg_Date(res.data[0]);
          setData(res.data);
          console.log(res?.data,"res1234");
          setCreatedDateEnd(moment().format(res?.data[0]?.organisation_date_picker));
          setCreatedDateStart(moment().subtract(1, 'months').format(res?.data[0]?.organisation_date_picker));
        })
        .catch((error) => {
                console.error(error);
            })
         axios.get("/api/crm-leads/residential")
        .then((response) => {
           setLeadStatus(response.lead_statuses);
           setSortingOrderArray(response.sorting_order_array);

       })
        .catch((error) => {
                console.error(error);
            })
        .finally(()=>{
                setisLoading(false);
            })
   
}
useEffect(() => {
    loadTable();
     }, []);
 const handleSubmit = (event) => {
    event.preventDefault();
    let result;
    if(lead_status_id == "" || lead_status_id == undefined || lead_status_id == null){
            toast.error("Select Lead Status", {theme:"light"});
            return false;
    }else{
        const values =lead_status_id.map(item => item.label);
         result = values.join(',');
    }
    // let startDate;
    // if(createdDateStart !== undefined && createdDateStart !== "" && moment(createdDateStart, 'DD MMM, YYYY').isValid()){
    //   startDate = moment(createdDateStart, 'DD MMM, YYYY').format('DD/MM/YYYY');
    // }else{
    //     startDate = null;
    // }
    // let endDate;
    // if (createdDateEnd !== undefined && createdDateEnd !== "" && moment(createdDateEnd, 'DD MMM, YYYY').isValid()) {
    //   endDate = moment(createdDateEnd, 'DD MMM, YYYY').format('DD/MM/YYYY');
    // } else {
    //   endDate = null;
    // }
    const postData = {
            created_date_start:createdDateStart,
            created_date_end:createdDateEnd,
            lead_status:result,
            sorting_order:sorting_order_id.value,
            sort_descending:activeInterest,
        };
        console.log(postData,"postData");
        setisLoading(true);
      axios.post("/api/crm-leads/residentialData", postData)
        .then((res) => {
          setData(res.data);
          console.log(res.data);
        })
        .catch((error) => {
                console.error(error);
            })
        .finally(()=>{
                setisLoading(false);
            })
}
const sortingOrderArray = Object.keys(sorting_order_array).map(key => ({
  value: key,
  label: sorting_order_array[key]
}));
function handleLeadStatus(selectedLeadStatus) {
        setLeadstatusid(selectedLeadStatus);
    }
    function handleSortingOrder(selectedArray) {
        setSortingOrderid(selectedArray);
    }
    const [activeInterest, setActiveInterest] = useState(0);
        const handleCheckboxChange = (event) => {
            setActiveInterest(event.target.checked ? 1 : 0);
      };
   const resetTable = () => {
        setCreatedDateStart(null);
        setCreatedDateEnd(null);
        setLeadstatusid("");
        setSortingOrderid("");
        setSortdescending(0);
        loadTable();
    }
    const columns = useMemo(
    () => [
      /*{
        Header: <input type="checkbox" id="checkBoxAll" className="form-check-input" onClick={() => checkedAll()} />,
        Cell: (cellProps) => {
          return <input type="checkbox" className="contactCheckBox form-check-input" value={cellProps.row.original._id} onChange={() => deleteCheckbox()} />;
        },
        id: '#',
      },*/
      {
        Header: "Name",
        accessor: "customer",
        filterable: false,
        Cell: (cell) => {
                    return <Link to={"/customerdetails/"+cell.value.id} className="fw-medium link-primary">{cell.value.name}</Link>;
                },
      },
      {
        Header: "Mobile",
        accessor: "mobile",
        filterable: false,
      },

      {
        Header: "Email",
        accessor: "email",
        filterable: false,
      },
      {
        Header: "Status",
        accessor: "lead_status",
        filterable: false,
      },
    ],
    [handleContactClick, checkedAll]
  );
    document.title = "Residential | Onexfort";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Residential Customer" pageTitle="Customer" />
                    <Accordion id="default-accordion-example">
                        <AccordionItem>
                            <h2 className="accordion-header" id="headingOne">
                                <button
                                    className={classnames("accordion-button fw-semibold", { collapsed: !col1 })} type="button" onClick={t_col1} style={{ cursor: "pointer" }} >
                                    Search Filters
                                </button>
                            </h2>
                            <Collapse isOpen={col1} className="accordion-collapse" id="collapseOne" >
                                <div className="accordion-body">
                                    <Row>
                                        <Col md={4}>
                                            <div className="mb-3">
                                                <Label for="leadStatusinput" className="form-label">Lead Status</Label>
                                                <Select
                                                    value={lead_status_id}
                                                    isMulti={true}
                                                    onChange={(selectedLeadStatus) => {
                                                         handleLeadStatus(selectedLeadStatus);
                                                    }}
                                                    options={lead_status.map(item => ({ value: item.id, label: item.lead_status }))}
                                                />
                                            </div>
                                        </Col>
                                        {/*<Col lg={4}>
                                            <div className="mb-3">
                                                <Label for="createdDateinput" className="form-label">Created Date</Label>
                                                <Flatpickr
                                                    className="form-control"
                                                    options={{
                                                        mode: "range",
                                                        dateFormat: "Y-m-d"
                                                    }}
                                                    value={[createdDateStart, createdDateEnd]}
                                                    onChange={(date) => {
                                                        if (date.length === 2) {
                                                            setCreatedDateStart(moment(date[0]).format('DD/MM/YYYY'));
                                                            setCreatedDateEnd(moment(date[1]).format('DD/MM/YYYY'));
                                                        } else {
                                                            setCreatedDateStart(null);
                                                            setCreatedDateEnd(null);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </Col>*/}
                                        <Col lg={2}>
                                            <div className="mb-3">
                                                <Label for="createdDateinput" className="form-label">Created Start Date</Label>
                                                {/* <Flatpickr
                                                    className="form-control" placeholder='Start Date' value={createdDateStart} onChange={(date) => setCreatedDateStart(moment(date[0]).format('DD MMM, YYYY'))}
                                                    options={{
                                                        dateFormat: "d M, Y",
                                                        enableTime: false
                                                    }}
                                                /> */}
                                                <Flatpickr
                                                    className="form-control" placeholder='Start Date'
                                                    options={{
                                                        dateFormat: org_date?.organisation_date_format,
                                                    }}
                                                    value={createdDateStart}
                                                    onChange={(selectedDates) => {
                                                        if (selectedDates.length === 1) {
                                                          setCreatedDateStart(moment(selectedDates[0]).format(org_date?.organisation_date_picker));
                                                        } else {
                                                          setCreatedDateStart(null);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={2}>
                                            <div className="mb-3">
                                                <Label for="createdDateinput" className="form-label" >Created End Date</Label>
                                                {/* <Flatpickr
                                                    className="form-control" placeholder='End Date' value={createdDateEnd} onChange={(date) => setCreatedDateEnd(moment(date[0]).format('DD MMM, YYYY'))}
                                                    options={{
                                                        dateFormat: "d M, Y",
                                                        enableTime: false
                                                    }}
                                                /> */}
                                                <Flatpickr
                                                    className="form-control" placeholder='End Date'
                                                    options={{
                                                        dateFormat: org_date?.organisation_date_format,
                                                    }}
                                                    value={createdDateEnd}
                                                    onChange={(selectedDates) => {
                                                        if (selectedDates.length === 1) {
                                                            setCreatedDateEnd(moment(selectedDates[0]).format(org_date?.organisation_date_picker));
                                                        } else {
                                                            setCreatedDateEnd(null);
                                                        }
                                                    }}
                                                />

                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <div className="mb-3">
                                                <Label for="leadStatusinput" className="form-label">Sorting Order</Label>
                                                 <Select value={sorting_order_id} onChange={(selectedArray) => {
                                                        handleSortingOrder(selectedArray);
                                                    }}
                                                options={sortingOrderArray} />
                                            </div>
                                        </Col>
                                        <Col lg={2}>
                                            <div className="form-check form-radio-pad form-check-right mb-2">
                                                <Input className="form-check-input" type="checkbox" name="formCheckboxRight" id="formCheckboxRight1"  value="sort_descending" onChange={handleCheckboxChange}
                          checked={activeInterest == 1}/>
                                                <Label className="form-check-label" for="formCheckboxRight1">
                                                    Descending
                                                </Label>

                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="text-start">
                                                <button type="submit" onClick={handleSubmit} className="btn form-btn-marg btn-primary">Apply</button>
                                                <button type="submit" onClick={resetTable} className="btn form-btn-marg btn-primary">Reset</button>
                                            </div>

                                        </Col>
                                    </Row>
                                </div>
                            </Collapse>
                        </AccordionItem>
                    </Accordion>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0 flex-grow-1">Search Filters</h4>
                                    
                                </CardHeader>

                                <CardBody>
                                    <div id="table-gridjs">
                                        {/*<BaseExample data={data}/>*/}
                                        {isContactSuccess && crmcontacts ? (
                                <TableContainer
                                  columns={columns}
                                  data={(crmcontacts || [])}
                                  isGlobalFilter={true}
                                  isAddUserList={false}
                                  customPageSize={8}
                                  className="custom-header-css"
                                  divClass="table-responsive table-card mb-3"
                                  tableClass="align-middle table-nowrap"
                                  theadClass="table-light"
                                  handleContactClick={handleContactClicks}
                                  isContactsFilter={true}
                                  SearchPlaceholder="Search for contact..."
                                />
                              ) : (<Loader />)
                              }
                                    </div>
                                    {/*{isLoading && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            background: 'rgba(255, 255, 255, 0.8)',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            zIndex: 9999,
                                        }}
                                    >
                                        <Loader />
                                    </div>
                                )}*/}
                                </CardBody>
                            </Card>
                            <ToastContainer closeButton={false} limit={1} theme="light" />
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};

export default CustResidential;