import { Card, CardBody, Col, Row } from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import img13 from "../../../assets/images/small/img-13.png";
import { Button } from 'reactstrap';
import { isEmpty } from "lodash";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";

import "../../../assets/scss/pages/_settings.scss";

import {
  CardHeader,
  Label,
  Input,

} from "reactstrap";
//Import actions
import {
  getContacts as onGetContacts,
  addNewContact as onAddNewContact,
  updateContact as onUpdateContact,
  deleteContact as onDeleteContact,
} from "../../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
//reloading the page
import { jsx } from '@emotion/react'
import { ClipLoader } from 'react-spinners';
//toast
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// axios
import axios from "axios";
import { api } from "../../../config";
import Loader from "../../../Components/Common/Loader";


//reloading the page
const override = jsx`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
export const ProfileSetting = () => {
  document.title="Profile Settings | Onexfort";
  const [modal, setModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);//reloading the page


  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setContact(null);
    } else {
      setModal(true);
      setTag([]);
      setAssignTag([]);
    }
  }, [modal]);


  const [contact, setContact] = useState([]);
  const dispatch = useDispatch();
  const { crmcontacts, isContactCreated, isContactSuccess, error } = useSelector((state) => ({
    crmcontacts: state.Crm.crmcontacts,
    isContactCreated: state.Crm.isContactCreated,
    isContactSuccess: state.Crm.isContactSuccess,
    error: state.Crm.error,
  }));

  useEffect(() => {
    setContact(crmcontacts);
  }, [crmcontacts]);

  useEffect(() => {
    if (!isEmpty(crmcontacts)) {
      setContact(crmcontacts);
      setIsEdit(false);
    }
  }, [crmcontacts]);


  const [isEdit, setIsEdit] = useState(false);
  //delete Conatct
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  // Date & Time Format

  const dateFormat = () => {
    var d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return (d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear());
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // img: (contact && contact.img) || '',
      name: (contact && contact.name) || '',
      company: (contact && contact.company) || '',
      designation: (contact && contact.designation) || '',
      email: (contact && contact.email) || '',
      phone: (contact && contact.phone) || '',
      lead_score: (contact && contact.lead_score) || '',
      tags: (contact && contact.tags) || [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      company: Yup.string().required("Please Enter Company"),
      designation: Yup.string().required("Please Enter Designation"),
      email: Yup.string().required("Please Enter Email"),
      phone: Yup.string().required("Please Enter Phone"),
      lead_score: Yup.string().required("Please Enter lead_score"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateContact = {
          _id: contact ? contact._id : 0,
          // img: values.img,
          name: values.name,
          company: values.company,
          designation: values.designation,
          email: values.email,
          phone: values.phone,
          lead_score: values.lead_score,
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // update Contact 
        dispatch(onUpdateContact(updateContact));
        validation.resetForm();
      } else {
        const newContact = {
          _id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          // img: values["img"],
          name: values["name"],
          company: values["company"],
          designation: values["designation"],
          email: values["email"],
          phone: values["phone"],
          lead_score: values["lead_score"],
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // save new Contact
        dispatch(onAddNewContact(newContact));
        validation.resetForm();
      }
      toggle();
    },
  });

  const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);

  const [gbl, setGbl] = useState('');
  const [employeeDetails, setEmployeeDetails] = useState('');
  const [proName, setProName] = useState("");
  const [proPassword, setProPassword] = useState("");
  const [proEmail, setProEmail] = useState("");
  const [proMobile, setProMobile] = useState("");
  const [proGender, setProGender] = useState("");
  const [proAddress, setProAddress] = useState("");
  const [id, setId] = useState("");
  const [shouldShowIdField, setShouldShowIdField] = useState(false);
  const [imagesel, setImage] = useState("");
  const [uploadedImage, setUploadedImage] = useState("");
  const [isLoading, setisLoading] = useState(false);


  const loadSettings = () => {
    setisLoading(true);
    axios.get('/api/profile-settings/data')
      .then(response => {
        setGbl(response.userdetail);
        setEmployeeDetails(response.employeedetail);
        setProPassword("");
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setisLoading(false);
      })
  }
  
  //*backend response*
  useEffect(() => {
    loadSettings();
  }, []);

  // profile_gender
  const gender = [
    { value: `male`, label: `male` },
    { value: `female`, label: `female` },
    { value: `others`, label: `Others` }
  ];

   //*input field default value(database value)* 
   //proid
  useEffect(() => {
    setId(gbl.id);
  }, [gbl.id]);
  //proname
  useEffect(() => {
    setProName(gbl.name);
  }, [gbl.name]);
  // proemail
  useEffect(() => {
    setProEmail(gbl.email);
  }, [gbl.email]);
  // propassword
  useEffect(() => {
    setProPassword("");
  }, []);
  // promobile
  useEffect(() => {
    setProMobile(gbl.mobile);
  }, [gbl.mobile]);
  //progender
  useEffect(() => {
    const selectedOption = gender.find(option => option.value === gbl.gender);
    if (selectedOption) {
      setProGender(selectedOption.value);
    }
  }, [gbl.gender]);
  const selectedGenderOption = gender.find(option => option.value === proGender);
  //proaddress
  useEffect(() => {
    if (!isEmpty(employeeDetails?.address) || employeeDetails?.address!=null ) {
        setProAddress(employeeDetails?.address);
    }
    else{
      setProAddress("");
    }
  }, [employeeDetails?.address]);
  //proimage
  useEffect(() => {
    setImage(gbl.image || '');
  }, [gbl.image]);


 //*onchange values for dropdown*
 //proname
 const handleNameChange = (e) => {
  setProName(e.target.value);
};
//proemail
const handleEmailChange = (e) => {
  setProEmail(e.target.value);
};
//propassword
const handlePasswordChange = (e) => {
  setProPassword(e.target.value);
};
//promobile
const handleMobileChange = (e) => {
  setProMobile(e.target.value);
};
//progender
 const handleGenderChange = (selectedOption) => {
  setProGender(selectedOption.value);
};
//proaddress
const handleAddressChange = (e) => {
  setProAddress(e.target.value);
};


 //image selection
 const fileInputRef = useRef(null);
 const [selectedImage, setSelectedImage] = useState(null);
 const [isImageSelected, setIsImageSelected] = useState(false);

 const handleButtonClick = (event) => {
   event.preventDefault();
   fileInputRef.current.click();
 };

 const handleFileInputChange = (event) => {
   const selectedFile = event.target.files[0];
   if(selectedFile){
   const objectUrl = URL.createObjectURL(selectedFile);
   setUploadedImage(selectedFile);
   setSelectedImage(objectUrl);
   setIsImageSelected(true);
  }
 };

 const handleRemoveClick = () => {
   setSelectedImage(null);
   setUploadedImage(null);
   setIsImageSelected(false);
   fileInputRef.current.value = null; // Clear the input field value
 };

 const handleChangeClick = (event) => {
   event.preventDefault();
   fileInputRef.current.click();
 };

  //*Submit(Update button)*
  const handleSubmit = (event) => {
    event.preventDefault();
    //reloading the page
    setIsUpdating(true);
    if (proName === '') {
      toast.warning("The name field is required.", { theme: "light" });
      setIsUpdating(false);
      return; // Stop form submission if proName is empty
    } else if (proEmail === '') {
      toast.warning("The email field is required.", { theme: "light" });
      setIsUpdating(false);
      return; // Stop form submission if proEmail is empty
    } else if (proAddress === '') {
      toast.warning("The address field is required.", { theme: "light" });
      setIsUpdating(false);
      return; // Stop form submission if proAddress is empty
    } 
    const formData = new FormData();
    formData.append('name',proName);
    formData.append('email',proEmail);
    formData.append('gender',proGender);
    formData.append('password',proPassword);
    formData.append('mobile',proMobile);
    formData.append('image',uploadedImage);
    formData.append('address',proAddress);
    formData.append('id',id);
    const proId = id;
    axios.post(`${"api/profile-settings/update/"}${proId}`, formData)
      .then(response => {
        toast.success(response.success,{theme:"light"});
        //realoding the page
        loadSettings();
      })
      .catch(error => {
        console.error(error);
        toast.error('Something went wrong...');
      })
      .finally(() => {
        setIsUpdating(false); //reloading the page
        //image selection
        setSelectedImage(null);
        setUploadedImage(null);
        setIsImageSelected(false);
        fileInputRef.current.value = null; // Clear the input field value
      });
  }

  const handleReset = event => {
    event.preventDefault();
    setProName(gbl.name || '');
    setProEmail(gbl.email || '');
    setProMobile(gbl.mobile || '');
    setProGender(gbl.gender || '');
    setProAddress(employeeDetails.address);
    setSelectedImage( api.WEB_URL+"user-uploads/avatar/"+imagesel || img13);
  }
  return (
    <div>
      <Card>
        <CardHeader>
          <h5 className="mb-0 text-primary">Update Profile Info</h5>
        </CardHeader>
        <CardBody>
          <Col xxl={12}>

            <div className="live-preview">
              <Row className="gy-4">
                <Col xxl={12} md={12} style={{ display: shouldShowIdField ? 'block' : 'none' }}>
                  <div className="mb-3">
                    <Label for="warehouseName" className="form-label">
                      Id
                    </Label>
                    <Input
                      type="text"
                      value={id}
                      className="form-control"
                      placeholder=""
                      id="warehouseName"
                      readOnly
                    />
                    </div>
                  </Col>
                <Col xxl={12} md={12}>
                  <div>
                    <Label htmlFor="basiInput" className="form-label">Your Name</Label>
                    <Input type="text" value={proName} onChange={handleNameChange} className="form-control" id="readonlyInput" />
                  </div>
                </Col>

                <Col xxl={12} md={12}>
                  <div>
                    <Label className="form-label"> Your Email</Label>
                    <Input type="email" value={proEmail} onChange={handleEmailChange} className="form-control" />
                  </div>
                </Col>

                <Col xxl={12} md={12}>
                  <div>
                    <Label htmlFor="placeholderInput" className="form-label"> Your Password</Label>
                    <Input type="Password" value={proPassword} onChange={handlePasswordChange} className="form-control" id="placeholderInput" placeholder="" />
                  </div>
                  Leave blank to keep your current password
                </Col>

                <Col xxl={12} md={12}>
                  <div>
                    <Label htmlFor="valueInput" className="form-label">Your Mobile Number</Label>
                    <Input type="Mobile" value={proMobile} onChange={handleMobileChange} className="form-control" id="valueInput" defaultValue="" />
                  </div>
                </Col>

                <Col xxl={12} md={12}>
                  <div>
                    <Label htmlFor="readonlyInput" className="form-label">Gender</Label>
                    <Select
                    value={selectedGenderOption}
                    onChange={handleGenderChange}
                    options={gender}
                    isSearchable={true}
                  />
                  </div>
                </Col>

                <Col xxl={12} md={12}>
                  <div className="mb-3">
                    <Label htmlFor="choices-single-default" className="form-label">Your Address</Label>
                    <textarea
                    value={proAddress}
                    onChange={handleAddressChange}
                    className="form-control"
                    rows="3"
                    placeholder="Enter your address">
                  </textarea>
                  </div>

                </Col>
                <Col lg={7}>
                  <div className="mb-3">
                    <Label htmlFor="choices-single-default" className="form-label">
                      Profile Picture
                    </Label>
                    <Row className="mb-2">
                      <Col md={4}>
                        <div className="img-container">
                          {imagesel ? (
                                <img
                                className="img-thumbnail"
                                alt="Company Logo"
                                src={
                                  isImageSelected
                                    ? selectedImage
                                    : api.WEB_URL+"user-uploads/avatar/"+imagesel
                                }
                              />
                              ) : (
                                <>
                                {isImageSelected ? (
                                  <img
                                    className="img-thumbnail"
                                    alt="Company Logo"
                                    src={selectedImage}
                                  />
                                ) : (
                                  <>
                                    <img
                                      className="img-thumbnail"
                                      alt="Company Logo"
                                      src={img13}
                                    />
                                    <div className="img-text">Upload your picture</div>
                                  </>
                                )}
                              </>
                            )}
                        </div>
                      </Col>
                    </Row>
                    {isImageSelected ? (
                      <div>
                        <button
                          className="btn btn-primary change-button"
                          onClick={handleChangeClick}
                        >
                          Change
                        </button>
                        <button
                          className="btn btn-danger remove-button"
                          onClick={handleRemoveClick}
                        >
                          Remove
                        </button>
                      </div>
                    ) : (
                      <button
                        className="btn btn-info"
                        onClick={handleButtonClick}
                      >
                        Select image
                      </button>
                    )}
                    <input
                      type="file"
                      style={{ display: 'none' }}
                      ref={fileInputRef}
                      onChange={handleFileInputChange}
                    />
                  </div>
                </Col>
              </Row>
            </div>

          </Col>
          <hr></hr>
          <ToastContainer closeButton={false} limit={1} theme="light" />
           {/* reloading the page */}
           {isUpdating && (
                <div className="spinner-overlay">
                  <ClipLoader css={override} size={35} color={'#123abc'} loading={true} />
                </div>
              )}
          <div className="text-center">
            <Button className="btn btn-success" onClick={handleSubmit} disabled={isUpdating}>Update</Button> &nbsp;
            <Button className="btn btn-dark" onClick={handleReset}>Reset</Button>
          </div>
          {isLoading && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(255, 255, 255, 0.8)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999,
              }}
            >
              <Loader />
            </div>
          )}
        </CardBody>

      </Card>
    </div>
  )
}
