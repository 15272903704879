const General = {
    VehicleDailyCHecklist: {
        id: 2,
        displayText: 'Cleaning Quote Questions',
        iconClass: 'send'
    },
    Vehicles: {
        id: 0,
        displayText: ' General Cleaning Quote Form Settings',
        iconClass: 'send',
       
    },
    VehicleGroups: {
        id: 1,
        displayText: 'End of Lease Cleaning Quote Form Settings',
        iconClass: 'send'
    },
   
    OHSChecklist: {
        id: 3,
        displayText: 'End of Lease Pricing',
        iconClass: 'send'
    },
    InventoryGroups: {
        id: 4,
        displayText: 'Enable Auto Quote',
        iconClass: 'send'
    },
    InventoryDefinition: {
        id: 5,
        displayText: ' Cleaning Shifts',
        iconClass: 'send'
    },
    PropertyCategoryOption: {
        id: 6,
        displayText: ' Cleaning Team Members',
        iconClass: 'send'
    },
}

// const AutoQuote = {
//     EnableAutoQuote: {
//         id: 7,
//         displayText: ' Enable Auto Quote',
//         iconClass: 'send'
//     },
//     PricingSettings: {
//         id: 8,
//         displayText: ' Pricing Settings',
//         iconClass: 'send'
//     },
//     LocalMovesHourlySettings: {
//         id: 9,
//         displayText: ' Local Moves Hourly Settings',
//         iconClass: 'send'
//     },
//     PricingRegions: {
//         id: 10,
//         displayText: ' Pricing Regions',
//         iconClass: 'send'
//     },
//     RegionToRegionPricing: {
//         id: 11,
//         displayText: ' Region To Region Pricing',
//         iconClass: 'send'
//     },
//     RemovalsQuoteForm: {
//         id: 12,
//         displayText: ' Removals Quote Form',
//         iconClass: 'send'
//     }
// }

export const CleaningSettingsConstant = {
    General
}