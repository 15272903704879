import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid'

import axios from "axios";
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
//Import Icons
import FeatherIcon from "feather-icons-react";

import {
  Card,
  CardBody,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  CardHeader
} from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";

import Flatpickr from "react-flatpickr";
import listPlugin from '@fullcalendar/list';

//redux
import { useSelector, useDispatch } from "react-redux";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteModal from "../../Components/Common/DeleteModal";

//Simple bar
import SimpleBar from "simplebar-react";
import UpcommingEvents from "./UpcommingEvents";

import {
  getEvents as onGetEvents,
  getCategories as onGetCategories,
  addNewEvent as onAddNewEvent,
  deleteEvent as onDeleteEvent,
  updateEvent as onUpdateEvent,
  resetCalendar,
} from "../../store/actions";

const Jobschedule = () => {
  const dispatch = useDispatch();
  const [event, setEvent] = useState({});
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedDay, setSelectedDay] = useState(0);
  const [selectedNewDay, setSelectedNewDay] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditButton, setIsEditButton] = useState(true);
  const [upcommingevents, setUpcommingevents] = useState([]);
  const calendarRef = useRef(null);
  const [resources, setResources] = useState([])
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    getResources();
    getEvents();
  }, [])

  // const { events, categories, isEventUpdated } = useSelector((state) => ({
  //   events: state.Calendar.events,
  //   categories: state.Calendar.categories,
  //   isEventUpdated: state.Calendar.isEventUpdated,
  // }));

  useEffect(() => {
    dispatch(onGetEvents());
    dispatch(onGetCategories());
    // new Draggable(document.getElementById("external-events"), {
    //   itemSelector: ".external-event",
    // });
  }, [dispatch]);

  useEffect(() => {

    setUpcommingevents(events);

    upcommingevents.sort(function (o1, o2) {
      return new Date(o1.start) - new Date(o2.start);
    });

  }, [events, upcommingevents]);


  /**
   * Handling the modal state
   */
  const toggle = () => {
    if (modal) {
      setModal(false);
      setEvent(null);
      setIsEdit(false);
      setIsEditButton(true);
    } else {
      setModal(true);
    }
  };

  const vehicleGroup = "all"
  const getResources = () => {

    axios.get(`api/calendar-resources/${vehicleGroup}`)
      .then((res) => {
        res.map(item => {
          item.eventBackgroundColor = "#171e27"
        })
        console.log(res);
        setResources(res)

      })
      .catch((err) => {
        console.log(err);
      });
  }
  const getEvents = () => {
    axios.get(`api/calendar-events/${vehicleGroup}?start=2023-07-10T00:00:00&end=2023-07-11T00:00:00&_=1690955254069`)
      .then(res => {
        console.log(res);
        res.map(item => {
          item.editable = true
        })
        console.log(res);
        setEvents(res);
      })
      .then(err => {
        console.log(err);
      })
  }

  const handleEventDrop = (arg) => {
    const { event } = arg;
    const formatTime = (date) => {
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      return `${hours}:${minutes}:${seconds}`;
    };
    console.log(event);
    // Update the eventUpdates object with the new start and end times
    const eventUpdates = {
      "leg_id": event.id,
      "vehicle_id": event._def.resourceIds[0],
      "job_date": event.start.toISOString().split('T')[0],
      "start_time": formatTime(event.start),
      "end_time": formatTime(event.end),
    };
    // Call the API to update the event with the new times
    axios.post("api/updateScheduleEvent", eventUpdates)
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
  };
  /**
   * Handling click on event on calendar
   */

  const handleDeleteEvent = () => {
    dispatch(onDeleteEvent(event));
    setDeleteModal(false);
    toggle();
  };


  const submitOtherEvent = () => {
    document.getElementById("form-event").classList.remove("view-event");

    document
      .getElementById("event-title")
      .classList.replace("d-none", "d-block");
    document
      .getElementById("event-category")
      .classList.replace("d-none", "d-block");
    document
      .getElementById("event-start-date")
      .parentNode.classList.remove("d-none");
    document
      .getElementById("event-start-date")
      .classList.replace("d-none", "d-block");
    document
      .getElementById("event-location")
      .classList.replace("d-none", "d-block");
    document
      .getElementById("event-description")
      .classList.replace("d-none", "d-block");
    document
      .getElementById("event-start-date-tag")
      .classList.replace("d-block", "d-none");
    document
      .getElementById("event-location-tag")
      .classList.replace("d-block", "d-none");
    document
      .getElementById("event-description-tag")
      .classList.replace("d-block", "d-none");

    setIsEditButton(true);
  };

  const [tooltipContent, setTooltipContent] = useState("");
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const handleEventMouseEnter = (arg) => {
    const { event, jsEvent } = arg;

    // Set the tooltip content
    setTooltipContent(event.mousehover_title);

    // Calculate tooltip position
    const tooltipTop = jsEvent.clientY + 20; // Adjust this value for spacing
    const tooltipLeft = jsEvent.clientX + 20;

    setTooltipPosition({ top: tooltipTop, left: tooltipLeft });
  };
  const handleEventMouseLeave = (arg) => {
    const { event } = arg;
    console.log("out")
    // setTooltipEvent(null);
    setTooltipContent("");
  };

  const handleEventContent = (arg) => {
    console.log(arg)
    const classNames = arg.event.classNames || [];
    return (
      <div
      // Add any additional CSS classes for styling
      >
        {arg.event.title}
      </div>
    );
  };
  const [showDropdown, setShowDropdown] = useState(false);


  const handleEventDragStop = (arg) => {
    const { event, prevStart, prevEnd, start, end, resource } = arg;
    console.log(arg)
    // Update the event's start and end dates
    // You might want to format them as needed before sending
    const updatedEvent = {
      ...event,
      start: event.start.toISOString(),
      end: event.end.toISOString(),
      resource: resource.id, // Assuming resource ID is needed
    };

    // Send the updated event data to the server
    axios.post("api/updateScheduleEvent", updatedEvent)
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
  };
  document.title = "Job Schedule | Onexfort";

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Calendar" pageTitle="Apps" />
          <Card>
            <CardBody>
              <Row className="mt-3">
                <Col lg={6}>
                  <h5>Left Band - Payment Status</h5>
                  <span className="static-unpaid">Unpaid</span>
                  <span className="static-partial">Partially Paid</span>
                  <span className="static-paid">Paid</span>
                </Col>
                <Col lg={6}>
                  <h5>Right Band - Job/Leg Status</h5>
                  <span className="static-unpaid">New Awaiting Confirmation</span>
                  <span className="static-partial">Partially Paid</span>
                  <span className="static_complete">Confirmed</span>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col xs={12}>
                  <FullCalendar
                    schedulerLicenseKey="0324696433-fcs-1690265247"
                    plugins={[resourceTimelinePlugin, dayGridPlugin, interactionPlugin]}
                    initialView="resourceTimelineDay"
                    headerToolbar={{
                      left: 'prev,next today',
                      center: 'title',
                      right: 'resourceTimelineDay,resourceTimelineWeek,dayGridMonth',
                    }}
                    selectable={true}
                    resources={resources}
                    resourceGroupField='groupId'
                    eventContent={handleEventContent}
                    editable={true}
                    droppable={true}
                    aspectRatio='1.5'
                    events={events}
                    eventDrop={handleEventDrop}
                    //eventDragStop={handleEventDrop} // Event drag stop callback
                    eventResize={handleEventDrop} 
                    slotMinTime="06:00:00"
                    slotMaxTime="20:00:00"
                    eventMouseEnter={handleEventMouseEnter} // Event mouse enter callback
                    eventMouseLeave={handleEventMouseLeave}
                  />
                  {tooltipContent && (
                    <div
                      className="tooltipevent"
                      style={{ top: tooltipPosition.top, left: tooltipPosition.left }}
                    >
                      {tooltipContent}
                    </div>
                  )}
                  <div style={{ clear: "both" }}></div>

                  <Modal isOpen={modal} id="event-modal" centered>
                    <ModalHeader toggle={toggle} tag="h5" className="p-3 bg-soft-info modal-title">
                      {!!isEdit ? "Edit Event" : "Add Event"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        className={
                          !!isEdit
                            ? "needs-validation view-event"
                            : "needs-validation"
                        }
                        name="event-form"
                        id="form-event"
                        onSubmit={(e) => {
                          e.preventDefault();

                          return false;
                        }}
                      >
                        {!!isEdit ? (
                          <div className="text-end">
                            <Link
                              to="#"
                              className="btn btn-sm btn-soft-primary"
                              id="edit-event-btn"
                              onClick={(e) => {
                                e.preventDefault();
                                submitOtherEvent();
                                return false;
                              }}
                            >
                              Edit
                            </Link>
                          </div>
                        ) :
                          null
                        }
                        <div className="event-details">
                          <div className="d-flex mb-2">
                            <div className="flex-grow-1 d-flex align-items-center">
                              <div className="flex-shrink-0 me-3">
                                <i className="ri-calendar-event-line text-muted fs-16"></i>
                              </div>
                              <div className="flex-grow-1">
                                <h6
                                  className="d-block fw-semibold mb-0"
                                  id="event-start-date-tag"
                                >
                                  {event ? event.datetag : ""}
                                </h6>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center mb-2">
                            <div className="flex-shrink-0 me-3">
                              <i className="ri-map-pin-line text-muted fs-16"></i>
                            </div>
                            <div className="flex-grow-1">
                              <h6 className="d-block fw-semibold mb-0">
                                {" "}
                                <span id="event-location-tag">
                                  {event && event.location !== undefined ? event.location : "No Location"}
                                </span>
                              </h6>
                            </div>
                          </div>
                          <div className="d-flex mb-3">
                            <div className="flex-shrink-0 me-3">
                              <i className="ri-discuss-line text-muted fs-16"></i>
                            </div>
                            <div className="flex-grow-1">
                              <p
                                className="d-block text-muted mb-0"
                                id="event-description-tag"
                              >
                                {event && event.description !== undefined ? event.description : "No Description"}
                              </p>
                            </div>
                          </div>
                        </div>
                        <Row className="event-form">
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Type</Label>
                              <Input
                                className={
                                  !!isEdit
                                    ? "form-select d-none"
                                    : "form-select d-block"
                                }
                                name="category"
                                id="event-category"
                                type="select"

                              >
                                <option value="bg-soft-danger">Danger</option>
                                <option value="bg-soft-success">Success</option>
                                <option value="bg-soft-primary">Primary</option>
                                <option value="bg-soft-info">Info</option>
                                <option value="bg-soft-dark">Dark</option>
                                <option value="bg-soft-warning">Warning</option>
                              </Input>

                            </div>
                          </Col>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Event Name</Label>

                            </div>
                          </Col>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label>Event Date</Label>
                              <div
                                className={
                                  !!isEdit ? "input-group d-none" : "input-group"
                                }
                              >
                                <Flatpickr
                                  className="form-control"
                                  id="event-start-date"
                                  name="defaultDate"
                                  placeholder="Select Date"
                                  //value={validation.values.defaultDate || ""}
                                  options={{
                                    mode: "range",
                                    dateFormat: "Y-m-d",
                                  }}
                                  onChange={(date) => {
                                    setSelectedNewDay(date);
                                  }}
                                />
                                <span className="input-group-text">
                                  <i className="ri-calendar-event-line"></i>
                                </span>
                              </div>
                            </div>
                          </Col>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label htmlFor="event-location">Location</Label>
                              <div>
                                <Input
                                  type="text"
                                  className={
                                    !!isEdit
                                      ? "form-control d-none"
                                      : "form-control d-block"
                                  }
                                  name="location"
                                  id="event-location"
                                  placeholder="Event location"
                                // onChange={validation.handleChange}
                                //  onBlur={validation.handleBlur}
                                // value={validation.values.location || "No Location"}
                                // invalid={
                                //   validation.touched.location &&
                                //   validation.errors.location
                                //     ? true
                                //     : false
                                // }
                                />

                              </div>
                            </div>
                          </Col>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Description</Label>
                              <textarea
                                className={
                                  !!isEdit
                                    ? "form-control d-none"
                                    : "form-control d-block"
                                }
                                id="event-description"
                                name="description"
                                placeholder="Enter a description"
                                rows="3"
                              // onChange={validation.handleChange}
                              // onBlur={validation.handleBlur}
                              // value={validation.values.description || "No Description"}
                              // invalid={
                              //   validation.touched.description &&
                              //   validation.errors.description
                              //     ? true
                              //     : false
                              // }
                              ></textarea>

                            </div>
                          </Col>
                        </Row>
                        <div className="hstack gap-2 justify-content-end">
                          {!!isEdit && (
                            <button
                              type="button"
                              className="btn btn-soft-danger"
                              id="btn-delete-event"
                              onClick={() => setDeleteModal(true)}
                            >
                              <i className="ri-close-line align-bottom"></i> Delete
                            </button>
                          )}
                          {isEditButton && <button
                            type="submit"
                            className="btn btn-success"
                            id="btn-save-event"
                          >
                            {!!isEdit ? "Edit Event" : "Add Event"}
                          </button>}
                        </div>
                      </Form>
                    </ModalBody>
                  </Modal>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

Jobschedule.propTypes = {
  events: PropTypes.any,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default Jobschedule;
