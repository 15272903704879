import React, { useEffect, useState, useCallback, useMemo } from "react";
import Select from "react-select";
import { StorageData } from '../Customer/GridTablesData';
import {
  Card, CardBody, CardHeader, Col, Container, Row, Label, Input, Accordion, AccordionItem, Collapse, Form, FormFeedback, Modal, ModalHeader, ModalBody, ModalFooter, Nav,
  NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
// import Components
import BreadCrumb from '../../Components/Common/BreadCrumb';
import Flatpickr from "react-flatpickr";
import classnames from "classnames";
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import * as moment from "moment";
import Loader from "../../Components/Common/Loader";
import TableContainer from "../../Components/Common/TableContainer";
import { useSelector, useDispatch } from "react-redux";
import {
  getContacts as onGetContacts,
  addNewContact as onAddNewContact,
  updateContact as onUpdateContact,
  deleteContact as onDeleteContact,
} from "../../store/actions";
import { isEmpty } from "lodash";
import { Link } from "react-router-dom";
const Unitslist = () => {
  const isContactSuccess = true;
  const [col1, setcol1] = useState(false);
  const [selectedMulti, setselectedMulti] = useState(null);
  const [selectedMulti1, setselectedMulti1] = useState(null);
  const [data, setData] = useState([]);
  const [storage_types, setStoragetypes] = useState([]);
  const [allocation_status, setAllocationStatus] = useState([]);
  const [storage_unit, setStorageUnit] = useState([]);
  const [storage_type_id, setStoragetypeid] = useState("");
  const [allocation_status_name, setAllocationstatusname] = useState("");
  const [storage_unit_id, setStorageunitid] = useState("");
  const [value, setValue] = useState("");
  const [from_date, setFromDate] = useState(null);
  const [end_date, setEndDate] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [contact_person, setContactperson] = useState("");
  const [contact, setContact] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const handleDateChange = (selectedDates) => {
    setFromDate(selectedDates);
  };
  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".contactCheckBox:checked");
    ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };
  const handleContactClicks = () => {
    setContact("");
    setIsEdit(false);
    toggle();
  };
  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setContact(null);
    } else {
      setModal(true);
      setTag([]);
      setAssignTag([]);
    }
  }, [modal]);
  const crmcontacts = data;

  useEffect(() => {
    setContact(crmcontacts);
  }, [crmcontacts]);

  useEffect(() => {
    if (!isEmpty(crmcontacts)) {
      setContact(crmcontacts);
      setIsEdit(false);
    }
  }, [crmcontacts]);
  const handleContactClick = useCallback((arg) => {
    const contact = arg;

    setContact({
      _id: contact._id,
      // img: contact.img,
      name: contact.name,
      company: contact.company,
      email: contact.email,
      designation: contact.designation,
      phone: contact.phone,
      lead_score: contact.lead_score,
      last_contacted: contact.date,
      // time: contact.time,
      tags: contact.tags,
    });

    setIsEdit(true);
    toggle();
  }, [toggle]);

  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);
  const handleEndDateChange = (selectedDates) => {
    setEndDate(selectedDates);
  };
  const loadTable = () => {
    setisLoading(true);
    axios.get("/api/storage-data")
      .then((res) => {
        setData(res.data);
        console.log(res.data);
      })
      .catch((error) => {
        console.error(error);
      })
    axios.get("/api/units-list")
      .then((response) => {
        console.log(response);
        setStoragetypes(response.storage_types);
        setAllocationStatus(response.allocation_status);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setisLoading(false);
      })
  }

  function handleMulti(selectedOption) {
    setStoragetypeid(selectedOption);

    const selectedValues = selectedOption.map((item) => item.value);

    selectedValues.forEach((value) => {
      axios.post("/api/get-storage-units", { storage_type: [value] })
        .then((res) => {
          console.log(res.data);
          const storageUnitsList = res.data;
          if (storageUnitsList) {
            /* const options = storageUnitsList.map((unit) => ({
               value: unit.id,
               label: unit.name,
             }));*/
            setStorageUnit(storageUnitsList);
          } else {
            toast.warning('No Storage Data Found', { theme: "light" });
          }
        })
        .catch((error) => {
          // Handle any errors
        });
    });
  }
  function handleUnit(selectedUnitOption) {
    setStorageunitid(selectedUnitOption);
  }
  console.log(storage_unit_id);
  function handleStatus(selectedOption) {
    setAllocationstatusname(selectedOption);
  }

  const t_col1 = () => {
    setcol1(!col1);

  };

  useEffect(() => {
    loadTable();
  }, []);
  const handleSubmit = (event) => {
    event.preventDefault();
    let storageDate;
    if (from_date !== undefined && from_date !== "" && from_date !== "Invalid date") {
      storageDate = moment(from_date, 'DD MMM, YYYY').format('DD/MM/YYYY');
    } else {
      storageDate = null;
    }
    let formattedEndDate;
    if (end_date !== undefined && end_date !== "" && end_date !== "Invalid date") {
      formattedEndDate = moment(end_date, 'DD MMM, YYYY').format('DD/MM/YYYY');
    } else {
      formattedEndDate = null;
    }
    let result2
    if (storage_type_id !== undefined && storage_type_id !== "") {
      const values2 = storage_type_id.map(item => item.value);
      result2 = values2.join(',');
    } else {
      result2 = null;
    }
    let result;
    if (allocation_status_name !== undefined && allocation_status_name !== "") {
      const values = allocation_status_name.map(item => item.value);
      result = values.join(',');
    } else {
      result = null;
    }
    var formData = {
      "storage_unit": storage_unit_id.value,
      "storage_type": result2,
      "allocation_status": result,
      "from_date": storageDate,
      "to_date": formattedEndDate,
    }
    console.log(formData);
    if (formData.storage_unit === "" || formData.storage_unit === undefined || formData.storage_unit === null) {
      toast.error("Select Storage Unit", { theme: "light" });
    }
    else if (formData.allocation_status === "" || formData.allocation_status === undefined || formData.allocation_status === null) {
      toast.error("Select Allocation Status", { theme: "light" });
    }
    else {
      setisLoading(true);
      axios.post("api/storage-data", formData)
        .then(res => {
          console.log(res.data);
          setData(res.data);
          setisLoading(false);
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          setisLoading(false);
        })
    }
  }

  const resetTable = () => {
    setStoragetypeid("");
    setStorageunitid("");
    setAllocationstatusname("");
    setFromDate(null);
    setEndDate(null);
    loadTable();
  }
  const columns = useMemo(
    () => [
      {
        Header: <input type="checkbox" id="checkBoxAll" className="form-check-input" onClick={() => checkedAll()} />,
        Cell: (cellProps) => {
          return <input type="checkbox" className="contactCheckBox form-check-input" value={cellProps.row.original._id} onChange={() => deleteCheckbox()} />;
        },
        id: '#',
      },
      {
        Header: "Storage Job#",
        accessor: "storage",
        filterable: false,
        Cell: (cell) => {
          return <Link to={"/storage/view-storage-job/" + cell.value.id} className="fw-medium link-primary">{cell.value.name}</Link>;
        },
      },
      {
        Header: "Customer",
        accessor: "customer_name",
        filterable: false,
      },

      {
        Header: "Commercial Customer",
        accessor: "commercial_customer",
        filterable: false,
        Cell: ({ value }) => {
          return value ? value : "-";
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: false,
        Cell: ({ value }) => {
          return value ? value : "-";
        },
      },
      {
        Header: "Storage Units",
        accessor: "storage_units",
        filterable: false,
        Cell: ({ value }) => {
          return value ? value : "-";
        },
      },
      {
        Header: "Location",
        accessor: "location",
        filterable: false,
        Cell: ({ value }) => {
          return value ? value : "-";
        },
      },
      {
        Header: "Removals Job#",
        accessor: "job",
        filterable: false,
        Cell: (cell) => {
          return <Link to={"/listjobs/listjobsdetail/" + cell.value.id} className="fw-medium link-primary">{cell.value.name}</Link>;
        },
      },
      {
        Header: "Volume",
        accessor: "volume",
        filterable: false,
        Cell: ({ value }) => {
          return value ? value : "-";
        },
      },
      {
        Header: "Date In",
        accessor: "date_in",
        filterable: false,
        Cell: ({ value }) => {
          return value ? value : "-";
        },
      },
      {
        Header: "Date Out",
        accessor: "date_out",
        filterable: false,
        Cell: ({ value }) => {
          return value ? value : "-";
        },
      },
    ],
    [handleContactClick, checkedAll]
  );
   crmcontacts.sort((a, b) => a.storage_job_number - b.storage_job_number);
    document.title = "Storage Jobs | Onexfort";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Storage Jobs" pageTitle="Onexfort" />
                    <Accordion id="default-accordion-example">
                        <AccordionItem>
                            <h2 className="accordion-header" id="headingOne">
                                <button
                                    className={classnames("accordion-button fw-semibold", { collapsed: !col1 })} type="button" onClick={t_col1} style={{ cursor: "pointer" }} >
                                    Search Filters
                                </button>
                            </h2>
                            <Collapse isOpen={col1} className="accordion-collapse" id="collapseOne" >
                                <div className="accordion-body">
                                    <Row>
                                        <Col lg={4} md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="choices-multiple-default" className="form-label text-muted">Storage Type</Label>
                                                <Select
                                                    value={storage_type_id}
                                                    isMulti={true}
                                                    onChange={(selectedOption) => {
                                                        handleMulti(selectedOption);
                                                    }}
                                                    options={storage_types.map(item => ({ value: item.id, label: item.name }))}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={4} md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="choices-multiple-default" className="form-label text-muted">Storage Unit</Label>
                                                <Select value={storage_unit_id} onChange={(selectedUnitOption) => {
                                                        handleUnit(selectedUnitOption);
                                                    }}
                                                options={storage_unit.map(item => ({ value: item.id, label: item.name }))} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={4} md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="choices-multiple-default" className="form-label text-muted">Allocation Status</Label>
                                                <Select
                                                    value={allocation_status_name}
                                                    isMulti={true}
                                                    onChange={(selectedOption) => {
                                                        handleStatus(selectedOption);
                                                    }}
                                                    options={allocation_status.map(item => ({ value: item,label:item}))}
                                                />
                                            </div>
                                        </Col>

                    <Col lg={2}>
                      <div className="mb-3">
                        <Label for="createdDateinput" className="form-label">Allocation Start Date</Label>
                        <Flatpickr
                          className="form-control" placeholder='Start Date' value={from_date} onChange={(date) => setFromDate(moment(date[0]).format('DD MMM, YYYY'))}
                          options={{
                            dateFormat: "d M, Y",
                            enableTime: false
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label for="createdDateinput" className="form-label" >Allocation End Date</Label>
                        <Flatpickr
                          className="form-control" placeholder='End Date' value={end_date} onChange={(date) => setEndDate(moment(date[0]).format('DD/MM/YYYY'))}
                          options={{
                            dateFormat: "d M, Y",
                            enableTime: false
                          }}
                        />

                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="text-start">
                        <button type="submit" onClick={handleSubmit} className="btn form-btn-marg btn-primary">Apply</button>
                        <button type="submit" onClick={resetTable} className="btn form-btn-marg btn-primary">Reset</button>
                      </div>

                    </Col>
                  </Row>
                </div>
              </Collapse>
            </AccordionItem>
          </Accordion>
          <Card>
            <CardBody>
              <h5>Storage Jobs:</h5>
              <div id="table-gridjs">
                {/*<StorageData data={data}/>*/}
                {isContactSuccess && crmcontacts ? (
                  <TableContainer
                    columns={columns}
                    data={(crmcontacts || [])}
                    isGlobalFilter={true}
                    isAddUserList={false}
                    customPageSize={8}
                    className="custom-header-css"
                    divClass="table-responsive table-card mb-3"
                    tableClass="align-middle table-nowrap"
                    theadClass="table-light"
                    handleContactClick={handleContactClicks}
                    isContactsFilter={true}
                    SearchPlaceholder="Search for contact..."
                  />
                ) : (<Loader />)
                }
              </div>
              {/*{isLoading && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            background: 'rgba(255, 255, 255, 0.8)',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            zIndex: 9999,
                                        }}
                                    >
                                        <Loader />
                                    </div>
                                )}*/}
            </CardBody>
          </Card>
          <ToastContainer closeButton={false} limit={1} theme="light" />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Unitslist;
