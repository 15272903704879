import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import "../Customer/nameDetails.css"

import { Activity } from "./StorageTabPages/Activity";
import { Storage } from "./StorageTabPages/Storage";
import { Invoice } from "./StorageTabPages/Invoice";

import activeTab from "../Customer/activeTab.css"
import { useParams } from "react-router-dom";
import axios from 'axios';
import { Link } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import {
    Card,
    CardBody,
    Collapse,
    Button,
    Input,
    CardHeader,
    Nav,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    NavLink, NavItem,
    Col,
    Container, Row
} from "reactstrap"

import classnames from "classnames";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../Components/Common/DeleteModal";
import CustomStorageModal from "../../Components/Common/CustomStorageModal";
import moment from "moment";
import api from "../../config";
import { update } from "lodash";
// import "flatpickr/dist/themes/material_green.css";
//import { getJobDetails } from '../../store/actions';
const NameDetailsUnitList = () => {
    const flatpickrRef = useRef(null);
    const dispatch = useDispatch();
    const params = useParams();
    const storage_id = params.id;
    const job_id = params.job_id;

    const [addTag, setaddTag] = useState(true);
    const [data, setData] = useState([]);

    const [componentToShow, setComponentToShow] = useState(null);
    const [customActiveTab, setcustomActiveTab] = useState("Activity");
    const handleClick = (component) => {
        setComponentToShow(component);
        if (customActiveTab !== component) {
            setcustomActiveTab(component);
        }
    };
    const flatpickrtaskdate = useRef(null);
    const [tags, setTags] = useState([]);
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [companyname, setCompanyName] = useState("");
    const [storagevolume, setStorageVolume] = useState(0);
    const [storagestatus, setStorageStatus] = useState('');
    const [fromdate, setFromdate] = useState(null);
    const [todate, setTodate] = useState(null);
    const [expandedContactId, setExpandedContactId] = useState(null);
    const [contactname, setContactName] = useState('');
    const [contacttitle, setContactTitle] = useState('');
    const [contactdetail, setContactDetail] = useState([]);
    const [contacttype, setContactType] = useState([]);
    const [storageDetails, setStorageDetails] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [storageModal, setCustomStorageModal] = useState(false);
    const [org_date, setOrg_Date] = useState([]);


    const [newContact, setNewContact] = useState([]);

    useEffect(() => {
        getData();

    }, []);
    const getData = () => {
        axios.get(`${"api/view-storage-job/"}${storage_id}`)
            .then((response) => {
                console.log(response, "response1234");
                setOrg_Date(response.organisation_settings);

                response?.contact.map(contact => {
                    contact.contact_detail_data.push({ "detail_type": response.contact_types[0].list_option })
                })
                setStorageDetails(response);

                newContact.push({ "contacttype": response.contact_types[0].list_option })
            })

    }
    const renderComponent = () => {
        switch (componentToShow) {
            case 'Activity':
                return <Activity data={storageDetails} onChangeData={() => getData()} />;
            case 'Invoice':
                return <Invoice data={storageDetails} onChangeData={() => getData()} />;
            case 'Storage':
                return <Storage data={storageDetails} onChangeData={() => getData()} />;

            default:
                return <Activity data={storageDetails} onChangeData={() => getData()} />;
        }
    }

    const [showStorageDetails, setshowstorageDetails] = useState(false);
    const [showaddContact, setaddcontact] = useState(false);

    useEffect(() => {
        if (storageDetails != undefined && storageDetails != "") {
            setStorageVolume(storageDetails.storage.storage_volume);
            //  From date
            const from_date = storageDetails.f_date;
            const formattedfromDate = moment(from_date).format('DD/MM/YYYY');
            setFromdate(formattedfromDate);
            //  TO date  
            const to_date = storageDetails.t_date;
            const formattedtoDate = moment(to_date).format('DD/MM/YYYY'); // Format the date as 'd/m/Y'
            setTodate(formattedtoDate);
            
            const selectedOption = storageDetails?.allocation_status?.find(option => option === storageDetails?.storage?.allocation_status);
            setStorageStatus(selectedOption);

        }
    }, [storageDetails]);
    const clickThreeDots = () => {
        console.log(true)
    }
    const removeTag = (index) => {
        const updatedTags = [...tags];
        updatedTags.splice(index, 1);
        setTags(updatedTags);
    }
    const t_storageDetails = () => {
        setshowstorageDetails(!showStorageDetails);
    };
    const save_storageDetails = () => {
    //    const q_fromdate= moment(fromdate).format("DD/MM/YYYY");
    //    console.log(q,"qqqq");
        let allocation_status;
        if (storagestatus === "" || storagestatus === undefined || storagestatus === null) {
            toast.error("Select Storage Status", { theme: "light" });
            return;
        }
        var data = {
            "from_date": fromdate,
            "to_date": todate,
            "storage_id": storage_id,
            "storage_volume": storagevolume,
            "allocation_status": storagestatus,
        }
        console.log(data, "ddata");
        axios.post('/api/ajaxUpdateStorageJobDetail', data).then((res) => {
            setshowstorageDetails(!showStorageDetails);
            toast.success('Storage Details have been updated', { theme: "light" });
            getData();

        })
    }
    const [storagedeletedata, setStorageDeleteData] = useState('');
    const handleStorageDelete = () => {
        setCustomStorageModal(true);
    };
    const delete_storage = () => {
        var data = {
            "storage_id": storage_id,
        }
        axios.post("api/ajaxDestroSyStorage", data).then((res) => {
            //setshowstorageDetails(!showStorageDetails);
            toast.success('Storage Details have been Deleted', { theme: "light" });
            window.location.href = '/unitslist';
            //history.push("/unitslist");
            setCustomStorageModal(false);

        })
    }
    const t_addcontact = () => {

        setaddcontact(!showaddContact);
    };
    const Add_newContact = () => {
        console.log(newContact);
        let contactDetail = [];
        let contactType = [];
        newContact.map(item => {
            contactDetail.push(item.contactdetail);
            contactType.push(item.contacttype)
        })
        if (contactdetail != "") {
            contactDetail.push(contactdetail);
        }
        if (contacttype != "") {
            contactType.push(contacttype);
        }

        var data = {
            "name": contactname,
            "description": contacttitle,
            "contact_detail": contactDetail,
            "contact_detail_type": contactType,
            "lead_id": storageDetails?.storage?.customer_id,

        }
        if (contactname == "") {
            toast.error("Contact name must be entered", { theme: "light" });
            return false;
        } else if (contacttitle == "") {
            toast.error("Contact title must be entered", { theme: "light" });
            return false;
        }
        else if (data.contact_detail == "") {
            toast.error("Contact detail must be entered", { theme: "light" });
            return false;
        }
        axios.post('/api/ajaxStoreContact', data).then((res) => {
            handleContactEdit();
            t_addcontact();
            getData();
            toast.success(res.message, { theme: "light" });
            setContactName("");
            setContactTitle("");
            setNewContact([{ "contacttype": storageDetails.contact_types[0].list_option, "contactdetail": "" }])

        })
    }
    const Get_volume_from_removals = () => {
        var data = {
            "storage_id": storage_id,

        }
        axios.post('/api/getJobsCubicValue', data).then((res) => {
            setStorageVolume(res.total_cbm);
            toast.success(res.message, { theme: "light" });

        })
    }
    const handleContactEdit = (taskId) => {
        setExpandedContactId(taskId);
    };
    const [deletedata, setDeleteData] = useState('');
    const handleContactDelete = (data) => {
        setDeleteData(data);
        setDeleteModal(true);
    };
    const deletecontact = () => {
        var postData = {
            "lead_id": deletedata.lead_id,
            "contact_id": deletedata.id,
        }
        axios.post("/api/ajaxDestroyContact", postData).then((res) => {
            //setshowstorageDetails(!showStorageDetails);
            toast.success(res.message, { theme: "light" });
            setDeleteModal(false);
            getData();
        })
        //seteditTask(!showeditTask);
    };
    const Update_Contact = (data) => {
        var contact_detail = [];
        var contact_type = [];
        console.log(data);
        data?.contact_detail_data?.map((res) => {
            console.log(res);
            contact_detail.push(res.detail);
            contact_type.push(res.detail_type);
        })
        if (contactdetail != "") {
            contact_detail.push(contactdetail);
        }
        if (contacttype != "") {
            contact_type.push(contacttype);
        }
        var data = {
            "name": data.name,
            "description": data.description,
            "contact_detail": contact_detail,
            "contact_detail_type": contact_type,
            "lead_id": data.lead_id,
            "contact_id": data.id,
        }
        if (data.name == "") {
            toast.error("Contact name must be entered", { theme: "light" });
            return false;
        } else if (data.description == "") {
            toast.error("Contact title must be entered", { theme: "light" });
            return false;
        }
        console.log(data)

        axios.post('/api/ajaxUpdateContact', data).then((res) => {
            handleContactEdit(data.id);
            toast.success(res.message, { theme: "light" });
            getData();

        })
    }
    // const a= moment(new Date(storageDetails.storage.from_date)).format('YYYY-MM-DD');
    console.log(fromdate, "a123");

    return (
        <div className="page-content">
            <DeleteModal
                show={deleteModal}
                orderId={selectedOrderId}
                onDeleteClick={deletecontact}
                onCloseClick={() => setDeleteModal(false)}
            />
            <CustomStorageModal
                show={storageModal}
                orderId={selectedOrderId}
                onDeleteClick={delete_storage}
                onCloseClick={() => setCustomStorageModal(false)}
            />
            <Container fluid>
                <Row>
                    <Col xl={3}>
                        <Card>
                            <CardHeader>
                                <div className="d-flex">
                                    <h5 className="card-title flex-grow-1 mb-0">
                                        <i className="mdi mdi-truck-fast-outline align-middle me-1 text-muted"></i>
                                        JOB DETAILS
                                    </h5>

                                </div>
                            </CardHeader>
                            <CardBody>
                                <Collapse isOpen={!showStorageDetails} id="collapseWithicon">
                                    <div className="edit-icon">
                                        {/*<button className="show_update_job_detail_btn btn btn-icon" onClick={t_storageDetails}><i className="ri-pencil-line fs-22"></i></button>*/}
                                        <UncontrolledDropdown className="dropdown d-inline-block">
                                            <DropdownToggle className="btn fs-18" tag="button">
                                                <i className="ri-more-2-line"></i>
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu-end">
                                                <DropdownItem className='edit-item-btn' onClick={t_storageDetails}>
                                                    <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>Edit
                                                </DropdownItem>
                                                <DropdownItem className='remove-item-btn text-danger' onClick={() => { handleStorageDelete() }}>
                                                    <i className="ri-delete-bin-fill align-bottom me-2 text-danger"></i>Delete
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                    <div className="">
                                        <p className="text-muted mb-2">{(storageDetails && storageDetails.storage) ? storageDetails.f_date : ""} <b>To</b> {(storageDetails && storageDetails.storage) ? storageDetails.t_date : ""}</p>
                                        <p className="text-muted mb-2">{(storageDetails && storageDetails.storage) ? storageDetails.storage.storage_volume : ""} cbm</p>
                                        <Link to="#" className={((storageDetails && storageDetails.storage) ? (storageDetails?.storage?.allocation_status == "Completed'") : "") ? "badge badge-soft-success fs-11"
                                            : "badge badge-soft-warning fs-14"}>
                                            {(storageDetails && storageDetails?.storage) ? storageDetails?.storage?.allocation_status : ""}
                                        </Link>
                                    </div>
                                </Collapse>
                                <Collapse isOpen={showStorageDetails} id="collapseWithicon">
                                    <div className="mb-0 mt-1">
                                        Total Cubic Meters
                                        <Button style={{ marginLeft: "48px", marginBottom: "4px" }} size="sm" color="success" onClick={() => { Get_volume_from_removals() }}>Get Volume from Removals Job</Button>
                                        <div>
                                            <Input className="form-control" type="number" value={storagevolume} placeholder="0.00"
                                                onChange={(e) => {
                                                    setStorageVolume(e.target.value);
                                                }} />
                                        </div>
                                        Status
                                        <div>
                                            {<select className="form-select"
                                                value={storagestatus}
                                                onChange={(e) => {
                                                    setStorageStatus(e.target.value);
                                                }}>
                                                {storageDetails?.allocation_status?.map((res) => (
                                                    <option key={res} value={res}>
                                                        {res}
                                                    </option>
                                                ))}
                                            </select>}
                                        </div>
                                        Move In
                                        <Flatpickr
                                            className="form-control"
                                            placeholder='Start Date'
                                            value={fromdate}
                                            onChange={(dates) => {
                                                if (dates.length > 0) {
                                                    const formattedDate = moment(dates[0]).format('DD/MM/YYYY'); // Format the date as 'd/m/Y'
                                                    console.log(formattedDate, "formattedDate123");
                                                    setFromdate(formattedDate);
                                                } else {
                                                    setFromdate(null);
                                                }
                                            }}
                                            options={{
                                                dateFormat: "d/m/Y",
                                                enableTime: false
                                            }}
                                        />
                                        Move Out
                                        <Flatpickr
                                            className="form-control"
                                            placeholder='End Date'
                                            value={todate} // Make sure the value is already formatted as 'd/m/Y'
                                            onChange={(dates) => {
                                                if (dates.length > 0) {
                                                    const formattedDate = moment(dates[0]).format('DD/MM/YYYY'); // Format the date as 'd/m/Y'
                                                    setTodate(formattedDate);
                                                } else {
                                                    setTodate(null);
                                                }
                                            }}
                                            options={{
                                                dateFormat: "d/m/Y", // Set the desired display format
                                                enableTime: false
                                            }}
                                        />
                                        <div className="hstack gap-2 mt-2">
                                            <Button className="btn btn-light" onClick={t_storageDetails} >Cancel</Button>
                                            <Button className="btn btn-info" onClick={() => { save_storageDetails(); }}>Update</Button>
                                        </div>
                                    </div>
                                </Collapse>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader>
                                <div className="d-flex justify-content-between align-items-center">
                                    <h5 className="mb-0">
                                        <i className="ri-secure-payment-line align-bottom me-1 text-muted"></i>{" "}
                                        CONTACTS
                                    </h5>
                                    <button className="show_update_job_detail_btn btn btn-icon" onClick={t_addcontact}>
                                        <i className="ri-add-line fs-22"></i>
                                    </button>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Collapse isOpen={showaddContact} id="collapsepickup">
                                    <div className="collapse-section">
                                        Name
                                        <div className="pd-field">
                                            <Input className="form-control" type="text" value={contactname} placeholder="Contact Name"
                                                onChange={(e) => {
                                                    setContactName(e.target.value);
                                                }} />
                                        </div>
                                        Title
                                        <div className="pd-field">
                                            <Input className="form-control" type="text" value={contacttitle} placeholder="Contact Title"
                                                onChange={(e) => {
                                                    setContactTitle(e.target.value)
                                                }} />
                                        </div>
                                        Contact Detail
                                        {newContact.map(function (data, index) {
                                            return (
                                                <div className="input-group mgb-10" key={index}>
                                                    <Input type="text" className="form-control contact_detail" placeholder="Phone, email or URL" value={data.contactdetail}
                                                        onChange={(e) => {
                                                            const updatedStorageDetails = [...newContact];
                                                            // const taskIndex = updatedStorageDetails.findIndex((contact, id) => id == index);
                                                            updatedStorageDetails[index].contactdetail = e.target.value;
                                                            console.log(index)
                                                            if (newContact.length == index + 1) {
                                                                updatedStorageDetails.push({ "contacttype": storageDetails.contact_types[0].list_option, "contactdetail": "" })
                                                                console.log(newContact)

                                                            }
                                                            setNewContact(updatedStorageDetails);

                                                        }} />
                                                    <div className="input-group-append">
                                                        <select className="form-control form-control-uniform" value={data.contacttype} onChange={(e) => {
                                                            const updatedStorageDetails = [...newContact];
                                                            // const taskIndex = updatedStorageDetails.findIndex((contact, id) => id == index);
                                                            updatedStorageDetails[index].contacttype = e.target.value;
                                                            console.log(updatedStorageDetails)
                                                            setNewContact(updatedStorageDetails);

                                                        }}>
                                                            {storageDetails?.contact_types?.map((res) => (
                                                                <option key={res.list_option} value={res.list_option}>
                                                                    {res.list_option}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>)
                                        })}
                                        <div className="hstack gap-2 mt-2">
                                            <Button className="btn btn-light" onClick={t_addcontact} >Cancel</Button>
                                            <Button className="btn btn-info" onClick={() => { Add_newContact() }}>Save</Button>
                                        </div>
                                    </div>
                                </Collapse>

                                {storageDetails?.contact?.map((res) => (
                                    <><div key={res.id}>
                                        <div className="page-title d-flex align-items-center justify-content-between">
                                            <div className=" align-items-center mb-2 contact-sec-top">
                                                <div className="">
                                                    <h6 className=" mb-1">{res.name}</h6>
                                                </div>
                                                <div className="">
                                                    <p className="mb-0 text-muted">{res?.description?.substring(0, 25)}</p>
                                                </div>
                                            </div>
                                            <UncontrolledDropdown className="dropdown d-inline-block">
                                                <DropdownToggle className="btn fs-18" tag="button">
                                                    <i className="ri-more-2-line"></i>
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-end">
                                                    <DropdownItem className='edit-item-btn' onClick={() => { handleContactEdit(res.id); }}>
                                                        <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>Edit
                                                    </DropdownItem>
                                                    <DropdownItem className='remove-item-btn text-danger' onClick={() => { handleContactDelete(res); }}>
                                                        <i className="ri-delete-bin-fill align-bottom me-2 text-danger"></i>Delete
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                        {expandedContactId === res.id && (<div className="collapse-section">
                                            Name
                                            <div className="pd-field">
                                                <Input className="form-control" type="text" value={res.name} placeholder="Contact Name"
                                                    onChange={(e) => {
                                                        const updatedTasks = [...storageDetails.contact];
                                                        const taskIndex = updatedTasks.findIndex((contact) => contact.id === res.id);
                                                        updatedTasks[taskIndex].name = e.target.value;
                                                        setStorageDetails({ ...storageDetails, contact: updatedTasks });

                                                    }} />
                                            </div>
                                            Title
                                            <div className="pd-field">
                                                <Input className="form-control" type="text" value={res?.description} placeholder="Contact Title"
                                                    onChange={(e) => {
                                                        const updatedTasks = [...storageDetails.contact];
                                                        const taskIndex = updatedTasks.findIndex((contact) => contact.id === res.id);
                                                        updatedTasks[taskIndex].description = e.target.value;
                                                        setStorageDetails({ ...storageDetails, contact: updatedTasks });
                                                    }} />
                                            </div>
                                            Contact Detail
                                            {res?.contact_detail_data?.map(function (data, index) {
                                                return (
                                                    <div className="input-group mgb-10" key={index}>
                                                        <Input type="text" className="form-control contact_detail" placeholder="Phone, email or URL" value={data.detail}
                                                            onChange={(e) => {
                                                                const updatedContactDetail = res.contact_detail_data.map((item, id) => {
                                                                    if (id === index) {
                                                                        return { ...item, detail: e.target.value };
                                                                    }
                                                                    return item;
                                                                });
                                                                const updatedStorageDetails = { ...storageDetails };
                                                                const taskIndex = updatedStorageDetails.contact.findIndex((contact) => contact.id === res.id);
                                                                updatedStorageDetails.contact[taskIndex].contact_detail_data = updatedContactDetail;
                                                                setStorageDetails(updatedStorageDetails);
                                                                if (index == res.contact_detail_data.length - 1) {
                                                                    res.contact_detail_data.push({ "detail_type": storageDetails.contact_types[0].list_option, "detail": "" })
                                                                }

                                                            }} />
                                                        <div className="input-group-append">
                                                            <select className="form-control form-control-uniform" value={data.detail_type} onChange={(e) => {
                                                                const updatedContactDetail = res.contact_detail_data.map((item) => {
                                                                    if (item.id === data.id) {
                                                                        return { ...item, detail_type: e.target.value };
                                                                    }
                                                                    return item;
                                                                });
                                                                const updatedStorageDetails = { ...storageDetails };
                                                                const taskIndex = updatedStorageDetails.contact.findIndex((contact) => contact.id === res.id);
                                                                updatedStorageDetails.contact[taskIndex].contact_detail_data = updatedContactDetail;
                                                                setStorageDetails(updatedStorageDetails);
                                                            }}>
                                                                {storageDetails.contact_types?.map((res) => (
                                                                    <option key={res.list_option} value={res.list_option}>
                                                                        {res.list_option}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>);
                                            })}
                                            {/* <div className="input-group mgb-10">
                                                <Input type="text" className="form-control contact_detail" placeholder="Phone, email or URL" value={res.detail}
                                                    onChange={(e) => {
                                                        setContactDetail(e.target.value);
                                                    }} />
                                                <div className="input-group-append">
                                                    <select className="form-control form-control-uniform" value={contacttype} onChange={(e) => {
                                                        setContactType(e.target.value);
                                                    }}>
                                                        {storageDetails.contact_types?.map((res) => (
                                                            <option key={res.list_option} value={res.list_option}>
                                                                {res.list_option}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div> */}
                                            <div className="hstack gap-2 mt-2">
                                                <Button className="btn btn-light" onClick={() => { handleContactEdit() }}>Cancel</Button>
                                                <Button className="btn btn-info" onClick={() => { Update_Contact(res); }}>Update</Button>
                                            </div>
                                        </div>
                                        )}
                                        <div className="align-items-center mb-2 contact-sec-bottom">
                                            <div className="">
                                                <h6 className=" mb-1">Name</h6>
                                                <p className="mb-0 text-muted">{res.name}</p>
                                            </div>
                                            <div className="">
                                                <h6 className=" mb-1">Description</h6>
                                                <p className="mb-0 text-muted">{res?.description?.substring(0, 25)}</p>
                                            </div>
                                            {res?.contact_detail_data?.map(function (data) {
                                                return (
                                                    <div className="flex-shrink-0" key={data.id}>
                                                        <h6 className="mb-1">{data.detail_type}</h6>
                                                        <p className="mb-0 text-muted ">{data.detail}</p>
                                                    </div>);
                                            })}
                                        </div>
                                    </div><div>
                                        </div></>
                                ))}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl={9}>
                        <Card>
                            <CardHeader>
                                <div className="row align-items-center">
                                    <div className="col">
                                        <Nav>
                                            <NavItem className="flex-grow-1">
                                                <NavLink
                                                    href="#"
                                                    onClick={() => { handleClick("Activity") }}

                                                >
                                                    <h5 className={classnames({
                                                        active: customActiveTab === "Activity",
                                                    })} >Activity</h5>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="flex-grow-1">
                                                <NavLink
                                                    href="#"
                                                    onClick={() => { handleClick("Storage") }}
                                                >
                                                    <h5 className={classnames({
                                                        active: customActiveTab === "Storage",
                                                    })}>Storage</h5>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="flex-grow-1">
                                                <NavLink
                                                    href="#"
                                                    onClick={() => { handleClick("Invoice") }}
                                                >
                                                    <h5 className={classnames({
                                                        active: customActiveTab === "Invoice",
                                                    })}>Invoice</h5>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </div>
                                </div>
                            </CardHeader>
                        </Card>
                        {renderComponent()}

                    </Col>

                </Row>
                <ToastContainer closeButton={false} limit={1} theme="light" />
            </Container>
        </div>
    )

}

export default NameDetailsUnitList