import React, { useState,useRef, useCallback, useEffect, useMemo } from 'react';
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane, Label, UncontrolledTooltip, Button, CardHeader, Input } from "reactstrap";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { get_cookie } from '../../../helpers/get_cookie';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../../Components/Common/Loader";

export const Invoices = () => {
  document.title = "Invoice Settings | Onexfort";
  const [isLoading, setisLoading] = useState(false);

  const [invoice,setInvoice] =useState('');
  const [emailtemplate,setEmailTemplate]= useState('');
  const [allemailtemplate,setAllEmailTemplate]= useState('');
  const [allproduct,setAllProduct] =useState('')
  const [oppstatus,setOppStatus]= useState('')
  const [alloppstatus,setAllOppStatus]= useState('')
  const [product,setProduct] =useState('')
  const [ccprocessing, setCreditprocessing] = useState('');
  // const [editorData, setEditorData] = useState('');
  const [qfee,setQfee] = useState('')
  //form values
  const [prefix,setPrefix] =useState('')
  const [due,setDue]=useState('')
  const [allowqfee,setAllowQfee]= useState('')
  const [feepercent,setfeePercent]=useState('')
  const [stripepre,setStripePre] =useState('')
  const [stripe,setStripe] =useState('')
  const [invoiceterms,setInvoiceTerms] =useState('')
  const [storageemailtemplate,setStorageEmailTemplate] =useState()

  // const handleEditorChange = (event, editor) => {
  //   const data = editor.getData();
  //   setEditorData(data);
  // };

  
  useEffect(() => {
    
    const user = JSON.parse(get_cookie("authUser"));
    var tenant_id = user.tenant_id;
    var datavalue={
      "tenant_id": tenant_id
    }
    axios.post("/api/invoice-settings", datavalue)
      .then((res) => {
        console.log(res)
        setPrefix(res.invoiceSetting.invoice_prefix)
        setDue(res.invoiceSetting.due_after)
        setAllowQfee(res.invoiceSetting.allow_instalments_quickfee)
        setfeePercent(res.invoiceSetting.cc_processing_fee_percent);
        console.log(res.invoiceSetting);
        setInvoice(res.invoiceSetting);
        setEmailTemplate(res.invoiceSetting.email_template_mobile_app)
        setStorageEmailTemplate(res.invoiceSetting.email_template_storage_invoice)
        setProduct(res.invoiceSetting.cc_processing_product_id)
        setAllEmailTemplate(res.email_templates)
        setAllOppStatus(res.opp_statuses);
        setAllProduct(res.products);
        // setQfee(qfee)
        setInvoiceTerms(res.invoiceSetting.invoice_terms)
        
        setCreditprocessing(res.invoiceSetting.cc_processing_product_id)
        setStripe(res.invoiceSetting.stripe_pre_authorise)
        setStripePre(res.invoiceSetting.stripe_pre_authorised_op_status)
      });

  }, []);

  console.log(feepercent)
  // console.log(allproduct)
  // const selectedWeekOption = product.find(product => product.id === invoice.cc_processing_product_id);
  // console.log("selectedWeekOption",selectedWeekOption.name)
  const updatedata = () => {
    setisLoading(true)

    var a = emailtemplate;
    var b=oppstatus;
    var c = prefix;
    var f= product;
    console.log(c,a+"email",b,f+"product",invoiceterms,stripepre);
    const updatedInvoice = {
      invoice_prefix : prefix,
      due_after: due,
      email_template: emailtemplate,
      invoice_terms: invoiceterms,
      cc_processing_fee_percent: feepercent,
      cc_processing_product_id: product,
      stripe_pre_authorised_op_status: oppstatus,
      stripe_pre_authorise: stripepre,
      quickfee_allow_installment: qfee,
      storage_template : storageemailtemplate

    };
    
    console.log(updatedInvoice);
    // axios.post('/api/products/delete',id)
    axios.post(`/api/invoice-settings/update`,updatedInvoice)
      .then((response) => {
        console.log('Updated successful');
        setisLoading(false)

        toast.success(response.message,{theme:"light"});
        // Perform additional actions after successful deletion
      })
      .catch((error) => {
        console.error('Delete error:', error);
        // Handle delete error
      });
  
}
// const handleChange=(e)=>{
// setDue(e.target.value)
// }
const handleReset=()=>{
  setPrefix(invoice.invoice_prefix)
  setDue(invoice.due_after)
  setfeePercent(invoice.cc_processing_fee_percent)
  setInvoiceTerms(invoice.invoice_terms)
  setQfee(invoice.qfee)
  setStripe(invoice.stripe_pre_authorise)
  setStripePre(invoice.stripe_pre_authorised_op_status)
  setProduct(invoice.cc_processing_product_id)
  setEmailTemplate(invoice.email_template_mobile_app)
  setOppStatus(invoice.stripe_pre_authorised_op_status)
  
  const abb=invoice.email_template_mobile_app
  console.log("aaa",abb)
  // setProduct()

}
const handleCheckboxChange = (e) => {
  const isChecked = e.target.checked;
  const value = isChecked ? 'Y' : 'N';

  setAllowQfee(value);
};
const handleCheckboxChangeStripe = (e) => {
  const isChecked = e.target.checked;
  const value = isChecked ? 'Y' : 'N';

    setStripe(value);
};
console.log(allowqfee)
  return (


    <Container fluid>
      <Card>
      {isLoading && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            background: 'rgba(255, 255, 255, 0.8)',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            zIndex: 9999,
                                        }}
                                    >
                                        <Loader />
                                    </div>
                                )}
      <ToastContainer closeButton={false} limit={1} theme="light"/>
        <CardHeader><h5 className='m-0'>Update Invoice Settings </h5></CardHeader>
        <CardBody>
          <Row>
            <Col lg={12} className='mb-3'>
              <Label>Invoice Prefix</Label>
              <Input value={prefix} onChange={(e) => setPrefix(e.target.value)}></Input>
            </Col>
            <Col lg={6}>
              <Label> Due After </Label>
              {/* <Input type="number" placeholder='Days' value={due} onChange={handleChange}> */}
              <Input type="number" placeholder='Days' value={due} onChange={(e) => setDue(e.target.value)}>

              </Input>
            </Col>
            <Col lg={6}>
              <Label>Mobile App- Send Email to Customer - Email Template </Label>
              <select className="form-select mb-3" value={emailtemplate} onChange={(e) => setEmailTemplate(e.target.value)}>
              <option value="0">Choose a template</option>
             
              {/* {console.log(allemailtemplate)} */}
  {allemailtemplate && allemailtemplate.length > 0 ? (
    allemailtemplate.map((template, index) => (
      <option key={index} value={template.id}>{template.email_template_name}</option>
    ))
  ) : (
    <option value="">No email templates found</option>
  )}
</select>
            </Col>
           {qfee == 1 && (
              // should be ===1
            
            <Col lg={6} className='mb-3'>
              <Label className="form-check-label" for="customSwitchsizelg">Quickfee Allow Installments</Label>
              <div className="form-check form-switch form-switch-lg" dir="ltr">
                {/* <Input type="checkbox" className="form-check-input" id="customSwitchsizelg" defaultChecked="" /> */}
                              <input
                type="checkbox"
                id="customSwitchsizelg"
                name="qfee_allow"
                className="form-check-input"
                value="Y"
                checked={allowqfee === 'Y'}
              
                data-color="#f96262"
                onChange={handleCheckboxChange}
                // onChange={(e) => setInvoice({ ...invoice, allowqfee: e.target.checked ? 'Y' : 'N' })}
              />

              </div>
            </Col>
           )}
            <Col lg={6} className='mb-3'>
              <Label className="form-check-label" for="customSwitchsizelg">Storage Invoice - Email Template</Label>
                <select className="form-select mb-3" value={storageemailtemplate} onChange={(e) => setStorageEmailTemplate(e.target.value)}>
                  <option value="0">Choose a template</option>
                  {allemailtemplate && allemailtemplate.length > 0 ? (
                    allemailtemplate.map((template, index) => (
                      <option key={index} value={template.id}>{template.email_template_name}</option>
                    ))
                  ) : (
                    <option value="">No email templates found</option>
                  )}
                </select>
            </Col>
            <Col lg={12} className='mb-3'>
              <Label>Invoice Terms</Label>
              <CKEditor
      editor={ClassicEditor}
      data={invoiceterms}
      onReady={(editor) => {
      }}
      onChange={(event, editor) => {
        const data = editor.getData();
        setInvoiceTerms(data);
      }}
    />
            </Col>
            <Col lg={6}>
              <Label>Credit Card Processing Free Percent</Label>
              <Input
  value={feepercent || ''}
  onChange={(e) => setfeePercent(e.target.value)}
/>

            </Col>
            <Col lg={6}>
              <Label>Credit Card Processing Product Item </Label>
              
              <select className="form-select mb-3" value={product} onChange={(e) => setProduct(e.target.value)}>

                    {allproduct && allproduct.length > 0 ? (
                      
                      allproduct.map((item,index) => (
                        <option key={index} value={item.id}>{item.name}</option>
                      ))
                    ) : (
                      <option value="">No products found</option>
                    )}
                  </select>


     
            </Col>
            <Col lg={6} className='mb-3'>
              <Label className="form-check-label" for="customSwitchsizelg">Stripe Pre Authorise Booking Payment</Label>
              <div className="form-check form-switch form-switch-lg" dir="ltr">
                {/* <Input type="checkbox" className="form-check-input" id="customSwitchsizelg" defaultChecked="" /> */}
                <input
  type="checkbox"
  id="customSwitchsizelg"
  name="stripe_pre_authorise"
  className="form-check-input"
  value="Y"
  checked={stripe === 'Y'}
 
  data-color="#f96262"
  onChange={handleCheckboxChangeStripe}
  // onChange={(e) => setInvoice({ ...invoice, stripe_pre_authorise: e.target.checked ? 'Y' : 'N' })}
/>

              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Label>Strip Pre Authorised Opportunity Status </Label>

        <select className="form-select mb-3" value={oppstatus} onChange={(e) => setOppStatus(e.target.value)}>
  <option value="" selected={stripepre}>{stripepre}</option>
  {alloppstatus && alloppstatus.length > 0 ? (
    alloppstatus.map((item) => (
      <option key={item.id} value={item.pipeline_status} selected={item.pipeline_status === stripepre}>
        {item.pipeline_status}
      </option>
    ))
  ) : (
    <option value="">No status found</option>
  )}
</select>


            </Col>
          </Row>
        </CardBody>
        <hr></hr>
        <div className='hstack mb-3 gap-2 flex-wrap justify-content-center'>
        <Button className='btn btn-success' onClick={() => updatedata()}>Update</Button>
        <Button className='btn btn-danger' onClick={handleReset}>Reset</Button>
        </div>
    
      </Card>
    </Container>

  )
}