import { Button, Card, CardBody, CardFooter, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledTooltip } from "reactstrap";
import Dragula from 'react-dragula';
import classnames from "classnames";
import { Link } from 'react-router-dom';
import React, { useEffect, useState, useCallback, useMemo } from "react";
import axios from 'axios';

import { isEmpty } from "lodash";
import {
  CardHeader,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  ModalFooter,
  Table,
  FormFeedback

} from "reactstrap";
import DeleteModal from "../../../Components/Common/DeleteModal"

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
//redux
// import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../../Components/Common/TableContainer";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import Loader from "../../../Components/Common/Loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Sequences = () => {
  document.title="Email And SMS Sequences | Onexfort";
  // Export Modal
  const [data,setData] =useState('')
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [contact, setContact] = useState([]);
///////////////////////////////////
  const isContactSuccess = true;
  const error=null;
const crmcontacts=data;
const [seqname,setSeqName] = useState('')
const [desc,setDesc] = useState('')
const [statusdate,setStatusDate] =useState('')
const [jobDate,setJobDate] =useState('')
const [fromName,setFromName] =useState('')
const [fromEmail,setFromEmail] =useState('')
const [fromnum,setFromNum] =useState('')
const [company,setCompany] =useState('');
const [companyid,setCompanyId] =useState('');
const [emailTemplateId,setEmailTemplateId] =useState('');
const [smsTemplateId,setSmsTemplateId] =useState('');
const [jobtype,setJobType]= useState('')
const [jobstatus,setJobStatus]= useState('');
const [emailtemp,setEmailTemplate] =useState('');
const [smstemp,setSmsTemp]= useState('')
const [intialStatus, setIntialStatus] = useState('');
const [postStatus, setPostStatus] = useState('');
const [pipelineStatus, setPipelineStatus] = useState('');
const [pipeline2Status, setPipeline2Status] = useState('');
const [sysJobType,setSysJobType] = useState('')
const [id,setId] = useState('')
const [pipelinestatuses,setPipelineStatuses]= useState('');
const [intialPipelineStatus, setIntialPipelineStatus] = useState('');
const [postPipelineStatus, setPostPipelineStatus] = useState('');
  useEffect(() => {
    loaddata();
  }, []);

  const loaddata = () => {

    axios
        .get("/api/email-sequences/data")
        .then((res) => {
            console.log(res.data);
            setData(res.data);
        })
        .catch((err) => {
            console.log(err);
        });
        axios
        .get("/api/email-sequences/create")
        .then((res) => {
            console.log(res,"create");
            setCompany(res.companies);
            setCompanyId(res.companies[0].id)
            setJobType(res.sys_job_type);
            setSysJobType(res.sys_job_type[0].options)
            setJobStatus(res.job_status);
            setIntialStatus(res.job_status[0].options)
            setPostStatus(res.job_status[0].options)
            setPostPipelineStatus(res.pipeline_statuses[0].pipeline_status)
            setIntialPipelineStatus(res.pipeline_statuses[0].pipeline_status)
            setPipelineStatus(res.pipeline_statuses[0].pipeline_status)
            setPipeline2Status(res.pipeline_statuses[0].pipeline_status)
            setEmailTemplate(res.email_templates);
            setEmailTemplateId(res.email_templates[0].id)
            setSmsTemp(res.sms_templates)
            setSmsTemplateId(res.sms_templates[0].id)

        })
        .catch((err) => {
            console.log(err);
        });

    };


  const [isEdit, setIsEdit] = useState(false);
  //delete Conatct
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const [modal, setModal] = useState(false);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setContact(null);
    } else {
      setModal(true);
      setTag([]);
      setAssignTag([]);
    }
  }, [modal]);

  const [modal_sequence, setmodal_sequence] = useState(false);

  function tog_sequenceModal() {
    setmodal_sequence(!modal_sequence);
  }

  // Add Data
  // const handleContactClicks = () => {
  //   setContact("");
  //   setIsEdit(false);
  //   toggle();
  // };



  const handleFormSubmit = (e) => {
    e.preventDefault();
    const selectElement = document.getElementById('selectOption');
    const selectedValue = selectElement.value;

  var formData = {
  "sequence_name" :seqname,
  "sequence_description" :desc,
  "from_email":fromEmail,
  "from_email_name":fromName,
  "company_id":companyid,
  "email_template_id":emailTemplateId,
  "sms_template_id":smsTemplateId,
  "from_sms_number_name":fromnum,
  "sequence_type" :selectedValue,
  "initial_status":intialStatus,
  "post_status" :postStatus,
  "sys_job_type" :sysJobType,
  "active":active,
  "is_opportunity":isOpp,
  "send_email":sendEmail,
  "send_sms" : sendSms,
  "check_frequency":selectedFrequency,
  "hours_after_initial_status": selectedValue1,
  "days_after_initial_status" :statusdate,
  "days_before_after_job_date" : jobDate,
  "pipeline_status": intialPipelineStatus,
  "pipeline_status2" :postPipelineStatus
  }
  console.log(formData,"id");

  if(seqname==""){
    toast.error('Enter sequence Name',{theme:"light"});
  }
 else if(fromEmail==""){
    toast.error('Enter Email ID',{theme:"light"});
  }
  else if(fromName==""){
    toast.error('Enter Email Name',{theme:"light"});
  }
  else if(intialStatus==postStatus){
    toast.error('Intial Status and Post Status cannot be equal',{theme:"light"});
  }
  else{

  
  if (isEdit) {
  axios.post(`${"api/email-sequences/update/"}${id}`, formData)
  
  .then((response) => {
    // Handle success
    // tog_smsModal();
    tog_sequenceModal();
    setModal(false);
    console.log('Save successful', response);
    toast.success("Saved Successfully",{theme:"light"});
    loaddata();
  })
  .catch((error) => {
    // Handle error
    console.error('Error saving data:', error);
  });
  
  } else {

  axios.post('/api/email-sequences/store', formData)
  
  .then((response) => {
    // Handle success
    // tog_smsModal();
    tog_sequenceModal();
    setModal(false);
    console.log('Save successful', response.data);
    toast.success("Saved Successfully",{theme:"light"});
    loaddata();
  })
  .catch((error) => {
    // Handle error
    console.error('Error saving data:', error);
  });
  }
}
  }

  const handleIntialStatus = (event) => {
    setIntialStatus(event.target.value);
  };
  const handlePostStatus  = (event) => {
    setPostStatus(event.target.value);
  };
  const handlePipelineStatus = (event) => {
    setIntialPipelineStatus(event.target.value);
  };
  const handlePipeline2Status  = (event) => {
    setPostPipelineStatus(event.target.value);
  };
  // Date & Time Format



  // Update Data


  const [order, setOrder] = useState([]);
////////////////////////////for is opportunity
 const [isCheckboxChecked,setIsCheckboxChecked]=useState('N');

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);
  const handleOrderClick = (id) => {
    if (id !== '') {
      console.log("edit" + id);
     

      axios.post(`/api/email-sequences/edit/${id}`)
          .then((response) => {
              console.log(response);
              setId(response.sequence.id)
              setSeqName(response.sequence.sequence_name);
              setDesc(response.sequence.sequence_description)
              setCompanyId(response.sequence.company_id)
              setStatusDate(response.sequence.days_after_initial_status);
              setJobDate(response.sequence.days_before_after_job_date);
              setEmailTemplateId(response.sequence.email_template_id)
              setFromEmail(response.sequence.from_email);
              setFromName(response.sequence.from_email_name)
              setFromNum(response.sequence.from_sms_number_name)
              setSelectedValue1(response.sequence.hours_after_initial_status)
              setIsOpp(response.sequence.is_opportunity)
              setSendEmail(response.sequence.send_email)
              setSendSms(response.sequence.send_sms)
              setSelectedValue(response.sequencesequence_type)
              setSmsTemplateId(response.sequence.sms_template_id)
              setSysJobType(response.sequence.sys_job_type)
              setPipelineStatuses(response.pipeline_statuses)
              if(response.sequence.is_opportunity==="Y"){
                setIsCheckboxChecked(true);
                setPostPipelineStatus(response.sequence.post_status);
                setIntialPipelineStatus(response.sequence.initial_status)
              }
              else{
                setIsCheckboxChecked(false);
              setIntialStatus(response.sequence.initial_status)
              setPostStatus(response.sequence.post_status)
              }
          })
          .catch((error) => {
              console.error('Delete error:', error);
              // Handle delete error
          });
    setIsEdit(true);
    tog_sequenceModal();
  }
  };
  const [seqId,setSeqId] = useState('')
  const deletePopUp=(id)=>{
    setSeqId(id);
    setDeleteModal(true);
  
  }
  const handleDelete =async () => {
    if (seqId !== '') {
      console.log("delete" + seqId);
     

      axios.post(`/api/email-sequences/delete/${seqId}`)
          .then((response) => {
              console.log(response);
              loaddata();
              toast.success(response.message,{theme:"light"});

          })
          .catch((error) => {
              console.error('Delete error:', error);
              // Handle delete error
          });
          setDeleteModal(false);
    // setIsEdit(false);
    // tog_sequenceModal();
  }
  };
  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      // dispatch(onDeleteContact(element.value));
      setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".contactCheckBox:checked");
    ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  //email and sms temp
  const emailsmscolumn = useMemo(
    () => [
      {
        Header: <input type="checkbox" id="checkBoxAll" className="form-check-input" onClick={() => checkedAll()} />,
        Cell: (cellProps) => {
          return <input type="checkbox" className="contactCheckBox form-check-input" value={cellProps.row.original._id} onChange={() => deleteCheckbox()} />;
        },
        id: '#',
      },
      {
        Header: "Email & SMS Sequence Name",
        accessor: "sequence_name",
        filterable: false,

      },
      {
        Header: "Description",
        accessor: "sequence_description",
        filterable: false,
      },

      {
        Header: "Status",
        accessor: "active",
        filterable: false,
      },

      {
        Header: "Action",
        Cell: (cellProps) => {
          const { id } = cellProps.row.original;
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item">
                <button className="btn btn-sm btn-soft-info edit-list" onClick={() => {
                  handleOrderClick(id);
                }}><i className="bx bxs-pencil fs-12 pt-1"></i>
                </button>
              </li>
              <li className="list-inline-item edit">
                <Link
                  to="#"
                  className=" btn btn-sm btn-soft-danger fs-13 pt-1"
                  onClick={() => {
                    deletePopUp(id);
                  }}>
                
                  <i className="bx bxs-trash fs-12"></i> 
                </Link>
              </li>

            </ul>
          );
        },
      },
    ],
    []
  );

  const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);
  const [active, setActive] = useState("N");
  const [activeValue, setActivetValue] = useState('N');
  const [isOpp,setIsOpp] = useState("N");
  const [isOppValue, setIsOppValue] = useState('N');
  const [sendEmail,setSendEmail] = useState('N');
  const [sendEmailValue, setSendEmailValue] = useState('N');
  const [sendSms,setSendSms] = useState("N");
  const [sendSmsValue, setSendSmsValue] = useState('N');
  useEffect(() => {	
    const value = active ? 'Y' : 'N';	
    setActivetValue(value);	
    const value1 = isOpp ? 'Y' : 'N';	
    setIsOppValue(value1);
    const value2 = sendEmail ? 'Y' : 'N';	
    setSendEmailValue(value2);
    const value3 = sendSms ? 'Y' : 'N';	
    setSendSmsValue(value3);
  }, [active,isOpp,sendEmail,sendSms]);
//////////////////////
  const handleActive = (event) => {
    const isCheck = event.target.checked;
    const value = isCheck ? 'Y' : 'N';	
    setActive(value);	
};
const handleIsOpp = (event) => {
  const isCheck = event.target.checked;
  const value = isCheck ? 'Y' : 'N';
  setIsCheckboxChecked(event.target.checked);	
  setIsOpp(value);	
};
const handleEmail = (event) => {
  const isCheck = event.target.checked;
  const value = isCheck ? 'Y' : 'N';	
  setSendEmail(value);	
};
const handleSms = (event) => {
  const isCheck = event.target.checked;
  const value = isCheck ? 'Y' : 'N';	
  setSendSms(value);	
};
const [selectedFrequency, setSelectedFrequency] = useState(240);
const [selectedValue, setSelectedValue] = useState('');

useEffect(() => {
  // Access the selectedFrequency value here and perform actions
  console.log(selectedFrequency,"fre");
}, [selectedFrequency]);
const [selectedValue1, setSelectedValue1] = useState('0.00');
const handleChange = (event) => {
  setSelectedValue1(event.target.value);
};
// const validateEmail = (email) => {
//   // Regular expression for email validation
//   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//   return emailRegex.test(email);
// };
  return (
    <div>
    
      <Card>
      <DeleteModal
  show={deleteModal}
  onDeleteClick={()=>handleDelete()}
  onCloseClick={() => setDeleteModal(false)}
/>
        <CardHeader>
          <div className="d-flex align-items-center flex-wrap gap-2">
          <div className="flex-grow-1">
             </div>
             <button
                 className="btn btn-brown add-btn"
                 onClick={() =>{setIsEdit(false); tog_sequenceModal()}}
               >
                 <i className="ri-add-fill me-1 align-bottom"></i> Add
                 Sequence
               </button>
             <div className="flex-shrink-0">
               <div className="hstack text-nowrap gap-2">
                 {isMultiDeleteButton && <button className="btn btn-danger"
                   onClick={() => setDeleteModalMulti(true)}
                 ><i className="ri-delete-bin-2-line"></i></button>}

                {/* <button className="btn btn-soft-success" onClick={() => setIsExportCSV(true)}>Export To Excel</button> */}
{/* <input type="hideen" value={id}/> */}
              </div>
            </div>
          </div>
        </CardHeader>
      </Card>
      <Modal
        size="xl"
        isOpen={modal_sequence}
        toggle={() => {
          tog_sequenceModal();
          setSeqName(" ")
          setDesc("")
          setFromEmail("");
          setFromName("")
          setFromNum("");
        }}
      >

        <Card>
          <ModalHeader className="bg-soft-primary p-3">
            <h5 className="mb-0 text-black"> {!!isEdit ? " Update Email & SMS Sequence" : "Add Email & SMS Sequence"}</h5>
          </ModalHeader>
          <CardBody>
            <Row className="mb-3">
              <Col md={12}>
                <Label>Email & SMS Sequence Name</Label>
                <Input type="text"  value={seqname}
                        onChange={(e) => setSeqName(e.target.value)}></Input>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={12}>
                <Label htmlFor="VertimeassageInput" className="form-label">Description</Label>
                <textarea className="form-control" id="VertimeassageInput" rows="3" placeholder="Enter your message"  value={desc}
                        onChange={(e) => setDesc(e.target.value)}>
                </textarea>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={12}>
                <Label >Company Name</Label>
                <select
                      value={companyid}
                      onChange={(e) => setCompanyId(e.target.value)}
                      className="form-select mb-3"
                      >
                        
                      {company && company.length > 0 ? (
                          company.map((role, index) => (
                              <option key={index} value={role.id}>{role.company_name
                              }</option>
                          ))
                      ) : (
                          <option value="">No Company found</option>
                      )}
                    </select>
              </Col>
            </Row>

            <select
      className="select2 form-control"
      data-placeholder="Choose Frequency"
      name="check_frequency"
      hidden={true}
      value={selectedFrequency}

    >
      <option value="240">4 hours</option>
    </select>


            <Row className="mb-3">
      <Col md={6}>
        <Label>Is Opportunity?</Label>
        <div className="form-check form-switch form-switch-md">
        <Input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" 
                       onChange={handleIsOpp}  checked={isOpp === "Y"} />   
          {/* <Input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckDefault"
            onChange={handleIsOpp}  checked={isOpp === "Y"} />     */}
                        {/* onChange={handleCheckboxChange} */}
 
          
        </div>
      </Col>
      {!isCheckboxChecked && (
        <Col md={6}>
          <Label>System Job Type</Label>
          <select
                      value={sysJobType}
                      onChange={(e) => setSysJobType(e.target.value)}
                      className="form-select mb-3"
                      >
                         
                      {jobtype && jobtype.length > 0 ? (
                          jobtype.map((role, index) => (
                              <option key={index} value={role.options}>{role.options
                              }</option>
                          ))
                      ) : (
                          <option value="">No Company found</option>
                      )}
                    </select>
        </Col>
      )}
    </Row>
    <Row className="mb-3">
  <Col md={6}>
    <Label>Date Check</Label>
    <select
      className="form-select"
      aria-label="Default select example"
      id="selectOption"
      value={selectedValue}
      onChange={(e) => setSelectedValue(e.target.value)}
    >
      <option value="Status Date">Status Date</option>
      <option value="Job Date">Job Date</option>
    </select>
  </Col>
</Row>

{selectedValue === 'Job Date' ? (
    <Row className="mb-3">
  <Col md={6}>
    <Label>Job Date: Send Email after number of days</Label>
    <Input
      type="number"
      value={jobDate}
      onChange={(e) => setJobDate(e.target.value)}
    />
  </Col>
  </Row>
) : (
  <Row className="mb-3">
  <Col md={6} className="mb-3">
    <Label>Status Date: Send Email after number of days</Label>
    <Input
      type="number" required
      value={statusdate} 
      onChange={(e) => setStatusDate(e.target.value)}
    />
  </Col>
  </Row>
)}
       {isCheckboxChecked ? (
  <>
    <Row className="mb-3">
      <Col md={6} className="mb-3">
        <Label>Send Email with respect to above days plus number of hours</Label>
        <select
          name="hours_after_initial_status"
          id="hours_after_initial_status"
          className="form-control mt-1"
          required
          value={selectedValue1}
          onChange={handleChange}
        >
          {Array.from({ length: 24 }, (_, index) => (
            <option key={index} value={(index / 24).toFixed(2)}>
              {index}
            </option>
          ))}
        </select>
      </Col>

      <Row className="mb-3">
        <Col md={6}>
          <Label>Initial Status </Label>
          <select
            value={intialPipelineStatus}
            onChange={handlePipelineStatus}
            className="form-select mb-3"
          >
           
            {pipelinestatuses && pipelinestatuses.length > 0 ? (
              pipelinestatuses.map((role, index) => (
                <option key={index} value={role.pipeline_status}>
                  {role.pipeline_status}
                </option>
              ))
            ) : (
              <option value=""></option>
            )}
          </select>
        </Col>
        <Col md={6}>
          <Label>Post Status</Label>
          <select
            value={postPipelineStatus}
            onChange={handlePipeline2Status}
            className="form-select mb-3"
          >
           
            {pipelinestatuses && pipelinestatuses.length > 0 ? (
              pipelinestatuses.map((role, index) => (
                <option key={index} value={role.pipeline_status}>
                  {role.pipeline_status}
                </option>
              ))
            ) : (
              <option value=""></option>
            )}
          </select>
        </Col>
      </Row>
    </Row>
  </>
) : (
  <Row className="mb-3">
  <Col md={6}>
    <Label>Initial Status</Label>
    <select
           value={intialStatus}
           onChange={handleIntialStatus}
          className="form-select mb-3"
          >
             
          {jobstatus && jobstatus.length > 0 ? (
              jobstatus.map((role, index) => (
                  <option key={index} value={role.options}>{role.options
                  }</option>
              ))
          ) : (
              <option value=""></option>
          )}
        </select>
  </Col>
  <Col md={6}>
    <Label>Post Status</Label>
    <select
           value={postStatus}
           onChange={handlePostStatus}
          className="form-select mb-3"
          >
           
          {jobstatus && jobstatus.length > 0 ? (
              jobstatus.map((role, index) => (
                  <option key={index} value={role.options}>{role.options
                  }</option>
              ))
          ) : (
              <option value=""></option>
          )}
        </select>
  </Col>
</Row>
)}

          
            <Row className="mb-3">
              <Col md={6}>
                <Label>Send Email</Label>
                <div className="form-check form-switch form-switch-md">
                  <Input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" value={sendEmail==='Y'} onChange={handleEmail}/>

                </div>
              </Col>
              <Col md={6}>
                <Label>Send SMS</Label>
                <div className="form-check form-switch form-switch-md">
                  <Input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"  value={sendSms==='Y'} onChange={handleSms}/>

                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={6}>
                <Label>Email Templates</Label>
                <select
                      value={emailTemplateId}
                      onChange={(e) => setEmailTemplateId(e.target.value)}
                      className="form-select mb-3"
                      >
                      
                      {emailtemp && emailtemp.length > 0 ? (
                          emailtemp.map((role, index) => (
                              <option key={index} value={role.id}>{role.email_template_name
                              }</option>
                          ))
                      ) : (
                          <option value="">No Template Found</option>
                      )}
                    </select>
              </Col>
              <Col md={6}>
                <Label>SMS Templates</Label>
                <select
                       value={smsTemplateId}
                       onChange={(e) => setSmsTemplateId(e.target.value)}
                      className="form-select mb-3"
                      >
                         
                      {smstemp && smstemp.length > 0 ? (
                          smstemp.map((role, index) => (
                              <option key={index} value={role.id}>{role.sms_template_name
                              }</option>
                          ))
                      ) : (
                          <option value="">No Template Found</option>
                      )}
                    </select>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={6}>
                <Label>
                  From Email ID
                </Label>
                <Input type="email" placeholder="contact@yourcopmany.com"  value={fromEmail}
                        onChange={(e) => {setFromEmail(e.target.value);
                          // if (!validateEmail(e.target.value)) {
                          //   toast.error('Invalid email format', { theme: 'light' });
                          // }
                        }}></Input>
              </Col>
              <Col md={6}>
                <Label>
                  From Number/Name
                </Label>
                <Input type="text"  value={fromnum} 
                        onChange={(e) => setFromNum(e.target.value)}></Input>
              </Col>
            </Row>
            <Row >
              <Col md={6}>
                <Label>From Email Name</Label>
                <Input type="text"placeholder="Contact, YourCompany"  value={fromName}
                        onChange={(e) => setFromName(e.target.value)}></Input>
              </Col>
              <Col md={6}></Col>
              <Col md={6}>
                <Label>Active</Label>
                  <div className="form-check form-switch form-switch-md">
                    <Input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" 
                       onChange={handleActive}  checked={active === "Y"} />                
                  </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <ModalFooter className="justify-content-center">
          <Button className="btn btn-success"  onClick={handleFormSubmit} >{!!isEdit ? " Update" : "Save"}</Button>
          <Button className="btn btn-danger" onClick={() => { tog_sequenceModal() }}>Cancel</Button>
        </ModalFooter>
      </Modal>
      <Card id="contactList">
        <CardBody className="pt-0">
          <div>
            {isContactSuccess && crmcontacts.length ? (
              <TableContainer
                columns={emailsmscolumn}
                data={(crmcontacts || [])}
                isGlobalFilter={true}
                isAddUserList={false}
                customPageSize={50}
                className="custom-header-css"
                divClass="table-responsive table-card mb-3"
                tableClass="align-middle table-nowrap"
                theadClass="table-light"
                // handleContactClick={handleContactClicks}
                isContactsFilter={true}
                SearchPlaceholder="Search for contact..."
              />
            ) : (<Loader error={error} />)
            }

          </div>


          <ToastContainer closeButton={false} limit={1} />
        </CardBody>
      </Card>
    </div>
  )
}
