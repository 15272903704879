import { Card, CardBody, Col, Row, Modal, Input, Label, ModalHeader, Form, ModalBody } from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Button } from 'reactstrap';
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import "../../../assets/scss/pages/_settings.scss";
import {
  CardHeader,
  Table,
} from "reactstrap";
//Import actions
import {
  getContacts as onGetContacts,
  addNewContact as onAddNewContact,
  updateContact as onUpdateContact,
  deleteContact as onDeleteContact,
} from "../../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";
import Select from "react-select";
import DeleteModal from "../../../Components/Common/DeleteModal";
import {
  getOrders as onGetOrders,
  addNewOrder as onAddNewOrder,
  updateOrder as onUpdateOrder,
  deleteOrder as onDeleteOrder,
} from "../../../store/ecommerce/action";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// Export Modal

export const PaymentCredentials = () => {
  document.title="Payment Credentials | Onexfort";
  const [modal, setModal] = useState(false);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setContact(null);
    } else {
      setModal(true);
      setTag([]);
      setAssignTag([]);
    }
  }, [modal]);
  const [modal_large, setModal_Company] = useState(false);
  function tog_Company() {
    setModal_Company(!modal_large);
  }
  //fn

  const [paymentTable, setPaymentTable] = useState([]);
  const [contact, setContact] = useState([]);
  const dispatch = useDispatch();
  // const { crmcontacts, isContactCreated, isContactSuccess, error } = useSelector((state) => ({
  //   crmcontacts: state.Crm.crmcontacts,
  //   isContactCreated: state.Crm.isContactCreated,
  //   isContactSuccess: state.Crm.isContactSuccess,
  //   error: state.Crm.error,
  // }));
  const crmcontacts = paymentTable;
  const isContactSuccess = true;
  const error = null;

  useEffect(() => {
    setContact(crmcontacts);
  }, [crmcontacts]);

  useEffect(() => {
    if (!isEmpty(crmcontacts)) {
      setContact(crmcontacts);
      setIsEdit(false);
    }
  }, [crmcontacts]);

  const [isEdit, setIsEdit] = useState(false);
  // Date & Time Format

  const dateFormat = () => {
    var d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return (d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear());
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // img: (contact && contact.img) || '',
      name: (contact && contact.name) || '',
      company: (contact && contact.company) || '',
      designation: (contact && contact.designation) || '',
      email: (contact && contact.email) || '',
      phone: (contact && contact.phone) || '',
      lead_score: (contact && contact.lead_score) || '',
      tags: (contact && contact.tags) || [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      company: Yup.string().required("Please Enter Company"),
      designation: Yup.string().required("Please Enter Designation"),
      email: Yup.string().required("Please Enter Email"),
      phone: Yup.string().required("Please Enter Phone"),
      lead_score: Yup.string().required("Please Enter lead_score"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateContact = {
          _id: contact ? contact._id : 0,
          // img: values.img,
          name: values.name,
          company: values.company,
          designation: values.designation,
          email: values.email,
          phone: values.phone,
          lead_score: values.lead_score,
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // update Contact 
        dispatch(onUpdateContact(updateContact));
        validation.resetForm();
      } else {
        const newContact = {
          _id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          // img: values["img"],
          name: values["name"],
          company: values["company"],
          designation: values["designation"],
          email: values["email"],
          phone: values["phone"],
          lead_score: values["lead_score"],
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // save new Contact
        dispatch(onAddNewContact(newContact));
        validation.resetForm();
      }
      toggle();
    },
  });

  const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);



  const loadSettings = () => {
    axios.get('/api/offline-payment-setting/data')
      .then(response => {
        setPaymentTable(response.offlinemethods);
      })
      .catch(error => {
        console.log(error);
      });
  }
  //*backend response*
  useEffect(() => {
    loadSettings();
  }, []);


  //Table Modal
  const [order, setOrder] = useState([]);

  const handleOrderClick = useCallback((arg) => {
    const order = arg;
    setOrder({
      _id: order._id,
      // orderId: order.orderId,
      customer: order.customer,
      product: order.product,
      orderDate: order.orderDate,
      ordertime: order.ordertime,
      amount: order.amount,
      payment: order.payment,
      status: order.status
    });

    setIsEdit(true);
    toggle();
  }, [toggle]);
  // Update Data
  const handleContactClick = useCallback((arg) => {
    const contact = arg;

    setContact({
      _id: contact._id,
      // img: contact.img,
      name: contact.name,
      company: contact.company,
      email: contact.email,
      designation: contact.designation,
      phone: contact.phone,
      lead_score: contact.lead_score,
      last_contacted: contact.date,
      // time: contact.time,
      tags: contact.tags,
    });

    setIsEdit(true);
    toggle();
  }, [toggle]);

  // Add Data
  const handleContactClicks = () => {
    setContact("");
    setIsEdit(false);
    toggle();
  };

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      dispatch(onDeleteContact(element.value));
      setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".contactCheckBox:checked");
    ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  const [payname, setPayName] = useState("");
  const [description, setDescription] = useState("");
  const [payStatus, setPayStatus] = useState("");
  const [editId, setEditId] = useState("");
  const [selectedOrderId, setSelectedOrderId] = useState(null);

  //sms column
  const smscolumn = useMemo(
    () => [
      {
        Header: "#",
        accessor: "serialNumber",
        Cell: ({ row, rows }) => {
          return <span>{rows.length - row.index}</span>;
        },
        filterable: false,
        // disableFilters: true,
        // disableSortBy: true
      },
      {
        Header: "Method",
        accessor: "name",
        filterable: false,
      },
      {
        Header: "Description",
        accessor: "description",
        filterable: false,
        Cell: ({ value }) => (
          <td dangerouslySetInnerHTML={{ __html: value }} />
        )
      },
      {
        Header: "Status",
        accessor: (row) => row.status === "yes" ? <span className="badge bg-success">Active</span> : <span className="badge bg-danger">Inactive</span>,
        filterable: false,
      },

      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">

              <li className="list-inline-item">
                <button className="btn btn-sm btn-soft-info edit-list" onClick={() => {
                  const orderData = cellProps.row.original;
                  const orderId = orderData.id; // Assuming 'id' is the property containing the ID
                  setEditId(orderId);
                  tog_Company();
                  setIsEdit(true);
                  axios.get(`${"api/offline-payment-setting/edit/"}${orderId}`)
                    .then(response => {
                      setPayName(response.method.name);
                      setDescription(response.method.description);
                      const selectedWareOption = status.find(option => option.value === response.method.status);
                      setPayStatus({ value: selectedWareOption.value, label: selectedWareOption.label });
                    })
                    .catch(error => {
                      console.error(error);
                    });
                }} ><i className="bx bxs-pencil fs-12 pt-1"></i>
                </button>
              </li>
              <li className="list-inline-item edit">
                <Link
                  to="#"
                  className=" btn btn-sm btn-soft-danger fs-13 pt-1"
                  onClick={() => {
                    const orderData = cellProps.row.original;
                    setSelectedOrderId(orderData.id);
                    onClickDelete(selectedOrderId);
                  }}
                >
                  <i className="bx bxs-trash fs-12"></i>
                </Link>
              </li>

            </ul>
          );
        },
      },
    ],
    [handleContactClick, checkedAll]
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    if (payname === '') {
      toast.error("The name field is required.", { theme: "light" });
      return; // Stop form submission if orgTime is empty
    } else if (description === '') {
      toast.error("The description field is required.", { theme: "light" });
      return; // Stop form submission if orgDate is empty
    }
    var formData = {
      "name": payname,
      "description": description,
      "status": payStatus.value
    }

    if (!isEdit) {
      axios.post('/api/offline-payment-setting/store', formData)
        .then(response => {
          toast.success(response.success, { theme: "light" });
          //realoding the page
          loadSettings();
          setModal_Company(false);
        })
        .catch(error => {
          console.error(error);
          toast.error('Something went wrong...');
        })
    } else {
      axios.post(`${"api/offline-payment-setting/update/"}${editId}`, formData)
        .then(response => {
          toast.success(response.success, { theme: "light" });
          loadSettings();
          setModal_Company(false);
        })
        .catch(error => {
          console.error(error);
        });
    }

  }
  const status = [
    { value: `yes`, label: `Active` },
    { value: `no`, label: `Inactive` },
  ];

  //Delete Modal
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  const handleDeleteContact = () => {
    if (contact) {
      dispatch(onDeleteContact(contact._id));
      setDeleteModal(false);
    }
  };

  const onClickDelete = (itemId) => {
    setDeleteItemId(itemId);
    setDeleteModal(true);
  };


  const handleDeleteOrder = async () => {
    var orderIds = selectedOrderId;
    axios.get(`${"api/offline-payment-setting/destroy/"}${orderIds}`)
      .then(response => {
        toast.success(response.success, { theme: "light" });
        loadSettings();
        setDeleteModal(false)

      })
      .catch(error => {
        console.error(error);
      });
  }

  //orgpaystatus
  const handlePayStatus = (selectedOption) => {
    setPayStatus({ value: selectedOption.value, label: selectedOption.label });
  };


  return (
    <div>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Card>
        <CardHeader> <Row>
          <Col md={9} className="paymentCredHead"><h5 className="mb-0 text-primary">Offline Payment Method</h5></Col>
          <Col md={3} className="paycredBtn"><Button className="btn btn-success" onClick={() => { setIsEdit(false); tog_Company(); setPayName(""); setDescription(""); setPayStatus(""); }}> <i className="ri-add-fill me-1 align-bottom"></i> Add Method </Button></Col>
        </Row></CardHeader>

        <Col lg={12}>
          <CardBody>

            <div>
              {isContactSuccess && crmcontacts.length > 0 ? (
                <TableContainer
                  columns={smscolumn}
                  data={(crmcontacts || [])}
                  isGlobalFilter={false}
                  isAddUserList={false}
                  customPageSize={8}
                  className="custom-header-css"
                  divClass="table-responsive table-card mb-3"
                  tableClass="align-middle table-nowrap"
                  theadClass="table-light"
                  handleContactClick={handleContactClicks}
                  isContactsFilter={false}
                  SearchPlaceholder="Search for payment..."
                />
              ) : (<Loader />)
              }

            </div>
            <Modal id="showModal" isOpen={modal_large} size="md" //name c
              toggle={() => {
                tog_Company(); //c
              }} centered>
              <ModalHeader className="bg-soft-info p-3" >
                {!!isEdit ? "Update Offline Payment Method" : "Add Add New Offline Payment Method"}
              </ModalHeader>

              <Form className="tablelist-form">
                <ModalBody>
                  <Input type="hidden" id="id-field" />
                  <Row className="g-3">
                    <Col xxl={12} md={12}>
                      <div className="mb-3">
                        <Label for="warehouseName" className="form-label">
                          Method
                        </Label>
                        <Input
                          name="name"
                          id="customername-field"
                          className="form-control"
                          placeholder=""
                          type="text"
                          validate={{
                            required: { value: true },
                          }}
                          value={payname}
                          onChange={(e) => setPayName(e.target.value)}
                          onBlur={validation.handleBlur}

                        />
                      </div>
                    </Col>
                    <Col xxl={12} md={12}>
                      <div className="mb-3">
                        <Label for="warehouseName" className="form-label">
                          Description
                        </Label>
                        <textarea
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          className="form-control"
                          rows="5"
                          placeholder="">
                        </textarea>
                      </div>
                    </Col>
                    <Col xxl={12} md={12} style={{ display: isEdit ? 'block' : 'none' }}>
                      <div className="mb-5">
                        <Label htmlFor="readonlyInput" className="form-label">Status</Label>
                        <Select
                          value={payStatus}
                          onChange={handlePayStatus}
                          options={status}
                          isSearchable={true}
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="text-right">
                    <div className="button-container hstack gap-2">
                      <Button className="btn btn-success" onClick={handleSubmit}>{!!isEdit ? "Update" : "Save"} </Button>
                      <Button className="btn btn-brown" onClick={() => { setModal_Company() }}>Cancel</Button>
                    </div>
                  </div>
                </ModalBody>
              </Form>
            </Modal>
          </CardBody>
        </Col>
      </Card>
      <ToastContainer closeButton={false} limit={1} theme="light" />
    </div>
  )
}
