
export const SettingsTabPages = {
    OrganisationSetting: {
        id: 0,
        displayText: 'Organisation Settings',
        iconClass: 'cog'
    },
    Companies: {
        id: 1,
        displayText: 'Companies',
        iconClass: 'buildings'
    },
    ServiceCities: {
        id: 2,
        displayText: 'Servicing Cities',
        iconClass: 'chevron-right'
    },
    ListTypeAndOptions: {
        id: 3,
        displayText: 'List Type and Options',
        iconClass: 'file'
    },
    ProfileSetting: {
        id: 4,
        displayText: 'Profile Settings',
        iconClass: 'user'
    },
    PaymentCredentials: {
        id: 5,
        displayText: 'Payment Credentials',
        iconClass: 'dollar-circle'
    },
    BuySmsCredits: {
        id: 6,
        displayText: 'Buy SMS Credits',
        iconClass: 'chat'
    },
    ConnectStripe: {
        id: 7,
        displayText: 'Connect Stripe',
        iconClass: 'check-shield'
    },
    // ConnectQuickFee: {
    //     id: 2,
    //     displayText: ' Connect QuickFee',
    //     iconClass: 'send'
    // },
    ConnectXero: {
        id: 8,
        displayText: 'Connect Xero',
        iconClass: 'check-shield'
    },
    ConnectMyob: {
        id: 9,
        displayText: 'Connect MYOB',
        iconClass: 'check-shield'
    },
    ConfigureEmail: {
        id: 10,
        displayText: 'Configure Email',
        iconClass: 'envelope'
    },
    ConnectCoverfright: {
        id: 11,
        displayText: 'Connect Coverfright',
        iconClass: 'check-shield'
    },
    PagePersonalisation: {
        id: 12,
        displayText: 'Page Personalisation',
        iconClass: 'contact'
    },
 
}

