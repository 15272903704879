import { Card, CardBody, Col, CardHeader, Table, Row, } from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import TableRows from "../TableRows";
//Import actions
import {
  getContacts as onGetContacts,
  addNewContact as onAddNewContact,
  updateContact as onUpdateContact,
  deleteContact as onDeleteContact,
} from "../../../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const OHSChecklist = () => {

  
  return (
    <div>
      <Card>
        <CardHeader>
          <h5 className="mb-0"></h5>
        </CardHeader>
        <CardBody>
         
        </CardBody>
      </Card>
    </div>
  )
}
