import React, { useState,useRef, useCallback, useEffect, useMemo } from 'react';
import { Link } from "react-router-dom";
import { Card, CardBody, Table, } from 'reactstrap';
import TableRows from './Tables/TableRows';
import { get_cookie } from '../../../helpers/get_cookie';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteModal from "../../../Components/Common/DeleteModal";
import Loader from "../../../Components/Common/Loader";


export const Taxes = () => {
  document.title="Taxes | Onexfort";
  //rowsdata
  const [rowsData, setRowsData] = useState([]);
  const [tax,setTax] = useState('')
  const [rowsCheck,setRowsCheck]= useState('')
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [isLoading, setisLoading] = useState(false);

const loadTable=()=>{
  setisLoading(true)

  const user = JSON.parse(get_cookie("authUser"));
  var tenant_id = user.tenant_id;
  var datavalue={
    "tenant_id": tenant_id
  }
  axios.get("/api/manage-taxes")
    .then((res) => {
      console.log(res);
      setTax(res);
      setisLoading(false)

    });
}
  useEffect(() => {
  
    loadTable();
  }, []);
//////////

const addTableRows = () => {
  setRowsData([...rowsData, { groupTax: '', groupRate: '' }]);
};
const handleChange = (index, event) => {
  const { name, value } = event.target;
  if (name === 'category_name') {
    const updatedProductCategory = [...tax];
    updatedProductCategory[index].tax_name = value;
    setTax(updatedProductCategory);
  } else if (name === 'groupTax') {
    const updatedRowsData = [...rowsData];
    updatedRowsData[index].groupTax = value;
    setRowsData(updatedRowsData);
  }
};

const handleNewChange = (index, event) => {
  const { name, value } = event.target;
  if (name === 'category_name') {
    const updatedProductCategory = [...tax];
    updatedProductCategory[index].rate_percent = value;
    setTax(updatedProductCategory);
  } else if (name === 'groupRate') {
    const updatedRowsData = [...rowsData];
    updatedRowsData[index].groupRate = value;
    setRowsData(updatedRowsData);
  }
};
console.log(rowsData,"rowsdata")
console.log(tax,"tax")

//Edit

const handleEdit = (index) => {
  const updatedProductCategory = [...tax];
  updatedProductCategory[index].isEditing = true;
  setTax(updatedProductCategory);
};
//cancel button for edit
const handleCancel = (index) => {
  const updatedProductCategory = [...tax];
  updatedProductCategory[index].isEditing = false;
  setTax(updatedProductCategory);
};
//save(Update)
const handleSave = async (index) => {
  const item = tax[index];
  console.log('update:', item);
  console.log("data",item)
  var datavalue={
    "id": item.id,
    "tax_name": item.tax_name,
    "rate_percent":item.rate_percent
  }
if(item.tax_name==''){
  toast.error("Enter Tax Name ",{theme:"light"});
return
}
if(item.rate_percent==''){
  toast.error("Enter Rate Percent",{theme:"light"});
  return
}
  axios.post('/api/ajaxUpdateTax',datavalue)
  .then(res => {
    toast.success(res.success,{theme:"light"});
    loadTable();
  })
  .catch(error => {
    console.error('Error fetching lead types:', error);
  });
};

//Delete(Upadte)
const handleDelete = async (index) => {
  
  const item = tax[index];
  setDeleteItemId(item.id)
   setDeleteModal(true)
};
const handleDeleteTax = async () => {
  
    var deleteval = {
      "id": deleteItemId,
    }
    axios.post('/api/ajaxDestroyTax',deleteval)
    .then(res => {
      console.log(res.success);
      toast.success(res.success,{theme:"light"});
      loadTable();
      setDeleteModal(false)
    })
    .catch(error => {
      console.error('Error fetching lead types:', error);
    });
};
//SAVE(new row)
const saveTableRow = (index) => {
  const data = rowsData[index];
  // console.log('Saving:', data.groupName);
  var datavalue={
    "tax_name":  data.groupTax,
    "rate_percent" :data.groupRate
  }
  if(data.groupTax==''){
    toast.error("Enter Tax Name ",{theme:"light"});
  return
  }
  if(data.groupRate==''){
    toast.error("Enter Rate Percent",{theme:"light"});
    return
  }
  axios.post('/api/ajaxCreateTax',datavalue)
  .then(res => {
    console.log(res.success);
    toast.success(res.success,{theme:"light"});
    const updatedRows = [...rowsData];
    updatedRows.splice(index, 1);
    setRowsData([...updatedRows]);
    loadTable();
  })
  .catch(error => {
    console.error('Error fetching lead types:', error);
  });
}

console.log("rowsData1",rowsData);

//cancel(new row)
const deleteTableRows = (index) => {
  const updatedRowsData = [...rowsData];
  updatedRowsData.splice(index, 1);
  setRowsData(updatedRowsData);
};
  return (
    
    <Card>
       {isLoading && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            background: 'rgba(255, 255, 255, 0.8)',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            zIndex: 9999,
                                        }}
                                    >
                                        <Loader />
                                    </div>
                                )}
                                <DeleteModal
                        show={deleteModal}
                        onDeleteClick={handleDeleteTax}
                        onCloseClick={() => setDeleteModal(false)}
                    />
      <CardBody>
        <div className="table-responsive mt-4 mt-xl-0">

          <Table className="table table-bordered table-nowrap align-middle mb-3">
            <thead>
              <tr>
                <th scope="col"> Tax</th>
                <th scope="col"> Rate Percent</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>  
               {tax && tax.map((item, index) => (
         <tr key={index}>
              <td className="fw-medium">
                            {item.isEditing ? (
                              <input
                                type="text"
                                value={item.tax_name}
                                onChange={(event) => handleChange(index, event)}
                                className="form-control"
                                name="category_name"
                              />
                            ) : (
                              item.tax_name
                            )}
                          </td>
                          <td className="fw-medium">
                            {item.isEditing ? (
                              <input
                              type="text"
                              value={item.rate_percent}
                              onChange={(event) => handleNewChange(index, event)}
                              className="form-control"
                              name="category_name"
                            />
                            ) : (
                              item.rate_percent+'%'
                            )}
                          </td>
                          <td>
                            <div className="hstack gap-3 flex-wrap">
                              {item.isEditing ? (
                                <>
                                  <button
                                    className="btn btn-success"
                                    onClick={() => handleSave(index)}
                                  >
                                    Update
                                  </button>
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => handleCancel(index)}
                                  >
                                    Cancel
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    className="btn btn-sm btn-soft-info edit-list "
                                    onClick={() => handleEdit(index)}
                                  >
                                    <i className="bx bxs-pencil fs-12 pt-1"></i>
                                  </button>
                                  <button
                                    className="btn btn-sm btn-soft-danger  "
                                    onClick={() => handleDelete(index)}
                                  >
                                    <i className="ri-delete-bin-5-fill fs-16"></i>
                                  </button>
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                      {rowsData && rowsData.map((data, index) => (
                        <tr key={index}>
                          <td>
                          <input
                              type="text"
                              value={data.groupTax}
                              onChange={(event) => handleChange(index, event)}
                              name="groupTax"
                              className="form-control"
                            />
                          </td>
                          <td>
                          <input
                              type="text"
                              value={data.groupRate}
                              onChange={(event) => handleNewChange(index, event)}
                              name="groupRate"
                              className="form-control"
                            />
                          </td>
                          <td>
                            <div className="hstack gap-2">
                              <button className="btn btn-success" 
                              onClick={() => saveTableRow(index)}
                              >
                                Save
                              </button>
                              <button className="btn btn-primary" 
                              onClick={() => deleteTableRows(index)}
                              >
                                Cancel
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <ToastContainer closeButton={false} limit={1} theme="light" />
          </Table>
          <button className="btn btn-primary" onClick={addTableRows} >+</button>
        </div>
      </CardBody>

    </Card>
  )
}
