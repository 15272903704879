const Communication = {
    EmailTemplates: {
        id: 0,
        displayText: 'Email Templates',
        iconClass: 'bx bx-envelope',
       
    },
    SmsTemplates: {
        id: 1,
        displayText: 'SMS Templates',
        iconClass: 'bx bx-chat'
    },
    EmailAndSmsSequence: {
        id: 2,
        displayText: 'Email And SMS Sequences',
        iconClass: 'bx bx-envelope'
    },
}
const Customisation = {
    Statuses: {
        id: 3,
        displayText: 'Statuses',
        iconClass: 'send'
    },
}
   
export const CrmSettingsTabPages = {
    Communication, Customisation
}