import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Card, CardHeader, CardBody, Col, Label,
  Input, Modal, Row,
} from "reactstrap";

import { Link } from 'react-router-dom'
// import Components
import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";
import { toast, ToastContainer } from 'react-toastify';
import { isEmpty } from "lodash";
import {

} from "reactstrap";
// Import Images
import * as Yup from "yup";
import { useFormik } from "formik";
//redux
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
//Import actions
import {
  getContacts as onGetContacts,
  addNewContact as onAddNewContact,
  updateContact as onUpdateContact,
  deleteContact as onDeleteContact,
} from "../../../store/actions";
import { get_cookie } from '../../../helpers/get_cookie';
import axios from 'axios';
import DeleteModal from "../../../Components/Common/DeleteModal";
import {
  getOrders as onGetOrders,
  addNewOrder as onAddNewOrder,
  updateOrder as onUpdateOrder,
  deleteOrder as onDeleteOrder,
} from "../../../store/ecommerce/action";
export const StorageType = () => {
  document.title = "Storage Types | Onexfort";
  const isContactSuccess = true;
  const error = null;
  const [contact, setContact] = useState([]);


  // add large modals
  const [modal_type, setmodal_type] = useState(false);
  function tog_mod_2() {
    setmodal_type(!modal_type);
  }
  const [isEdit, setIsEdit] = useState(false);
  // Delete Data

  const handleDeleteOrder = () => {
    var orderIds = deleteId;
    axios.get(`${"api/storage-types/destroy/"}${deleteId}`)
      .then(response => {
        if (response.data == "1") {
          toast.success(response.message, { theme: "light" });
        }
        else {
          toast.error(response.message, { theme: "light" });
        }
        loadTable();
        setDeleteModal(false);
      })
      .catch(error => {
        console.error(error);
      });
  };


  const onClickDelete = (contact) => {
    setContact(contact);
    setDeleteModal(true);
  };

  // Add Data
  const handleContactClicks = () => {
    setContact("");
    setIsEdit(false);
    toggle();
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // img: (contact && contact.img) || '',
      name: (contact && contact.name) || '',
      company: (contact && contact.company) || '',
      designation: (contact && contact.designation) || '',
      email: (contact && contact.email) || '',
      phone: (contact && contact.phone) || '',
      lead_score: (contact && contact.lead_score) || '',
      tags: (contact && contact.tags) || [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      company: Yup.string().required("Please Enter Company"),
      designation: Yup.string().required("Please Enter Designation"),
      email: Yup.string().required("Please Enter Email"),
      phone: Yup.string().required("Please Enter Phone"),
      lead_score: Yup.string().required("Please Enter lead_score"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateContact = {
          _id: contact ? contact._id : 0,
          // img: values.img,
          name: values.name,
          company: values.company,
          designation: values.designation,
          email: values.email,
          phone: values.phone,
          lead_score: values.lead_score,
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // update Contact 
        dispatch(onUpdateContact(updateContact));
        validation.resetForm();
      } else {
        const newContact = {
          _id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          // img: values["img"],
          name: values["name"],
          company: values["company"],
          designation: values["designation"],
          email: values["email"],
          phone: values["phone"],
          lead_score: values["lead_score"],
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // save new Contact
        dispatch(onAddNewContact(newContact));
        validation.resetForm();
      }
      toggle();
    },
  });

  const [data, setData] = useState([]);
  const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);

  const loadTable = () => {
    axios.get("/api/storage-types/data")
      .then((res) => {
        setData(res.data);
      });
  }
  useEffect(() => {
    loadTable();
  }, []);
  // Checked All 
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  // Date && Time Format

  const dateFormat = () => {
    var d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return (d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear());
  };
  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      dispatch(onDeleteContact(element.value));
      setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".contactCheckBox:checked");
    ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  const dispatch = useDispatch();
  const crmcontacts = data;

  useEffect(() => {
    setContact(crmcontacts);
  }, [crmcontacts]);

  useEffect(() => {
    if (!isEmpty(crmcontacts)) {
      setContact(crmcontacts);
      setIsEdit(false);
    }
  }, [crmcontacts]);

  //delete Conatct
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const [modal, setModal] = useState(false);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setContact(null);
    } else {
      setModal(true);
      setTag([]);
      setAssignTag([]);
    }
  }, [modal]);
  // Update Data
  const handleContactClick = useCallback((arg) => {
    const contact = arg;

    setContact({
      _id: contact._id,
      // img: contact.img,
      name: contact.name,
      company: contact.company,
      email: contact.email,
      designation: contact.designation,
      phone: contact.phone,
      lead_score: contact.lead_score,
      last_contacted: contact.date,
      // time: contact.time,
      tags: contact.tags,
    });

    setIsEdit(true);
    toggle();
  }, [toggle]);


  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState([]);
  const [deleteId, setDeleteId] = useState([]);

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [transportable, setTransportable] = useState("Y");
  const [inside_cubic_capacity, setInsidecubiccapacity] = useState("");
  const [max_gross_weight_kg, setMaxgrossweightkg] = useState("");
  const [tare_weight_kg, setTareweightkg] = useState("");
  const [ext_length_m, setExtlengthm] = useState("");
  const [ext_width_m, setExtwidthm] = useState("");
  const [ext_height_m, setExtheightm] = useState("");
  const [int_length_m, setIntlengthm] = useState("");
  const [int_width_m, setIntwidthm] = useState("");
  const [int_height_m, setIntheightm] = useState("");
  const [active, setActive] = useState("1");

  // Save and Update Button
  const handleSubmit = (event) => {
    event.preventDefault();

    var formData = {
      "name": name,
      "transportable": transportable,
      "inside_cubic_capacity": inside_cubic_capacity,
      "max_gross_weight_kg": max_gross_weight_kg,
      "tare_weight_kg": tare_weight_kg,
      "ext_length_m": ext_length_m,
      "ext_width_m": ext_width_m,
      "ext_height_m": ext_height_m,
      "int_length_m": int_length_m,
      "int_width_m": int_width_m,
      "int_height_m": int_height_m,
      "active": active,
      "id": id,
    }
   
    if (name === '') {
      toast.error("The name field is required.", { theme: "light" });
      return; // Stop form submission if name is empty
    }
    
    if (!isEdit) {
       console.log(formData);
      axios.post("api/storage-types/store", formData)
        .then(response => {
          toast.success(response.message, { theme: "light" });
          loadTable();
          tog_mod_2(true);
          setId("");
          setName("");
          setTransportable("Y");
          setInsidecubiccapacity("");
          setMaxgrossweightkg("");
          setTareweightkg("");
          setExtlengthm("");
          setExtwidthm("");
          setExtheightm("");
          setIntlengthm("");
          setIntwidthm("");
          setIntheightm("");
          setActive("1");
        })
        .catch(error => {
          console.error(error);
        });
    } else {
      axios.post("api/storage-types/update", formData)
        .then(response => {
          toast.success(response.message, { theme: "light" });
          loadTable();
          tog_mod_2(true);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }

  const handleOrderClick = useCallback((arg) => {
    const order = arg;
    setOrder({
      _id: order._id,
      orderId: order.orderId,
      customer: order.customer,
      product: order.product,
      orderDate: order.orderDate,
      ordertime: order.ordertime,
      amount: order.amount,
      payment: order.payment,
      status: order.status
    });

    setIsEdit(true);
    toggle();
  }, [toggle]);


  // Customber Column

  const colstoragtype = useMemo(
    () => [
      {
        Header: <input type="checkbox" id="checkBoxAll" className="form-check-input" onClick={() => checkedAll()} />,
        Cell: (cellProps) => {
          return <input type="checkbox" className="contactCheckBox form-check-input" value={cellProps.row.original._id} onChange={() => deleteCheckbox()} />;
        },
        id: '#',
      },
      {
        Header: "Storage Type",
        accessor: "storage_type",
        filterable: false,

      },
      {
        Header: "Dimensions",
        accessor: "dimensions",
        filterable: false,
      },


      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">

              <li className="list-inline-item">
                <button className="btn btn-sm btn-soft-info edit-list" onClick={() => {
                  const orderData = cellProps.row.original;
                  handleOrderClick(orderData);
                  const orderId = orderData.id; // Assuming 'id' is the property containing the ID
                  setIsEdit(true);
                  tog_mod_2(true);
                  // Use orderId as a parameter in the Axios URL
                  axios.get(`${"api/storage-types/edit/"}${orderId}`)
                    .then(response => {
                      setId(response.storage_type.id);
                      setName(response.storage_type.name);
                      setTransportable(response.storage_type.transportable);
                      setInsidecubiccapacity(response.storage_type.inside_cubic_capacity);
                      setMaxgrossweightkg(response.storage_type.max_gross_weight_kg);
                      setTareweightkg(response.storage_type.tare_weight_kg);
                      setExtlengthm(response.storage_type.ext_length_m);
                      setExtwidthm(response.storage_type.ext_width_m);
                      setExtheightm(response.storage_type.ext_height_m);
                      setIntlengthm(response.storage_type.int_length_m);
                      setIntwidthm(response.storage_type.int_width_m);
                      setIntheightm(response.storage_type.int_height_m);
                      setActive(response.storage_type.active);
                    })
                    .catch(error => {
                      console.error(error);
                    });
                }} ><i className="bx bxs-pencil fs-12 pt-1"></i>
                </button>
              </li>
              <li className="list-inline-item edit">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    const orderData = cellProps.row.original;
                    setDeleteId(orderData.id);
                    onClickDelete(orderData);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>

            </ul>
          );
        },
      },
    ],
    [handleContactClick, checkedAll]
  );
  const [activeInterest, setActiveInterest] = useState("N");
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (name === "active") {
      setActiveInterest(checked ? "Y" : "N");
    }
  };


  //toggle
  const handleToggle = () => {
    setTransportable(transportable === 'Y' ? 'N' : 'Y');
  };
  //toggle1
  const handleToggle1 = () => {
    setActive(active === '1' ? '0' : '1');
  };
  return (
    <div>
      <DeleteModal
        show={deleteModal}
        deleteId={deleteId}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      /> <Card>
        <CardHeader>
          <div className="d-flex align-items-center flex-wrap gap-2">
            <div className="flex-grow-1">
              <button
                className="btn btn-brown add-btn"
                onClick={() => {
                  setIsEdit(false);
                  tog_mod_2(true);
                  setId("");
                  setName("");
                  setTransportable("Y");
                  setInsidecubiccapacity("");
                  setMaxgrossweightkg("");
                  setTareweightkg("");
                  setExtlengthm("");
                  setExtwidthm("");
                  setExtheightm("");
                  setIntlengthm("");
                  setIntwidthm("");
                  setIntheightm("");
                  setActive("1");
                }}
              >
                <i className="ri-add-fill me-1 align-bottom"></i> Add Storage Type
              </button>
            </div>

          </div>
        </CardHeader>
      </Card>
      <Col xxl={12}>
        <Card id="contactList">
          <CardBody className="pt-0">
            <div>
              {isContactSuccess && crmcontacts.length ? (
                <TableContainer
                  columns={colstoragtype}
                  data={(crmcontacts || [])}
                  isGlobalFilter={true}
                  isAddUserList={false}
                  customPageSize={8}
                  className="custom-header-css"
                  divClass="table-responsive table-card mb-3"
                  tableClass="align-middle table-nowrap"
                  theadClass="table-light"
                  handleContactClick={handleContactClicks}
                  isContactsFilter={true}
                  SearchPlaceholder="Search for contact..."
                />
              ) : (<Loader/>)
              }

            </div>

            <Modal
              className="mt-5"
              size="lg"
              isOpen={modal_type}
              toggle={() => {
                tog_mod_2();
              }}
            >
              <Card>
                <CardHeader>
                  <h5 className="mb-0"> {!!isEdit ? "Edit Storage Type" : "Create Storage Type"}</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                  <h5 className="mb-3">Storage Type Detail</h5>
                    <Input type="hidden" value={id} onChange={(e) => setId(e.target.value)} className="form-control" placeholder="" id="storageId" />
                    <Col md={6}>
                      <div className="mb-3 ">
                        <Label for="storageName" className="form-label">Name</Label>
                        <Input type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control" placeholder="eg. 20'Container" id="storageName" />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3 ">
                        <Label className="form-label" for="customSwitchsizelg">Transportable</Label>
                        <div className="form-check form-switch form-switch-lg">
                          <Input
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckRightDisabled"
                            value="Y"
                            name="active"
                            checked={transportable === 'Y'}
                            onChange={handleToggle}
                          />
                        </div>
                        {/* <Input type="checkbox" value={transportable} onChange={(e) => setTransportable(e.target.value)} className="form-check-input" id="customSwitchsizelg" defaultChecked /> */}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Label for="address" className="form-label">Inside Capacity in cubic meter (m3)</Label>
                    <Col md={3}>
                      <div className="mb-3">

                        <Input type="text" value={inside_cubic_capacity} onChange={(e) => setInsidecubiccapacity(e.target.value)} className="form-control" placeholder="" id="address" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>
                      <div className="mb-3">
                        <Label for="address" className="form-label">Max Gross Weight in Kg</Label>
                        <Input type="text" value={max_gross_weight_kg} onChange={(e) => setMaxgrossweightkg(e.target.value)} className="form-control" placeholder="" id="address" />
                      </div>
                    </Col>
                    <Col md={3}></Col>
                    <Col md={3}>
                      <div className="mb-3">
                        <Label for="address" className="form-label">Tare Weight in Kg</Label>
                        <Input type="text" value={tare_weight_kg} onChange={(e) => setTareweightkg(e.target.value)} className="form-control" placeholder="" id="address" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Label for="suburb" className="form-label">External Dimensions in meter</Label>
                    <Col md={3}>
                      <div className="mb-3">
                        <Input type="text" value={ext_length_m} onChange={(e) => setExtlengthm(e.target.value)} className="form-control" placeholder="Length" id="suburb" />
                      </div>
                    </Col>
                    <Col md={1} className="d-flex align-items-center justify-content-center" style={{ marginBottom: '15px' }}>
                      <b>x</b>
                    </Col>
                    <Col md={3}>
                      <div className="mb-3">
                        <Input type="text" value={ext_width_m} onChange={(e) => setExtwidthm(e.target.value)} className="form-control" placeholder="Width" id="state" />
                      </div>
                    </Col>
                    <Col md={1} className="d-flex align-items-center justify-content-center" style={{ marginBottom: '15px' }}>
                      <b>x</b>
                    </Col>
                    <Col md={3}>
                      <div className="mb-3">
                        <Input type="text" value={ext_height_m} onChange={(e) => setExtheightm(e.target.value)} className="form-control" placeholder="Height" id="postCode" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Label for="suburb" className="form-label">Internal Dimensions in meter</Label>
                    <Col md={3}>
                      <div className="mb-3">
                        <Input type="text" value={int_length_m} onChange={(e) => setIntlengthm(e.target.value)} className="form-control" placeholder="Length" id="suburb" />
                      </div>
                    </Col>
                    <Col md={1} className="d-flex align-items-center justify-content-center" style={{ marginBottom: '15px' }}>
                      <b>x</b>
                    </Col>
                    <Col md={3}>
                      <div className="mb-3">
                        <Input type="text" value={int_width_m} onChange={(e) => setIntwidthm(e.target.value)} className="form-control" placeholder="Width" id="state" />
                      </div>
                    </Col>
                    <Col md={1} className="d-flex align-items-center justify-content-center" style={{ marginBottom: '15px' }}>
                      <b>x</b>
                    </Col>
                    <Col md={3}>
                      <div className="mb-3">
                        <Input type="text" value={int_height_m} onChange={(e) => setIntheightm(e.target.value)} className="form-control" placeholder="Height" id="postCode" />
                      </div>
                    </Col>
                  </Row>
                  <Row>

                    <Col md={12}>
                      <Row>
                        <Col md={9}>
                          <div>
                            <div className="form-check mb-2" style={{ paddingLeft: '0px' }}>
                              <Label className="form-check-label" for="checkActive">
                                Active
                              </Label>
                            </div>
                            <div className="form-check form-switch form-switch-lg">
                              <Input
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckRightDisabled"
                                value="Y"
                                name="active"
                                checked={active === '1'}
                                onChange={handleToggle1}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>

                    <Row>
                      <Col md={5}></Col>
                      <div className="hstack gap-2 justify-content-end">
                        <button type="submit" onClick={handleSubmit} className="btn btn-success"> {!!isEdit
                          ? "Update"
                          : "Save"}</button>
                        <button type="submit" className="btn btn-primary" onClick={() =>
                          tog_mod_2(true)
                        }>Cancel</button></div>
                    </Row>
                  </Row>
                </CardBody>
              </Card>

            </Modal>
            <ToastContainer closeButton={false} limit={1} />
          </CardBody>
        </Card>
      </Col>
    </div>
  )
}
