import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, Alert, UncontrolledAlert, TabContent, TabPane, UncontrolledTooltip } from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Button } from 'reactstrap';
import { isEmpty } from "lodash";
import "../../../assets/scss/pages/_settings.scss";
import {
  CardHeader,
  Label,
  Input,
} from "reactstrap";
import Select from "react-select";
//Import actions
import {
  getContacts as onGetContacts,
  addNewContact as onAddNewContact,
  updateContact as onUpdateContact,
  deleteContact as onDeleteContact,
} from "../../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
//dateformat
import moment from 'moment';
import { get_cookie } from '../../../helpers/get_cookie';
//reloading the page
import { jsx } from '@emotion/react'
import { ClipLoader } from 'react-spinners';
//toast
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../../Components/Common/Loader";


//reloading the page
const override = jsx`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
export const OrganizationSetting = () => {
  document.title="Organisation Setting | Onexfort";
  const [modal, setModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);//reloading the page


  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setContact(null);
    } else {
      setModal(true);
      setTag([]);
      setAssignTag([]);
    }
  }, [modal]);

  const [contact, setContact] = useState([]);
  const dispatch = useDispatch();
  const { crmcontacts, isContactCreated, isContactSuccess, error } = useSelector((state) => ({
    crmcontacts: state.Crm.crmcontacts,
    isContactCreated: state.Crm.isContactCreated,
    isContactSuccess: state.Crm.isContactSuccess,
    error: state.Crm.error,
  }));

  useEffect(() => {
    setContact(crmcontacts);
  }, [crmcontacts]);

  useEffect(() => {
    if (!isEmpty(crmcontacts)) {
      setContact(crmcontacts);
      setIsEdit(false);
    }
  }, [crmcontacts]);


  const [isEdit, setIsEdit] = useState(false);
  // Date & Time Format

  const dateFormat = () => {
    var d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return (d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear());
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // img: (contact && contact.img) || '',
      name: (contact && contact.name) || '',
      company: (contact && contact.company) || '',
      designation: (contact && contact.designation) || '',
      email: (contact && contact.email) || '',
      phone: (contact && contact.phone) || '',
      lead_score: (contact && contact.lead_score) || '',
      tags: (contact && contact.tags) || [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      company: Yup.string().required("Please Enter Company"),
      designation: Yup.string().required("Please Enter Designation"),
      email: Yup.string().required("Please Enter Email"),
      phone: Yup.string().required("Please Enter Phone"),
      lead_score: Yup.string().required("Please Enter lead_score"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateContact = {
          _id: contact ? contact._id : 0,
          // img: values.img,
          name: values.name,
          company: values.company,
          designation: values.designation,
          email: values.email,
          phone: values.phone,
          lead_score: values.lead_score,
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // update Contact 
        dispatch(onUpdateContact(updateContact));
        validation.resetForm();
      } else {
        const newContact = {
          _id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          // img: values["img"],
          name: values["name"],
          company: values["company"],
          designation: values["designation"],
          email: values["email"],
          phone: values["phone"],
          lead_score: values["lead_score"],
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // save new Contact
        dispatch(onAddNewContact(newContact));
        validation.resetForm();
      }
      toggle();
    },
  });

  const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);

  const [selectedSingle, setSelectedSingle] = useState(null);
  //*backend response variable name*
  const [values, setValues] = useState([]);
  const [dateObject, setDateObject] = useState('');
  const [lang, setLang] = useState([]);
  const [langCode, setLangCode] = useState([]);
  const [gbl, setGbl] = useState('');

  //*input fields values variable name*
  const [orgName, setOrgName] = useState("");
  const [orgEmail, setOrgEmail] = useState("");
  const [orgPhn, setOrgPhn] = useState("");
  const [orgWeb, setOrgWeb] = useState("");
  const [orgAdd, setOrgAdd] = useState("");
  const [orgTime, setOrgTime] = useState("");
  const [orgDate, setOrgDate] = useState("");
  const [orgTimeFormat, setOrgTimeFormat] = useState("");
  const [orgWeekStart, setOrgWeekStart] = useState("");
  const [orgLang, setOrgLang] = useState("");
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [isLoading, setisLoading] = useState(false);

  //*tenantid*
  const user = JSON.parse(get_cookie("authUser"));
  var tenant_id = user.data.tenant_id;


  const loadSettings = () => {
    setisLoading(true);
    axios.get('/api/settings')
      .then(response => {
        setValues(response.timezones);
        setDateObject(response.dateObject);
        //database values for dropdown
        setGbl(response.global);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setisLoading(false);
      })

    axios.get('/api/lang')
      .then(response => {
        const languages = response.languages;
        const languageNames = languages.map(language => language.language_name);
        const languageCodes = languages.map(language => language.language_code);
        setLangCode(languageCodes);
        setLang(languageNames);
        setSelectedSingle(languageNames[0]);

        setIsInitialRender(false); // Set isInitialRender to false after fetching the language data
      })
      .catch(error => {
        console.log(error);
      });
  }
  //*backend response*
  useEffect(() => {
    loadSettings();
  }, []);

  //*static dropdown values*
  //orgdate
  const date = useMemo(() => {
    const dates = new Date(dateObject);
    const formattedDate1 = moment(dates).format('DD-MM-YYYY');
    const formattedDate2 = moment(dates).format('MM-DD-YYYY');
    const formattedDate3 = moment(dates).format('YYYY-MM-DD');
    const formattedDate4 = moment(dates).format('DD/MM/YYYY');
    const formattedDate5 = moment(dates).format('MM/DD/YYYY');
    const formattedDate6 = moment(dates).format('YYYY/MM/DD');

    return [
      { value: 'd-m-Y', label: `d-m-Y (${formattedDate1})` },
      { value: 'm-d-Y', label: `m-d-Y (${formattedDate2})` },
      { value: 'Y-m-d', label: `Y-m-d (${formattedDate3})` },
      { value: 'd/m/Y', label: `d/m/Y (${formattedDate4})` },
      { value: 'm/d/Y', label: `m/d/Y (${formattedDate5})` },
      { value: 'Y/m/d', label: `Y/m/d (${formattedDate6})` },
    ];
  }, [dateObject]);
  //orgtimeformat
  const time = [
    { value: 'h:i A', label: '12 Hour (6:20 PM)' },
    { value: 'h:i a', label: '12 Hour (6:20 pm)' },
    { value: 'H:i', label: '24 Hour (18:20)' }
  ];
  //orgweek
  const week = [
    { value: `Sunday`, label: `Sunday` },
    { value: `Monday`, label: `Monday` }
  ];
  //orlang
  const langOptions = [
    { value: 'en', label: 'English' },
    ...lang.map((language, index) => ({
      value: langCode[index],
      label: language,
    }))
  ];

  //*dropdown default value(database value)* 
  //orgname
  useEffect(() => {
    setOrgName(gbl.company_name);
  }, [gbl.company_name]);
  //orgemail
  useEffect(() => {
    setOrgEmail(gbl.company_email);
  }, [gbl.company_email]);
  //orgPhone
  useEffect(() => {
    setOrgPhn(gbl.company_phone);
  }, [gbl.company_phone]);
  //orgwebsite
  useEffect(() => {
    setOrgWeb(gbl.website);
  }, [gbl.website]);
  //orgaddress
  useEffect(() => {
    setOrgAdd(gbl.address);
  }, [gbl.address]);
  //orgtimezone
  useEffect(() => {
    if (values) {
      var selectedOption = values.find(timezone => gbl.timezone === timezone);
      setOrgTime(selectedOption);
    }
  }, [values]);
  //orgdate
  useEffect(() => {
    if (date) {
      const selectedOption = gbl.date_format;
      const foundOption = date.find(option => option.value === selectedOption);
      if (foundOption) {
        setOrgDate(foundOption);
      }
    }
  }, [date]);
  //orgtimeformat
  useEffect(() => {
    const selectedOption = time.find(option => option.value === gbl.time_format);
    if (selectedOption) {
      setOrgTimeFormat(selectedOption.value);
    }
  }, [gbl.time_format]);

  const selectedTimeOption = time.find(option => option.value === orgTimeFormat);
  //orgweek
  useEffect(() => {
    const selectedOption = week.find(option => option.value === gbl.week_starts);
    if (selectedOption) {
      setOrgWeekStart(selectedOption.value);
    }
  }, [gbl.week_starts]);
  const selectedWeekOption = week.find(option => option.value === orgWeekStart);
  //orglang
  useEffect(() => {
    if (!isInitialRender) { // Condition to prevent rendering during initial render
      const selectedOption = langOptions.find(option => option.value === gbl.locale);
      if (selectedOption) {
        setOrgLang(selectedOption.value);
      }
    }
  }, [gbl.locale, isInitialRender]);
  const selectedLangOption = langOptions.find(option => option.value === orgLang);

  //*onchange values for dropdown*
  //orgname
  const handleNameChange = (e) => {
    setOrgName(e.target.value);
  };
  //orgemail
  const handleEmailChange = (e) => {
    setOrgEmail(e.target.value);
  };
  //orgphone
  const handlePhoneChange = (e) => {
    setOrgPhn(e.target.value);
  };
  //orgwebsite
  const handleWebsiteChange = (e) => {
    setOrgWeb(e.target.value);
  };
  // orgaddress
  const handleAddressChange = (e) => {
    setOrgAdd(e.target.value);
  };
  // orgtimezone
  const handleOrgTimeChange = (selectedOption) => {
    setOrgTime(selectedOption.label);
  };

  //orgdate
  const handleOrgDateChange = (selectedOption) => {
    setOrgDate(selectedOption); // Save the selected option object to the orgDate state
  };

  //orgtimeformat
  const handleOrgTimeFormatChange = (selectedOption) => {
    setOrgTimeFormat(selectedOption.value);
  };
  //orgweek
  const handleOrgWeekFormatChange = (selectedOption) => {
    setOrgWeekStart(selectedOption.value);
  };
  //orglang
  const handleOrgLangFormatChange = (selectedOption) => {
    setOrgLang(selectedOption.value);
  };


  //*Submit(Update button)*
  const handleSubmit = (event) => {
    event.preventDefault();
    //reloading the page
    setisLoading(true);
    var orgtimes = orgTime;
    var orgdates = orgDate.value;
    var orgtimeformats = orgTimeFormat;
    var orgweekstarts = orgWeekStart;
    var orglangs = orgLang;
    console.log(orgdates);
    if (orgName === '') {
      toast.error("The Company name field is required.", { theme: "light" });
      setisLoading(false);
      return; // Stop form submission if orgTime is empty
    } else if (orgEmail === '') {
      toast.error("The company email field is required.", { theme: "light" });
      setisLoading(false);
      return; // Stop form submission if orgDate is empty
    }else if (orgEmail !== '') {
      const emailPattern = /^[A-Za-z0-9._+-]+@[A-Za-z._-]+\.[A-Za-z]{2,}$/;
      const isValid = emailPattern.test(orgEmail);
      if (!isValid) {
        toast.error('Invalid email', { theme: "light" });
        setisLoading(false);
        return;
      }
    } else if (orgPhn === '') {
      toast.error("The company phone field is required.", { theme: "light" });
      setisLoading(false);
      return; 
    } else if (orgAdd === '') {
      toast.error("The company address field is required.", { theme: "light" });
      setisLoading(false);
      return; 
    } 
    var formData = {
      "company_name": orgName,
      "company_email": orgEmail,
      "company_phone": orgPhn,
      "website": orgWeb,
      "address": orgAdd,
      "timezone": orgtimes,
      "date_format": orgdates,
      "time_format": orgtimeformats,
      "week_starts": orgweekstarts,
      "locale": orglangs,
    }
    axios.post('/api/update', formData)
      .then(response => {
        toast.success(response.success, { theme: "light" });
        //realoding the page
        loadSettings();
      })
      .catch(error => {
        console.error(error);
        toast.error('Something went wrong...');
      })
      .finally(() => {
        setisLoading(false); //reloading the page
      });
  }

  const handleReset = event => {
    event.preventDefault();
    const initialDate = date.find(option => option.value === gbl.date_format);
    const initialTimeFormat = time.find(option => option.value === gbl.time_format);
    const initialLang = langOptions.find(option => option.value === gbl.locale);
    setOrgName(gbl.company_name || '');
    setOrgEmail(gbl.company_email || '');
    setOrgPhn(gbl.company_phone || '');
    setOrgWeb(gbl.website || '');
    setOrgAdd(gbl.address || '');
    setOrgTime(gbl.timezone || '');
    setOrgDate(initialDate || '');
    setOrgTimeFormat(initialTimeFormat.value || '');
    setOrgWeekStart(gbl.week_starts || '');
    setOrgLang(initialLang.value || '');
  }
  return (
    <div>
      <Card id="contactList">
        <CardHeader><h5 className="mb-0 text-primary">Update Organisation Settings</h5></CardHeader>
        <CardBody className="card-body ">
          <div className="live-preview">
            <Row className="gy-4 position-relative">

              <Col xxl={12} md={12}>
                <div>
                  <Label htmlFor="basiInput" className="form-label">Organisation Name</Label>
                  <Input
                    type="text"
                    value={orgName}
                    onChange={handleNameChange}
                    placeholder="Enter your organization name"
                    className="form-control"
                  />
                </div>
              </Col>

              <Col xxl={12} md={12}>
                <div>
                  <Label className="form-label"> Organisation Email</Label>
                  <Input
                    type="email"
                    value={orgEmail}
                    onChange={handleEmailChange}
                    placeholder="Enter your organization email"
                    className="form-control"
                  />
                </div>
              </Col>

              <Col xxl={12} md={12}>
                <div>
                  <Label htmlFor="placeholderInput" className="form-label">Organisation Phone</Label>
                  <Input
                    type="number"
                    value={orgPhn}
                    onChange={handlePhoneChange}
                    placeholder="Enter your organization phone"
                    className="form-control"
                  />
                </div>
              </Col>

              <Col xxl={12} md={12}>
                <div>
                  <Label className="form-label">Organisation Website</Label>
                  <Input
                    type="text"
                    value={orgWeb}
                    onChange={handleWebsiteChange}
                    placeholder="Enter your organization website"
                    className="form-control"
                  />
                </div>
              </Col>
              <Col xxl={12} md={12}>
                <div>
                  <Label htmlFor="readonlyInput" className="form-label">Organisation Address</Label>
                  <textarea
                    value={orgAdd}
                    onChange={handleAddressChange}
                    className="form-control"
                    rows="3"
                    placeholder="Enter your organization address">
                  </textarea>
                </div>
              </Col>

              <Col xxl={12} md={12}>
                <div >
                  <Label htmlFor="choices-single-default" className="form-label">Default Timezone</Label>
                  <Select
                    value={{ label: orgTime }}
                    onChange={handleOrgTimeChange}
                    options={values.map((timezone, index) => ({
                      value: index,
                      label: timezone,
                    }))}
                    isSearchable={true}
                  />

                </div>
              </Col>

              <Col xxl={12} md={12}>
                <div>
                  <Label htmlFor="iconInput" className="form-label">Date Format</Label>
                  <Select
                    value={orgDate}
                    onChange={handleOrgDateChange}
                    options={date}
                    isSearchable={true}
                  />
                </div>
              </Col>

              <Col xxl={12} md={12}>
                <div>
                  <Label htmlFor="iconrightInput" className="form-label">Time Format</Label>

                  <Select
                    value={selectedTimeOption}
                    onChange={handleOrgTimeFormatChange}
                    options={time}
                    isSearchable={true}
                  />

                </div>
              </Col>

              <Col xxl={12} md={12}>
                <div>
                  <Label htmlFor="exampleDataList" className="form-label">Week Start</Label>
                  <Select
                    value={selectedWeekOption}
                    onChange={handleOrgWeekFormatChange}
                    options={week}
                    isSearchable={true}
                  />
                </div>
              </Col>
              <Col xxl={12} md={12}>
                <div>
                  <Label htmlFor="exampleInputdate" className="form-label">Change Language</Label>
                  {!isInitialRender && (
                    <Select
                      value={selectedLangOption}
                      onChange={handleOrgLangFormatChange}
                      options={langOptions}
                      isSearchable={true}
                    />
                  )}
                </div>
              </Col>
              {/* for toast message */}
              <ToastContainer closeButton={false} limit={1} theme="light" />
              {/* reloading the page */}
              {/* {isUpdating && (
                <div className="spinner-overlay">
                  <ClipLoader css={override} size={35} color={'#123abc'} loading={true} />
                </div>
              )} */}
              <div className="text-center">
                <Button className="btn btn-success " onClick={handleSubmit} disabled={isUpdating}>Update</Button>&nbsp;
                <Button className="btn btn-danger" onClick={handleReset}>Reset</Button>
              </div>
            </Row>
          </div>
          {isLoading && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(255, 255, 255, 0.8)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999,
              }}
            >
              <Loader />
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  )
}
