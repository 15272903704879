import React, { useEffect, useState } from 'react';
import { Col, Input, Label, Row, CardHeader, CardBody, Accordion, AccordionItem, Collapse, Card, Container, Table } from 'reactstrap';
import Flatpickr from "react-flatpickr";
import classnames from "classnames";
import axios from 'axios';
// import * as moment from "moment";
import moment from "moment";
// import Components
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { resetProperties } from 'lord-icon-element/utils/lottie';

const OperationsReport = () => {
    const [col1, setcol1] = useState(false);
    const t_col1 = () => {
        setcol1(!col1);

    };
    const [data, setData] = useState();
    const [users, setUsers] = useState([]);
    const [reports, setReports] = useState([]);
    const [local, setlocal] = useState([]);
    const [interstate, setInterstate] = useState([]);

    //Created start date and end date
    const [createdDateStart, setCreatedDateStart] = useState();
    const [createdDateEnd, setCreatedDateEnd] = useState();
    const [selectedUserId, setSelectedUserId] = useState();
    const [org_date, setOrg_Date] = useState([]);

    const loadData = () => {
        axios.get("api/crm-operations-report")
            .then(res => {
                console.log(res,"res12344");
                setOrg_Date(res.organisation_settings);
                setUsers(res.users);
                setData(res);
                setReports(res.reports);
                setlocal(res.local);
                setInterstate(res.interstates);
                setCreatedDateEnd(moment().format(res.organisation_settings?.date_picker_format));
                setCreatedDateStart(moment().subtract(1, 'months').format(res.organisation_settings?.date_picker_format));
            })
    }
    useEffect(() => {
        loadData();
       
    }, [])

    const applyFilter = (event) => {
        event.preventDefault();
        const selectedDateFormat = org_date?.date_format;
        const filterData = {
            'user_id': selectedUserId,
            'created_date_start': createdDateStart,
            'created_date_end': createdDateEnd,
            'selected_date_format': selectedDateFormat
        }
        axios.post("api/crm-operations-get-data", filterData)
            .then(res => {
                console.log(res,"res456");
                setInterstate(res.interstates);
                setReports(res);
                setlocal(res.local);
                //loadData();
            })
            .catch(err => {
                console.log(err);
            })
    }
    const handleReset = () => {
        loadData();
        setSelectedUserId(users[0].user_id);
        setCreatedDateEnd(moment().format(org_date.date_picker_format));
        setCreatedDateStart(moment().subtract(1, 'months').format(org_date.date_picker_format));
        t_col1();

    }
    document.title = "Operations Report | Onexfort";
    console.log(users,"users");
    console.log(reports,"reports");
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Field Worker Pay Sheet" pageTitle="Onexfort" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <Accordion id="default-accordion-example">
                                        <AccordionItem>
                                            <h2 className="accordion-header" id="headingOne">
                                                <button
                                                    className={classnames("accordion-button fw-semibold", { collapsed: !col1 })} type="button" onClick={t_col1} style={{ cursor: "pointer" }} >
                                                    Search Filters
                                                </button>
                                            </h2>
                                            <Collapse isOpen={col1} className="accordion-collapse" id="collapseOne" >
                                                <div className="accordion-body">

                                                    <Row>
                                                        <Col lg={3}>
                                                            <div className="mb-3">
                                                                <Label for="createdDateinput" className="form-label">Created Date</Label>
                                                                <Flatpickr
                                                                    className="form-control" placeholder='Start Date'
                                                                    options={{
                                                                        dateFormat: org_date?.date_format,
                                                                    }}
                                                                    value={createdDateStart}
                                                                    onChange={(selectedDates) => {
                                                                        if (selectedDates.length === 1) {
                                                                            setCreatedDateStart(moment(selectedDates[0]).format(org_date?.date_picker_format));
                                                                        } else {
                                                                            setCreatedDateStart(null);
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg={3}>
                                                            <div className="mb-3">
                                                                <Label for="createdDateinput" className="form-label" >End Date</Label>
                                                                <Flatpickr
                                                                    className="form-control"
                                                                    value={createdDateEnd}
                                                                    options={{
                                                                        dateFormat: org_date?.date_format,
                                                                    }}
                                                                    onChange={(selectedDates) => {
                                                                        if (selectedDates.length === 1) {
                                                                            setCreatedDateEnd(moment(selectedDates[0]).format(org_date?.date_picker_format));
                                                                        } else {
                                                                            setCreatedDateEnd(null);
                                                                        }
                                                                    }} />
                                                            </div>
                                                        </Col>
                                                        <Col lg={3}>
                                                            <Label for="Sortingorder" className="form-label">User</Label>
                                                            <select
                                                                value={selectedUserId}
                                                                onChange={(event) => setSelectedUserId(event.target.value)}
                                                                className="form-select "
                                                                aria-label="Default select example"
                                                            >
                                                                {users.map((item) => (
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </Col>
                                                        <Col lg={5}>
                                                            <div className="text-end">
                                                                <button type="submit" className="btn form-btn-marg btn-primary"
                                                                    onClick={applyFilter}
                                                                >Apply</button>
                                                                <button type="submit" className="btn form-btn-marg btn-primary"
                                                                    onClick={handleReset}
                                                                >Reset</button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Collapse>
                                        </AccordionItem>
                                    </Accordion>
                                </CardHeader>
                                <CardBody>
                                    <Table className="table table-bordered mb-5">
                                        <thead>
                                            <tr>
                                                <th>User</th>
                                                <th>Number of Jobs</th>
                                                <th>Hours Worked</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                           
                                                <tr>
                                                    <td> {reports?.user}</td>
                                                    <td>{reports?.NumberOfJobs}</td>
                                                    <td>{reports?.Hours}</td>

                                                </tr>
                                            
                                        </tbody>
                                    </Table>
                                    <h5 className='mb-3' >Local Jobs</h5>
                                    <Table className="table table-bordered mb-5">
                                        <thead>
                                            <tr>
                                                <th>Job Date</th>
                                                <th>Job</th>
                                                <th>Customer</th>
                                                <th>Actual Hours</th>
                                                <th>Total Invoice</th>
                                                <th>Payments Received</th>
                                                <th>Balance</th>
                                                <th>Employee</th>
                                                <th>Employe Payment</th>
                                                <th>Company Profit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {local?.map((item, index) => (
                                                <tr key={index}>
                                                    <td> {item.leg_date}</td>
                                                    <td>{item.job_number}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.actual_hours}</td>
                                                    <td>$ {item.total_invoice}</td>
                                                    {
                                                        item.payment_received ? (
                                                            <td>$ {item.payment_received}</td>
                                                        ) : (
                                                            <td>$ 0.00</td>
                                                        )
                                                    }
                                                    <td> {item.balance_formatted}</td>
                                                    <td>{item.employee}</td>
                                                    <td> {item.employee_payment_formatted}</td>
                                                    <td>{item.company_profit_formatted}</td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td colSpan="3">
                                                    Total
                                                </td>
                                                <td >
                                                    {data?.global.currency_symbol} {local?.reduce((sum, item) => sum + parseInt(item.actual_hours.replace(/\$/g, '')), 0)}

                                                </td>
                                                <td >
                                                    {data?.global.currency_symbol} {local?.reduce((sum, item) => sum + item.total_invoice, 0)}
                                                </td>
                                                <td >
                                                    {data?.global.currency_symbol} {local?.reduce((sum, item) => sum + item.payment_received, 0)}
                                                </td>
                                                <td >
                                                    {data?.global.currency_symbol} {local?.reduce((sum, item) => sum + parseInt(item.balance_formatted.replace(/\$/g, '')), 0)}
                                                </td>
                                                <td></td>
                                                <td >

                                                    {data?.global.currency_symbol} {local?.reduce((sum, item) => sum + parseInt(item.employee_payment_formatted.replace(/\$/g, '')), 0)}
                                                </td>
                                                <td >

                                                    {data?.global.currency_symbol} {local?.reduce((sum, item) => sum + parseInt(item.company_profit_formatted.replace(/\$/g, '')), 0)}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <h5 className='mb-3'>Interstate Trips</h5>
                                    <Table className="table table-bordered mb-5">
                                        <thead>
                                            <tr>
                                                <th>Job Date</th>
                                                <th>Job #</th>
                                                <th>Customer</th>
                                                <th>Total Invoice</th>
                                                <th>Payments Received</th>
                                                <th>Balance</th>
                                                <th>Employee</th>
                                                <th>Employee Payment</th>
                                                <th>Company Profit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {interstate?.map((item, index) => (
                                                <tr key={index}>
                                                    <td> {item.start_date}</td>
                                                    <td> {item.job_number}</td>
                                                    <td> {item.name}</td>
                                                    <td> {item.total_invoice}</td>
                                                    <td> {item.payment_received}</td>
                                                    <td> {item.balance_formatted}</td>
                                                    <td> {item.employee}</td>
                                                    <td> {item.payment_received}</td>
                                                    <td> </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};

export default OperationsReport;
