import { Card, CardBody, Row, Col, Form } from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Button } from 'reactstrap'
import "../../../assets/scss/pages/_settings.scss";
// Import Images
import connectXero from "../../../assets/images/connectxero.jpg";
import {
  CardHeader,
} from "reactstrap"
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import Loader from "../../../Components/Common/Loader";
import { useNavigate } from "react-router-dom";

export const ConnectXero = () => {
  document.title="Connect Xero | Onexfort";
  const history = useNavigate();
  const [xeroAcc, setXeroAcc] = useState([]);
  const [myobconn, setMyobConn] = useState([]);
  const [invoice, setInvoice] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [code, setCode] = useState("");
  const [accounts, setAccounts] = useState([]);
  const [paymentAccounts, setPaymentAccount] = useState([]);
  const [paymentAccount, setSelectedPayment] = useState([]);
  const [salesAccount, setSelectedSales] = useState([]);

  const loadXero = () => {
    setisLoading(true);
    axios.get('/api/connect-xero/data')
      .then(response => {
        setXeroAcc(response.tenant_api_details);
        setMyobConn(response.myob_connected);
        setInvoice(response.invoices);
        setAccounts(response.accounts);
        setPaymentAccount(response.payment_accounts);
        setSelectedPayment(response?.tenant_api_details?.smtp_secret);
        setSelectedSales(response?.tenant_api_details?.account_key);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setisLoading(false);
      })
  }
  //*backend response*
  useEffect(async () => {
    await loadXero();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    handleCallback(urlParams.get('code'), urlParams.get('state'));
  }, []);

  //Connect with Xero button
  const handleSubmit = (event) => {
    event.preventDefault();
    axios.get('/api/connect-xero/authorize')
      .then(response => {
        const url = response.url;
        window.location.href = url; // Open the URL in the same page
      })
      .catch(error => {
        console.log(error);
      });
  }

  const handleCallback = (codedata, state) =>{
    console.log(codedata);
    if(codedata){
    axios.get("api/connect-xero/callback?code="+codedata+"&state="+state).then(response =>{
      history('/settings/connect-xero');
      loadXero();
    })
    }
  }

  const handleUpdate = () =>{
    var postData = {
      "account_id": salesAccount,
      "payment_account_id": paymentAccount,
    }
    axios.post('/api/connect-xero/storeConfig', postData).then((res)=>{
      console.log(res);
      history('/settings/connect-xero');
      loadXero();
    })
  }

  const handleDisconnect = () =>{
    axios.get("/api/connect-xero/disconnect").then((res)=>{
      history('/settings/connect-xero');
      loadXero();
    })
  }

  return (
    <div>
      <Card>
        <CardHeader className="card-header bg-white header-elements-inline" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h5 className="mb-0 text-brown" style={{ alignSelf: 'flex-start', marginTop: '10px' }}>Connect with XERO</h5>
          {xeroAcc ? (
            <a onClick={()=>handleDisconnect()} className="btn btn-outline-danger">Disconnect</a>
          ) : null}
        </CardHeader>
        <CardBody>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'center' }}>
            <img src={connectXero} className="img img-responsive img-powered-by-stripe " alt="Connect XERO" />
          </Col>
          {xeroAcc ? (
            <Row>
              <Col xl={12}>
                <div id="xero_config" action="#">
                  <div style={{ borderBottom: '1px solid rgba(0,0,0,.125)', margin: '28px 0px' }}>
                    <h6 className="card-title">Account Configuration</h6>
                  </div>
                  <Row className="form-group ">
                    <Col lg={4}>
                      <label className="form-label">Sales Account</label>
                    </Col>
                    <Col lg={4}>
                      <select name="account_id" id="account_id" className="form-control" value={salesAccount} onChange={(e)=> setSelectedSales(e.target.value)}>
                        <option>Select account</option>
                        {accounts?.length > 0 && accounts?.map((res)=>{
                          return <option value={res.AccountID} key={res.AccountID}>{res.Name}</option>
                        })}
                      </select>
                    </Col>
                    <Col lg={1}>
                      {(!xeroAcc || !xeroAcc.smtp_secret || !xeroAcc.account_key) ? (
                          <i
                            className="bx bx-check"
                            style={{
                              fontSize: '24px',
                              color: '#4caf50',
                              display: 'none'
                            }}
                          ></i>
                        ) : (
                          <i
                            className="bx bx-check"
                            style={{
                              fontSize: '24px',
                              color: '#4caf50',
                            }}
                          ></i>
                        )}
                    </Col>
                  </Row>

                  <Row className="form-group row mt-2">
                    <Col lg={4}>
                      <label className="form-label ">Payments Account</label>
                    </Col>
                    <Col lg={4}>
                      <select name="payment_account_id" id="payment_account_id" className="form-control" value={paymentAccount} onChange={(e)=> setSelectedPayment(e.target.value)}>
                        <option>Select account</option>
                        {paymentAccounts?.length > 0 && paymentAccounts?.map((res)=>{
                          return <option value={res.AccountID} key={res.AccountID}>{res.Name}</option>
                        })}
                      </select>
                    </Col>
                    <Col lg={1}>
                      {(!xeroAcc || !xeroAcc.smtp_secret || !xeroAcc.account_key) ? (
                        <i
                          className="bx bx-check"
                          style={{
                            fontSize: '24px',
                            color: '#4caf50',
                            display: 'none'
                          }}
                        ></i>
                      ) : (
                        <i
                          className="bx bx-check"
                          style={{
                            fontSize: '24px',
                            color: '#4caf50',
                          }}
                        ></i>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={12}>
                      <hr />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3}>
                      <button id="save_config" className="btn btn-md btn-success m-l-10" onClick={()=>handleUpdate()}><i className="fa fa-check"></i>Update</button>
                    </Col>
                    <Col lg={9}>
                      <p>For product wise sale account setup <a href="{{ route('admin.products.index') }}">click here</a></p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          ) : <Row>
            <Col xl={12}>
              <p style={{ paddingTop: '15px' }}>
                Connect XERO and Onexfort will automatically add invoices, payments & customers to your XERO account.<br />
                When you click connect, a XERO window will open. There are following quick steps to do there
              </p>
            </Col>
            <Col xl={12}>
              <p style={{ paddingTop: '15px' }}>
                1- Login to your XERO account
              </p>
            </Col>
            <Col xl={12}>
              <p style={{ paddingTop: '15px' }}>
                2- Select the organization you want to sync with Onexfort
              </p>
            </Col>
            <Col xl={12}>
              <p style={{ paddingTop: '15px' }}>
                3- Authorize the connection to Onexfort
              </p>
            </Col>
            <Col xl={12}>
              <p style={{ paddingTop: '15px' }}>
                4- XERO accounts configuration
              </p>
            </Col>
            <Col xl={12}> <hr /></Col>
            <Col xl={12}>
              {myobconn ? (
                <div className="alert alert-danger">XERO is already connected!</div>
              ) : <Button color="info" onClick={handleSubmit}> Connect with Xero </Button>}  
            </Col>
          </Row>}
          <Row>
          </Row>
          {/* reloading */}
          {isLoading && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(255, 255, 255, 0.8)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999,
              }}
            >
              <Loader />
            </div>
          )}
        </CardBody>
      </Card>
      {invoice.length > 0 && (
      <Card>
        <CardBody>
          <Row>
            <Col xl={12}>
              <div style={{borderBottom:'1px solid rgba(0,0,0,.125)', margin: '28px 0px'}}>
                <h6 className="card-title">In Queue Invoices</h6>
              </div> 
              <table>
                <thead>
                  {/* pending */}
                    {/* @include('admin.connect-xero.inqueue-invoice') */}
                </thead>
              </table>
            </Col>
          </Row>
          
        </CardBody>
      </Card>
      )}
    </div>
  )
}
