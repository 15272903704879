import React from 'react';
import { Container } from 'reactstrap';
import { Card, CardBody, Col, Nav, NavItem, NavLink, Row, Alert, UncontrolledAlert, TabContent, TabPane, UncontrolledTooltip } from "reactstrap";
// import Components
import BreadCrumb from '../../Components/Common/BreadCrumb';
import TableContainer from "../../Components/Common/TableContainer";
import Loader from "../../Components/Common/Loader";
import dummyImg from "../../assets/images/users/user-dummy-img.jpg";
import { useEffect, useState, useCallback, useMemo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DeleteModal from "../../Components/Common/DeleteModal";

import {
    CardHeader,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Label,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    ModalFooter,
    Table,
    FormFeedback
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { get_cookie } from '../../helpers/get_cookie';
import {
    getContacts as onGetContacts,
    addNewContact as onAddNewContact,
    updateContact as onUpdateContact,
    deleteContact as onDeleteContact,
} from "../../store/actions";
import { toast, ToastContainer } from 'react-toastify';
import { AlignRight } from 'feather-icons-react/build/IconComponents';

const Employees = () => {
    const [data, setData] = useState([]);
    const [modal, setModal] = useState(false);
    const [tag, setTag] = useState([]);
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
    const [deleteModalMulti, setDeleteModalMulti] = useState(false);
    const [isExportCSV, setIsExportCSV] = useState(false);
    const [assignTag, setAssignTag] = useState([]);
    const [isEmployeeUser, setIsEmployeeUser] = useState(false);
    //////constants for Form
    const [fname, setFName] = useState();
    const [lname, setLName] = useState();
    const [empnum, setEmpNum] = useState();
    const [phone, setPhone] = useState();
    const [hrate, setHRate] = useState();
    const [jobtype, setJobType] = useState();
    const [sysuser, setSysUser] = useState();
    const [esign, setESign] = useState();
    const [roles, setRoles] = useState();
    const [sysmodules, setSysModules] = useState();
    const [roleid, setRoleId] = useState();
    const [sysid, setSysId] = useState();
    const [allowqfee, setAllowQfee] = useState();
    const [active, setActive] = useState();
    const [email, setEmail] = useState();
    const [pwd, setpwd] = useState();
    const [confirmpwd, setConfirmpwd] = useState();
    const [emp_id, setId] = useState();
    const error = null;
    const [deleteItemId, setDeleteItemId] = useState(null);

    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
            setContact(null);
        } else {
            setModal(true);
            setTag([]);
            setAssignTag([]);
        }
    }, [modal]);
    const handleContactClicks = () => {
        setContact("");
        setIsEdit(false);
        toggle();
    };
    const toggleModal = () => {
        setModal(!modal);
    };


    function handlestag(tags) {
        setTag(tags);
        const assigned = tags.map((item) => item.value);
        setAssignTag(assigned);
    }
    const deleteCheckbox = () => {
        const ele = document.querySelectorAll(".contactCheckBox:checked");
        ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
        setSelectedCheckBoxDelete(ele);
    };
    const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const checkedAll = useCallback(() => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".contactCheckBox");

        if (checkall.checked) {
            ele.forEach((ele) => {
                ele.checked = true;
            });
        } else {
            ele.forEach((ele) => {
                ele.checked = false;
            });
        }
        deleteCheckbox();
    }, []);


    const dateFormat = () => {
        var d = new Date(),
            months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return (d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear());
    };

    const [isEdit, setIsEdit] = useState(false);

    const [contact, setContact] = useState([]);


    const dispatch = useDispatch();

    const handleOrderClick = (id) => {
        setIsEdit(true);
        toggle();

        if (id !== '') {
            console.log("edit" + id);
            // axios.post('/api/products/delete',id)
            axios.post(`/api/list-employees/edit/${id}`)
                .then((response) => {
                    console.log(response);
                    setFName(response.employee.first_name || '')
                    setLName(response.employee.last_name || '')
                    setEmpNum(response.employee.employee_number || '')
                    setPhone(response.employee.mobile || '')

                    setIsEmployeeUser(response.employee.is_system_user)
                    setEmail(response.employee.email || '')
                    setESign(response.employee.email_signature || '')
                    setRoleId(response.employee.role_id)
                    // const selectedWareOption = status.find(option => option.value === response.method.status);
                    //   setRoleId({ value: selectedWareOption.value, label: selectedWareOption.label});
                    setSysId(response.employee.sys_job_type)
                    setHRate(response.employee.hourly_rate || '')
                    setId(response.employee.id)
                })
                .catch((error) => {
                    console.error('Delete error:', error);
                    // Handle delete error
                });
        }
    };
    console.log(isEmployeeUser, "valuef")
    const toastError = () => {
        toast.error("The email has already been taken.", { theme: "light" });
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();

        // const inputElement = document.getElementById("hourly_pricing_min_hours").value;

        //    const id=id;
        var formData = {
            // "id" :'80',
            "first_name": fname,
            "last_name": lname,
            "employee_number": empnum,
            "mobile": phone,
            "hourly_rate": hrate,
            "role_id": roleid,
            "sys_job_type": sysid,
            "email_signature": esign,
            "is_system_user": isEmployeeUser,
            "status": active,
            "email": email,
            "password": pwd,
            "confirm_password": confirmpwd

        }


        console.log(formData, "form")
        console.log(emp_id, "id");
        if (isEdit) {
            console.log("edit");
            axios.post(`${"api/list-employees/update/"}${emp_id}`, formData)

                .then((response) => {
                    // Handle success
                    setModal(false);
                    console.log('Save successful', response.data);
                    toast.success("Saved Successfully", { theme: "light" });
                    loaddata();
                })
                .catch((error) => {
                    // Handle error
                    console.error('Error saving data:', error);
                });

        } else {
            console.log("save");
            // Handle save logic
            if (isEmployeeUser === 'Y') {
                if (confirmpwd == '') {
                    toast.error("The Password field is required.", { theme: "light" });
                    return;
                }

                if (pwd != confirmpwd) {
                    toast.error("Password doesn't match.", { theme: "light" });
                    return;
                }
            }
            if (formData.password.length < 6) {
                toast.error("The password must be at least 6 characters.", { theme: "light" });
                return;
            }
            else if (formData.first_name === "") {
                toast.error("Enter the First name.", { theme: "light" });
            }
            else if (formData.last_name === "") {
                toast.error("Enter the Last name.", { theme: "light" });
            }
            else if (formData.employee_number === "") {
                toast.error("Enter the Employee number.", { theme: "light" });
            }
            else if (formData.mobile === "") {
                toast.error("Enter the Mobile number.", { theme: "light" });
            }
            else {
                axios.post('/api/list-employees/store', formData)

                    .then((response) => {

                        // Handle success
                        setModal(false);
                        console.log(response)
                        console.log('Save successful', response.data);
                        toast.success("Saved Successfully", { theme: "light" });
                        loaddata();
                    })
                    .catch((error) => {
                        // Handle error
                        if (error == "Request failed with status code 422") {
                            toastError()
                        }
                        console.log('Error saving data:', error);
                    });
            }
        }
    }
    const onClickDelete = (itemId) => {
        setDeleteItemId(itemId);
        setDeleteModal(true);
    };

    const crmcontacts = data;
    const isContactSuccess = true;
    useEffect(() => {
        loaddata();
    }, []);
    const loaddata = () => {

        axios
            .get("/api/list-employees/data")
            .then((res) => {
                console.log(res.data);
                setData(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
        axios
            .get("/api/list-employees/create")
            .then((res) => {
                console.log(res, "create");
                setRoles(res.roles);
                setRoleId(res.roles[0].id)
                setSysModules(res.sysModules)
                // setData(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
        setEmail("");
    };

    // const handleContactClick = useCallback((arg) => {
    //     const contact = arg;

    //     setContact({
    //         _id: contact._id,
    //         // img: contact.img,
    //         name: contact.name,
    //         company: contact.company,
    //         email: contact.email,
    //         designation: contact.designation,
    //         phone: contact.phone,
    //         lead_score: contact.lead_score,
    //         last_contacted: contact.date,
    //         // time: contact.time,
    //         tags: contact.tags,
    //     });

    //     setIsEdit(true);
    //     toggle();
    // }, [toggle]);

    console.log(roles, "shhs")
    const handleDeleteOrder = async () => {
        console.log("deleteItemId", deleteItemId);
        if (deleteItemId) {
            var orderIds = deleteItemId;
            // axios.get(`/api/products/delete/"}${orderIds}`)
            axios.post(`${"api/list-employees/delete/"}${orderIds}`)
                .then(response => {
                    console.log(response.message);
                    toast.success(response.message, { theme: "light" });
                    loaddata();
                    setDeleteModal(false)
                })
                .catch(error => {
                    console.error(error);
                });
        }
    };
    // console.log("dataa", data)
    const smscolumn = useMemo(
        () => [

            {
                Header: "Employee",
                accessor: "employee_number",
                filterable: false,

            },
            {
                Header: "First Name",
                accessor: "first_name",
                filterable: false,
            },

            {
                Header: "Last Name",
                accessor: "last_name",
                filterable: false,
            },

            {
                Header: "Mobile",
                accessor: "mobile",
                filterable: false,
            }, {
                Header: "System User",
                accessor: "is_system_user",
                filterable: false,
            },

            {
                Header: "Email",
                accessor: "email",
                filterable: false,
            },

            {
                Header: "System Role",
                accessor: "display_name",
                filterable: false,
            },


            {
                Header: "Action",
                Cell: (cellProps) => {
                    const { id } = cellProps.row.original;
                    return (

                        <ul className="list-inline hstack gap-2 mb-0">

                            <li className="list-inline-item">
                                <UncontrolledDropdown>
                                    <DropdownToggle
                                        href="#"
                                        className="btn btn-soft-secondary btn-sm dropdown"
                                        tag="button"
                                    >
                                        <i className="ri-more-fill align-middle"></i>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">

                                        <DropdownItem
                                            className="dropdown-item edit-item-btn"
                                            href="#"
                                            onClick={() => handleOrderClick(id)}
                                        >
                                            <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                                            Edit
                                        </DropdownItem>
                                        <DropdownItem
                                            className="dropdown-item remove-item-btn"
                                            href="#"
                                            onClick={() => onClickDelete(id)}
                                        // onClick={() => { const contactData = cellProps.row.original; onClickDelete(contactData); }}
                                        >
                                            <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                                            Delete
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </li>
                        </ul>
                    );
                },
            },
        ],
        []
    );


    useEffect(() => {
        const value = isEmployeeUser ? 'Y' : 'N';
        setAllowQfee(value);
    }, [isEmployeeUser]);

    console.log(allowqfee, "hsn");
    const handleCheckboxChangeStripe = (e) => {
        const isChecked = e.target.checked;
        const value = isChecked ? 'active' : 'deactive';

        setActive(value);
    };
    const handleCheckboxChange = (e) => {
        const isCheck = e.target.checked;
        const value = isCheck ? 'Y' : 'N';

        setIsEmployeeUser(value);
    };


    console.log(isEmployeeUser, "acttt");

    document.title = "Employees | Onexfort";
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteOrder}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Employees" pageTitle="Onexfort" />

                </Container>

                <Container fluid>
                    <Card>
                        <CardHeader>
                            <div className="d-flex align-items-center flex-wrap gap-2">
                                <div className="flex-grow-1">
                                    <button
                                        className="btn btn-brown add-btn"
                                        onClick={() => {
                                            setModal(true);
                                            setFName('');
                                            setLName('');
                                            setActive('active')
                                            setESign('')
                                            setEmail(" ");
                                            setEmpNum('')
                                            setPhone('')
                                            setHRate('0.00')
                                            setpwd('')
                                            setConfirmpwd('')
                                            setSysId('')
                                            setIsEdit(false);

                                        }}
                                    >
                                        <i className="ri-add-fill me-1 align-bottom"></i> Add
                                        Employee
                                    </button>
                                </div>
                                <div className="flex-shrink-0">
                                    <div className="hstack text-nowrap gap-2">
                                        {isMultiDeleteButton && <button className="btn btn-danger"
                                            onClick={() => setDeleteModalMulti(true)}
                                        ><i className="ri-delete-bin-2-line"></i></button>}
                                    </div>
                                </div>
                            </div>
                        </CardHeader>
                    </Card>
                    <Col xxl={12}>
                        <Card id="contactList">
                            <CardBody className="pt-0">
                                <div>
                                    {isContactSuccess && crmcontacts.length ? (
                                        <TableContainer
                                            columns={smscolumn}
                                            data={(crmcontacts || [])}
                                            isGlobalFilter={true}
                                            isAddUserList={false}
                                            customPageSize={50}
                                            className="custom-header-css"
                                            divClass="table-responsive table-card mb-3"
                                            tableClass="align-middle table-nowrap"
                                            theadClass="table-light"
                                            handleContactClick={handleContactClicks}
                                            isContactsFilter={true}
                                            SearchPlaceholder="Search for contact..."
                                        />
                                    ) : (<Loader error={error} />)
                                    }

                                </div>

                                <Modal id="showModal" isOpen={modal} toggle={toggle} centered size="xl">
                                    <ModalHeader className="bg-soft-info p-3" toggle={toggle}>
                                        {!!isEdit ? "Edit SMS" : "Add Employee"}
                                    </ModalHeader>

                                    <Form className="tablelist-form" onSubmit={(e) => {
                                        e.preventDefault();
                                        // validation.handleSubmit();
                                        return false;
                                    }}>
                                        <ModalBody>
                                            <Input type="hidden" id="id-field" />
                                            <Input
                                                type="hidden"
                                                value={emp_id}
                                                //   onChange={(e) => setId(e.target.value)}
                                                className="form-control"
                                                placeholder=""
                                                id="warehouseName"
                                                readOnly
                                            />
                                            <Row className="g-3">
                                                <Col lg={6}>

                                                    <div>
                                                        <Label
                                                            htmlFor="name-field"
                                                            className="form-label"
                                                        >First Name

                                                        </Label>
                                                        <Input
                                                            name="name"
                                                            id="customername-field"
                                                            className="form-control"
                                                            placeholder="Enter Name"
                                                            type="text" value={fname}
                                                            onChange={(e) => setFName(e.target.value)} />


                                                    </div>
                                                </Col>
                                                <Col lg={6}>

                                                    <div>
                                                        <Label
                                                            htmlFor="name-field"
                                                            className="form-label"
                                                        >
                                                            Last Name
                                                        </Label>
                                                        <Input
                                                            name="lname"
                                                            className="form-control"
                                                            type="text" value={lname}
                                                            onChange={(e) => setLName(e.target.value)} />


                                                    </div>
                                                </Col>
                                                <Col lg={6}>


                                                    <div>
                                                        <Label
                                                            htmlFor="name-field"
                                                            className="form-label"
                                                        >
                                                            Employee Number
                                                        </Label>
                                                        <Input
                                                            name="enum"
                                                            id="customername-field"
                                                            className="form-control"
                                                            value={empnum}
                                                            onChange={(e) => setEmpNum(e.target.value)}
                                                            type="text" />

                                                    </div><br />




                                                </Col>

                                                <Col lg={6}>
                                                    <div>
                                                        <Label
                                                            htmlFor="name-field"
                                                            className="form-label"
                                                        >
                                                            Mobile
                                                        </Label>
                                                        <Input
                                                            name="phone"
                                                            id="phone-field"
                                                            className="form-control"
                                                            placeholder="Enter Phone No."
                                                            type="text"
                                                            value={phone}
                                                            onChange={(e) => setPhone(e.target.value)}
                                                        />

                                                    </div>
                                                </Col>

                                                <Col lg={6}>
                                                    <p><b>Is this Employee a user</b></p>
                                                    <div className='mt-3 d-flex align-items-center'>
                                                        <div className="form-check form-switch form-switch-lg" dir="ltr">

                                                            <Input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id="customSwitchsizelg"
                                                                checked={isEmployeeUser === "Y"}
                                                                onChange={handleCheckboxChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>

                                                {isEmployeeUser == "Y" && (
                                                    <div>
                                                        <Col lg={6}>
                                                            <div>
                                                                <Label htmlFor="name-field" className="form-label">
                                                                    Email (will be used for system login)
                                                                </Label>
                                                                <Input
                                                                    name="name"
                                                                    id="customername-field"
                                                                    className="form-control"
                                                                    placeholder="Enter email"
                                                                    type="email" value={email}
                                                                    onChange={(e) => setEmail(e.target.value)} />
                                                            </div>
                                                            <br />
                                                        </Col>
                                                        <Row>
                                                            <Col lg={6}>
                                                                <span className='hstack gap-5'>
                                                                    <Label htmlFor="name-field" className="form-label">
                                                                        Password
                                                                    </Label>
                                                                    <div className='mb-2 justify-content-end d-flex' style={{ marginLeft: "auto" }}>
                                                                        <input type="checkbox" onClick={togglePasswordVisibility}></input>
                                                                        <label className="ms-1">Show Password</label>
                                                                    </div>
                                                                </span>

                                                                <Input
                                                                    name="name"
                                                                    id="customername-field"
                                                                    className="form-control"
                                                                    placeholder="Enter number"
                                                                    value={pwd}
                                                                    type={showPassword ? 'text' : 'password'}
                                                                    onChange={(e) => setpwd(e.target.value)}
                                                                />

                                                            </Col>
                                                            <Col lg={6}>
                                                                <div>
                                                                    <Label htmlFor="name-field" className="form-label">
                                                                        Confirm Password
                                                                    </Label>
                                                                    <Input

                                                                        name="name"
                                                                        id="customername-field"
                                                                        type="password"
                                                                        className="form-control"
                                                                        placeholder="Enter password"
                                                                        value={confirmpwd}
                                                                        onChange={(e) => setConfirmpwd(e.target.value)}
                                                                    />
                                                                    {isEdit && (
                                                                        <label>Leave blank to keep your current password.</label>
                                                                    )}

                                                                </div>

                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={6} className='mt-3'>
                                                                <Label>System Role </Label>
                                                                <select
                                                                    value={roleid}
                                                                    onChange={(e) => setRoleId(e.target.value)}
                                                                    className="form-select mb-3"
                                                                >

                                                                    {roles && roles.length > 0 ? (
                                                                        roles.map((role, index) => (
                                                                            <option key={index} value={role.id}>{role.display_name}</option>
                                                                        ))
                                                                    ) : (
                                                                        <option value="">No category types  found</option>
                                                                    )}
                                                                </select>
                                                            </Col>
                                                            <Col lg={6} className='mt-3'>
                                                                <Label>System Job Type </Label>
                                                                <select
                                                                    value={sysid}
                                                                    onChange={(e) => setSysId(e.target.value)}
                                                                    className="form-select mb-3"
                                                                >
                                                                    <option value="">Select System Job Type</option>
                                                                    <option></option>
                                                                    {sysmodules && sysmodules.length > 0 ? (
                                                                        sysmodules.map((role, index) => (
                                                                            <option key={index} value={role.id}>{role.options}</option>
                                                                        ))
                                                                    ) : (
                                                                        <option value="">No category types  found</option>
                                                                    )}
                                                                </select>
                                                            </Col>
                                                        </Row>
                                                        <Col lg={6}>
                                                            <div>
                                                                <Label htmlFor="name-field" className="form-label">
                                                                    Hourly Rate
                                                                </Label>
                                                                <Input
                                                                    name="name"
                                                                    id="customername-field"
                                                                    className="form-control"
                                                                    p laceholder="Enter number"
                                                                    type="number"
                                                                    value={hrate}
                                                                    onChange={(e) => setHRate(e.target.value)}

                                                                />

                                                            </div>
                                                            <br />
                                                        </Col>

                                                        {/* Text Area */}
                                                        <Col lg={12} className='mb-3'>
                                                            <Label>Email Signature</Label>
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                data={esign}
                                                                onReady={(editor) => {
                                                                }}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    setESign(data);
                                                                }}
                                                            />
                                                        </Col>

                                                        <Col lg={12}>
                                                            <label>Active</label>
                                                            <div className='mt-3 d-flex align-items-center'>
                                                                <div className="form-check form-switch form-switch-lg" dir="ltr">
                                                                    {/* <Input type="checkbox" className="form-check-input" id="customSwitchsizelg" checked="" /> */}
                                                                    <input type="checkbox" className="form-check-input" id="customSwitchsizelg" value="Y"
                                                                        checked={active === 'active'} onChange={handleCheckboxChangeStripe} />

                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </div>

                                                )}

                                            </Row>
                                        </ModalBody>
                                        <ModalFooter>
                                            <div className="hstack gap-2 justify-content-end">
                                                <button type="button" className="btn btn-light" onClick={() => { setModal(false); }} > Close </button>
                                                <button type="submit" className="btn btn-success" id="add-btn" onClick={handleFormSubmit}> {!!isEdit ? "Update" : "Save"} </button>
                                            </div>
                                        </ModalFooter>
                                    </Form>
                                </Modal>
                                <ToastContainer closeButton={false} limit={1} />
                            </CardBody>
                        </Card>
                    </Col>

                </Container>
            </div>
        </React.Fragment>
    );
};

export default Employees;
