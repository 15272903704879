import { Card, CardBody, Col, Row, Form } from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Button } from 'reactstrap';
import "../../../assets/scss/pages/_settings.scss";
// Import Images
import connectmyob from "../../../assets/images/connectmyob.png"
import {
  CardHeader,
} from "reactstrap";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import Loader from "../../../Components/Common/Loader";
import { useNavigate } from 'react-router-dom';

export const ConnectMYOB = () => {
  document.title="Connect MYOB | Onexfort";
  const history = useNavigate();
  const [myobAcc,setMyobAcc] =useState([]);
  const [myobConn, setMyobConn] =useState([]);
  const [myobComp, setMyobComp] =useState([]);
  const [invoice, setInvoice] = useState([]);
  const [businessname, setBusinessName] = useState("");
  const [accounts, setAccounts] = useState("");
  const [callbackURL, setCallbackURL] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [taxCodes, setTaxCodes] = useState("");
  const [salesAccount, setSalesAccount] = useState("");
  const [paymentsAccount, setPaymentsAccount] = useState("");
  const [taxcode, setTaxCode] = useState("");


  const loadMyob = () => {
    setisLoading(true);
    axios.get('/api/connect-myob/data')
      .then(response => {
        console.log(response);
        setMyobAcc(response.tenant_api_details);
        setMyobConn(response.xeroconnected);
        setMyobComp(response.myob_companies);
        setInvoice(response.invoices);
        setBusinessName(response.tenant_api_details?.url);
        setSalesAccount(response.tenant_api_details?.account_key);
        setPaymentsAccount(response.tenant_api_details?.smtp_secret);
        setTaxCode(response.tenant_api_details?.smtp_user);
        setAccounts(response.accounts?.Items);
        setTaxCodes(response.taxcodes?.Items);
      })
      .catch(error => {
        console.log(error);
      }).finally(() => {
        setisLoading(false);
      })
  }
  
  const saveCompany = () =>{
    var postData = {
      "url": businessname
    }
    axios.post("/api/connect-myob/storeCompanyDetail", postData).then((res)=>{
      console.log(res);
      history('/settings/connect-myob');
      loadMyob()
    });
  }

  useEffect(() => {
    loadMyob();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    handleCallback(urlParams.get('code'));
  }, []);

  //Connect with Xero button
  const handleSubmit = (event) => {
    event.preventDefault();
    axios.get('/api/connect-myob/data')
      .then(response => {
        const url = response.url;
        setCallbackURL(url);
        window.location.href = url;
      })
      .catch(error => {
        console.log(error);
      });
  }


  const handleCallback = (codedata) =>{
    console.log(codedata);
    if(codedata){
    axios.get("api/connect-myob/callback?code="+codedata).then(response =>{
      history('/settings/connect-myob');
      loadMyob();
    })
    }
  }

  const handleUpdate = () =>{
    var postData = {
      "account_id": salesAccount,
      "payment_account_id": paymentsAccount,
      "smtp_user": taxcode
    }
    axios.post('/api/connect-myob/storeConfig', postData).then((res)=>{
      console.log(res);
      history('/settings/connect-myob');
      loadMyob();
    })
  }

  const handleDisconnect = () =>{
    axios.get("/api/connect-myob/disconnect").then((res)=>{
      history('/settings/connect-myob');
      loadMyob();
    })
  }

  return (
    <div>
      <Card>
        <CardHeader className="card-header bg-white header-elements-inline" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h5 className="mb-0 text-primary" style={{ alignSelf: 'flex-start', marginTop: '10px' }}>Connect MYOB</h5>
          {myobAcc ? (
            <a onClick={()=>handleDisconnect()} className="btn btn-outline-danger">Disconnect</a>
          ) : null}
        </CardHeader>
        <CardBody>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'center' }}>
              <img src={connectmyob} className="img img-responsive img-powered-by-stripe" alt="Connect MYOB" />
          </Col>
          {myobAcc ? (
            <Row>
              <Col xl={12}>
                {(!myobAcc || !myobAcc.url) ? (
                  <div id="myob_company_detail" action="#">
                    <div style={{borderBottom:'1px solid rgba(0,0,0,.125)', margin: '28px 0px'}}>
                        <h6 className="card-title">MYOB Business</h6>
                    </div>  
                    <Row className="form-group ">
                      <Col lg={4}>
                          <label className="form-label">Business Name</label>
                        </Col>
                        <Col lg={4}>
                          <select name="url" id="url" className="form-control col-lg-5" onChange={(e) => {
                            setBusinessName(e.target.value);              
                          }} value={businessname}>
                              <option>Select</option>  
                              {myobComp?.map((res)=>{
                                return (<option value={res.Uri} key={res.id}>{res.Name}</option>)
                              })}    
                          </select>
                        </Col>
                        <Col lg={1}>
                          {(!myobAcc || !myobAcc.smtp_secret || !myobAcc.account_key) ? (
                              <i
                                className="bx bx-check"
                                style={{
                                  fontSize: '24px',
                                  color: '#4caf50',
                                  display: 'none'
                                }}
                              ></i>
                            ) : (
                              <i
                                className="bx bx-check"
                                style={{
                                  fontSize: '24px',
                                  color: '#4caf50',
                                }}
                              ></i>
                            )}
                        </Col>
                    </Row>
                    <Row>
                      <Col xl={12}>
                        <hr />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={10}></Col>
                      <Col lg={2}>
                        <button id="save_company_detail" onClick={()=>saveCompany()} className="btn btn-md btn-success m-l-10">Save & Next <i className="bx bx-angle-double-right"></i></button> 
                      </Col>
                    </Row>
                  </div>
                 ) : ( 
                  <div id="myob_company_detail" action="#">
                    <div style={{borderBottom:'1px solid rgba(0,0,0,.125)', margin: '28px 0px'}}>
                        <h6 className="card-title">Account Configuration</h6>
                    </div> 
                    <Row className="form-group mt-2">
                      <Col lg={4}>
                        <label className="form-label mt-2">Sales Account </label>
                      </Col>
                      <Col lg={4}>
                      <select name="account_id" id="account_id" className="form-control" value={salesAccount} onChange={(e) => {
                            setSalesAccount(e.target.value);              
                          }}>
                          <option>Select account</option>   
                          {accounts?.map((res)=>{
                            if(res.Classification != "Income")
                            return(<option value={res.UID} key={res.DisplayID}>{ res.DisplayID+' - '+res.Name}</option>)

                          })}
                        </select>
                      </Col>
                      <Col lg={1}>
                        {(!myobAcc || !myobAcc.smtp_secret || !myobAcc.account_key) ? (
                            <i
                              className="bx bx-check"
                              style={{
                                fontSize: '24px',
                                color: '#4caf50',
                                display: 'none'
                              }}
                            ></i>
                          ) : (
                            <i
                              className="bx bx-check"
                              style={{
                                fontSize: '24px',
                                color: '#4caf50',
                              }}
                            ></i>
                          )}
                      </Col>
                   </Row>
                   <Row className="form-group row mt-2">
                      <Col lg={4}>
                        <label className="form-label mt-2">Payments Account </label>
                      </Col>
                      <Col lg={4}>
                        <select name="account_id" id="account_id" className="form-control" value={paymentsAccount} onChange={(e) => {
                            setPaymentsAccount(e.target.value);              
                          }}>
                          <option>Select account</option> 
                          {accounts?.map((res)=>{
                            if(res.Classification != "Bank")
                            return(<option value={res.UID} key={res.DisplayID}>{ res.DisplayID+' - '+res.Name}</option>)

                          })}  
                        </select>
                      </Col>
                      <Col lg={1}>
                        {(!myobAcc || !myobAcc.smtp_secret || !myobAcc.account_key) ? (
                            <i
                              className="bx bx-check"
                              style={{
                                fontSize: '24px',
                                color: '#4caf50',
                                display: 'none'
                              }}
                            ></i>
                          ) : (
                            <i
                              className="bx bx-check"
                              style={{
                                fontSize: '24px',
                                color: '#4caf50',
                              }}
                            ></i>
                          )}
                      </Col>
                   </Row>
                   <Row className="form-group row mt-2">
                      <Col lg={4}>
                        <label className="form-label mt-2">Tax Code </label>
                      </Col>
                      <Col lg={4}>
                        <select name="smtp_user" id="smtp_user" className="form-control" value={taxcode} onChange={(e) => {
                            setTaxCode(e.target.value);              
                          }}>
                            <option>Select tax</option> 
                            {taxCodes?.map((res)=>{
                            if(res.Classification != "Bank")
                            return(<option value={res.UID} key={res.DisplayID}>{ res.Code+' - '+res.Type+"("+res.Rate +")"}</option>)

                          })}  
                        </select>
                      </Col>
                      <Col lg={1}>
                        {(!myobAcc || !myobAcc.smtp_secret || !myobAcc.account_key) ? (
                            <i
                              className="bx bx-check"
                              style={{
                                fontSize: '24px',
                                color: '#4caf50',
                                display: 'none'
                              }}
                            ></i>
                          ) : (
                            <i
                              className="bx bx-check"
                              style={{
                                fontSize: '24px',
                                color: '#4caf50',
                              }}
                            ></i>
                          )}
                      </Col>
                   </Row>
                   <Row>
                    <Col xl={12}>
                      <hr />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={10}></Col>
                    <Col lg={2}>
                    <button id="save_config" className="btn btn-md btn-success m-l-10" onClick={()=>handleUpdate()}><i className="bx bx-check"></i>Update</button>                                        
                    </Col>
                  </Row>
                  </div>
                )}
              </Col>
            </Row>
          ) : <Row>
            <Col xl={12}>
              <p style={{paddingTop:'15px'}}>
                  Connect MYOB and Onexfort will automatically add invoices, payments & customers to your MYOB account.<br/>
                  When you click connect, a MYOB window will open. There are following quick steps to do there
              </p>
            </Col>
            <Col xl={12}>
              <p style={{paddingTop:'15px'}}>
                  1- Login to your MYOB account
              </p>
            </Col>
            <Col xl={12}>
              <p style={{paddingTop:'15px'}}>
                  2- Select the organization you want to sync with Onexfort
              </p>
            </Col>
            <Col xl={12}>
              <p style={{paddingTop:'15px'}}>
                  3- Authorize the connection to Onexfort
              </p>
            </Col>
            <Col xl={12}>
              <p style={{paddingTop:'15px'}}>
                  4- MYOB accounts configuration
              </p>
            </Col>
            <Col xl={12}>
             <hr/>
            </Col>
            <Col xl={12}>
              {myobConn ? (
                  <div className="alert alert-danger">XERO is already connected!</div>
                ) : <Button color="success" onClick={handleSubmit}> Connect  </Button>}  
            </Col>
          </Row>
          } 
        </Row>
        {/* reloading */}
        {isLoading && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(255, 255, 255, 0.8)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999,
              }}
            >
              <Loader />
            </div>
          )}
        </CardBody>
      </Card>
      {invoice.length > 0 && (
      <Card>
        <CardBody>
          <Row>
            <Col xl={12}>
              <div style={{borderBottom:'1px solid rgba(0,0,0,.125)', margin: '28px 0px'}}>
                <h6 className="card-title">In Queue Invoices</h6>
              </div> 
              <table>
                <thead>
                  {/* pending */}
                    {/* @include('admin.connect-xero.inqueue-invoice') */}
                </thead>
              </table>
            </Col>
          </Row>
        </CardBody>
      </Card>
      )}
    </div>
  )
}
