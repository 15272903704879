import React, { useState,useRef, useCallback, useEffect, useMemo } from 'react';
import {
  Card, CardBody, CardHeader, Col, Container, Row, Label, Input, Form, FormFeedback, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu, Button,
  DropdownItem,
} from 'reactstrap';
import { isEmpty } from "lodash";
import { Link } from "react-router-dom";
import axios from 'axios';

// import Components
import BreadCrumb from '../../../Components/Common/BreadCrumb';

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import Select from "react-select";

//Import actions

import Loader from "../../../Components/Common/Loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Import Images
import dummyImg from "../../../assets/images/users/user-dummy-img.jpg";

import TableContainer from "../../../Components/Common/TableContainer";
import { get_cookie } from '../../../helpers/get_cookie';
import { FormGroup } from 'reactstrap';
import DeleteModal from "../../../Components/Common/DeleteModal";


export const Products = () => {
   document.title="Products | Onexfort";
  const [customerTypes, setCustomerTypes] = useState();
  const [productTypes, setProductTypes] = useState();
  const [productCategories, setProductCategories] = useState();
  const [tax, setTax] = useState();
  const [taxid, setTaxId] = useState();
  const [data, setData] = useState();
  const [name, setName] = useState('');
  const [price,setPrice]= useState();
  const [description,setDescription]=useState();
  const [categoryid,setCategoryId] = useState();
  const [id, setId] = useState('');
  const [names,setNames]= useState({ name: '' });
  const [custtype,setCustType] = useState('');
  const [prodtype, setProdType] = useState();
  const [customers, setCustomers] = useState();
  const [customerid, setCustomerId] = useState(0);
  const { xeroConnected, myobConnected, accounts, product, myobAccounts, myobTenantApiDetails ,myob_tenant_api_details,myob_accounts} = useState('')
  const [stockable, setStockable] = useState("");
  const [hourly, setHourly] = useState();
  const [selectedAccount, setSelectedAccount] =useState('')
  const [active, setActive] = useState("Y");
  const [activeValue, setActivetValue] = useState('Y');

  const [isLoading, setisLoading] = useState(false);


  const crmcontacts = data;  
    const loadthis = () => {
      setisLoading(true)
    const user = JSON.parse(get_cookie("authUser"));
    var tenant_id = user.tenant_id;
    var datavalue={
      "tenant_id": tenant_id
    }
    axios.post("/api/products/data", datavalue)
      .then((res) => {
        console.log(res);
        setData(res.data);
        setId(res.data.id);
        setisLoading(false)
      });

    axios.post("/api/products/create", datavalue)
    .then((res) => {
      console.log(res.customer_types[0])
      setModal(false);
      const customerTypes = res.customer_types;
      const productTypes = res.product_types;
      const productcategories = res.product_categories;
      const tax = res.taxes;
      const cust=res.customers;
      setCustType(customerTypes[0])
      setCustomers(cust);
      setTax(tax);
      setCustomerTypes(customerTypes);
      setProductCategories(productcategories);
      setProductTypes(productTypes); // Add this line to set the product types state
      setSelectedAccount(res.accounts);
      const xeroConnected = res.xero_connected;
      const myobConnected = res.myob_connected;
      const accounts = res.accounts;
      const product = res.product;
      const myobAccounts = res.myob_accounts;
      const myobTenantApiDetails = res.myob_tenant_api_details;
    })
    
    .catch((error) => {
      console.error('Error fetching customer types and product types:', error);
    });
  };
  useEffect(() => {
    loadthis();
  }, []);
  useEffect(() => {	
    const value = active ? 'Y' : 'N';	
    setActivetValue(value);	
   
  }, [active]);
  const handleActive = (event) => {
    const isCheck = event.target.checked;
    const value = isCheck ? 'Y' : 'N';	
    setActive(value);	
};
 
console.log(stockable,"stockable")

  const [isEdit, setIsEdit] = useState(false);


  //delete Conatct
  const [deleteModal, setDeleteModal] = useState(false);

  const [modal, setModal] = useState(false);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
 
    } else {
      setModal(true);
      setTag([]);
      setAssignTag([]);
    }
  }, [modal]);


  // Add Data
  const handleContactClicks = () => {
 
    setIsEdit(false);
    toggle();
  };

  const [order, setOrder] = useState([]);
  const [productId,setProductId] = useState();
  const handleOrderClick = (id) => {
    setOrder(""); 
    setIsEdit(true);
    toggle();
    setModal(true);
    if (id !== '') {
      console.log("edit"+id);
      axios.post(`/api/products/edit/${id}`)
        .then((response) => {
          console.log(response.product);
          setProductId(response.product.id)
          setStockable(response.product.stockable === "Y" ? "Y" : "");          
          setNames(response.product);
          setName(response.product.name|| '')
          setPrice(response.product.price|| '')
          setDescription(response.product.description|| '')
          setCustType(response.product.customer_type);
          setProdType(response.product.product_type);
          setCategoryId(response.product.category_id)
        })
        .catch((error) => {
          console.error('Delete error:', error);
        });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            className="form-check-input"
          />
        ),
        Cell: (cellProps) => {
          return (
            <input
              type="checkbox"
              className="contactCheckBox form-check-input"
              value={cellProps.row.original._id}
            />
          );
        },
        id: "#",
      },
      {
        Header: "Name",
        accessor: "name",
        filterable: false,
      },
      {
        Header: "Category",
        accessor: "category",
        filterable: false,
      },
      {
        Header: "Product Type",
        accessor: "productType",
        filterable: false,
      },
      {
        Header: "Price",
        accessor: "price",
        filterable: false,
      },
      {
        Header: "Customer Type",
        accessor: "customerType",
        filterable: false,
      },
      {
        Header: "Stockable",
        accessor: "stockable",
        filterable: false,
      },
    
          {
            Header: "Action",
            Cell: ({ row }) => {
              const { id } = row.original;
              return (
                <ul className="list-inline hstack gap-2 mb-0">
                  <li className="list-inline-item">
                    <button
                      className="btn btn-sm btn-soft-info edit-list"
                      onClick={() => handleOrderClick(id)}
                     
                    >
                       
                      <i className="bx bxs-pencil fs-12 pt-1"></i>
                    </button>
                  </li>
                  <li className="list-inline-item edit">
                  <Link
                    to="#"
                    className="btn btn-sm btn-soft-danger fs-13 pt-1"
                    onClick={() => onClickDelete(id)}
                    // onClick={() => deletedata(id)}
                  >
                    <i className="bx bxs-trash fs-12"></i>
                  </Link>
                  </li>
                </ul>
              );
            },
          },
 
    ],

   
  );


  const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);

  function handlestag(tags) {
    setTag(tags);
    const assigned = tags.map((item) => item.value);
    setAssignTag(assigned);
  }
  const tags = [
    { label: "Exiting", value: "Exiting" },
    { label: "Lead", value: "Lead" },
    { label: "Long-term", value: "Long-term" },
    { label: "Partner", value: "Partner" }
  ];



  const [selectedSingle, setSelectedSingle] = useState(null);
  function handleSelectSingle(selectedSingle) {
    setSelectedSingle(selectedSingle);
  }


const deletedata = (id) => {
  if (id !== '') {
    console.log(id);
    axios.post(`/api/products/delete/${id}`)
      .then((response) => {
        console.log('Delete successful');
        toast.success('Delete successfully',{theme:"light"});
        // Perform additional actions after successful deletion
      })
      .catch((error) => {
        console.error('Delete error:', error);
        // Handle delete error
      });
      loadthis();
  }
}

  const handleFormSubmit = (e) => {
    e.preventDefault();

var formData = {
  "name" :name,
  "price" :price,
  "tax_id" :taxid,
  "description" :description,
  "category_id": categoryid,
  "product_type": prodtype,
  "customer_type": custtype,
  "hourly_pricing_min_hours": hourly,
  "xero_account_id": selectedAccount,
  "customer_id": customerid,
  "stockable" :stockable, 
  "is_visible_field_workers" : active
}
console.log(formData)
if (name===""){
  toast.error('Enter Name',{theme:"light"});
}
else if (price===""){
  toast.error('Enter Price',{theme:"light"});

} else if (taxid===""){
  toast.error('Select Tax',{theme:"light"});
}
else if (categoryid===""){
  toast.error('Select Category',{theme:"light"});
}
else if (prodtype===""){
  toast.error('Select Product Type',{theme:"light"});
}
else if (custtype===""){
  toast.error('Select Customer Type',{theme:"light"});
}
else{
console.log(formData);
      if (isEdit) {
        console.log("edit");
        console.log(productId,"id");
        axios.post(`${"api/products/update/"}${productId}`, formData)
          .then((response) => {
            setModal(false);
            if (response.success) {
              toast.success("Updated Successfully",{theme:"light"});
              console.log('Update successful', response.success);
            } else {
              setModal(false);
              console.log('Response is not successful');
            }
        
        
          })
          .catch((error) => {
            // Handle error
            console.error('Error updating data:', error);
          });
          loadthis();
        }
 else {
  console.log("save");
  // Handle save logic
  axios.post('/api/products/save', formData)
    .then((response) => {
      // Handle success
      setModal(false);
      console.log('Save successful', response.data);
      loadthis();
      toast.success("Saved Successfully",{theme:"light"});
    })
    .catch((error) => {
      // Handle error
      console.error('Error saving data:', error);
    });
   
  }
  }
  }


const [deleteItemId, setDeleteItemId] = useState(null);

const onClickDelete = (itemId) => {
  setDeleteItemId(itemId);
  setDeleteModal(true);
};
const handleDeleteOrder = async () => {
  console.log("deleteItemId",deleteItemId);
  if (deleteItemId) {
    var orderIds = deleteItemId;
    axios.get(`${"api/products/delete/"}${orderIds}`)
      .then(response => {
        console.log(response.message);
        toast.success(response.message,{theme:"light"});
        // loadTable();
        loadthis();
        setDeleteModal(false)
      })
      .catch(error => {
        console.error(error);
      });
  }
};

  return (
    <div>
      <DeleteModal
  show={deleteModal}
  onDeleteClick={handleDeleteOrder}
  onCloseClick={() => setDeleteModal(false)}
/>
      {isLoading && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            background: 'rgba(255, 255, 255, 0.8)',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            zIndex: 9999,
                                        }}
                                    >
                                        <Loader />
                                    </div>
                                )}
    <Card>
      <CardHeader>
        <Button
          className="btn btn-success"
          onClick={() => {
            setModal(true);
            setNames("");
            setPrice('')
            setIsEdit(false);
            setCustType(customerTypes[0])
            setName('')
            setDescription('')
            setProdType('')
          }}
        >
          Add New Product +
        </Button>
      </CardHeader>
      <CardBody className="pt-0">
        <div>
        
            <TableContainer
            
              // columns={columns}
              columns={[
                ...columns,
                {
                  // Header: "Hidden Field",
                  accessor: "id",
                  Cell: ({ row }) => (
                    <input type="hidden" name="extraField" value={row.original.id} />
                  ),
                  filterable: false,
                },
              ]}
              data={crmcontacts || []}
              isGlobalFilter={true}
              isAddUserList={false}
              customPageSize={50}
              className="custom-header-css"
              divClass="table-responsive table-card mb-3"
              tableClass="align-middle table-nowrap"
              theadClass="table-light"
              handleContactClick={handleContactClicks}
              isContactsFilter={true} 
              SearchPlaceholder="Search for contact..."
            />
         
        </div>
{/* Edit Products */}
        <Modal id="showModal" isOpen={modal} toggle={toggle} size="lg" centered>
          <ModalHeader className="bg-soft-info p-3" toggle={toggle}>
            {!!isEdit ? "Edit Products" : "Add Products"}
          </ModalHeader>

         
           <Form className="tablelist-form" >
      <ModalBody>
        <Input type="hidden" id="id-field" />
        <Row className="g-3">
          <Col lg={6}>
            <div className="mb-3">
              <Label htmlFor="name-field" className="form-label">
                Name
              </Label>
              <input
        name="name"
        id="customername-field"
        className="form-control"
        placeholder="Enter Name"
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
            </div>
          </Col>
          <Col lg={6}>
            <div>
              <Label htmlFor="price-field" className="form-label">
                Price
              </Label>
              <Input 
                name="price"
                id="price-field"
                className="form-control"
                placeholder="Insert price without currency code."
                type="number"
                value={price}  onChange={(e) => setPrice(e.target.value)}
              />
              <span>Insert price without currency code.</span>
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label htmlFor="tax-field" className="form-label">
                Tax
              </Label>

              <select
                      value={taxid}
                      //onChange={(e) => setOpType(e)}
                      onChange={(e) => setTaxId(e.target.value)}
                      className="form-select mb-3"
                      >
                        <option>Select Tax</option>
                      {tax && tax.length > 0 ? (
                          tax.map((tax, index) => (
                          <option key={index} value={tax.id}>
                              {tax.tax_name}
                          </option>
                          ))
                      ) : (
                          <option value="">No  found</option>
                      )}
                      </select>

            </div>
          </Col>
          <Col lg={6}>
            <Label htmlFor="description-field" className="form-label">
              Description
            </Label>
            <textarea
              className="form-control"
              id="description-field"
              rows="3"
              placeholder="Enter your reason"  value={description}  onChange={(e) => setDescription(e.target.value)}
            ></textarea>
            <div>
              {"{first_name}, {last_name}, {pickup_suburb}, {delivery_suburb}, {pickup_address}, {delivery_address}, {mobile}, {email}, {inventory_list}"}
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label htmlFor="category-field" className="form-label">
                Product Category
              </Label>
              <select
                      value={categoryid}
                      onChange={(e) => setCategoryId(e.target.value)}
                      className="form-select mb-3"
                      >
                        <option value="">Select Category</option>
                      {productCategories && productCategories.length > 0 ? (
                          productCategories.map((category, index) => (
                            <option key={index} value={category.id}>{category.category_name}</option>
                          ))
                      ) : (
                          <option value="">No category types  found</option>
                      )}
                      </select>

            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label htmlFor="productType" className="form-label">Product Type</Label>
              <select className="form-select mb-3" value={prodtype} onChange={(e) => setProdType(e.target.value)}>
  <option value="">Select Type</option>
  {productTypes && productTypes.length > 0 ? (
    productTypes.map((type, index) => (
      <option key={index} value={type}>
        {type === 'Item' && 'Item - Fixed'}
        {type === 'Service' && 'Service - Hourly'}
        {type === 'Charge' && type}
      </option>
    ))
  ) : (
    <option value="">No product types found</option>
  )}
</select>

            </div>
          </Col>
          {prodtype === 'Service' && (
  <div id="hourly_pricing_min_hours_div" className="col-md-6">
    <div className="form-group">
      <label className="control-label">Minimum Hours</label>
      <input type="number" id="hourly_pricing_min_hours" step="0.01" name="hourly_pricing_min_hours" value={hourly}  onChange={(e) => setHourly(e.target.value)} className="form-control" />
    </div>
  </div>
)}

                        <Input
                          type="hidden"
                          value={id}
                          onChange={(e) => setId(e.target.value)}
                          className="form-control"
                          placeholder=""
                          id="warehouseName"
                          readOnly
                        />
          <Col md={6}>
            <div className="mb-3">
              <Label htmlFor="customerType" className="form-label">Customer Type</Label>
              <select className="form-select mb-3"  value={custtype}
                      onChange={(e) => setCustType(e.target.value)} >
                {customerTypes && customerTypes.length > 0 ? (
                  customerTypes.map((type, index) => (
                    <option key={index} value={type}>{type}</option>
                  ))
                ) : (
                  <option value="">No customer types found</option>
                )}
              </select>
              
            </div>
          </Col>
          <Col md={6}>
                <Label>Visible to field workers</Label>
                  <div className="form-check form-switch form-switch-md">
                    <Input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" 
                       onChange={handleActive}  checked={active === "Y"} />                
                  </div>
              </Col>
          { xeroConnected ? (

      
  <div className="col-md-6">
    <div className="form-group">
      <label className="control-label">Xero Sale Account</label>
      <select name="xero_account_id" id="xero_account_id" className="select2 form-control"  value={selectedAccount} onChange={(e) => setSelectedAccount(e.target.value)}>
        <option>Select account</option>
        {accounts.map((account) => (
          <option key={account.Code} value={account.Code}>
            {account.Code} - {account.Name}
          </option>
        ))}
      </select>
    </div>
  </div>

) : myobConnected ? (
  <div className="col-md-6">
    <div className="form-group">
      <label className="control-label">Myob Sale Account</label>
      <select name="myob_account_id" id="myob_account_id" className="select2 form-control">
        <option>Select account</option>
        {myob_accounts && myob_accounts.Items.map((account) => {
          if (account.Classification !== 'Income') return null;
          return (
            <option key={account.UID} value={account.UID} selected={account.UID === myob_tenant_api_details.account_key}>
              {account.DisplayID} - {account.Name}
            </option>
          );
        })}
      </select>
    </div>
  </div>
) : (
  <React.Fragment>
    <input type="hidden" name="xero_account_id" id="xero_account_id" value="0" />
    <input type="hidden" name="myob_account_id" id="myob_account_id" value="0" />
  </React.Fragment>
)}
</Row>

          <row>
          <Col lg={12}>
            <div className="mb-3">
          {custtype === 'Commercial' && (
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="control-label">Customer (leave blank if applicable for all commercial customers)</label>
                      <select name="customer_id" id="customer_id" className="form-control" value={customerid}   onChange={(e) => setCustomerId(e.target.value)}>
  <option value="0"></option>
  {customers && customers.length > 0 ? (
    customers.map((customer) => (
      <option key={customer.id} value={customer.id}>
        {customer.name}
      </option>
    ))
  ) : (
    <option value="">No customers found</option>
  )}
</select>

                    </div>
                  </div>
                </div>
            )}
</div>
</Col>
  </row>  
  {prodtype === 'Item' && (
 <div className="form-check mb-3">
 <input
    className="form-check-input"
    type="checkbox"
    id="stockable"
    value={stockable}
    checked={stockable === "Y"}
    onChange={(e) => setStockable(e.target.checked ? "Y" : "N")}
  />
 <label className="form-check-label" htmlFor="stockable">Stockable</label>
</div>
)}   
      </ModalBody>
      <ModalFooter>
        <div className="hstack gap-2 justify-content-end">
          <button type="submit" className="btn btn-success" id="add-btn"  onClick={handleFormSubmit}>
            {!!isEdit ? "Update" : "Save"}
          </button>
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              setModal(false);
            }}
          >
            Cancel
          </button>
        </div>
      </ModalFooter>
    </Form>
        </Modal>
        <ToastContainer closeButton={false} limit={1} />
      </CardBody>
    </Card>
    </div>
  );
}
