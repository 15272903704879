import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Card, CardHeader, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledTooltip, Button } from "reactstrap";
import classnames from "classnames";
import { Link } from 'react-router-dom'
import he from 'he';
// import Components
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";
import { toast, ToastContainer } from 'react-toastify';
import { isEmpty } from "lodash";
import DeleteModal from "../../../Components/Common/DeleteModal";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  ModalFooter,
  Table,
  FormFeedback

} from "reactstrap";
// Import Images
import dummyImg from "../../../assets/images/users/user-dummy-img.jpg";
import * as Yup from "yup";
import { useFormik } from "formik";
//redux
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
//Import actions
import {
  getContacts as onGetContacts,
  addNewContact as onAddNewContact,
  updateContact as onUpdateContact,
  deleteContact as onDeleteContact,
} from "../../../store/actions";
import axios from 'axios';
import {
  getOrders as onGetOrders,
  addNewOrder as onAddNewOrder,
  updateOrder as onUpdateOrder,
  deleteOrder as onDeleteOrder,
} from "../../../store/ecommerce/action";
export const StorageUnit = () => {
  document.title = "Storage Units | Onexfort";
  const isContactSuccess = true;
  const error = null;
  const [verticalTab, setverticalTab] = useState("1");
  const [contact, setContact] = useState([]);
  const toggleVertical = (tab) => {
    if (verticalTab !== tab) {
      setverticalTab(tab);
    }
  };

  // add large modals
  const [modal_unit, setmodal_unit] = useState(false);
  function tog_mod_3() {
    setmodal_unit(!modal_unit);
  }

  const [isEdit, setIsEdit] = useState(false);
  // Delete Data
  const handleDeleteContact = () => {
    if (contact) {
      dispatch(onDeleteContact(contact._id));
      setDeleteModal(false);
    }
  };

  const onClickDelete = (contact) => {
    setContact(contact);
    setDeleteModal(true);
  };

  // Add Data
  const handleContactClicks = () => {
    setContact("");
    setIsEdit(false);
    toggle();
  };
  const [data, setData] = useState([]);
  const loadTable = () => {
    axios.get("/api/storage-units/data")
      .then((res) => {
        setData(res.data);
      });
  }
  useEffect(() => {
    loadTable();
  }, []);
  const [serial_number, setSerialnumber] = useState("");
  const [name, setName] = useState("");
  const [storage_type_id, setStoragetypeid] = useState("");
  const [storageType, setStorageType] = useState("");  //Storage Type
  const [warehouseLocation, setWareHouseLocation] = useState(""); //WareHouseLocation
  const [manufacturer_serial_number, setManufacturerserialnumber] = useState("");
  const [warehouse_location, setWarehouselocation] = useState("");
  const [active, setActive] = useState("1");
  const [id, setId] = useState("");

  // Save and Update Button
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isEdit) {
      var formData = {
        "serial_number": serial_number,
        "name": name,
        "storage_type_id": storageType.value,
        "manufacturer_serial_number": manufacturer_serial_number,
        "warehouse_location": warehouseLocation.value,
        "active": active,
        "id": id,
      }
      if (isNaN(serial_number) || serial_number === '') {
        toast.error("The serial number field is required.", { theme: "light" });
        return; // Stop form submission if warehouse_name is empty
      } else if (name === '') {
        toast.error("The name field is required.", { theme: "light" });
        return;
      } else if (manufacturer_serial_number === '') {
        toast.error("The Manufacturer serial number is required.", { theme: "light" });
        return;
      }
      axios.post("api/storage-units/store", formData)
        .then(response => {
          toast.success(response.message, { theme: "light" });
          loadTable();
          tog_mod_3(true);
          setSerialnumber("");
          setName("");
          setStoragetypeid("");
          setManufacturerserialnumber("");
          setWarehouselocation("");
          setId("");
        })
        .catch(error => {
          console.error(error);
        });
    } else {
      var formData = {
        "serial_number": serial_number,
        "name": name,
        "storage_type_id": storageType.value,
        "manufacturer_serial_number": manufacturer_serial_number,
        "warehouse_location": warehouseLocation.value,
        "active": active,
        "id": id,
      }
      if (isNaN(serial_number) || serial_number === '') {
        toast.error("The serial number field is required.", { theme: "light" });
        return; // Stop form submission if warehouse_name is empty
      } else if (name === '') {
        toast.error("The name field is required.", { theme: "light" });
        return;
      } else if (manufacturer_serial_number === '') {
        toast.error("The manufacturer serial number field is required.", { theme: "light" });
        return;
      }
      axios.post("api/storage-units/update", formData)
        .then(response => {
          toast.success(response.message, { theme: "light" });
          loadTable();
          tog_mod_3(true);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // img: (contact && contact.img) || '',
      name: (contact && contact.name) || '',
      company: (contact && contact.company) || '',
      designation: (contact && contact.designation) || '',
      email: (contact && contact.email) || '',
      phone: (contact && contact.phone) || '',
      lead_score: (contact && contact.lead_score) || '',
      tags: (contact && contact.tags) || [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      company: Yup.string().required("Please Enter Company"),
      designation: Yup.string().required("Please Enter Designation"),
      email: Yup.string().required("Please Enter Email"),
      phone: Yup.string().required("Please Enter Phone"),
      lead_score: Yup.string().required("Please Enter lead_score"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateContact = {
          _id: contact ? contact._id : 0,
          // img: values.img,
          name: values.name,
          company: values.company,
          designation: values.designation,
          email: values.email,
          phone: values.phone,
          lead_score: values.lead_score,
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // update Contact 
        dispatch(onUpdateContact(updateContact));
        validation.resetForm();
      } else {
        const newContact = {
          _id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          // img: values["img"],
          name: values["name"],
          company: values["company"],
          designation: values["designation"],
          email: values["email"],
          phone: values["phone"],
          lead_score: values["lead_score"],
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // save new Contact
        dispatch(onAddNewContact(newContact));
        validation.resetForm();
      }
      toggle();
    },
  });
  const [selectedSingle, setSelectedSingle] = useState(null);
  const [storage_types, setStoragetypes] = useState([]);
  const [storage_warehouse, setStoragewarehouse] = useState([]);
  useEffect(() => {
    axios.get("/api/storage-units/create")
      .then((response) => {
        setStoragetypes(response.storage_types);
        setStoragewarehouse(response.storage_warehouse);
      });
  }, []);
  const SingleOptions = [
  ];
  const handleStorageType = (selectedOption) => {
    if (selectedOption) {
      setStoragetypeid(selectedOption.value);
    }
  };
  const handleWarehouseLocation = (selectedOptionWareHouse) => {
    if (selectedOptionWareHouse) {
      setWarehouselocation(selectedOptionWareHouse.value);
    }
  };
  const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);

  function handlestag(tags) {
    setTag(tags);
    const assigned = tags.map((item) => item.value);
    setAssignTag(assigned);
  }

  const tags = [
    { label: "Exiting", value: "Exiting" },
    { label: "Lead", value: "Lead" },
    { label: "Long-term", value: "Long-term" },
    { label: "Partner", value: "Partner" }
  ];

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  // Date && Time Format

  const dateFormat = () => {
    var d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return (d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear());
  };
  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      dispatch(onDeleteContact(element.value));
      setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".contactCheckBox:checked");
    ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };
  const handleDeleteOrder = () => {
    var orderIds = deleteId;
    axios.get(`${"api/storage-units/destroy/"}${orderIds}`)
      .then(response => {
        if (response.data == "1") {
          toast.success(response.message, { theme: "light" });
        }
        else {
          toast.error(response.message, { theme: "light" });
        }
        loadTable();
        setDeleteModal(false);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const dispatch = useDispatch();
  const crmcontacts = data;

  useEffect(() => {
    setContact(crmcontacts);
  }, [crmcontacts]);

  useEffect(() => {
    if (!isEmpty(crmcontacts)) {
      setContact(crmcontacts);
      setIsEdit(false);
    }
  }, [crmcontacts]);

  //delete Conatct
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const [modal, setModal] = useState(false);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setContact(null);
    } else {
      setModal(true);
      setTag([]);
      setAssignTag([]);
    }
  }, [modal]);
  // Update Data
  const handleContactClick = useCallback((arg) => {
    const contact = arg;

    setContact({
      _id: contact._id,
      // img: contact.img,
      name: contact.name,
      company: contact.company,
      email: contact.email,
      designation: contact.designation,
      phone: contact.phone,
      lead_score: contact.lead_score,
      last_contacted: contact.date,
      // time: contact.time,
      tags: contact.tags,
    });

    setIsEdit(true);
    toggle();
  }, [toggle]);


  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState([]);
  const [deleteId, setDeleteId] = useState([]);
  const [selectedWeekOption, setSelectedWeekOption] = useState([]);
  const [selectedWareOption, setSelectedWareOption] = useState([]);


  const handleOrderClick = useCallback((arg) => {
    const order = arg;
    setOrder({
      _id: order._id,
      orderId: order.orderId,
      customer: order.customer,
      product: order.product,
      orderDate: order.orderDate,
      ordertime: order.ordertime,
      amount: order.amount,
      payment: order.payment,
      status: order.status
    });

    setIsEdit(true);
    toggle();
  }, [toggle]);

  const colstorageunit = useMemo(
    () => [
      {
        Header: <input type="checkbox" id="checkBoxAll" className="form-check-input" onClick={() => checkedAll()} />,
        Cell: (cellProps) => {
          return <input type="checkbox" className="contactCheckBox form-check-input" value={cellProps.row.original._id} onChange={() => deleteCheckbox()} />;
        },
        id: '#',
      },
      {
        Header: "Serial Number",
        accessor: "serial_number",
        filterable: false,

      },
      {
        Header: "Name",
        Cell: (cellProps) => {
          const encodedText = cellProps.row.original.name;
          const decodedText = he.decode(encodedText);
          return <p>{decodedText}</p>;
        },
        filterable: false,
      },
      {
        Header: "Storage Type",
        Cell: (cellProps) => {
          const encodedString = cellProps.row.original.storage_type;
          console.log(encodedString)
          const encodedText = cellProps.row.original.storage_type;
          const decodedText = he.decode(encodedText);
          // const finalText = decodedText.toLowerCase();
          return <p>{decodedText}</p>;
        },
        filterable: false,
      },
      {
        Header: "Warehouse",
        accessor: "name_warehouse",
        filterable: false,
      },

      {
        Header: "Active",
        accessor: "active",
        filterable: false,
      },

      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">

              <li className="list-inline-item">
                <button className="btn btn-sm btn-soft-info edit-list" onClick={() => {
                  const orderData = cellProps.row.original;
                  const orderId = orderData.id; // Assuming 'id' is the property containing the ID
                  handleOrderClick(orderData);
                  axios.get(`${"api/storage-units/edit/"}${orderId}`)
                    .then(response => {
                      setSerialnumber(response.storage_unit.serial_number);
                      setName(response.storage_unit.name);
                      const selectedWeekOption = storage_types.find(option => option.id === response.storage_unit.storage_type_id);
                      setStorageType({ value: response.storage_unit.storage_type_id, label: selectedWeekOption.name });
                      setManufacturerserialnumber(response.storage_unit.manufacturer_serial_number);
                      const selectedWareOption = storage_warehouse.find(option => option.id === response.storage_unit.warehouse_id);
                      setWareHouseLocation({ value: response.storage_unit.warehouse_id, label: selectedWareOption.warehouse_name });
                      setActive(response.storage_unit.active);
                      setId(response.storage_unit.id);
                      setIsEdit(true);
                      tog_mod_3(true);
                    })
                    .catch(error => {
                      console.error(error);
                    });
                }} ><i className="bx bxs-pencil fs-12 pt-1"></i>
                </button>
              </li>
              <li className="list-inline-item edit">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    const orderData = cellProps.row.original;
                    setDeleteId(orderData.id);
                    onClickDelete(deleteId);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>

            </ul>
          );
        },
      },
    ],
    [handleContactClick, checkedAll]
  );
  //toggle
  const [activeInterest, setActiveInterest] = useState("Y");
  //toggle
  const handleToggle = () => {
    setActive(active === '1' ? '0' : '1');
  };

  //select (Storage Type)
  const handleStorageTypeValue = (selectedOption) => {
    setStorageType({ value: selectedOption.value, label: selectedOption.label });
  };

  //select ()
  const handleWareHouseValue = (selectedOption) => {
    setWareHouseLocation({ value: selectedOption.value, label: selectedOption.label });
  };

  return (
    <div>
      <DeleteModal
        show={deleteModal}
        deleteId={deleteId}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Card>
        <CardHeader>
          <div className="d-flex align-items-center flex-wrap gap-2">
            <div className="flex-grow-1">
              <button
                className="btn btn-brown add-btn"
                onClick={() => {
                  setIsEdit(false);
                  tog_mod_3(true);
                  setActive("1");
                  setSerialnumber("");
                  setName("");
                  setStoragetypeid("");
                  setManufacturerserialnumber("");
                  setWarehouselocation("");
                  setId("");
                }
                }
              >
                <i className="ri-add-fill me-1 align-bottom"></i> Create Storage Units
              </button>
            </div>

          </div>
        </CardHeader>
      </Card>
      <Col xxl={12}>
        <Card id="contactList">
          <CardBody className="pt-0">
            <div>
              {isContactSuccess && crmcontacts.length ? (
                <TableContainer
                  columns={colstorageunit}
                  data={(crmcontacts || [])}
                  isGlobalFilter={true}
                  isAddUserList={false}
                  customPageSize={50}
                  className="custom-header-css"
                  divClass="table-responsive table-card mb-3"
                  tableClass="align-middle table-nowrap"
                  theadClass="table-light"
                  handleContactClick={handleContactClicks}
                  isContactsFilter={true}
                  SearchPlaceholder="Search for contact..."
                />
              ) : (<Loader error={error} />)
              }

            </div>

            <Modal
              className="mt-5"
              size="lg"
              isOpen={modal_unit}
              toggle={() => {
                tog_mod_3();
              }}
            >
              <Card>
                <CardHeader>
                  <h5 className="mb-0">{!!isEdit ? "Edit Storage Unit" : "Create Storage Unit"}</h5>
                </CardHeader>
                <CardBody>
                  <h5>Storage Unit Detail</h5>
                  <hr></hr>
                  <Row>
                    <Input type="hidden" value={id} onChange={(e) => setId(e.target.value)} className="form-control" placeholder="" id="storageId" />
                    <Col md={3}>
                      <div className="mb-3 ">
                        <Label for="serialNumber" className="form-label">Serial Number</Label>
                        <Input type="number" value={serial_number} onChange={(e) => setSerialnumber(e.target.value)} className="form-control" placeholder="" id="serialNumber" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label for="unitName" className="form-label">Unit Name</Label>
                        <Input type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control" placeholder="" id="unitName" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="choices-single-default" className="form-label">Storage Type</Label>
                        {/* <Select
                          value={storage_type_id}
                          onChange={(selectedOption) => {
                            handleStorageType(selectedOption);
                          }}
                          options={storage_types.map(item => ({ value: item.id, label: item.name }))}
                        /> */}
                        <Select
                          value={storageType}
                          onChange={handleStorageTypeValue}
                          options={storage_types.map(item => ({ value: item.id, label: item.name }))}
                          isSearchable={true}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label for="manufacturerSerialNum" className="form-label">Manufacturer Serial Number</Label>
                        <Input type="text" value={manufacturer_serial_number} onChange={(e) => setManufacturerserialnumber(e.target.value)} className="form-control" placeholder="" id="manufacturerSerialNum" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="choices-single-default" className="form-label text-muted">Warehouse Location</Label>
                          {/* <Select
                            value={warehouse_location}
                            onChange={(selectedOptionWareHouse) => {
                              handleWarehouseLocation(selectedOptionWareHouse);
                            }}
                            options={storage_warehouse.map(item => ({ value: item.id, label: item.warehouse_name }))}
                          /> */}
                          <Select
                            value={warehouseLocation}
                            onChange={handleWareHouseValue}
                            options={storage_warehouse.map(item => ({ value: item.id, label: item.warehouse_name }))}
                            isSearchable={true}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Col md={12}>
                      <Row>
                        <Col md={9}>
                          <div>
                            <div className="form-check mb-2" style={{ paddingLeft: '0px' }}>
                              <Label className="form-check-label" for="checkActive">
                                Active
                              </Label>
                            </div>
                            <div className="form-check form-switch form-switch-lg">
                              <Input
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckRightDisabled"
                                value="Y"
                                name="active"
                                checked={active === '1'}
                                onChange={handleToggle}
                              />
                            </div>
                          </div>
                        </Col>

                      </Row>
                    </Col>
                    <Row>
                      <div className="hstack gap-2 justify-content-end">
                        <button type="submit" onClick={handleSubmit} className="btn btn-success"> {!!isEdit
                          ? "Update"
                          : "Save"}</button>
                        <button type="submit" className="btn btn-primary" onClick={() =>
                          tog_mod_3(true)
                        }>Cancel</button>
                      </div>

                    </Row>
                  </Row>
                </CardBody>
              </Card>

            </Modal>
            <ToastContainer closeButton={false} limit={1} />
          </CardBody>
        </Card>
      </Col>
    </div>
  )
}
