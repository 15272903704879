import { Card, CardBody, Col, Row, Button, CardHeader, Modal, ModalBody } from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import connectcover from "../../../assets/images/connectcover.jpg";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../../Components/Common/Loader";

export const ConnectCoverfright = () => {
  document.title="Connect Coverfright | Onexfort";
  const [coverAcc, setcoverAcc] = useState("");
  const [isLoading, setisLoading] = useState(false);

  const loadCover = () => {
    setisLoading(true);
    axios.get('/api/connect-coverfright/data')
      .then(response => {
        setcoverAcc(response.tenant_api_details);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setisLoading(false);
      })
  }
  //*backend response*
  useEffect(() => {
    loadCover();
  }, []);

  const handleSubmit = () => {
    axios.get('/api/connect-coverfright/disconnect')
      .then(response => {
        toast.success(response.success, { theme: "light" });
        loadCover();
      })
      .catch(error => {
        console.log(error);
      });
  };
  const [modal, setOpenModal] = useState(false);

  const toggle = () => {
    setOpenModal(true);
  };
  return (
    <div>
      <Modal
        id="showModal"
        className="modal-dialog-edit"
        isOpen={modal}
        centered
      >
        <ModalBody className="py-2 px-3">
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/wdqztrtx.json"
              trigger="loop"
              colors="primary:#F8BB86,secondary:#F8BB86"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you sure you want to disconnect from CoverFreight?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              data-bs-dismiss="modal" onClick={() => setOpenModal(false)}>
              No, Cancel!
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={() => { handleSubmit(); setOpenModal(false); }}
            >
              Yes, Disconnect!
            </button>
          </div>
        </ModalBody>
      </Modal>
      <Card>
        <CardHeader className="card-header bg-white header-elements-inline" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h5 className="mb-0 text-primary" style={{ alignSelf: 'flex-start', marginTop: '10px' }}>Connect CoverFreight</h5>
          {coverAcc ? (
            // <a href="#" className="btn btn-outline-danger" onClick={handleSubmit}>Disconnect</a>
            <a href="#" className="btn btn-outline-danger" onClick={() => {
              toggle();
            }}>Disconnect</a>
          ) : null}
        </CardHeader>

        <CardBody>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} >
            <img src={connectcover} className="img img-responsive img-powered-by-stripe" alt="Connect CoverFreight" />
          </Col>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <p style={{ paddingTop: '15px' }}>
              CoverFreight provides Comprehensive Moving Insurance for household goods and personal effects. The insurance provides cover for accidental damage to household and personal goods in transit.
            </p>
          </Col>
          {!coverAcc ? (
            <Row>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <p style={{ paddingTop: '15px' }}>
                  To connect with CoverFreight, please contact CoverFreight at <a href="mailto:info@coverfreight.com.au">info@coverfreight.com.au</a> or call (07) 3613 7901. Or email us at <a href="mailto:support@onexfort.com">support@onexfort.com</a>
                </p>
              </Col>
            </Row>
          ) : null}
          {/* reloading */}
          {isLoading && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(255, 255, 255, 0.8)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999,
              }}
            >
              <Loader />
            </div>
          )}

        </CardBody>
      </Card>
      <ToastContainer closeButton={false} limit={1} theme="light" />
    </div>
  )
}
