import { Card, CardBody, Col, Row, Alert, UncontrolledAlert, } from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { isEmpty } from "lodash";
import "../../../assets/scss/pages/_settings.scss";
import powered_by_stripe from "../../../assets/images/powered_by_stripe.png";
import connect_with_stripe from "../../../assets/images/connect-with-stripe.png";
import {
  CardHeader,

} from "reactstrap";
//Import actions
import {
  getContacts as onGetContacts,
  addNewContact as onAddNewContact,
  updateContact as onUpdateContact,
  deleteContact as onDeleteContact,
} from "../../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import Loader from "../../../Components/Common/Loader";


export const ConnectStripe = () => {
  document.title="Connect Stripe | Onexfort";
  const [modal, setModal] = useState(false);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setContact(null);
    } else {
      setModal(true);
      setTag([]);
      setAssignTag([]);
    }
  }, [modal]);

  const [contact, setContact] = useState([]);
  const dispatch = useDispatch();
  const { crmcontacts, isContactCreated, isContactSuccess, error } = useSelector((state) => ({
    crmcontacts: state.Crm.crmcontacts,
    isContactCreated: state.Crm.isContactCreated,
    isContactSuccess: state.Crm.isContactSuccess,
    error: state.Crm.error,
  }));


  useEffect(() => {
    setContact(crmcontacts);
  }, [crmcontacts]);

  useEffect(() => {
    if (!isEmpty(crmcontacts)) {
      setContact(crmcontacts);
      setIsEdit(false);
    }
  }, [crmcontacts]);

  const [isEdit, setIsEdit] = useState(false);
  // Date & Time Format

  const dateFormat = () => {
    var d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return (d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear());
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // img: (contact && contact.img) || '',
      name: (contact && contact.name) || '',
      company: (contact && contact.company) || '',
      designation: (contact && contact.designation) || '',
      email: (contact && contact.email) || '',
      phone: (contact && contact.phone) || '',
      lead_score: (contact && contact.lead_score) || '',
      tags: (contact && contact.tags) || [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      company: Yup.string().required("Please Enter Company"),
      designation: Yup.string().required("Please Enter Designation"),
      email: Yup.string().required("Please Enter Email"),
      phone: Yup.string().required("Please Enter Phone"),
      lead_score: Yup.string().required("Please Enter lead_score"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateContact = {
          _id: contact ? contact._id : 0,
          // img: values.img,
          name: values.name,
          company: values.company,
          designation: values.designation,
          email: values.email,
          phone: values.phone,
          lead_score: values.lead_score,
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // update Contact 
        dispatch(onUpdateContact(updateContact));
        validation.resetForm();
      } else {
        const newContact = {
          _id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          // img: values["img"],
          name: values["name"],
          company: values["company"],
          designation: values["designation"],
          email: values["email"],
          phone: values["phone"],
          lead_score: values["lead_score"],
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // save new Contact
        dispatch(onAddNewContact(newContact));
        validation.resetForm();
      }
      toggle();
    },
  });
  const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);

  const [stripeAcc, setStripeAcc] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const loadStripe = () => {
    setisLoading(true);
    axios.get('/api/connect-stripe/data')
      .then(response => {
        setStripeAcc(response.stripe);
      })
      .catch(error => {
        console.log(error);
      }).finally(() => {
        setisLoading(false);
      })
  }
  //*backend response*
  useEffect(() => {
    loadStripe();
  }, []);

  //Connect with Stripe button
  const handleSubmit = (event) => {
    event.preventDefault();
    axios.get('/api/connect-stripe/create')
      .then(response => {
        const url = response.url;
        window.location.href = url; // Open the URL in the same page
      })
      .catch(error => {
        console.log(error);
      });
  }

  return (
    <div>
      <Card>
        <CardHeader>
          <h5 className="mb-0 text-primary">Connect Stripe</h5>
        </CardHeader>
        <CardBody>
        {stripeAcc && stripeAcc.account_key !== '' ? (
          <Row>
            <Col xl={2} className="mb-3">
              <img src={powered_by_stripe} className="img img-responsive img-powered-by-stripe" alt="powered-by-stripe" />
            </Col><br />
            <Col xl={12}>
            <div className="alert alert-success alert-styled-left alert-arrow-left alert-dismissible" style={{width:"100%"}}>
              <span className="font-weight-semibold">Connected!</span> Your Stripe account is connected to Onexfort
            </div>
            </Col>
          </Row>
           ): <Row>
           <Col xl={12}>
              <p style={{paddingTop:'10px'}}>For processing payments on Onexfort using Stripe, you should create your own Stripe account and connect your Stripe <br/>account to Onexfort.</p>
           </Col>
           <Col xl={12}>
              <p style={{paddingTop:'15px'}}>1- All payments processed will be directly deposited into your bank account by Stripe.</p>           
           </Col>
           <Col xl={12}>
              <p style={{paddingTop:'15px'}}>2- Onexfort does not store any credit card details of your customers.</p>
           </Col>
           <Col xl={12}>
              <hr/>
           </Col>
           <Col xl={12}>
            <p style={{paddingTop:'15px'}}>
              If you don't have a Stripe account, please use the following link to create one.<br/>
              <a href="https://dashboard.stripe.com/register" target="_blank" style={{color:"blue"}}>https://dashboard.stripe.com/register</a>
            </p>
           </Col>
           <Col xl={12}>
            <p style={{paddingTop:'15px'}}>If you already have a Stripe account, click on the Connect button below to connect your Stripe account to Onexfort:</p>
           </Col>
           <Col xl={12}>
            <div style={{paddingTop:'8px'}}>
            
              <img
                src={connect_with_stripe}
                className="img img-responsive img-powered-by-stripe"
                alt="powered-by-stripe"
                style={{ width: '25%', height: '15%' }}
                onClick={handleSubmit}
              />
            
            </div>
           </Col><br/>
         </Row>}
         {/* reloading */}
         {isLoading && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(255, 255, 255, 0.8)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999,
              }}
            >
              <Loader />
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  )
}
