import { Button, Card, CardBody, CardFooter, Col, Row, } from "reactstrap";
import Dragula from 'react-dragula';
import { Link } from 'react-router-dom';

import Dropzone from "react-dropzone";
import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import axios from 'axios';
import { get_cookie } from '../../../helpers/get_cookie'
import { isEmpty } from "lodash";
import {
  CardHeader,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  ModalFooter,
  Table,
  FormFeedback

} from "reactstrap";


//import action
import {
  getTodos as onGetTodos,
  updateTodo as onupdateTodo,
  deleteTodo as onDeleteTodo,
  addNewTodo as onAddNewTodo,

  getProjects as onGetProjects,
  addNewProject as onAddNewProject,
} from "../../../store/actions";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../../Components/Common/TableContainer";
import DeleteModal from "../../../Components/Common/DeleteModal";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import Loader from "../../../Components/Common/Loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from "../../../config";


export const EmailTemplates = () => {
   document.title="Email Templates | Onexfort";
  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [contact, setContact] = useState([]);
  const [data, setData] = useState([]);
  const [company, setCompany] = useState()
  // const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();
  ////////////////////////Content
  const [selectedFiles, setselectedFiles] = useState([]);
  const [tempname, setTempName] = useState();
  const [compname, setCompName] = useState();
  const [frommail, setFromEmail] = useState();
  const [frommailid, setFromMailId] = useState();
  const [mailsub, setMailSub] = useState('');
  const [mailbody, setMailBody] = useState('')
  const [companyid, setCompanyId] = useState('')
  const [tempid, setTempId] = useState('')
  ///for toggles
  const [quote, setQuote] = useState('N');
  const [quoteValue, setQuoteValue] = useState('N');
  const [invoice, setInvoice] = useState('N');
  const [invoiceValue, setInvoiceValue] = useState('N');
  const [workorder, setWorkOrder] = useState('N');
  const [WorkorderValue, setWorkOrderValue] = useState('N');
  const [insurance, setInsurance] = useState('N');
  const [insuranceValue, setInsuranceValue] = useState('N');
  const [delivery, setDelivery] = useState('N');
  const [deliveryValue, setDeliveryValue] = useState('N');
  const [storage, setStorage] = useState('N');
  const [storageValue, setStorageValue] = useState('N');
  const [inventory, setInventory] = useState('N');
  const [inventoryValue, setInventoryValue] = useState('N');
  const [active, setActive] = useState('N');
  const [activeValue, setActivetValue] = useState('N');
  const error = null;
  const isContactSuccess = true;
  const crmcontacts = data;
  useEffect(() => {
    loaddata();
  }, []);
  const loaddata = () => {

    axios
      .get("/api/email-templates/data")
      .then((res) => {
        // console.log(res.data);
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get("/api/email-templates/create")
      .then((res) => {
        setCompany(res.companies);

        setCompanyId(res.companies[0].id)
      })
      .catch((err) => {
        console.log(err);
      });

  };
  useEffect(() => {
    setContact(crmcontacts);
  }, [crmcontacts]);

  useEffect(() => {
    if (!isEmpty(crmcontacts)) {
      setContact(crmcontacts);
      setIsEdit(false);
    }
  }, [crmcontacts]);
  useEffect(() => {
    const value = active ? 'Y' : 'N';
    setActivetValue(value);
    const value1 = quote ? 'Y' : 'N';
    setQuoteValue(value1);
    const value2 = invoice ? 'Y' : 'N';
    setInvoiceValue(value2);
    const value3 = workorder ? 'Y' : 'N';
    setWorkOrderValue(value3)
    const value4 = insurance ? 'Y' : 'N';
    setInsuranceValue(value4)
  }, [active, quote, invoice, workorder, insurance]);
  ///// Toggles/////////////////
  const handleQuote = (event) => {
    const isCheck = event.target.checked;
    const value = isCheck ? 'Y' : 'N';
    setQuote(value);
  };
  const handleInvoice = (event) => {
    const isCheck = event.target.checked;
    const value = isCheck ? 'Y' : 'N';
    setInvoice(value);
  };
  const handleWorkOrder = (event) => {
    const isCheck = event.target.checked;
    const value = isCheck ? 'Y' : 'N';
    setWorkOrder(value);
  };
  const handleInsurance = (event) => {
    const isCheck = event.target.checked;
    const value = isCheck ? 'Y' : 'N';
    setInsurance(value);
  };
  const handleDelivery = (event) => {
    const isCheck = event.target.checked;
    const value = isCheck ? 'Y' : 'N';
    setDelivery(value);
  };
  const handlestorage = (event) => {
    const isCheck = event.target.checked;
    const value = isCheck ? 'Y' : 'N';
    setStorage(value);
  };
  const handleInventory = (event) => {
    const isCheck = event.target.checked;
    const value = isCheck ? 'Y' : 'N';
    setInventory(value);
  };
  const handleStatus = (event) => {
    const isCheck = event.target.checked;
    const value = isCheck ? 'Y' : 'N';
    setActive(value);
  };

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }


  const [attachments, setAttachments] = useState('')
  const [isEdit, setIsEdit] = useState(false);
  //delete Conatct

  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const [modal, setModal] = useState(false);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setContact(null);
    } else {
      setModal(true);
      setTag([]);
      setAssignTag([]);
    }
  }, [modal]);

  // Add Data
  const handleContactClicks = () => {
    setContact("");
    setIsEdit(false);
    toggle();
  };

  // Date & Time Format

  const dateFormat = () => {
    var d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return (d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear());
  };


  useEffect(() => {
    const value = active ? 'Y' : 'N';
    setActivetValue(value);
  }, [active]);
  const handleFormSubmit = (e) => {
    e.preventDefault();


    var formData = {
      "email_template_name": tempname,
      "email_subject": mailsub,
      "from_email_name": frommail,
      "email_body": mailbody,
      "from_email": frommailid,
      "company_id": companyid,

      "attach_quote": quote,
      "attach_invoice": invoice,
      "attach_inventory": inventory,
      "attach_work_order": workorder,
      "attach_pod": delivery,
      "attach_storage_invoice": storage,
      "attach_insurance": insurance,
      "active": active

    }
    if (tempname == '') {
      toast.error("The Email Template Name field is required.", { theme: "light" });
      return;
    }
    console.log(formData, "id");
    if (isEdit) {
      console.log("edit");
      axios.post(`${"api/email-templates/update/"}${tempid}`, formData)

        .then((response) => {
          // Handle success
          tog_large();
          // setModal(false);
          console.log('Save successful', response.data);
          toast.success("Updated Successfully", { theme: "light" });

        })
        .catch((error) => {
          // Handle error
          console.error('Error saving data:', error);
        });

    } else {
      console.log("save");
      // Handle save logic
      axios.post('/api/email-templates/store', formData)

        .then((response) => {
          // Handle success
          tog_large();
          console.log('Save successful', response.data);
          toast.success("Saved Successfully", { theme: "light" });
        })
        .catch((error) => {
          // Handle error
          console.error('Error saving data:', error);
        });
    }
    loaddata();
  }
  const [file, selectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");

  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    console.log(file, "filedrop");
    const formData = new FormData();
    formData.append('templateid', tempid);
    formData.append('attachment', file);
    axios.post('/api/storeTemplateAttachment', formData)
      .then(response => {

        console.log(response.message);
        toast.success(response.message, { theme: "light" });
        setAttachments(response.attachments)
        // loaddata();

      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleFileChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    console.log(tempid);
    console.log(file, "selectedFile");

    //reloading the page
    // setIsUpdating(true);
    const formData = new FormData();
    formData.append('templateid', tempid);
    formData.append('attachment', file);
    axios.post('/api/storeTemplateAttachment', formData)
      .then(response => {

        console.log(response.message);
        toast.success(response.message, { theme: "light" });
        setAttachments(response.attachments)
        // loaddata();

      })
      .catch(error => {
        console.error(error);
      });
  };
  const [mailId, setMailId] = useState('')
  const deletePopUp = (id) => {
    setMailId(id);
    setDeleteModal(true);

  }
  const onClickDelete = async () => {
    console.log("deleteItemId", mailId);
    if (mailId) {
      var orderIds = mailId;
      axios.post(`${"api/email-templates/delete/"}${orderIds}`)
        .then(response => {
          console.log(response.message);
          loaddata();
          toast.success(response.message, { theme: "light" });


        })
        .catch(error => {
          console.error(error);
        });
    }
    setDeleteModal(false);
    loaddata();
  };
  // Update Data
  const handleContactClick = useCallback((arg) => {
    const contact = arg;

    setContact({
      _id: contact._id,
      // img: contact.img,
      name: contact.name,
      company: contact.company,
      email: contact.email,
      designation: contact.designation,
      phone: contact.phone,
      lead_score: contact.lead_score,
      last_contacted: contact.date,
      // time: contact.time,
      tags: contact.tags,
    });

    setIsEdit(true);
    tog_large();
  }, [toggle]);

  const [order, setOrder] = useState([]);

  const handleOrderClick = (id) => {
    console.log(id);

    if (id !== '') {
      console.log("edit" + id);
      axios.post(`/api/email-templates/edit/${id}`)
        .then((response) => {
          console.log(response);
          setTempId(response.template.id)
          setTempName(response.template.email_template_name || '')
          setFromEmail(response.template.from_email_name || '')
          setFromMailId(response.template.from_email || '')
          setMailSub(response.template.email_subject || '')
          setMailBody(response.template.email_body || '')
          setCompanyId(response.template.company_id || '')
          setActive(response.template.active || '')
          setInsurance(response.template.attach_insurance);
          setInventory(response.template.attach_inventory);
          setDelivery(response.template.attach_pod);
          setQuote(response.template.attach_quote);
          setInvoice(response.template.attach_invoice);
          setStorage(response.template.attach_storage_invoice);
          setWorkOrder(response.template.attach_work_order)
          setAttachments(response.attachments)
        })
        .catch((error) => {
          console.error('Delete error:', error);
          // Handle delete error
        });
      setIsEdit(true);
      console.log("end")
      setmodal_large(true);
    }
  };
  const removeattach = (id) => {
    console.log(id, "attcg");
    var data = {
      'id': id
    }
    axios.post(`/api/removeTemplateAttachment`, data)
      .then((response) => {
        console.log(response);
        toast.error(response.message, { theme: "light" });

        setAttachments(response.attachments)
        // setmodal_large(true);
      })
      .catch((error) => {
        console.error('Delete error:', error);
        // Handle delete error
      });
  }


  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".contactCheckBox:checked");
    ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  // Customber Column
  const columns = useMemo(
    () => [

      {
        Header: "Email Template Name",
        accessor: "email_template_name",
        filterable: false,

      },
      {
        Header: "Company Name",
        accessor: "company_name",
        filterable: false,
      },

      {
        Header: "Status",
        accessor: "active",
        filterable: false,
      },

      {
        Header: "Action",
        Cell: (cellProps) => {
          const { id } = cellProps.row.original;
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item">
                <button className="btn btn-sm btn-soft-info edit-list" onClick={() => {
                  // const orderData = cellProps.row.original;
                  handleOrderClick(id);
                }} ><i className="bx bxs-pencil fs-12 pt-1"></i>
                </button>
              </li>
              <li className="list-inline-item edit">
                <Link
                  to="#"
                  className=" btn btn-sm btn-soft-danger fs-13 pt-1" onClick={() => deletePopUp(id)}

                >
                  <i className="bx bxs-trash fs-12"></i>
                </Link>
              </li>

            </ul>
          );
        },
      },
    ],
    [handleContactClick, checkedAll]
  );
  const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);

  //modalv large
  const [modal_large, setmodal_large] = useState(false);

  function tog_large() {
    setmodal_large(!modal_large);
  }

  const { todos, projects } = useSelector((state) => ({
    todos: state.Todos.todos,
    projects: state.Todos.projects,
  }));


  const [taskList, setTaskList] = useState([]);

  // Projects
  const [modalProject, setModalProject] = useState(false);

  useEffect(() => {
    dispatch(onGetProjects());
  }, [dispatch]);

  const [todo, setTodo] = useState(null);
  useEffect(() => {
    dispatch(onGetTodos());
  }, [dispatch]);

  useEffect(() => {
    setTodo(todos);
    setTaskList(todos);
  }, [todos]);

  // Toggle Project Modal
  const toggleProject = () => {
    if (modalProject) {
      setModalProject(false);
    } else {
      setModalProject(true);
    }
  };

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  return (
    <div>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => onClickDelete()}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Card>
        <CardHeader>
          <div className="d-flex align-items-center flex-wrap gap-2">
            <div className="flex-grow-1">
              <button
                className="btn btn-brown add-btn"
                onClick={() => {
                  tog_large();
                  setTempName('')
                  setFromEmail('')
                  setModal(true);


                  setIsEdit(false);


                }}
              >
                <Input type="hidden" id="id-field" />
                <Input
                  type="hidden"
                  value={tempid}
                  className="form-control"
                  placeholder=""
                  id="warehouseName"
                  readOnly
                />
                <i className="ri-add-fill me-1 align-bottom"></i> Add
                Email Template
              </button>
            </div>
            <div className="flex-shrink-0">
              <div className="hstack text-nowrap gap-2">
                {isMultiDeleteButton && <button className="btn btn-danger"
                  onClick={() => setDeleteModalMulti(true)}
                ><i className="ri-delete-bin-2-line"></i></button>}

              </div>
            </div>
          </div>
        </CardHeader>
      </Card>
      <Card id="contactList">
        <CardBody className="pt-0">
          <div>
            {isContactSuccess && crmcontacts.length ? (
              <TableContainer
                columns={columns}
                data={(crmcontacts || [])}
                isGlobalFilter={true}
                isAddUserList={false}
                customPageSize={50}
                className="custom-header-css"
                divClass="table-responsive table-card mb-3"
                tableClass="align-middle table-nowrap"
                theadClass="table-light"
                handleContactClick={handleContactClicks}
                isContactsFilter={true}
                SearchPlaceholder="Search for contact..."
              />
            ) : (<Loader error={error} />)
            }

          </div>
          <Modal id="showModal" size="xl"
            isOpen={modal_large}
            toggle={() => {
              tog_large();
            }}  >
            <ModalHeader className="bg-soft-primary p-3">
              <h5 className="text-black mb-0">{!!isEdit ? " Update Email Template" : "Create Email Template"}</h5>
            </ModalHeader>

            <Form className="tablelist-form" onSubmit={(e) => {
              e.preventDefault();
              return false;
            }}>
              <ModalBody>
                <Input type="hidden" id="id-field" />
                <Row>
                  <Col lg={12} className="mb-3">
                    <div>
                      <Label
                        htmlFor="name-field"
                        className="form-label"
                      >
                        Email Template Name
                      </Label>
                      <Input
                        name="name"
                        id="customername-field"
                        className="form-control"
                        placeholder="Enter Name"
                        type="text" value={tempname}
                        onChange={(e) => setTempName(e.target.value)}
                        validate={{
                          required: { value: true },
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg={12} className="mb-3">
                    <Label
                      htmlFor="designation-field"
                      className="form-label"
                    >
                      Company Name
                    </Label>
                    <select
                      value={companyid}
                      onChange={(e) => setCompanyId(e.target.value)}
                      className="form-select mb-3"
                    >

                      {company && company.length > 0 ? (
                        company.map((role, index) => (
                          <option key={index} value={role.id}>{role.company_name
                          }</option>
                        ))
                      ) : (
                        <option value="">No Company found</option>
                      )}
                    </select>
                  </Col>
                  <Col lg={12} className="mb-3">
                    <Label htmlFor="description-field" className="form-label">
                      Available Dynamic Fields
                    </Label>
                    <div>
                      {"{job_id}, {first_name}, {last_name}, {pickup_suburb}, {delivery_suburb}, {pickup_address}, {delivery_address},{pickup_access}, {drop_off_access},{job_date}, {mobile}, {email},{driver_assigned_first_leg},{offsiders_assigned_first_leg},{total_amount}, {total_paid}, {total_due},{external_inventory_form_button},{inventory_list}, {book_now_button}, {user_first_name}, {user_last_name}, {est_start_time}, {est_first_leg_start_time}, {user_email_signature}"};
                    </div>
                  </Col>
                  <Col lg={12} className="mb-3">
                    <div>
                      <Label
                        htmlFor="designation-field"
                        className="form-label"
                      >
                        From Email
                      </Label>

                      <Input
                        name="designation"
                        id="designation-field"
                        className="form-control"
                        placeholder="Contact, YourCompany"
                        type="text" value={frommail}
                        onChange={(e) => setFromEmail(e.target.value)}
                        validate={{
                          required: { value: true },
                        }}
                      />

                    </div>
                  </Col>
                  <Col lg={12} className="mb-3">
                    <div>
                      <Label
                        htmlFor="email_id-field"
                        className="form-label"
                      >
                        From Email ID
                      </Label>

                      <Input
                        name="email"
                        id="email_id-field"
                        className="form-control"
                        placeholder="contact@yourcompany.com"
                        type="text" value={frommailid}
                        onChange={(e) => setFromMailId(e.target.value)}
                        validate={{
                          required: { value: true },
                        }}

                      />


                    </div>
                  </Col>
                  <Col lg={12} className="mb-3">
                    <div>
                      <Label
                        htmlFor="phone-field"
                        className="form-label"
                      >
                        Email Subject
                      </Label>

                      <Input
                        name="phone"
                        id="phone-field"
                        className="form-control"
                        placeholder=""
                        type="text" value={mailsub}
                        onChange={(e) => setMailSub(e.target.value)}
                        validate={{
                          required: { value: true },
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg={12} className="mb-3">
                    <div>
                      <Label
                        htmlFor="lead_score-field"
                        className="form-label"
                      >
                        Email Body
                      </Label>

                      <CKEditor
                        editor={ClassicEditor}
                        data={mailbody}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setMailBody(data);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row  >
                  <Col lg={4} className="mb-3">
                    <Label className="form-check-label" for="flexSwitchCheckChecked">Attach Quote</Label>
                    <div className="form-check form-switch form-switch-md">
                      <Input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                        checked={quote === "Y"} onChange={handleQuote} />
                    </div>
                  </Col>
                  <Col lg={4} className="mb-3">
                    <Label className="form-check-label" for="flexSwitchCheckChecked">Attach Invoice</Label>
                    <div className="form-check form-switch form-switch-md">
                      <Input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                        checked={invoice === "Y"} onChange={handleInvoice} />
                    </div>
                  </Col>
                  <Col lg={4} className="mb-3">
                    <Label className="form-check-label" for="flexSwitchCheckChecked">Attach WOrk Order</Label>
                    <div className="form-check form-switch form-switch-md">
                      <Input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                        checked={workorder === "Y"} onChange={handleWorkOrder} />
                    </div>
                  </Col>
                  <Col lg={4} className="mb-3">
                    <Label className="form-check-label" for="flexSwitchCheckChecked">Attach Insurance Quote</Label>
                    <label><Link to="/settings/coverfreight" className="fw-medium link-primary">Connect CoverFreight</Link> to enable this checkbox</label>
                    <div className="form-check form-switch form-switch-md">
                      <Input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                        checked={insurance === "Y"} onChange={handleInsurance} />
                    </div>
                  </Col>
                  <Col lg={4} className="mb-3">
                    <Label className="form-check-label" for="flexSwitchCheckChecked">Attach Proof of Delivery</Label>
                    <div className="form-check form-switch form-switch-md">
                      <Input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                        checked={delivery === "Y"} onChange={handleDelivery} />
                    </div>
                  </Col>
                  <Col lg={4} className="mb-3">
                    <Label className="form-check-label" for="flexSwitchCheckChecked">Attach Storage Invoice</Label>
                    <div className="form-check form-switch form-switch-md">
                      <Input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                        checked={storage === "Y"} onChange={handlestorage} />
                    </div>
                  </Col>
                  <Col lg={4} className="mb-3" >
                    <Label className="form-check-label" for="flexSwitchCheckChecked">Attach Inventory List</Label>
                    <div className="form-check form-switch form-switch-md">
                      <Input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                        checked={inventory === "Y"} onChange={handleInventory} />
                    </div>
                  </Col>
                  <hr></hr>

                  <Row>
                    <div>
                      {attachments && attachments.length > 0 ? (
                        <>
                          <label style={{ fontSize: '24px', fontWeight: '300' }}>Attachments</label>
                          <ul className="list-group list-group-flush">
                            {attachments.map((res, index) => (
                              <div key={res.key} className="d-flex align-items-center justify-content-between">
                                <div>
                                  <Col md={12}>
                                    <i className="fa fa-paperclip"></i>
                                    {res && (
                                      <p key={index}>
                                        <a className="ri-attachment-2" href={api.api.API_URL + res.attachment_file_location.substring(res.attachment_file_location.lastIndexOf("public/") + 2)} target="_blank">
                                          {res.attachment_file_name}
                                        </a>
                                      </p>
                                    )}
                                  </Col>
                                </div>
                                <div>
                                  <Col md={2}>
                                    <a
                                      className="ri-close-circle-fill"
                                      onClick={() => removeattach(res, index)}
                                    ></a>
                                  </Col>
                                </div>
                              </div>
                            ))}
                          </ul>
                        </>
                      ) : <p></p>}
                    </div>
                  </Row>

                  {/* //file upload */}
                  <Col xxl={12} md={12} style={{ display: isEdit ? 'block' : 'none' }}>
                    <div className="mb-5">
                      <Label htmlFor="readonlyInput" className="form-label"> Upload Your File</Label>
                      <div>
                        <div className="dropzone dz-clickable" onClick={handleClick} onDrop={handleDrop} onDragOver={handleDragOver}>
                          <div className="dz-message needsclick">
                            <div className="mb-3">
                              <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                            </div>
                            <h4>Drop files here or click to upload.</h4>
                          </div>
                        </div>
                        <input
                          type="file"
                          onChange={handleFileChange}
                          ref={fileInputRef}
                          style={{ display: 'none' }}
                        />
                      </div>
                    </div>
                  </Col>


                  <Col lg={4} >
                    <Label className="form-check-label" for="flexSwitchCheckChecked">Active</Label>
                    <div className="form-check form-switch form-switch-md">
                      <Input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                        checked={active === "Y"} onChange={handleStatus} />
                    </div>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter className="justify-content-center">
                <div className="hstack gap-2">
                  <button type="submit" className="btn btn-success" id="add-btn" onClick={handleFormSubmit} > {!!isEdit ? "Update" : "Save"} </button>
                  <button type="button" className="btn btn-danger"
                    onClick={() => { tog_large() }} > Cancel </button>

                </div>
              </ModalFooter>
            </Form>
          </Modal>
          <ToastContainer closeButton={false} limit={1} />
        </CardBody>
      </Card>
    </div>
  )
}
