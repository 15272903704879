import { Card, CardBody, CardHeader, Col, Container, Row , Accordion, AccordionItem,Collapse,Label, Input} from 'reactstrap';
import React, { useEffect, useState, useCallback, useMemo } from "react";
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import { useParams } from "react-router-dom";
import Select from "react-select";
export const Jobpage = ({ data }) => {
    const [lead_name, setLeadName] = useState("");
  const [lead_type, setLeadType] = useState("");
  const [lead_status, setLeadStatus] = useState("");
  const [billing_address, setBillingAddress] = useState("");
  const [billing_suburb, setBillingSuburb] = useState("");
  const [billing_post_code, setBillingPostCode] = useState("");
  const [modal_large, setmodal_large] = useState(false);
  const [customer_detail_id, setCustomerDetailId] = useState(false);
  const [crm_lead_statuses, setCrmLeadStatuses] = useState([]);
  const[account_number,setAccountNumber] = useState([]);
  const[invoice_terms,setInvoiceTerms] = useState([]);
  const[payment_instructions,setPaymentInstructions] = useState([]);
  useEffect(()=>{
        console.log(data);
        setLeadName(data?.crmlead?.name);
        setCrmLeadStatuses(data?.crmleadstatuses);
        setCrmLeadStatuses(data?.crmleadstatuses);
        setAccountNumber(data?.customer_detail?.account_number);
        setInvoiceTerms(data?.customer_detail?.invoice_terms);
        setPaymentInstructions(data?.customer_detail?.payment_instructions);
        setLeadType(data?.crmlead?.lead_type);
        const selectedOption = crm_lead_statuses?.find(option => option.lead_status == data?.crmlead?.lead_status);
        setLeadStatus({value: data?.crmlead?.lead_status, label: data?.crmlead?.lead_status});
        setBillingAddress(data?.customer_detail?.billing_address);
        setBillingSuburb(data?.customer_detail?.billing_suburb);
        setBillingPostCode(data?.customer_detail?.billing_post_code);
        setCustomerDetailId(data?.customer_detail?.id);
    }, [data])

  function tog_large() {
    setmodal_large(!modal_large);
  }
  function tog_large() {
    setmodal_large(!modal_large);
  }
    const params = useParams();
    const lead_id = params.id;
    const handlecrmleadstatues = (selectedOption) => {
          if (selectedOption) {
           setLeadStatus(selectedOption);
            console.log(selectedOption.value);
          }
    };
    const handleLeadTypeChange = (event) => {
    setLeadType(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const name = lead_name[0];
    const value = lead_status.label;
    var formData = {
      "lead_name": name,
      "lead_type": lead_type,
      "lead_status" : value,
      "billing_address": billing_address,
      "billing_suburb": billing_suburb,
      "billing_post_code": billing_post_code,
      "lead_id":lead_id,
      "customer_detail_id":customer_detail_id,
    }
    axios.post("api/ajax-save-customer-detail", formData)
          .then(response => {
            console.log(response);
             toast.success(response.message,{theme:"light"});
          })
          .catch(error => {
            toast.error("Data not updated",{theme:"light"});
          });
   
  }
  const [isEdit, setIsEdit] = useState(false);
	return(
          <div>
          <Card>
                <CardHeader>
                </CardHeader>
                <CardBody>
                  <Row>
                   <Input type="hidden" value={customer_detail_id} onChange={(e) => setCustomerDetailId(e.target.value)} className="form-control" placeholder="" id="warehouseName"/>
                    <Col md={6}>
                      <div className="mb-3 ">
                        <Label for="warehouseName" className="form-label">Customer Name</Label>
                        <Input type="text" value={lead_name} onChange={(e) => setLeadName(e.target.value)} className="form-control" placeholder="" id="warehouseName" />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label for="description" className="form-label">Customer Type</Label>
                        <select id="lead_type" name="lead_type" className="form-control" value={lead_type} onChange={handleLeadTypeChange}>
                          <option value="Residential">Residential</option>
                          <option value="Commercial">Commercial</option>
                        </select>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label for="address" className="form-label">Customer Status</Label>
                         <Select
                          value={lead_status}
                          onChange={(selectedOption) => {
                            handlecrmleadstatues(selectedOption);
                          }}
                          options={crm_lead_statuses?.map(item => ({label: item.lead_status }))}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label for="suburb" className="form-label">Address</Label>
                        <Input type="text" value={billing_address} onChange={(e) => setBillingAddress(e.target.value)} className="form-control" placeholder="" id="suburb" />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label for="state" className="form-label">Suburb</Label>
                        <Input type="text" value={billing_suburb} onChange={(e) => setBillingSuburb(e.target.value)} className="form-control" placeholder="" id="state" />
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="mb-3">
                        <Label for="postCode" className="form-label">Postcode</Label>
                        <Input type="text" value={billing_post_code} onChange={(e) => setBillingPostCode(e.target.value)} className="form-control" placeholder="" id="postCode" />
                      </div>
                    </Col>
                   {lead_type !== 'Residential' && (
        <>
                     <Col md={6}>
                      <div className="mb-3">
                        <Label for="postCode" className="form-label">Account Number</Label>
                        <Input type="text" value={account_number} onChange={(e) => setAccountNumber(e.target.value)} className="form-control" placeholder="" id="postCode" />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label for="postCode" className="form-label">Invoice Terms</Label>
                        <Input type="text" value={invoice_terms} onChange={(e) => setInvoiceTerms(e.target.value)} className="form-control" placeholder="" id="postCode" />
                      </div>
                    </Col> 
                     <Col md={12}>
                     <div className="mb-3">
                        <Label for="postCode" className="form-label">Payment Instructions</Label>
                        <Input type="textarea" value={payment_instructions} onChange={(e) => setPaymentInstructions(e.target.value)} className="form-control" placeholder="" id="postCode" />
                      </div>
                    </Col>
                    </>
      )}
                    <Col md={12}>
                      <Row>
                        <div className="hstack gap-2 justify-content-end">
                        <button type="submit" onClick={handleSubmit} className="btn btn-success">Update</button>
                        <button type="submit" className="btn btn-primary" onClick={() =>
                          tog_large(false)}>Cancel</button>
                          </div>
                      </Row>
                    </Col>
                    <Row>

                    </Row>
                  </Row>
                </CardBody>
                <ToastContainer closeButton={false} limit={1} theme="light" />
              </Card>
          </div>
		);
}