import { Card, CardBody, CardHeader, CardTitle, Col, Container, Row, Table, Input } from "reactstrap";
import { Link, useParams } from 'react-router-dom';
import React, { useEffect, useState, useCallback, useMemo } from "react";
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import Loader from "../../Components/Common/Loader";


const PermissionSettings = () => {
  const { id } = useParams();

  const [role, setRole] = useState('')
  const [mainModule, setMainModule] = useState([]);
  const [isLoading, setisLoading] = useState(false);

  const loadPermission = () => {
    setisLoading(true);

    axios.get(`/api/role-permissions/${id}`)
      .then(response => {
        // Handle the response
        console.log("res", response);
        setRole(response.role)
        setMainModule(response.app_modules);
        setisLoading(false)

      })
      .catch(error => {
        // Handle any errors
        console.error(error);
      });
  }
  useEffect(() => {
    // Use the id in your Axios request
    loadPermission();
  }, []);
  const handlePermissionChange = (event, permissionId) => {
    const checked = event.target.value;
    // const toggleState = checked ? 'Y' : 'N';
    // console.log("checked",checked);
    var formdata = {
      'has_access': checked,
      'role_id': role.id,
      'permission_id': permissionId,
    }
    console.log("permissionId", formdata);
    axios.post(`/api/ajax-update-role-permissions`, formdata)
      .then(response => {
        // Handle the response
        console.log("res", response.message);
        toast.success(response.message, { theme: "light" });

        loadPermission();
      })
      .catch(error => {
        // Handle any errors
        console.error(error);
      });
  };
  return (

    <div className="page-content">
          {isLoading && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            background: 'rgba(255, 255, 255, 0.8)',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            zIndex: 9999,
                                        }}
                                    >
                                        <Loader />
                                    </div>
                                )}
        <ToastContainer closeButton={false} limit={1} theme="light"/>
      <Container fluid>
        <BreadCrumb title="Roles & Permission" pageTitle="Onexfort" />
        <Row>
          <Col xl={3}>
            <Card>
              <CardHeader> <h6 className='text-brown'> <i className='bx bx-list-ol '></i> Roles & Permission</h6></CardHeader>
            </Card>
          </Col>
          <Col xl={9}>
            <div className="d-md-flex align-items-md-start">
              {/* Include the necessary components */}
              {/* <include> is not a valid JSX element, so replace it with the relevant component */}

              <div style={{ flex: 'auto' }}>
                <div className="card">
                  <div className="card-header bg-white header-elements-inline">
                    <h6 className="card-title">Role: {role?.display_name}</h6>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12 col-xs-12">
                        {/* Iterate over the appModules array */}
                        {mainModule?.map((module) => (
                          <div className="row" key={module.id}>
                            <div className="col-sm-12 col-xs-12">
                              <ul className=" m-5 mt-1 mb-1  ">
                                <h5 >
                                  {module?.display_name}
                                </h5>
                                {/* Iterate over the sub_modules array */}
                                {module?.sub_modules?.map((sub_module) => (
                                  <div className="row  m-3 mt-1 mb-1" key={sub_module.id} style={{ borderBottom: '1px solid #eee' }}>
                                    <div className="col-sm-9 mt-2">
                                      <span className="mt-2" style={{ fontSize: '.900rem' }}>
                                        {sub_module.level === 1 && (
                                          <i className="ri-arrow-right-s-fill level-icon m-4 mt-0 mb-0 "></i>
                                        )}
                                        {sub_module.level === 2 && (
                                          <><i className="ri-arrow-right-s-fill level-icon" style={{marginLeft: "20px"}}></i><i className="ri-arrow-right-s-fill  level-icon mr-2"></i></>
                                        )}
                                        <span className="m-1 " >{sub_module.display_name}</span>
                                      </span>

                                    </div>
                                    <div className="col-sm-3">
                                      <div className="switchery-demo pull-right" style={{ paddingTop: '10px' }}>
                                        <span className="form-check form-switch form-switch-lg" style={{ alignSelf: 'flex-end' }}>
                                          <Input
                                            type="checkbox"
                                            role="switch"
                                            id="flexSwitchCheckRightDisabled"
                                            value={!sub_module.permission ? 'Y' : 'N'}
                                            data-color="#f96262"
                                            data-permission_id={sub_module.id}
                                            checked={!sub_module.permission ? true : false}
                                            onClick={(event) => handlePermissionChange(event, sub_module.id)}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </ul>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );










}
export default PermissionSettings