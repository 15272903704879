// import Components
import BreadCrumb from '../../Components/Common/BreadCrumb';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
    Card, CardBody, CardHeader, Col, Container, Row, Label, Input, Accordion, AccordionItem, Collapse
} from 'reactstrap';
import { get_cookie } from '../../helpers/get_cookie';
import axios from 'axios';
import classnames from "classnames";
import TableContainer from "../../Components/Common/TableContainer";
import Flatpickr from "react-flatpickr";

import Loader from "../../Components/Common/Loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
//redux
import { useSelector, useDispatch } from "react-redux";
import * as moment from "moment";
import { Link } from "react-router-dom";
import Select from "react-select";
//Import actions
import {
    getOrders as onGetOrders,
    addNewOrder as onAddNewOrder,
    updateOrder as onUpdateOrder,
    deleteOrder as onDeleteOrder,
} from "../../store/ecommerce/action";
import DeleteModal from "../../Components/Common/DeleteModal";


const ListJobs = () => {
    const [col1, setcol1] = useState(false);
    const t_col1 = () => {
        setcol1(!col1);

    };

    const [modal, setModal] = useState(false);

    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const orders = data;
    const isOrderSuccess = true;


    const [jobStatus, setJobStatus] = useState([]);
    const [paymentStatus, setPaymentStatus] = useState([])
    const [isLoading, setisLoading] = useState(false);
    const [tableData,setTableData]=useState([])
    const [org_date, setOrg_Date] = useState([]);



    //fetching data table api 
    const user = JSON.parse(get_cookie("authUser"));
    var tenant_id = user.tenant_id;
    useEffect(() => {
        setisLoading(true);
        axios.get("/api/list-jobs/data", tenant_id)
            .then((res) => {
                console.log(res)
                setData(res.data);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(()=>{
                setisLoading(false);
            })
        axios.get("/api/list-jobs")
            .then((res) => {
                // console.log(res,"res2344")
                setOrg_Date(res.organisation_settings);
                setJobStatus(res.job_status);
                setPaymentStatus(res.payment_status)

            });

        axios
            .get("/api/get-display-field-job-data")
            .then((response) => {
              setTableData(response.displayColoumn)
            console.log(response)
            })
            .catch((error) => {
              console.error("Error fetching lead types:", error);
            });

    }, []);

    //filter constants
    const [selectedStatus, setSelectedStatus] = useState("");

    const [selectedJob, setselectedJob] = useState(null);
    function handleJobMulti(selectedOptions) {
        setselectedJob(selectedOptions);
    }
    const [selectedPayment, setselectedPayment] = useState(null);
    // function handlePaymentMulti(selectedOptions) {
    //     setselectedPayment(selectedOptions);
    // }
    //Created start date and end date
    const [createdDateStart, setCreatedDateStart] = useState(null);
    const [createdDateEnd, setCreatedDateEnd] = useState(null);

    //Removal start date and end date
    const [removalDateStart, setRemovalDateStart] = useState(null);
    const [removalDateEnd, setRemovalDateEnd] = useState(null);

    const [hideDeletedArchived, setHideDeletedArchived] = useState(1);
    console.log(hideDeletedArchived)

    const handleCheckboxChange = (event) => {
        const newValue = hideDeletedArchived === 0 ? 1 : 0;
        setHideDeletedArchived(newValue);
    };
    //apply filter loads table
    const loadTable = (event) => {
        event.preventDefault();
        const selectedDateFormat = org_date?.date_format;
        var ss;
        var pay;
        let apiEndpoint = "/api/list-jobs/data";
        // let formattedcreatedDateStart=null;
        // let formattedcreatedDateEnd=null;
        // let formattedremovalDateStart=null;
        // let formattedremovalDateEnd=null;


        setisLoading(true);
        // if(createdDateStart){
        //     formattedcreatedDateStart=moment(createdDateStart).format("DD-MM-YYYY");
        // }
       
        // if(createdDateEnd){
        //     formattedcreatedDateEnd=moment(createdDateEnd).format("DD-MM-YYYY");
        // }
        // if(removalDateStart){
        //     formattedremovalDateStart=moment(removalDateStart).format("DD-MM-YYYY");
        // }
        // if(removalDateEnd){
        //     formattedremovalDateEnd=moment(removalDateEnd).format("DD-MM-YYYY");
        // }
        var subcontractor;
        if (selectedJob && selectedJob.length > 0) {
            // setSelectedStatus(selectedJob.map((option) => option.label));
           ss=selectedJob.map((option) => option.label);
        } 
        else{
            ss=null;
        }
        if (selectedPayment && selectedPayment.length > 0) {
            // setSelectedStatus(selectedJob.map((option) => option.label));
           pay=selectedPayment.map((option) => option.label);
        } 
        else{
            pay=null;
        }
       
        console.log(ss)
        apiEndpoint += `?&job_status=${ss}&payment_status=${pay}&subcontractor=${subcontractor}&created_date_start=${createdDateStart}&created_date_end=${createdDateEnd}&removal_date_start=${removalDateStart}&removal_date_end=${removalDateEnd}&hide_deleted_archived=${hideDeletedArchived}&selected_date_format=${selectedDateFormat}`;
        axios
            .get(apiEndpoint)
            .then((res) => {
                console.log(res);
                setData(res.data);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setcol1(false);
                setisLoading(false);
            })
    };
    //Reset table
    const resetTable = () => {
        setisLoading(true);
        let apiEndpoint = "/api/list-jobs/data";
        apiEndpoint += `?tenant_id=${tenant_id}`;
        axios
            .get(apiEndpoint)
            .then((res) => {
                console.log(res.data);
                setData(res.data);

            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setcol1(false);
                setisLoading(false);
            })
        setselectedJob("");
        setCreatedDateStart(null);
        setCreatedDateEnd(null);
        setselectedPayment('');
        setRemovalDateEnd(null);
        setRemovalDateStart(null);
    }

    const [orderList, setOrderList] = useState([]);
    const [order, setOrder] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteModalMulti, setDeleteModalMulti] = useState(false);
    const onClickDelete = (order) => {
        setOrder(order);
        setDeleteModal(true);
    };

    const handleDeleteOrder = () => {
        console.log(order,"order");
        var id=order;
        axios.get(`/api/delete-job/${id}`)

        // .get("/api/delete-job/${id}")
        .then((response) => {
        //   setTableData(response.displayColoumn)
        console.log(response)
        setDeleteModal(false);
        loadTable();
        })
        .catch((error) => {
          console.error("Error fetching lead types:", error);
        });
    };

    useEffect(() => {
        setOrderList(orders);
    }, [orders]);

    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            orderId: (order && order.orderId) || '',
            customer: (order && order.customer) || '',
            product: (order && order.product) || '',
            orderDate: (order && order.orderDate) || '',
            // ordertime: (order && order.ordertime) || '',
            amount: (order && order.amount) || '',
            payment: (order && order.payment) || '',
            status: (order && order.status) || '',
        },
        validationSchema: Yup.object({
            orderId: Yup.string().required("Please Enter order Id"),
            customer: Yup.string().required("Please Enter Customer Name"),
            product: Yup.string().required("Please Enter Product Name"),
            // orderDate: Yup.string().required("Please Enter Order Date"),
            // ordertime: Yup.string().required("Please Enter Order Time"),
            amount: Yup.string().required("Please Enter Total Amount"),
            payment: Yup.string().required("Please Enter Payment Method"),
            status: Yup.string().required("Please Enter Delivery Status")
        }),
        onSubmit: (values) => {
            if (isEdit) {
                const updateOrder = {
                    _id: order ? order._id : 0,
                    orderId: values.orderId,
                    customer: values.customer,
                    product: values.product,
                    orderDate: date,
                    // ordertime: values.ordertime,
                    amount: values.amount,
                    payment: values.payment,
                    status: values.status
                };
                // update order
                dispatch(onUpdateOrder(updateOrder));
                validation.resetForm();
            } else {
                const newOrder = {
                    _id: Math.floor(Math.random() * (30 - 20)) + 20,
                    orderId: values["orderId"],
                    customer: values["customer"],
                    product: values["product"],
                    orderDate: date,
                    // ordertime: values["ordertime"],
                    amount: values["amount"],
                    payment: values["payment"],
                    status: values["status"]
                };
                // save new order
                dispatch(onAddNewOrder(newOrder));
                validation.resetForm();
            }
            toggle();
        },
    });

    useEffect(() => {
        setOrder(orders);
    }, [orders]);

    useEffect(() => {
        if (!isEmpty(orders)) {
            setOrder(orders);
            setIsEdit(false);
        }
    }, [orders]);


    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
            setOrder(null);
        } else {
            setModal(true);
            setDate(defaultdate());
        }
    }, [modal]);

    const handleOrderClicks = () => {
        setOrder("");
        setIsEdit(false);
        toggle();
    };

    const handleOrderClick = useCallback((arg) => {
        const order = arg;
        setOrder({
            _id: order._id,
            orderId: order.orderId,
            customer: order.customer,
            product: order.product,
            orderDate: order.orderDate,
            ordertime: order.ordertime,
            amount: order.amount,
            payment: order.payment,
            status: order.status
        });

        setIsEdit(true);
        toggle();
    }, [toggle]);



    // Node API 
    // useEffect(() => {

    const checkedAll = useCallback(() => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".orderCheckBox");
        if (checkall.checked) {
            ele.forEach((ele) => {
                ele.checked = true;
            });
        } else {
            ele.forEach((ele) => {
                ele.checked = false;
            });
        }
        deleteCheckbox();
    }, []);

    // Delete Multiple
    const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

    const deleteMultiple = () => {
        const checkall = document.getElementById("checkBoxAll");
        selectedCheckBoxDelete.forEach((element) => {
            dispatch(onDeleteOrder(element.value));
            setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
        });
        setIsMultiDeleteButton(false);
        checkall.checked = false;
    };

    const deleteCheckbox = () => {
        const ele = document.querySelectorAll(".orderCheckBox:checked");
        ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
        setSelectedCheckBoxDelete(ele);
    };


    // Customber Column
    const columns = useMemo(
        () => [
            {
                Header: <input type="checkbox" id="checkBoxAll" className="form-check-input" onClick={() => checkedAll()} />,
                Cell: (cellProps) => {
                    return <input type="checkbox" className="orderCheckBox form-check-input" value={cellProps.row.original._id} onChange={() => deleteCheckbox()} />;
                },
                id: '#',
            },
            {
                Header: "Job#",
                accessor: "job_number",
                tableId:15,
                filterable: false,
                Cell: (cell) => {
                    // return <Link to={"/listjobs/listjobsdetail/"+cell.value} className="fw-medium link-primary">{cell.value}</Link>;
                    return <Link to={"/listjobs/listjobsdetail/" + cell.row.original.job_id} className="fw-medium link-primary">
                    {cell.value}
                  </Link>
                },
            },
            {
                Header: "Customer Name",
                accessor: "customer_name",
                tableId:16,
                filterable: false,
            },
            {
                Header: "Job Date",
                accessor: "job_date",
                tableId:17,
                filterable: false,
            },
            {
                Header: "Email",
                accessor: "email",
                tableId:18,
                filterable: false,
            },
            {
                Header: "Mobile",
                tableId:19,
                accessor: "mobile",
                filterable: false,
            },
            {
                Header: "Pickup Suburb",
                tableId:20,
                accessor: "pickup_suburb",
                filterable: false,
            },
            {
                Header: "Drop off Suburb",
                tableId:21,
                accessor: "delivery_address",
                filterable: false,
            },

            {
                Header: "Job Status",
                accessor: "job_status",
                tableId:23,
                filterable: false,
            },

            {
                Header: "Payment Status",
                accessor: "payment_status",
                tableId:24,
                filterable: false,
            },
            {
                Header: 'Balance',
                accessor: 'balance_payment',
                tableId:25,
                filterable: false,
            },

            {
                Header: 'Action',
                accessor: 'action',
                tableId:25,
                filterable: false,
                Cell: (cellProps) => {
                  return (
                    <ul className="list-inline hstack gap-2 mb-0">
                      <li className="list-inline-item">
                        <Link
                          to="#"
                          className="text-danger d-inline-block remove-item-btn"
                          onClick={() => {
                            const orderData = cellProps.row.original.job_id;
                            onClickDelete(orderData);
                          }}
                        >
                           <i className="ri-delete-bin-5-fill fs-16"></i>
                        </Link>
                      </li>
                    </ul>
                  );
                },
              },
        ],
        [handleOrderClick, checkedAll]
    );

    const filteredColumns = columns.filter((column) => {
        const matchingDisplay = tableData.find(
          (displayObj) => displayObj.id === column.tableId
        );
        console.log(matchingDisplay)
        return matchingDisplay && matchingDisplay.display === 1;
        });

    console.log(filteredColumns)

    const defaultdate = () => {
        let d = new Date(),
            months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let h = (d.getHours() % 12) || 12;
        let ampm = d.getHours() < 12 ? "AM" : "PM";
        return ((d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear() + ", " + h + ":" + d.getMinutes() + " " + ampm).toString());
    };


    const [date, setDate] = useState(defaultdate());


    const handleValidDate = date => {
        const date1 = moment(new Date(date)).format("DD MMM Y");
        return date1;
    };

    const handleValidTime = (time) => {
        const time1 = new Date(time);
        const getHour = time1.getUTCHours();
        const getMin = time1.getUTCMinutes();
        const getTime = `${getHour}:${getMin}`;
        var meridiem = "";
        if (getHour >= 12) {
            meridiem = "PM";
        } else {
            meridiem = "AM";
        }
        const updateTime = moment(getTime, 'hh:mm').format('hh:mm') + " " + meridiem;
        return updateTime;
    };
    orderList?.sort((a, b) => a.job_number - b.job_number);
    useEffect(() => {
        const defaultSelectedOptions = jobStatus
            .filter(status => status.options !== 'Deleted' && status.options !== 'Completed')
            .map((status, index) => ({
                value: index,
                label: status.options
            }));
            
        setselectedJob(defaultSelectedOptions);
    }, [jobStatus]);
    

    const $payment_status_array = {
        all: 'All',
        unpaid: 'Unpaid',
        paid: 'Paid',
        partial: 'Partial'
    };
    const paymentStatusOptions = Object.keys($payment_status_array).map((status) => ({
        value: status,
        label: $payment_status_array[status]
    }));
    const handlePaymentMulti = (selectedOptions) => {
        setselectedPayment(selectedOptions);
    };
    const handleDateChange = (selectedDates) => {
        if (selectedDates.length === 2) {
            setCreatedDateStart(moment(selectedDates[0]).format(org_date?.date_picker_format));
            setCreatedDateEnd(moment(selectedDates[1]).format(org_date?.date_picker_format));
        } else {
            setCreatedDateStart(null);
            setCreatedDateEnd(null);
        }
    };
    const handleRemovalDateChange = (selectedRemovalDates) => {
        if (selectedRemovalDates.length === 2) {
            setRemovalDateStart(moment(selectedRemovalDates[0]).format(org_date?.date_picker_format));
            setRemovalDateEnd(moment(selectedRemovalDates[1]).format(org_date?.date_picker_format));
        } else {
            setRemovalDateStart(null);
            setRemovalDateEnd(null);
        }
    };

    const flatpickrOptions = {
        mode: "range",
        dateFormat: org_date?.date_format,
    };
    // orderList.sort((a, b) => a.job_no - b.job_no);
    document.title = "List Jobs | Onexfort";
    console.log(org_date?.date_format,"org_date?.date_format");
    return (
        <React.Fragment>
            <div className="page-content">
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={handleDeleteOrder}
                    onCloseClick={() => setDeleteModal(false)}
                />

                <DeleteModal
                    show={deleteModalMulti}
                    onDeleteClick={() => {
                        deleteMultiple();
                        setDeleteModalMulti(false);
                    }}
                    onCloseClick={() => setDeleteModalMulti(false)}
                />
                <Container fluid> 
                    <BreadCrumb title="ListJobs" pageTitle="Onexfort" />
                    <Accordion id="default-accordion-example">
                        <AccordionItem>
                            <h2 className="accordion-header" id="headingOne">
                                <button
                                    className={classnames("accordion-button fw-semibold", { collapsed: !col1 })} type="button" onClick={t_col1} style={{ cursor: "pointer" }} >
                                    Search Filters
                                </button>
                            </h2>
                            <Collapse isOpen={col1} className="accordion-collapse" id="collapseOne" >
                                <div className="accordion-body">
                                    <Row>
                                        <Col md={4}>
                                            <div className="mb-3">
                                                <Label htmlFor="choices-multiple-default" className="form-label text-muted">Job Status</Label>
                                                <Select
                                                    value={selectedJob}
                                                    isMulti={true}
                                                    onChange={handleJobMulti}
                                                    options={jobStatus?.map((status, index) => ({
                                                        value: index,
                                                        label: status.options
                                                    }))}

                                                />

                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className="mb-3">
                                                <Label for="leadStatusinput" className="form-label">Payment Status</Label>
                                                <Select
                                                    value={selectedPayment}
                                                    isMulti={true}
                                                    onChange={handlePaymentMulti}
                                                    options={paymentStatusOptions}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={2}>
                                            <div className="form-check form-radio-pad form-check-right mb-2">
                                                <Input className="form-check-input"
                                                    type="checkbox"
                                                    name="formCheckboxRight"
                                                    id="formCheckboxRight1"
                                                    checked={hideDeletedArchived}
                                                    onChange={handleCheckboxChange} />
                                                <Label className="form-check-label" for="formCheckboxRight1">
                                                    Hide Deleted & Archived
                                                </Label>

                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={4}>
                                            <div className="mb-3">
                                                <Label for="createdDateinput" className="form-label">Created Date</Label>
                                                <Flatpickr
                                                    className="form-control"
                                                    options={{
                                                        mode: "range",
                                                        dateFormat: org_date?.date_format
                                                    }}
                                                    value={[createdDateStart, createdDateEnd]}
                                                    onChange={handleDateChange}
                                                />
                                            </div>
                                        </Col>

                                        <Col lg={4}>
                                            <div className="mb-3">
                                                <Label for="createdDateinput" className="form-label">Removal Date</Label>
                                                <Flatpickr
                                                    className="form-control"
                                                    options={flatpickrOptions}
                                                    value={[removalDateStart, removalDateEnd]}
                                                    onChange={handleRemovalDateChange} 
                                                />
                                            </div>
                                        </Col>

                                        <Col lg={4}>
                                            <div className="text-start">
                                                <button type="submit" className="btn form-btn-marg btn-primary"
                                                    onClick={loadTable}>Apply</button>
                                                <button type="submit" className="btn form-btn-marg btn-primary"
                                                    onClick={resetTable}>Reset</button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Collapse>
                        </AccordionItem>
                    </Accordion>
                    <Card id="orderList">
                        <CardBody className="pt-0">
                            <div>

                                <TableContainer
                                    columns={filteredColumns}
                                    data={(orderList || [])}
                                    isGlobalFilter={true}
                                    isAddUserList={false}
                                    customPageSize={50}
                                    divClass="table-responsive table-card mb-1"
                                    tableClass="align-middle table-nowrap"
                                    theadClass="table-light text-muted text-uppercase"
                                    handleOrderClick={handleOrderClicks}
                                    isOrderFilter={true}
                                    SearchPlaceholder="Search for job ID, customer name, job status, payment status or others..."
                                />
                                {isLoading && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            background: 'rgba(255, 255, 255, 0.8)',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            zIndex: 9999,
                                        }}
                                    >
                                        <Loader />
                                    </div>
                                )}

                            </div>

                            <ToastContainer closeButton={false} limit={1} />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default ListJobs;
