import React from 'react'
import { Card, CardBody, Nav, NavItem, NavLink } from 'reactstrap';
import {SettingsTabPages } from '../Constants/SettingsConstants';
import TabControlNavItem from '../../../Components/Common/TabControlNavItem';
import { useNavigate } from 'react-router-dom';

export default function SettingsTabControl({ activeTabSetter, activeTab }) {
    const history = useNavigate();

    function clickHandler(navObject) {
        if(navObject.displayText == "Organisation Setting"){
            history("/settings/organisation-settings");
          }else if(navObject.displayText == "Companies"){
            history("/settings/companies");
          }else if(navObject.displayText == "Servicing Cities"){
            history("/settings/service-cities");
          }else if(navObject.displayText == "List Type and Options"){
            history("/settings/list-type-options");
          }else if(navObject.displayText == "Profile Settings"){
            history("/settings/profile-settings");
          }else if(navObject.displayText == "Payment Credentials"){
            history("/settings/offline-payment-setting");
          }else if(navObject.displayText == "Buy SMS Credits"){
            history("/settings/sms-credits");
          }else if(navObject.displayText == "Connect Stripe"){
            history("/settings/connect-stripe");
          }else if(navObject.displayText == "Connect Xero"){
            history("/settings/connect-xero");
          }else if(navObject.displayText == "Connect MYOB"){
            history("/settings/connect-myob");
          }else if(navObject.displayText == "Configure Email"){
            history("/settings/configure-email");
          }else if(navObject.displayText == "Connect Coverfright"){
            history("/settings/coverfreight");
          }else if(navObject.displayText == "Page Personalisation"){
            history("/settings/pagepersonalisation");
          }
        activeTabSetter(navObject);
    }

    return (
        <Card>
            <CardBody>
                <Nav pills vertical>
                    {
                        Object.keys(SettingsTabPages).map(tabKey =>
                            <TabControlNavItem navObject={SettingsTabPages[tabKey]} onClickCallback={clickHandler} key={tabKey.id} activeTab={activeTab} />
                        )
                    }
                </Nav>
            </CardBody>
        </Card>


    )
}
