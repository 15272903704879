import { Card, CardBody } from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo,useRef } from "react";
import TableRows from "../TableRows";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import {
  CardHeader,
  Table,
} from "reactstrap";
//Import actions
import {
  getContacts as onGetContacts,
  addNewContact as onAddNewContact,
  updateContact as onUpdateContact,
  deleteContact as onDeleteContact,
} from "../../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteModal from "../../../Components/Common/DeleteModal";
import {
  getOrders as onGetOrders,
  addNewOrder as onAddNewOrder,
  updateOrder as onUpdateOrder,
  deleteOrder as onDeleteOrder,
} from "../../../store/ecommerce/action";
// google map autocomplete(only cities)
import GoogleAutocomplete from 'react-google-autocomplete';
import Loader from "../../../Components/Common/Loader";


export const ServiceCities = () => {
  document.title="Servicing Cities | Onexfort";
  const [modal, setModal] = useState(false);  

  const toggle = useCallback(() => {
    if (modal){
      setModal(false);
      setContact(null);
    } else {
      setModal(true);
      setTag([]);
      setAssignTag([]);
    }
  }, [modal]);

  const [contact, setContact] = useState([]);
  const dispatch = useDispatch();
  const { crmcontacts, isContactCreated, isContactSuccess, error } = useSelector((state) => ({
    crmcontacts: state.Crm.crmcontacts,
    isContactCreated: state.Crm.isContactCreated,
    isContactSuccess: state.Crm.isContactSuccess,
    error: state.Crm.error,
  }));

  useEffect(() => {
    setContact(crmcontacts);
  }, [crmcontacts]);

  useEffect(() => {
    if (!isEmpty(crmcontacts)) {
      setContact(crmcontacts);
      setIsEdit(false);
    }
  }, [crmcontacts]);


  const [isEdit, setIsEdit] = useState(false);
  // Date & Time Format

  const dateFormat = () => {
    var d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return (d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear());
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // img: (contact && contact.img) || '',
      name: (contact && contact.name) || '',
      company: (contact && contact.company) || '',
      designation: (contact && contact.designation) || '',
      email: (contact && contact.email) || '',
      phone: (contact && contact.phone) || '',
      lead_score: (contact && contact.lead_score) || '',
      tags: (contact && contact.tags) || [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      company: Yup.string().required("Please Enter Company"),
      designation: Yup.string().required("Please Enter Designation"),
      email: Yup.string().required("Please Enter Email"),
      phone: Yup.string().required("Please Enter Phone"),
      lead_score: Yup.string().required("Please Enter lead_score"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateContact = {
          _id: contact ? contact._id : 0,
          // img: values.img,
          name: values.name,
          company: values.company,
          designation: values.designation,
          email: values.email,
          phone: values.phone,
          lead_score: values.lead_score,
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // update Contact 
        dispatch(onUpdateContact(updateContact));
        validation.resetForm();
      } else {
        const newContact = {
          _id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          // img: values["img"],
          name: values["name"],
          company: values["company"],
          designation: values["designation"],
          email: values["email"],
          phone: values["phone"],
          lead_score: values["lead_score"],
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // save new Contact
        dispatch(onAddNewContact(newContact));
        validation.resetForm();
      }
      toggle();
    },
  });

  // Update Data
  const handleContactClick = useCallback((arg) => {
    const contact = arg;

    setContact({
      _id: contact._id,
      // img: contact.img,
      name: contact.name,
      company: contact.company,
      email: contact.email,
      designation: contact.designation,
      phone: contact.phone,
      lead_score: contact.lead_score,
      last_contacted: contact.date,
      // time: contact.time,
      tags: contact.tags,
    });

    setIsEdit(true);
    toggle();
  }, [toggle]);

  const [order, setOrder] = useState([]);

  const handleOrderClick = useCallback((arg) => {
    const order = arg;
    setOrder({
      _id: order._id,
      orderId: order.orderId,
      customer: order.customer,
      product: order.product,
      orderDate: order.orderDate,
      ordertime: order.ordertime,
      amount: order.amount,
      payment: order.payment,
      status: order.status
    });

    setIsEdit(true);
    toggle();
  }, [toggle]);

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".contactCheckBox:checked");
    ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);


  const [data, setData] = useState([]);
  const [rowsData, setRowsData] = useState([]);
  const [isLoading, setisLoading] = useState(false);

  const loadTable = () => {
    setisLoading(true);
    axios.get('/api/servicing-cities/data')
      .then(response => {
        setData(response.servicingcities);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setisLoading(false);
      })
  }

  useEffect(() => {
    loadTable();
  }, []);
  
  const addTableRows = () => {
    const newTableRow = { groupName: '', isEditing: true };
    setRowsData([...rowsData, newTableRow]);
  };

  //google map choosen value for save
  const handlePlaceSelect = (place, index) => {
    const selectedCity = place && place.formatted_address;
    const updatedRowsData = [...rowsData];
    updatedRowsData[index].groupName = selectedCity;
    setRowsData(updatedRowsData);
  };
  // google map choosen value for update
  const handlePlaceSelects = (place, index) => {
    const value = place && place.formatted_address;
    const updatedProductCategory = [...data];
    updatedProductCategory[index].servicing_city = value;
    setData(updatedProductCategory);
  };
 
  //SAVE(new row)
  const saveTableRow = (index) => {
    const data = rowsData[index];
    if (data.groupName === '' || data.groupName === null) {
      toast.error("Enter the Location.", { theme: "light" });
      return; // Stop form submission if servicing_city is empty
    }
    const datavalue = {
      servicing_city: data.groupName,
    };
    axios.post('/api/servicing/ajaxCreateServicingCities',datavalue)
    .then(res => {
      toast.success(res.success,{theme:"light"});
      const updatedRows = [...rowsData];
      updatedRows.splice(index, 1);
      setRowsData([...updatedRows]);
      loadTable();
    })
    .catch(error => {
      console.error('Error fetching lead types:', error);
    
    });
  }

  //cancel(new row)
  const deleteTableRows = (index) => {
    const updatedRowsData = [...rowsData];
    updatedRowsData.splice(index, 1);
    setRowsData(updatedRowsData);
  };

  //Edit
  const handleEdit = (index) => {
    const updatedProductCategory = [...data];
    updatedProductCategory[index].isEditing = true;
    setData(updatedProductCategory);
  };

// cancel(Update)
const handleCancel = (index) => {
  const updatedProductCategory = [...data];
  updatedProductCategory[index].isEditing = false;
  setData(updatedProductCategory);
};

//save(Update)
const handleSave = async (index) => {
  const item = data[index];
  if (item.servicing_city === '' || item.servicing_city === null) {
    toast.error("Enter the Location.", { theme: "light" });
    return; // Stop form submission if servicing_city is empty
  }
  var datavalue={
    "row_id": item.id,
    "servicing_city": item.servicing_city
  }
  axios.post('/api/servicing/ajaxUpdateServicingCities',datavalue)
  .then(res => {
    toast.success(res.success,{theme:"light"});
    loadTable();
  })
  .catch(error => {
    console.error('Error fetching lead types:', error);
  });
};


//Delete 
const [deleteModal, setDeleteModal] = useState(false);
const [deleteModalMulti, setDeleteModalMulti] = useState(false);
const [deleteItemId, setDeleteItemId] = useState(null);
const handleDeleteContact = () => {
  if (contact) {
    dispatch(onDeleteContact(contact._id));
    setDeleteModal(false);
  }
};

const onClickDelete = (itemId) => {
  setDeleteItemId(itemId);
  setDeleteModal(true);
};


const handleDeleteOrder = async () => {
  if (deleteItemId) {
    var orderIds = deleteItemId;
    axios.get(`${"api/servicing/ajaxDestroyServicingCities/"}${orderIds}`)
      .then(response => {
        toast.success(response.success,{theme:"light"});
        loadTable();
        setDeleteModal(false)
      })
      .catch(error => { 
        console.error(error);
      });
  }
};

  return (
    <div>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Card id="contactList">
        <CardHeader>
          <h5 className="mb-0 text-primary">Servicing Cities</h5>
        </CardHeader>
        <CardBody className="pt-0 mt-3">
          <Table className="table table-bordered table-nowrap align-middle mb-3">
            <thead>
              <tr>
                <th>Servicing City</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={item.id} >
                  <td className="fw-medium">
                    {item.isEditing ? (
                      <GoogleAutocomplete
                        apiKey="AIzaSyB2SMtaVBlqC5v72gqS716BX8R5oXklaFc"
                        onPlaceSelected={(place) => handlePlaceSelects(place, index)}
                        options={{
                          types: ['(cities)'],
                          componentRestrictions: {country: "au"}
                        }}
                        defaultValue={data[index]?.servicing_city || ''}
                        style={{width: '650px', height: '40px', border: '1px solid #ccc', padding: '10px',borderRadius: '5px' }}
                      />
                    ) : (
                      item.servicing_city
                    )}
                    
                  </td>
                  <td>
                    <div className="hstack gap-3 flex-wrap">
                      {item.isEditing ? (
                        <>
                          <button
                            className="btn btn-success"
                            onClick={() => handleSave(index)}
                          >
                            Update
                          </button>
                          <button
                            className="btn btn-primary"
                            onClick={() => handleCancel(index)}
                          >
                            Cancel
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="btn btn-sm btn-soft-info edit-list"
                            onClick={() => handleEdit(index)}
                          >
                            <i className="bx bxs-pencil fs-12 pt-1"></i>
                          </button>
                          <button
                            className="btn btn-sm btn-soft-danger"
                            onClick={() => onClickDelete(item.id)}
                          >
                            <i className="ri-delete-bin-5-fill fs-16"></i>
                          </button>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
              {rowsData.map((data, index) => (
                <tr key={index}>
                  <td>
                  <GoogleAutocomplete
                    apiKey="AIzaSyB2SMtaVBlqC5v72gqS716BX8R5oXklaFc"
                    onPlaceSelected={(place) => handlePlaceSelect(place, index)}
                    options={{
                      types: ['(cities)'],
                      componentRestrictions: {country: "au"}
                    }}
                    style={{width: '650px', height: '40px', border: '1px solid #ccc', padding: '10px',borderRadius: '5px' }}
                  />
                  </td>
                  <td>
                    <button
                      className="btn btn-success "
                      onClick={() => saveTableRow(index)}
                    >
                      Save
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={() => deleteTableRows(index)}
                      style={{marginLeft:'10px'}}
                    >
                      Cancel
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <button className="btn btn-primary" onClick={addTableRows} >+</button>
          {isLoading && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(255, 255, 255, 0.8)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999,
              }}
            >
              <Loader />
            </div>
          )}
        </CardBody>
      </Card>
      <ToastContainer closeButton={false} limit={1} theme="light" />
    </div>
  )
}
