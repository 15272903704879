import { Card, CardBody, Col, Row, CardHeader, Label, Input, Button, Collapse } from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

export const RemovalsQuoteForm = () => {
  document.title="Removals Quote Form | Onexfort";
  const [isRedirect, setIsRedirect] = useState();
  const [id, setId] = useState(null);
  const [companyId, setCompanyId] = useState(16);
  const [url, setUrl] = useState("");
  const [script1, setScript1] = useState();
  const [script2, setScript2] = useState();
  const [showScript1, setShowScript1] = useState(false);
  const [showScript2, setShowScript2] = useState(false);
  const loadData = () => {
    axios.post("api/removal-quote-form")
      .then(res => {
        console.log(res.data.quote_form_redirect_after_submit);
        setIsRedirect((res.data.quote_form_redirect_after_submit === "Y") ? true : false,)
        setId(res.data.id);
        if (res.data.quote_form_redirect_after_submit === "N") {
          setcoll1(false);
        }
        else {
          setcoll1(true);
        }
      })
      .catch(err => {
        console.log(err);
      })
  }
  useEffect(() => {
    loadData()

  }, [])

  const handleRedirect = () => {
    setIsRedirect(!isRedirect);
    if (isRedirect === true) {
      setcoll1(false);
    }
    else if (isRedirect === false) {
      setcoll1(true);
    }
  }
  const generateScript = (scriptNo) => {
    let request;
    if (scriptNo === "script1") {
      request = {
        "company": companyId,
        "script1": "show",
        "script2": "",
      }
    }
    else {
      request = {
        "company": companyId,
        "script1": "",
        "script2": "show",
      }
    }

    axios.post("api/removal-quote-form", request)
      .then(res => {
        console.log(res);
        if (scriptNo === "script1") {
          setScript1(res.script1);
          setShowScript1(true);
        }
        else {
          setScript2(res.script2);
          setShowScript2(true);
        }

      })
      .then(err => console.log(err))

  }

  //toggle collapse
  const [coll1, setcoll1] = useState(true);

  //submit 
  const handleSubmit = () => {

    let data = {
      'id': id,
      'quote_form_redirect_after_submit': isRedirect ? "Y" : "N",
      'quote_form_redirect_url': url
    }
    axios.post("api/saveRemovalQuoteFormSettings", data)
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      })

  }
  const handleCopy = (scriptnum) => {
    let scriptEle;
    if (scriptnum === "script1") {
      scriptEle = document.querySelector('.script1');
    }
    else {
      scriptEle = document.querySelector('.script2');
    }

    if (scriptEle) {
      const range = document.createRange();
      range.selectNode(scriptEle);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);

      // Copy the selected content to the clipboard
      document.execCommand('copy');

      // Clean up the selection
      window.getSelection().removeAllRanges();
    }
  };

  return (
    <div>
      <Card>
        <CardHeader>
          <h5>Removals Quote Form</h5>
        </CardHeader>
        <CardBody>

          <Row className="mb-3">
            <Col lg={6}>

              <Label className="form-label">Redirect to Thank You page after form submission</Label>
              <div className="form-check form-switch form-switch-lg" dir="ltr">
                <Input type="checkbox" className="form-check-input" id="customSwitchsizelg" checked={isRedirect}
                  onChange={handleRedirect}
                />
              </div>
            </Col>
          </Row>
          <Collapse isOpen={coll1} id="collapseExample">
            <Row>
              <Col lg={6}>
                <Label className="form-label">Redirect Url after form submission</Label>
                <Input className="form-input" onChange={e => setUrl(e.target.value)}></Input>
              </Col>
            </Row>
          </Collapse>
          <hr></hr>
          <Col md={12}>
            <div className="text-center">
              <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Save</button>
            </div>
          </Col>

          <hr></hr>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Label for="successstatus" className="form-label">Choose company for the external Removals Quote form: </Label>
                <select id="successstatus" className="form-select" value={companyId}
                  onChange={e => setCompanyId(e.target.value)}
                  data-choices data-choices-sorting="true" >
                  <option value="16">Onexfort</option>
                  <option value="21">Box Removals</option>
                </select>
              </div>
            </Col>
          </Row>
          <h5>Hidden Tab</h5>
          <hr></hr>
          <Col md={12} className="mb-3">
            <div className="text-left">
              <button type="submit" className="btn btn-success" onClick={() => generateScript("script1")}>Generate Script</button>
            </div>
            {
              showScript1 ? (
                <>
                  <div className="mt-2 bg-soft-light-purple scriptdiv script1 text-primary">
                    {script1}
                  </div>
                  <Button className="btn btn-info mt-2" onClick={() => handleCopy("script1")}>Copy</Button>
                </>
              ) : (null)
            }
          </Col>
          <h5>
            Always Protruding
          </h5>
          <hr></hr>
          <Col md={12}>
            <div className="text-left">
              <button type="submit" className="btn btn-success" onClick={() => generateScript("script2")}>Generate Script</button>
            </div>
            {
              showScript2 ? (
                <>
                  <div className="mt-2 bg-soft-light-purple scriptdiv script2 text-primary">
                    {script2}
                  </div>
                  <Button className="btn btn-info mt-2" onClick={() => handleCopy("script2")}>Copy</Button>
                </>
              ) : (null)
            }
          </Col>
        </CardBody>
      </Card>
    </div>
  )
}
