import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import { useDropzone } from "react-dropzone";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Progress,
  Button
} from "reactstrap";
import axios from "axios";

//Import images
import dummyUser from "../../assets/images/users/user-dummy-img.jpg";
import logoDark from "../../assets/images/logo-dark.png";

import classnames from "classnames";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import lottie from "lottie-web";
import { defineElement } from "lord-icon-element";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EmailToolbar from "../EmailInbox/EmailToolbar";
import { useNavigate } from "react-router-dom";
// register lottie and define custom element
defineElement(lottie.loadAnimation);

const Register = () => {
  const history = useNavigate();

  const [activeTab, setactiveTab] = useState(1);
  const [activeArrowTab, setactiveArrowTab] = useState(4);
  const [activeVerticalTab, setactiveVerticalTab] = useState(7);
  const [progressbarvalue, setprogressbarvalue] = useState(0);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [passedarrowSteps, setPassedarrowSteps] = useState([1]);
  const [passedverticalSteps, setPassedverticalSteps] = useState([1]);
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone();
  const [businessname, setBusinessName] = useState('');
  const [businessaddress1, setBusinessAddress1] = useState('');
  const [businessaddress2, setBusinessAddress2] = useState('');
  const [city, setCity] = useState('');
  const [postcode, setPostcode] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState("");
  const [phone, setPhone] = useState("");
  const [module, setModule] = useState('');
  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [mobile, setMobileNumber] = useState('');
  const [webaddress, setWebAddress] = useState('');
  const [abn, setABN] = useState('');
  const [isgst, setIsGst] = useState('');
  const [employeecount, setEmployeecount] = useState('');
  const [countriesList, setCountriesList] = useState('');
  const [business_industries, setBusinessIndustries] = useState('');
  const [sys_modules, setSysModules] = useState('');
  const [tab1submitted, setTab1submitted] = useState(false);
  const [tab2submitted, setTab2submitted] = useState(false);
  const [tab3submitted, setTab3submitted] = useState(false);
  const [email, setEmailInput] = useState('');
  const [emailexists, setEmailexists] = useState('');
  const [password, setPassword] = useState('');
  const [passmatch, setPassmatch] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');
  const [otp, setOTP] = useState('');
  
  useEffect(() => {
    get_data();
}, []);

const get_data = () => {
    axios.get('/api/registration').then((res)=>{
        setCountriesList(res.countries);
        setBusinessIndustries(res.business_industries);
        setSysModules(res.sys_modules);
        setCountry(res.countries[0].country_id);
        setModule(res.sys_modules[0].id);
    })
};

  function toggleTab(tab, value) {
    if(activeTab == 1){
        setTab1submitted(true);
        if(businessname =="" || businessaddress1 =="" || businessaddress2 == "" || city == "" ||
        postcode == "" || state == ""){
            toast.error("Enter the missing information")
            return false;
        }
    }
    if(activeTab == 2){
      setTab2submitted(true);
      if(phone == "" || employeecount == ""){
          toast.error("Enter the missing information")
          return false;
      }
  }
    if(activeTab == 3){
      setTab3submitted(true);
      if(firstname == "" || lastname == "" || email == "" || password == "" || confirmpassword == "" || mobile == "" || webaddress == "" || abn == ""){
        toast.error("Enter the missing information")
        return false;
      }
      var data={
        "email": email,
        "user_name": firstname+ " "+lastname,
      }
    }
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];

      if (tab >= 1 && tab <= 5) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
    setprogressbarvalue(value);
  }

  function handleEmailBlur(){
    axios.get('/api/checkemail/?business_email='+email).then((res) =>{
      (res != true) ? setEmailexists(res) : setEmailexists('');
    })
  }
  const handlePasswordBlur = () =>{
    //console.log(inputValue)
    var msg = "Enter confirm password same as password";
    (password != confirmpassword) ? setPassmatch(msg): setPassmatch('');
  }
  const handleEmailChange = (e) => {
    const email = e.target.value;
    setEmailInput(email);
  };

  function toggleArrowTab(tab) {
    if (activeArrowTab !== tab) {
      var modifiedSteps = [...passedarrowSteps, tab];
      if (tab >= 5 && tab <= 7) {
        setactiveArrowTab(tab);
        setPassedarrowSteps(modifiedSteps);
      }
    }
  }

  function SendAgain(){
    var data={
      "email": email,
      "user_name": firstname+ " "+lastname,
    }
    // axios.post('/api/registration-generate-opt', data).then((res)=>{
    //   console.log(res)

    // })
    //return false;
  }

  function VerifyOTP(){
    var data = {
      "email": email,
      "otp_code": otp
    }
    axios.post('/api/verify-opt',data).then((res)=>{
      var postData = new FormData()
      postData.append("business_email", email);
      postData.append("otp_code", otp);
      postData.append("business_name", businessname);
      postData.append("business_address1", businessaddress1);
      postData.append("business_address2", businessaddress2);
      postData.append("business_phone", phone);
      postData.append("city", city);
      postData.append("state", state);
      postData.append("postcode", postcode);
      postData.append("country", country);
      postData.append("first_name", firstname);
      postData.append("last_name", lastname);
      postData.append("sys_module_id", module);
      postData.append("business_logo", uploadedFiles);
      postData.append("password", password);
      postData.append("mobile", mobile);
      postData.append("website_address", webaddress);
      postData.append("abn", abn);
      postData.append("gst_registered", isgst);

      console.log(postData)
      if(res.error == 0){
        createTenant(postData);

      }else{
        toast.error("OTP did not match. Please enter the correct OTP");
      }
    })
  }

  function createTenant(data){
    axios.post('/api/registration',data).then((res) =>{
      if(res.error == 0){
        setactiveTab(5);
        toast.success(res.message);
        setTimeout(() => { history('/login'); }, 3000);
      }
    })
  }
  function toggleVerticalTab(tab) {
    if (activeVerticalTab !== tab) {
      var modifiedSteps = [...passedverticalSteps, tab];
      if (tab >= 7 && tab <= 11) {
        setactiveVerticalTab(tab);
        setPassedverticalSteps(modifiedSteps);
      }
    }
  }
  document.title = "Form Wizard | Onexfort";
  const [selectedFiles, setselectedFiles] = useState([]);
  const [uploadedFiles, setUploadedFile] = useState([]);
  const [files, setFiles] = useState([]);

  function handleAcceptedFiles(files) {
    var uploadedFile = files[0];
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setUploadedFile(uploadedFile);
    setselectedFiles(files);
  }
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  return (
    <React.Fragment>
      <div className="auth-page-wrapper">
        <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
          <div className="bg-overlay"></div>
        </div>
        <div className="page-content pt-5">
          <Container>
            <Row>
              <Col lg={12}></Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-0">
                  <CardBody className="p-4">
                    <div className="text-center mt-sm-2 mb-2 text-white-50">
                      <div>
                        <Link to="/" className="d-inline-block auth-logo">
                          <img src={logoDark} alt="" height="50" />
                        </Link>
                      </div>
                    </div>

                    <Form action="#" className="form-steps">
                     {activeTab !=5 && <div className="progress-nav mb-4">
                        <Progress
                          value={progressbarvalue}
                          style={{ height: "1px" }}
                        />

                        <Nav
                          className="nav-pills progress-bar-tab custom-nav"
                          role="tablist"
                        >
                          <NavItem>
                            <NavLink
                              to="#"
                              id="pills-gen-info-tab"
                              className={classnames(
                                {
                                  active: activeTab === 1,
                                  done: activeTab <= 6 && activeTab >= 0,
                                },
                                "rounded-pill"
                              )}
                              onClick={() => {
                                toggleTab(1, 0);
                              }}
                              tag="button"
                            >
                              1
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              to="#"
                              id="pills-gen-info-tab"
                              className={classnames(
                                {
                                  active: activeTab === 2,
                                  done: activeTab <= 6 && activeTab > 1,
                                },
                                "rounded-pill"
                              )}
                              onClick={() => {
                                toggleTab(2, 50);
                              }}
                              tag="button"
                            >
                              2
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              to="#"
                              id="pills-gen-info-tab"
                              className={classnames(
                                {
                                  active: activeTab === 3,
                                  done: activeTab <= 6 && activeTab > 2,
                                },
                                "rounded-pill"
                              )}
                              onClick={() => {
                                toggleTab(3, 75);
                              }}
                              tag="button"
                            >
                              3
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              to="#"
                              id="pills-gen-info-tab"
                              className={classnames(
                                {
                                  active: activeTab === 4,
                                  done: activeTab <= 6 && activeTab > 3,
                                },
                                "rounded-pill"
                              )}
                              onClick={() => {
                                toggleTab(4, 100);
                              }}
                              tag="button"
                            >
                              4
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </div>}

                      <TabContent activeTab={activeTab}>
                        <TabPane tabId={1}>
                          <div>
                            <div className="mb-4">
                              <div className="text-center">
                                <h5 className="mb-1">
                                  TELL US ABOUT YOUR BUSINESS
                                </h5>
                                <p className="text-muted">
                                  We'll use this information to personalise your
                                  quote and invoices.
                                </p>
                              </div>
                            </div>
                            <Row>
                              <Col lg={12}>
                                <div className="mb-2">
                                  <Label className="form-label" htmlFor="">
                                    Business Name
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id=""
                                    placeholder="Business Name"
                                    onChange={(e) => {
                                        setBusinessName(e.target.value);}}
                                  />
                                  { tab1submitted && businessname =="" && (<label className="pt-1 text-danger" htmlFor="">
                                    Enter Business Name
                                  </label>)}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={12}>
                                <div className="mb-2">
                                  <Label className="form-label" htmlFor="">
                                    Business Address 1
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id=""
                                    placeholder="Business Address 1"
                                    onChange={(e) => {
                                        setBusinessAddress1(e.target.value);}}
                                  />
                                  {tab1submitted && businessaddress1 =="" && (<label className="pt-1 text-danger" htmlFor="">
                                    Enter Business Address 1
                                  </label>)}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={12}>
                                <div className="mb-2">
                                  <Label className="form-label" htmlFor="">
                                    Business Address 2
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id=""
                                    placeholder="Business Address 2"
                                    onChange={(e) => {
                                        setBusinessAddress2(e.target.value);}}
                                  />
                                  {tab1submitted && businessaddress2 =="" && (<label className="pt-1 text-danger" htmlFor="">
                                    Enter Business Address 2
                                  </label>)}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={6}>
                                <div className="mb-2">
                                  <Label className="form-label pt-1" htmlFor="">
                                    City
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id=""
                                    placeholder="City Name"
                                    onChange={(e) => {
                                        setCity(e.target.value);}}
                                  />
                                {tab1submitted && city =="" && (<label className="pt-1 text-danger" htmlFor="">
                                    Enter City
                                  </label>)}
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="mb-2">
                                  <Label className="form-label" htmlFor="">
                                    Postcode
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id=""
                                    value={postcode}
                                    placeholder="Postcode"
                                    onChange={(e) => {
                                      var value = e.target.value.replace(/[^0-9]/g, '');
                                        setPostcode(value);}}
                                  />
                                {tab1submitted && postcode =="" && (<label className="pt-1 text-danger" htmlFor="">
                                    Enter Postcode
                                  </label>)}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={6}>
                                <div className="mb-2">
                                  <Label className="form-label" htmlFor="">
                                    State
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id=""
                                    placeholder="State Name"
                                    onChange={(e) => {
                                        setState(e.target.value);}}
                                  />
                                {tab1submitted && state =="" && (<label className="pt-1 text-danger" htmlFor="">
                                    Enter State
                                  </label>)}
                                </div>
                              </Col>

                              <Col lg={6}>
                                <div className="mb-2">
                                  <Label className="form-label" htmlFor="">
                                    Country
                                  </Label>
                                  <select
                                    className="form-select mb-3"
                                    aria-label="Default select example"
                                    onChange={(e) => {
                                        setCountry(e.target.value);}}
                                  >
                                    {(countriesList) ? countriesList.map((res) =>(
                                        <option key={res.id} value={res.country_id}>{res.country_name}</option>
                                    )) : ''}
                                  </select>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg={12}>
                                <Card className="dz-card">
                                  <Label>Business logo</Label>
                                  <CardBody className="dz-card-body">
                                    <Dropzone
                                      onDrop={(acceptedFiles) => {
                                        handleAcceptedFiles(acceptedFiles);
                                      }}
                                    >
                                      {({ getRootProps, getInputProps }) => (
                                        <div className="dropzone dz-clickable">
                                          <div
                                            className="dz-message dz-box needsclick"
                                            {...getRootProps()}
                                          >
                                            <input {...getInputProps()}/>
                                            <div className="mb-0">
                                              <i className="display-6 text-muted ri-upload-cloud-2-fill" />
                                            </div>
                                            <h6>
                                              Drop files here or click to
                                              upload.
                                            </h6>
                                          </div>
                                        </div>
                                      )}
                                    </Dropzone>
                                    <div
                                      className="list-unstyled mb-0"
                                      id="file-previews"
                                    >
                                      {selectedFiles.map((f, i) => {
                                        return (
                                          <Card
                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                            key={i + "-file"}
                                          >
                                            <div className="p-2">
                                              <Row className="align-items-center">
                                                <Col className="col-auto">
                                                  <img
                                                    data-dz-thumbnail=""
                                                    height="80"
                                                    className="avatar-sm rounded bg-light"
                                                    alt={f.name}
                                                    src={f.preview}
                                                  />
                                                </Col>
                                                <Col>
                                                  <Link
                                                    to="#"
                                                    className="text-muted font-weight-bold"
                                                  >
                                                    {f.name}
                                                  </Link>
                                                  <p className="mb-0">
                                                    <strong>
                                                      {f.formattedSize}
                                                    </strong>
                                                  </p>
                                                </Col>
                                              </Row>
                                            </div>
                                          </Card>
                                        );
                                      })}
                                    </div>
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          </div>
                          <div className="text-center  mt-0">
                            <button
                              type="button"
                              className="btn btn-brown ms-auto nexttab nexttab"
                              onClick={() => {
                                toggleTab(activeTab + 1, 50);
                              }}
                            >
                              Next
                            </button>
                          </div>
                        </TabPane>

                        <TabPane tabId={2}>
                          <div>
                            <div className="mb-4">
                              <div className="text-center">
                                <h6 className="mb-1">
                                  WHAT DOES YOUR BUSINESS DO?
                                </h6>
                                <p className="text-muted">
                                  This will help us customise the account to
                                  work best for your industry and size.
                                </p>
                              </div>
                            </div>
                            <Row>
                              <Col lg={12}>
                                <Label>Module</Label>
                                <select
                                  className="form-select mb-3"
                                  aria-label="Default select example"
                                  onChange={(e) => {
                                    setModule(e.target.value);}}
                                >
                                  {(sys_modules) ? sys_modules.map((res) =>(
                                        <option key={res.id} value={res.id}>{res.module_name}</option>
                                    )) : ''}
                                </select>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={12}>
                                <div className="mb-3">
                                  <Label className="form-label" htmlFor="">
                                    Business Phone Number
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id=""
                                    value={phone}
                                    placeholder="Business Phone Number"
                                    onChange={(e) => {
                                      var value = e.target.value.replace(/[^0-9]/g, '');
                                      setPhone(value);}}
                                  />
                                  {tab2submitted && phone =="" && (<label className="pt-1 text-danger" htmlFor="">
                                    Enter Business Phone Number
                                  </label>)}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={12}>
                                <div className="mb-3">
                                  <Label className="form-label" htmlFor="">
                                    Number Of Employees
                                  </Label>
                                  <Input
                                    type="number"
                                    className="form-control"
                                    id=""
                                    value={employeecount}
                                    placeholder="Number Of Employees"
                                    onChange={(e) => {
                                      var value = e.target.value.replace(/[^0-9]/g, '');
                                      setEmployeecount(value);}}
                                  />
                                  {tab2submitted && employeecount =="" && (<label className="pt-1 text-danger" htmlFor="">
                                    Enter Number of Employees
                                  </label>)}
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <div className="d-flex align-items-start gap-3 mt-4">
                            <button
                              type="button"
                              className="btn btn-link text-decoration-none btn-label previestab"
                              onClick={() => {
                                toggleTab(activeTab - 1, 0);
                              }}
                            >
                              <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                              Previous
                            </button>
                            <div className=" right ms-auto ">
                              <button
                                type="button"
                                className="btn btn-brown nexttab nexttab"
                                onClick={() => {
                                  toggleTab(activeTab + 1, 100);
                                }}
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        </TabPane>

                        <TabPane tabId={3}>
                          <div>
                            <div className="mb-4">
                              <div className="text-center">
                                <h6 className="mb-1">
                                  SETUP YOUR BUSINESS DETAILS
                                </h6>
                                <p className="text-muted">
                                  This will be used on your quotes, invoices and
                                  email signature.
                                </p>
                              </div>
                            </div>
                            <Row>
                              <Col lg={6}>
                                <div className="mb-2">
                                  <Label className="form-label" htmlFor="">
                                    Your First Name
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id=""
                                    placeholder="First Name"
                                    onChange={(e) => {
                                      setFirstName(e.target.value);}}
                                  />
                                  {tab3submitted && firstname =="" && (<label className="pt-1 text-danger" htmlFor="">
                                    Enter First Name
                                  </label>)}
                                </div>
                              </Col>

                              <Col lg={6}>
                                <div className="mb-2">
                                  <Label className="form-label" htmlFor="">
                                    Your Last Name
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id=""
                                    placeholder="Last Name"
                                    onChange={(e) => {
                                      setLastName(e.target.value);}}
                                  />
                                  {tab3submitted && lastname =="" && (<label className="pt-1 text-danger" htmlFor="">
                                    Enter Last Name
                                  </label>)}
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg={12}>
                                <div className="mb-2">
                                  <Label className="form-label" htmlFor="">
                                    Business Email Address
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id=""
                                    placeholder="Business Email Address"
                                    onBlur={() => handleEmailBlur()}
                                    onChange={handleEmailChange}
                                  />
                                  {tab3submitted && email =="" && (<label className="pt-1 text-danger" htmlFor="">
                                    Enter Business Email Address
                                  </label>)}
                                  {emailexists && <label className="text-danger pt-2">{emailexists}</label>}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={12}>
                                <div className="mb-2">
                                  <Label className="form-label" htmlFor="">
                                    Enter Password
                                  </Label>
                                  <Input
                                    type="password"
                                    className="form-control"
                                    id=""
                                    placeholder="******"
                                    onChange={(e) => {
                                      setPassword(e.target.value);}}
                                  />
                                  {tab3submitted && password =="" && (<label className="pt-1 text-danger" htmlFor="">
                                    Enter Password
                                  </label>)}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={12}>
                                <div className="mb-2">
                                  <Label className="form-label" htmlFor="">
                                    Confirm Password
                                  </Label>
                                  <Input
                                    type="password"
                                    className="form-control"
                                    id=""
                                    placeholder="Confirm Password"
                                     onBlur={ () => handlePasswordBlur()}
                                     onChange={(e) => setConfirmPassword(e.target.value)}
                                  />
                                  {tab3submitted && confirmpassword =="" && (<label className="pt-1 text-danger" htmlFor="">
                                    Enter Confirm Password
                                  </label>)}
                                  {passmatch && <label className="text-danger pt-2">{passmatch}</label>}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={12}>
                                <div className="mb-2">
                                  <Label className="form-label" htmlFor="">
                                    Your Mobile Number
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id=""
                                    value={mobile}
                                    placeholder="Mobile Number"
                                    onChange={(e) => {
                                      var value = e.target.value.replace(/[^0-9]/g, '');
                                      setMobileNumber(value);}}
                                  />
                                  {tab3submitted && mobile =="" && (<label className="pt-1 text-danger" htmlFor="">
                                    Enter Mobile Number
                                  </label>)}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={12}>
                                <div className="mb-2">
                                  <Label className="form-label" htmlFor="">
                                    Web Address
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id=""
                                    placeholder="Website Address"
                                    onChange={(e) => {
                                      setWebAddress(e.target.value);}}
                                  />
                                  {tab3submitted && webaddress =="" && (<label className="pt-1 text-danger" htmlFor="">
                                    Enter Web Address
                                  </label>)}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={12}>
                                <div className="mb-2">
                                  <Label className="form-label" htmlFor="">
                                    ABN
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id=""
                                    value={abn}
                                    placeholder="00 000 000 000"
                                    onChange={(e) => {
                                      var value = e.target.value.replace(/[^0-9]/g, '').slice(0, 11);
                                      setABN(value);}}
                                  />
                                  {tab3submitted && abn =="" && (<label className="pt-1 text-danger" htmlFor="">
                                    Enter ABN
                                  </label>)}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={12}>
                                <div className="mb-2">
                                  <Input type="checkbox" onChange={(e)=>
                                  (e.target.value)? 
                                  setIsGst("1"): setIsGst("")}></Input>
                                  <Label className="cb-lbl">
                                    Are You GST registered?
                                  </Label>
                                </div>
                              </Col>
                            </Row>
                            <div className="d-flex align-items-start gap-3 mt-4">
                              <button
                                type="button"
                                className="btn btn-link text-decoration-none btn-label previestab"
                                onClick={() => {
                                  toggleTab(activeTab - 1, 0);
                                }}
                              >
                                <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                                Previous
                              </button>
                              <div className=" right ms-auto ">
                                <button
                                  type="button"
                                  className="btn btn-brown nexttab nexttab"
                                  onClick={() => {
                                    toggleTab(activeTab + 1, 100);
                                  }}
                                >
                                  Verify Email
                                </button>
                              </div>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId={4}>
                          <div>
                            <div className="text-center">
                              {/* <div className="mb-4">
                                <lord-icon
                                  src="https://cdn.lordicon.com/lupuorrc.json"
                                  trigger="loop"
                                  colors="primary:#0ab39c,secondary:#6691e7"
                                  style={{ width: "120px", height: "120px" }}
                                ></lord-icon>
                              </div> */}
                              <h5>One-Time Password</h5>
                              <p className="text-muted">
                              You will be receiving an email that contains the one-time passcode to continue with your registration process. Please make sure to check the SPAM/Junk folders of your email. This code will expire in 5 minutes. If you don't receive the email, click on the 
                              <a onClick={() => SendAgain()}> Send Again</a>
                              </p>
                              <Input type="text" placeholder="Enter OTP" onChange={(e) => setOTP(e.target.value)}/>
                              <Button className="btn btn-brown nexttab nexttab mt-3" onClick={()=> VerifyOTP()}>Submit</Button>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId={5}>
                        <div>
                          <div className="text-center">
                          <div className="mb-4">
                            <lord-icon
                              src="https://cdn.lordicon.com/lupuorrc.json"
                              trigger="loop"
                              colors="primary:#0ab39c,secondary:#6691e7"
                              style={{ width: "120px", height: "120px" }}
                            ></lord-icon>
                            </div>
                            <h5>Well Done !</h5>
                            <p className="text-muted">
                            You have Successfully Signed Up
                            </p>
                            </div>
                            </div>
                         </TabPane>
                      </TabContent>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <ToastContainer closeButton={false} limit={1} />
          </Container>
        </div>
        <footer className="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="text-center">
                  <p className="mb-0 footer_copyright">
                    &copy; {new Date().getFullYear()} Onexfort.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default Register;
