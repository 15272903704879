import { Card, CardBody, Col, Table, CardHeader, Row, Button, Modal, ModalBody } from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';

import { toast, ToastContainer } from 'react-toastify';
import DeleteModal from "../../../../Components/Common/DeleteModal";

export const VehicleDailyChecklist = () => {
  document.title="Vehicles Daily Checklist | Onexfort";
  const [group_name, setGroupName] = useState([]);
  const [groupid, setGroupId] = useState(null);
  const [itemId, setItemId] = useState(null);
  const [updateId, setUpdateId] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const [tempValue, setTempValue] = useState("");
  const [checklistName, setChecklistName] = useState([]);

  //list table
  const loadTable = () => {
    axios.get("/api/Cleaning-questions-checklist")
      .then(res => {
        console.log(res);
        setGroupName(res);

      })
      .catch(err => {
        console.log(err);
      })
  }

  useEffect(() => {
    loadTable();
  }, [])


  // console.log(group_name,"helooooooooooooooooooooooooooooow")
  //rowsdata
  const [rowsData, setRowsData] = useState([]);
  const [rowsCheck, setRowsCheck] = useState([]);
  //////// CHECKLIST ////////
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [selectedChecklistId, setSelectedChecklistId] = useState();
  //Edit
  const handleEdit = (index, item, tableType) => {
    console.log(item);

    setTempValue(item.cleaning_question);
    if (tableType === "table1") {
      setGroupId(item.id);
      setGroupName((prevGroupNames) => {
        const updatedGroupNames = [...prevGroupNames];
        updatedGroupNames[index].isEditing = true;
        return updatedGroupNames;
      });
    }
    else if (tableType === 'table2') {
      setGroupId(item.group_id);
      setUpdateId(item.id)
      setChecklistName((prevChecklistNames) => {
        const updatedChecklistNames = [...prevChecklistNames];
        updatedChecklistNames[index].isEditing = true;
        return updatedChecklistNames;
      });
    }

  };
  useEffect(() => {
    console.log(tempValue);
  }, [tempValue]);

  //edit-> save
  const handleSave = (index, tableType) => {
    if (tableType === "table1") {
      let data = {
        "cleaning_question": inputValue,
        "group_id": groupid
      }

      axios.post("/api/ajaxCleaningUpdateGroup", data)
        .then(res => {
          console.log("successfully edited");
          setGroupName((prevGroupNames) => {
            const updatedGroupNames = [...prevGroupNames];
            updatedGroupNames[index].isEditing = false;
            return updatedGroupNames;
          });

        })
        .catch(err => {
          console.log(err);

        })
    }
    else if (tableType === "table2") {
      let data = {
        "group_id": selectedChecklistId,
        "update_id": updateId,
        "choice": inputValue2

      }
      console.log(data);
      axios.post("/api/ajaxUpdatecleaningoptions", data)
        .then(res => {
          console.log(res);
          setChecklistName((prevChecklistNames) => {
            const updatedChecklistNames = [...prevChecklistNames];
            updatedChecklistNames[index].isEditing = false;
            return updatedChecklistNames;
          })
        })
        .catch(err => {
          console.log(err);
        })
    }

  };

  //edit ->cancel
  const handleCancel = (index, tableType) => {
    console.log(tempValue);
    if (tableType === "table1") {
      loadTable();
      setGroupName((prevGroupNames) => {
        const updatedGroupNames = [...prevGroupNames];
        updatedGroupNames[index].isEditing = false;
        return updatedGroupNames;
      });
    }
    else if (tableType === "table2") {
      loadChecklist();
      setChecklistName((prevChecklistNames) => {
        const updatedChecklistNames = [...prevChecklistNames];
        updatedChecklistNames[index].isEditing = false;
        return updatedChecklistNames;
      });
    }

  };

  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [infoMessage,setInfoMessage]=useState("")

  const handleDeleteOrder = async () => {
    console.log(itemId)
    if (tableModal==="table1"){
    let data = {
      "id": itemId
    }
    axios.post("/api/ajaxCleaningDestroyGroup", data)
      .then(res => {
        loadTable();
        setDeleteModal(false);
        if (res.error===0){
          toast.success(res.message);
        }
        else{
          setInfoModal(true)
          setInfoMessage(res.message)
        }
      })
      .catch(err => {
        console.log(err);
      })
    // console.log(selectedinvoice);
    // var postData = {

    //   "crm_opportunity_id": oppid,
    //   "lead_id": leadid,
    //   "id": deleteItemId,
    //   "sys_job_type": data.quote.sys_job_type,
    //   "quote_id": quoteid,

    // }
    // axios.post("api/ajaxDestroyQuoteItem", postData).then((res) => {
    //   if (res.error == 0) {
    //     toast.success(res.message);
    //     onChangeData();
    //     t_deleteInvoice();
    //     setDeleteModal(false);
    //   }
    //   else {
    //     toast.error("Something went wrong");
    //   }
    // })
    }
    else if (tableModal==="table2"){
      setDeleteModal(false)
       let data = {
        "id": itemId,
        "selected_group_id": groupid
      }
      axios.post("/api/ajaxDeletecleaningoptions", data)
        .then(res => {
          loadChecklist();
          
        })
        .catch(err => {
          console.log(err);
        })
    }
  };
  console.log(groupid)
  const [tableModal,setTableModal]=useState("")
  const handleDelete = (index, item, tableType) => {
    if (tableType === "table1") {
      // setDeleteItemId(id);
      setTableModal("table1")
      setDeleteModal(true);
      setItemId(item.id);
    }
    else if (tableType === "table2") {
      console.log(item)
      setNewCheckInput("")
      setTableModal("table2")
      setDeleteModal(true);
      setItemId(item.id);
      setGroupId(groupid);
      // let data = {
      //   "id": item.id,
      //   "selected_group_id": groupid
      // }
      // axios.post("/api/ajaxDeleteGroupChecklist", data)
      //   .then(res => {
      //     loadChecklist();
      //   })
      //   .catch(err => {
      //     console.log(err);
      //   })
    }
  }

  const [infoModal, setInfoModal] = useState(false);
  const infoModalCloseClick = () => {
      setInfoModal(false)
  }
  // input change
  const handleChange = (index, event, tableType, inputType) => {
    const { value } = event.target;
    if (tableType === "table1") {
      setInputValue(value);
      setGroupName((prevGroupNames) => {
        const updatedGroupNames = [...prevGroupNames];
        updatedGroupNames[index].cleaning_question = value;
        return updatedGroupNames;
      });
    }
    else if (tableType === 'table2') {
      setInputValue2(value);
      if (inputType === 'cleaning_question') {
        setChecklistName((prevChecklistNames) => {
          const updatedChecklistNames = [...prevChecklistNames];
          updatedChecklistNames[index].cleaning_question = value;
          return updatedChecklistNames;
        });

      } else if (inputType === 'choice') {
        setChecklistName((prevChecklistNames) => {
          const updatedChecklistNames = [...prevChecklistNames];
          updatedChecklistNames[index].choice = value;
          return updatedChecklistNames;
        });
      }
    }
  };

  const [newGroupNames, setNewGroupNames] = useState([]);
  const addTableRows = () => {
    const rowsInput = {
      groupName: '',
    }
    setRowsData([...rowsData, rowsInput])
  }

  const addChecklist = () => {
    const rowsInput = {
      groupName: '',
      checklistName: '',

    }
    setRowsCheck([...rowsCheck, rowsInput])
  }

  //Add new row
  const [newInput, setNewInput] = useState({});
  const [newCheckInput, setNewCheckInput] = useState("");

  const handleNewChange = (index, event, tableType, inputType) => {
    if (tableType === "table1") {
      const { value } = event.target;
      setNewInput((prevNewInput) => ({
        ...prevNewInput,
        cleaning_question: value,
      }));
      setNewGroupNames((prevNewGroupNames) => {
        const updatedNewGroupNames = [...prevNewGroupNames];
        updatedNewGroupNames[index] = value;
        return updatedNewGroupNames;
      });
    }
    else if (tableType === "table2") {
      if (inputType === "checklistid") {
        setSelectedChecklistId(event.target.value);
        console.log(selectedChecklistId);
      }
      else if (inputType === "checklistname") {
        setNewCheckInput(event.target.value);
      }
    }
  };

  //create->row
  const saveTableRow = (index, tableType) => {
    
    if (tableType === "table1") {
      const updatedRowsData = [...rowsData];
      updatedRowsData[index].groupName = newGroupNames[index];
      setRowsData(updatedRowsData);

      console.log(updatedRowsData)

      if (updatedRowsData[index].groupName==="" || updatedRowsData[index].groupName===undefined ){
        toast.error('Enter Category Name',{theme:"light"}); 
      }

      else{
      axios.post("/api/ajaxCleaningquestions", newInput)
        .then(res => {
          console.log("successfully edited");
          const updatedRows = [...rowsData];
          updatedRows.splice(index, 1);
          setRowsData([...updatedRows]);
          loadTable();
          //clear input field
          setNewGroupNames((prevGroupNames) => {
            const updatedGroupNames = [...prevGroupNames];
            updatedGroupNames[index] = '';
            return updatedGroupNames;
          });
        })
        .catch(err => {
          console.log(err);

        })
      }
    }
    else if (tableType === "table2") {
      let data = {
        "group_id": selectedChecklistId,
        "choice": newCheckInput
      }


      if (data.checklist===""){
        toast.error('Enter Chechklist Name',{theme:"light"}); 
      }
      else{
      axios.post("/api/ajaxCreatecleaningoptions", data)
        .then(res => {
          const updatedRows = [...rowsCheck];
          updatedRows.splice(index, 1);
          setRowsCheck([...updatedRows]);
          loadChecklist();
        })
        .catch(err => {
          console.log(err);
        })
      }
    }
  };





  //cancel ->row
  const deleteTableRows = (index, tableType) => {
    if (tableType === "table1") {
      const rows = [...rowsData];
      rows.splice(index, 1);
      setRowsData(rows);
      setNewGroupNames((prevGroupNames) => {
        const updatedGroupNames = [...prevGroupNames];
        updatedGroupNames[index] = '';
        return updatedGroupNames;
      });
    }
    else if (tableType === "table2") {
      const updatedRows = [...rowsCheck];
      updatedRows.splice(index, 1);
      setRowsCheck([...updatedRows]);
      setNewCheckInput("")
    }

  }
  const loadChecklist = () => {
    const data = {
      "group_id": selectedGroupId
    }
    setSelectedChecklistId(selectedGroupId);
    console.log("Selected Group ID:", data);
    axios.post("/api/ajaxLoadCleaningOptions", data)
      .then(res => {
        setChecklistName(res.checklist);
      })
      .catch(err => {
        console.log(err);
      })
  }

  return (
    <div>
      <Modal isOpen={infoModal} toggle={infoModalCloseClick} centered={true}>
                <ModalBody className="py-3 px-5">
                    <div className="mt-2 text-center">
                        <script src="https://cdn.lordicon.com/bhenfmcm.js"></script>
                        <lord-icon
                            src="https://cdn.lordicon.com/lfqzieho.json"
                            trigger="hover"
                            colors="primary:#121331"
                            style={{ width: "100px", height: "100px" }}>
                        </lord-icon>

                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you sure ?</h4>
                            <p className="text-muted mx-4 mb-0 mt-3">
                                {infoMessage}
                            </p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button
                            type="button"
                            className="btn w-sm btn-danger "
                            id="delete-record"
                            onClick={infoModalCloseClick}
                        >
                            Ok
                        </button>
                    </div>
                </ModalBody>
            </Modal>
      <DeleteModal
        show={deleteModal}
        orderId={selectedOrderId}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Card>
        <CardHeader>
          <h5 className="mb-0 text-primary">Cleaning Quote Options</h5>
        </CardHeader>
        <CardBody>
          <div className="live-preview">
            <Row>
              <Col xl={12}>
                <div className="table-responsive mt-4 mt-xl-0">
                  <Table className="table table-bordered table-nowrap align-middle mb-3">
                    <thead>
                      <tr>
                        <th scope="col">Questions</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {group_name?.map((item, index) => (
                        <tr key={item.id}>
                          <td className="fw-medium">
                            {item.isEditing ? (
                              <input
                                type="text"
                                value={item.cleaning_question}
                                onChange={(event) => handleChange(index, event, "table1")}
                                className="form-control"
                              />
                            ) : (
                              item.cleaning_question
                            )}
                          </td>
                          <td>
                            <div className="hstack gap-3 flex-wrap">
                              {item.isEditing ? (
                                <>
                                  <button
                                    className="btn btn-success"
                                    onClick={() => handleSave(index, "table1")}
                                  >
                                    Save
                                  </button>
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => handleCancel(index, "table1")}
                                  >
                                    Cancel
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    className="btn btn-sm btn-soft-info edit-list "
                                    onClick={() => handleEdit(index, item, "table1")}
                                  >
                                    <i className="bx bxs-pencil fs-12 pt-1"></i>
                                  </button>
                                  <button
                                    className="btn btn-sm btn-soft-danger  "
                                    onClick={() => handleDelete(index, item, "table1")}
                                  >
                                    <i className="ri-delete-bin-5-fill fs-16"></i>
                                  </button>
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                      {rowsData.map((data, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              type="text"
                              value={newGroupNames[index] || ''}
                              onChange={(event) => handleNewChange(index, event, "table1")}
                              name="groupName"
                              className="form-control"
                            />
                          </td>
                          <td>
                            <div className="hstack gap-2">
                              <button className="btn btn-success" onClick={() => saveTableRow(index, "table1")}>
                                Save
                              </button>
                              <button className="btn btn-primary" onClick={() => deleteTableRows(index, "table1")}>
                                Cancel
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <button className="btn btn-primary" onClick={addTableRows} >+</button>
                </div>
              </Col>
            </Row>
          </div>
                
        </CardBody>
      </Card>
      <Card className="mt-1">
        <CardHeader>
          <h5 className="mb-0 text-brown">Options</h5>
        </CardHeader>
        <CardBody>
          <Row>
            <Col lg={4}>
              <h6 className="mb-0 mt-3">Choose Questions</h6>
            </Col>
            <Col lg={4}>
              <select className="form-select mb-3" aria-label="Default select example"
                onChange={(event) => setSelectedGroupId(event.target.value)}>
                <option >Select Questions </option>
                {group_name?.map((item) => (
                  <option key={item.id} value={item.id}>{item.cleaning_question}</option>
                ))}
              </select>
            </Col>
          </Row>
          <Button className="btn btn-brown mb-4"
            onClick={() => loadChecklist()}><i className="bx bx-check"
            /> Load Options</Button>
          <div className="live-preview">
            <Row>
              <Col xl={12}>
                <div className="table-responsive mt-4 mt-xl-0">
                  <Table className="table table-bordered table-nowrap align-middle mb-3">
                    <thead>
                      <tr>
                        <th scope="col">Questions</th>
                        <th scope="col">Options</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {checklistName.map((item, index) => (
                        <tr key={item.id}>
                          <td className="fw-medium">
                            {item.isEditing ? (
                              <select
                                value={selectedChecklistId}
                                onChange={(event) => setSelectedChecklistId(event.target.value)}
                                className="form-select "
                                aria-label="Default select example"
                              >
                                {group_name?.map((groupItem) => (
                                  <option key={groupItem.id} value={groupItem.id}>
                                    {groupItem.cleaning_question}
                                  </option>
                                ))}
                              </select>
                            ) : (
                              item.cleaning_question
                            )}
                          </td>
                          <td className="fw-medium">
                            {item.isEditing ? (
                              <input
                                type="text"
                                value={item.choice}
                                onChange={(event) => handleChange(index, event, "table2", "choice")}
                                className="form-control"
                              />
                            ) : (
                              item.choice
                            )}
                          </td>
                          <td>
                            <div className="hstack gap-3 flex-wrap">
                              {item.isEditing ? (
                                <>
                                  <button
                                    className="btn btn-success"
                                    onClick={() => handleSave(index, "table2")}
                                  >
                                    Save
                                  </button>
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => handleCancel(index, "table2")}
                                  >
                                    Cancel
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    className="btn btn-sm btn-soft-info edit-list "
                                    onClick={() => handleEdit(index, item, "table2")}
                                  >
                                    <i className="bx bxs-pencil fs-12 pt-1"></i>
                                  </button>
                                  <button
                                    className="btn btn-sm btn-soft-danger  "
                                    onClick={() => handleDelete(index, item, "table2")}
                                  >
                                    <i className="ri-delete-bin-5-fill fs-16"></i>
                                  </button>
                                </>

                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                      {rowsCheck.map((data, index) => (
                        <tr key={index}>
                          <td>
                            <select
                              value={selectedChecklistId}
                              onChange={(event) => { handleNewChange(index, event, "table2", "checklistid") }}
                              className="form-select "
                              aria-label="Default select example"
                            >
                              {group_name?.map((groupItem) => (
                                <option key={groupItem.id} value={groupItem.id}>
                                  {groupItem.cleaning_question}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <input
                              type="text"

                              onChange={(event) => handleNewChange(index, event, "table2", "checklistname")}

                              className="form-control"
                            />
                          </td>
                          <td>
                            <div className="hstack gap-2">
                              <button className="btn btn-success" onClick={() => saveTableRow(index, "table2")}>
                                Save
                              </button>
                              <button className="btn btn-primary" onClick={() => deleteTableRows(index, "table2")}>
                                Cancel
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}

                    </tbody>
                  </Table>
                  <button className="btn btn-primary" onClick={addChecklist} >+</button>
                </div>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
      <ToastContainer closeButton={false} limit={1} />
    </div>
  )
}
