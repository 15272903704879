import React, { useState, useEffect } from 'react';

import { Card, CardBody, CardHeader, Col, Container, Row, Table } from "reactstrap";
import TableInput from './checklist.js';
import { Link } from "react-router-dom";
// import Components
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { get_cookie } from '../../helpers/get_cookie';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteModal from "../../Components/Common/DeleteModal";
import Loader from "../../Components/Common/Loader"

const PeopleSettings = () => {


  const [tax, setTax] = useState('')
  const [rowsData, setRowsData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);


  document.title = "People Settings | Onexfort";

  const [isLoading, setisLoading] = useState(false);


  const loadTable = () => {
    setisLoading(true);

    const user = JSON.parse(get_cookie("authUser"));
    var tenant_id = user.tenant_id;
    var datavalue = {
      "tenant_id": tenant_id
    }
    axios.get("/api/manage-roles")
      .then((res) => {
        console.log(res);
        setTax(res);
        setisLoading(false);

      });
  }
  useEffect(() => {

    loadTable();
  }, []);


  const addTableRows = () => {
    setRowsData([...rowsData, { groupTax: '', groupRate: '' }]);
  };

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    if (name === 'category_name') {
      const updatedProductCategory = [...tax];
      updatedProductCategory[index].display_name = value;
      setTax(updatedProductCategory);
    } else if (name === 'groupTax') {
      const updatedRowsData = [...rowsData];
      updatedRowsData[index].groupTax = value;
      setRowsData(updatedRowsData);
    }
  };

  const handleNewChange = (index, event) => {
    const { name, value } = event.target;
    if (name === 'category_name') {
      const updatedProductCategory = [...tax];
      updatedProductCategory[index].description = value;
      setTax(updatedProductCategory);
    } else if (name === 'groupRate') {
      const updatedRowsData = [...rowsData];
      updatedRowsData[index].groupRate = value;
      setRowsData(updatedRowsData);
    }
  };
  console.log(rowsData, "rowsdata")
  console.log(tax, "tax")

  //Edit

  const handleEdit = (index) => {
    const updatedProductCategory = [...tax];
    updatedProductCategory[index].isEditing = true;
    setTax(updatedProductCategory);
  };
  //cancel button for edit
  const handleCancel = (index) => {
    const updatedProductCategory = [...tax];
    updatedProductCategory[index].isEditing = false;
    setTax(updatedProductCategory);
  };
  //save(Update)
  const handleSave = async (index) => {
    const item = tax[index];
    console.log('update:', item);
    console.log("data", item)
    var datavalue = {
      "id": item.id,
      "display_name": item.display_name,
      "description": item.description
    }

    axios.post('/api/ajax-update-role', datavalue)
      .then(res => {
        toast.success(res.message, { theme: "light" });
        loadTable();
      })
      .catch(error => {
        console.error('Error fetching lead types:', error);
      });
  };

  const [deleteId,setDeleteId]= useState('')
  //Delete(Upadte)
  const handleDelete = async (index) => {

    const item = tax[index];
    var id = item.id;
    setDeleteId(id)
    setDeleteModal(true)
  };
  const handleDeletePermission = async () => {

    
    var deleteval = {
      "id": deleteId,
    }
    axios.post('/api/ajax-destroy-role', deleteval)
      .then(res => {
        console.log(res.success);
        toast.success(res.message, { theme: "light" });
        loadTable();
        setDeleteModal(false)
      })
      .catch(error => {
        console.error('Error fetching lead types:', error);
      });
  };
  //SAVE(new row)
  const saveTableRow = (index) => {
    const data = rowsData[index];
    // console.log('Saving:', data.groupName);
    var datavalue = {
      "display_name": data.groupTax,
      "description": data.groupRate
    }
    if(data.groupTax==''){
      toast.error("The display name field is required.", { theme: "light" });
      return;
    }
    if(data.groupRate==''){
      toast.error("The description field is required.", { theme: "light" });
      return;
    }
    axios.post('/api/ajax-create-role', datavalue)
      .then(res => {
        console.log(res.success);
        toast.success(res.message, { theme: "light" });
        const updatedRows = [...rowsData];
        updatedRows.splice(index, 1);
        setRowsData([...updatedRows]);
        loadTable();
      })
      .catch(error => {
        console.error('Error fetching lead types:', error);
      });
  }
  //cancel(new row)
  const deleteTableRows = (index) => {
    const updatedRowsData = [...rowsData];
    updatedRowsData.splice(index, 1);
    setRowsData(updatedRowsData);
  };
  return (
    <React.Fragment>
        
      <div className="page-content">
      {isLoading && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            background: 'rgba(255, 255, 255, 0.8)',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            zIndex: 9999,
                                        }}
                                    >
                                        <Loader />
                                    </div>
                                )}
                                   <DeleteModal
  show={deleteModal}
  onDeleteClick={handleDeletePermission}
  onCloseClick={() => setDeleteModal(false)}
/>
      <ToastContainer closeButton={false} limit={1} theme="light"/>
        <Container fluid>
          <BreadCrumb title="Roles & Permission" pageTitle="Onexfort" />
          <Row>
            <Col xl={3}>
              <Card>
                <CardHeader> <h6 className='text-brown'> <i className='bx bx-list-ol '></i> Roles & Permission</h6></CardHeader>
              </Card>
            </Col>

            <Col xl={9}>
              <Card>
                <CardBody>
                  <Table className="table table-bordered   align-middle mb-3">
                    <thead className="bg-light">
                      <tr>
                        <th className="listTypeHead" >

                          Role Name
                        </th>
                        <th>
                          Description
                        </th>
                        <th>
                          Action
                        </th>

                      </tr>

                    </thead>
                    <tbody className="mb-3">
                      {tax && tax.map((item, index) => (
                        <tr key={index}>
                          <td className="fw-medium">
                            {item.isEditing ? (
                            
                              <input
                                type="text"
                                value={item.display_name}
                                onChange={(event) => handleChange(index, event)}
                                className="form-control"
                                name="category_name"
                              />
                            ) : (
                              item.display_name
                            )}
                          </td>
                          <td className="fw-medium">
                            {item.isEditing ? (
                              <input
                                type="text"
                                value={item.description}
                                onChange={(event) => handleNewChange(index, event)}
                                className="form-control"
                                name="category_name"
                              />
                            ) : (
                              item.description
                            )}
                          </td>
                          {item.tenant_id > 0 && (

                          
                          <td>
                            <div className="hstack gap-3 flex-wrap">
                              {item.isEditing ? (
                                <>
                                  <button
                                    className="btn btn-success"
                                    onClick={() => handleSave(index)}
                                  >
                                    Update
                                  </button>
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => handleCancel(index)}
                                  >
                                    Cancel
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    className="btn btn-sm btn-soft-info edit-list "
                                    onClick={() => handleEdit(index)}
                                  >
                                    <i className="bx bxs-pencil fs-12 pt-1"></i>
                                  </button>
                                  <button
                                    className="btn btn-sm btn-soft-danger  "
                                    onClick={() => handleDelete(index)}
                                  >
                                    <i className="ri-delete-bin-5-fill fs-16"></i>
                                  </button>
                                  {/* <button
                                    className="btn btn-sm btn-soft-danger  "
                                    onClick="/permission"
                                  > */}


        <a href={`/permission/${item.id}`}><i className="ri-list-check" style={{ fontSize: '22px' }}></i></a>

                                  {/* </button> */}
                                </>
                              )}
                            </div>
                          
                          </td>
)}
                        </tr>
                      ))}
                      {/* ///Adding for new rows  */}
                      {rowsData && rowsData.map((data, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              type="text"
                              value={data.groupTax}
                              onChange={(event) => handleChange(index, event)}
                              name="groupTax"
                              className="form-control"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={data.groupRate}
                              onChange={(event) => handleNewChange(index, event)}
                              name="groupRate"
                              className="form-control"
                            />
                          </td>
                          <td>
                            <div className="hstack gap-2">
                              <button className="btn btn-success"
                                onClick={() => saveTableRow(index)}
                              >
                                Save
                              </button>
                              <button className="btn btn-primary"
                                onClick={() => deleteTableRows(index)}
                              >
                                Cancel
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                      {/* <TableInput rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange={handleChange} /> */}

                    </tbody>

                    <button className="btn btn-primary mt-3" onClick={addTableRows} >+</button>
                  </Table>
                </CardBody>

              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PeopleSettings;
