import React, { useState, useEffect } from 'react'
import { Accordion, AccordionItem, Button, Card, CardBody, CardHeader, Col, Collapse, Table, Form, Label, Input, Container, Row, Modal, ModalBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import Flatpickr from "react-flatpickr";
import TableRows from "./TableRow";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import Loader from "../../../Components/Common/Loader";

export const Storage = ({ data, onChangeData }) => {
  const [rowsData, setRowsData] = useState([]);
  const [storage_types, setStorageTypes] = useState([]);
  const [storage_warehouses, setStorageWareHouses] = useState([]);
  const [storage_reservation, setStorageReservation] = useState([]);
  const [storage_jobunits, setStorageJobUnits] = useState([]);
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [taxs, setTaxes] = useState([]);
  const [showEndDate, setShowEndDate] = useState(false);
  const [discounttype, setDiscountType] = useState('');
  const [editstorageid, setEditStorageId] = useState('');
  const [showPicker, setShowPicker] = useState(false);
  const [storagetype, setStorageType] = useState();
  const [storagelocation, setStorageLocation] = useState();
  const [from_date, setFromDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [to_date, setToDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [showreserves, setShowReserveStorage] = useState(false);
  const [storagereserves, setStorageReserveData] = useState(false);
  const [storageunit, setStorageUnit] = useState(false);
  const [disableEndDate, setDisableEndDate] = useState(true);
  const [modal, setOpenModal] = useState(false);
  const[storageTab, setstorageTab] = useState([]);
  const params = useParams();
  const storage_id = params.id;
  const job_id = data?.storage?.job_id;
  var total_payments = 0;
  var total_excl_tax = 0;
  var total_incl_tax = 0;
  var rate_percent = 0;
  var total_tax = 0;
  var total_after_discount = 0;
  const [isLoader, setLoader] = useState(true);
  useEffect(()=>{
    getData();
  }, [])

  function getData(){
    var postData={
      "job_id": job_id,
    }
    axios.post('api/storageTabContent',postData).then((res)=>{
      setstorageTab(res);
      console.log(res);
      setStorageTypes(res.storage_type_list);
      setStorageWareHouses(res.storage_warehouses);
      setStorageReservation(res.storage_reservation);
      setStorageJobUnits(res.storage_job_units);
      setStorageType(res.storage_type_list[0].id);
      setStorageLocation(res.storage_warehouses[0].id)
      if(res.storage_reservation.length > 0 && res.storage_reservation[0]?.to_date == "0000-00-00"){
        setDisableEndDate(false);
        setShowEndDate(true);
      }
      if(data){
        setTimeout(function () {
          setLoader(false);
      }, 2000);
      }
  })
  }

  const addTableRows = () => {
    const rowsInput = {
    }
    setRowsData([...rowsData, rowsInput])
    console.log(rowsData);
  }

  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  }

  const handleToggle = (e) => {
    setShowPicker(e.target.checked);
  };

  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);
  }

  const EditStorage = (val) =>{
    setEditStorageId(val);
  }

  const DeleteStorage = (val) =>{
    axios.get("api/delete-storage-job/"+val).then((res)=>{
      if(res.status == "success"){
        toast.success(res.message);
        getData();
      }
    })
  }

  const DeleteUnits = (data) =>{
    var postData = {
      "id": data
    }
    axios.post("/api/ajaxDestroyStorageReservation",postData).then((res)=>{
      if(res.error == 0){
        toast.success(res.message);
        getData();
      }else{
        toast.error("Something went wrong");
      }
    })
  }

  const UpdateStorage = (val) =>{
    val.if_end_date = showEndDate.toString();
    console.log(val.if_end_date);
    if(showPicker){
    console.log();
      console.log(showPicker);
      val.if_end_date = "true";
      val.to_date = moment(new Date()).format("YYYY-MM-DD");
      console.log(val.to_date);
    }
    var postData = {
      "id": val.id,
      "datepicker_to": val.to_date,
      "datepicker_from": val.from_date,
      "volume_update":val.storage_volume,
      "update_allocation":val.allocation_status,
      "if_end_date":val.if_end_date,
      "unit_id":val.unit_id
    }
    axios.post("/api/updateReserves",postData).then((res)=>{
      if(res.error == 0){
        toast.success(res.message);
        getData();
        EditStorage();
      }
    })
  }

  const findStorage = () =>{
    var postData = {
      "storage_unit_id":storagetype,
      "storage_location":storagelocation,
      "from_date":from_date,
      "to_date":to_date
    }
    axios.post("api/find-available-storage-units", postData).then((res)=>{
      if(res.data.length > 0){
        toast.success(res.message);
        setShowReserveStorage(true)
        setStorageReserveData(res.data);
      }else{
        toast.warning(res.message);
        setShowReserveStorage(false)
        setStorageReserveData([]);
      }
    })
  }

  const SaveReservation = () =>{
    var postData={
      "lead_id": 0,
      "job_id":job_id,
      "unit_id":storageunit || storagereserves[0].id,
      "from_date":from_date,
      "to_date":to_date,
      "allocation_status":"Active",
      "enable_date": showEndDate,
    }
    console.log(postData);
    axios.post("/api/ajaxSaveStorageReservation",postData).then((res)=>{
      if(res.error == 0){
        toast.success(res.message);
        getData();
        setShowReserveStorage(false)
        setStorageReserveData();
      }else{
        toast.error("Something went wrong");
      }
    })
  }
  return (
    <div>
    {isLoader && <><div
                    style={{
                        position: 'absolute',
                        // top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: 'rgba(255, 255, 255, 0.8)',
                        // display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 9999,
                    }}
                  >
                  <Loader />
                </div></>}
      <Card>
        <CardHeader>
          <h5>Reservation</h5>
        </CardHeader>
        <CardBody>
          {disableEndDate && <div className="form-check mb-2">
            <Input className="form-check-input" type="checkbox" id="formCheck1" onClick={()=>{
              if(showEndDate){
                setShowEndDate(false);
              }else{
                setShowEndDate(true);
              }
            }} checked/>
            <Label className="form-check-label" for="formCheck1">
              <b>Enable End Date</b>
            </Label>
          </div>}
          <Row className='hstack gap-1 mt-3'>
            <Col lg={3} className='mt-3'>
              <Label><h6>Storage Type</h6></Label>
              <select className="form-select mb-3" aria-label="Default select example" onChange={(e)=>{
                setStorageType(e.target.value);
              }} value={storagetype}>
                {storage_types?.map((res)=>{
                  return(<option key={res.id} value={res.id}>{res.name}</option>)
                })}
              </select>
            </Col>
            <Col lg={3} className='mt-3'>
              <Label><h6>Location</h6></Label>
              <select className="form-select mb-3" aria-label="Default select example" onChange={(e)=>{
                setStorageLocation(e.target.value);
              }} value={storagelocation}>
              {storage_warehouses?.map((res)=>{
                  return(<option key={res.id} value={res.id}>{res.warehouse_name}</option>)
                })}
              </select>
            </Col>
            <Col lg={2}>
              <Label><h6>Start Date</h6></Label>
              <Flatpickr
                className="form-control"
                options={{
                  dateFormat: "Y-m-d",
                  defaultDate: [moment(new Date()).format("YYYY-MM-DD")]
                }}
                onChange={(selectedDates)=>{
                  setFromDate(moment(selectedDates[0]).format('YYYY-MM-DD'));
                }}
                value={from_date}
              disabled/>
            </Col>
            {/*showEndDate &&*/ <Col lg={2}>
              <Label><h6>End Date</h6></Label>
              <Flatpickr
                className="form-control"
                options={{
                  dateFormat: "Y-m-d",
                  defaultDate: [moment(new Date()).format("YYYY-MM-DD")]
                }}
                onChange={(selectedDates)=>{
                  setToDate(moment(selectedDates[0]).format('YYYY-MM-DD'));
                }}
                value={from_date} disabled
              />
            </Col>}
          </Row>
          <Row className='mt-2'>
            <Col lg={9} className='text-muted'>
              <p > *Use the search when you want to add a new reservation <br />Enter the parameters for the search and click on the Search</p>
            </Col>
            <Col lg={3} className='hstack gap-3'>
              <Button className='btn btn-success' onClick={()=>{findStorage()}}>Search</Button>
              <Button className='btn btn-dark'>Reset</Button>

            </Col>
          </Row>
          <Table className='mt-5 table-bordered' >
            <thead className='bg-soft-purple'>
              <tr>
                <th width="20%">Storage Job Number</th>
                <th width="18%">Move In</th>
                <th width="18%">Move Out</th>
                <th>Status</th>
                <th>Volume</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {storage_reservation.length > 0 && storage_reservation.map((res)=>{
                return(
                  <>
                  <tr key={res.id}>
                  <td><span>{res.storage_job_number}</span></td>
                  <td>{res.from_date}</td>
                  <td>{res.to_date}</td>
                  <td>{res.allocation_status}</td>
                  <td>{res.storage_volume} cbm</td>
                  <td className='hstack gap-2'>
                    <button className="btn btn-sm btn-soft-info edit-list" onClick={()=>EditStorage(res.id)}>
                      <i className="bx bxs-pencil fs-12 pt-1"></i>
                    </button>
                    <button className="btn btn-sm btn-soft-danger edit-list" onClick={()=>DeleteStorage(res.storage_job_number)}>
                      <i className="bx bxs-trash fs-12 pt-1"></i>
                    </button>
                  </td>
                </tr>
                {editstorageid == res.id && <tr key={res.id} className='edit_row'>
                <td><Input className='form-control' disabled value={res.storage_job_number} onChange={(e)=>{
                  const updatedLegs = [...storage_reservation];
                  const index = updatedLegs.findIndex((task) => task.id === res.id);
                  updatedLegs[index].storage_job_number = e.target.value;
                  setStorageReservation(updatedLegs);
                }}/></td>
                <td><Flatpickr
                className="form-control"
                options={{
                  dateFormat: "Y-m-d",
                  defaultDate: [res.from_date]
                }}
                onChange={(selectedDates)=>{
                  const updatedLegs = [...storage_reservation];
                  const index = updatedLegs.findIndex((task) => task.id === res.id);
                  updatedLegs[index].from_date = moment(selectedDates[0]).format('YYYY-MM-DD');
                  setStorageReservation(updatedLegs);
                }}
              /></td>
              <td>{res.to_date != "0000-00-00" && <Flatpickr
                className="form-control"
                options={{
                  dateFormat: "Y-m-d",
                  defaultDate: [res.to_date]
                }}
                onChange={(selectedDates)=>{
                  const updatedLegs = [...storage_reservation];
                  const index = updatedLegs.findIndex((task) => task.id === res.id);
                  updatedLegs[index].to_date = moment(selectedDates[0]).format('YYYY-MM-DD');
                  setStorageReservation(updatedLegs);
                }}
              />}
              {res.to_date == "0000-00-00" && <> <Input type='checkbox' onChange={handleToggle}/> <b>Add End Date</b>
              {showPicker && (<Flatpickr
                className="form-control mt-2"
                options={{
                  dateFormat: "Y-m-d",
                  defaultDate: [moment(new Date()).format("YYYY-MM-DD")]
                }}
                onChange={(e)=>{
                  const updatedLegs = [...storage_reservation];
                  const index = updatedLegs.findIndex((task) => task.id === res.id);
                  updatedLegs[index].to_date = e.target.value;
                  setStorageReservation(updatedLegs);
                }}
              />)}</>}
              </td>
                <td>
                  <select className='form-control' value={res.allocation_status} onChange={(e)=>{
                  const updatedLegs = [...storage_reservation];
                  const index = updatedLegs.findIndex((task) => task.id === res.id);
                  updatedLegs[index].allocation_status = e.target.value;
                  setStorageReservation(updatedLegs);
                }}> 
                    <option value="Reserved">Reserved</option>
                    <option value="Active">Active</option>
                    <option value="Hold">Hold</option>
                    <option value="Closed">Closed</option>
                  </select>
                </td>
                <td><Input type='number' step="0.00" value={res.storage_volume} onChange={(e)=>{
                  const updatedLegs = [...storage_reservation];
                  const index = updatedLegs.findIndex((task) => task.id === res.id);
                  updatedLegs[index].storage_volume = e.target.value;
                  setStorageReservation(updatedLegs);
                }}/></td>
                <td className='hstack gap-2'>
                  <button className="btn btn-sm btn-light edit-list" onClick={()=>EditStorage()}>
                    Cancel
                  </button>
                  <button className="btn btn-sm btn-success edit-list" onClick={()=>UpdateStorage(res)}>
                    Save
                  </button>
                </td>
                </tr>}
                </>
                )
              })}
              {storage_reservation && storage_reservation.length == 0 && 
              <tr>
                <td colSpan={6} className='text-center'> No Reservations found!</td>
              </tr>}
            </tbody>
          </Table>
          <Table className='mt-5 table-bordered' >
            <thead className='bg-soft-purple'>
              <tr>
                <th>Storage Unit</th>
                <th>Location</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {storage_jobunits && storage_jobunits.length > 0  &&
              storage_jobunits.map((res)=>{
                return(<tr key={res.id}>
                <td>{res.serial_number} - {res.storage_type_name}</td>
                <td>{res.warehouse_name}</td>
                <td><Button className='btn btn-sm btn-soft-danger' onClick={() => DeleteUnits(res.id)}><i className="bx bxs-trash fs-12"></i></Button></td>
                </tr>)
              })}
              {showreserves && <tr>
                  <td width="45%">
                  <select className='form-control' onChange={(e)=>{
                    setStorageUnit(e.target.value);
                  }}>
                    {storagereserves.map((res)=>{
                      return(<option key={res.id} value={res.id}>{res.serial_number} - {res.name}</option>)
                    })}
                  </select>
                  </td>
                  <td width="30%">
                  <select className='form-control'>
                  {storagereserves.map((res)=>{
                      return(<option key={res.warehouse_id}>{res.warehouse_name}</option>)
                    })}
                  </select>
                  </td>
                  <td width="25%">
                  <button id="s_cancel_discount_btn" type="button" className="btn btn-light" onClick={()=>setShowReserveStorage(false)}> Cancel</button>
                  <button id="s_save_discount_btn" type="button" className="btn btn-success mx-2" onClick={()=>SaveReservation()}> Reserve</button>
                  </td>
                </tr>
              }
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </div>
  )
}
