
import React, { useState,useRef, useCallback, useEffect, useMemo } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row, Label, Input, Accordion, AccordionItem, Collapse, Form, FormFeedback, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Flatpickr from "react-flatpickr";
import classnames from "classnames";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import dummyImg from "../../../assets/images/users/user-dummy-img.jpg";
// Import Table Data
import { CustomerCommercial } from '../GridTablesData';
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../../Components/Common/Loader";
//Import actions
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import * as moment from "moment";
import { Link } from "react-router-dom";
import TableContainer from "../../../Components/Common/TableContainer";
const CustCommercial = () => {
    const isContactSuccess = true;
    const [modal, setModal] = useState(false);
    const [contact, setContact] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [tag, setTag] = useState([]);
    const [assignTag, setAssignTag] = useState([]);
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [lead_status,setLeadStatus] = useState([]);
    const [sorting_order_array,setSortingOrderArray] = useState([]);
    const [lead_status_id, setLeadstatusid] = useState("");
     const [sorting_order_id, setSortingOrderid] = useState("");
     const [createdDateStart, setCreatedDateStart] = useState(null);
    const [createdDateEnd, setCreatedDateEnd] = useState(null);
    const [sort_descending, setSortdescending] = useState("");
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
    const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
    const [org_date, setOrg_Date] = useState([]);

    const loadTable =() =>{
      const postData = {
            created_date_start:null,
            created_date_end:null,
            lead_status:null,
            sorting_order:null,
            sort_descending:null,
        };
        setisLoading(true);
      axios.post("/api/crm-leads/commercialData", postData)
        .then((res) => {
            setOrg_Date(res.data[0]);
          setData(res.data);
          setCreatedDateEnd(moment().format(res?.data[0]?.organisation_date_picker));
          setCreatedDateStart(moment().subtract(1, 'months').format(res?.data[0]?.organisation_date_picker));
          console.log(res.data);
        })
        .catch((error) => {
                console.error(error);
            })
        .finally(()=>{
                setisLoading(false);
            })
         axios.get("/api/crm-leads/commercial")
        .then((response) => {
           setLeadStatus(response.lead_statuses);
           setSortingOrderArray(response.sorting_order_array);

       });
   
}
useEffect(() => {
    loadTable();
     }, []);

const handleSubmit = (event) => {
    event.preventDefault();
    console.log(lead_status_id);
    let result
    if(lead_status_id == "" || lead_status_id == undefined || lead_status_id == null){
            toast.error("Select Lead Status", {theme:"light"});
            return false;
    }else{
        const values =lead_status_id.map(item => item.label);
         result = values.join(',');
    }
    // let startDate;
    // if(createdDateStart !== undefined && createdDateStart !== "" && moment(createdDateStart, 'DD MMM, YYYY').isValid()){
    //   startDate = moment(createdDateStart, 'DD MMM, YYYY').format('DD/MM/YYYY');
    // }else{
    //     startDate = null;
    // }
    // let endDate;
    // if (createdDateEnd !== undefined && createdDateEnd !== "" && moment(createdDateEnd, 'DD MMM, YYYY').isValid()) {
    //   endDate = moment(createdDateEnd, 'DD MMM, YYYY').format('DD/MM/YYYY');
    // }else {
    //   endDate = null;
    // }
    // console.log(endDate);
    // console.log(startDate);
    const postData = {
            created_date_start:createdDateStart,
            created_date_end:createdDateEnd,
            lead_status:result,
            sorting_order:sorting_order_id.value,
            sort_descending:activeInterest,
        };
        setisLoading(true);
         axios.post("/api/crm-leads/commercialData", postData)
        .then((res) => {
          setData(res.data);
          console.log(res.data);
        })
        .catch((error) => {
                console.error(error);
            })
        .finally(()=>{
                setisLoading(false);
            })
}
    const sortingOrderArray = Object.keys(sorting_order_array).map(key => ({
      value: key,
      label: sorting_order_array[key]
    }));
    function handleLeadStatus(selectedLeadStatus) {
            setLeadstatusid(selectedLeadStatus);
        }
    function handleSortingOrder(selectedArray) {
        setSortingOrderid(selectedArray);
    }
    const [activeInterest, setActiveInterest] = useState(0);
    const handleCheckboxChange = (event) => {
        setActiveInterest(event.target.checked ? 1 : 0);
  };
   const resetTable = () => {
        setCreatedDateStart(null);
        setCreatedDateEnd(null);
        setLeadstatusid("");
        setSortingOrderid("");
        setSortdescending(0);
        loadTable();
    }

    function handlestag(tags) {
        setTag(tags);
        const assigned = tags.map((item) => item.value);
        setAssignTag(assigned);
    }

    const tags = [
        { label: "Exiting", value: "Exiting" },
        { label: "Lead", value: "Lead" },
        { label: "Long-term", value: "Long-term" },
        { label: "Partner", value: "Partner" }
    ];

    const dateFormat = () => {
        var d = new Date(),
            months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return (d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear());
    };

    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
            setContact(null);
        } else {
            setModal(true);
            setTag([]);
            setAssignTag([]);
        }
    }, [modal]);

    const [col1, setcol1] = useState(false);
    const handleContactClicks = () => {
    setContact("");
    setIsEdit(false);
    toggle();
  };
  const crmcontacts = data;
  const handleContactClick = useCallback((arg) => {
    const contact = arg;

    setContact({
      _id: contact._id,
      // img: contact.img,
      name: contact.name,
      company: contact.company,
      email: contact.email,
      designation: contact.designation,
      phone: contact.phone,
      lead_score: contact.lead_score,
      last_contacted: contact.date,
      // time: contact.time,
      tags: contact.tags,
    });

    setIsEdit(true);
    toggle();
  }, [toggle]);

    const t_col1 = () => {
        setcol1(!col1);

    };
    const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".contactCheckBox:checked");
    ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };
     const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);
    // validation
    const validation = useFormik({

        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            // img: (contact && contact.img) || '',
            name: (contact && contact.name) || '',
            company: (contact && contact.company) || '',
            designation: (contact && contact.designation) || '',
            email: (contact && contact.email) || '',
            phone: (contact && contact.phone) || '',
            lead_score: (contact && contact.lead_score) || '',
            tags: (contact && contact.tags) || [],
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Name"),
            company: Yup.string().required("Please Enter Company"),
            designation: Yup.string().required("Please Enter Designation"),
            email: Yup.string().required("Please Enter Email"),
            phone: Yup.string().required("Please Enter Phone"),
            lead_score: Yup.string().required("Please Enter lead_score"),
        }),
        onSubmit: (values) => {
            if (isEdit) {
                const updateContact = {
                    _id: contact ? contact._id : 0,
                    // img: values.img,
                    name: values.name,
                    company: values.company,
                    designation: values.designation,
                    email: values.email,
                    phone: values.phone,
                    lead_score: values.lead_score,
                    last_contacted: dateFormat(),
                    // time: timeFormat(),
                    tags: assignTag,
                };
                // update Contact 
                // dispatch(onUpdateContact(updateContact));
                validation.resetForm();
            } else {
                const newContact = {
                    _id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
                    // img: values["img"],
                    name: values["name"],
                    company: values["company"],
                    designation: values["designation"],
                    email: values["email"],
                    phone: values["phone"],
                    lead_score: values["lead_score"],
                    last_contacted: dateFormat(),
                    // time: timeFormat(),
                    tags: assignTag,
                };
                // save new Contact
                // dispatch(onAddNewContact(newContact));
                validation.resetForm();
            }
            toggle();
        },
    });
    const [name, setName] = useState("");
    const [company, setCompany] = useState("");
    const [description, setDescription] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    
    const customerSave = (event) => {
    event.preventDefault();
    const postData = {
            contact_name: name,
            company_name:company,
            contact_description:description,
            email: email,
            mobile: phone,
        };
        axios.post("/api/crm-leads/commercial/ajaxAddCustomer", postData)
        .then((res) => {
        const result = res.message;
            if(res.error == 0){
                toast.success("Customer Data Updated Successfully",{theme:"light"});
                setModal(false);
            }else{
                toast.error(result,{theme:"light"});
                setModal(true);
            }
        });
}
const columns = useMemo(
    () => [
      /*{
        Header: <input type="checkbox" id="checkBoxAll" className="form-check-input" onClick={() => checkedAll()} />,
        Cell: (cellProps) => {
          return <input type="checkbox" className="contactCheckBox form-check-input" value={cellProps.row.original._id} onChange={() => deleteCheckbox()} />;
        },
        id: '#',
      },*/
      {
        Header: "Name",
        accessor: "customer",
        filterable: false,
        Cell: (cell) => {
                    return <Link to={"/customerdetails/"+cell.value.id} className="fw-medium link-primary">{cell.value.name}</Link>;
                },
      },
      {
        Header: "Mobile",
        accessor: "mobile",
        filterable: false,
      },

      {
        Header: "Email",
        accessor: "email",
        filterable: false,
      },
      {
        Header: "Status",
        accessor: "lead_status",
        filterable: false,
      },
    ],
    [handleContactClick, checkedAll]
  );
    document.title = "Commercial | Onexfort";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Commercial  Customer" pageTitle="Customer" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <Accordion id="default-accordion-example">
                                        <AccordionItem>
                                            <h2 className="accordion-header" id="headingOne">
                                                <button
                                                    className={classnames("accordion-button fw-semibold", { collapsed: !col1 })} type="button" onClick={t_col1} style={{ cursor: "pointer" }} >
                                                    Search Filters
                                                </button>
                                            </h2>
                                            <Collapse isOpen={col1} className="accordion-collapse" id="collapseOne" >
                                                <div className="accordion-body">
                                                    <Row>
                                                       

                                                            <Col md={4}>
                                                                <div className="mb-3">
                                                                    <Label for="leadStatusinput" className="form-label">Lead Status</Label>
                                                                    <Select
                                                                        value={lead_status_id}
                                                                        isMulti={true}
                                                                        onChange={(selectedLeadStatus) => {
                                                                             handleLeadStatus(selectedLeadStatus);
                                                                        }}
                                                                        options={lead_status.map(item => ({ value: item.id, label: item.lead_status }))}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        
                                                        {/*<Col lg={4}>
                                                            <div className="mb-3">
                                                                <Label for="createdDateinput" className="form-label">Created Date</Label>
                                                                <Flatpickr
                                                                    className="form-control"
                                                                    options={{
                                                                        mode: "range",
                                                                        dateFormat: "Y-m-d"
                                                                    }}
                                                                    value={[createdDateStart, createdDateEnd]}
                                                                    onChange={(date) => {
                                                                        if (date.length === 2) {
                                                                            setCreatedDateStart(moment(date[0]).format('DD/MM/YYYY'));
                                                                            setCreatedDateEnd(moment(date[1]).format('DD/MM/YYYY'));
                                                                        } else {
                                                                            setCreatedDateStart(null);
                                                                            setCreatedDateEnd(null);
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </Col>*/}
                                                            <Col lg={2}>
                                            <div className="mb-3">
                                                <Label for="createdDateinput" className="form-label">Created Start Date</Label>
                                                {/* <Flatpickr
                                                    className="form-control" placeholder='Start Date' value={createdDateStart} onChange={(date) => setCreatedDateStart(moment(date[0]).format('DD MMM, YYYY'))}
                                                    options={{
                                                        dateFormat: "d M, Y",
                                                        enableTime: false
                                                    }}
                                                /> */}
                                                <Flatpickr
                                                    className="form-control" placeholder='Start Date'
                                                    options={{
                                                        dateFormat: org_date?.organisation_date_format,
                                                    }}
                                                    value={createdDateStart}
                                                    onChange={(selectedDates) => {
                                                        if (selectedDates.length === 1) {
                                                          setCreatedDateStart(moment(selectedDates[0]).format(org_date?.organisation_date_picker));
                                                        } else {
                                                          setCreatedDateStart(null);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={2}>
                                            <div className="mb-3">
                                                <Label for="createdDateinput" className="form-label" >Created End Date</Label>
                                                {/* <Flatpickr
                                                    className="form-control" placeholder='End Date' value={createdDateEnd} onChange={(date) => setCreatedDateEnd(moment(date[0]).format('DD MMM, YYYY'))}
                                                    options={{
                                                        dateFormat: "d M, Y",
                                                        enableTime: false
                                                    }}
                                                /> */}
                                                <Flatpickr
                                                    className="form-control" placeholder='End Date'
                                                    options={{
                                                        dateFormat: org_date?.organisation_date_format,
                                                    }}
                                                    value={createdDateEnd}
                                                    onChange={(selectedDates) => {
                                                        if (selectedDates.length === 1) {
                                                            setCreatedDateEnd(moment(selectedDates[0]).format(org_date?.organisation_date_picker));
                                                        } else {
                                                            setCreatedDateEnd(null);
                                                        }
                                                    }}
                                                />

                                            </div>
                                        </Col>
                                                        </Row>
                                                             <Row>
                                                            <Col md={4}>
                                                            <div className="mb-3">
                                                                <Label for="leadStatusinput" className="form-label">Sorting Order</Label>
                                                                 <Select value={sorting_order_id} onChange={(selectedArray) => {
                                                                        handleSortingOrder(selectedArray);
                                                                    }}
                                                                options={sortingOrderArray} />
                                                            </div>
                                                        </Col>
                                                        <Col lg={2}>
                                                            <div className="form-check form-radio-pad form-check-right mb-2">
                                                                <Input className="form-check-input" type="checkbox" name="formCheckboxRight" id="formCheckboxRight1"  value="sort_descending" onChange={handleCheckboxChange}
                                          checked={activeInterest == 1}/>
                                                                <Label className="form-check-label" for="formCheckboxRight1">
                                                                    Descending
                                                                </Label>

                                                            </div>
                                                        </Col>
                                                        <Col lg={4}>
                                                            <div className="text-end">
                                                                <button type="submit" onClick={handleSubmit} className="btn form-btn-marg btn-primary">Apply</button>
                                                                <button type="submit" onClick={resetTable} className="btn form-btn-marg btn-primary">Reset</button>
                                                            </div>

                                                        </Col>

                                                    </Row>
                                                </div>
                                            </Collapse>
                                        </AccordionItem>
                                    </Accordion>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col lg={10}>
                                            {/* <h4 className="card-title mb-0 flex-grow-1">Search</h4> */}
                                        </Col>
                                        <Col lg={2}><button
                                            className="btn btn-brown add-btn"
                                            onClick={() => {
                                                setModal(true);
                                            }}
                                        >
                                            <i className="ri-add-fill me-1 align-bottom"></i> Add Commercial Customer

                                        </button></Col>
                                    </Row>
                                    <div id="table-gridjs">
                                        {/*<CustomerCommercial data={data}/>*/}
                                        {isContactSuccess && crmcontacts ? (
                                        <TableContainer
                                          columns={columns}
                                          data={(crmcontacts || [])}
                                          isGlobalFilter={true}
                                          isAddUserList={false}
                                          customPageSize={8}
                                          className="custom-header-css"
                                          divClass="table-responsive table-card mb-3"
                                          tableClass="align-middle table-nowrap"
                                          theadClass="table-light"
                                          handleContactClick={handleContactClicks}
                                          isContactsFilter={true}
                                          SearchPlaceholder="Search for contact..."
                                        />
                                      ) : (<Loader />)
                                      }
                                    </div>
                                    {/*{isLoading && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            background: 'rgba(255, 255, 255, 0.8)',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            zIndex: 9999,
                                        }}
                                    >
                                        <Loader />
                                    </div>
                                )}*/}
                                    <Modal id="showModal" isOpen={modal} toggle={toggle} centered>
                                        <ModalHeader className="bg-soft-primary p-3" toggle={toggle}>
                                            {!!isEdit ? "Edit" : "New Commercial Customer"}
                                        </ModalHeader>

                                        <Form className="tablelist-form" onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}>
                                            <ModalBody>
                                                <Input type="hidden" id="id-field" />
                                                <Row className="g-3">
                                                <Col lg={6}>
                                                        <div>
                                                            <Label
                                                                htmlFor="company_name-field"
                                                                className="form-label"
                                                            >
                                                               Company Name
                                                            </Label>
                                                            <Input
                                                                name="company"
                                                                id="company_name-field"
                                                                className="form-control"
                                                                placeholder="Enter Contact Name"
                                                                type="text"
                                                                validate={{
                                                                    required: { value: true },
                                                                }}
                                                                onChange={(e) => setCompany(e.target.value)}
                                                                onBlur={validation.handleBlur}
                                                                value={company}
                                                                invalid={
                                                                    validation.touched.company && validation.errors.company ? true : false
                                                                }
                                                           />
                                                            {validation.touched.company && validation.errors.company ? (
                                                                <FormFeedback type="invalid">{validation.errors.company}</FormFeedback>
                                                            ) : null}

                                                        </div>
                                                    </Col>
                                                </Row >
                                                <Row className="g-3 mt-0">
                                                    <Col lg={6}>
                                                        <div>
                                                            <Label
                                                                htmlFor="name-field"
                                                                className="form-label"
                                                            >
                                                                Contact Name
                                                            </Label>
                                                            <Input
                                                                name="name"
                                                                id="customername-field"
                                                                className="form-control"
                                                                placeholder="Enter Company Name"
                                                                type="text"
                                                                validate={{
                                                                    required: { value: true },
                                                                }}
                                                                onChange={(e) => setName(e.target.value)}
                                                                onBlur={validation.handleBlur}
                                                                value={name}
                                                                invalid={
                                                                    validation.touched.name && validation.errors.name ? true : false
                                                                }
                                                            />
                                                            {validation.touched.name && validation.errors.name ? (
                                                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                                            ) : null}

                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div>
                                                            <Label
                                                                htmlFor="designation-field"
                                                                className="form-label"
                                                            >
                                                                Contact Description
                                                            </Label>

                                                            <Input
                                                                name="designation"
                                                                id="designation-field"
                                                                className="form-control"
                                                                placeholder="Description"
                                                                type="text"
                                                                validate={{
                                                                    required: { value: true },
                                                                }}
                                                                onChange={(e) => setDescription(e.target.value)}
                                                                onBlur={validation.handleBlur}
                                                                value={description}
                                                                invalid={
                                                                    validation.touched.designation && validation.errors.designation ? true : false
                                                                }
                                                            />
                                                            {validation.touched.designation && validation.errors.designation ? (
                                                                <FormFeedback type="invalid">{validation.errors.designation}</FormFeedback>
                                                            ) : null}

                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div>
                                                            <Label
                                                                htmlFor="email_id-field"
                                                                className="form-label"
                                                            >
                                                                Email
                                                            </Label>

                                                            <Input
                                                                name="email"
                                                                id="email_id-field"
                                                                className="form-control"
                                                                placeholder="Your Email"
                                                                type="email"
                                                                validate={{
                                                                    required: { value: true },
                                                                }}
                                                                onChange={(e) => setEmail(e.target.value)}
                                                                onBlur={validation.handleBlur}
                                                                value={email}
                                                                invalid={
                                                                    validation.touched.email && validation.errors.email ? true : false
                                                                }
                                                            />
                                                            {validation.touched.email && validation.errors.email ? (
                                                                <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                            ) : null}

                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div>
                                                            <Label
                                                                htmlFor="phone-field"
                                                                className="form-label"
                                                            >
                                                                Mobile
                                                            </Label>

                                                            <Input
                                                                name="phone"
                                                                id="phone-field"
                                                                className="form-control"
                                                                placeholder="Enter Phone No."
                                                                type="text"
                                                                validate={{
                                                                    required: { value: true },
                                                                }}
                                                                onChange={(e) => setPhone(e.target.value)}
                                                                onBlur={validation.handleBlur}
                                                                value={phone}
                                                                invalid={
                                                                    validation.touched.phone && validation.errors.phone ? true : false
                                                                }
                                                            />
                                                            {validation.touched.phone && validation.errors.phone ? (
                                                                <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                </Row>
                                            </ModalBody>
                                            <ModalFooter>
                                                <div className="hstack gap-2 justify-content-end">
                                                    <button type="submit" className="btn btn-primary" onClick={customerSave} id="add-btn" > {!!isEdit ? "Update" : "Create Customer"} </button>
                                                    <button type="button" className="btn btn-light" onClick={() => { setModal(false); }} > Cancel </button>

                                                </div>
                                            </ModalFooter>
                                        </Form>
                                    </Modal>
                                    <ToastContainer closeButton={false} limit={1} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};

export default CustCommercial;