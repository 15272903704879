import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { Link } from 'react-router-dom';
import React, { useEffect, useState, useCallback, useMemo } from "react";
import axios from 'axios';

import { isEmpty } from "lodash";
import {
  CardHeader,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalFooter,

} from "reactstrap";



//import action
import {
  getTodos as onGetTodos,
  updateTodo as onupdateTodo,
  deleteTodo as onDeleteTodo,
  addNewTodo as onAddNewTodo,

  getProjects as onGetProjects,
  addNewProject as onAddNewProject,
} from "../../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../../Components/Common/TableContainer";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import copy from "copy-to-clipboard";

import Loader from "../../../Components/Common/Loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteModal from "../../../Components/Common/DeleteModal";
export const SmsTemplates = () => {
  document.title="SMS Templates | Onexfort";
  // Export Modal

  const [deleteModal, setDeleteModal] = useState(false);
  const dispatch = useDispatch();
  
const [data,setData] =useState()
const [company,setCompany]= useState();

////////////data
const [companyid,setCompanyId] =useState()
const [tempname,setTempName]=useState();
const [sms,setSMS]= useState()
const [compname,setCompName]=useState();
const [frommail,setFromEmail]=useState();
const [frommailid,setFromMailId] =useState();
const [mailsub,setMailSub] =useState('');
const [mailbody,setMailBody] =useState('')
const [tempid,setTempId] =useState('')
const [active, setActive] = useState('N');
const [activeValue, setActivetValue] = useState('N');
const [quote, setQuote] = useState('N');
const [quoteValue, setQuoteValue] = useState('N');
const [invoice, setInvoice] = useState('N');
const [invoiceValue, setInvoiceValue] = useState('N');
const error =null;
const isContactSuccess = true;
const crmcontacts=data;


  useEffect(() => {
    loaddata();
  }, []);
  const loaddata = () => {

    axios
        .get("/api/sms-templates/data")
        .then((res) => {
            console.log(res.data);
            setData(res.data);
        })
        .catch((err) => {
            console.log(err);
        });
        axios
        .get("/api/sms-templates/create")
        .then((res) => {
            console.log(res.companies,"create");
            setCompany(res.companies);
            setCompanyId(res.companies[0].id)

        })
        .catch((err) => {
            console.log(err);
        });

    };
////////////////////////////

 
  const [isEdit, setIsEdit] = useState(false);
  const [modal, setModal] = useState(false);



  const [modal_sms, setmodal_sms] = useState(false);
  function tog_smsModal() {
    setmodal_sms(!modal_sms);
  }


  const dateFormat = () => {
    var d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return (d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear());
  };


  const [order, setOrder] = useState([]);
  const handleOrderClick = (id) => {
    if (id !== '') {
      console.log("edit" + id);
      // axios.post('/api/products/delete',id)
      axios.post(`/api/sms-templates/edit/${id}`)
          .then((response) => {
              console.log(response);
              setTempId(response.template.id)
              setTempName(response.template.sms_template_name  ||'')
              setSMS(response.template.sms_message || '')
              setCompanyId(response.template.company_id )
              setActive(response.template.active)

          })
          .catch((error) => {
              console.error('Delete error:', error);
              // Handle delete error
          });
    setIsEdit(true);
    setmodal_sms(modal_sms);
    tog_smsModal();
  }
  };
  const [smsId,setSmsId] = useState('')
  const deletePopUp = (id) =>{
    setSmsId(id);
    setDeleteModal(true);
  }

  const onClickDelete =async () => {
    var orderIds = smsId;
    axios.post(`${"api/sms-templates/delete/"}${orderIds}`)
          .then((response) => {
              console.log(response);
              toast.success("Deleted Successfully",{theme:"light"});
              loaddata();
          })
          .catch((error) => {
              console.error('Delete error:', error);
          });
    setTodo(todo);
    setDeleteModal(false);
  };
  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  useEffect(() => {	
    const value = active ? 'Y' : 'N';	
    setActivetValue(value);	
    const value1 = quote ? 'Y' : 'N';	
    setQuoteValue(value1);
    const value2 = invoice ? 'Y' : 'N';	
    setInvoiceValue(value2);
  }, [active,quote,invoice]);
  ////////////toggle
  const handleStatus = (event) => {
    const isCheck = event.target.checked;
    const value = isCheck ? 'Y' : 'N';	
    setActive(value);	
};
  const handleQuote = (event) => {
    const isCheck = event.target.checked;
    const value = isCheck ? 'Y' : 'N';	
    setQuote(value);	
  };
  const handleInvoice = (event) => {
    const isCheck = event.target.checked;
    const value = isCheck ? 'Y' : 'N';	
    setInvoice(value);	
  };
  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".contactCheckBox:checked");
    ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  const [copyText, setCopyText] = useState('');
  const entireCopyText=["{job_id}", "{first_name}", "{last_name}","{pickup_suburb}","{pickup_address}", "{delivery_address}", "{est_start_time}","{est_first_leg_start_time}","{delivery_suburb}", "{user_first_name}", "{user_last_name}","{mobile}","{email}", "{total_amount}", "{total_paid}","{total_due}"]
 
  useEffect(() => {
    copy(copyText);
    toast.success(`Dynamic Field is ${copyText}`, { theme: "light" });
}, [copyText]);
  //sms column
  const smscolumn = useMemo(
    () => [
      {
        Header: <input type="checkbox" id="checkBoxAll" className="form-check-input" onClick={() => checkedAll()} />,
        Cell: (cellProps) => {
          return <input type="checkbox" className="contactCheckBox form-check-input" value={cellProps.row.original._id} onChange={() => deleteCheckbox()} />;
        },
        id: '#',
      },
      {
        Header: "SMS Template Name",
        accessor: "sms_template_name",
        filterable: false,

      },
      {
        Header: "Company Name",
        accessor: "company_name",
        filterable: false,
      },

      {
        Header: "Status",
        accessor: "active",
        filterable: false,
      },

      {
        Header: "Action",
        Cell: (cellProps) => {
          const { id } = cellProps.row.original;
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item">
                <button className="btn btn-sm btn-soft-info edit-list" onClick={() => {
                  handleOrderClick(id);
                }} ><i className="bx bxs-pencil fs-12 pt-1"></i>
                </button>
              </li>
              <li className="list-inline-item edit">
                <Link
                  to="#"
                  className=" btn btn-sm btn-soft-danger fs-13 pt-1" onClick={() => {deletePopUp(id);setSmsId(id)}}

                >
                  <i className="bx bxs-trash fs-12"></i>
                </Link>
              </li>

            </ul>
          );
        },
      },
    ],
    [ checkedAll]
  );

  const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);

  const { todos, projects } = useSelector((state) => ({
    todos: state.Todos.todos,
    projects: state.Todos.projects,
  }));
  const [taskList, setTaskList] = useState([]);
  // Projects
  const [modalProject, setModalProject] = useState(false);

  useEffect(() => {
    dispatch(onGetProjects());
  }, [dispatch]);

  const [todo, setTodo] = useState(null);
  useEffect(() => {
    dispatch(onGetTodos());
  }, [dispatch]);

  useEffect(() => {
    setTodo(todos);
    setTaskList(todos);
  }, [todos]);

  // Toggle Project Modal
  const toggleProject = () => {
    if (modalProject) {
      setModalProject(false);
    } else {
      setModalProject(true);
    }
  };

  // validation
  const projectValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      title: '',
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Project Title"),
    }),
    onSubmit: (values) => {
      const newProjectData = {
        id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
        title: values.title,
        subItem: [{ id: 1, version: "v1.0.0", iconClass: "danger" }]
      };
      // save new Project Data
      dispatch(onAddNewProject(newProjectData));
      projectValidation.resetForm();
      toggleProject();
    },
  });
////Save and Update
const handleFormSubmit = (e) => {
  e.preventDefault();

var formData = {
// "id" :'80',
"sms_template_name" :tempname,
"sms_message" :sms,
"attach_qoute": quote,
"attach_invoice": invoice,
"company_id":companyid,
"active": active
}
console.log(formData,"id");
if(tempname==''){
  toast.error("The SMS Template Name field is required.", { theme: "light" });
  return;
}
if(sms==""){
  toast.error("The SMS Message field is required.", { theme: "light" });
  return;
}
if (isEdit) {
axios.post(`${"api/sms-templates/update/"}${tempid}`, formData)

.then((response) => {
  tog_smsModal();
  console.log('Save successful', response.data);
  toast.success("Saved Successfully",{theme:"light"});
  loaddata();
})
.catch((error) => {
  // Handle error
  console.error('Error saving data:', error);
});

} else {
axios.post('/api/sms-templates/store', formData)

.then((response) => {
  tog_smsModal();
  console.log('Save successful', response.data);
  toast.success("Saved Successfully",{theme:"light"});
  loaddata();
})
.catch((error) => {
  // Handle error
  console.error('Error saving data:', error);
});
}
}
  return (
    <div>

       <DeleteModal
  show={deleteModal}
  onDeleteClick={()=>onClickDelete()}
  onCloseClick={() => setDeleteModal(false)}
/>
      <Card>
        <CardHeader>
          <div className="d-flex align-items-center flex-wrap gap-2">
            <div className="flex-grow-1">
              <button
                className="btn btn-brown add-btn"
                onClick={() => {tog_smsModal();
                  setTempName('');
                  setSMS("");
                  
                }}
              >
                <i className="ri-add-fill me-1 align-bottom"></i> Add
                SMS Template
              </button>
            </div>
           
          </div>
        </CardHeader>
      </Card>
      <Card id="contactList">
        <CardBody className="pt-0">
          <div>
            {isContactSuccess &&  crmcontacts  ? (
              <TableContainer
                columns={smscolumn}
                data={(crmcontacts || [])}
                isGlobalFilter={true}
                isAddUserList={false}
                customPageSize={50}
                className="custom-header-css"
                divClass="table-responsive table-card mb-3"
                tableClass="align-middle table-nowrap"
                theadClass="table-light"
                isContactsFilter={true}
                SearchPlaceholder="Search for contact..."
              />
            ) : (<Loader error={error} />)
            }

          </div>
          <Modal
            size="lg"
            isOpen={modal_sms}
            toggle={() => {
              tog_smsModal();
              setTempName("");
              setSMS("");
              // setCompanyId('');
              setQuote('')
              setInvoice('')
              setActive('')
            }}
          >
            <Card>
              <ModalHeader className="bg-soft-primary p-3">
                <h5 className="mb-0 text-black"> {!!isEdit ? " Update SMS Template" : "Create SMS Template"}</h5>

              </ModalHeader>
              <CardBody>
                <Row className="mb-3">
                  <Col md={12}>
                    <Label>SMS Template Name</Label>
                    <Input type="text" value={tempname}
                        onChange={(e) => setTempName(e.target.value)}></Input>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col md={12}>
                    <Label >Company Name</Label>
                    <select
                      value={companyid}
                      onChange={(e) => setCompanyId(e.target.value)}
                      className="form-select mb-3"
                      >
                      {company && company.length > 0 ? (
                          company.map((role, index) => (
                              <option key={index} value={role.id}>{role.company_name
                              }</option>
                          ))
                      ) : (
                          <option value="">No Company found</option>
                      )}
                    </select>
                  </Col>
                </Row>
                <Col md={12}>
                  <label>Available Dynamic Fields </label>
                  <span className="mx-3">(Click to Copy)</span>
                  <div>
                    {entireCopyText.map((res)=>(<span key={res} onClick={()=>  setCopyText(res)}>{res+" "}</span>))};</div>
                </Col>
                <br></br>
                <Row className="mb-3">
                  <Col md={12}>
                    <Label htmlFor="VertimeassageInput" className="form-label">SMS Message</Label>
                    <textarea className="form-control" id="VertimeassageInput" rows="3" placeholder="Enter your message" value={sms}
                        onChange={(e) => setSMS(e.target.value)}>
                    </textarea>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={6}>
                    <Label>Attach Quote</Label>
                    <div className="form-check form-switch form-switch-md">
                    <Input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" 
                     defaultChecked={quote ==="Y"}  onChange={handleQuote} />
                    </div>
                  </Col>
                  <Col md={6}>
                    <Label>Attach Invoice</Label>
                    <div className="form-check form-switch form-switch-md">
                    <Input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" 
                     defaultChecked={invoice ==="Y"}  onChange={handleInvoice} />
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={6}>
                    <Label>Active</Label>
                    <div className="form-check form-switch form-switch-md">
                    <Input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" 
                     onChange={handleStatus}  checked={active === "Y"} />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <ModalFooter className="justify-content-center">
              <Button className="btn btn-success" onClick={ handleFormSubmit} >{!!isEdit ? " Update" : "Save"}</Button>

              <Button className="btn btn-danger" onClick={() => { tog_smsModal() }}>Cancel</Button>
            </ModalFooter>

          </Modal>
          <ToastContainer closeButton={false} limit={1} />
        </CardBody>
      </Card>
    </div>
  )
}
