import { Card, CardBody, Col, Row } from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { isEmpty } from "lodash";
import {
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalFooter,

} from "reactstrap";
//Import actions
import {
  getContacts as onGetContacts,
  addNewContact as onAddNewContact,
  updateContact as onUpdateContact,
  deleteContact as onDeleteContact,
} from "../../../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
// import TableContainer from "../../../Components/Common/TableContainer";
import TableContainer from "../../../../Components/Common/TableContainer";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

// import Loader from "../../../Components/Common/Loader";
import Loader from "../../../../Components/Common/Loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export const PropertyCategoryOption = () => {
 

  return (
    <div>
      <Card>
        <CardHeader>
          <h4 className="card-title mb-0 flex-grow-1"></h4>
        </CardHeader>
        <CardBody>
        
          </CardBody>
      
        </Card>


  
    </div>
  )
}
