import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Card, CardHeader, CardBody, Col, Label,
  Input, Modal, Row,
} from "reactstrap";

import { Link } from 'react-router-dom'
// import Components
import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";
import { toast, ToastContainer } from 'react-toastify';
import { isEmpty } from "lodash";
import {

} from "reactstrap";
// Import Images
import * as Yup from "yup";
import { useFormik } from "formik";
//redux
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
//Import actions
import {
  getContacts as onGetContacts,
  addNewContact as onAddNewContact,
  updateContact as onUpdateContact,
  deleteContact as onDeleteContact,
} from "../../../store/actions";
import { get_cookie } from '../../../helpers/get_cookie';
import axios from 'axios';
import DeleteModal from "../../../Components/Common/DeleteModal";
import {
    getOrders as onGetOrders,
    addNewOrder as onAddNewOrder,
    updateOrder as onUpdateOrder,
    deleteOrder as onDeleteOrder,
} from "../../../store/ecommerce/action";
import { useParams } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import * as moment from "moment";

export const Customerdetails = ({ data, onChangeData}) => {
  const isContactSuccess = true;
  const error = null;
  const [contact, setContact] = useState([]);
  const [isLoader, setLoader] = useState(true);
  const[job_ids, setJobId] = useState(""); 
  // add large modals
  const [modal_type, setmodal_type] = useState(false);
  const [modal_type_job, setmodal_type_job] = useState(false);
  function tog_mod_2() {
    setmodal_type(!modal_type);
  }
  function tog_mod_1() {
    setmodal_type_job(!modal_type_job);
  }
  const [isEdit, setIsEdit] = useState(false);
 
  const onClickDelete = (contact) => {
    setContact(contact);
    setDeleteModal(true);
  };

  // Add Data
  const handleContactClicks = () => {
    setContact("");
    setIsEdit(false);
    toggle();
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // img: (contact && contact.img) || '',
      name: (contact && contact.name) || '',
      company: (contact && contact.company) || '',
      designation: (contact && contact.designation) || '',
      email: (contact && contact.email) || '',
      phone: (contact && contact.phone) || '',
      lead_score: (contact && contact.lead_score) || '',
      tags: (contact && contact.tags) || [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      company: Yup.string().required("Please Enter Company"),
      designation: Yup.string().required("Please Enter Designation"),
      email: Yup.string().required("Please Enter Email"),
      phone: Yup.string().required("Please Enter Phone"),
      lead_score: Yup.string().required("Please Enter lead_score"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateContact = {
          _id: contact ? contact._id : 0,
          // img: values.img,
          name: values.name,
          company: values.company,
          designation: values.designation,
          email: values.email,
          phone: values.phone,
          lead_score: values.lead_score,
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // update Contact 
        dispatch(onUpdateContact(updateContact));
        validation.resetForm();
      } else {
        const newContact = {
          _id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          // img: values["img"],
          name: values["name"],
          company: values["company"],
          designation: values["designation"],
          email: values["email"],
          phone: values["phone"],
          lead_score: values["lead_score"],
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // save new Contact
        dispatch(onAddNewContact(newContact));
        validation.resetForm();
      }
      toggle();
    },
  });

  const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);
  const params = useParams();
    const lead_id = params.id;
    const[from_date, setFromDate] = useState();
     const[to_date, setToDate] = useState();
      const[allocation_status, setAllocationStatus] = useState();
       const[storage_type, setStorageType] = useState();
        const[storage_unit, setStorageUnit] = useState();
       
       const[jobData, setJobData] = useState([]);
       const[opportunity_data, setOpportunityData] = useState([]);
       const[storage_data, setStorageData] = useState([]);
       //const[custJobId, setCustJobId] = useState([]);

       //copy job popup
       const[user_name, setUserName] = useState([]);
        const[user_email, setUserEmail] = useState([]);
        const[user_mobile, setUserMobile] = useState([]);
      const[copy_job_pickup_address, setCopyJobPickupAddress] = useState([]);
      const[copy_job_drop_off_address, setCopyJobDropOffAddress] = useState([]);
      const[job_pickup_address_only, setJobPickupAddressOnly] = useState([]);
      const[job_pickup_suburb,setJobPickupSuburb] = useState([]);
      const[job_pickup_post_code,setJobPickupPostCode]=useState([]);
      const[job_drop_off_address_only,setJobDropOffAddressOnly] = useState([]);
      const[job_delivery_suburb,setJobDeliverySuburb]= useState([]);
      const[job_drop_off_post_code,setJobDropOffPostCode] = useState([]);
      const[est_job_date, setEstJobDate] = useState(null);
      const[company_id, setCompanyId] = useState([]);
      const[op_type,setOpType] = useState([]);
      const [companies, setCompanies] = useState("");

       //copy opportunity popup
       const[opportunity_user_name, setOpportunityUserName] = useState([]);
        const[opportunity_user_email, setOpportunityUserEmail] = useState([]);
        const[opportunity_user_mobile, setOpportunityUserMobile] = useState([]);
      const[copy_opportunity_pickup_address, setCopyOpportunityPickupAddress] = useState([]);
      const[copy_opportunity_drop_off_address, setCopyOpportunityDropOffAddress] = useState([]);
      const[opportunity_pickup_address_only, setOpportunityPickupAddressOnly] = useState([]);
      const[opportunity_pickup_suburb,setOpportunityPickupSuburb] = useState([]);
      const[opportunity_pickup_post_code,setOpportunityPickupPostCode]=useState([]);
      const[opportunity_drop_off_address_only,setOpportunityDropOffAddressOnly] = useState([]);
      const[opportunity_delivery_suburb,setOpportunityDeliverySuburb]= useState([]);
      const[opportunity_drop_off_post_code,setOpportunityDropOffPostCode] = useState([]);
      const[est_opportunity_date, setEstOpportunityDate] = useState(null);
      const[opportunity_company_id, setOpportunityCompanyId] = useState([]);
      const[opportunity_op_type,setOpportunityOpType] = useState([]);
      const [opportunity_companies, setOpportunityCompanies] = useState("");
      const[opportunity_id, setOpportunityId] = useState([]);
      const[opportunity_job_id, setOpportunityJobId] = useState([]);
        const crmcontacts = data;
      useEffect(() => {
  setJobId(data?.jobs?.map(job => job.job_id).toString());
  if (data) {
      setTimeout(function () {
        setLoader(false);
      }, 2000);
      loadTable();
    }
}, [data]);
    const handleJobType = (event) => {
    setOpType(event.target.value);
  };
  const handleOpportunityType = (event) => {
    setOpportunityOpType(event.target.value);
  };
   const loadTable = () => {
  var url_data = {
    'lead_id': lead_id,
  };

  axios.post("api/ajaxGetCustomerJobData", url_data)
    .then((response) => {
      setJobData(response.data);
      console.log(response);
    });

  axios.post("api/ajaxGetCustomerOpportunityData", url_data)
    .then((response) => {
      setOpportunityData(response.data);
      console.log(response);
    });
  if (job_ids != ""){ 
  console.log("job_ids");// Check if job_ids has a value
    var formData = {
      "from_date": from_date ? from_date : null,
      "to_date": to_date ? to_date : null,
      "allocation_status": allocation_status ? allocation_status : 'Active',
      "storage_type": storage_type ? storage_type : null,
      "storage_unit": storage_unit ? storage_unit : null,
      "job_id": job_ids,
    };
    axios.post("api/crm-leads/datas", formData)
      .then((response) => {
        setStorageData(response.data);
        console.log(response);
      });
  }
};
useEffect(() => {
  loadTable();
}, [job_ids]);
  // Checked All 
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  // Date && Time Format

  const dateFormat = () => {
    var d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return (d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear());
  };
  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      dispatch(onDeleteContact(element.value));
      setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".contactCheckBox:checked");
    ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };
    function handleCompany(selectedOption) {
        setCompanyId(selectedOption);
    }
    function handleOpportunityCompany(selectedCompanyOption) {
        setOpportunityCompanyId(selectedCompanyOption);
    }
     function handleType(selectedOption) {
        setOpType(selectedOption);
    }
  const dispatch = useDispatch();

  useEffect(() => {
    setContact(crmcontacts);
  }, [crmcontacts]);

  useEffect(() => {
    if (!isEmpty(crmcontacts)) {
      setContact(crmcontacts);
      setIsEdit(false);
    }
  }, [crmcontacts]);
  
  //delete Conatct
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const [modal, setModal] = useState(false);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setContact(null);
    } else {
      setModal(true);
      setTag([]);
      setAssignTag([]);
    }
  }, [modal]);
  // Update Data
  const handleContactClick = useCallback((arg) => {
    const contact = arg;

    setContact({
      _id: contact._id,
      // img: contact.img,
      name: contact.name,
      company: contact.company,
      email: contact.email,
      designation: contact.designation,
      phone: contact.phone,
      lead_score: contact.lead_score,
      last_contacted: contact.date,
      // time: contact.time,
      tags: contact.tags,
    });

    setIsEdit(true);
    toggle();
  }, [toggle]);


  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState([]);
  const[deleteId, setDeleteId] = useState([]);

  const handleOrderClick = useCallback((arg) => {
    const order = arg;
    setOrder({
      _id: order._id,
      orderId: order.orderId,
      customer: order.customer,
      product: order.product,
      orderDate: order.orderDate,
      ordertime: order.ordertime,
      amount: order.amount,
      payment: order.payment,
      status: order.status
    });

    setIsEdit(true);
    toggle();
  }, [toggle]);
  const handleJobsClick = useCallback((arg) => {
    const order = arg;
    setOrder({
      _id: order._id,
      orderId: order.orderId,
      customer: order.customer,
      product: order.product,
      orderDate: order.orderDate,
      ordertime: order.ordertime,
      amount: order.amount,
      payment: order.payment,
      status: order.status
    });

    setIsEdit(true);
    toggle();
  }, [toggle]);

const copyJob = (event) => {
    event.preventDefault();
      const formattedDate = moment(est_job_date, 'DD MMM, YYYY').format('DD/MM/YYYY');
    var formData = {
      "copy_job_pickup_address": copy_job_pickup_address,
      "copy_job_drop_off_address": copy_job_drop_off_address,
      "job_pickup_address_only": job_pickup_address_only,
      "job_pickup_suburb": job_pickup_suburb,
      "job_pickup_post_code": job_pickup_post_code,
      "job_drop_off_address_only": job_drop_off_address_only,
      "job_delivery_suburb": job_delivery_suburb,
      "job_drop_off_post_code": job_drop_off_post_code,
      "est_job_date": formattedDate,
      "company_id": company_id.value,
      "op_type":"Moving",
      "job_id": job_ids,
      "lead_id": lead_id,
    }
    axios.post("api/crm-leads/ajaxSaveJob", formData)
      .then(response => {
        if(response.error == 0){
            toast.success(response.message);
            onChangeData();
            tog_mod_1();
          }
          else{
            toast.error(response.message);
          }
       })
      .catch(error => {
        console.error(error);
      });
  }
  const copyOpportunity = (event) => {
    event.preventDefault();
      const formattedDate = moment(est_opportunity_date, 'DD MMM, YYYY').format('DD/MM/YYYY');
    var formData = {
      "copy_opportunity_pickup_address": copy_opportunity_pickup_address,
      "copy_opportunity_drop_off_address": copy_opportunity_drop_off_address,
      "opportunity_pickup_address_only": opportunity_pickup_address_only,
      "opportunity_pickup_suburb": opportunity_pickup_suburb,
      "opportunity_pickup_post_code": opportunity_pickup_post_code,
      "opportunity_drop_off_address_only": opportunity_drop_off_address_only,
      "opportunity_delivery_suburb": opportunity_delivery_suburb,
      "opportunity_drop_off_post_code": opportunity_drop_off_post_code,
      "est_job_date": formattedDate,
      "company_id": opportunity_company_id.value,
      "op_type":"Moving",
      "job_id": opportunity_job_id,
      "lead_id": lead_id,
      "opportunity_id":opportunity_id,
    }
    axios.post("api/crm-leads/ajaxSaveOpportunity", formData)
      .then(response => {
          if(response.error == 0){
            toast.success(response.message);
            onChangeData();
            tog_mod_2();
          }
          else{
            toast.error(response.message);
          }
       })
      .catch(error => {
        console.error(error);
      });
  }
  // Customber Column
  const coljobs = useMemo(
    () => [
      {
        Header: "Job#",
        accessor: "url_data",
        filterable: false,
        Cell: (cell) => {
                    return <Link to={"/listjobs/listjobsdetail/"+cell.value.id} className="fw-medium link-primary">{cell.value.name}</Link>;
                },
       },
      {
        Header: "Name",
        accessor: "",
        filterable: false,

      },
      {
        Header: "Created",
        accessor: "created_at",
        filterable: false,

      },
      {
        Header: "Job Date",
        accessor: "job_date",
        filterable: false,

      },
      {
        Header: "Pickup Suburb",
        accessor: "pickup_suburb",
        filterable: false,

      },
      {
        Header: "Drop Off Suburb",
        accessor: "delivery_suburb",
        filterable: false,
      },
      {
        Header: "Job Status",
        accessor: "job_status",
        filterable: false,
      },
      {
        Header: "Payment Status",
        accessor: "",
        filterable: false,
      },


      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">

              <li className="list-inline-item">
                <button className="btn btn-sm btn-soft-info edit-list" onClick={() => {
                  const orderData = cellProps.row.original;
                  const job_id = orderData.job_id;
                  handleJobsClick(orderData);
                    setIsEdit(true);
                   tog_mod_1(true);
                   var job_popup = {
                        "job_id" : job_id,
                        "lead_id":lead_id,
                   }
                   axios.post("api/crm-leads/ajaxJobPopupData", job_popup)
                    .then(response => {
                      console.log(response);
                        setUserName(response.crmlead.name);
                        setUserEmail(response.email);
                        setUserMobile(response.mobile);
                        setCopyJobPickupAddress(response.jobs.pickup_address + response.jobs.pickup_suburb); 
                        setCopyJobDropOffAddress(response.jobs.drop_off_address + response.jobs.delivery_suburb); 
                        setJobPickupAddressOnly(response.jobs.pickup_address);
                        setJobPickupSuburb(response.jobs.pickup_suburb);
                        setJobPickupPostCode(response.jobs.pickup_post_code);
                        setJobDropOffAddressOnly(response.jobs.drop_off_address);
                        setJobDeliverySuburb(response.jobs.delivery_suburb); 
                        setJobDropOffPostCode(response.jobs.drop_off_post_code);
                        const initialDate = moment(response.jobs.job_date).toDate(); 
                        setEstJobDate(initialDate);
                           const selectedOption = data?.companies?.find(option => option.id === response.jobs.company_id);
                            setCompanyId({ value: response.jobs.company_id, label: selectedOption.company_name}); 
                        setOpType(response.jobs.job_type);
                     })
                    .catch(error => {
                      console.error(error);
                    });
                }} ><i className="bx bx-copy-alt fs-12 pt-1"></i>
                </button>
              </li>
              <li className="list-inline-item edit">
              </li>

            </ul>
          );
        },
      },
    ],
    [handleContactClick, checkedAll]
  );
   const colopportunities = useMemo(
    () => [
      {
        Header: "Opp#",
        accessor: "url_data",
        filterable: false,
        Cell: (cell) => {
                    return <Link to={"/opportunitydetails/"+cell.value.lead_id + "/" + cell.value.id} className="fw-medium link-primary">{cell.value.name}</Link>;
                },
      },
      {
        Header: "Name",
        accessor: "",
        filterable: false,

      },
      {
        Header: "Created",
        accessor: "created_at",
        filterable: false,

      },
      {
        Header: "Job Date",
        accessor: "job_date",
        filterable: false,

      },
      {
        Header: "Pickup Suburb",
        accessor: "pickup_suburb",
        filterable: false,

      },
      {
        Header: "Drop Off Suburb",
        accessor: "drop_off_suburb",
        filterable: false,
      },
      {
        Header: "Status",
        accessor: "op_status",
        filterable: false,
      },
      {
        Header: "Company",
        accessor: "company_name",
        filterable: false,
      },


      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">

              <li className="list-inline-item">
                <button className="btn btn-sm btn-soft-info edit-list" onClick={() => {
                  const orderData = cellProps.row.original;
                  handleOrderClick(orderData);
                    setIsEdit(true);
                   tog_mod_2(true);
                   const job_id = orderData.job_id;
                   const opportunity_id = orderData.id;
                   var opportunity_popup = {
                        "job_id" : job_id,
                        "lead_id":lead_id,
                        "opportunity_id":opportunity_id,
                   }
                   axios.post("api/crm-leads/ajaxOpportunityPopupData", opportunity_popup)
                    .then(response => {
                      console.log(response);
                        setOpportunityId(response.opportunity.id);
                        setOpportunityJobId(response.opportunity.job_id);
                        setOpportunityUserName(response.crmlead.name);
                        setOpportunityUserEmail(response.email);
                        setOpportunityUserMobile(response.mobile);
                        setCopyOpportunityPickupAddress(response.opportunity.pickup_address + response.opportunity.pickup_suburb); 
                        setCopyOpportunityDropOffAddress(response.opportunity.drop_off_address + response.opportunity.delivery_suburb); 
                        setOpportunityPickupAddressOnly(response.opportunity.pickup_address);
                        setOpportunityPickupSuburb(response.opportunity.pickup_suburb);
                        setOpportunityPickupPostCode(response.opportunity.pickup_post_code);
                        setOpportunityDropOffAddressOnly(response.opportunity.drop_off_address);
                        setOpportunityDeliverySuburb(response.opportunity.delivery_suburb); 
                        setOpportunityDropOffPostCode(response.opportunity.drop_off_post_code);
                        const initialDate = moment(response.opportunity.job_date).toDate(); 
                        setEstOpportunityDate(initialDate);
                        setOpportunityCompanyId(response.opportunity.company_id);
                           const selectedCompanyOption = data?.companies?.find(option => option.id === response.opportunity.company_id);
                            setOpportunityCompanyId({ value: response.opportunity.company_id, label: selectedCompanyOption.company_name}); 
                        setOpportunityOpType(response.opportunity.job_type);
                     })
                    .catch(error => {
                      console.error(error);
                    });
                }} ><i className="bx bx-copy-alt fs-12 pt-1"></i>
                </button>
              </li>
              <li className="list-inline-item edit">
              </li>

            </ul>
          );
        },
      },
    ],
    [handleContactClick, checkedAll]
  );
    const colstorage = useMemo(
    () => [
      {
        Header: "Storage Job#",
        accessor: "url_data",
        filterable: false,
          Cell: (cell) => {
                    return <Link to={"/storage/view-storage-job/" + cell.value.id} className="fw-medium link-primary">{cell.value.name}</Link>;
                },
      },
      {
        Header: "Customer",
        accessor: "customer_name",
        filterable: false,

      },
      {
        Header: "Commercial Customer",
        accessor: "",
        filterable: false,

      },
      {
        Header: "Status",
        accessor: "status",
        filterable: false,

      },
      {
        Header: "Storage Units",
        accessor: "storage_unit",
        filterable: false,

      },
      {
        Header: "Location",
        accessor: "location",
        filterable: false,
      },
      {
        Header: "Removals Job#",
        accessor: "job_data",
        filterable: false,
        Cell: (cell) => {
                    return <Link to={"/listjobs/listjobsdetail/" + cell.value.id} className="fw-medium link-primary">{cell.value.name}</Link>;
                },
      },
      {
        Header: "Volume",
        accessor: "volume",
        filterable: false,
      },
      {
        Header: "Date In",
        accessor: "date_in",
        filterable: false,
      },
      {
        Header: "Date Out",
        accessor: "date_out",
        filterable: false,
      },
    ],
    [handleContactClick, checkedAll]
  );
  return (
    <div>
      <Col xxl={12}>
        <Card id="contactList">
          <CardBody className="pt-0">
            <div>
          
                <TableContainer
                  columns={coljobs}
                  data={jobData}
                  isGlobalFilter={true}
                  isAddUserList={false}
                  customPageSize={8}
                  className="custom-header-css"
                  divClass="table-responsive table-card mb-3"
                  tableClass="align-middle table-nowrap"
                  theadClass="table-light"
                  handleContactClick={handleContactClicks}
                  isContactsFilter={true}
                  SearchPlaceholder="Search for contact..."
                />
           

            </div>

            {<Modal
              className="mt-5"
              size="lg"
              isOpen={modal_type_job}
              toggle={() => {
                tog_mod_1();
              }}
            >
              <Card>
                <CardHeader>
                  <h5 className="mb-0">Copy Job</h5>
                </CardHeader>
                <CardBody>
                 <Row>
                 <Col md={4}>
                 {user_name}
                 </Col>
                 <Col md={4}>
                 {user_email}
                 </Col>
                 <Col md={3}>
                 {user_mobile}
                 </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3 ">
                       <Input type="hidden" value={opportunity_job_id} onChange={(e) => setOpportunityJobId(e.target.value)} className="form-control" placeholder="" id="storageName" />
                      <Input type="hidden" value={opportunity_id} onChange={(e) => setOpportunityId(e.target.value)} className="form-control" placeholder="" id="storageName" />
                        <Label for="storageName" className="form-label">Pickup Address</Label>
                        <Input type="text" value={copy_job_pickup_address} onChange={(e) => setCopyJobPickupAddress(e.target.value)} className="form-control" placeholder="" id="storageName" />
                      </div>
                    </Col>
                     <Col md={6}>
                      <div className="mb-3 ">
                        <Label for="storageName" className="form-label">Drop off Address</Label>
                        <Input type="text" value={copy_job_drop_off_address} onChange={(e) => setCopyJobDropOffAddress(e.target.value)} className="form-control" placeholder="" id="storageName" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label for="createdDateinput" className="form-label">Estimated Job Date</Label>
                                <Flatpickr
                                                    className="form-control" placeholder='Start Date' value={est_job_date} onChange={(date) => setEstJobDate(moment(date[0]).format('DD MMM, YYYY'))}
                                                    options={{
                                                        dateFormat: "d M, Y",
                                                        enableTime: false
                                                    }}
                                                />
                                            </div>
                                        </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="choices-multiple-default" className="form-label text-muted">Company</Label>
                              <Select
                                                    value={company_id}
                                                    onChange={(selectedOption) => {
                                                        handleCompany(selectedOption);
                                                    }}
                                                    options={data?.companies?.map(item => ({ value: item.id, label: item.company_name}))}
                                                />
                                            </div>
                                        </Col>
                    </Row>
                   <Row>
                   <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="choices-multiple-default" className="form-label text-muted">Job Type</Label>
                        <select id="lead_type" name="lead_type" className="form-control" value={op_type} onChange={handleJobType}>
                          <option value="Moving">Moving</option>
                        </select>
                                            </div>
                                        </Col>
                  </Row>
                  <Row>
                    <hr></hr>
                    <Row>
                      <Col md={5}></Col>
                      <div className="hstack gap-2 justify-content-end">
                      <button type="submit" onClick={copyJob} className="btn btn-success">Update</button>
                     <button type="submit" className="btn btn-primary" onClick={() =>
                        tog_mod_1(true)
                      }>Cancel</button></div>
                    </Row>
                  </Row>
                </CardBody>
              </Card>

            </Modal>}
            <ToastContainer closeButton={false} limit={1} />
          </CardBody>
        </Card>
      </Col>
  
      <Col xxl={12}>
        <Card id="contactList">
          <CardBody className="pt-0">
            <div>
             
                <TableContainer
                  columns={colopportunities}
                  data={opportunity_data}
                  isGlobalFilter={true}
                  isAddUserList={false}
                  customPageSize={8}
                  className="custom-header-css"
                  divClass="table-responsive table-card mb-3"
                  tableClass="align-middle table-nowrap"
                  theadClass="table-light"
                  handleContactClick={handleContactClicks}
                  isContactsFilter={true}
                  SearchPlaceholder="Search for contact..."
                />
             

            </div>

            {<Modal
              className="mt-5"
              size="lg"
              isOpen={modal_type}
              toggle={() => {
                tog_mod_2();
              }}
            >
              <Card>
                <CardHeader>
                  <h5 className="mb-0">Copy Opportunity</h5>
                </CardHeader>
                <CardBody>
                 <Row>
                 <Col md={4}>
                 {opportunity_user_name}
                 </Col>
                 <Col md={4}>
                 {opportunity_user_email}
                 </Col>
                 <Col md={3}>
                 {opportunity_user_mobile}
                 </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3 ">
                        <Label for="storageName" className="form-label">Pickup Address</Label>
                        <Input type="text" value={copy_opportunity_pickup_address} onChange={(e) => setCopyOpportunityPickupAddress(e.target.value)} className="form-control" placeholder="" id="storageName" />
                      </div>
                    </Col>
                     <Col md={6}>
                      <div className="mb-3 ">
                        <Label for="storageName" className="form-label">Drop off Address</Label>
                        <Input type="text" value={copy_opportunity_drop_off_address} onChange={(e) => setCopyOpportunityDropOffAddress(e.target.value)} className="form-control" placeholder="" id="storageName" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label for="createdDateinput" className="form-label">Estimated Job Date</Label>
                                <Flatpickr
                                                    className="form-control" placeholder='Start Date' value={est_opportunity_date} onChange={(date) => setEstOpportunityDate(moment(date[0]).format('DD MMM, YYYY'))}
                                                    options={{
                                                        dateFormat: "d M, Y",
                                                        enableTime: false
                                                    }}
                                                />
                                            </div>
                                        </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="choices-multiple-default" className="form-label text-muted">Company</Label>
                              <Select
                                                    value={opportunity_company_id}
                                                    onChange={(selectedCompanyOption) => {
                                                        handleOpportunityCompany(selectedCompanyOption);
                                                    }}
                                                    options={data?.companies?.map(item => ({ value: item.id, label: item.company_name}))}
                                                />
                                            </div>
                                        </Col>
                    </Row>
                   <Row>
                   <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="choices-multiple-default" className="form-label text-muted">Job Type</Label>
                        <select id="lead_type" name="lead_type" className="form-control" value={opportunity_op_type} onChange={handleOpportunityType}>
                          <option value="Moving">Moving</option>
                        </select>
                                            </div>
                                        </Col>
                  </Row>
                  <Row>
                    <hr></hr>
                    <Row>
                      <Col md={5}></Col>
                      <div className="hstack gap-2 justify-content-end">
                      <button type="submit" onClick={copyOpportunity} className="btn btn-success">Update</button>
                     <button type="submit" className="btn btn-primary" onClick={() =>
                        tog_mod_2(true)
                      }>Cancel</button></div>
                    </Row>
                  </Row>
                </CardBody>
              </Card>

            </Modal>}
            <ToastContainer closeButton={false} limit={1} />
          </CardBody>
        </Card>
      </Col>
   

      <Col xxl={12}>
        <Card id="contactList">
          <CardBody className="pt-0">
            <div>
                <TableContainer
                  columns={colstorage}
                  data={(storage_data)}
                  isGlobalFilter={true}
                  isAddUserList={false}
                  customPageSize={8}
                  className="custom-header-css"
                  divClass="table-responsive table-card mb-3"
                  tableClass="align-middle table-nowrap"
                  theadClass="table-light"
                  handleContactClick={handleContactClicks}
                  isContactsFilter={true}
                  SearchPlaceholder="Search for contact..."
                />

            </div>

            {/*<Modal
              className="mt-5"
              size="lg"
              isOpen={modal_type}
              toggle={() => {
                tog_mod_2();
              }}
            >
              
            </Modal>*/}
            <ToastContainer closeButton={false} limit={1} />
          </CardBody>
        </Card>
      </Col>
     </div>
  )
}
