module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  api: {
    // API_URL: "http://127.0.0.1:8000/",
    // WEB_URL:"http://127.0.0.1:8000/",
    API_URL: "https://payments.onexfort.com/",
    WEB_URL:"https://payments.onexfort.com/",
  }
};