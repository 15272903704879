import React, { useEffect, useState } from 'react';
import { Col, Input, Label, Row, CardHeader, CardBody, Accordion, AccordionItem, Collapse, Card, Container, Table } from 'reactstrap';
import Flatpickr from "react-flatpickr";
import classnames from "classnames";
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Components
// import * as moment from "moment";
import moment from "moment";
import BreadCrumb from '../../Components/Common/BreadCrumb';


const LeadReport = () => {
    document.title="Lead Report | Onexfort";
    const [col1, setcol1] = useState(false);
    const [data, setData] = useState([]);
    const [source, setSource] = useState([]);
    const t_col1 = () => {
        setcol1(!col1);

    };

    //Created start date and end date
    const [createdDateStart, setCreatedDateStart] = useState(null);
    const [createdDateEnd, setCreatedDateEnd] = useState(null);
    const [selectedSourceId, setselectedSourceId] = useState("none");
    const [org_date, setOrg_Date] = useState([]);

    const loadData = () => {
        axios.get("api/leads-report")
            .then(res => {
                console.log(res, "res4566");
                setOrg_Date(res.organisation_settings);
                setData(res.reports);
                setSource(res.source)
                console.log(res.source);
                setCreatedDateEnd(moment().format(res.organisation_settings?.date_picker_format));
                setCreatedDateStart(moment().subtract(1, 'months').format(res.organisation_settings?.date_picker_format));
            })
    }
    const handleApply = (event) => {
        event.preventDefault();
        const selectedDateFormat = org_date?.date_format;
        const filterData = {
            'source': selectedSourceId,
            'created_date_start': createdDateStart,
            'created_date_end': createdDateEnd,
            'selected_date_format': selectedDateFormat
        }
        let arr = [];
        if (selectedSourceId === "none") {
            console.log(selectedSourceId);
            toast.error('Please select a Source',{theme:"light"});
        }
        else {
            axios.post("api/crm-lead-get-data", filterData)
                .then(res => {
                    console.log(res,"res123");
                    arr.push(res);
                    console.log(arr);
                    setData(arr);
                })
        }


    }

    const handleReset = () => {
        loadData();
        t_col1();
        setselectedSourceId('');
        setCreatedDateEnd(moment().format(org_date.date_picker_format));
        setCreatedDateStart(moment().subtract(1, 'months').format(org_date.date_picker_format));

    }

    useEffect(() => {
        loadData();

    }, [])

    document.title = "Lead Reports | Onexfort";
    console.log(data,"data1234");
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Lead Report" pageTitle="Onexfort" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <Accordion id="default-accordion-example">
                                        <AccordionItem>
                                            <h2 className="accordion-header" id="headingOne">
                                                <button
                                                    className={classnames("accordion-button fw-semibold", { collapsed: !col1 })} type="button" onClick={t_col1} style={{ cursor: "pointer" }} >
                                                    Search Filters
                                                </button>
                                            </h2>
                                            <Collapse isOpen={col1} className="accordion-collapse" id="collapseOne" >
                                                <div className="accordion-body">

                                                    <Row>
                                                        <Col lg={2}>
                                                            <div className="mb-3">
                                                                <Label for="createdDateinput" className="form-label">Start Date</Label>
                                                                <Flatpickr
                                                                    className="form-control" placeholder='Start Date'
                                                                    options={{
                                                                        dateFormat: org_date?.date_format,
                                                                    }}
                                                                    value={createdDateStart}
                                                                    onChange={(selectedDates) => {
                                                                        if (selectedDates.length === 1) {
                                                                            setCreatedDateStart(moment(selectedDates[0]).format(org_date?.date_picker_format));
                                                                        } else {
                                                                            setCreatedDateStart(null);
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg={2}>
                                                            <div className="mb-3">
                                                                <Label for="createdDateinput" className="form-label" >End Date</Label>
                                                                <Flatpickr
                                                                    className="form-control" placeholder='End Date'
                                                                    options={{
                                                                        dateFormat: org_date?.date_format,
                                                                    }}
                                                                    value={createdDateEnd}
                                                                    onChange={(selectedDates) => {
                                                                        if (selectedDates.length === 1) {
                                                                            setCreatedDateEnd(moment(selectedDates[0]).format(org_date?.date_picker_format));
                                                                        } else {
                                                                            setCreatedDateEnd(null);
                                                                        }
                                                                    }}
                                                                />

                                                            </div>
                                                        </Col>

                                                        <Col lg={3}>
                                                            <Label for="Sortingorder" className="form-label">Source</Label>
                                                            <select
                                                                value={selectedSourceId}
                                                                onChange={(event) => setselectedSourceId(event.target.value)}
                                                                className="form-select "
                                                                aria-label="Default select example"
                                                            >
                                                                <option value="none">Please select a value</option>
                                                                {source?.map((item) => (
                                                                    <option key={item.id} value={item.options}>
                                                                        {item.options}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </Col>


                                                        <Col lg={5}>
                                                            <div className="text-end">
                                                                <button type="submit" className="btn form-btn-marg btn-primary"
                                                                    onClick={handleApply}
                                                                >Apply</button>
                                                                <button type="submit" className="btn form-btn-marg btn-primary"
                                                                    onClick={handleReset}
                                                                >Reset</button>
                                                            </div>
                                                        </Col>

                                                    </Row>
                                                </div>
                                            </Collapse>
                                        </AccordionItem>
                                    </Accordion>
                                </CardHeader>
                                <CardBody>

                                    <Table className="table table-bordered">
                                        <thead >
                                            <tr className='text-primary'>
                                                <th>Source</th>
                                                <th>Quotes Created</th>
                                                <th>Jobs Confirmed</th>
                                                <th>Quotes Lost</th>
                                                <th>Total Sales</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.map((item, index) => (
                                                <tr key={index}>
                                                    <td> {item.source}</td>
                                                    <td>{item.QuotesCreated}</td>
                                                    <td>{item.jobsConfirmed}</td>
                                                    <td>{item.QuotesLost}</td>
                                                    <td>{item.totalSales}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ToastContainer closeButton={false} limit={1} />
            </div>
     
        </React.Fragment>
    );
};

export default LeadReport;
