import { Card, CardBody, Col, Row, Form } from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import "../../../assets/scss/pages/_settings.scss";
import {
  CardHeader,
  Input,
  Table,
} from "reactstrap";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../../Components/Common/Loader";


export const ConfigureEmail = () => {
  document.title="Configure Email | Onexfort";
  const [configEmail, setConfigEmail] = useState("");
  const [accountKey, setAccountKey] = useState("");
  const [domainDetail, setDomainDetail] = useState("");
  const [defaultEmail, setDefaultEmail] = useState("");
  const [isLoading, setisLoading] = useState(false);


  const loadCover = () => {
    setisLoading(true);
    axios.get('/api/configure-email/data')
      .then(response => {
        setConfigEmail(response.tenant_api_details);
        setAccountKey(response.account_key);
        setDomainDetail(response.domain_detail);
        setDefaultEmail(response.default_email)
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setisLoading(false);
      })
  }
  //*backend response*
  useEffect(() => {
    loadCover();
  }, []);

  const handleEmailChange = (e) => {
    setAccountKey(e.target.value);
  };

  const handleUpdateEmail = (e) => {
    setDefaultEmail(e.target.value);
  };

  const handleConfigDomain = (event) => {
    event.preventDefault();
    var formData = {
      "domain": accountKey,
    }
    
    axios.post('/api/configure-email/configuredomain',formData)
      .then(response => {
        toast.success(response.message,{theme:"light"});
        loadCover();
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleUpdate = (event) => {
    event.preventDefault();
    var formData = {
      "email": defaultEmail,
    }
    axios.post('/api/configure-email/updateEmail',formData)
      .then(response => {
        if(response.error==0){
          toast.success(response.message,{theme:"light"});
        }
        else{
          toast.error(response.message,{theme:"light"});
        }
        loadCover();
      })
      .catch(error => {
        console.log(error);
      });
  };
  return (
    <div>
      <Card>
        <CardHeader>
          <h5 className="mb-0 text-primary">Configure Email Settings</h5>
        </CardHeader>
        <CardBody>
          <Row>
            <Col lg={12} >
              <Form>
                <Row >
                  <Col lg={4} className="pt-2">
                    <h5 >Email Server Created</h5>
                  </Col>
                  <Col lg={4}>
                    {configEmail ? (
                      <i
                        className="ri-checkbox-circle-fill"
                        style={{ fontSize: '30px', color: '#4fc47f' }}
                      ></i>
                    ) : <i
                      className="ri-close-circle-fill"
                      style={{ fontSize: '30px', color: '#4fc47f' }}
                    ></i>}
                  </Col>
                </Row>
               
                <Row >
                  <Col lg={4} className="pt-2">
                    <h5 >Domain</h5>
                  </Col>
                  <Col lg={4}>
                    {accountKey ? (
                      <i
                        className="ri-checkbox-circle-fill"
                        style={{ fontSize: '30px', color: '#4fc47f' }}
                      ></i>
                    ) : <i
                    className="ri-close-circle-fill"
                    style={{ fontSize: '30px', color: '#d9dad9' }}
                  ></i>}
                  </Col>
                </Row>
                <Row className="pt-2">
                  <Col lg={4}>
                    <Input type="text" className="form-control" value={accountKey} onChange={handleEmailChange} aria-label="Recipient's username" aria-describedby="button-addon2" placeholder="Your website domain here" />
                  </Col>
                  <Col lg={4}>
                    {accountKey!="" ? (
                      <button className="btn btn-light btn-md weight500" type="button" disabled >Configure Domain</button>
                    ) : <button id="configure_domain" className="btn btn-light btn-md weight500" onClick={handleConfigDomain}>Configure Domain</button>
                    }
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col sm={12}  >
                    <h5 >DNS Settings</h5>
                  </Col>
                  <Col sm={12} className="mt-3">
                    <p >
                      Head over to DNS provider and add the following records to verify your domain for sending emails through Onexfort.
                    </p>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <p>Return Path Domain </p>
                  <Col sm={12}>

                    <Table className="table  email-config-table">
                      <thead>
                        <tr>
                          <th style={{ width: '20%' }}></th>
                          <th style={{ width: '20%' }}>Hostname</th>
                          <th style={{ width: '10%' }}>Type</th>
                          <th style={{ width: '40%' }}>Add this value</th>
                          <th style={{ width: '10%' }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="d-flex mb-2" >
                              {domainDetail != false && domainDetail.DKIMVerified == 1 ? (
                                <i
                                  className="ri-checkbox-circle-fill"
                                  style={{ fontSize: '30px', color: '#4fc47f' }}
                                ></i>
                              ) : <i
                                className="ri-close-circle-fill"
                                style={{ fontSize: '30px', color: '#d9dad9' }}
                              ></i>}

                              <div className="p-1">
                                <div className="font-weight-semibold font16">DKIM</div>
                                {domainDetail != false && domainDetail.DKIMVerified == 1 ? (
                                  <span className="icongreen">Active</span>
                                ) :
                                  <span className="text-muted">Inactive</span>
                                }
                              </div>
                            </div>
                          </td>
                          <td>
                            {domainDetail != false && domainDetail.DKIMVerified == 1 ? (
                              domainDetail.DKIMHost
                            ) : (
                              domainDetail.DKIMPendingHost
                            )}
                          </td>
                          <td>
                            {domainDetail != false ? (
                              'TXT'
                            ) : null}
                          </td>
                          <td>
                            {domainDetail !== false && domainDetail.DKIMVerified === 1 ? (
                              domainDetail.DKIMTextValue && domainDetail.DKIMTextValue.replace(' ', '')
                            ) : (
                              domainDetail !== false && domainDetail.DKIMPendingTextValue && domainDetail.DKIMPendingTextValue.replace(' ', '')
                            )}
                          </td>
                          <td>
                            {domainDetail != false && domainDetail.DKIMVerified == false ? (
                              <button id="verify_dkim" className="btn btn-primary btn-sm" data-val={domainDetail.ID}>Verify</button>
                            ) : null}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex ">
                              {domainDetail != false && domainDetail.ReturnPathDomainVerified == true ? (
                                <i
                                  className="ri-checkbox-circle-fill"
                                  style={{ fontSize: '30px', color: '#4fc47f' }}
                                ></i>
                              ) : <i
                                className="ri-close-circle-fill"
                                style={{ fontSize: '30px', color: '#d9dad9' }}
                              ></i>}

                              <div className="p-1">
                                <div className="font-weight-semibold " >Return-Path</div>
                                {domainDetail != false && domainDetail.ReturnPathDomainVerified == true ? (
                                  <span className="icongreen">Active</span>
                                ) :
                                  <span className="text-muted">Inactive</span>
                                }
                              </div>
                            </div>
                          </td>
                          <td>
                            {domainDetail != false && domainDetail.ReturnPathDomainVerified == 1 ? (
                              domainDetail.ReturnPathDomain
                            ) : (
                              domainDetail != false && "pm-bounces"
                            )}
                          </td>
                          <td>
                            {domainDetail != false ? (
                              'CNAME'
                            ) : null}
                          </td>
                          <td>
                            {domainDetail != false ? (
                              domainDetail.ReturnPathDomainCNAMEValue
                            ) : null}
                          </td>
                          <td>
                            {domainDetail != false && domainDetail.ReturnPathDomainVerified == false ? (
                              <button id="verify_return_path" className="btn btn-primary btn-sm" data-val={domainDetail.ID}>Verify</button>
                            ) : null}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={12}>
                    <p >
                      Help Article: <a href="https://postmarkapp.com/support/article/1090-resources-for-adding-dkim-and-return-path-records-to-dns-for-common-hosts-and-dns-providers" target="_blank" style={{ textDecoration: 'underline', color: '#2196f3' }}>
                        Resources for adding DNS records for common hosts and DNS providers
                      </a>
                    </p>
                  </Col>
                </Row>
                <Row className="mt-3" >
                  <Col sm={12} className="mb-1" >
                    <h5>Default Email For Communication</h5>
                  </Col>
                </Row>
                <Row  className="mt-3">
                  <Col sm={4}  >
                    <Input type="text" className="form-control domain_field" id="default_email" value={defaultEmail} onChange={handleUpdateEmail} placeholder="email@onexfort.com" />
                  </Col>
                  <Col sm={4}>
                    <button id="update_default_email" className="btn btn-light btn-md weight500" onClick={handleUpdate}>Update</button>
                  </Col>
                </Row>
           
                <Row className="mt-3">
                  <Col sm={12} >
                    <h5>Email Forwarding</h5>
                  </Col>
                  <Col sm={12} className="mt-3">
                    <p >
                      Head over to your Email service provider and set up to forward a copy of the email received as follows:
                    </p>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col sm={3}>{defaultEmail}</Col>
                  <Col sm={3} ><b>forward a copy to</b></Col>
                  <Col sm={6}>
                    <p>
                      {configEmail && configEmail.incoming_email}
                    </p>
                  </Col>
                </Row>
                <Row className="mt-3" >
                  <Col sm={12}>
                    <p>
                      Help Article: <a href="https://postmarkapp.com/support/article/785-configuring-a-custom-email-address-forward-with-gmail" target="_blank" style={{ textDecoration: 'underline', color: '#2196f3' }}>
                        Configuring email forwarding in Gmail/Google Apps
                      </a>
                    </p>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          {/* reloading */}
          {isLoading && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(255, 255, 255, 0.8)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999,
              }}
            >
              <Loader />
            </div>
          )}
        </CardBody>
      </Card>
      <ToastContainer closeButton={false} limit={1} theme="light" />
    </div>
  )
}
