import React, { useState, useEffect, useRef } from "react";
import {
  Accordion,
  AccordionItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Form,
  Label,
  Input,
  Container,
  Row,
  Modal,
  ModalBody,
} from "reactstrap";
import { Link } from "react-router-dom";
import DeleteModal from "../../../Components/Common/DeleteModal";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import moment from "moment";
import Loader from "../../../Components/Common/Loader";
import api from "../../../config";
import { es } from "date-fns/locale";
import { get_cookie } from "../../../helpers/get_cookie";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";

export const Activity = ({ data, onChangeData }) => {
  const user = JSON.parse(get_cookie("authUser"));
  var tenant_id = user.email;

  const [notes, setNotes] = useState([]);
  const [emailtemplates, setEmailTemplates] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showmodelemail, setShowModalemail] = useState(false);
  const [showmodelemailfw, setShowModalemailFw] = useState(false);
  const [showmodelemailrpy, setShowModalemailrpy] = useState(false);
  const [showmodelemailrpyrec, setShowModalemailrpyrec] = useState(false);
  const [ppl_people, setPPL_People] = useState([]);
  const [ppl_peoples, setPPL_Peoples] = useState([]);
  const [smstemplates, setSMSTemplates] = useState([]);
  const [sms_contacts, setSMSContacts] = useState([]);
  const [job, setjobid] = useState([]);
  const [leademail, setleademail] = useState([]);
  const [removal, setremoval] = useState([]);
  const [templateid, setTemplateid] = useState(0);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [cc, setcc] = useState("");
  const [bcc, setbcc] = useState("");
  const [companyname, setCompanyname] = useState();
  const [smsfrom, setsmsfrom] = useState();
  const [subrpyrec, setSubrpyrec] = useState('');
  const [edit_notes, setEditNotes] = useState("");
  const [edit_email, setEditemail] = useState("");
  const [edit_emaill, setEditemaill] = useState("");
  const [fileemailrpyrec, selectedFileemailrpyrec] = useState(null);
  const [toemailrpyrec, setToEmailrpyrec] = useState("");

  const [edit_emailreciv, setEditemailreciv] = useState("");
  const [initialSub, setInitialSub] = useState('');
  const [sub, setSub] = useState('');
  const [subrpy, setSubrpy] = useState('');
  const [edit_email_reply, setEditemailreply] = useState("");
  const [coll3, setcoll3] = useState(false);
  const [emailcontent, setemailcontent] = useState([]);
  const [coll4, setcoll4] = useState(false);
  const [editedLogSubject, setEditedLogSubject] = useState("");
  const [toemail, setToEmail] = useState("");
  const [toemailfw, setToEmailfw] = useState("");
  const [toemailrpy, setToEmailrpy] = useState("");
  const [leadid, setleadid] = useState("");
  const [tempid, setTempid] = useState();
  const [forwardto, setforwardto] = useState("");
  const [smsMessage, setSmsMessage] = useState("");
  const [smsCredits, setSmsCredits] = useState(1);
  const [smsto, SetSmsto] = useState();
  const [smsTotalWords, setSmsTotalWords] = useState(0);
  const [smsTemplates, setSmsTemplates] = useState([]);
  //notes attachemnt
  const [file, selectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  //email attachment
  const [fileemail, selectedFileemail] = useState(null);
  const [fileemailfw, selectedFileemailfw] = useState(null);
  const [fileemailrpy, selectedFileemailrpy] = useState(null);
  const [selectedFileNameEmail, setSelectedFileNameEmail] = useState("");
  const [selectedFileNameEmailfw, setSelectedFileNameEmailfw] = useState("");
  const [selectedFileNameEmailrpy, setSelectedFileNameEmailrpy] = useState("");
  const [isLoader, setLoader] = useState(true);
  const [isNote, setIsNote] = useState(null);
  const [isEmail, setIsEmail] = useState(null);
  const [isEmailrpy, setIsEmailrpy] = useState(null);

  useEffect(() => {
    console.log(data, "data");
    setNotes(data?.notes);
    setEmailTemplates(data?.email_templates);
    setSMSTemplates(data?.sms_templates);
    setPPL_People(data?.ppl_people);
    setPPL_Peoples(`<br />${data?.ppl_people?.email_signature || ''}`);
    setSMSContacts(data?.sms_contacts);
    setjobid(data?.job);
    setremoval(data?.removal_companies);
    setleademail(data?.crmcontact_email);
    setsmsfrom(data?.companies?.sms_number);
    setleadid(data?.job?.customer_id);
    setforwardto(data?.notes?.log_to);
    if (data) {
      setTimeout(function () {
        setLoader(false);
      }, 2000);
    }
  }, [data]);

  useEffect(() => {
    setToEmail(leademail);
  }, [leademail]);



  // useEffect(() => {
  //   if (notes && notes.length > 0) {
  //     if (notes[0].log_subject == null) {
  //       setEditedLogSubject("");
  //     } else {
  //       setEditedLogSubject(notes[0].log_subject);
  //     }
  //   }
  // }, [notes]);
  const [bookingDetails, setBookingdetails] = useState(false);
  const t_bookingDetails = () => {
    setBookingdetails(!bookingDetails);
  };

  const handleAddNotes = () => {
    setLoader(true);
    if (editedLogSubject === '') {
      toast.error("The Notes field is required.", { theme: "light" });
      setLoader(false);
      return; // Stop form submission if editedLogSubject is empty
    }
    if (editedLogSubject.length > 0) {
      var postdata = editedLogSubject;
    } else {
      var postdata = "";
    }
    const formData = new FormData();
    formData.append("lead_id", notes[0].lead_id);
    formData.append("notes", editedLogSubject);
    formData.append("job_id", notes[0].job_id);
    formData.append("notes_attachment", JSON.stringify(attch));

    axios
      .post("/api/crm-leads/ajaxStoreNote", formData)
      .then((response) => {
        console.log(response.message);
        t_editnotes();
        toast.success(response.message, { theme: "light" });
        t_bookingDetails();
        onChangeData();
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setnote(false);
        // window.location.reload();
        setLoader(false);
      })

  };

  const t_coll3 = () => {
    setcoll3(!coll3);
  };

  const t_coll4 = () => {
    setcoll4(!coll4);
  };

  const t_editnotes = (id) => {
    setEditNotes(id);
  };

  // const t_editemail = (id) => {
  //   setCompanyname(data?.company_list[0].email);
  //   setEditemail(id);
  // };

  // const t_editemailreply = (id) => {
  //   setCompanyname(data?.company_list[0].email);
  //   setEditemaill(id);
  // };

  // const t_editemailreplyreciv = (id) => {
  //   setCompanyname(data?.company_list[0].email);
  //   setEditemailreciv(id);
  // };

  const t_editemail = (id) => {
    if (edit_email === id) {
      // If the content is already open, close it by setting edit_email to an empty string
      setCompanyname((data?.company_list[0]?.email) || "");
      setEditemail("");
    } else {
      // If the content is closed, open it by setting edit_email to the current id
      setCompanyname((data?.company_list[0]?.email) || "");
      setEditemail(id);
    }
  };



  const t_editemailreply = (id) => {
    if (edit_emaill === id) {
      // If the content is already open, close it by setting edit_email to an empty string
      setCompanyname((data?.company_list[0]?.email) || "");
      setEditemaill("");
    } else {
      // If the content is closed, open it by setting edit_email to the current id
      setCompanyname((data?.company_list[0]?.email) || "");
      setEditemaill(id);
    }
  };


  const t_editemailreplyreciv = (id) => {
    if (edit_emailreciv === id) {
      // If the content is already open, close it by setting edit_email to an empty string
      setCompanyname((data?.company_list[0]?.email) || "");
      setEditemailreciv("");
    } else {
      // If the content is closed, open it by setting edit_email to the current id
      setCompanyname((data?.company_list[0]?.email) || "");
      setEditemailreciv(id);
    }
  };



  const sendEmail = () => {
    setLoader(true);
    if (companyname === '') {
      toast.error("The email field is required.", { theme: "light" });
      setLoader(false);
      return; // Stop form submission if orgDate is empty
    } else if (toemail === '') {
      toast.error("The email field is required.", { theme: "light" });
      setLoader(false);
      return; // Stop form submission if orgDate is empty
    }
    else if (companyname !== '') {
      console.log("elseif");
      const emailPattern = /^[A-Za-z0-9._+-]+@[A-Za-z._-]+\.[A-Za-z]{2,}$/;
      const isValid = emailPattern.test(companyname);
      if (!isValid) {
        console.log("if");
        toast.error('Invalid email', { theme: "light" });
        setLoader(false);
        return;
      }
    } else if (toemail !== '') {
      const emailPattern = /^[A-Za-z0-9._+-]+@[A-Za-z._-]+\.[A-Za-z]{2,}$/;
      const isValid = emailPattern.test(toemail);
      if (!isValid) {
        toast.error('Invalid email', { theme: "light" });
        setLoader(false);
        return;
      }
    } else if (cc !== '') {
      const emailPattern = /^[A-Za-z0-9._+-]+@[A-Za-z._-]+\.[A-Za-z]{2,}$/;
      const isValid = emailPattern.test(cc);
      if (!isValid) {
        toast.error('Invalid email', { theme: "light" });
        setLoader(false);
        return;
      }
    } else if (bcc !== '') {
      const emailPattern = /^[A-Za-z0-9._+-]+@[A-Za-z._-]+\.[A-Za-z]{2,}$/;
      const isValid = emailPattern.test(bcc);
      if (!isValid) {
        toast.error('Invalid email', { theme: "light" });
        setLoader(false);
        return;
      }
    }
    const logType3Note = removal?.find((res) => res.email === companyname);
    let contact_Name = "";
    if (logType3Note) {
      contact_Name = logType3Note.contact_name;
    } else {
      contact_Name = "";
    }
    // console.log(attname);
    const fileNames = attname.map((file) => file.name);
    console.log(fileNames);

    const formData = new FormData();
    formData.append("lead_id", leadid);
    formData.append("job_id", job.job_id);
    formData.append("from_name", contact_Name);
    formData.append("from_email", companyname);
    formData.append("to", toemail);
    formData.append("cc", cc);
    formData.append("bcc", bcc);
    formData.append("email_subject", subject);
    formData.append("email_template", selectedTemplate);
    formData.append("email_body", ppl_peoples);
    formData.append("email_attachment", JSON.stringify(attname));
    // console.log(companyname);
    axios
      .post("/api/crm-leads/ajaxSendEmail", formData)
      .then((response) => {
        console.log(response, "123344");
        toast.success(response.message, { theme: "light" });
        onChangeData();
        t_editemail();
        setbcc("");
        setcc("");
        setsubject("");
        // setSelectedTemplate("");
        setEmailBody("");
        setAttName([]);
      })
      .catch((error) => {
        console.error(error);
      }).finally(() => {
        setemail(false);
        // window.location.reload();
        setLoader(false);
      })
  };


  const updateedit = (editlog, id) => {
    setLoader(true);
    if (editlog === '') {
      toast.error("The Notes field is required.", { theme: "light" });
      setLoader(false);
      return; // Stop form submission if editlog is empty
    }
    const updatededitNote = {
      lead_id: notes[0].lead_id,
      notes: editlog,
      id: id,
      notes_attachment: attch,
    };

    axios
      .post("/api/crm-leads/ajaxUpdateNote", updatededitNote)
      .then((response) => {
        console.log(response);
        t_editnotes();
        toast.success(response.message, { theme: "light" });
        onChangeData();
      })
      .catch((error) => {
        console.error(error);
      }).finally(() => {
        setLoader(false);
      })
  };



  ///
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const destroynotes = (id) => {
    setDeleteItemId(id);
    setDeleteModal(true);

  }


  ///
  const handleDeleteOrder = () => {
    const destroynotes = {
      lead_id: notes[0].lead_id,
      id: deleteItemId,
    };

    axios
      .post("/api/crm-leads/ajaxDestroyNote", destroynotes)
      .then((response) => {
        console.log(response.message);
        setDeleteModal(false);
        toast.success(response.message, { theme: "light" });
        onChangeData();
        // window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  //attachment for note tab
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log(file, "selectedFile");
    event.preventDefault();
    selectedFile(file);
    setIsNote(file);
    const formData = new FormData();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    selectedFile(file);
    setSelectedFileName(file.name);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const [attch, setAttach] = useState([]);
  const [key, setKey] = useState([]);

  const fileuploadnotes = () => {
    const formData = new FormData();
    formData.append("id", notes[0].lead_id);
    formData.append("type", "notes"); //dd
    formData.append("is_reply", 0); //dd
    formData.append("attachment", file);
    formData.append("note_id", "");
    if (isNote) {
      axios
        .post("/api/crm-leads/uploadActivityAttachment", formData)
        .then((response) => {
          toast.success(response.message, { theme: "light" });
          setAttach([...attch, response.attachment]);
          selectedFile(null);
          setShowModal(false);
          setIsNote(null);
        })
        .catch((error) => {
          console.error(error);
        })
    }
  };

  //send email attachment
  const fileInputRefemail = useRef(null);

  const handleClicks = () => {
    fileInputRefemail.current.click();
  };
  const handleFileChangesemail = (event) => {
    const fileemail = event.target.files[0];
    event.preventDefault();
    selectedFileemail(fileemail);
    const formData = new FormData();
    setIsEmail(fileemail);
  };
  const handleDropemail = (event) => {
    event.preventDefault();
    const fileemail = event.dataTransfer.files[0];
    selectedFileemail(fileemail);
    setSelectedFileNameEmail(fileemail.name);
  };

  const handleDragOveremail = (event) => {
    event.preventDefault();
  };

  const [attname, setAttName] = useState([]);

  const fileuploademail = () => {
    const formData = new FormData();
    formData.append("id", notes[0].lead_id);
    formData.append("type", "email");
    formData.append("is_reply", 0);
    formData.append("attachment", fileemail);
    formData.append("note_id", "");
    if (isEmail) {
      axios
        .post("/api/crm-leads/uploadActivityAttachment", formData)
        .then((response) => {
          toast.success(response.message, { theme: "light" });
          // setAttach([...attch, response.attachment]);
          setAttName([...attname, response.attachment]);
          selectedFileemail(null);
          setShowModalemail(false);
          setIsEmail(null);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  //email forward
  const fileInputRefemailfw = useRef(null);

  const handleClicksfw = () => {
    fileInputRefemailfw.current.click();
  };
  const handleFileChangesemailfw = (event) => {
    const fileemail = event.target.files[0];
    event.preventDefault();
    selectedFileemailfw(fileemail);
    const formData = new FormData();
  };
  const handleDropemailfw = (event) => {
    event.preventDefault();
    const fileemail = event.dataTransfer.files[0];
    selectedFileemailfw(fileemail);
    setSelectedFileNameEmailfw(fileemail.name);
  };

  const handleDragOveremailfw = (event) => {
    event.preventDefault();
  };

  const [attnamefw, setAttNameFw] = useState([]);

  const fileuploademailfw = () => {
    const formData = new FormData();
    formData.append("id", notes[0].lead_id);
    formData.append("type", "email");
    formData.append("is_reply", 0);
    formData.append("attachment", fileemailfw);
    formData.append("note_id", "");

    axios
      .post("/api/crm-leads/uploadActivityAttachment", formData)
      .then((response) => {
        toast.success(response.message, { theme: "light" });
        // setAttach([...attch, response.attachment]);
        setAttNameFw([...attnamefw, response.attachment]);
        selectedFileemailfw(null);
        setShowModalemailFw(false);

      })
      .catch((error) => {
        console.error(error);
      });
  };

  //email reply
  const fileInputRefemailrpy = useRef(null);

  const handleClicksrpy = () => {
    fileInputRefemailrpy.current.click();
  };
  const handleFileChangesemailrpy = (event) => {
    const fileemail = event.target.files[0];
    event.preventDefault();
    selectedFileemailrpy(fileemail);
    const formData = new FormData();
    setIsEmailrpy(fileemail);
  };
  const handleDropemailrpy = (event) => {
    event.preventDefault();
    const fileemail = event.dataTransfer.files[0];
    selectedFileemailrpy(fileemail);
    setSelectedFileNameEmailrpy(fileemail.name);
  };

  const handleDragOveremailrpy = (event) => {
    event.preventDefault();
  };

  const [attnamerpy, setAttNamerpy] = useState([]);

  const fileuploademailrpy = () => {
    const formData = new FormData();
    formData.append("id", notes[0].lead_id);
    formData.append("type", "email");
    formData.append("is_reply", 0);
    formData.append("attachment", fileemailrpy);
    formData.append("note_id", "");

    axios
      .post("/api/crm-leads/uploadActivityAttachment", formData)
      .then((response) => {
        toast.success(response.message, { theme: "light" });
        // setAttach([...attch, response.attachment]);
        setAttNamerpy([...attnamerpy, response.attachment]);
        selectedFileemailrpy(null);
        setShowModalemailrpy(false);
        setIsEmailrpy(null);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  //email reply(email recieved)
  const fileInputRefemailrpyrec = useRef(null);

  const handleClicksrpyrec = () => {
    fileInputRefemailrpyrec.current.click();
  };
  const handleFileChangesemailrpyrec = (event) => {
    const fileemail = event.target.files[0];
    event.preventDefault();
    selectedFileemailrpyrec(fileemail);
    const formData = new FormData();
  };
  const handleDropemailrpyrec = (event) => {
    event.preventDefault();
    const fileemail = event.dataTransfer.files[0];
    selectedFileemailrpyrec(fileemail);
    setSelectedFileNameEmailrpy(fileemail.name);
  };

  const handleDragOveremailrpyrec = (event) => {
    event.preventDefault();
  };

  const [attnamerpyrec, setAttNamerpyrec] = useState([]);

  const fileuploademailrpyrec = () => {
    const formData = new FormData();
    formData.append("id", notes[0].lead_id);
    formData.append("type", "email");
    formData.append("is_reply", 0);
    formData.append("attachment", fileemailrpyrec);
    formData.append("note_id", "");

    axios
      .post("/api/crm-leads/uploadActivityAttachment", formData)
      .then((response) => {
        toast.success(response.message, { theme: "light" });
        // setAttach([...attch, response.attachment]);
        setAttNamerpyrec([...attnamerpyrec, response.attachment]);
        selectedFileemailrpyrec(null);
        setShowModalemailrpyrec(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const chooseTemplate = (event) => {
    const selectedTemplateId = event.target.value;
    setTemplateid(selectedTemplateId);
    console.log(selectedTemplateId, "vgvgvgvgvgvgvgv");
    const url = `/api/crm-leads/getSmsTemplate/${selectedTemplateId}`;
    const data = {
      lead_id: leadid,
      job_id: job.job_id,
      job_type: job.job_type,
    };

    axios
      .post(url, data)
      .then((response) => {
        console.log(response);
        const { sms_message } = response;
        setSmsMessage(sms_message);
        countChar();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [modalerr, setModalErr] = useState(false);
  const [errormsg, setErrorMsg] = useState("");
  const [credits, setCredits] = useState(0);

  const sendsms = () => {
    console.log(smsfrom, "tempid");
    setLoader(true);
    if (smsfrom === '') {
      toast.error("The email field is required.", { theme: "light" });
      setLoader(false);
      return; // Stop form submission if orgDate is empty
    } else if (smsto === '') {
      toast.error("The email field is required.", { theme: "light" });
      setLoader(false);
      return; // Stop form submission if orgDate is empty
    } else if (smsMessage === '') {
      toast.error("Please enter Message!.", { theme: "light" });
      setLoader(false);
      return; // Stop form submission if orgDate is empty
    }
    const len = smsMessage.length;
    if (len > 0) {
      const calculatedCredits = Math.ceil(len / 160);
      setCredits(calculatedCredits);
    }
    let smsend = {
      'lead_id': leadid,
      'sms_from': smsfrom,
      'sms_to': smsto,
      'sms_templates': templateid,
      'sms_message': smsMessage,
      'sms_total_credits': credits,
      'job_id': job.job_id,
    };

    axios
      .post("/api/crm-leads/ajaxSendSms", smsend)
      .then((response) => {

        console.log(response);
        if (response.error == 0) {
          toast.success(response.message, { theme: "light" });
        } else {
          // toast.error(response.message, { theme: "light" });
          setErrorMsg(response.message);
          setModalErr(true);
        }
      })
      .catch((error) => {
        console.error(error);
      }).finally(() => {
        setLoader(false);
      })
  };





  const forwardemail = (res) => {
    setLoader(true);
    if (companyname === '') {
      toast.error("The email field is required.", { theme: "light" });
      setLoader(false);
      return; // Stop form submission if orgDate is empty
    } else if (toemailfw === '') {
      toast.error("The email field is required.", { theme: "light" });
      setLoader(false);
      return; // Stop form submission if orgDate is empty
    }
    else if (companyname !== '') {
      const emailPattern = /^[A-Za-z0-9._+-]+@[A-Za-z._-]+\.[A-Za-z]{2,}$/;
      const isValid = emailPattern.test(companyname);
      if (!isValid) {
        toast.error('Invalid email', { theme: "light" });
        setLoader(false);
        return;
      }
    } else if (toemailfw !== '') {
      const emailPattern = /^[A-Za-z0-9._+-]+@[A-Za-z._-]+\.[A-Za-z]{2,}$/;
      const isValid = emailPattern.test(toemailfw);
      if (!isValid) {
        toast.error('Invalid email', { theme: "light" });
        setLoader(false);
        return;
      }
    } else if (cc !== '') {
      const emailPattern = /^[A-Za-z0-9._+-]+@[A-Za-z._-]+\.[A-Za-z]{2,}$/;
      const isValid = emailPattern.test(cc);
      if (!isValid) {
        toast.error('Invalid email', { theme: "light" });
        setLoader(false);
        return;
      }
    } else if (bcc !== '') {
      const emailPattern = /^[A-Za-z0-9._+-]+@[A-Za-z._-]+\.[A-Za-z]{2,}$/;
      const isValid = emailPattern.test(bcc);
      if (!isValid) {
        toast.error('Invalid email', { theme: "light" });
        setLoader(false);
        return;
      }
    }
    if (emailcontent.length > 0) {
      var post = emailcontent;
    } else {
      var post =
        "<p><br/><br/><br/>" +
        ppl_people.email_signature +
        "</p><hr/><p><b>From: </b>" +
        res.log_from +
        "<br/><b>Sent: </b>" +
        moment(res.log_date).format("dddd, MMMM DD, YYYY h:mm:ss A") +
        "<br/><b>To: </b>" +
        res.log_to +
        "<br/><b>Subject: </b>" +
        res.log_subject +
        "</p><br/>" +
        res.log_message;
    }
    const formData = new FormData();
    formData.append("lead_id", leadid);
    formData.append("is_reply", "");
    formData.append("from_email", companyname);
    formData.append("to", toemailfw);
    formData.append("cc", cc);
    formData.append("bcc", bcc);
    formData.append("email_subject", sub);
    formData.append("email_body", post);
    formData.append("job_id", job.job_id);
    formData.append("email_attachment", JSON.stringify(attnamefw));

    axios
      .post("/api/crm-leads/ajaxSendEmail", formData)
      .then((response) => {
        console.log(response, "111111");
        t_editemail();
        onChangeData();
        toast.success(response.message, { theme: "light" });
      })
      .catch((error) => {
        console.error(error);
      }).finally(() => {
        setLoader(false);
      })
  };

  const replyemail = (res) => {
    setLoader(true);
    if (companyname === '') {
      toast.error("The email field is required.", { theme: "light" });
      setLoader(false);
      return; // Stop form submission if orgDate is empty
    } else if (toemailrpy === '') {
      toast.error("The email field is required.", { theme: "light" });
      setLoader(false);
      return; // Stop form submission if orgDate is empty
    }
    else if (companyname !== '') {
      const emailPattern = /^[A-Za-z0-9._+-]+@[A-Za-z._-]+\.[A-Za-z]{2,}$/;
      const isValid = emailPattern.test(companyname);
      if (!isValid) {
        toast.error('Invalid email', { theme: "light" });
        setLoader(false);
        return;
      }
    } else if (toemailrpy !== '') {
      const emailPattern = /^[A-Za-z0-9._+-]+@[A-Za-z._-]+\.[A-Za-z]{2,}$/;
      const isValid = emailPattern.test(toemailrpy);
      if (!isValid) {
        toast.error('Invalid email', { theme: "light" });
        setLoader(false);
        return;
      }
    } else if (cc !== '') {
      const emailPattern = /^[A-Za-z0-9._+-]+@[A-Za-z._-]+\.[A-Za-z]{2,}$/;
      const isValid = emailPattern.test(cc);
      if (!isValid) {
        toast.error('Invalid email', { theme: "light" });
        setLoader(false);
        return;
      }
    } else if (bcc !== '') {
      const emailPattern = /^[A-Za-z0-9._+-]+@[A-Za-z._-]+\.[A-Za-z]{2,}$/;
      const isValid = emailPattern.test(bcc);
      if (!isValid) {
        toast.error('Invalid email', { theme: "light" });
        setLoader(false);
        return;
      }
    }
    if (emailcontent.length > 0) {
      var post = emailcontent;
    } else {
      var post =
        "<p><br/><br/><br/>" +
        ppl_people.email_signature +
        "</p><hr/><p><b>From: </b>" +
        res.log_from +
        "<br/><b>Sent: </b>" +
        moment(res.log_date).format("dddd, MMMM DD, YYYY h:mm:ss A") +
        "<br/><b>To: </b>" +
        res.log_to +
        "<br/><b>Subject: </b>" +
        res.log_subject +
        "</p><br/>" +
        res.log_message;
    }

    const formData = new FormData();
    formData.append("lead_id", leadid);
    formData.append("is_reply", tenant_id);
    formData.append("from_email", companyname);
    formData.append("to", toemailrpy);
    formData.append("cc", cc);
    formData.append("bcc", bcc);
    formData.append("email_subject", subrpy);
    formData.append("email_body", post);
    formData.append("job_id", job.job_id);
    formData.append("email_attachment", JSON.stringify(attnamerpy));
    axios
      .post("/api/crm-leads/ajaxSendEmail", formData)
      .then((response) => {
        console.log(response);

        t_editemailreply();
        onChangeData();
        toast.success(response.message, { theme: "light" });
      })
      .catch((error) => {
        console.error(error);
      }).finally(() => {
        setLoader(false);
      })
  };

  //email recieved(reply)
  const replyemailrec = (res) => {
    setLoader(true);
    if (companyname === '') {
      toast.error("The email field is required.", { theme: "light" });
      setLoader(false);
      return; // Stop form submission if orgDate is empty
    } else if (toemailrpyrec === '') {
      toast.error("The email field is required.", { theme: "light" });
      setLoader(false);
      return; // Stop form submission if orgDate is empty
    }
    else if (companyname !== '') {
      const emailPattern = /^[A-Za-z0-9._+-]+@[A-Za-z._-]+\.[A-Za-z]{2,}$/;
      const isValid = emailPattern.test(companyname);
      if (!isValid) {
        toast.error('Invalid email', { theme: "light" });
        setLoader(false);
        return;
      }
    } else if (toemailrpyrec !== '') {
      const emailPattern = /^[A-Za-z0-9._+-]+@[A-Za-z._-]+\.[A-Za-z]{2,}$/;
      const isValid = emailPattern.test(toemailrpyrec);
      if (!isValid) {
        toast.error('Invalid email', { theme: "light" });
        setLoader(false);
        return;
      }
    } else if (cc !== '') {
      const emailPattern = /^[A-Za-z0-9._+-]+@[A-Za-z._-]+\.[A-Za-z]{2,}$/;
      const isValid = emailPattern.test(cc);
      if (!isValid) {
        toast.error('Invalid email', { theme: "light" });
        setLoader(false);
        return;
      }
    } else if (bcc !== '') {
      const emailPattern = /^[A-Za-z0-9._+-]+@[A-Za-z._-]+\.[A-Za-z]{2,}$/;
      const isValid = emailPattern.test(bcc);
      if (!isValid) {
        toast.error('Invalid email', { theme: "light" });
        setLoader(false);
        return;
      }
    }
    if (emailcontent.length > 0) {
      var post = emailcontent;
    } else {
      var post =
        "<p><br/><br/><br/>" +
        ppl_people.email_signature +
        "</p><hr/><p><b>From: </b>" +
        res.log_from +
        "<br/><b>Sent: </b>" +
        moment(res.log_date).format("dddd, MMMM DD, YYYY h:mm:ss A") +
        "<br/><b>To: </b>" +
        res.log_to +
        "<br/><b>Subject: </b>" +
        res.log_subject +
        "</p><br/>" +
        res.log_message;
    }

    const formData = new FormData();
    formData.append("lead_id", leadid);
    formData.append("is_reply", tenant_id);
    formData.append("from_email", companyname);
    formData.append("to", toemailrpyrec);
    formData.append("cc", cc);
    formData.append("bcc", bcc);
    formData.append("email_subject", subrpyrec);
    formData.append("email_body", post);
    formData.append("job_id", job.job_id);
    formData.append("email_attachment", JSON.stringify(attnamerpyrec));
    axios
      .post("/api/crm-leads/ajaxSendEmail", formData)
      .then((response) => {
        console.log(response);

        t_editemailreplyreciv();
        onChangeData();
        toast.success(response.message, { theme: "light" });
      })
      .catch((error) => {
        console.error(error);
      }).finally(() => {
        setLoader(false);
      })
  };



  //notes
  const removenotesattachment = (res, index) => {
    console.log(res,);
    const remove = {
      key: res.key,
      type: res.type,
      is_reply: res.note_id,
      name: res.name
    };
    axios
      .post("/api/crm-leads/removeActivityAttachment", remove)
      .then((response) => {
        const removeIndex = attch.findIndex((item) => item.key === response.key);
        if (removeIndex !== -1) {
          // Create a copy of the current state array and remove the file at the found index
          const updatedAttach = [...attch];
          updatedAttach.splice(removeIndex, 1);
          setAttach(updatedAttach);
        }
        toast.success(response.message, { theme: "light" });
      })
      .catch((error) => {
        console.error(error);
      });
  };


  //email
  const removeemailattachment = (res, index) => {
    const remove = {
      key: res.key,
      type: res.type,
      is_reply: res.note_id
    };
    axios
      .post("/api/crm-leads/removeActivityAttachment", remove)
      .then((response) => {
        const removeIndex = attname.findIndex((item) => item.key === response.key);
        if (removeIndex !== -1) {
          // Create a copy of the current state array and remove the file at the found index
          const updatedAttach = [...attname];
          updatedAttach.splice(removeIndex, 1);
          setAttName(updatedAttach);
        }
        toast.success(response.message, { theme: "light" });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  //email forward
  const removeemailattachmentfw = (res, index) => {
    const remove = {
      key: res.key,
      type: res.type,
      is_reply: res.note_id
    };
    axios
      .post("/api/crm-leads/removeActivityAttachment", remove)
      .then((response) => {
        const removeIndex = attnamefw.findIndex((item) => item.key === response.key);
        if (removeIndex !== -1) {
          // Create a copy of the current state array and remove the file at the found index
          const updatedAttach = [...attnamefw];
          updatedAttach.splice(removeIndex, 1);
          setAttNameFw(updatedAttach);
        }
        toast.success(response.message, { theme: "light" });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  //email reply
  const removeemailattachmentrpy = (res, index) => {
    const remove = {
      key: res.key,
      type: res.type,
      is_reply: res.note_id
    };
    axios
      .post("/api/crm-leads/removeActivityAttachment", remove)
      .then((response) => {
        const removeIndex = attnamerpy.findIndex((item) => item.key === response.key);
        if (removeIndex !== -1) {
          // Create a copy of the current state array and remove the file at the found index
          const updatedAttach = [...attnamerpy];
          updatedAttach.splice(removeIndex, 1);
          setAttNamerpy(updatedAttach);
        }
        toast.success(response.message, { theme: "light" });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const removeemailattachmentrpyrec = (res, index) => {
    const remove = {
      key: res.key,
      type: res.type,
      is_reply: res.note_id
    };
    axios
      .post("/api/crm-leads/removeActivityAttachment", remove)
      .then((response) => {
        const removeIndex = attnamerpyrec.findIndex((item) => item.key === response.key);
        if (removeIndex !== -1) {
          // Create a copy of the current state array and remove the file at the found index
          const updatedAttach = [...attnamerpyrec];
          updatedAttach.splice(removeIndex, 1);
          setAttNamerpyrec(updatedAttach);
        }
        toast.success(response.message, { theme: "light" });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  //pinned for notes 
  const chngimg = (id, job_id, lead_id) => {
    const remove = {
      activity_log_id: id,
      job_id: job_id,
      lead_id: lead_id
    };
    axios
      .post("/api/updatePinStatus", remove)
      .then((response) => {
        toast.success(response.message, { theme: "light" });
        onChangeData();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  //pinned for email 
  const chngimgemail = (id, job_id, lead_id) => {
    const remove = {
      activity_log_id: id,
      job_id: job_id,
      lead_id: lead_id
    };
    axios
      .post("/api/updatePinStatus", remove)
      .then((response) => {
        toast.success(response.message, { theme: "light" });
        onChangeData();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  //pinned for email(Email Recieved)
  const chngimgemailreciv = (id, job_id, lead_id) => {
    const remove = {
      activity_log_id: id,
      job_id: job_id,
      lead_id: lead_id
    };
    axios
      .post("/api/updatePinStatus", remove)
      .then((response) => {
        toast.success(response.message, { theme: "light" });
        onChangeData();
      })
      .catch((error) => {
        console.error(error);
      });
  };


  // down arrow icon(collapse) for note
  const [isCollapsed, setIsCollapsed] = useState(true);
  const toggleCollapse = (noteId) => {
    setIsCollapsed((prevState) => ({
      ...prevState,
      [noteId]: !prevState[noteId]
    }));
  };

  // down arrow icon(collapse) for email
  const [isCollapsedemail, setIsCollapsedemail] = useState(true);
  const toggleCollapseemail = (emailId) => {
    setIsCollapsedemail((prevStates) => ({
      ...prevStates,
      [emailId]: !prevStates[emailId]
    }));
  };

  // down arrow icon(collapse) for email recieve
  const [isCollapsedemailreciv, setIsCollapsedemailreciv] = useState(true);
  const toggleCollapseemailreciv = (emailId) => {
    setIsCollapsedemailreciv((prevStatesrec) => ({
      ...prevStatesrec,
      [emailId]: !prevStatesrec[emailId]
    }));
  };

  // down arrow icon(collapse) for inventory created
  const [isCollapsedinv, setIsCollapsedinv] = useState(true);
  const toggleCollapseinv = (emailId) => {
    setIsCollapsedinv((prevStatesinv) => ({
      ...prevStatesinv,
      [emailId]: !prevStatesinv[emailId]
    }));
  };

  // down arrow icon(collapse) for inventory modified
  const [isCollapsedinvm, setIsCollapsedinvm] = useState(true);
  const toggleCollapseinvm = (emailId) => {
    setIsCollapsedinvm((prevStatesinvm) => ({
      ...prevStatesinvm,
      [emailId]: !prevStatesinvm[emailId]
    }));
  };

  // down arrow icon(collapse) for Driver Notes
  const [isCollapsedinvd, setIsCollapsedinvd] = useState(true);
  const toggleCollapseinvd = (emailId) => {
    setIsCollapsedinvd((prevStatesinvd) => ({
      ...prevStatesinvd,
      [emailId]: !prevStatesinvd[emailId]
    }));
  };

  // down arrow icon(collapse) for Email Opened
  const [isCollapsedinve, setIsCollapsedinve] = useState(true);
  const toggleCollapseinve = (emailId) => {
    setIsCollapsedinve((prevStatesinve) => ({
      ...prevStatesinve,
      [emailId]: !prevStatesinve[emailId]
    }));
  };


  const [emailBody, setEmailBody] = useState("");

  const [subject, setsubject] = useState("");
  const handleTemplateChange = (event) => {
    const templateId = event.target.value;

    const template = {
      lead_id: data?.crmlead?.id,
      id: templateId,
      job_id: job.job_id,
      job_type: job.job_type,
      crm_opportunity_id: job.crm_opportunity_id,
    };

    axios
      .post(`/api/crm-leads/getEmailTemplate/${templateId}`, template)
      .then((response) => {
        // Handle the response as needed
        console.log(response);
        setAttName(response.attach_html);
        setPPL_Peoples(response.body);
        setsubject(response.subject);
        setTempid(response.id);
        // Update the email body content
        const updatedEmailTemplates = emailtemplates.map((res) => {
          if (res.id === templateId) {
            return { ...res, email_body: response.data.email_body };
          }
          return res;
        });
        setEmailTemplates(updatedEmailTemplates);
      })
      .catch((error) => {
        console.error(error);
      });

    setSelectedTemplate(templateId);
  };

  const countChar = (event) => {
    const message = event.target.value;
    const len = message.length;
    const credits = len > 0 ? Math.ceil(len / 160) : 0;
    setSmsMessage(message);
    setSmsCredits(credits);
    setSmsTotalWords(len);
  };

  // Collapse with Icon

  const [note, setnote] = useState(false);
  const [email, setemail] = useState(false);
  const [sms, setsms] = useState(false);
  const [inventory, setinventory] = useState(false);

  const col_note = () => {
    setnote(!note);
    setEditedLogSubject("");
  };

  const col_email = () => {
    setCompanyname(data?.company_list[0]?.email);
    // setemail(!email);
    setemail(!email);
  };
  const col_sms = () => {
    setsms(!sms);
  };

  const col_inventory = () => {
    setinventory(!inventory);
  };


  // console.log("iddd:", emailtemplates);
  // console.log(editedLogSubject);
  // console.log(attch, "attch");
  // console.log("notes1", notes);
  // console.log("api.api.API_URL", api.api.API_URL);

  // const divRef = useRef(null);
  // ------------------ckeditor-------------
  const [cbm, setCBM] = useState("");

  useEffect(() => {
    // Parse the emailBody string into a DOM tree
    const parser = new DOMParser();
    const doc = parser.parseFromString(emailBody, 'text/html');

    // Find the <a> tags and update the class attribute
    const anchorTags = doc.querySelectorAll('a');
    anchorTags.forEach((anchor) => {
      // Remove the default class name, if any
      anchor.classList.remove('ck-link_selected');

      // Add the new class name to the <a> tag
      anchor.classList.add('new-class-name');
    });

    // Get the updated HTML content with modified class names
    const updatedEmailBody = doc.documentElement.outerHTML;

    // Set the updated HTML as the data for CKEditor
    setCBM(updatedEmailBody);

    // Log the updated emailBody
  }, [emailBody]);
  // ------------------ckeditor-------------



  useEffect(() => {
    // Check if 'notes' is not empty and find the first 'log_type' with value 3
    const logType3Note = notes?.find((res) => res.log_type === 3);
    if (logType3Note) {
      setSub(`Fw: ${logType3Note.log_subject}`);
    } else {
      setSub('');
    }
  }, [notes]);

  // forward email
  useEffect(() => {
    // Check if 'notes' is not empty and find the first 'log_type' with value 3
    const logType3Note = notes?.find((res) => res.log_type === 3);
    if (logType3Note) {
      setSubrpy(`Re: ${logType3Note.log_subject}`);
    } else {
      setSubrpy('');
    }
  }, [notes]);

  // forward email
  useEffect(() => {
    // Check if 'notes' is not empty and find the first 'log_type' with value 3
    const logType3Note = notes?.find((res) => res.log_type === 3);
    if (logType3Note) {
      setToEmailrpy(logType3Note.log_to);
    } else {
      setToEmailrpy('');
    }
  }, [notes]);
  // reply email recieved(to)
  useEffect(() => {
    // Check if 'notes' is not empty and find the first 'log_type' with value 3
    const logType3Note = notes?.find((res) => res.log_type === 5);
    if (logType3Note) {
      setToEmailrpyrec(logType3Note.log_to);
    } else {
      setToEmailrpyrec('');
    }
  }, [notes]);

  // reply email recieved(subject)
  useEffect(() => {
    // Check if 'notes' is not empty and find the first 'log_type' with value 3
    const logType3Note = notes?.find((res) => res.log_type === 5);
    if (logType3Note) {
      setSubrpyrec(`Re: ${logType3Note.log_subject}`);
    } else {
      setSubrpyrec('');
    }
  }, [notes]);

  //date and time format(logtype == 18,17)
  const formatDate = (logDate) => {
    const date = new Date(logDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const amOrPm = hours >= 12 ? 'PM' : 'AM';
    const formattedTime = `${hours % 12}:${minutes} ${amOrPm}`;

    return `${year}-${month}-${day} ${formattedTime}`;
  };

  const { quill, quillRef } = useQuill();

  useEffect(() => {
    if (quill) {
      quill.root.innerHTML = ppl_peoples;
    }
  }, [quill, ppl_peoples]);

  useEffect(() => {
    if (quill) {
      const handleChange = () => {
        const content = quill.root.innerHTML;
        setPPL_Peoples(content);
        console.log(content, "content123");
      };

      // quill.on('text-change', handleChange);

      quill.root.addEventListener('blur', handleChange);

      return () => {
        // quill.off('text-change', handleChange);
        quill.root.removeEventListener('blur', handleChange);
      };
    }
  }, [quill]);

  return (
    <div className="live-preview">
      <DeleteModal
        show={deleteModal}
        orderId={selectedOrderId}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <ToastContainer closeButton={false} limit={1} theme="light" />
      <Modal
        id="showModal"
        className="modal-dialog-edit"
        isOpen={modalerr}
        centered
      >
        <ModalBody className="py-2 px-3">
          <div className="mt-2 text-center">
            <i className=" bx bx-x-circle" style={{ fontSize: "80px", color: "#F27474" }}></i>
            <div className="   pt-2 fs-15 mx-4 mx-sm-5">
              <h3>Error</h3>
              <p className="text-muted mx-4 mb-0" style={{ marginTop: "20px" }}>
                {errormsg}
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              data-bs-dismiss="modal" onClick={() => setModalErr(false)} style={{ backgroundColor: "#8CD4F5", color: "white", fontWeight: "450", border: "none", fontSize: "17px" }}>
              OK
            </button>
          </div>
        </ModalBody>
      </Modal>
      <Card>
        <CardHeader>
          <div className="hstack gap-3 ">
            <Button
              color="light"
              onClick={col_note}
              style={{ cursor: "pointer", display: "flex", alignItems: "center", borderRadius: "18px" }}
            >
              <i className="bx bxs-pencil" style={{ marginRight: "8px", color: "#912a4e", fontSize: "17px" }}></i> Note
            </Button>
            <Button
              color="light"
              onClick={col_email}
              style={{ cursor: "pointer", display: "flex", alignItems: "center", borderRadius: "18px" }}
            >
              <i className="bx bxs-envelope" style={{ marginRight: "8px", color: "#912a4e", fontSize: "17px" }}></i> Email
            </Button>

            <Button
              color="light"
              onClick={col_sms}
              style={{ cursor: "pointer", display: "flex", alignItems: "center", borderRadius: "18px" }}
            >
              <i className="bx bx-message-square-dots" style={{ marginRight: "8px", color: "#912a4e", fontSize: "17px" }}></i> SMS
            </Button>
          </div>
        </CardHeader>
      </Card>
      {isLoader && <><div
        style={{
          position: 'absolute',
          // top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(255, 255, 255, 0.8)',
          // display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 9999,
        }}
      >
        <Loader />
      </div></>}
      <div className="timeline-2">
        <div className="timeline-continue">
          <Row className="timeline-right mb-3">
            <Col xs={12}>
              <Collapse
                isOpen={note}
                id="collapseWithicon"
              // className="timeline-date"
              >
                <div style={{ marginLeft: "24px", marginTop: "17px", backgroundColor: "#912a4e", display: "inline-flex", alignItems: "center", justifyContent: "center", width: "33px", height: "30px", borderRadius: "50%", lineHeight: "0", zIndex: "999", position: "absolute" }}>
                  <i className="bx bxs-pencil timelineicon" style={{ color: "white", fontSize: "14px" }}></i>
                </div>
                {notes && notes.length > 0 && (
                  <div className="card mb-0" style={{ marginLeft: "65px" }} key={notes[0].id}>
                    {/* <CardHeader className="align-items-center d-flex">
                      <h4 className="card-title mb-0">
                        Notes
                      </h4>
                    </CardHeader> */}
                    <CardBody>
                      <Form method="post">
                        <CKEditor
                          editor={ClassicEditor}
                          data={editedLogSubject}
                          onReady={(editor) => {
                            // You can store the "editor" and use it when needed.
                          }}
                          onChange={(event, editor) => {
                            const updatedLogSubject = editor.getData();
                            setEditedLogSubject(updatedLogSubject);
                          }}
                        />
                      </Form>


                      {attch.length > 0 ?
                        <div className="hstack gap-4 justify-content-start mt-3" style={{ flexWrap: 'wrap' }}>
                          {attch.map((res, index) => (
                            <div key={res.key}>
                              <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
                                {res && (
                                  <p key={index}>
                                    <a href={api.api.API_URL + res.path.substring(res.path.lastIndexOf("public/") + 2)} target="_blank">
                                      {res.name}
                                    </a>
                                  </p>
                                )}
                                <a
                                  className="ri-close-circle-fill"
                                  onClick={() => removenotesattachment(res, index)}
                                ></a><br></br>
                              </div>
                            </div>
                          ))}
                        </div>
                        : <p></p>}
                      <div className="hstack gap-3 justify-content-end mt-3">
                        <i
                          className="bx bx-paperclip fs-3"
                          onClick={() => setShowModal(true)}
                        ></i>
                        <i
                          className="bx bx-x-circle fs-3"
                          onClick={col_note}
                        ></i>
                        <button
                          type="submit"
                          className="btn btn-brown"
                          onClick={handleAddNotes}
                        >
                          {" "}
                          Done <i className="bx bx-check fs-5" style={{ verticalAlign: "middle" }}></i>
                        </button>
                        <div>
                          <Modal
                            id="showModal"
                            className="modal-dialog modal-lg"
                            isOpen={showModal}
                            toggle={() => setShowModal(false)}
                          >
                            <ModalBody className="">
                              <div className="mb-5">
                                <Label
                                  htmlFor="readonlyInput"
                                  className="form-label"
                                >
                                  {" "}
                                  Upload Your File
                                </Label>
                                <div>
                                  <div
                                    className="dropzone dz-clickable"
                                    onClick={handleClick}
                                    onDrop={handleDrop}
                                    onDragOver={handleDragOver}
                                  >
                                    <div className="dz-message needsclick">
                                      <div className="mb-3">
                                        <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                                      </div>
                                      <h4>
                                        Choose File or Drag it here...
                                      </h4>
                                      {file ? (
                                        <p style={{ fontSize: "12px" }}>{file.name}</p>
                                      ) : (
                                        <p style={{ fontSize: "12px" }}>No file choosen</p>
                                      )}

                                    </div>
                                  </div>
                                  <input
                                    type="file"
                                    onChange={handleFileChange}
                                    ref={fileInputRef}
                                    style={{ display: "none" }}
                                  />
                                </div>
                              </div>
                              <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px", marginTop: "10px" }}>
                                <Button className="btn btn-success" onClick={fileuploadnotes} disabled={!isNote}>
                                  Upload
                                </Button>
                                <div>
                                  <Button className="btn btn-light" onClick={() => { setShowModal(false); }}>
                                    Cancel
                                  </Button>
                                </div>
                              </div>
                            </ModalBody>
                          </Modal>
                        </div>
                      </div>

                    </CardBody>
                  </div>
                )}
              </Collapse>
            </Col>
          </Row>
          <Row className="timeline-right mb-3">
            <Col xs={12}>
              <Collapse
                isOpen={sms}
                id="collapseWithicon2"
              // className="timeline-date"
              >
                <div style={{ marginLeft: "24px", marginTop: "17px", backgroundColor: "#912a4e", display: "inline-flex", alignItems: "center", justifyContent: "center", width: "33px", height: "30px", borderRadius: "50%", lineHeight: "0", zIndex: "999", position: "absolute" }}>
                  <i className="bx bx-message-square-dots" style={{ color: "white", fontSize: "14px" }}></i>
                </div>
                <div className="card mb-0" style={{ marginLeft: "65px" }}>
                  <CardBody>
                    <Row>
                      <Col lg={6}>
                        <Label>SMS From</Label>
                        <Input
                          disabled
                          // placeholder="92394324"
                          value={smsfrom}
                          style={{ backgroundColor: "white" }}
                        ></Input>
                      </Col>
                      <Col lg={6}>
                        <Label>SMS To</Label>
                        <div className="col-md-6">
                          <select
                            id="act_sms_send_to"
                            name="sms_to"
                            className="form-control"
                            value={smsto}
                            onChange={(e) => SetSmsto(e.target.value)}
                          >
                            <option>SMS To</option>
                            {sms_contacts?.map((contact) => (
                              <option
                                key={contact.detail}
                                value={contact.detail}
                              >
                                {contact.name} - {contact.detail}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col lg={6} style={{ marginTop: "10px" }}>
                        <select
                          className="form-control"
                          value={templateid}
                          onChange={(event) => chooseTemplate(event)}
                        >
                          <option>choose a template</option>
                          {smstemplates?.map((template) => (
                            <option key={template.id} value={template.id}>
                              {template.sms_template_name}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col lg={12} style={{ marginTop: "10px" }}>
                        <div className="form-group">
                          <textarea
                            id="sms_message"
                            name="sms_message"
                            rows="3"
                            cols="3"
                            className="form-control"
                            value={smsMessage}
                            onChange={(event) => countChar(event)}
                          ></textarea>

                          <note className="pull-left">
                            Cost = <span id="sms_costspan">{smsCredits}</span>{" "}
                            credit
                          </note>

                          <note
                            className="pull-right"
                            style={{
                              position: "absolute",
                              top: 0,
                              right: "0.7vw",
                              marginTop: "76px"
                            }}
                          >
                            <i>(160 characters will cost 1 credit)</i>{" "}
                            <span id="sms_totalWords">{smsTotalWords}</span>{" "}
                            characters
                          </note>
                          <input
                            type="hidden"
                            name="sms_total_credits"
                            id="sms_total_credits"
                            value={smsCredits}
                          />
                        </div>
                      </Col>
                      <div className="hstack gap-3 justify-content-end mt-3">
                        {/* <i className="bx bx-paperclip fs-3"></i> */}
                        <i className="bx bx-x-circle fs-3" onClick={col_sms}
                        ></i>
                        <button
                          type="submit"
                          className="btn btn-brown"
                          onClick={() => sendsms()}
                        >
                          Send <i className="bx bxl-telegram"></i>
                        </button>
                      </div>
                    </Row>
                  </CardBody>
                </div>
              </Collapse>
            </Col>
          </Row>
          <Row className="timeline-right mb-3">
            <Col xs={12}>
              <Collapse
                isOpen={email}
                id="collapseWithicon2"
              // className="timeline-date"
              >
                <div style={{ marginLeft: "24px", marginTop: "17px", backgroundColor: "#912a4e", display: "inline-flex", alignItems: "center", justifyContent: "center", width: "33px", height: "30px", borderRadius: "50%", lineHeight: "0", zIndex: "999", position: "absolute" }}>
                  <i className="bx bxs-envelope timelineicon" style={{ color: "white", fontSize: "14px" }}></i>
                </div>
                <div className="card mb-0" style={{ marginLeft: "65px" }}>
                  <CardBody>
                    <Row>
                      <Col lg={6}>
                        <div className="input-group">
                          <span
                            className="input-group-text"
                            id="inputGroup-sizing-default"
                          >
                            From
                          </span>
                          <select
                            id="act_email_from"
                            name="from_email"
                            className="form-control form-control-lg"
                            style={{ fontSize: "14px" }}
                            value={companyname}
                            onChange={(e) => setCompanyname(e.target.value)}
                          >
                            {data?.company_list?.map((company) => (
                              <option
                                key={company.id}
                                value={company.email}
                                data-name={company.contact_name}
                              >
                                {company.email}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="input-group">
                          <span
                            className="input-group-text"
                            id="inputGroup-sizing-default"
                          >
                            To
                          </span>
                          <Input
                            type="text"
                            className="form-control"
                            aria-label="Sizing example input"
                            aria-describedby="inputGroup-sizing-default"
                            value={toemail}
                            onChange={(e) => setToEmail(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="d-flex gap-2 flex-wrap mb-0 mt-3">
                      <Button
                        onClick={t_coll3}
                        color="primary"
                        style={{ cursor: "pointer" }}
                      >
                        Add CC{" "}
                      </Button>
                      <Button
                        onClick={t_coll4}
                        color="primary"
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        Add BCC{" "}
                      </Button>
                    </div>
                    <Row className="mb-3 mt-3">
                      <Col lg={6}>
                        <Collapse isOpen={coll3} id="multiCollapseExample1">
                          <div className="input-group">
                            <span
                              className="input-group-text"
                              id="inputGroup-sizing-default"
                            >
                              CC
                            </span>
                            <Input
                              type="text"
                              className="form-control"
                              aria-label="Sizing example input"
                              aria-describedby="inputGroup-sizing-default"
                              value={cc}
                              onChange={(e) => setcc(e.target.value)}
                            />
                          </div>
                        </Collapse>
                      </Col>
                      <Col lg={6}>
                        <Collapse isOpen={coll4} id="multiCollapseExample2">
                          <div className="input-group">
                            <span
                              className="input-group-text"
                              id="inputGroup-sizing-default"
                            >
                              BCC
                            </span>
                            <Input
                              type="text"
                              className="form-control"
                              aria-label="Sizing example input"
                              aria-describedby="inputGroup-sizing-default"
                              value={bcc}
                              onChange={(e) => setbcc(e.target.value)}
                            />
                          </div>
                        </Collapse>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col lg={6}>
                        {/* <Input value={subject} /> */}
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Subject"
                          value={subject}
                          onChange={(e) =>
                            setsubject(e.target.value)}
                        />
                      </Col>
                      <Col lg={6}>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          onChange={handleTemplateChange}
                        >
                          <option defaultValue="1">Choose a Template</option>
                          {emailtemplates &&
                            emailtemplates.map((res) => (
                              <option key={res.id} value={res.id}>
                                {res.email_template_name}
                              </option>
                            ))}
                        </select>
                      </Col>
                    </Row>

                    <Form method="post" className="custom_ck">
                      <div className="snow-editor" style={{ height: 315 }}>
                        <div ref={quillRef} />
                      </div>
                      {/* <CKEditor
                          editor={ClassicEditor}
                          data={ppl_peoples}
                          onReady={(editor) => { }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setPPL_Peoples(data)
                            console.log(data);
                          }}
                        /> */}

                    </Form>
                    {attname.length > 0 ?
                      <div className="hstack gap-4 justify-content-start mt-0 " style={{ flexWrap: 'wrap' }}>
                        {attname.map((res, index) => (
                          <div key={res.key}>
                            <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
                              {res && (
                                <p key={index}>
                                  <a href={api.api.API_URL + res.path.substring(res.path.lastIndexOf("public/") + 2)} target="_blank">
                                    {res.name}
                                  </a>
                                </p>
                              )}
                              <a
                                className="ri-close-circle-fill"
                                onClick={() => removeemailattachment(res, index)}
                              ></a>
                              {/* <a className="ri-close-circle-fill" onClick={() => console.log('Clicked!')}></a> */}

                            </div>
                          </div>
                        ))}
                      </div>
                      : <p></p>}
                    <div className="hstack gap-3 justify-content-end mt-3">
                      <i
                        className="bx bx-paperclip fs-3"
                        onClick={() => setShowModalemail(true)}
                      ></i>
                      <i className="bx bx-x-circle fs-3" onClick={col_email}
                      ></i>
                      <button
                        type="submit"
                        className="btn btn-brown"
                        onClick={sendEmail}
                      >
                        Send Email <i className="bx bxl-telegram"></i>
                      </button>
                      <div>
                        <Modal
                          id="showModal"
                          className="modal-dialog modal-lg"
                          isOpen={showmodelemail}
                          toggle={() => setShowModalemail(false)}
                        >
                          <ModalBody className="">
                            <div className="mb-5">
                              <Label
                                htmlFor="readonlyInput"
                                className="form-label"
                              >
                                {" "}
                                Upload Your File
                              </Label>
                              <div>
                                <div
                                  className="dropzone dz-clickable"
                                  onClick={handleClicks}
                                  onDrop={handleDropemail}
                                  onDragOver={handleDragOveremail}
                                >
                                  <div className="dz-message needsclick">
                                    <div className="mb-3">
                                      <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                                    </div>
                                    <h4>
                                      Choose File or Drag it here...
                                    </h4>
                                    {fileemail ? (
                                      <p style={{ fontSize: "12px" }}>{fileemail.name}</p>
                                    ) : (
                                      <p style={{ fontSize: "12px" }}>No file choosen</p>
                                    )}

                                  </div>
                                </div>
                                <input
                                  type="file"
                                  onChange={handleFileChangesemail}
                                  ref={fileInputRefemail}
                                  style={{ display: "none" }}
                                />
                              </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px", marginTop: "10px" }}>
                              <Button className="btn btn-success" onClick={fileuploademail} disabled={!isEmail}>
                                Upload
                              </Button>
                              <div>
                                <Button className="btn btn-light" onClick={() => { setShowModalemail(false); }}>
                                  Cancel
                                </Button>
                              </div>
                            </div>
                          </ModalBody>
                        </Modal>
                      </div>
                    </div>
                  </CardBody>
                </div>
              </Collapse>
            </Col>
          </Row>
          {notes &&
            notes.map((res) => {
              return (
                <>
                  {res.log_type === 7 && (
                    <AccordionItem key={res.id}>
                      <div className="timeline-right mb-3">
                        <Row className="timeline-right mb-3">
                          <Col xs={12}>
                            <div className="timeline-row">
                              <div
                                style={{
                                  marginLeft: "24px",
                                  backgroundColor: "#912a4e",
                                  display: "inline-flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "33px",
                                  height: "30px",
                                  borderRadius: "50%",
                                  lineHeight: "0",
                                  zIndex: "999",
                                  position: "absolute",
                                }}
                              >
                                <i
                                  className="bx bxs-pencil timelineicon"
                                  style={{ color: "white", fontSize: "14px" }}
                                ></i>
                              </div>
                              <div className="card mb-0" style={{ marginLeft: "65px" }}>
                                <CardHeader className="align-items-center d-flex justify-content-between">
                                  <div>
                                    <h4 className="card-title mb-0">Notes</h4>
                                  </div>
                                  <div>
                                    {res.pinned === 0 ? (
                                      <i
                                        className="bx bx-pin cursor-pointer"
                                        style={{ fontSize: "20px" }}
                                        title="Pin to Top"
                                        onClick={() => {
                                          chngimg(res.id, res.job_id, res.lead_id);
                                        }}
                                      ></i>
                                    ) : (
                                      <i
                                        className="bx bxs-pin cursor-pointer"
                                        style={{ fontSize: "20px" }}
                                        title="Unpin"
                                        onClick={() => {
                                          chngimg(res.id, res.job_id, res.lead_id);
                                        }}
                                      ></i>
                                    )}
                                    <i
                                      className={`bx ${isCollapsed[res.id] ? "bx-chevron-up" : "bx-chevron-down"} cursor-pointer`}
                                      style={{ fontSize: "23px" }}
                                      onClick={() => toggleCollapse(res.id)}
                                    ></i>
                                  </div>
                                </CardHeader>
                                <Collapse isOpen={!isCollapsed[res.id]} className="accordion-collapse">
                                  <CardBody>
                                    {res.id !== edit_notes && (
                                      <div className="pb-3">
                                        <p dangerouslySetInnerHTML={{ __html: res.log_message }}></p>
                                        <br />
                                        {res?.attachments?.map((attachment, index) => (
                                          <p key={index}>
                                            <a href={api.api.API_URL + attachment.attachment_content.substring(attachment.attachment_content.lastIndexOf("public/") + 7)} target="_blank">
                                              {attachment.attachment_type}
                                            </a>
                                          </p>
                                        ))}
                                        <p className="mt-4 text-muted">
                                          Note Written {moment(res.log_date).fromNow()} by {ppl_people.first_name} {ppl_people.last_name} on {moment(res.log_date).format("YYYY-MM-DD hh:mm A")} {res.notes_attachment}
                                        </p>
                                        <Button className="btn btn-brown" onClick={() => t_editnotes(res.id)}>
                                          Edit &nbsp;
                                          <i className="bx bx-pencil fs-5" style={{ verticalAlign: "middle" }}></i>
                                        </Button>
                                      </div>
                                    )}
                                    {res.id === edit_notes && (
                                      <div>
                                        <Form method="post">
                                          <CKEditor
                                            editor={ClassicEditor}
                                            data={res.log_message}
                                            onReady={(editor) => { }}
                                            onChange={(event, editor) => {
                                              const data = editor.getData();
                                              setEditedLogSubject(data);
                                            }}
                                          />
                                        </Form>
                                        <div className="hstack gap-3 justify-content-end mt-3">
                                          <i
                                            className="bx bx-trash fs-3 cursor-pointer"
                                            onClick={() => destroynotes(res.id)}
                                          ></i>
                                          <button
                                            type="submit"
                                            className="btn btn-light"
                                            onClick={() => t_editnotes()}
                                          >
                                            Cancel
                                          </button>
                                          <button
                                            type="button"
                                            className="btn btn-brown"
                                            onClick={() => updateedit(editedLogSubject, res.id)}
                                          >
                                            Update{" "}
                                            <i className="bx bx-check fs-5" style={{ verticalAlign: "middle" }}></i>
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </CardBody>
                                </Collapse>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </AccordionItem>
                  )}


                  {res.log_type == 8 && (
                    <Row className="timeline-right mb-3" key={res.id}>
                      <Col xs={12}>
                        <div className="timeline-row">
                          {/* <Collapse
                          isOpen={sms}
                          id="collapseWithicon2"
                          className="timeline-date"
                        > */}
                          <div style={{ marginLeft: "24px", backgroundColor: "#912a4e", display: "inline-flex", alignItems: "center", justifyContent: "center", width: "33px", height: "30px", borderRadius: "50%", lineHeight: "0", zIndex: "999", position: "absolute" }}>
                            <i className="bx bx-message-square-dots timelineicon" style={{ color: "white", fontSize: "17px" }}></i>
                          </div>
                          <div className="card mb-0" style={{ marginLeft: "65px" }}>
                            <CardBody>
                              <Row>
                                <Col lg={6}>
                                  <h4>SMS</h4>
                                </Col>
                              </Row>
                              <Row>
                                <Col className="pl-1">
                                  <p>From: {res.log_from}</p>
                                  <p>To: {res.log_to}</p>
                                  <p className="pt-3">{res.log_message}</p>
                                  <span className="pt-5 text-muted">
                                    SMS Send {moment(res.log_date).fromNow()}
                                  </span>
                                </Col>
                              </Row>
                            </CardBody>
                          </div>
                        </div>
                        {/* </Collapse> */}
                      </Col>
                    </Row>
                  )}
                  {res.log_type === 3 && (
                    <AccordionItem key={res.id}>
                      <div className="timeline-right mb-3">
                        <Row className="timeline-right mb-3">
                          <Col xs={12}>
                            <div className="timeline-row">
                              {/* <Collapse
                                isOpen={email}
                                id="collapseWithicon2"
                                className="timeline-date"
                              > */}
                              <div style={{ marginLeft: "24px", backgroundColor: "#912a4e", display: "inline-flex", alignItems: "center", justifyContent: "center", width: "33px", height: "30px", borderRadius: "50%", lineHeight: "0", zIndex: "999", position: "absolute" }}>
                                <i className="bx bxs-envelope timelineicon" style={{ color: "white", fontSize: "14px" }}></i>
                              </div>
                              <div className="card mb-0" style={{ marginLeft: "65px" }}>
                                <CardHeader className="align-items-center d-flex justify-content-between">
                                  <div>
                                    <h5>{res.log_subject}</h5>
                                  </div>
                                  <div>
                                    <i
                                      className="ri-reply-fill"
                                      style={{ fontSize: "20px", marginRight: "10px" }}
                                      title="Reply"
                                      onClick={() => t_editemailreply(res.id)}
                                    ></i>
                                    <i
                                      className="ri-share-forward-fill"
                                      style={{ fontSize: "20px", marginRight: "10px" }}
                                      title="Forward"
                                      onClick={() => t_editemail(res.id)}
                                    ></i>
                                    {res.pinned === 0 ? (
                                      <i
                                        className="bx bx-pin cursor-pointer"
                                        style={{ fontSize: "20px" }}
                                        title="Pin to Top"
                                        onClick={() => {
                                          chngimgemail(res.id, res.job_id, res.lead_id);
                                        }}
                                      ></i>
                                    ) : (
                                      <i
                                        className="bx bxs-pin cursor-pointer"
                                        style={{ fontSize: "20px" }}
                                        title="Unpin"
                                        onClick={() => {
                                          chngimgemail(res.id, res.job_id, res.lead_id);
                                        }}
                                      ></i>
                                    )}
                                    <i
                                      className={`bx ${isCollapsedemail[res.id] ? "bx-chevron-up" : "bx-chevron-down"} cursor-pointer`}
                                      style={{ fontSize: "23px" }}
                                      title="Collapse"
                                      onClick={() => toggleCollapseemail(res.id)}
                                    ></i>


                                  </div>
                                </CardHeader>
                                <Collapse isOpen={!isCollapsedemail[res.id]} className="accordion-collapse">
                                  <CardBody>

                                    {res.id != edit_email && res.id != edit_emaill && (
                                      <div className="pb-3">

                                        <p>
                                          <b>From:</b> {res.log_from} <br />
                                          <b>To:</b> {res.log_to} <br />
                                          {res.log_cc && (
                                            <span>
                                              <b>CC:</b> {res.log_cc} <br />
                                            </span>
                                          )}
                                          {res.log_bcc && (
                                            <span>
                                              <b>BCC:</b> {res.log_bcc} <br />
                                            </span>
                                          )}
                                        </p>
                                        <br></br>
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: res.log_message,
                                          }}
                                        ></p>
                                        <br />
                                        {res?.attachments?.map((attachment, index) => (
                                          <p key={index}>
                                            <a href={api.api.API_URL + attachment.attachment_content.substring(attachment.attachment_content.lastIndexOf("public/") + 7)} target="_blank">
                                              {attachment.attachment_type}
                                            </a>
                                          </p>
                                        ))}
                                        <p className="mt-4 text-muted"><i>
                                          Email Sent {moment(res.log_date).fromNow()} by {ppl_people.first_name} {ppl_people.last_name} on {moment(res.log_date).format("YYYY-MM-DD hh:mm A")}
                                        </i></p>
                                        {/* <p className="mt-4 text-muted">
                                          Email Written{" "}
                                          {moment(
                                            ppl_people.email_signature
                                          ).fromNow()}{" "}
                                          from:{res.log_from} to:{res.log_to} on{" "}
                                          {moment(res.log_date).format(
                                            "YYYY-MM-DD hh:mm A"
                                          )}
                                          subject:{res.log_subject}cc:{res.log_cc}{" "}
                                          Bcc:{res.log_bcc}
                                        </p> */}
                                        {/* <Button
                                              className="btn btn-brown"
                                              onClick={() => t_editemail(res.id)}
                                            >
                                              <i className="ri-share-forward-fill"></i>
                                            </Button> */}
                                      </div>
                                    )}
                                    {res.id == edit_email && (
                                      <div>
                                        <Row>
                                          <Col lg={6}>
                                            <div className="input-group">
                                              <span
                                                className="input-group-text"
                                                id="inputGroup-sizing-default"
                                              >
                                                From
                                              </span>
                                              <select
                                                id="act_email_from"
                                                name="from_email"
                                                className="form-control form-control-lg"
                                                value={companyname}
                                                onChange={(e) => setCompanyname(e.target.value)}
                                              >
                                                {data?.company_list?.map((company) => (
                                                  <option
                                                    key={company.id}
                                                    value={company.email}
                                                    data-name={company.contact_name}
                                                  >
                                                    {company.email}
                                                  </option>
                                                ))}
                                              </select>
                                            </div>
                                          </Col>
                                          <Col lg={6}>
                                            <div className="input-group">
                                              <span
                                                className="input-group-text"
                                                id="inputGroup-sizing-default"
                                              >
                                                To
                                              </span>
                                              <Input
                                                type="text"
                                                className="form-control"
                                                aria-label="Sizing example input"
                                                value={toemailfw}
                                                aria-describedby="inputGroup-sizing-default"
                                                // placeholder="onexfort.teststar22@gmail.com"
                                                onChange={(e) =>
                                                  setToEmailfw(e.target.value)
                                                }
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                        <div className="d-flex gap-2 flex-wrap mb-0 mt-3">
                                          <Button
                                            onClick={t_coll3}
                                            color="primary"
                                            style={{ cursor: "pointer" }}
                                          >
                                            Add CC{" "}
                                          </Button>
                                          <Button
                                            onClick={t_coll4}
                                            color="primary"
                                            style={{ cursor: "pointer" }}
                                          >
                                            {" "}
                                            Add BCC{" "}
                                          </Button>
                                        </div>
                                        <Row className="mb-3 mt-3">
                                          <Col lg={6}>
                                            <Collapse
                                              isOpen={coll3}
                                              id="multiCollapseExample1"
                                            >
                                              <div className="input-group">
                                                <span
                                                  className="input-group-text"
                                                  id="inputGroup-sizing-default"
                                                >
                                                  CC
                                                </span>
                                                <Input
                                                  type="text"
                                                  className="form-control"
                                                  aria-label="Sizing example input"
                                                  value={cc}
                                                  aria-describedby="inputGroup-sizing-default"
                                                  onChange={(e) =>
                                                    setcc(e.target.value)
                                                  }
                                                />
                                              </div>
                                            </Collapse>
                                          </Col>
                                          <Col lg={6}>
                                            <Collapse
                                              isOpen={coll4}
                                              id="multiCollapseExample2"
                                            >
                                              <div className="input-group">
                                                <span
                                                  className="input-group-text"
                                                  id="inputGroup-sizing-default"
                                                >
                                                  BCC
                                                </span>
                                                <Input
                                                  type="text"
                                                  className="form-control"
                                                  aria-label="Sizing example input"
                                                  value={bcc}
                                                  aria-describedby="inputGroup-sizing-default"
                                                  onChange={(e) =>
                                                    setbcc(e.target.value)
                                                  }
                                                />
                                              </div>
                                            </Collapse>
                                          </Col>
                                        </Row>
                                        <Row className="mb-3">
                                          <Col lg={6}>
                                            <Input
                                              type="text"
                                              className="form-control"
                                              placeholder="Subject"
                                              value={sub}
                                              onChange={(e) =>
                                                setSub(e.target.value)}
                                            />
                                          </Col>
                                        </Row>
                                        <Form method="post">
                                          <CKEditor
                                            editor={ClassicEditor}
                                            data={
                                              "<p><br/><br/><br/>" +
                                              ppl_people.email_signature +
                                              "</p><hr/><p><b>From: </b>" +
                                              res.log_from +
                                              "<br/><b>Sent: </b>" +
                                              moment(res.log_date).format(
                                                "dddd, MMMM DD, YYYY h:mm:ss A"
                                              ) +
                                              "<br/><b>To: </b>" +
                                              res.log_to +
                                              "<br/><b>Subject: </b>" +
                                              res.log_subject +
                                              "</p><br/>" +
                                              res.log_message

                                            }
                                            onReady={(editor) => { }}
                                            onChange={(event, editor) => {
                                              const data = editor.getData();
                                              console.log(data);
                                              setemailcontent(data);
                                            }}
                                          />
                                        </Form>
                                        {attnamefw.length > 0 ?
                                          <div className="hstack gap-3 justify-content-start mt-3">
                                            {attnamefw.map((res, index) => (
                                              <div key={res.key}>
                                                <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
                                                  {res && (
                                                    <p key={index}>
                                                      <a href={api.api.API_URL + res.path.substring(res.path.lastIndexOf("public/") + 2)} target="_blank">
                                                        {res.name}
                                                      </a>
                                                    </p>
                                                  )}
                                                  <a
                                                    className="ri-close-circle-fill"
                                                    onClick={() => removeemailattachmentfw(res, index)}
                                                  ></a>
                                                  {/* <a className="ri-close-circle-fill" onClick={() => console.log('Clicked!')}></a> */}

                                                </div>
                                              </div>
                                            ))}
                                          </div>
                                          : <p></p>}
                                        <div className="hstack gap-3 justify-content-end mt-3">
                                          <i
                                            className="bx bx-paperclip fs-3"
                                            onClick={() => setShowModalemailFw(true)}
                                          ></i>
                                          <i className="bx bx-x-circle fs-3" onClick={() => t_editemail(false)} ></i>
                                          <button
                                            type="submit"
                                            className="btn btn-brown"
                                            onClick={() => forwardemail(res)}
                                          >
                                            Send Forward<i className="bx bxl-telegram"></i>
                                          </button>
                                          <div>
                                            <Modal
                                              id="showModal"
                                              className="modal-dialog modal-lg"
                                              isOpen={showmodelemailfw}
                                              toggle={() => setShowModalemailFw(false)}
                                            >
                                              <ModalBody className="">
                                                <div className="mb-5">
                                                  <Label
                                                    htmlFor="readonlyInput"
                                                    className="form-label"
                                                  >
                                                    {" "}
                                                    Upload Your File
                                                  </Label>
                                                  <div>
                                                    <div
                                                      className="dropzone dz-clickable"
                                                      onClick={handleClicksfw}
                                                      onDrop={handleDropemailfw}
                                                      onDragOver={handleDragOveremailfw}
                                                    >
                                                      <div className="dz-message needsclick">
                                                        <div className="mb-3">
                                                          <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                                                        </div>
                                                        <h4>
                                                          Choose File or Drag it here...
                                                        </h4>
                                                        {fileemailfw ? (
                                                          <p style={{ fontSize: "12px" }}>{fileemailfw.name}</p>
                                                        ) : (
                                                          <p style={{ fontSize: "12px" }}>No file choosen</p>
                                                        )}

                                                      </div>
                                                    </div>
                                                    <input
                                                      type="file"
                                                      onChange={handleFileChangesemailfw}
                                                      ref={fileInputRefemailfw}
                                                      style={{ display: "none" }}
                                                    />
                                                  </div>
                                                </div>
                                                <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px", marginTop: "10px" }}>
                                                  <Button className="btn btn-success" onClick={fileuploademailfw}>
                                                    Upload
                                                  </Button>
                                                  <div>
                                                    <Button className="btn btn-light" onClick={() => { setShowModalemailFw(false); }}>
                                                      Cancel
                                                    </Button>
                                                  </div>
                                                </div>
                                              </ModalBody>
                                            </Modal>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {/* reply */}
                                    {res.id == edit_emaill && (
                                      <div>
                                        <Row>
                                          <Col lg={6}>
                                            <div className="input-group">
                                              <span
                                                className="input-group-text"
                                                id="inputGroup-sizing-default"
                                              >
                                                From
                                              </span>
                                              <select
                                                id="act_email_from"
                                                name="from_email"
                                                className="form-control form-control-lg"
                                                value={companyname}
                                                onChange={(e) => setCompanyname(e.target.value)}
                                              >
                                                {data?.company_list?.map((company) => (
                                                  <option
                                                    key={company.id}
                                                    value={company.email}
                                                    data-name={company.contact_name}
                                                  >
                                                    {company.email}
                                                  </option>
                                                ))}
                                              </select>
                                            </div>
                                          </Col>
                                          <Col lg={6}>
                                            <div className="input-group">
                                              <span
                                                className="input-group-text"
                                                id="inputGroup-sizing-default"
                                              >
                                                To
                                              </span>
                                              <Input
                                                type="text"
                                                className="form-control"
                                                aria-label="Sizing example input"
                                                value={toemailrpy}
                                                aria-describedby="inputGroup-sizing-default"
                                                placeholder="onexfort.teststar22@gmail.com"
                                                onChange={(e) =>
                                                  setToEmailrpy(e.target.value)
                                                }
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                        <div className="d-flex gap-2 flex-wrap mb-0 mt-3">
                                          <Button
                                            onClick={t_coll3}
                                            color="primary"
                                            style={{ cursor: "pointer" }}
                                          >
                                            Add CC{" "}
                                          </Button>
                                          <Button
                                            onClick={t_coll4}
                                            color="primary"
                                            style={{ cursor: "pointer" }}
                                          >
                                            {" "}
                                            Add BCC{" "}
                                          </Button>
                                        </div>
                                        <Row className="mb-3 mt-3">
                                          <Col lg={6}>
                                            <Collapse
                                              isOpen={coll3}
                                              id="multiCollapseExample1"
                                            >
                                              <div className="input-group">
                                                <span
                                                  className="input-group-text"
                                                  id="inputGroup-sizing-default"
                                                >
                                                  CC
                                                </span>
                                                <Input
                                                  type="text"
                                                  className="form-control"
                                                  aria-label="Sizing example input"
                                                  value={cc}
                                                  aria-describedby="inputGroup-sizing-default"
                                                  onChange={(e) =>
                                                    setcc(e.target.value)
                                                  }
                                                />
                                              </div>
                                            </Collapse>
                                          </Col>
                                          <Col lg={6}>
                                            <Collapse
                                              isOpen={coll4}
                                              id="multiCollapseExample2"
                                            >
                                              <div className="input-group">
                                                <span
                                                  className="input-group-text"
                                                  id="inputGroup-sizing-default"
                                                >
                                                  BCC
                                                </span>
                                                <Input
                                                  type="text"
                                                  className="form-control"
                                                  aria-label="Sizing example input"
                                                  value={bcc}
                                                  aria-describedby="inputGroup-sizing-default"
                                                  onChange={(e) =>
                                                    setbcc(e.target.value)
                                                  }
                                                />
                                              </div>
                                            </Collapse>
                                          </Col>
                                        </Row>
                                        <Row className="mb-3">
                                          <Col lg={6}>
                                            {/* <Input
                                              placeholder="Subject"
                                              value={"Re: " + res.log_subject}
                                            ></Input> */}
                                            <Input
                                              type="text"
                                              className="form-control"
                                              placeholder="Subject"
                                              value={subrpy}
                                              onChange={(e) =>
                                                setSubrpy(e.target.value)}
                                            />
                                          </Col>
                                        </Row>
                                        <Form method="post">
                                          {console.log(res)}
                                          <CKEditor
                                            editor={ClassicEditor}
                                            data={
                                              "<p><br/><br/><br/>" +
                                              ppl_people.email_signature +
                                              "</p><hr/><p><b>From: </b>" +
                                              res.log_from +
                                              "<br/><b>Sent: </b>" +
                                              moment(res.log_date).format(
                                                "dddd, MMMM DD, YYYY h:mm:ss A"
                                              ) +
                                              "<br/><b>To: </b>" +
                                              res.log_to +
                                              "<br/><b>Subject: </b>" +
                                              res.log_subject +
                                              "</p><br/>" +
                                              res.log_message

                                            }
                                            onReady={(editor) => { }}
                                            onChange={(event, editor) => {
                                              const data = editor.getData();
                                              console.log(data);
                                              setemailcontent(data);
                                            }}
                                          />
                                        </Form>
                                        {attnamerpy.length > 0 ?
                                          <div className="hstack gap-3 justify-content-start mt-3">
                                            {attnamerpy.map((res, index) => (
                                              <div key={res.key}>
                                                <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
                                                  {res && (
                                                    <p key={index}>
                                                      <a href={api.api.API_URL + res.path.substring(res.path.lastIndexOf("public/") + 2)} target="_blank">
                                                        {res.name}
                                                      </a>
                                                    </p>
                                                  )}
                                                  <a
                                                    className="ri-close-circle-fill"
                                                    onClick={() => removeemailattachmentrpy(res, index)}
                                                  ></a>
                                                  {/* <a className="ri-close-circle-fill" onClick={() => console.log('Clicked!')}></a> */}

                                                </div>
                                              </div>
                                            ))}
                                          </div>
                                          : <p></p>}
                                        <div className="hstack gap-3 justify-content-end mt-3">
                                          <i
                                            className="bx bx-paperclip fs-3"
                                            onClick={() => setShowModalemailrpy(true)}
                                          ></i>
                                          <i className="bx bx-x-circle fs-3" onClick={() => t_editemailreply(false)} ></i>
                                          <button
                                            type="submit"
                                            className="btn btn-brown"
                                            onClick={() => replyemail(res)}
                                          >
                                            SendReply <i className="bx bxl-telegram"></i>
                                          </button>
                                          <div>
                                            <Modal
                                              id="showModal"
                                              className="modal-dialog modal-lg"
                                              isOpen={showmodelemailrpy}
                                              toggle={() => setShowModalemailrpy(false)}
                                            >
                                              <ModalBody className="">
                                                <div className="mb-5">
                                                  <Label
                                                    htmlFor="readonlyInput"
                                                    className="form-label"
                                                  >
                                                    {" "}
                                                    Upload Your File
                                                  </Label>
                                                  <div>
                                                    <div
                                                      className="dropzone dz-clickable"
                                                      onClick={handleClicksrpy}
                                                      onDrop={handleDropemailrpy}
                                                      onDragOver={handleDragOveremailrpy}
                                                    >
                                                      <div className="dz-message needsclick">
                                                        <div className="mb-3">
                                                          <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                                                        </div>
                                                        <h4>
                                                          Choose File or Drag it here...
                                                        </h4>
                                                        {fileemailrpy ? (
                                                          <p style={{ fontSize: "12px" }}>{fileemailrpy.name}</p>
                                                        ) : (
                                                          <p style={{ fontSize: "12px" }}>No file choosen</p>
                                                        )}

                                                      </div>
                                                    </div>
                                                    <input
                                                      type="file"
                                                      onChange={handleFileChangesemailrpy}
                                                      ref={fileInputRefemailrpy}
                                                      style={{ display: "none" }}
                                                    />
                                                  </div>
                                                </div>
                                                <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px", marginTop: "10px" }}>
                                                  <Button className="btn btn-success" onClick={fileuploademailrpy} disabled={!isEmailrpy}>
                                                    Uploadasd
                                                  </Button>
                                                  <div>
                                                    <Button className="btn btn-light" onClick={() => { setShowModalemailrpy(false); }}>
                                                      Cancel
                                                    </Button>
                                                  </div>
                                                </div>
                                              </ModalBody>
                                            </Modal>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {/* reply */}
                                  </CardBody>
                                </Collapse>
                              </div>
                            </div>
                            {/* </Collapse> */}
                          </Col>
                        </Row>
                      </div>
                    </AccordionItem >
                  )}

                  {res.log_type === 11 && (
                    <AccordionItem key={res.id}>
                      <div className="timeline-right mb-3">
                        <Row className="timeline-right mb-3">
                          <Col xs={12}>
                            <div className="timeline-row">
                              <div
                                style={{
                                  marginLeft: "24px",
                                  backgroundColor: "#912a4e",
                                  display: "inline-flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "33px",
                                  height: "30px",
                                  borderRadius: "50%",
                                  lineHeight: "0",
                                  zIndex: "999",
                                  position: "absolute",
                                }}
                              >
                                <i
                                  className="bx bxs-bx bx-list-ul timelineicon"
                                  style={{ color: "white", fontSize: "18px" }}
                                ></i>
                              </div>
                              <div className="card mb-0" style={{ marginLeft: "65px" }}>
                                <CardHeader className="align-items-center d-flex justify-content-between">
                                  <div>
                                    <h4 className="card-title mb-0">Inventory List</h4>
                                  </div>
                                  <div>
                                    <i
                                      className={`bx ${isCollapsedinv[res.id] ? "bx-chevron-up" : "bx-chevron-down"} cursor-pointer`}
                                      style={{ fontSize: "23px" }}
                                      onClick={() => toggleCollapseinv(res.id)}
                                    ></i>
                                  </div>
                                </CardHeader>
                                <Collapse isOpen={!isCollapsedinv[res.id]} className="accordion-collapse">
                                  <CardBody>
                                    <Col className="pl-1">
                                      <p>{res.log_message}</p>
                                      <p className="mt-4 text-muted"><i>
                                        {moment(res.log_date).fromNow()} On {formatDate(res.log_date)}
                                      </i></p>
                                    </Col>
                                  </CardBody>
                                </Collapse>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </AccordionItem>
                  )}

                  {res.log_type == 4 && (
                    <AccordionItem key={res.id}>
                      <div className="timeline-right mb-3">
                        <Row className="timeline-right mb-3">
                          <Col xs={12}>
                            <div className="timeline-row">
                              <div style={{ marginLeft: "24px", backgroundColor: "#912a4e", display: "inline-flex", alignItems: "center", justifyContent: "center", width: "33px", height: "30px", borderRadius: "50%", lineHeight: "0", zIndex: "999", position: "absolute" }}>
                                <i className="bx bxs-envelope timelineicon" style={{ color: "white", fontSize: "18px" }}></i>
                              </div>
                              <div className="card mb-0" style={{ marginLeft: "65px" }}>
                                <CardHeader className="align-items-center d-flex justify-content-between">
                                  <div>
                                    <h4 className="card-title mb-0">{res.log_subject}</h4>
                                  </div>
                                  <div>
                                    <i
                                      className={`bx ${isCollapsedinve[res.id] ? "bx-chevron-up" : "bx-chevron-down"} cursor-pointer`}
                                      style={{ fontSize: "23px" }}
                                      onClick={() => toggleCollapseinve(res.id)}
                                    ></i>
                                  </div>
                                </CardHeader>
                                <Collapse isOpen={!isCollapsedinve[res.id]} className="accordion-collapse">
                                  <CardBody>
                                    <Col className="pl-1">
                                      <p>{res.log_message}</p>
                                      <p className="mt-4 text-muted"><i>
                                        Email Opened {moment(res.log_date).fromNow()} On {formatDate(res.log_date)}
                                      </i></p>
                                    </Col>
                                  </CardBody>
                                </Collapse>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </AccordionItem>

                  )}
                  {res.log_type === 5 && (
                    <AccordionItem key={res.id}>
                      <div className="timeline-right mb-3">
                        <Row className="timeline-right mb-3" >
                          <Col xs={12}>
                            <div className="timeline-row">
                              <div style={{ marginLeft: "24px", backgroundColor: "#912a4e", display: "inline-flex", alignItems: "center", justifyContent: "center", width: "33px", height: "30px", borderRadius: "50%", lineHeight: "0", zIndex: "999", position: "absolute" }}>
                                <i className="bx bxs-envelope timelineicon" style={{ color: "white", fontSize: "18px" }}></i>
                              </div>
                              <div className="card mb-0" style={{ marginLeft: "65px" }}>
                                <CardHeader className="align-items-center d-flex justify-content-between">
                                  <div>
                                    <h5>{res.log_subject}</h5>
                                  </div>
                                  <div>
                                    <i
                                      className="ri-reply-fill"
                                      style={{ fontSize: "20px", marginRight: "10px" }}
                                      title="Reply"
                                      onClick={() => t_editemailreplyreciv(res.id)}
                                    ></i>

                                    {res.pinned === 0 ? (
                                      <i
                                        className="bx bx-pin cursor-pointer"
                                        style={{ fontSize: "20px" }}
                                        title="Pin to Top"
                                        onClick={() => {
                                          chngimgemailreciv(res.id, res.job_id, res.lead_id);
                                        }}
                                      ></i>
                                    ) : (
                                      <i
                                        className="bx bxs-pin cursor-pointer"
                                        style={{ fontSize: "20px" }}
                                        title="Unpin"
                                        onClick={() => {
                                          chngimgemailreciv(res.id, res.job_id, res.lead_id);
                                        }}
                                      ></i>
                                    )}
                                    <i
                                      className={`bx ${isCollapsedemailreciv[res.id] ? "bx-chevron-up" : "bx-chevron-down"} cursor-pointer`}
                                      style={{ fontSize: "23px" }}
                                      title="Collapse"
                                      onClick={() => toggleCollapseemailreciv(res.id)}
                                    ></i>
                                  </div>
                                </CardHeader>
                                <Collapse isOpen={!isCollapsedemailreciv[res.id]} className="accordion-collapse">
                                  <CardBody>
                                    {res.id != edit_emailreciv && (
                                      <div className="pb-3">
                                        <p>
                                          <b>From:</b> {res.log_from} <br />
                                          <b>To:</b> {res.log_to} <br />
                                          {res.log_cc && (
                                            <span>
                                              <b>CC:</b> {res.log_cc} <br />
                                            </span>
                                          )}
                                          {res.log_bcc && (
                                            <span>
                                              <b>BCC:</b> {res.log_bcc} <br />
                                            </span>
                                          )}
                                        </p>
                                        <br></br>
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: res.log_message,
                                          }}
                                        ></p>
                                        <br />
                                        {res?.attachments?.map((attachment, index) => (
                                          <p key={index}>
                                            <a href={api.api.API_URL + attachment.attachment_content.substring(attachment.attachment_content.lastIndexOf("public/") + 7)} target="_blank">
                                              {attachment.attachment_type}
                                            </a>
                                          </p>
                                        ))}
                                        <p className="mt-4 text-muted">
                                          <i>
                                            Email Recieved {moment(res.log_date).fromNow()} by {ppl_people.first_name} {ppl_people.last_name} on {formatDate(res.log_date)}
                                          </i>                                        </p>
                                      </div>

                                    )}
                                    {/* reply */}
                                    {res.id == edit_emailreciv && (
                                      <div>
                                        <Row>
                                          <Col lg={6}>
                                            <div className="input-group">
                                              <span
                                                className="input-group-text"
                                                id="inputGroup-sizing-default"
                                              >
                                                From
                                              </span>
                                              <select
                                                id="act_email_from"
                                                name="from_email"
                                                className="form-control form-control-lg"
                                                value={companyname}
                                                onChange={(e) => setCompanyname(e.target.value)}
                                              >
                                                {removal?.map((company) => (
                                                  <option
                                                    key={company.id}
                                                    value={company.email}
                                                    data-name={company.contact_name}
                                                  >
                                                    {company.email}
                                                  </option>
                                                ))}
                                              </select>
                                            </div>
                                          </Col>
                                          <Col lg={6}>
                                            <div className="input-group">
                                              <span
                                                className="input-group-text"
                                                id="inputGroup-sizing-default"
                                              >
                                                To
                                              </span>
                                              <Input
                                                type="text"
                                                className="form-control"
                                                aria-label="Sizing example input"
                                                value={toemailrpyrec}
                                                aria-describedby="inputGroup-sizing-default"
                                                placeholder="onexfort.teststar22@gmail.com"
                                                onChange={(e) =>
                                                  setToEmailrpyrec(e.target.value)
                                                }
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                        <div className="d-flex gap-2 flex-wrap mb-0 mt-3">
                                          <Button
                                            onClick={t_coll3}
                                            color="primary"
                                            style={{ cursor: "pointer" }}
                                          >
                                            Add CC{" "}
                                          </Button>
                                          <Button
                                            onClick={t_coll4}
                                            color="primary"
                                            style={{ cursor: "pointer" }}
                                          >
                                            {" "}
                                            Add BCC{" "}
                                          </Button>
                                        </div>
                                        <Row className="mb-3 mt-3">
                                          <Col lg={6}>
                                            <Collapse
                                              isOpen={coll3}
                                              id="multiCollapseExample1"
                                            >
                                              <div className="input-group">
                                                <span
                                                  className="input-group-text"
                                                  id="inputGroup-sizing-default"
                                                >
                                                  CC
                                                </span>
                                                <Input
                                                  type="text"
                                                  className="form-control"
                                                  aria-label="Sizing example input"
                                                  value={cc}
                                                  aria-describedby="inputGroup-sizing-default"
                                                  onChange={(e) =>
                                                    setcc(e.target.value)
                                                  }
                                                />
                                              </div>
                                            </Collapse>
                                          </Col>
                                          <Col lg={6}>
                                            <Collapse
                                              isOpen={coll4}
                                              id="multiCollapseExample2"
                                            >
                                              <div className="input-group">
                                                <span
                                                  className="input-group-text"
                                                  id="inputGroup-sizing-default"
                                                >
                                                  BCC
                                                </span>
                                                <Input
                                                  type="text"
                                                  className="form-control"
                                                  aria-label="Sizing example input"
                                                  value={bcc}
                                                  aria-describedby="inputGroup-sizing-default"
                                                  onChange={(e) =>
                                                    setbcc(e.target.value)
                                                  }
                                                />
                                              </div>
                                            </Collapse>
                                          </Col>
                                        </Row>
                                        <Row className="mb-3">
                                          <Col lg={6}>
                                            {/* <Input
                                              placeholder="Subject"
                                              value={res.log_subject}
                                            ></Input> */}
                                            <Input
                                              type="text"
                                              className="form-control"
                                              placeholder="Subject"
                                              value={subrpyrec}
                                              onChange={(e) =>
                                                setSubrpyrec(e.target.value)}
                                            />
                                          </Col>
                                        </Row>
                                        <Form method="post">
                                          {console.log(res)}
                                          <CKEditor
                                            editor={ClassicEditor}
                                            data={
                                              "<p><br/><br/><br/>" +
                                              ppl_people.email_signature +
                                              "</p><hr/><p><b>From: </b>" +
                                              res.log_from +
                                              "<br/><b>Sent: </b>" +
                                              moment(res.log_date).format(
                                                "dddd, MMMM DD, YYYY h:mm:ss A"
                                              ) +
                                              "<br/><b>To: </b>" +
                                              res.log_to +
                                              "<br/><b>Subject: </b>" +
                                              res.log_subject +
                                              "</p><br/>" +
                                              res.log_message

                                            }
                                            onReady={(editor) => { }}
                                            onChange={(event, editor) => {
                                              const data = editor.getData();
                                              console.log(data);
                                              setemailcontent(data);
                                            }}
                                          />
                                        </Form>
                                        {attnamerpyrec.length > 0 ?
                                          <div className="hstack gap-3 justify-content-start mt-3">
                                            {attnamerpyrec.map((res, index) => (
                                              <div key={res.key}>
                                                <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
                                                  {res && (
                                                    <p key={index}>
                                                      <a href={api.api.API_URL + res.path.substring(res.path.lastIndexOf("public/") + 2)} target="_blank">
                                                        {res.name}
                                                      </a>
                                                    </p>
                                                  )}
                                                  <a
                                                    className="ri-close-circle-fill"
                                                    onClick={() => removeemailattachmentrpyrec(res, index)}
                                                  ></a>
                                                  {/* <a className="ri-close-circle-fill" onClick={() => console.log('Clicked!')}></a> */}

                                                </div>
                                              </div>
                                            ))}
                                          </div>
                                          : <p></p>}
                                        <div className="hstack gap-3 justify-content-end mt-3">
                                          <i
                                            className="bx bx-paperclip fs-3"
                                            onClick={() => setShowModalemailrpyrec(true)}
                                          ></i>
                                          <i className="bx bx-x-circle fs-3" onClick={() => t_editemailreplyreciv(false)} ></i>
                                          <button
                                            type="submit"
                                            className="btn btn-brown"
                                            onClick={() => replyemailrec(res)}
                                          >
                                            SendReplyReci <i className="bx bxl-telegram"></i>
                                          </button>
                                          <div>
                                            <Modal
                                              id="showModal"
                                              className="modal-dialog modal-lg"
                                              isOpen={showmodelemailrpyrec}
                                              toggle={() => setShowModalemailrpyrec(false)}
                                            >
                                              <ModalBody className="">
                                                <div className="mb-5">
                                                  <Label
                                                    htmlFor="readonlyInput"
                                                    className="form-label"
                                                  >
                                                    {" "}
                                                    Upload Your File
                                                  </Label>
                                                  <div>
                                                    <div
                                                      className="dropzone dz-clickable"
                                                      onClick={handleClicksrpyrec}
                                                      onDrop={handleDropemailrpyrec}
                                                      onDragOver={handleDragOveremailrpyrec}
                                                    >
                                                      <div className="dz-message needsclick">
                                                        <div className="mb-3">
                                                          <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                                                        </div>
                                                        <h4>
                                                          Choose File or Drag it here...
                                                        </h4>
                                                        {fileemailrpyrec ? (
                                                          <p style={{ fontSize: "12px" }}>{fileemailrpyrec.name}</p>
                                                        ) : (
                                                          <p style={{ fontSize: "12px" }}>No file choosen</p>
                                                        )}

                                                      </div>
                                                    </div>
                                                    <input
                                                      type="file"
                                                      onChange={handleFileChangesemailrpyrec}
                                                      ref={fileInputRefemailrpyrec}
                                                      style={{ display: "none" }}
                                                    />
                                                  </div>
                                                </div>
                                                <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px", marginTop: "10px" }}>
                                                  <Button className="btn btn-success" onClick={fileuploademailrpyrec}>
                                                    Upload
                                                  </Button>
                                                  <div>
                                                    <Button className="btn btn-light" onClick={() => { setShowModalemailrpyrec(false); }}>
                                                      Cancel
                                                    </Button>
                                                  </div>
                                                </div>
                                              </ModalBody>
                                            </Modal>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {/* reply */}
                                  </CardBody>
                                </Collapse>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </AccordionItem>
                  )}

                  {res.log_type == 14 && (
                    <AccordionItem key={res.id}>
                      <div className="timeline-right mb-3">
                        <Row className="timeline-right mb-3">
                          <Col xs={12}>
                            <div className="timeline-row">
                              <div
                                style={{
                                  marginLeft: "24px",
                                  backgroundColor: "#912a4e",
                                  display: "inline-flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "33px",
                                  height: "30px",
                                  borderRadius: "50%",
                                  lineHeight: "0",
                                  zIndex: "999",
                                  position: "absolute",
                                }}
                              >
                                <i
                                  className="bx bxs-bx bx-list-ul timelineicon"
                                  style={{ color: "white", fontSize: "18px" }}
                                ></i>
                              </div>
                              <div className="card mb-0" style={{ marginLeft: "65px" }}>
                                <CardHeader className="align-items-center d-flex justify-content-between">
                                  <div>
                                    <h4 className="card-title mb-0">Inventory List</h4>
                                  </div>
                                  <div>
                                    <i
                                      className={`bx ${isCollapsedinvm[res.id] ? "bx-chevron-up" : "bx-chevron-down"} cursor-pointer`}
                                      style={{ fontSize: "23px" }}
                                      onClick={() => toggleCollapseinvm(res.id)}
                                    ></i>
                                  </div>
                                </CardHeader>
                                <Collapse isOpen={!isCollapsedinvm[res.id]} className="accordion-collapse">
                                  <CardBody>
                                    <Col className="pl-1">
                                      <p>{res.log_message}</p>
                                      <p className="mt-4 text-muted"><i>
                                        {moment(res.log_date).fromNow()} On {formatDate(res.log_date)}
                                      </i></p>
                                    </Col>
                                  </CardBody>
                                </Collapse>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </AccordionItem>
                  )}
                  {res.log_type == 15 && (
                    <AccordionItem key={res.id}>
                      <div className="timeline-right mb-3">
                        <Row className="timeline-right mb-3">
                          <Col xs={12}>
                            <div className="timeline-row">
                              <div style={{ marginLeft: "24px", backgroundColor: "#912a4e", display: "inline-flex", alignItems: "center", justifyContent: "center", width: "33px", height: "30px", borderRadius: "50%", lineHeight: "0", zIndex: "999", position: "absolute" }}>
                                <i className="bx bxs-pencil timelineicon" style={{ color: "white", fontSize: "14px" }}></i>
                              </div>
                              <div className="card mb-0" style={{ marginLeft: "65px" }}>
                                <CardHeader className="align-items-center d-flex justify-content-between">
                                  <div>
                                    <h4 className="card-title mb-0">Driver Notes</h4>
                                  </div>
                                  <div>
                                    <i
                                      className={`bx ${isCollapsedinvd[res.id] ? "bx-chevron-up" : "bx-chevron-down"} cursor-pointer`}
                                      style={{ fontSize: "23px" }}
                                      onClick={() => toggleCollapseinvd(res.id)}
                                    ></i>
                                  </div>
                                </CardHeader>
                                <Collapse isOpen={!isCollapsedinvd[res.id]} className="accordion-collapse">
                                  <CardBody>
                                    <Row>
                                      <Col className="pl-1">
                                        <p>{res.log_message}</p>
                                        <p className="mt-4 text-muted"><i>
                                          {moment(res.log_date).fromNow()} by {ppl_people.first_name} {ppl_people.last_name} on {formatDate(res.log_date)}
                                        </i></p>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Collapse>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </AccordionItem>
                  )}
                  {res.log_type == 16 && (
                    <Row className="timeline-right mb-3" key={res.id}>
                      <Col xs={12}>
                        <div className="timeline-row">
                          <div style={{ marginLeft: "24px", backgroundColor: "#912a4e", display: "inline-flex", alignItems: "center", justifyContent: "center", width: "33px", height: "30px", borderRadius: "50%", lineHeight: "0", zIndex: "999", position: "absolute" }}>
                            <i className="bx bx-bulb timelineicon" style={{ color: "white", fontSize: "18px" }}></i>
                          </div>
                          <div className="card mb-0" style={{ marginLeft: "65px" }}>
                            {/* <div className="card mb-0 mt-3"> */}
                            <CardBody>
                              <Row>
                                <Col className="pl-1">
                                  <p className="pt-3">{res.log_message}</p>
                                  <p><i style={{ color: '#999' }}>
                                    Opportunity created on {formatDate(res.log_date)}
                                  </i></p>
                                </Col>
                              </Row>
                            </CardBody>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    // Opportunity created on 
                  )}
                  {res.log_type == 17 && (
                    <Row className="timeline-right mb-3" >
                      <Col xs={12}>
                        <div className="timeline-row">
                          <div style={{ marginLeft: "24px", backgroundColor: "#912a4e", display: "inline-flex", alignItems: "center", justifyContent: "center", width: "33px", height: "30px", borderRadius: "50%", lineHeight: "0", zIndex: "999", position: "absolute" }}>
                            <i className="bx bxs-bell timelineicon" style={{ color: "white", fontSize: "18px" }}></i>
                          </div>
                          <div className="card mb-0" style={{ marginLeft: "65px" }}>
                            <CardBody>
                              <Row>
                                <Col className="pl-1">
                                  <span>
                                    <p><i style={{ color: '#999' }}>
                                      {res.log_message} on {formatDate(res.log_date)}
                                    </i></p>
                                  </span>
                                </Col>
                              </Row>
                            </CardBody>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}
                  {res.log_type === 18 && (
                    <Row className="timeline-right mb-3" >
                      <Col xs={12}>
                        <div className="timeline-row">
                          <div style={{ marginLeft: "24px", backgroundColor: "#912a4e", display: "inline-flex", alignItems: "center", justifyContent: "center", width: "33px", height: "30px", borderRadius: "50%", lineHeight: "0", zIndex: "999", position: "absolute" }}>
                            <i className="bx bxs-user-check timelineicon" style={{ color: "white", fontSize: "18px", marginLeft: "5px" }}></i>
                          </div>
                          <div className="card mb-0" style={{ marginLeft: "65px" }}>
                            <CardBody>
                              <Row>
                                <Col className="pl-1">
                                  <span>
                                    <i style={{ color: '#999' }}>
                                      {res.log_message} on {formatDate(res.log_date)}
                                    </i>
                                  </span>
                                </Col>
                              </Row>
                            </CardBody>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}


                  {res.log_type == 19 && (
                    <Row>
                      <Col xs={12}>
                        <div className="timeline-date">
                          <div className="card mb-0 mt-3">
                            <CardBody>
                              <Row>
                                <Col lg={6}>
                                  <h5>{res.log_subject}</h5>
                                </Col>
                              </Row>
                              <Row>
                                <Col className="pl-1">
                                  <i className="ri-account-circle-line"></i>
                                  <span className="pt-5 text-muted">
                                    date {moment(res.log_date).fromNow()}
                                  </span>
                                </Col>
                              </Row>

                            </CardBody>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}
                </>
              );
            })}
        </div>
      </div >
    </div >
    // </div>
  );
};
