import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Table, CardHeader, Row, Button } from "reactstrap";
import axios from 'axios';
import { get_cookie } from '../../../helpers/get_cookie';
import DeleteModal from "../../../Components/Common/DeleteModal";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../../Components/Common/Loader";

export const FinanceSettingsProductCategory = () => {
   document.title="Product Category | Onexfort";
  const [productCategory, setProductCategory] = useState([]);
  const [rowsData, setRowsData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [isLoading, setisLoading] = useState(false);


  const user = JSON.parse(get_cookie("authUser"));
  var tenant_id = user.tenant_id;
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData=()=>{
    setisLoading(true)
    axios.get('/api/product-categories')
    .then(res => {
      console.log(res.category_name);
      setProductCategory(res);
      setisLoading(false)

      // setCustomerData(res);

    })
    .catch(error => {
      console.error('Error fetching lead types:', error);
    });
  };

  const handleChange = (index, event) => {
    const { name, value } = event.target;

    if (name === 'category_name') {
      const updatedProductCategory = [...productCategory];
      updatedProductCategory[index].category_name = value;
      setProductCategory(updatedProductCategory);
    } else if (name === 'groupName') {
      const updatedRowsData = [...rowsData];
      updatedRowsData[index].groupName = value;
      setRowsData(updatedRowsData);
    }
  };

  const handleSave = async (index) => {
    const item = productCategory[index];
    console.log('update:', item.category_name);
    var datavalue={
      "product_categories_id": item.id,
      "category_name": item.category_name
    }
    if(item.category_name==''){
      toast.error("Category Name cannot be empty",{theme:"light"});
      return

    }

    axios.post('/api/product-categories/update',datavalue)
    .then(res => {
      console.log(res.category_name);
      toast.success(res.message,{theme:"light"});

      fetchData();
      // setCustomerData(res);

    })
    .catch(error => {
      console.error('Error fetching lead types:', error);
    });
  };

  const handleCancel = (index) => {
    const updatedProductCategory = [...productCategory];
    updatedProductCategory[index].isEditing = false;
    setProductCategory(updatedProductCategory);
  };

  const handleEdit = (index) => {
    const updatedProductCategory = [...productCategory];
    updatedProductCategory[index].isEditing = true;
    setProductCategory(updatedProductCategory);
  };
  const [prodcatid,setProdcatId] = useState('')

  const handleDelete = async (index) => {
    const item = productCategory[index];
    setProdcatId(item.id)
  setDeleteModal(true)
  };
  const handleDeleteCategory = async (index) => {
    
    var data={
      "id":prodcatid
    }
 
      axios.post('/api/product-categories/delete',data)
      .then(res => {
        toast.success(res.message,{theme:"light"});
        const updatedRows = [...rowsData];
    updatedRows.splice(index, 1);
    setRowsData([...updatedRows]);
        fetchData();
        setDeleteModal(false)  
      })
      .catch(error => {
        console.error('Error fetching lead types:', error);
      });
  };

  const saveTableRow = (index) => {
    const data = rowsData[index];
    console.log('Saving:', data.groupName);
    var datavalue={
      "category_name":  data.groupName,
      "tenant_id":tenant_id
    }
if(data.groupName==''){
  toast.error('Enter Category Name', {theme: "light"});
  return;
}
    axios.post('/api/product-categories/save',datavalue)
    .then(res => {
      toast.success(res.message, {theme: "light"});
      console.log(res);
      const updatedRows = [...rowsData];
      updatedRows.splice(index, 1);
      setRowsData([...updatedRows]);
      fetchData();
  

    })
    .catch(error => {
      console.error('Error fetching lead types:', error);
    });
    // Make a POST request with data.groupName

    // Remove the editing flag
    const updatedRowsData = [...rowsData];
    updatedRowsData[index].isEditing = false;
    setRowsData(updatedRowsData);
  };

  const deleteTableRows = (index) => {
    const updatedRowsData = [...rowsData];
    updatedRowsData.splice(index, 1);
    setRowsData(updatedRowsData);
  };

  const addTableRows = () => {
    const newTableRow = { groupName: '', isEditing: true };
    setRowsData([...rowsData, newTableRow]);
  };

  return (
    <div>
      <Card>
      <ToastContainer closeButton={false} limit={1} theme="light"/>
        <CardHeader></CardHeader>
        <CardBody>
        <DeleteModal
                        show={deleteModal}
                        onDeleteClick={handleDeleteCategory}
                        onCloseClick={() => setDeleteModal(false)}
                    />
                     {isLoading && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            background: 'rgba(255, 255, 255, 0.8)',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            zIndex: 9999,
                                        }}
                                    >
                                        <Loader />
                                    </div>
                                )}
          <div className="live-preview">
            <Row>
              <Col xl={12}>
                <div className="table-responsive mt-4 mt-xl-0">
                  <Table className="table table-bordered table-nowrap align-middle mb-3">
                    <thead>
                      <tr>
                        <th scope="col">Category Name</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {productCategory.map((item, index) => (
                        <tr key={item.id}>
                          <td className="fw-medium">
                            {item.isEditing ? (
                              <input
                                type="text"
                                value={item.category_name}
                                onChange={(event) => handleChange(index, event)}
                                className="form-control"
                                name="category_name"
                              />
                            ) : (
                              item.category_name
                            )}
                          </td>
                          <td>
                            <div className="hstack gap-3 flex-wrap">
                              {item.isEditing ? (
                                <>
                                  <button
                                    className="btn btn-success"
                                    onClick={() => handleSave(index)}
                                  >
                                    Update
                                  </button>
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => handleCancel(index)}
                                  >
                                    Cancel
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    className="btn btn-sm btn-soft-info edit-list"
                                    onClick={() => handleEdit(index)}
                                  >
                                    <i className="bx bxs-pencil fs-12 pt-1"></i>
                                  </button>
                                  <button
                                    className="btn btn-sm btn-soft-danger"
                                    onClick={() => handleDelete(index)}
                                  >
                                    <i className="ri-delete-bin-5-fill fs-16"></i>
                                  </button>
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                      {rowsData.map((data, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              type="text"
                              value={data.groupName}
                              onChange={(event) => handleChange(index, event)}
                              name="groupName"
                              className="form-control"
                            />
                          </td>
                          <td>
                            <button
                              className="btn btn-success"
                              onClick={() => saveTableRow(index)}
                            >
                              Save
                            </button>
                            <button
                              className="btn btn-primary"
                              onClick={() => deleteTableRows(index)}
                            >
                              Cancel
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <button className="btn btn-primary" onClick={addTableRows}>
                    +
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
