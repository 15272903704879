import { Card, CardBody, Col, Container, Row, Accordion, AccordionItem, Collapse } from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Button } from 'reactstrap';
import { isEmpty } from "lodash";
import "../../../assets/scss/pages/_settings.scss";
import classnames from "classnames";
import {
  CardHeader,
  Label,
  Input,
  Table,
} from "reactstrap";
//Import actions
import {
  getContacts as onGetContacts,
  addNewContact as onAddNewContact,
  updateContact as onUpdateContact,
  deleteContact as onDeleteContact,
} from "../../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import moment from 'moment';
//toast
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../../Components/Common/Loader";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import StripePayment from '../../../Components/SMSStripe';
import { get_cookie } from "../../../helpers/get_cookie";

const stripePromise = loadStripe('pk_test_E0qVMK2JSQXGItx8GHNrlqX9');

export const BuySMSCredits = () => {
  document.title="Buy SMS Credits | Onexfort";
  const [modal, setModal] = useState(false);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setContact(null);
    } else {
      setModal(true);
      setTag([]);
      setAssignTag([]);
    }
  }, [modal]);

  

  const [contact, setContact] = useState([]);
  const dispatch = useDispatch();
  const { crmcontacts, isContactCreated, isContactSuccess, error } = useSelector((state) => ({
    crmcontacts: state.Crm.crmcontacts,
    isContactCreated: state.Crm.isContactCreated,
    isContactSuccess: state.Crm.isContactSuccess,
    error: state.Crm.error,
  }));

  useEffect(() => {
    setContact(crmcontacts);
  }, [crmcontacts]);

  useEffect(() => {
    if (!isEmpty(crmcontacts)) {
      setContact(crmcontacts);
      setIsEdit(false);
    }
  }, [crmcontacts]);

  const [isEdit, setIsEdit] = useState(false);
  // Date & Time Format
  const dateFormat = () => {
    var d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return (d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear());
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // img: (contact && contact.img) || '',
      name: (contact && contact.name) || '',
      company: (contact && contact.company) || '',
      designation: (contact && contact.designation) || '',
      email: (contact && contact.email) || '',
      phone: (contact && contact.phone) || '',
      lead_score: (contact && contact.lead_score) || '',
      tags: (contact && contact.tags) || [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      company: Yup.string().required("Please Enter Company"),
      designation: Yup.string().required("Please Enter Designation"),
      email: Yup.string().required("Please Enter Email"),
      phone: Yup.string().required("Please Enter Phone"),
      lead_score: Yup.string().required("Please Enter lead_score"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateContact = {
          _id: contact ? contact._id : 0,
          // img: values.img,
          name: values.name,
          company: values.company,
          designation: values.designation,
          email: values.email,
          phone: values.phone,
          lead_score: values.lead_score,
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // update Contact 
        dispatch(onUpdateContact(updateContact));
        validation.resetForm();
      } else {
        const newContact = {
          _id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          // img: values["img"],
          name: values["name"],
          company: values["company"],
          designation: values["designation"],
          email: values["email"],
          phone: values["phone"],
          lead_score: values["lead_score"],
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // save new Contact
        dispatch(onAddNewContact(newContact));
        validation.resetForm();
      }
      toggle();
    },
  });

  const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);

 
  const [col1, setcol1] = useState(false);
  const t_col1 = () => {
    setcol1(false);
  };
  const [smsTable, setSmsTable] = useState([]);
  const [smsTopup, setsmsTopup] = useState("");
  const [stripecusid, setStripeCusid] = useState("");
  const [smsBalance, setSmsBalance] = useState("");
  const [smsBalanceTop, setSmsBalanceTop] = useState("");
  const [tenantDetails, setTenantDetalis] = useState([]);
  const [sysApiSettings, setSysApiSettings] = useState([]);
  const [smsCredit, setSmsCredit] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [org_date, setOrg_Date] = useState([]);
  
  const [balTop, setBalTop] = useState("");
  
  const loadSms = () => {
    setisLoading(true);
    axios.get('/api/sms-credits/data')
      .then(response => {
        setOrg_Date(response.organisation_settings);
        setSmsTable(response.sms_purchase_history);
        setsmsTopup(response.sms_auto_top_up);
        setStripeCusid(response.stripe_customer_id);
        setSmsBalance(response.sms_balance_lower_limit);
        setSmsBalanceTop(response.sms_balance_top_up_qty);
        setSysApiSettings(response.sys_api_settings);
        setTenantDetalis(response.tenant_details);
      })
      .catch(error => {
        console.log(error);
      }).finally(() => {
        setisLoading(false);
      })
  }
  //*backend response*
  useEffect(() => {
    loadSms();
    var email = JSON.parse(get_cookie('authUser')).email;
    setUserEmail(email);
  }, []);

    console.log("smsTopup",smsTopup);

  //toggling form based on database value defaultelynumber
  useEffect(() => {
    if (smsTopup === "Y") {
      setcol1(true);
  } 
  else{
    setcol1(false);
  }
  }, [smsTopup]);

  const handleChange = (e) => {
    setSmsCredit(e.target.value);
    var credits = e.target.value;
    console.log(parseFloat(((parseFloat(credits)*parseFloat(sysApiSettings?.per_unit_cost))*(1+parseFloat(sysApiSettings?.variable1)/100)+parseFloat(sysApiSettings?.variable2))).toFixed(2))
    var amount = (((parseFloat(credits)*parseFloat(sysApiSettings?.per_unit_cost))*(1+parseFloat(sysApiSettings?.variable1)/100))+parseFloat(sysApiSettings?.variable2)).toFixed(2)*100;
    setTotalAmount(amount);
  };

  //Top up & Save Button
  const handleSubmit = (event) => {
    event.preventDefault();
    if (smsBalance == '' || smsBalance == 0) {
      toast.error("Please enter sms balance lower limit", { theme: "light" });
      return; 
    } else if (smsBalanceTop == ''|| smsBalanceTop == 0) {
      toast.error("Please enter top up credits", { theme: "light" });
      return; 
    } else if (smsBalanceTop < 10) {
      toast.error("Please enter minimum 10 credits", { theme: "light" });
      return; 
    } 
  }

  //Buy Button
  const handleBuySubmit = (event) => {
    event.preventDefault();
    if (smsCredit == '' || smsCredit == 0) {
      toast.error("Please enter credits", { theme: "light" });
      return; 
    } else if (smsCredit < 10) {
      toast.error("Please enter minimum 10 credits", { theme: "light" });
      return; 
    } 
  }

  return (
    <div>
      <Card>
        <CardHeader><h5 className="mb-0 text-brown">Buy SMS Credits</h5></CardHeader>
        <CardBody>
          <Row>
            <Col md={6}>
              <Label className="form-Label text-black">Credits</Label>
              <input
                type="number"
                min="1"
                step="1"
                className="form-control"
                id="sms_credit"
                name="sms_credit"
                value={smsCredit}
                onChange={handleChange}
              />
              <input type="hidden" id="per_unit_cost" value={sysApiSettings.per_unit_cost} />
              <input type="hidden" id="processing_fee_1" value={sysApiSettings.variable1} />
              <input type="hidden" id="processing_fee_2" value={sysApiSettings.variable2} />
              <note>
                1 credit = 1 sms at <b>{(sysApiSettings.per_unit_cost * 100).toFixed(0)}</b> cents per sms
              </note>
            </Col>
            <Col md={1}></Col>
            <Col md={5} className="mt-7">
              <Row>
                <Col md="2" className="mt-2">
                  <span href="#" className="btn bg-transparent border-success text-success rounded-pill border-2 btn-icon mr-3">
                    <i className="bx bx-coin-stack icnCoin"></i>
                  </span>
                </Col>
                <Col md="10" >
                    <span className="SMSCred">{tenantDetails && tenantDetails.sms_credit ? tenantDetails.sms_credit : '0'}
                    <p className="SMSCred-light">Available Credits</p></span>
                </Col>
              </Row>
            </Col>
          </Row>
          <div>
          <Elements stripe={stripePromise}>
            <StripePayment button_text={"Buy"} stripecusid={stripecusid} smsCredit={smsCredit} balance={totalAmount} smsBalance={smsBalance} smsTopup={smsTopup} smsBalanceTop={smsBalanceTop} loadSms={loadSms} email={userEmail} />
          </Elements>
          </div>
          <hr></hr>
          <h5 style={{margin: '10px'}}>Auto Top Up</h5>
          {/* //accordion */}
          <Accordion id="default-accordion-example">
            <AccordionItem>
              <Row className="mb-2" style={{paddingLeft: '10px'}}>
                <Col lg={2} className="hstack gap-3">
                  <Label className="form-check-Label " for="flexSwitchCheckRightDisabled">Off</Label>
                  <span className="form-check form-switch form-switch-lg  mt-2 mb-2">
                    <Label className="form-check-Label pad-left" for="flexSwitchCheckRightDisabled">On</Label>
                    <Input className={classnames("form-check-Input", { collapsed: !col1 })} onClick={()=> t_col1} type="checkbox" role="switch" id="flexSwitchCheckRightDisabled"  checked={smsTopup == 'Y'} onChange={(e) => setsmsTopup(e.target.checked ? 'Y' : 'N')}/>
                  </span>
                </Col>
              </Row>
              <Collapse isOpen={col1} className="accordion-collapse" id="collapseOne">
                <div id="auto_topup_div" style={{paddingLeft: '10px'}}>
                  <Row>
                    <Col lg={10} >
                      <div className="form-group">
                        <Label>Payment Method</Label>
                        <Input type="text" name="stripe_customer_id" className="form-control" id="stripe_customer_id" value={`Stripe Customer Id: ${stripecusid === 'N' ? '' : stripecusid}`} readOnly/>
                      </div>
                    </Col >
                  </Row>
                  <Row className="form-group mt-3 ">
                    <Col lg={4} >
                      <Label className="">When my balance falls below</Label>
                    </Col>
                    <Col lg={6} >
                      <div className="input-group ">
                        <Input type="number" min="0" aria-label="First name" className="form-control" value={smsBalance} onChange={(e) => {setSmsBalance(e.target.value);}}/>
                        <span className="input-group-text ">Credits</span>
                      </div>
                    </Col >
                  </Row>
                  <Row className="form-group mt-3 ">
                    <Col lg={4} >
                      <Label className="">Then top up my balance by </Label>
                    </Col>
                    <Col lg={6} >
                      <div className="input-group mb-3">
                        <Input type="text" aria-label="First name" className="form-control"  value={smsBalanceTop} onChange={(e) => {setSmsBalanceTop(e.target.value);}}/>
                        <span className="input-group-text ">Credits</span>
                      </div>
                    </Col >
                  </Row>

                  <div className="form-actions"style={{paddingBottom: '10px'}}>
                    <div className="row">
                      <div className="col-md-12">
                      <Elements stripe={stripePromise}>
                        <StripePayment button_text={"Top Up & Save"} stripecusid={stripecusid} smsCredit={smsBalanceTop} balance={(((parseFloat(smsBalanceTop)*parseFloat(sysApiSettings?.per_unit_cost))*(1+parseFloat(sysApiSettings?.variable1)/100))+parseFloat(sysApiSettings?.variable2)).toFixed(2)*100} smsBalance={smsBalance} smsTopup={smsTopup} smsBalanceTop={smsBalanceTop} loadSms={loadSms} email={userEmail} />
                      </Elements>
                        {/* <button type="button" className="btn btn-success m-r-10"  id="topup_setting_btn" onClick={handleSubmit}>Top up & Save</button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </Collapse>
            </AccordionItem>
          </Accordion>
          <hr></hr>
          <h5 className="mb-3">SMS Purchases</h5>
          <Table className="table align-middle table-nowrap table-striped-columns mb-0">
            <thead className="table-light">
              <tr>
                <th scope="col">
                  Gateway</th>
                <th scope="col">Transaction ID</th>
                <th scope="col">Transaction Date</th>
                <th scope="col">Credit Purchased</th>
                <th scope="col" style={{ width: "150px" }}>Payment Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {smsTable.map((item, index) => (
                <tr key={item.id}>
                  <td>{item.gateway}</td>
                  <td>{item.transaction_id}</td>
                  {/* <td>{moment(item.transaction_date).format(org_date)}</td> */}
                  <td>{moment(item.transaction_date).format(org_date.date_picker_format)}</td>
                  <td>{item.qty_purchased}</td>
                  <td>${item.payment_amount}</td>
                  <td><span className="badge bg-success" style={{ textTransform: "capitalize" }}>{item.status}</span></td>
                </tr>
              ))}
            </tbody>
          </Table>
          {isLoading && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(255, 255, 255, 0.8)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999,
              }}
            >
              <Loader />
            </div>
          )}
        </CardBody>
      </Card>
      <ToastContainer closeButton={false} limit={1} theme="light" />
    </div>
  )
}
