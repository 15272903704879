import React from 'react';
import { Grid, _ } from 'gridjs-react';
import { Link } from "react-router-dom";

// Base Example
const BaseExample = ({ data }) => {

    /*return (
        <React.Fragment>
            <Grid
                data={data}
                columns={[
                    {
                        name: 'customer',
                        formatter: (cell) => _(<a href={'customerdetails/' + parseInt(cell.id)}> {JSON.stringify(cell.name).replace(/['"]+/g, '')} </a>)
                    },
                    "Mobile",
                    "Email",
                    "Lead_status"

                ]}
                search={true}
                sort={true}
                pagination={{ enabled: true, limit: 5, }}
            />
        </React.Fragment>
    );*/
};
// CUSTOMER COMMERCIAL
const CustomerCommercial = ({ data }) => {
    /*return (
        <React.Fragment>
            <Grid
                data={data}
                columns={[
                     {
                        name: 'customer',
                        formatter: (cell) => _(<a href={'customerdetails/' + parseInt(cell.id)}> {JSON.stringify(cell.name).replace(/['"]+/g, '')} </a>)
                    },
                    "Mobile",
                    "Email",
                    "Lead_status"

                ]}
                search={true}
                sort={true}
                pagination={{ enabled: true, limit: 5, }}
            />
        </React.Fragment>
    );*/
};
//STORAGE MODULE
const StorageData = ({ data }) => {
    /*const formatDate = (date) => {
    if (date && date !== '0000-00-00') {
      const formattedDate = new Date(date);
      if (isNaN(formattedDate)) {
        return '-';
      }
      return formattedDate.toLocaleDateString();
    }
    return '';
  };
    return (
        <React.Fragment>
            <Grid
                data={data}
                columns={[
                     {
                        name: 'storage',
                        formatter: (cell) => _(<a href={'/storage/view-storage-job/' + parseInt(cell.id)}> {JSON.stringify(cell.name).replace(/['"]+/g, '')} </a>)
                    },
                    "customer_name",
                    "commercial_customer",
                    "status",
                    "storage_units",
                    "location",
                    {
                        name: 'job',
                        formatter: (cell) => _(<a href={'/listjobs/listjobsdetail/' + parseInt(cell.id)}> {JSON.stringify(cell.name).replace(/['"]+/g, '')} </a>)
                    },
                    "volume",
                    {
                      name: 'date_in',
                      formatter: (cell) => formatDate(cell),
                    },
                    {
                      name: 'date_out',
                      formatter: (cell) => formatDate(cell),
                    },

                ]}
                search={true}
                sort={true}
                pagination={{ enabled: true, limit: 5, }}
            />
        </React.Fragment>
    );*/
};

export { BaseExample,StorageData,CustomerCommercial};
