import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledTooltip,
  Button,
} from "reactstrap";
import classnames from "classnames";
import React, { useEffect, useState, useCallback, useMemo,useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import * as moment from "moment";
import TablesColors from "../BasicTablesCode";
import TableRows from "../TableRows";
import { TableCheckList, InvDefinitions } from "../TableRows";
import "../../../../assets/scss/pages/_opportunities.scss";

import {
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  ModalFooter,
  Table,
  FormFeedback,
} from "reactstrap";

import DeleteModal from "../../../../Components/Common/DeleteModal";

import {
  getContacts as onGetContacts,
  addNewContact as onAddNewContact,
  updateContact as onUpdateContact,
  deleteContact as onDeleteContact,
} from "../../../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";

import TableContainer from "../../../../Components/Common/TableContainer";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import Loader from "../../../../Components/Common/Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Export Modal

import ExportCSVModal from "../../../../Components/Common/ExportCSVModal";

export const RemovalSettingsVehicles = () => {
  const [contact, setContact] = useState([]);
  const [group_name, setGroupName] = useState([]);
  const [ProductCategories, setProductCategories] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedfrequency, setselectedfrequency] = useState("");
  const [Maxcleaner, setMaxCleaner] = useState(0);
  const [Mincleaner, setMincleaner] = useState(0);
  const [Minhours, setMinhours] = useState(0);
  const [Maxhours, setMaxhours] = useState(0);
  const [frequency, setfrequency] = useState();
  const [quoteFormSetup, setquoteFormSetup] = useState();
  const [generatedScript, setGeneratedScript] = useState('');
  const[generateScript2,setGeneratedScript2]=useState();

  const [selectedExtrasCategory, setSelectedExtrasCategory] = useState(
    quoteFormSetup?.extras_category_id || "" // Set initial value from quoteFormSetup
  );

  const [modal_large, setmodal_large] = useState(false);
  function tog_large() {
    setmodal_large(!modal_large);
  }

  const dispatch = useDispatch();
  const { crmcontacts, isContactCreated, isContactSuccess, error } =
    useSelector((state) => ({
      crmcontacts: state.Crm.crmcontacts,
      isContactCreated: state.Crm.isContactCreated,
      isContactSuccess: state.Crm.isContactSuccess,
      error: state.Crm.error,
    }));

  useEffect(() => {
    axios
      .get("/api/generalQuoteFormSettings")
      .then((response) => {
        // console.log(response.status, "hhh");
        setProductCategories(response.product_categories);
        setfrequency(response.list_types);
        setquoteFormSetup(response.quote_form_setup);
        setGroupName(response.group);
        setCompaniesList(response.companies);
        setGeneratedScript(response.code_script);
        setGeneratedScript2(response.code_script2);
        // Other state updates can be done here
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  // console.log(generatedScript,"producttttttttttttttttttttt")

  // const[quote_id,setquoteformid]=useState(quoteFormSetup?.id);
  // console.log(quote_id,"quote_idquote_idquote_id")

  useEffect(() => {
    if (crmcontacts && !crmcontacts.length) {
      dispatch(onGetContacts());
    }
  }, [dispatch, crmcontacts]);

  useEffect(() => {
    setContact(crmcontacts);
  }, [crmcontacts]);

  useEffect(() => {
    if (!isEmpty(crmcontacts)) {
      setContact(crmcontacts);
      setIsEdit(false);
    }
  }, [crmcontacts]);

  const [isEdit, setIsEdit] = useState(false);
  //delete Conatct
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [companyname, setCompanyName] = useState("none");
  const [companieslist, setCompaniesList] = useState([]);

  const [modal, setModal] = useState(false);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setContact(null);
    } else {
      setModal(true);
      setTag([]);
      setAssignTag([]);
    }
  }, [modal]);

  const onClickDelete = (contact) => {
    setContact(contact);
    setDeleteModal(true);
  };

  // Add Data
  const handleContactClicks = () => {
    setContact("");
    setIsEdit(false);
    toggle();
  };

  // Date & Time Format

  const dateFormat = () => {
    var d = new Date(),
      months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    return d.getDate() + " " + months[d.getMonth()] + ", " + d.getFullYear();
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // img: (contact && contact.img) || '',
      name: (contact && contact.name) || "",
      company: (contact && contact.company) || "",
      designation: (contact && contact.designation) || "",
      email: (contact && contact.email) || "",
      phone: (contact && contact.phone) || "",
      lead_score: (contact && contact.lead_score) || "",
      tags: (contact && contact.tags) || [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      company: Yup.string().required("Please Enter Company"),
      designation: Yup.string().required("Please Enter Designation"),
      email: Yup.string().required("Please Enter Email"),
      phone: Yup.string().required("Please Enter Phone"),
      lead_score: Yup.string().required("Please Enter lead_score"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateContact = {
          _id: contact ? contact._id : 0,
          // img: values.img,
          name: values.name,
          company: values.company,
          designation: values.designation,
          email: values.email,
          phone: values.phone,
          lead_score: values.lead_score,
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // update Contact
        dispatch(onUpdateContact(updateContact));
        validation.resetForm();
      } else {
        const newContact = {
          _id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          // img: values["img"],
          name: values["name"],
          company: values["company"],
          designation: values["designation"],
          email: values["email"],
          phone: values["phone"],
          lead_score: values["lead_score"],
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // save new Contact
        dispatch(onAddNewContact(newContact));
        validation.resetForm();
      }
      toggle();
    },
  });


  

  // Update Data
  const handleContactClick = useCallback(
    (arg) => {
      const contact = arg;

      setContact({
        _id: contact._id,
        // img: contact.img,
        name: contact.name,
        company: contact.company,
        email: contact.email,
        designation: contact.designation,
        phone: contact.phone,
        lead_score: contact.lead_score,
        last_contacted: contact.date,
        // time: contact.time,
        tags: contact.tags,
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      dispatch(onDeleteContact(element.value));
      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".contactCheckBox:checked");
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  //select ///

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handlefrequencychange = (event) => {
    setselectedfrequency(event.target.value);
  };

  const handleExtrasCategoryChange = (event) => {
    setSelectedExtrasCategory(event.target.value);
  };

  const [selectedGroupId, setSelectedGroupId] = useState(null);
 
  // const generatescript = () => {
   


   

  //   axios
  //     .get(`/api/generalQuoteFormSettings`)
  //     .then((res) => {
   
  //       console.log(res);
  
  //       if (res.error == 0) {
  //         toast.success(res.message);
  //         setGeneratedScript(res.code_script)
  //         console.log(generatedScript,"generatedScriptgeneratedScriptgeneratedScript")
  //       } else {
  //         console.log(generatedScript,"generatedScriptgeneratedScriptgeneratedScript")
  //         toast.error(res.message);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(generatedScript,"generatedScriptgeneratedScriptgeneratedScript")
  //       console.error(error);
  //       // Handle the error appropriately, display error messages, or take necessary actions
  //     });
  // };

 

  const handleSubmit = (event) => {
   

    let formData = {
      services_category_id: selectedCategory,
      min_cleaners: Mincleaner,
      max_cleaners: Maxcleaner,
      min_hours: Minhours,
      max_hours: Maxhours,
      time_selector_list_type_id: selectedfrequency,
      extras_category_id: selectedExtrasCategory,
      questions_list_type_id: selectedGroupId,
      id:quoteFormSetup.id
    };

   

    axios
      .post(`/api/saveGeneralQuoteFormSettings`, formData)
      .then((res) => {
   
        console.log(res);
        setModal(false);
        if (res.error == 0) {
          toast.success(res.message);
         
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => {
        console.error(error);
        // Handle the error appropriately, display error messages, or take necessary actions
      });
  };



   const[generatescriptcompany,setgeneratescriptcompany]=useState();
   const[generateprotrudings,setgenerateprotruding]=useState();

  const generatecompanyid = () => {
   

    let formData = {
     
      Company:companyname
    };

   

    axios
      .post(`/api/generalQuoteFormSettingCompany`, formData)
      .then((response) => {
     
        setgeneratescriptcompany(response.code_script)
        
        if (response.error == 0) {
          toast.success(response.message);
         
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        // console.error(error);
        // Handle the error appropriately, display error messages, or take necessary actions
      });
  };


  const generateprotruding = () => {
   

    let formData = {
     
      Company:companyname
    };

   

    axios
      .post(`/api/generalQuoteFormSettingCompany`, formData)
      .then((response) => {
     
        setgenerateprotruding(response.code_script2)
        
        if (response.error == 0) {
          toast.success(response.message);
         
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        // console.error(error);
        // Handle the error appropriately, display error messages, or take necessary actions
      });
  };

// console.log(generatescriptcompany,"generatescriptcompanygeneratescriptcompanygeneratescriptcompany")
  //////////////

  const readyForCopyRef = useRef(null);
  const readyForCopyRefpro =useRef(null);
  const copyToClipboard = () => {
    const tempInput = document.createElement('input');
    tempInput.style.position = 'absolute';
    tempInput.style.left = '-1000px';
    tempInput.style.top = '-1000px';
    tempInput.value = readyForCopyRef.current.value;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);

    toast.success('Script copied to clipboard.', {
      position: toast.POSITION.TOP_RIGHT,
    }); // Replace this with your desired notification method
  };

  const copyToClipboardPro = () => {
    const tempInput = document.createElement('input');
    tempInput.style.position = 'absolute';
    tempInput.style.left = '-1000px';
    tempInput.style.top = '-1000px';
    tempInput.value = readyForCopyRefpro.current.value;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);

    toast.success('Script copied to clipboard.', {
      position: toast.POSITION.TOP_RIGHT,
    }); // Replace this with your desired notification method
  };
  // Customber Column
  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            className="form-check-input"
            onClick={() => checkedAll()}
          />
        ),
        Cell: (cellProps) => {
          return (
            <input
              type="checkbox"
              className="contactCheckBox form-check-input"
              value={cellProps.row.original._id}
              onChange={() => deleteCheckbox()}
            />
          );
        },
        id: "#",
      },
      {
        Header: "Name",
        accessor: "name",
        filterable: false,
      },
      {
        Header: "License Plate",
        accessor: "company",
        filterable: false,
      },

      {
        Header: "Vehicle in Tons",
        accessor: "lead_score",
        filterable: false,
      },
      {
        Header: "Active",
        accessor: "",
        filterable: false,
      },

      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item">
                <UncontrolledDropdown>
                  <DropdownToggle
                    href="#"
                    className="btn btn-soft-secondary btn-sm dropdown"
                    tag="button"
                  >
                    Action <i className="ri-more-fill align-middle"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem
                      className="dropdown-item edit-item-btn"
                      href="#"
                      onClick={() => {
                        const contactData = cellProps.row.original;
                        handleContactClick(contactData);
                      }}
                    >
                      <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                      Edit
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown-item remove-item-btn"
                      href="#"
                      onClick={() => {
                        const contactData = cellProps.row.original;
                        onClickDelete(contactData);
                      }}
                    >
                      <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                      Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          );
        },
      },
    ],
    [handleContactClick, checkedAll]
  );

  const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);

  //rowsdata
  const [rowsData, setRowsData] = useState([]);

  return (
    <div>
        <ToastContainer />
      <Col xxl={12}>
        <Card id="contactList">
          <CardHeader>
            <h5 className="mb-0 text-primary">
              General Cleaning Quote Form Settings
            </h5>
          </CardHeader>
          <CardBody className="pt-0 mt-3">
            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="customer" className="form-label">
                    <b>Service Product Category</b>
                  </Label>
                  <select
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    className="form-select mb-3"
                  >
                    <option value="" disabled>
                      Select a category
                    </option>
                    {ProductCategories.length > 0 ? (
                      ProductCategories.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.category_name}
                        </option>
                      ))
                    ) : (
                      <option value="" disabled>
                        No categories found
                      </option>
                    )}
                  </select>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="customer" className="form-label">
                    <b>Mininum Cleaners</b>
                  </Label>
                  <input
                    type="number"
                    value={Mincleaner}
                    onChange={(e) => setMincleaner(e.target.value)}
                    className="form-control"
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label className="form-label">
                    <b>Maximum Cleaners</b>
                  </Label>
                  <Input
                    type="number"
                    value={Maxcleaner}
                    onChange={(e) => setMaxCleaner(Number(e.target.value))}
                    className="form-control"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="customer" className="form-label">
                    <b>Minimum Hours</b>
                  </Label>
                  <Input
                    type="number"
                    value={Minhours}
                    onChange={(e) => setMinhours(Number(e.target.value))}
                    className="form-control"
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="customer" className="form-label">
                    <b>Maximum Hours</b>
                  </Label>
                  <Input
                    type="number"
                    value={Maxhours}
                    onChange={(e) => setMaxhours(Number(e.target.value))}
                    className="form-control"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="customer" className="form-label">
                    <b>Cleaning Frequency</b>
                  </Label>

                  <select
                    value={selectedfrequency}
                    onChange={handlefrequencychange}
                    className="form-select mb-3"
                  >
                    <option value="" disabled>
                      Select a Frequency
                    </option>
                    {frequency?.length > 0 ? (
                      frequency?.map((frequency) => (
                        <option key={frequency.id} value={frequency.id}>
                          {frequency.list_name}
                        </option>
                      ))
                    ) : (
                      <option value="" disabled>
                        No categories found
                      </option>
                    )}
                  </select>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="customer" className="form-label">
                    <b>Extra Product Category</b>
                  </Label>
                  <select
                    name="extras_category_id"
                    id="extras_category_id"
                    className="form-control"
                    value={selectedExtrasCategory}
                    onChange={handleExtrasCategoryChange}
                  >
                    <option value="" disabled>
                      Select a category
                    </option>
                    {ProductCategories?.length > 0 ? (
                      ProductCategories?.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.category_name}
                        </option>
                      ))
                    ) : (
                      <option value="" disabled>
                        No categories found
                      </option>
                    )}
                  </select>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={11}>
                <div className="mb-3">
                  <Label htmlFor="customer" className="form-label">
                    <b>Questions List</b>
                  </Label>
                  <Col lg={8}>
<div className="question-list-container">
  <ul className="question-list">
    {group_name?.map((item) => (
      <li
        key={item.id}
        onClick={() => setSelectedGroupId(item.id)}
        className={selectedGroupId === item.id ? 'selected' : ''}
      >
        {item.cleaning_question}
      </li>
    ))}
  </ul>
</div>

            </Col>
                </div>
              </Col>
            </Row>

            <div style={{ textAlign: "center" }}>
              <Button className="btn btn-sm btn-success fs-13 pt-1 mt-4 mb-5"   onClick={handleSubmit}>
                Save
              </Button>
            </div>

            <div>
              <Row>
                <Col md={4}>
                  <Label
                    htmlFor="company"
                    className="form-label mt-2"
                    style={{ fontSize: "13px" }}
                  >
                    <b> Choose Company for the external Removals Quote Form:</b>
                  </Label>
                </Col>
                <Col md={2}>
                  <select
                    value={companyname}
                    onChange={(e) => setCompanyName(e.target.value)}
                    className="form-select "
                  >
                    <option value="none">none</option>
                    {companieslist && companieslist.length > 0 ? (
                      companieslist.map((company) => (
                        <option key={company.id} value={company.id}>
                          {company.company_name}
                        </option>
                      ))
                    ) : (
                      <option value="" disabled>
                        No companies found
                      </option>
                    )}
                  </select>
                </Col>
              </Row>

              <Card>
                <CardHeader>
                  <h5 className="mb-0 text-primary">Hidden Tab</h5>
                </CardHeader>
                <CardBody>
                  <Button className="btn btn-sm btn-success fs-13 pt-1 mt-3 mb-5" onClick={generatecompanyid}  >
                    Generate Script
                  </Button>
                  <div>
      {generatescriptcompany && (
        <div>
          <label style={{ color: 'red' }}>Generated Script:</label>
          <pre style={{ color: 'red' }}>{generatescriptcompany}</pre>
          <button
            type="button"
            onClick={copyToClipboard}
            className="btn btn-primary m-r-10"
          >
            <i className="fa fa-copy"></i> Copy
          </button>
          <input
            type="hidden"
            value={generatescriptcompany}
            ref={readyForCopyRef}
          />
        </div>
      )}
    </div>
   
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <h5 className="mb-0 text-primary">Always Protruding</h5>
                </CardHeader>
                <CardBody>
                  <Button className="btn btn-sm btn-success fs-13 pt-1 mt-3 mb-5" onClick={generateprotruding} >
                    Generate Script
                  </Button>
    

                <div>
                      {generateprotrudings && (
                        <div>
                          <label style={{ color: 'red' }}>Generated Script:</label>
                          <pre style={{ color: 'red' }}>{generateprotrudings}</pre>
                          <button
                            type="button"
                            onClick={copyToClipboardPro}
                            className="btn btn-primary m-r-10"
                          >
                            <i className="fa fa-copy"></i> Copy
                          </button>
                          <input
                            type="hidden"
                            value={generateprotrudings}
                            ref={readyForCopyRefpro}
                          />
                        </div>
                      )}
                    </div>
 
                </CardBody>
              </Card>
            </div>
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};
