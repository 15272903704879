import {
  Card, CardBody, Col, Row, CardHeader, Table,
} from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
//redux
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';

import { toast, ToastContainer } from 'react-toastify';
import DeleteModal from "../../../Components/Common/DeleteModal";

export const VehicleGroup = () => {
  document.title="Vehicle Groups | Onexfort";
  const [group_name, setGroupName] = useState([]);
  const [groupid, setGroupId] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [tempValue, setTempValue] = useState("");

  //list table
  const loadTable = () => {
    axios.get("/api/vehiclegroups")
      .then(res => {
        console.log(res.vehicleGroups);
        setGroupName(res.vehicleGroups);
      })
  }

  useEffect(() => {
    loadTable();
  }, [])

  //rowsdata
  const [rowsData, setRowsData] = useState([]);

  //Edit
  const handleEdit = (index, item) => {
    setGroupId(item.id);
    setTempValue(item.group_name);
    setGroupName((prevGroupNames) => {

      const updatedGroupNames = [...prevGroupNames];
      updatedGroupNames[index].isEditing = true;
      return updatedGroupNames;
    });
  };
  useEffect(() => {
    console.log(tempValue);
  }, [tempValue]);

  //edit-> save
  const handleSave = (index) => {

    let data = {
      "group_name": inputValue,
      "vehiclegroupid": groupid
    }

    axios.post("/api/vehiclegroups/update", data)
      .then(res => {
        console.log("successfully edited");
        setGroupName((prevGroupNames) => {
          const updatedGroupNames = [...prevGroupNames];
          updatedGroupNames[index].isEditing = false;
          return updatedGroupNames;
        });

      })
      .catch(err => {
        console.log(err);
      })
  };
  //edit ->cancel
  const handleCancel = (index) => {
    console.log(tempValue)
    loadTable();
    setGroupName((prevGroupNames) => {
      const updatedGroupNames = [...prevGroupNames];
      updatedGroupNames[index].isEditing = false;
      return updatedGroupNames;
    });
  };
  // const handleDelete = (index, item) => {
  //   setGroupId(item.id);
  //   console.log(item.id)
  //   let data = {
  //     "id": groupid
  //   }
  //   axios.post("/api/vehiclegroups/destroy", data)
  //     .then(res => {
  //       console.log("successfully deleted");
  //       loadTable();
  //     })
  //     .catch(err => {
  //       console.log(err);
  //     })
  // }

  const handleChange = (index, event) => {
    const { value } = event.target;
    setInputValue(value);
    setGroupName((prevGroupNames) => {
      const updatedGroupNames = [...prevGroupNames];
      updatedGroupNames[index].group_name = value;
      return updatedGroupNames;
    });
  };
  const [newGroupNames, setNewGroupNames] = useState([]);

  const addTableRows = () => {
    const rowsInput = {
      groupName: '',
    }
    setRowsData([...rowsData, rowsInput])
  }

  //Add new row
  const [newInput, setNewInput] = useState({});
  const handleNewChange = (index, event) => {
    const { value } = event.target;
    setNewInput((prevNewInput) => ({
      ...prevNewInput,
      group_name: value,
    }));
    setNewGroupNames((prevNewGroupNames) => {
      const updatedNewGroupNames = [...prevNewGroupNames];
      updatedNewGroupNames[index] = value;
      return updatedNewGroupNames;
    });
  };

  //save->row
  const saveTableRow = (index) => {
    const updatedRowsData = [...rowsData];
    updatedRowsData[index].groupName = newGroupNames[index];
    setRowsData(updatedRowsData);

    console.log( updatedRowsData[index].groupName )

    if (updatedRowsData[index].groupName=="" || updatedRowsData[index].groupName==undefined ){
      toast.error('Enter Category Name',{theme:"light"}); 
    }

    else{
      axios.post("/api/vehiclegroups/create", newInput)
      .then(res => {
        console.log("successfully edited");
        const updatedRows = [...rowsData];
        updatedRows.splice(index, 1);
        setRowsData([...updatedRows]);
        loadTable();
        //clear input field
        setNewGroupNames((prevGroupNames) => {
          const updatedGroupNames = [...prevGroupNames];
          updatedGroupNames[index] = '';
          return updatedGroupNames;
        });
      })
      .catch(err => {
        console.log(err);

      })
    }
  };

  //cancel ->row
  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
    setNewGroupNames((prevGroupNames) => {
      const updatedGroupNames = [...prevGroupNames];
      updatedGroupNames[index] = '';
      return updatedGroupNames;
    });
  }

  const [deleteModal, setDeleteModal] = useState(false);

  const handleDeleteOrder = () => {
    let data = {
      "id": groupid
    }
    axios.post("/api/vehiclegroups/destroy", data)
      .then(res => {
        console.log("successfully deleted");
        loadTable();
        setDeleteModal(false)
      })
      .catch(err => {
        console.log(err);
      })

  };

  return (
    <div>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Card>
        <CardHeader>
          <h5 className="mb-0">Vehicle Groups</h5>
        </CardHeader>
        <CardBody>
          <div className="live-preview">
            <Table className="table table-bordered table-nowrap align-middle mb-3">
              <thead>
                <tr>
                  <th scope="col">Category Name</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>

              <tbody>
                {group_name.map((item, index) => (
                  <tr key={item.id}>
                    <td className="fw-medium">
                      {item.isEditing ? (
                        <input
                          type="text"
                          value={item.group_name}
                          onChange={(event) => handleChange(index, event)}
                          className="form-control"
                        />
                      ) : (
                        item.group_name
                      )}
                    </td>
                    <td>
                      <div className="hstack gap-3 flex-wrap">
                        {item.isEditing ? (
                          <>
                            <button
                              className="btn btn-success"
                              onClick={() => handleSave(index)}
                            >
                              Save
                            </button>
                            <button
                              className="btn btn-primary"
                              onClick={() => handleCancel(index)}
                            >
                              Cancel
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              className="btn btn-sm btn-soft-info edit-list "
                              onClick={() => handleEdit(index, item)}
                            >
                              <i className="bx bxs-pencil fs-12 pt-1"></i>
                            </button>
                            <button
                              className="btn btn-sm btn-soft-danger  "
                              onClick={() => {setGroupId(item.id); setDeleteModal(true); }}
                            >
                              <i className="ri-delete-bin-5-fill fs-16"></i>
                            </button>
                          </>

                        )}
                      </div>
                    </td>
                  </tr>
                ))}
                {rowsData.map((data, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="text"
                        value={newGroupNames[index] || ''}
                        onChange={(event) => handleNewChange(index, event)}
                        name="groupName"
                        className="form-control"
                      />
                    </td>
                    <td>
                      <div className="hstack gap-2">
                        <button className="btn btn-success" onClick={() => saveTableRow(index)}>
                          Save
                        </button>
                        <button className="btn btn-primary" onClick={() => deleteTableRows(index)}>
                          Cancel
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <button className="btn btn-primary" onClick={addTableRows} >+</button>
          </div>
        </CardBody>
      </Card>
      <ToastContainer closeButton={false} limit={1} />
    </div>
  )
}
