import React from 'react'
import { Card } from 'reactstrap'
import { CleaningSettingsConstant } from '../Constants/CleaningSettingsConstant';
import { RemovalSettingsVehicles } from '../TabPages/QuoteForm';
import { VehicleGroup } from '../TabPages/CleaningTeams';
import { VehicleDailyChecklist } from '../TabPages/CleaningQuoteOptions';
import { OHSChecklist } from '../TabPages/AutoQuote';
import { InventoryGroups } from '../TabPages/LeaseSettings';
import { InventoryDefinition } from '../TabPages/CleaningQuoteForm';
import { PropertyCategoryOption } from '../TabPages/CleaningShifts';

import { Route, Routes } from 'react-router-dom';


export default function RemovalTabContent({ activeTab }) {

    const TabOutlet = () => {
        switch (activeTab) {
            case CleaningSettingsConstant.General.VehicleDailyCHecklist:
                return <VehicleDailyChecklist />
            case CleaningSettingsConstant.General.Vehicles:
                return <RemovalSettingsVehicles />
            case CleaningSettingsConstant.General.VehicleGroups:
                return <VehicleGroup />
            case CleaningSettingsConstant.General.OHSChecklist:
                return <OHSChecklist />
            case CleaningSettingsConstant.General.InventoryGroups:
                return <InventoryGroups />
            case CleaningSettingsConstant.General.InventoryDefinition:
                return <InventoryDefinition />
            case CleaningSettingsConstant.General.PropertyCategoryOption:
                return <PropertyCategoryOption />




            case CleaningSettingsConstant.General.VehicleDailyCHecklist:
            default:
                return <RemovalSettingsVehicles />
        }
    }

    return (
        //Fragment just in case
       <TabOutlet/>
    )
}
