import React, { useState, useEffect } from 'react'
import { Accordion, AccordionItem, Button, Card, CardBody, CardHeader, Col, Collapse, Table, Form, Label, Input, Container, Row } from 'reactstrap';
import classnames from "classnames";
import TableRows from "./TableRow";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../../Components/Common/Loader";

export const Inventory = ({data, onChangeData}) => {
  const params = useParams();
  const job_id = params.id;

  const [col1, setcol1] = useState(true);
  const [col2, setcol2] = useState(false);
  const [col3, setcol3] = useState(false);
  const [colDisplay, setColDisplay] = useState(false);
  const [isInventoryEdit, setInventoryEdit] = useState();
  const [postData, setData] = useState();
  const [trucksize, setTruckSize] = useState();
  const [cbm, setCBM] = useState();
  const [directvalue, setDirectValue] = useState();
  const [insuranceexcess, setInsuranceExcess] = useState();
  const [insurancepremium, setInsurancePremium] = useState();
  const [inventoryItems, setInventoryItems] = useState();
  const [inventoryGroup, setInventoryGroups] = useState();
  const [miscItems, setMiscItems] = useState();
  const [addRow, setShowAddRow] = useState();
  const [isLoader, setLoader] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [filteredAccordions, setFilteredAccordions] = useState([]);
  const [expandAllBtn,setExpandAllBtn] = useState(false);

  useEffect(()=>{
    getData();
  }, [data]);

  const handleSearch = (e) => {
    setSearchText(e.target.value.toLowerCase());
  };

  const filteredGroups = inventoryGroup && inventoryGroup.filter((accordion) =>
    accordion.group_name.toLowerCase().includes(searchText)
  );

  const filteredItems = inventoryItems?.filter((value) =>
  value.item_name.toLowerCase().includes(searchText)
  );

  const filteredGroupsWithItems = inventoryGroup && inventoryGroup?.filter((group) =>
    filteredItems?.some((item) => item.group_id == group.group_id)
  );

  const SaveInventory = ()=>{
    var postData = inventoryItems.map((res)=>{
      if(res.quantity >= 0){
        return res;
      }
    }).filter((element ) => {
      return element !== undefined;
   });
   var value = "";
    postData.map((res)=>{
      value += res.id+"="+res.quantity+"&"; 
    })
    if (value.endsWith("&")) {
      value = value.slice(0, -1);
    }
    postData = {
      "calc_data": value,
    }
    axios.post("admin.crm-leads.calculateTruckCbmValue"+job_id, postData).then((res)=>{
      if(res){
        toast.success("Inventory Items saved successfully");
        onChangeData()
      }
    })
  }

  const [colDisplayAll, setColDisplayAll] = useState(false);
  const expandAll =()=>{
    setColDisplayAll(!colDisplayAll)
  }
  function getData(){
    setCBM(data.truck_list[0].max_cbm)
    setDirectValue(data.truck_list[0].max_cbm*data.job_price_additional.goods_value_per_cbm)
    setData(data);
    setInventoryItems(data?.getInventoryItems);
    setMiscItems(data?.miscllanceous_items);
    setInventoryGroups(data?.inventory_groups);
    if(data){
      setTimeout(function () {
        setLoader(false);
    }, 2000);
    }
    // setInvoiceList(data?.invoice_items);
}

const generatepdf = () =>{
    const type ='Moving';
    const url = `api/generateInventoryPdf/${job_id}/${type}`;
    setLoader(true);
    axios.get(url).then((res)=>{
      if(res.error == 0){
        setLoader(false);
        toast.success(res.message);
      
      }
    })
  }
const downloadInventoryPdf = () =>{
  const url = `api/downloadInventoryPdf/${job_id}`;
    axios.get(url).then((res)=>{
        window.open(res.url, "_blank");
        toast.success(res.message);
    })
  }

const [contactToDelete, setContactToDelete] = useState(null);
  const handleContactDelete = (data) => {
    // Find the index of the item to be deleted
    const itemIndex = inventoryItems.findIndex((item) => item.id === data.id);

    if (itemIndex !== -1) {
      // Reset the quantity of the item to zero before deleting
      const updatedLegs = [...inventoryItems];
      updatedLegs[itemIndex].quantity = 0;
      setInventoryItems(updatedLegs);
    }

    // Proceed with the deletion
    setContactToDelete(data);
    // setDeleteModal(true);
  };


  const SaveInventory1 = ()=>{
    var postData = inventoryItems.map((res)=>{
      if(res.quantity >= 0){
        return res;
      }
    }).filter((element ) => {
      return element !== undefined;
   });
   var value = "";
    postData.map((res)=>{
      value += res.id+"="+res.quantity+"&"; 
    })
    if (value.endsWith("&")) {
      value = value.slice(0, -1);
    }
    postData = {
      "calc_data": value,
    }
    axios.post("api/save-inventory-data/"+job_id, postData).then((res)=>{
      if(res){
        toast.success("Inventory Items saved successfully");
        onChangeData()
      }
    })
  }

  const t_colDisplay = (data) => {
    if(colDisplay == data){
    setColDisplay();
    }else{
      setColDisplay(data);
    }
    setcol1(!col1);
    setcol2(false);
    setcol3(false);
  };

  const t_col2 = () => {
    setcol2(!col2);
    setcol1(false);
    setcol3(false);
  };

  const t_inventoryEdit = ()=>{
    if(isInventoryEdit){
    setInventoryEdit(false);
    }else{
      setInventoryEdit(true)
    }
  }

  const t_col3 = () => {
    setcol3(!col3);
    setcol1(false);
    setcol2(false);
  };
  const [blueCounter, setblueCounter] = useState("");
  function countUP(id, prev_data_attr) {
    id(prev_data_attr + 1);
  }

  function countDown(id, prev_data_attr) {
    id(prev_data_attr - 1);
  }
  const [rowsData, setRowsData] = useState([]);
  const addTableRows = () => {

    const rowsInput = {}

    setShowAddRow(true);
    setMiscItems([...miscItems, rowsInput])
  }

  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  }
  const handleChange = (index, evnt) => {

    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);
  }

  const invoiceEdit = () =>{
    var postData = {
      "job_id": job_id,
      "total_cbm": cbm,
      "goods_value": directvalue,
      "insurance_excess": insuranceexcess,
      "insurance_premium": insurancepremium
    }
    axios.post("/api/ajaxUpdateCbmManually", postData).then((res)=>{
      if(res.error == 0){
        toast.success(res.message);
        t_inventoryEdit();
        onChangeData();
      }
    })
  }
  const StoreMiscItems = () =>{
    var name = [];
    var cbm = [];
    var quantity = [];
    console.log(miscItems);
    miscItems.map((res)=>{
      console.log(res);
      name.push(res.misc_item_name);
      cbm.push(res.misc_item_cbm);
      quantity.push(res.quantity);
    })
    var postData = {
      "name": name,
      "cbm": cbm,
      "quantity": quantity
    }
    axios.post("api/save-inventory-miscellanceous-data/"+job_id, postData).then((res)=>{
      if(res.error == 0){
        toast.success(res.messgae);
        onChangeData();
      }
    })
  }

  const DeleteMiscItem = (id) =>{
    var postData = {
      "inv_id": id,
      "job_id": job_id
    }
    axios.post("/api/delete-inventory-miscllanceous-data", postData).then((res)=>{
      if(res.error == 0){
        toast.success(res.messgae);
        onChangeData();
      }
    })
  }
  console.log(trucksize)
  return (
    <Card>
          {isLoader && <><div
                    style={{
                        position: 'absolute',
                        // top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: 'rgba(255, 255, 255, 0.8)',
                        // display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 9999,
                    }}
                  >
                  <Loader />
                </div></>}
      <CardHeader>
        <Row>
          {!isInventoryEdit && <Col lg={8}>
            <div className='hstack gap-5'>
              <span><b>Cubic Volume: </b> {(postData?.job?.total_cbm > 0) ? postData?.job?.total_cbm : 0} <b>m3</b></span>
              <span><b>Goods Value: </b> {postData?.global?.currency_symbol} {parseFloat(postData?.job?.goods_value).toFixed(2)} </span>
              <span><b>Insurance Based on: </b> {postData?.job?.insurance_based_on} <b><i className='bx bx-pencil' onClick={t_inventoryEdit}/></b></span>
            </div>
          </Col>}
          {isInventoryEdit && <Col>
          <Row>
          <Col md={4}>
            Truck size to default value
            <select className='form-select' value={trucksize || postData?.job?.total_cbm}
             onChange={(e)=>{
              setTruckSize(e.target.value);
              setCBM(e.target.value)
              setDirectValue(e.target.value*postData.job_price_additional.goods_value_per_cbm)
            }}>
              <option disabled selected={postData?.job?.total_cbm == "" }>Choose a truck</option>
              {postData.truck_list.map((res)=>{
              return( <option key={res.id} value={res.max_cbm}>{res.truck_size_in_ton} T</option>)})}
            </select>
          </Col>
          <Col md={4}>
            or enter CBM to default value           
            <Input type="number" value={(cbm)} step={0.00} onChange={(e)=>{
              setCBM(e.target.value)
            }}/>
          </Col>
          <Col md={3}>
            or directly enter a value
            <Input type="number" value={(directvalue)} step={0.00} onChange={(e)=>{
              setDirectValue(e.target.value)
            }}/>
          </Col>
          </Row>
          <Row className='pb-2'>
          <Col md={3}>
            Insurance Excess
            <Input type="number" value={insuranceexcess || postData?.job?.insurance_excess} step={0.00} onChange={(e)=>{
              setInsuranceExcess(e.target.value)
            }}/>
          </Col>
          <Col md={4}>
            Insurance Premium
            <Input type="number" value={insurancepremium || postData?.job?.insurance_premium} step={0.00} onChange={(e)=>{
              setInsurancePremium(e.target.value)
            }}/>
          </Col>
          </Row>
            <Button className='btn btn-light' onClick={t_inventoryEdit}>
              Cancel
            </Button>
            <Button className='btn btn-success mx-1' onClick={()=> invoiceEdit()}>
              Update
            </Button>
          </Col>}
          <Col lg={4} style={{ paddingLeft: "0px" }}>
            <div className='hstack gap-2'>   
              <Button className='btn btn-light' onClick={()=>generatepdf()}> Generate Inventory PDF</Button>
              <Link
                to="#"
                className=" btn btn-sm btn-success fs-13 pt-1" onClick={()=> downloadInventoryPdf()}>
                <i className="bx bxs-download fs-20 p-1"></i>
              </Link>         
              <Button className='btn btn-teal' onClick={()=> SaveInventory1()}>Calculate</Button>        
            </div>
          </Col>
        </Row>

      </CardHeader>
      <CardBody>
        <div className='hstack gap-2'>

          <div className='mt-2'>
            <Input className='form-control' placeholder='Search for inventory' value={searchText}
        onChange={(e)=>handleSearch(e)}/>
          </div>
          <div className='justify-content-end'> <button className='border-0 btn btn-primary mt-2' onClick={expandAll}>{colDisplayAll ? 'Collapse all' : 'Expand all'}</button></div>
        </div>
        <Accordion id="default-accordion-example" className='mt-3'>
        {filteredGroupsWithItems?.map((res)=>{
          return(<AccordionItem key={res.id} value={res.group_id}>
          <h2 className="accordion-header" id="headingOne">
            <button
              className={classnames("accordion-button fw-semibold", { collapsed: !(colDisplay == res.id)})} type="button" onClick={() =>t_colDisplay(res.id)} style={{ cursor: "pointer", display: "unset" }} >
              <div className="d-flex justify-content-between align-items-center">
                <p>{res.group_name}</p>
                  <p className="float-right text-right">
                    {postData?.InventoryDetails.inventoryCalcCountbyGroupID.map((inv) =>
                      (inv.group_id == res.group_id) ? inv.count : ""
                    )}
                    <span className="text-muted"> Items selected {!(colDisplay == res.id) && <i className="ri-arrow-down-s-line"></i>} {(colDisplay == res.id) && <i className="ri-arrow-up-s-line"></i>} </span>
                  </p>
              </div>
            </button>
          </h2>
          <Collapse isOpen={colDisplayAll || colDisplay == res.id} className="accordion-collapse" id="collapseOne" >
          {filteredItems?.filter((value)=> value.group_id === res.group_id).map((value)=> {
            return(<div className="accordion-body justify-content-center" key={value.id}>
                      <Row className='d-flex align-items-center'>
                      <Col lg={5} className='text-end'>
<b>{value.item_name}</b></Col>
<Col lg={3} style={{ width: "16%"}}>
              <div className="input-step step-primary">
                <button
                  type="button"
                  className="minus"
                  onClick={() => {
                  const updatedLegs = [...inventoryItems];
                  const taskIndex = updatedLegs.findIndex((task) => task.id === value.id);
                  updatedLegs[taskIndex].quantity = updatedLegs[taskIndex].quantity-1;
                  setInventoryItems(updatedLegs);
                  countDown(setblueCounter, blueCounter);
                  }}
                >
                  -
                </button>
                <Input
                  type="number"
                  className="product-quantity"
                  value={value.quantity}
                  min="0"
                  max="100"
                  readOnly
                />
                <button
                  type="button"
                  className="plus"
                  onClick={() => {
                    const updatedLegs = [...inventoryItems];
                    const taskIndex = updatedLegs.findIndex((task) => task.id === value.id);
                    updatedLegs[taskIndex].quantity = isNaN(updatedLegs[taskIndex].quantity) ? 0 : updatedLegs[taskIndex].quantity;
                    updatedLegs[taskIndex].quantity = updatedLegs[taskIndex].quantity+1;
                    setInventoryItems(updatedLegs);
                    countUP(setblueCounter, blueCounter);
                  }}
                >
                  +
                </button>
              </div>
              </Col>
              <Col lg={3} classname="text-start">
              <Link
                to="#"
              >
                 <Button onClick={() => handleContactDelete(value)} className="brown-button">
                  <i className="bx bxs-trash fs-15 p-1"></i>
                </Button>
              </Link>
              </Col>
            </Row>
            </div>)})}
          </Collapse>
        </AccordionItem>)
        })}
        </Accordion>

        <Button className='btn btn-teal float-righ mt-3' onClick={()=> SaveInventory()}>Calculate</Button>
        <div className='mt-3'>
          <span className="badge badge-soft-success badge-border fs-14 mt-2">Miscellaneous Items</span>
        </div>
        <Table className='table-bordered mt-3'>
          <thead className='bg-soft-purple'>
            <tr>
              <th>Item Name</th>
              <th>CBM</th>
              <th>Quantity</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {miscItems?.map((res, index)=>{
              var value = index+1
            return(<tr key={res.id}>
              <td><Input placeholder={'item #'+parseInt(value)} value={res.misc_item_name} onChange={(e) => {
                  const updatedLegs = [...miscItems];
                  //const taskIndex = updatedLegs.findIndex((task) => task.id === res.id);
                  updatedLegs[index].misc_item_name = e.target.value;
                  setMiscItems(updatedLegs);
                  }} /></td>
              <td><Input placeholder='CBM' value={res.misc_item_cbm} onChange={(e) => {
                  const updatedLegs = [...miscItems];
                  //const taskIndex = updatedLegs.findIndex((task) => task.id === res.id);
                  updatedLegs[index].misc_item_cbm = e.target.value;
                  setMiscItems(updatedLegs);
                  }} /></td>
              <td><Input placeholder='quantity' value={res.quantity} onChange={(e) => {
                  const updatedLegs = [...miscItems];
                  //const taskIndex = updatedLegs.findIndex((task) => task.id === res.id);
                  updatedLegs[index].quantity = e.target.value;
                  setMiscItems(updatedLegs);
                  }}/></td>
              <td><Button className='btn btn-sm btn-soft-danger' onClick={() => DeleteMiscItem(res.id)}><i className="bx bxs-trash fs-12"></i></Button></td>
            </tr>)
            })}
          </tbody>
        </Table>
        <Button className="btn btn-brown" onClick={addTableRows} >+</Button>
      <div>
      <Button className='btn btn-teal mt-4' onClick={()=> StoreMiscItems()}>Calculate</Button>
      </div>
      </CardBody>
      <ToastContainer closeButton={false} limit={1} theme="light" />
    </Card>
  )
}