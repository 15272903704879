import { Card, CardBody, Col, Table, Row } from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';

export const RegionToRegionPricing = () => {
  document.title="Region To Region Pricing | Onexfort";
  const [data, setData] = useState([]);
  const [regions, setRegions] = useState([]);
  const [fromRegionId, setFromRegionId] = useState();
  const [toRegionId, setToRegionId] = useState();

  const [newFromRegionId, setNewFromRegionId] = useState();
  const [newToRegionId, setNewToRegionId] = useState();
  const loadTable = () => {
    axios.get("api/region-to-region-pricing")
      .then(res => {
        console.log(res);
        setData(res.regiontoregionpricings);
        setRegions(res.jobspricingregions);
        setNewFromRegionId(res.jobspricingregions[0].id)
        setNewToRegionId(res.jobspricingregions[0].id)
      })
      .catch(err => {
        console.log(err);
      })
  }
  useEffect(() => {
    loadTable();
  }, [])

  //rowsdata
  const [rowsData, setRowsData] = useState([]);

  const handleEdit = (index, item) => {
    console.log(item);
    setFromRegionId(item.from_region_id);
    setToRegionId(item.to_region_id);
    console.log(fromRegionId, toRegionId)
    setData((prevGroupNames) => {
      const updatedGroupNames = [...prevGroupNames];
      updatedGroupNames[index].isEditing = true;
      return updatedGroupNames;
    });
  }
  // input change
  const handleChange = (index, event, inputType) => {
    const { value } = event.target;
    if (inputType == 'mincbm') {
      setData((prevChecklistNames) => {
        const updatedChecklistNames = [...prevChecklistNames];
        updatedChecklistNames[index].cbm_min = value;
        return updatedChecklistNames;
      });
    }
    else if (inputType == 'maxcbm') {
      setData((prevChecklistNames) => {
        const updatedChecklistNames = [...prevChecklistNames];
        updatedChecklistNames[index].cbm_max = value;
        return updatedChecklistNames;
      });
    }
    else if (inputType == 'flatprice') {
      setData((prevChecklistNames) => {
        const updatedChecklistNames = [...prevChecklistNames];
        updatedChecklistNames[index].price_flat = value;
        return updatedChecklistNames;
      });
    }
    else if (inputType == 'pricepercbm') {
      setData((prevChecklistNames) => {
        const updatedChecklistNames = [...prevChecklistNames];
        updatedChecklistNames[index].price_per_cbm = value;
        return updatedChecklistNames;
      });
    }
    else if (inputType == 'minprice') {
      setData((prevChecklistNames) => {
        const updatedChecklistNames = [...prevChecklistNames];
        updatedChecklistNames[index].min_price = value;
        return updatedChecklistNames;
      });
    }
  }

  //edit-> save
  const handleSave = (index, item) => {
    let data = {
      'pricing_region_id': item.id,
      'from_region_id': fromRegionId,
      'to_region_id': toRegionId,
      'cbm_min': item.cbm_min,
      'cbm_max': item.cbm_max,
      'price_flat': item.price_flat,
      'price_per_cbm': item.price_per_cbm,
      'min_price': item.min_price,

    }
    axios.post("/api/ajaxUpdateRegionToRegionPricing", data)
      .then(res => {
        setData((prevGroupNames) => {
          const updatedGroupNames = [...prevGroupNames];
          updatedGroupNames[index].isEditing = false;
          return updatedGroupNames;
        });
        loadTable();
      })
      .catch(err => {
        loadTable();

      })
  }

  const handleDelete = (index, item) => {
    console.log(item.id)
    var postData = {
      "id": item.id,
    };
    axios.post('api/ajaxDestroyRegionToRegionPricing', postData)
      .then(res => {
        loadTable();
      })
      .catch(err => {
        console.log(err);

      })
  }

  //edit ->cancel
  const handleCancel = (index) => {
    setData((prevChecklistNames) => {
      const updatedChecklistNames = [...prevChecklistNames];
      updatedChecklistNames[index].isEditing = false;
      return updatedChecklistNames;
    });
    loadTable();
  }
  //rowsdata
  const addTableRows = () => {
    const rowsInput = {
      newFromRegionId: '',
      newToRegionId: '',
      suburbName: ''

    }
    setRowsData([...rowsData, rowsInput])
  }
  const [newCbmMin, setNewCbmMin] = useState();
  const [newCbmMax, setNewCbmMax] = useState();
  const [newFlatPrice, setNewFlatPrice] = useState();
  const [newCbmPrice, setNewCbmPrice] = useState();
  const [newMinPrice, setNewMinPrice] = useState();

  const saveTableRow = (index) => {
    let data = {
      "from_region_id": newFromRegionId,
      "to_region_id": newToRegionId,
      "cbm_min": newCbmMin,
      "cbm_max": newCbmMax,
      "price_flat": newFlatPrice,
      "price_per_cbm": newCbmPrice,
      "min_price": newMinPrice,
    }
    console.log(data)
    
    if (data.cbm_min===""||data.cbm_min===undefined){
      toast.error("Enter the From m3 Value",{theme:"light"});
    }
    else if (data.cbm_max===""||data.cbm_max===undefined){
      toast.error("Enter the To m3 Value",{theme:"light"});
    }
    else if (data.price_flat===""||data.price_flat===undefined){
      toast.error("Enter the Flat Price",{theme:"light"});
    }
    else if (data.price_per_cbm===""||data.price_per_cbm===undefined){
      toast.error("Enter the Price per m3",{theme:"light"});
    }
    else if (data.min_price===""||data.min_price===undefined){
      toast.error("Enter the 	Min Price",{theme:"light"});
    }
    else{
    axios.post("/api/ajaxCreateRegionToRegionPricing", data)
      .then(res => {
        const rows = [...rowsData];
        rows.splice(index, 1);
        setRowsData(rows);

      })
      .catch(err => {
        const rows = [...rowsData];
        rows.splice(index, 1);
        setRowsData(rows);
        loadTable();
        resetRow();
      })
    }
  }
  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
    resetRow();
  }

  const resetRow = () => {
    setNewCbmMin(null);
    setNewCbmMax(null);
    setNewFlatPrice(null);
    setNewCbmPrice(null);
    setNewMinPrice(null);
  }

  return (
    <div>
      <Card>
        <CardBody>
          <Row className="mb-3">
            <Col xl={12}>
              <div className="table-responsive mt-4 mt-xl-0">
                <Table className="table table-bordered table-nowrap align-middle mb-3">
                  <thead>
                    <tr>
                      <th scope="col">From Region</th>
                      <th scope="col">To Region</th>
                      <th scope="col">From m3 (cbm)</th>
                      <th scope="col">To m3 (cbm)</th>
                      <th scope="col">Flat Price</th>
                      <th scope="col">Price per m3 (cbm)</th>
                      <th scope="col">Min Price</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => (
                      <tr key={item.id}>
                        <td className="fw-medium">
                          {item.isEditing ? (
                            <select
                              value={fromRegionId}
                              onChange={(event) => setFromRegionId(event.target.value)}
                              className="form-select "
                              aria-label="Default select example"
                            >
                              {regions.map((res) => (
                                <option key={res.id} value={res.id}>
                                  {res.region_name}
                                </option>
                              ))}
                            </select>
                          ) : (
                            regions.map((res) =>
                              (res.id == item.from_region_id) ? res.region_name : ""
                            )
                          )}
                        </td>
                        <td className="fw-medium">
                          {item.isEditing ? (
                            <select
                              value={toRegionId}
                              onChange={(event) => setToRegionId(event.target.value)}
                              className="form-select "
                              aria-label="Default select example"
                            >
                              {regions.map((res) => (
                                <option key={res.id} value={res.id}>
                                  {res.region_name}
                                </option>
                              ))}
                            </select>
                          ) : (
                            regions.map((res) =>
                              (res.id == item.to_region_id) ? res.region_name : ""
                            )
                          )}
                        </td>
                        <td className="fw-medium">
                          {item.isEditing ? (
                            <input
                              type="text"
                              value={item.cbm_min}
                              onChange={(event) => handleChange(index, event, "mincbm")}
                              className="form-control"
                            />
                          ) : (
                            item.cbm_min
                          )}
                        </td>
                        <td className="fw-medium">
                          {item.isEditing ? (
                            <input
                              type="text"
                              value={item.cbm_max}
                              onChange={(event) => handleChange(index, event, "maxcbm")}
                              className="form-control"
                            />
                          ) : (
                            item.cbm_max
                          )}
                        </td>
                        <td className="fw-medium">
                          {item.isEditing ? (
                            <input
                              type="text"
                              value={item.price_flat}
                              onChange={(event) => handleChange(index, event, "flatprice")}
                              className="form-control"
                            />
                          ) : (
                            "$", item.price_flat
                          )}
                        </td>
                        <td className="fw-medium">
                          {item.isEditing ? (
                            <input
                              type="text"
                              value={item.price_per_cbm}
                              onChange={(event) => handleChange(index, event, "pricepercbm")}
                              className="form-control"
                            />
                          ) : (
                            item.price_per_cbm
                          )}
                        </td>
                        <td className="fw-medium">
                          {item.isEditing ? (
                            <input
                              type="text"
                              value={item.min_price}
                              onChange={(event) => handleChange(index, event, "minprice")}
                              className="form-control"
                            />
                          ) : (
                            item.min_price
                          )}
                        </td>
                        <td>
                          <div className="hstack gap-3 flex-wrap">
                            {item.isEditing ? (
                              <>
                                <button
                                  className="btn btn-success"
                                  onClick={() => handleSave(index, item)}
                                >
                                  Save
                                </button>
                                <button
                                  className="btn btn-primary"
                                  onClick={() => handleCancel(index)}
                                >
                                  Cancel
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  className="btn btn-sm btn-soft-info edit-list "
                                  onClick={() => handleEdit(index, item)}
                                >
                                  <i className="bx bxs-pencil fs-12 pt-1"></i>
                                </button>
                                <button
                                  className="btn btn-sm btn-soft-danger  "
                                  onClick={() => handleDelete(index, item)}
                                >
                                  <i className="ri-delete-bin-5-fill fs-16"></i>
                                </button>
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                    {rowsData.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <select
                            value={newFromRegionId}
                            onChange={(event) => setNewFromRegionId(event.target.value)}
                            className="form-select "
                            aria-label="Default select example"
                          >
                            {regions.map((res) => (
                              <option key={res.id} value={res.id}>
                                {res.region_name}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td>
                          <tr>
                            <select
                              value={newToRegionId}
                              onChange={(event) => setNewToRegionId(event.target.value)}
                              className="form-select "
                              aria-label="Default select example"
                            >
                              {regions.map((res) => (
                                <option key={res.id} value={res.id}>
                                  {res.region_name}
                                </option>
                              ))}
                            </select>
                          </tr>
                        </td>
                        <td>
                          <input
                            type="number"
                            value={newCbmMin}
                            onChange={(event) => setNewCbmMin(event.target.value)}
                            name="regionName"
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            type="number"              
                            value={newCbmMax}
                            onChange={(event) => setNewCbmMax(event.target.value)}
                            name="suburbName"
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            value={newFlatPrice}
                            onChange={(event) => setNewFlatPrice(event.target.value)}
                            name="suburbName"
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            value={newCbmPrice}
                            onChange={(event) => setNewCbmPrice(event.target.value)}
                            name="suburbName"
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            value={newMinPrice}
                            onChange={(event) => setNewMinPrice(event.target.value)}
                            name="suburbName"
                            className="form-control"
                          />
                        </td>

                        <td>
                          <div className="hstack gap-2">
                            <button className="btn btn-success" onClick={() => saveTableRow(index)}
                            >
                              Save
                            </button>
                            <button className="btn btn-primary" onClick={() => deleteTableRows(index, "table1")}
                            >
                              Cancel
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}

                  </tbody>
                </Table>
                <button className="btn btn-primary"
                  onClick={addTableRows}
                >+</button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <ToastContainer closeButton={false} limit={1} />
    </div>
  )
}
