import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
    Card, CardBody, CardHeader, Col, Container, Row, Label, Input, Accordion, AccordionItem, Collapse
} from 'reactstrap';
// import Components
import BreadCrumb from '../../Components/Common/BreadCrumb';
import * as moment from "moment";
import { Link } from "react-router-dom";
import Select from "react-select";
import classnames from "classnames";
import Flatpickr from "react-flatpickr";
import TableContainer from "../../Components/Common/TableContainer";
import DeleteModal from "../../Components/Common/DeleteModal";
import { isEmpty } from "lodash";
import { get_cookie } from '../../helpers/get_cookie';
import axios from 'axios';
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import actions
import {
    getOrders as onGetOrders,
    addNewOrder as onAddNewOrder,
    updateOrder as onUpdateOrder,
    deleteOrder as onDeleteOrder,
} from "../../store/ecommerce/action";

import Loader from "../../Components/Common/Loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Invoices = () => {
    const [col1, setcol1] = useState(false);
    const t_col1 = () => {
        setcol1(!col1);

    };

    const [invoiceStatus, setInvoiceStatus] = useState(null);

    function handleInvoiceStatus(selected) {
        setInvoiceStatus(selected.value);
    }
    console.log(invoiceStatus);
    const jobOpt = [
        { value: "New", label: "New" },
        { value: "Confirmed", label: "Confirmed" },
        { value: "Quoted", label: "Quoted" },
        { value: "Operations", label: "Operations" },
        { value: "Deleted", label: "Deleted" },
        { value: "On Hold", label: "On Hold" }
    ];
    const invoiceOpt = [
        { value: "All", label: "All" },
        { value: "Unpaid", label: "Unpaid" },
        { value: "Paid", label: "Paid" },
        { value: "Partial", label: "Partial" }
    ]

    const [modal, setModal] = useState(false);
    const dispatch = useDispatch();

    const [order, setOrder] = useState([]);
    const [isEdit, setIsEdit] = useState(false);

    const [data, setData] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const orders = data;
    //Created start date and end date
    const [createdDateStart, setCreatedDateStart] = useState(null);
    const [createdDateEnd, setCreatedDateEnd] = useState(null);
    const [jobStatus, setJobStatus] = useState([]);
    const [selectedJob, setselectedJob] = useState(null);
    const [optype, setOpType] = useState();
    const [org_date, setOrg_Date] = useState([]);


    //fetching data table api 
    const user = JSON.parse(get_cookie("authUser"));
    var tenant_id = user.tenant_id;
    useEffect(() => {
        setisLoading(true);
        axios.get('/api/all-invoices/data', {
            params: {
                job_status: null,
                status: 'unpaid',
                startDate: null,
                endDate: null
            }
        })
            .then((res) => {
                setOrg_Date(res.data[0]);
                setData(res.data);
                // setCreatedDateEnd(moment().format(res?.organisation.date_picker));
                // setCreatedDateStart(moment().subtract(1, 'months').format(res.organisation_settings?.date_picker_format));
                setCreatedDateEnd(moment().format(res?.data[0]?.organisation_date_picker));
                setCreatedDateStart(moment().subtract(1, 'months').format(res?.data[0]?.organisation_date_picker));
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setisLoading(false);
            });
        // axios.get("/api/all-invoices/index", tenant_id)

        // .then((res) => {
        //     setJobStatus(res.job_status);
        //     if (res.op_type && res.op_type.length > 0) {
        //         setOpType(res.op_type[0].options);
        //       }
        //     // setData(res.data);
        // })
        // .catch((err) => {
        //     console.log(err);
        // })
        // .finally(() => {

        // })
        // setisLoading(false);
    }, []
    )
    const [selectedPayment, setselectedPayment] = useState(null);

    const $payment_status_array = {
        all: 'All',
        unpaid: 'Unpaid',
        paid: 'Paid',
        partial: 'Partial'
    };
    const paymentStatusOptions = Object.keys($payment_status_array).map((status) => ({
        value: status,
        label: $payment_status_array[status]
    }));
    const handlePaymentMulti = (selectedOptions) => {
        setselectedPayment(selectedOptions);
    };
    useEffect(() => {
        const defaultSelectedOptions = jobStatus
            .filter(status => status.options === 'Completed')
            .map((status, index) => ({
                value: index,
                label: status.options
            }));

        setselectedJob(defaultSelectedOptions);
    }, [jobStatus]);
    function handleJobMulti(selectedOptions) {
        setselectedJob(selectedOptions);
    }
    let apiEndpoint = "/api/all-invoices/data";
    //Filter
    const loadTable = (event) => {
        console.log("1st cr date" + setCreatedDateStart + "1st cr end" + setCreatedDateEnd + "status" + setInvoiceStatus)
        event.preventDefault();
        const selectedDateFormat = org_date?.date_format;
        setisLoading(true);
        apiEndpoint += `?status=${invoiceStatus}&startDate=${createdDateStart}&endDate=${createdDateEnd}`
        // apiEndpoint += `?status=${pay}&job_status=${ss}&startDate=${formattedcreatedDateStart}&endDate=${formattedcreatedDateEnd}`
        axios.get(apiEndpoint)
            .then((res) => {
                setData(res.data);

            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setisLoading(false);
            })
    }

    useEffect(() => {
    }, [createdDateStart, createdDateEnd]);

    //reset table
    const resetTable = () => {
        setisLoading(true);

        apiEndpoint += `?tenant_id=${tenant_id}`;
        axios
            .get(apiEndpoint)
            .then((res) => {
                setData(res.data);

            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setisLoading(false);
            })
        setInvoiceStatus("");
        setCreatedDateStart(null);
        setCreatedDateEnd(null);
        setselectedJob([])
    }


    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            orderId: (order && order.orderId) || '',
            customer: (order && order.customer) || '',
            product: (order && order.product) || '',
            orderDate: (order && order.orderDate) || '',
            // ordertime: (order && order.ordertime) || '',
            amount: (order && order.amount) || '',
            payment: (order && order.payment) || '',
            status: (order && order.status) || '',
        },
        validationSchema: Yup.object({
            orderId: Yup.string().required("Please Enter order Id"),
            customer: Yup.string().required("Please Enter Customer Name"),
            product: Yup.string().required("Please Enter Product Name"),
            // orderDate: Yup.string().required("Please Enter Order Date"),
            // ordertime: Yup.string().required("Please Enter Order Time"),
            amount: Yup.string().required("Please Enter Total Amount"),
            payment: Yup.string().required("Please Enter Payment Method"),
            status: Yup.string().required("Please Enter Delivery Status")
        }),
        onSubmit: (values) => {
            if (isEdit) {
                const updateOrder = {
                    _id: order ? order._id : 0,
                    orderId: values.orderId,
                    customer: values.customer,
                    product: values.product,
                    orderDate: date,
                    // ordertime: values.ordertime,
                    amount: values.amount,
                    payment: values.payment,
                    status: values.status
                };
                // update order
                dispatch(onUpdateOrder(updateOrder));
                validation.resetForm();
            } else {
                const newOrder = {
                    _id: Math.floor(Math.random() * (30 - 20)) + 20,
                    orderId: values["orderId"],
                    customer: values["customer"],
                    product: values["product"],
                    orderDate: date,
                    // ordertime: values["ordertime"],
                    amount: values["amount"],
                    payment: values["payment"],
                    status: values["status"]
                };
                // save new order
                dispatch(onAddNewOrder(newOrder));
                validation.resetForm();
            }
            toggle();
        },
    });

    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
            setOrder(null);
        } else {
            setModal(true);
            setDate(defaultdate());
        }
    }, [modal]);

    const handleOrderClicks = () => {
        setOrder("");
        setIsEdit(false);
        toggle();
    };

    const handleOrderClick = useCallback((arg) => {
        const order = arg;
        setOrder({
            _id: order._id,
            orderId: order.orderId,
            customer: order.customer,
            product: order.product,
            orderDate: order.orderDate,
            ordertime: order.ordertime,
            amount: order.amount,
            payment: order.payment,
            status: order.status
        });

        setIsEdit(true);
        toggle();
    }, [toggle]);


    // Checked All
    const checkedAll = useCallback(() => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".orderCheckBox");
        if (checkall.checked) {
            ele.forEach((ele) => {
                ele.checked = true;
            });
        } else {
            ele.forEach((ele) => {
                ele.checked = false;
            });
        }
        deleteCheckbox();
    }, []);

    // Delete Multiple
    const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
    // data?.sort((a, b) => a.invoice_number - b.invoice_number);



    const deleteCheckbox = () => {
        const ele = document.querySelectorAll(".orderCheckBox:checked");
        ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
        setSelectedCheckBoxDelete(ele);
    };

    console.log(data, "data34556");
    // Customber Column
    const columns = useMemo(
        () => [
            {
                Header: <input type="checkbox" id="checkBoxAll" className="form-check-input" onClick={() => checkedAll()} />,
                Cell: (cellProps) => {
                    return <input type="checkbox" className="orderCheckBox form-check-input" value={cellProps.row.original._id} onChange={() => deleteCheckbox()} />;
                },
                id: '#',
            },
            {
                Header: "Invoice#",
                accessor: "invoice_number",
                filterable: false,
                Cell: (cell) => {
                    return <Link to={`/apps-ecommerce-order-details/${cell.value}`} className="fw-medium link-primary">{cell.value}</Link>;
                },
            },
            {
                Header: "System Job Type",
                accessor: "sys_job_type",
                filterable: false,
            },
            {
                Header: "Job No",
                accessor: "job_number",
                filterable: false,
            },
            {
                Header: "Customer",
                accessor: "name",
                filterable: false,
            },
            {
                Header: "Due Date",
                accessor: "due_date",
            },
            {
                Header: "Issue Date",
                accessor: "issue_date",

            },
            {
                Header: "Total",
                accessor: "total",
                filterable: false,
            },

            {
                Header: "Payment Received",
                accessor: "payment_recieved",
                filterable: false,
            },

            {
                Header: 'Lead Status',
                accessor: 'status',
                Cell: (cell) => {
                    return <div dangerouslySetInnerHTML={{ __html: cell.value }} />

                    // switch (cell.value) {
                    //     case "Pending":
                    //         return <span className="badge text-uppercase badge-soft-warning"> {cell.value} </span>;
                    //     case "Cancelled":
                    //         return <span className="badge text-uppercase badge-soft-danger"> {cell.value} </span>;
                    //     case "Inprogress":
                    //         return <span className="badge text-uppercase badge-soft-secondary"> {cell.value} </span>;
                    //     case "Pickups":
                    //         return <span className="badge text-uppercase badge-soft-info"> {cell.value} </span>;
                    //     case "Returns":
                    //         return <span className="badge text-uppercase badge-soft-primary"> {cell.value} </span>;
                    //     case "Delivered":
                    //         return <span className="badge text-uppercase badge-soft-success"> {cell.value} </span>;
                    //     default:
                    //         return <span className="badge text-uppercase badge-soft-warning"> {cell.value} </span>;
                    // }
                }
            },


        ],
        [handleOrderClick, checkedAll]
    );

    const defaultdate = () => {
        let d = new Date(),
            months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let h = (d.getHours() % 12) || 12;
        let ampm = d.getHours() < 12 ? "AM" : "PM";
        return ((d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear() + ", " + h + ":" + d.getMinutes() + " " + ampm).toString());
    };

    const [selectedJobs, setSelectedJobs] = useState([]);

    const handleDateChange = (selectedDates) => {
        if (selectedDates.length === 2) {
            setCreatedDateStart(selectedDates[0]);
            setCreatedDateEnd(selectedDates[1]);
        } else {
            setCreatedDateStart(null)
        }
    };
    const [date, setDate] = useState(defaultdate());

    document.title = "Invoices | Onexfort";
    console.log(org_date?.organisation_date_format, "org_date?.organisation_date_format");
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Invoices" pageTitle="Onexfort" />
                    <Accordion id="default-accordion-example">
                        <AccordionItem>
                            <h2 className="accordion-header" id="headingOne">
                                <button
                                    className={classnames("accordion-button fw-semibold", { collapsed: !col1 })} type="button" onClick={t_col1} style={{ cursor: "pointer" }} >
                                    Search Filters
                                </button>
                            </h2>
                            <Collapse isOpen={col1} className="accordion-collapse" id="collapseOne" >
                                <div className="accordion-body">
                                    <Row>
                                        <Col md={4}>
                                            <div className="mb-3">
                                                <Label htmlFor="choices-multiple-default" className="form-label text-muted">Job Status</Label>
                                                {/* <Select
                                                    isMulti={true}
                                                    options={jobOpt}
                                                    value={selectedJobs}
                                                    onChange={setSelectedJobs}
                                                /> */}
                                                <Select
                                                    value={selectedPayment}
                                                    isMulti={true}
                                                    onChange={handlePaymentMulti}
                                                    options={paymentStatusOptions}
                                                />
                                            </div>
                                        </Col>

                                        <Col md={4}>
                                            <div className="mb-3">
                                                <Label htmlFor="choices-multiple-default" className="form-label text-muted">Invoice Status</Label>
                                                <Select
                                                    value={invoiceStatus}
                                                    onChange={handleInvoiceStatus}
                                                    options={invoiceOpt}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Label for="createdDateinput" className="form-label">Issued Date</Label>
                                        <Col lg={4}>
                                            <div className="mb-3">
                                                <Label for="createdDateinput" className="form-label">Created Date</Label>
                                                <Flatpickr
                                                    className="form-control" placeholder='Start Date'
                                                    options={{
                                                        dateFormat: org_date?.organisation_date_format,
                                                    }}
                                                    value={createdDateStart}
                                                    onChange={(selectedDates) => {
                                                        if (selectedDates.length === 1) {
                                                            setCreatedDateStart(moment(selectedDates[0]).format(org_date?.organisation_date_picker));
                                                        } else {
                                                            setCreatedDateStart(null);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="mb-3">
                                                <Label for="createdDateinput" className="form-label">End Date</Label>
                                                <Flatpickr
                                                    className="form-control" placeholder='End Date'
                                                    options={{
                                                        dateFormat: org_date?.organisation_date_format,
                                                    }}
                                                    value={createdDateEnd}
                                                    onChange={(selectedDates) => {
                                                        if (selectedDates.length === 1) {
                                                            setCreatedDateEnd(moment(selectedDates[0]).format(org_date?.organisation_date_picker));
                                                        } else {
                                                            setCreatedDateEnd(null);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="text-start">
                                                <button type="submit" className="btn  btn-primary"
                                                    onClick={loadTable}>Apply</button>
                                                <button type="submit" className="btn form-btn-marg btn-primary"
                                                    onClick={resetTable}>Reset</button>
                                            </div>

                                        </Col>
                                    </Row>
                                </div>
                            </Collapse>
                        </AccordionItem>
                    </Accordion>
                    <Card>
                        <CardBody>
                            <div>

                                <TableContainer
                                    columns={columns}
                                    data={(data || [])}
                                    isGlobalFilter={true}
                                    isAddUserList={false}
                                    customPageSize={8}
                                    divClass="table-responsive table-card mb-1"
                                    tableClass="align-middle table-nowrap"
                                    theadClass="table-light text-muted text-uppercase"
                                    handleOrderClick={handleOrderClicks}
                                    isOrderFilter={true}
                                    SearchPlaceholder="Search for order ID, customer, order status or something..."
                                />


                            </div>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Invoices;
