import React, { useState, useEffect  } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader, Nav, NavItem, } from 'reactstrap';
import SettingsTabControl from './TabComponents/SettingsTabControl';
import SettingsTabContent from './TabComponents/SettingsTabContent';
import { SettingsTabPages } from './Constants/SettingsConstants';
import { useParams } from 'react-router-dom';

// import Components
import BreadCrumb from '../../Components/Common/BreadCrumb';
function Settings() {
  document.title="Settings | Onexfort";
  const params = useParams();
  const tab = params.activeTab;
  const [activeTab, setActiveTab] = useState(SettingsTabPages.OrganisationSetting);
  
  //Pagepersonalisation side Tab routes
  useEffect(() => {
    if(tab == 'organisation-settings'){
      setActiveTab(SettingsTabPages.OrganisationSetting);
    }else if(tab == 'companies'){
      setActiveTab(SettingsTabPages.Companies);
    }else if(tab == 'service-cities'){
      setActiveTab(SettingsTabPages.ServiceCities);
    }else if(tab == 'list-type-options'){
      setActiveTab(SettingsTabPages.ListTypeAndOptions);
    }else if(tab == 'profile-settings'){
      setActiveTab(SettingsTabPages.ProfileSetting);
    }else if(tab == 'offline-payment-setting'){
      setActiveTab(SettingsTabPages.PaymentCredentials);
    }else if(tab == 'sms-credits'){
      setActiveTab(SettingsTabPages.BuySmsCredits);
    }else if(tab == 'connect-stripe'){
      setActiveTab(SettingsTabPages.ConnectStripe);
    }else if(tab == 'connect-xero'){
      setActiveTab(SettingsTabPages.ConnectXero);
    }else if(tab == 'connect-myob'){
      setActiveTab(SettingsTabPages.ConnectMyob);
    }else if(tab == 'configure-email'){
      setActiveTab(SettingsTabPages.ConfigureEmail);
    }else if(tab == 'coverfreight'){
      setActiveTab(SettingsTabPages.ConnectCoverfright);
    }else if(tab == 'pagepersonalisation'){
      setActiveTab(SettingsTabPages.PagePersonalisation);
    }
  },[tab])
 
  //Pagepersonalsation Headings
    const getPageTitle = () => {
        switch (activeTab) {
          case SettingsTabPages.OrganisationSetting:
            return 'Organisation Setting';
          case SettingsTabPages.Companies:
            return 'Companies';
          case SettingsTabPages.ServiceCities:
            return 'Servicing Cities';
          case SettingsTabPages.ListTypeAndOptions:
            return 'List Type and Options';
          case SettingsTabPages.ProfileSetting:
            return 'Profile Settings';
          case SettingsTabPages.PaymentCredentials:
            return 'Payment Credentials';
          case SettingsTabPages.BuySmsCredits:
            return 'Buy SMS Credits';
          case SettingsTabPages.ConnectStripe:
            return 'Connect Stripe';
          case SettingsTabPages.ConnectXero:
            return 'Connect Xero';
          case SettingsTabPages.ConnectMyob:
            return 'Connect MYOB';
          case SettingsTabPages.ConfigureEmail:
            return 'Configure Email';
          case SettingsTabPages.ConnectCoverfright:
            return 'Connect Coverfright';
          case SettingsTabPages.PagePersonalisation:
            return 'Page Personalisation';
          default:
            return '';
        }
      };

    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title={getPageTitle()} pageTitle="" />
                <Row>
                    <Col md="3">
                        <SettingsTabControl activeTabSetter={setActiveTab} activeTab={activeTab} />
                    </Col>
                    <Col md="9">
                        <SettingsTabContent activeTab={activeTab} />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default Settings
