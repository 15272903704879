import React, { useState, useEffect, useCallback } from 'react'
import { Accordion, AccordionItem, Button, Card, CardBody, CardHeader, Col, Collapse, Table, Form, Label, Input, Container, Row, Modal, ModalHeader, ModalBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import { Link } from "react-router-dom";
import TableRows from "./TableRow";
import Flatpickr from "react-flatpickr";
import moment from 'moment';
import { useParams } from "react-router-dom";
import axios from 'axios';
import CustomDeleteModal from '../../../Components/Common/CustomDeleteModal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { api } from "../../../config";
import Loader from "../../../Components/Common/Loader";
import GoogleAutocomplete from 'react-google-autocomplete';

export const Operations = ({ data, onChangeData }) => {

    const params = useParams();
    const job_id = params.id;
    var cSignature = 0;

    const [modal, setModal] = useState(false);
    const [deletemodal, setDeleteModal] = useState(false);
    const [confirmmodal, setConfirmModal] = useState(false);
    const [notifyModal, setNotifyModal] = useState(false);
    const [jobLegs, setJobLegs] = useState([]);
    const [reassigndriver, setReassignDriver] = useState('');
    const [postData, setData] = useState([]);
    const [selectedLegid, setSelectedLeg] = useState('');
    const [expandedLegId, setExpandedLegId] = useState([]);
    const [expandedOffsideId, setOffsiderExpanded] = useState([]);
    const [tempoffsiderId, setTempoffsiderId] = useState([]);
    const [newlegdate, setNewLegDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [newlegstarttime, setNewStartTime] = useState();
    const [newlegendtime, setNewFinishTime] = useState();
    const pickupSuburb = data?.job?.pickup_suburb || '';
    const pickupAddress = data?.job?.pickup_address || '';
    const [newpickup, setNewPickUp] = useState(`${pickupSuburb} ${pickupAddress}`);
    const deliverySuburb = data?.job?.delivery_suburb || '';
    const dropOffAddress = data?.job?.drop_off_address || '';
    const [newdropoff, setNewDropOff] = useState(`${deliverySuburb} ${dropOffAddress}`);
    const [newnotes, setNewNotes] = useState();
    const [newdriverid, setNewDriverId] = useState();
    const [newvehicleid, setNewVehicleId] = useState();
    const [newstatus, setNewStatus] = useState();
    const [ismultipletrips, setIsMultipleTrips] = useState("0");
    const [deleteLegId, setDeleteLegId] = useState("0");
    const [newoffsider, setNewOffsider] = useState("");
    const [addnewoffsider, AddNewOffsider] = useState("");
    const [tripEdit, setTripEdit] = useState("");
    const [addnewTrip, setAddNewTrip] = useState(false);
    const [newtrippickup, setNewTripPickup] = useState(false);
    const [newtripdropoff, setNewTripDropOff] = useState(false);
    const [newtripnotes, setNewTripNotes] = useState(false);
    const [deletetripmodal, setDeleteTripModal] = useState(false);
    const [deletetripid, setDeleteTripId] = useState(false);
    const [selectedIssueId, setSelectedIssueId] = useState(false);
    const [AddnewIssues, setAddNewIssues] = useState(false);
    const [selectedReturnId, setSelectedReturnId] = useState(false);
    const [AddNewReturn, setAddNewReturns] = useState(false);
    const [NewIssues, setNewIssues] = useState([]);
    const [NewReturns, setNewReturns] = useState([]);
    const [deleteissuesmodal, setDeleteIssuesModal] = useState(false);
    const [deletereturnmodal, setDeleteReturnModal] = useState(false);
    const [deleteissuesId, setDeleteIssuesId] = useState(false);
    const [deletereturnId, setDeleteReturnId] = useState(false);
    const [isLoader, setLoader] = useState(true)
    const [org_date, setOrg_Date] = useState([]);

    useEffect(() => {
        getData();
    }, [data])

    function getData() {
        setJobLegs(data?.job_legs);
        setOrg_Date(data?.organisation_settings);

        setData(data);
        if (data) {
            setTimeout(function () {
                setLoader(false);
            }, 2000);
        }
    }

    //rowsdata
    const [rowsData, setRowsData] = useState([]);
    const [coll1, setcoll1] = useState(false);

    const t_EditLegs = (data) => {
        setExpandedLegId(data);
    }

    const t_EditOffside = (data) => {
        setTempoffsiderId();
        setOffsiderExpanded(data);
    }

    const t_deleteLeg = (data) => {
        if (deletemodal) {
            setDeleteModal(false);
        } else {
            setDeleteModal(true);
            setDeleteLegId(data);
        }
    }

    const t_deleteTrip = (data) => {
        if (deletetripmodal) {
            setDeleteTripModal(false);
        } else {
            setDeleteTripModal(true);
            setDeleteTripId(data);
        }
    }

    const t_deleteIssues = (data) => {
        if (deleteissuesmodal) {
            setDeleteIssuesModal(false);
        } else {
            setDeleteIssuesModal(true);
            setDeleteIssuesId(data);
        }
    }

    const t_deleteReturn = (data) => {
        if (deletereturnmodal) {
            setDeleteReturnModal(false);
        } else {
            setDeleteReturnModal(true);
            setDeleteReturnId(data);
        }
    }

    const t_coll1 = () => {
        setcoll1(!coll1);
    };

    const t_TripEdit = (data) => {
        if (tripEdit) {
            setTripEdit();
        } else {
            setTripEdit(data);
        }
    }

    const t_addNewTrip = () => {
        if (addnewTrip) {
            setAddNewTrip(false);
        } else {
            setAddNewTrip(true);
        }
    }

    const addTableRows = () => {
        const rowsInput = {
            fullName: '',
            emailAddress: '',
            salary: ''
        }
        setRowsData([...rowsData, rowsInput])

    }

    const Reassign_driver = useCallback((value) => {
        if (postData?.drivers?.length > 0) {
            setReassignDriver(postData?.drivers[0].id)
        }
        setSelectedLeg(value);
        if (modal) {
            setModal(false);
        } else {
            setModal(true);
        }
    }, [modal]);



    const updateinvoicepdf=()=>{

        setLoader(true);
    let req = {
      "job_id": job_id,
    }
    axios.post("api/ajaxUpdateAndGenerateInvoice", req)
      .then(res => {
        console.log(res);
        if (res.error === 0) {
          toast.success(res.message);
        }
        else {
          toast.error(res.message);
        }
        setLoader(false);
      })
      .catch(err => {
        console.log(err);
      })
    }



    const SaveOffsider = (leg_id) => {
        var postData = {
            "job_id": job_id,
            "leg_id": leg_id,
            "offsider_id": newoffsider
        }
        if (postData.offsider_id === "" || postData.offsider_id === undefined) {
            toast.error("Select the Name", { theme: "light" });
        }
        else {
            axios.post("/api/ajaxSaveJobOperationOffsider", postData).then((res) => {
                if (res.error == 0) {
                    toast.success(res.message);
                    onChangeData();
                } else {
                    toast.error("Something went wrong");
                }
            })
        }
    }

    const EditOffsider = (data) => {
        console.log(data);
        var postData = {
            "leg_id": data.leg_id,
            "offsider_table_id": data.id,
            "update_offsider_id": data.people_id,
            "job_id": job_id
        }
        axios.post("/api/ajaxUpdateJobOperationOffsider", postData).then((res) => {
            if (res.error == 0) {
                onChangeData();
                toast.success(res.message);
            } else {
                toast.error("Something went wrong");
            }
            t_EditOffside();
        })
    }

    const DeleteOffsider = (data) => {
        console.log(data);
        var postData = {
            "leg_id": data.leg_id,
            "offsider_table_id": data.id,
            "job_id": job_id,
        }
        axios.post("/api/ajaxDestroyJobOperationOffsider", postData).then((res) => {
            if (res.error == 0) {
                toast.success(res.message);
                onChangeData();
            } else {
                toast.error("Something went wrong");
            }
        })
    }

    const deleteOperationLeg = () => {
        var postData = {
            "job_id": job_id,
            "id": deleteLegId
        }
        axios.post("api/ajaxDestroyJobOperation", postData).then((res) => {
            if (res.error == 0) {
                toast.success(res.message);
                onChangeData();
            } else {
                toast.error("Something went wrong");
            }
            t_deleteLeg();
        })
    }

    const UpdateLeg = (data) => {
        var postData = {
            "id": data.id,
            "job_id": job_id,
            "pickup": data.pickup_address,
            "dropoff": data.drop_off_address,
            "leg_status": data.leg_status,
            "leg_date": data.leg_date,
            "est_start_time": data.est_start_time,
            "est_finish_time": data.est_finish_time,
            "driver_id": data.driver_id,
            "vehicle_id": data.vehicle_id,
            "has_multiple_trips": data.has_multiple_trips,
            "notes": data.notes
        }
        console.log(postData)
        if (postData.pickup === "" || postData.pickup === undefined) {
            toast.error("Enter the Pickup Address", { theme: "light" });
        }
        else if (postData.dropoff === "" || postData.dropoff === undefined) {
            toast.error("Enter the Delivery Address", { theme: "light" });
        }
        else {
            axios.post("/api/ajaxUpdateJobOperation", postData).then((res) => {
                getData();
                onChangeData();
                toast.success(res.message);
                t_EditLegs();
            })
        }
    }
    const [leg_id,setLegId]=useState([])
    const Notify_driver = (leg_id) => {
        setNotifyModal(true)
        setLegId(leg_id)
    }

    const Notify_offsider = (offData, legData) => {
        console.log(offData);
        console.log(legData);
        var postData = {
            "leg_id": legData.id,
            "offsider_team_id": offData.id,
            "offsider_people_id": offData.people_id,
            "job_id": legData.job_id
        }
        axios.post("/api/ajaxNotifyOffsider", postData).then((res) => {
            toast.success(res.message);
            onChangeData();
        })
    }

    const ReassignDriver = (data = null) => {
        if (data == "close") {
            setConfirmModal(false);
        } else {
            setConfirmModal(true);
            setModal(false)
        }
        if (data == "confirm") {
            var postData = {
                "job_id": job_id,
                "driver_id": reassigndriver,
                "id": selectedLegid
            }
            axios.post("/api/ajaxReassignDriver", postData).then((res) => {
                getData();
                onChangeData();
                setConfirmModal(false);
                toast.success(res.message);
            });
        }
    }
    const notifyDriver = (data = null) => {
        if (data == "close") {
            setNotifyModal(false);
        } else {
            setNotifyModal(true);
        }
        if (data == "confirm") {
            var postData = {
                "job_id": job_id,
                "id": leg_id
            }
            axios.post("/api/ajaxNotifyDriver", postData).then((res) => {
                getData();
                setNotifyModal(false)
                onChangeData();
                console.log(res);
            })
        }
    }

    const SaveNewLeg = () => {
        var postData = {
            "job_id": job_id,
            "pickup": newpickup,
            "dropoff": newdropoff,
            "leg_status": newstatus,
            "leg_date": newlegdate,
            "est_start_time": newlegstarttime,
            "est_finish_time": newlegendtime,
            "driver_id": newdriverid,
            "vehicle_id": newvehicleid,
            "has_multiple_trips": ismultipletrips,
            "notes": newnotes,
        }
        if (postData.pickup === "" || postData.pickup === undefined) {
            toast.error("Enter the Pickup Address", { theme: "light" });
        }
        else if (postData.dropoff === "" || postData.dropoff === undefined) {
            toast.error("Enter the Dropoff Address", { theme: "light" });
        }
        else {
            axios.post("/api/ajaxSaveJobOperation", postData).then((res) => {
                if (res.error == 0) {
                    toast.success(res.message);
                    onChangeData();
                    t_coll1();
                } else {
                    toast.error("Something went wrong");
                }
            })
        }
    }

    const UpdateTrip = (data) => {
        var postData = {
            "job_id": job_id,
            "id": data.id,
            "pickup": data.pickup_address,
            "dropoff": data.drop_off_address,
            "notes": data.trip_notes
        }
        axios.post("api/ajaxUpdateJobOperationTrip", postData).then((res) => {
            if (res.error == 0) {
                toast.success(res.message);
                onChangeData();
            } else {
                toast.error("Something went wrong");
            }
            t_TripEdit();
        })
    }



    const dropoffleg = (place) => {
        
        const addressComponents = place?.address_components;

        const suburbComponent = addressComponents?.find((component) =>
        component.types.includes("locality")
      );
      const stateComponent = addressComponents?.find((component) =>
      component.types.includes("administrative_area_level_1")
    );

    let selectedState = "";
    if (stateComponent) {
      selectedState = stateComponent.short_name; // Use short_name for state abbreviation
    }

      let selectedSuburb = "";
      if (suburbComponent) {
        selectedSuburb = suburbComponent.long_name;
      }
      setNewDropOff(`${selectedSuburb} ${selectedState}`);


      };


      
    const pickupleg = (place) => {
        
        const addressComponents = place?.address_components;

        const suburbComponent = addressComponents?.find((component) =>
        component.types.includes("locality")
      );
      const stateComponent = addressComponents?.find((component) =>
      component.types.includes("administrative_area_level_1")
    );

    let selectedState = "";
    if (stateComponent) {
      selectedState = stateComponent.short_name; // Use short_name for state abbreviation
    }

      let selectedSuburb = "";
      if (suburbComponent) {
        selectedSuburb = suburbComponent.long_name;
      }
        setNewPickUp(`${selectedSuburb} ${selectedState}`);


      };


    const AddNewTrip = (legid) => {
        var postData = {
            "job_id": job_id,
            "leg_id": legid,
            "pickup": newtrippickup,
            "dropoff": newtripdropoff,
            "notes": newtripnotes
        }
        console.log(postData)
        if (postData.pickup === "" || postData.pickup === undefined) {
            toast.error("Enter the Pickup Address", { theme: "light" });
        }
        else if (postData.dropoff === "" || postData.dropoff === undefined) {
            toast.error("Enter the Dropoff Address", { theme: "light" });
        }
        else {
            axios.post("api/ajaxSaveJobOperationTrip", postData).then((res) => {
                if (res.error == 0) {
                    toast.success(res.message);
                    onChangeData();
                } else {
                    toast.error("Something went wrong");
                }
                t_addNewTrip();
            })
        }
    }

    const DeleteTrip = (tripId) => {
        var postData = {
            "job_id": job_id,
            "id": deletetripid
        }
        axios.post("api/ajaxDestroyJobOperationTrip", postData).then((res) => {
            if (res.error == 0) {
                toast.success(res.message);
                onChangeData();

            } else {
                toast.error("Something went wrong");
            }
            t_deleteTrip();
        })
    }

    const t_IssuesEdit = (data) => {
        if (data) {
            setSelectedIssueId(data);
        } else {
            setSelectedIssueId();
        }
    }

    const t_ReturnEdit = (data) => {
        if (data) {
            setSelectedReturnId(data);
        } else {
            setSelectedReturnId();
        }
    }

    const SaveNewIssues = () => {
        var valData = {
            "job_id": job_id,
            "product_id": NewIssues.pid,
            "quantity": NewIssues.quantity,
            "type": NewIssues.type,
            "lead_id": postData.job.customer_id
        }
        console.log(valData)
        if (valData.product_id === null || valData.product_id === undefined) {
            toast.error("Enter the Item Name", { theme: "light" });
        }
        else {
            axios.post("/api/ajaxSaveMaterialIssue", valData).then((res) => {
                if (res.error == 0) {
                    toast.success(res.message);
                    onChangeData();
                    setNewIssues([]);
                } else {
                    toast.error("Something went wrong");
                }
                setNewIssues([]);
                setAddNewIssues([]);
            })
        }
    }

    const SaveNewReturns = () => {
        var valData = {
            "job_id": job_id,
            "product_id": NewReturns.pid,
            "quantity": NewReturns.quantity,
            "type": NewReturns.type,
            "lead_id": postData.job.customer_id
        }
        if (valData.product_id === null || valData.product_id === undefined) {
            toast.error("Enter the Item Name", { theme: "light" });
        }
        else {
            axios.post("/api/ajaxSaveMaterialReturn", valData).then((res) => {
                if (res.error == 0) {
                    toast.success(res.message);
                    onChangeData();
                    setNewReturns([]);
                } else {
                    toast.error("Something went wrong");
                }
                setNewReturns([])
                setAddNewReturns()
            })
        }
    }

    const UpdateIssues = (data) => {
        console.log("data");
        var postVal = {
            "product_id": data.item_id,
            "job_id": job_id,
            "quantity": data.quantity,
            "type": data.type,
            "lead_id": postData.job.customer_id,
            "id": data.id
        }
        axios.post("api/ajaxUpdateMaterialIssue", postVal).then((res) => {
            if (res.error == 0) {
                toast.success(res.message);
                onChangeData();

            } else {
                toast.error("Something went wrong");
            }
            t_IssuesEdit();
        })
    }

    const UpdateReturns = (data) => {
        var postVal = {
            "product_id": data.item_id,
            "job_id": job_id,
            "quantity": data.quantity,
            "type": data.type,
            "lead_id": postData.job.customer_id,
            "id": data.id
        }
        axios.post("api/ajaxUpdateMaterialReturn", postVal).then((res) => {
            if (res.error == 0) {
                toast.success(res.message);
                onChangeData();

            } else {
                toast.error("Something went wrong");
            }
            t_ReturnEdit();
        })
    }

    const DeleteIssues = (id) => {
        var valData = {
            "job_id": job_id,
            "id": deleteissuesId,
            "lead_id": postData.job.customer_id,
        }
        axios.post("api/ajaxDestroyMaterialIssue", valData).then((res) => {
            if (res.error == 0) {
                toast.success(res.message);
                onChangeData();
                t_deleteIssues();
            } else {
                toast.error("Something went wrong");
            }
        })
    }

    const DeleteReturns = (id) => {
        var valData = {
            "job_id": job_id,
            "id": deletereturnId,
            "lead_id": postData.job.customer_id,
        }
        axios.post("api/ajaxDestroyMaterialReturn", valData).then((res) => {
            if (res.error == 0) {
                toast.success(res.message);
                onChangeData();
                t_deleteReturn();
            } else {
                toast.error("Something went wrong");
            }
        })
    }

    const OpenAttach = (data) => {
        axios.get("/api/viewActivityAttachment/" + data).then((res) => {
            if (res.error == 0) {
                var publicSegment = '/public/';
                var redirectURL = res.url.substring(res.url.indexOf('/public/') + publicSegment.length);
                window.open(api.API_URL + redirectURL, "_blank");
            }
        })
    }
console.log(jobLegs, "jobLegs");
    return (
        <div>
            {isLoader && <><div
                style={{
                    position: 'absolute',
                    // top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'rgba(255, 255, 255, 0.8)',
                    // display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 9999,
                }}
            >
                <Loader />
            </div></>}
            <Card>
                {jobLegs && jobLegs.map((res) => {
                    var value = res.pickup_address;
                    var removaladdress = value.replace(/\//g, " ");
                    var value2 = res.drop_off_address;
                    var removal = value2?.replace(/\//g, " ");
                    return (
                        <>{(res.id != expandedLegId) && <Row className='m-3 border' key={res.id}>
                            <Col lg={6} className='border-end'>
                                <Row>
                                    <Col lg={2} className="p-1">
                                        <p className="p-2">
                                            <b>#{res.leg_number}</b>
                                        </p>
                                    </Col>
                                    <Col lg={10} className="p-2">

                                        <p className="mb-3">
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div>
                                                    {/* <b>{moment(res.leg_date).format(data.global.date_format)} &nbsp;</b> */}
                                                    <b>{moment(res.leg_date).format(org_date.date_picker_format)} &nbsp;</b>
                                                    <span><b>{moment(res.est_start_time, "HH:mm").format("HH:mm")} - {moment(res.est_finish_time, "HH:mm").format("HH:mm")}</b>
                                                    </span>
                                                </div>

                                               {removaladdress && removal && (
                                                <a href={`https://www.google.com/maps/dir/${removaladdress}/${removal}`} target="_blank">
                                                    <i className="ri-pin-distance-fill fs-24 float-right text-danger"></i>
                                                </a>
                                                )}
                                            </div>
                                        </p>
                                        <ul className='mb-3'>
                                            <li><a href={'https://www.google.com/maps/place/' + removaladdress} target='_blank'><i className="ri-map-pin-fill text-danger"></i>{res.pickup_address}</a></li>
                                            <li><a href={'https://www.google.com/maps/place/' + removal} target='_blank'><i className="ri-map-pin-fill text-danger"></i>{res.drop_off_address}</a></li>
                                        </ul>
                                        <div>
                                            <p>
                                                <b>Dispatch Note:</b>
                                                <p>{res.notes}</p>
                                            </p>
                                        </div>
                                    </Col>
                                </Row>

                            </Col>
                            <Col lg={6}>
                                <Row>
                                    <Col lg={6}>
                                        <Table className='table-borderless'>
                                            <tbody>
                                                <tr>
                                                    <td><b>Driver:</b></td>
                                                    <td>{postData.drivers?.map((val) => (
                                                        (res.driver_id == val.id) ? val.name : ""))}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><b>Vehicle:</b></td>
                                                    <td>{postData.vehicles?.map((val) => (
                                                        (res.vehicle_id == val.id) ? val.vehicle_name : ""))}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><b>Multiple Trips:</b></td>
                                                    <td>{(res.has_multiple_trips == "1") ? "Yes" : "No"}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                    <Col lg={6} >
                                        <div className='hstack gap-2 justify-content-end m-3'>
                                            <Button className="btn btn-sm btn-soft-info edit-list" onClick={() => t_EditLegs(res.id)}>
                                                <i className="bx bxs-pencil fs-12 pt-1"></i>
                                            </Button>
                                            <Button className=" btn btn-sm btn-soft-danger fs-13 pt-1" onClick={() => t_deleteLeg(res.id)}>
                                                <i className="bx bxs-trash fs-12"></i>
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='p-2'>
                                    <div className='hstack gap-3 '>
                                        <Button className='btn btn-light' onClick={() => Notify_driver(res.id)} disabled={res.leg_status != null && res.leg_status != "New"}>Notify Driver</Button>
                                        <Button className='btn btn-teal' onClick={() => Reassign_driver(res.id)}>(Re) Assign Driver</Button>
                                    </div>
                                </Row>
                                <Row className='p-2'>
                                    <div className='hstack gap-3 '>
                                        {res.leg_status == "Awaiting Confirmation" && <span className='text-danger'>{res.leg_status}</span>}
                                    </div>
                                </Row>
                            </Col>
                        </Row>}
                            {(res.id == expandedLegId) && <Row className='m-3 border' key={res.id}>
                                <Col lg={6} className='border-end'>
                                    <Row>
                                        <Col lg={2} className="p-1">
                                            <p className="p-2">
                                                <b>#{res.leg_number}</b>
                                            </p>
                                        </Col>
                                        <Col lg={3} className="p-2">
                                            <Flatpickr
                                                className="form-control"
                                                options={{
                                                    dateFormat: "Y-m-d",
                                                    defaultDate: [moment(res.leg_date).format("YYYY-MM-DD")]
                                                }} onChange={(selectedDates) => {
                                                    const updatedLegs = [...jobLegs];
                                                    const taskIndex = updatedLegs.findIndex((task) => task.id === res.id);
                                                    updatedLegs[taskIndex].leg_date = moment(selectedDates[0]).format('YYYY-MM-DD');
                                                    setJobLegs(updatedLegs)
                                                }} />
                                        </Col>
                                        <Col lg={3} className='p-2'>
                                            <Flatpickr
                                                className="form-control"
                                                options={{
                                                    enableTime: true,
                                                    noCalendar: true,
                                                    dateFormat: "H:i",
                                                }} value={res.est_start_time}
                                                onChange={(selectedDates) => {
                                                    const updatedLegs = [...jobLegs];
                                                    const taskIndex = updatedLegs.findIndex((task) => task.id === res.id);
                                                    updatedLegs[taskIndex].est_start_time = moment(selectedDates[0]).format('HH:mm:ss');
                                                    setJobLegs(updatedLegs)
                                                }} />
                                        </Col>
                                        <Col lg={3} className='p-2'>
                                            <Flatpickr
                                                className="form-control"
                                                options={{
                                                    enableTime: true,
                                                    noCalendar: true,
                                                    dateFormat: "H:i",
                                                }} value={res.est_finish_time} onChange={(selectedDates) => {
                                                    const updatedLegs = [...jobLegs];
                                                    const taskIndex = updatedLegs.findIndex((task) => task.id === res.id);
                                                    updatedLegs[taskIndex].est_finish_time = moment(selectedDates[0]).format('HH:mm:ss');
                                                    setJobLegs(updatedLegs)
                                                }} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={2} className="p-1">
                                        </Col>
                                        <Col lg={6} className='p-2'>
                                        <GoogleAutocomplete className='form-control'
                                                apiKey="AIzaSyB2SMtaVBlqC5v72gqS716BX8R5oXklaFc"
                                                value={res.pickup_address}
                                                onChange={(e) => {
                                                    const updatedLegs = [...jobLegs];
                                                    const taskIndex = updatedLegs.findIndex((task) => task.id === res.id);
                                                    updatedLegs[taskIndex].pickup_address = e.target.value;
                                                    setJobLegs(updatedLegs)
                                                }}
                                                onPlaceSelected={(place) => {
                                                    const selectedCity = place && place.formatted_address;
                                                    const updatedLegs = [...jobLegs];
                                                    const taskIndex = updatedLegs.findIndex((task) => task.id === res.id);
                                                    updatedLegs[taskIndex].pickup_address = selectedCity;
                                                    setJobLegs(updatedLegs)
                                                }}
                                                options={{
                                                    types: ['(cities)'],
                                                    componentRestrictions: { country: "au" }
                                                }}
                                            //style={{border: '1px solid #ccc', padding: '10px',borderRadius: '5px' }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={2} className="p-1">
                                        </Col>
                                        <Col lg={6} className='p-2'>
                                        <GoogleAutocomplete className='form-control'
                                                apiKey="AIzaSyB2SMtaVBlqC5v72gqS716BX8R5oXklaFc"
                                                value={res.drop_off_address}
                                                  onChange={(e) => {
                                                    const updatedLegs = [...jobLegs];
                                                    const taskIndex = updatedLegs.findIndex((task) => task.id === res.id);
                                                    updatedLegs[taskIndex].drop_off_address = e.target.value;
                                                    setJobLegs(updatedLegs)
                                                }}
                                                onPlaceSelected={(place) => {
                                                    const selectedCity = place && place.formatted_address;
                                                    const updatedLegs = [...jobLegs];
                                                    const taskIndex = updatedLegs.findIndex((task) => task.id === res.id);
                                                    updatedLegs[taskIndex].drop_off_address = selectedCity;
                                                    setJobLegs(updatedLegs)
                                                }}
                                                options={{
                                                    types: ['(cities)'],
                                                    componentRestrictions: { country: "au" }
                                                }}
                                            //style={{border: '1px solid #ccc', padding: '10px',borderRadius: '5px' }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={2} className="p-1">
                                        </Col>
                                        <Col lg={6} className='p-2'>
                                            Dispatch Notes
                                            <Input className="form-control mt-2" type="textarea" value={res.notes} placeholder=""
                                                onChange={(e) => {
                                                    const updatedLegs = [...jobLegs];
                                                    const taskIndex = updatedLegs.findIndex((task) => task.id === res.id);
                                                    updatedLegs[taskIndex].notes = e.target.value;
                                                    setJobLegs(updatedLegs)
                                                }} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={6}>
                                    <Row className='m-3'>
                                        <Col lg={4}>
                                            Status
                                        </Col>
                                        <Col lg={8} >
                                            <select className="form-select" value={res.leg_status}
                                                onChange={(e) => {
                                                    const updatedLegs = [...jobLegs];
                                                    const taskIndex = updatedLegs.findIndex((task) => task.id === res.id);
                                                    updatedLegs[taskIndex].leg_status = e.target.value;
                                                    setJobLegs(updatedLegs)
                                                }}>
                                                <option value=""></option>
                                                {postData.job_status?.map((res) => (
                                                    <option key={res.id} value={res.id}>
                                                        {res.options}
                                                    </option>
                                                ))}
                                            </select>
                                        </Col>
                                    </Row>
                                    <Row className='m-3'>
                                        <Col lg={4}>
                                            Driver
                                        </Col>
                                        <Col lg={8} >
                                            <select className="form-select" value={res.driver_id}
                                                onChange={(e) => {
                                                    const updatedLegs = [...jobLegs];
                                                    const taskIndex = updatedLegs.findIndex((task) => task.id === res.id);
                                                    updatedLegs[taskIndex].driver_id = e.target.value;
                                                    setJobLegs(updatedLegs)
                                                }}>
                                                <option value=""></option>
                                                {postData.drivers?.map((res) => (
                                                    <option key={res.id} value={res.id}>
                                                        {res.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </Col>
                                    </Row>
                                    <Row className='m-3'>
                                        <Col lg={4}>
                                            Vehicle
                                        </Col>
                                        <Col lg={8} >
                                            <select className="form-select" value={res.vehicle_id}
                                                onChange={(e) => {
                                                    const updatedLegs = [...jobLegs];
                                                    const taskIndex = updatedLegs.findIndex((task) => task.id === res.id);
                                                    updatedLegs[taskIndex].vehicle_id = e.target.value;
                                                    setJobLegs(updatedLegs)
                                                }}>
                                                <option value=""></option>
                                                {postData.vehicles?.map((res) => (
                                                    <option key={res.id} value={res.id}>
                                                        {res.vehicle_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </Col>
                                    </Row>
                                    <Row className='m-3'>
                                        <Col lg={4}>
                                            Multiple Trips?
                                        </Col>
                                        <Col lg={6} >
                                            <Input type="checkbox" onChange={(e) => {
                                                const updatedLegs = [...jobLegs];
                                                const taskIndex = updatedLegs.findIndex((task) => task.id === res.id);
                                                var checkedval = (e.target.checked) ? "1" : "0";
                                                updatedLegs[taskIndex].has_multiple_trips = checkedval;
                                                setJobLegs(updatedLegs)
                                            }} />
                                        </Col>
                                    </Row>
                                    <Row className='p-2'>
                                        <div className='hstack gap-3'>
                                            <Button className='btn btn-light' onClick={() => t_EditLegs()}>Cancel</Button>
                                            <Button className='btn btn-success' onClick={() => UpdateLeg(res)}>Update</Button>
                                        </div>
                                    </Row>
                                </Col>
                            </Row>}
                            <Row className='m-3'>
                                <h6 className='p-0'>Offsiders</h6>
                                <Col lg={12} className='px-0'>
                                    <Table className='table-bordered mt-2'>
                                        <thead className='bg-soft-purple'>
                                            <tr>
                                                <th>Name</th>
                                                <th>System User?</th>
                                                <th>Status</th>
                                                <th>Notify</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {res.job_legs_team_offsiders.map((offres) => {
                                                return (<>{(offres.id != expandedOffsideId) && <tr key={res.id}>
                                                    <td>{postData.people?.map((val) => (
                                                        (offres.people_id == val.id) ? val.name : ""))}</td>
                                                    <td>{offres.is_system_user}</td>
                                                    <td className='text-success'>{offres.confirmation_status}</td>
                                                    <td><Button className='btn btn-sm btn-soft-dark' onClick={() => Notify_offsider(offres, res)} disabled={offres.confirmation_status != "New"}>Notify</Button></td>
                                                    <td> <div className='hstack gap-2'>
                                                        <button className="btn btn-sm btn-soft-info edit-list" onClick={() => t_EditOffside(offres.id)}>
                                                            <i className="bx bxs-pencil fs-12 pt-1"></i>
                                                        </button>
                                                        <Link
                                                            to="#"
                                                            className=" btn btn-sm btn-soft-danger fs-13 pt-1" onClick={() => DeleteOffsider(offres)}>
                                                            <i className="bx bxs-trash fs-12"></i>
                                                        </Link>
                                                    </div></td>
                                                </tr>}
                                                    {(offres.id == expandedOffsideId) && <tr>
                                                        <td>
                                                            <select className="form-select"
                                                                value={tempoffsiderId || offres.people_id}
                                                                onChange={(e) => {
                                                                    //const updatedContactDetail = res.job_legs_team_offsider
                                                                    //console.log(updatedContactDetail)
                                                                    const updatedJobDetails = { ...data };
                                                                    const taskIndex = updatedJobDetails.job_legs.findIndex((contact) => (contact.id === res.id) ? contact.id : "");
                                                                    const innerIndex = updatedJobDetails.job_legs[taskIndex].job_legs_team_offsiders.findIndex((val) => val.id == offres.id);
                                                                    updatedJobDetails.job_legs[taskIndex].job_legs_team_offsiders[innerIndex].people_id = parseInt(e.target.value);
                                                                    setTempoffsiderId(updatedJobDetails.job_legs[taskIndex].job_legs_team_offsiders[innerIndex].people_id)
                                                                    setJobLegs(updatedJobDetails.job_legs);
                                                                }}>
                                                                {postData.people?.map((res) => (
                                                                    <option key={res.id} value={res.id}>
                                                                        {res.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </td>
                                                        <td></td>
                                                        <td className='text-success'></td>
                                                        <td></td>
                                                        <td> <div className='hstack gap-2'>
                                                            <Button className="btn btn-sm btn-light edit-list" onClick={() => t_EditOffside()}>
                                                                Cancel
                                                            </Button>
                                                            <Button
                                                                className=" btn btn-sm btn-success fs-13 pt-1" onClick={() => EditOffsider(offres)}>
                                                                Update
                                                            </Button>
                                                        </div></td>

                                                    </tr>}
                                                </>)
                                            }
                                            )}
                                            {addnewoffsider && <tr>
                                                <td colSpan={2}>
                                                    <select className='form-control w-50' onChange={(e) => {
                                                        setNewOffsider(e.target.value);
                                                    }}>
                                                        <option value="0"></option>
                                                        {postData.people?.map((res) => (
                                                            <option key={res.id} value={res.id}>
                                                                {res.name}
                                                            </option>))}
                                                    </select>
                                                </td>
                                                <td colSpan={3}>
                                                    <Button className='btn btn-md btn-light' onClick={() => AddNewOffsider(false)}>Cancel</Button>
                                                    <Button className='btn btn-md mx-2 btn-success' onClick={() => {SaveOffsider(res.id);AddNewOffsider(false)}}>Add</Button>
                                                </td>
                                            </tr>}
                                            {/* <TableRows rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange={handleChange} /> */}
                                        </tbody>
                                    </Table>
                                    <button className="btn btn-primary" onClick={() => AddNewOffsider(true)} >+</button>
                                </Col>
                            </Row>
                        </>)
                })}
                <div className='m-4'>
                    <Button
                        className='btn btn-teal'
                        onClick={t_coll1}
                        style={{ cursor: "pointer" }}
                    >
                        + Add New Leg
                    </Button>
                    <Collapse isOpen={coll1} id="collapseExample">
                        <div className="card mb-0">
                            <CardBody>
                                <Row>
                                    <Col lg={6} className='border'>
                                        <Row className='mb-3'>
                                            <Col lg={4}>
                                                <Flatpickr
                                                    className="form-control"
                                                    options={{
                                                        dateFormat: "Y-m-d",
                                                        defaultDate: [moment(new Date()).format("YYYY-MM-DD")]
                                                    }}
                                                    onChange={(selectedDate) => {
                                                        setNewLegDate(moment(selectedDate[0]).format("YYYY-MM-DD"))
                                                    }}
                                                />
                                            </Col>
                                            <Col lg={4}>
                                                <Flatpickr
                                                    className="form-control"
                                                    placeholder='--:--'
                                                    options={{
                                                        enableTime: true,
                                                        noCalendar: true,
                                                        dateFormat: "H:i",
                                                        time_24hr: true
                                                    }}
                                                    onChange={(selectedDate) => {
                                                        setNewStartTime(moment(selectedDate[0]).format("H:mm"))
                                                    }}
                                                />
                                            </Col>
                                            <Col lg={4}>
                                                <Flatpickr
                                                    className="form-control"
                                                    placeholder='--:--'
                                                    options={{
                                                        enableTime: true,
                                                        noCalendar: true,
                                                        dateFormat: "H:i",
                                                        time_24hr: true
                                                    }}
                                                    onChange={(selectedDate) => {
                                                        setNewFinishTime(moment(selectedDate[0]).format("H:mm"))
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className='mb-3'>
                                            <Col lg={8}>
                                            <GoogleAutocomplete
                                        apiKey="AIzaSyB2SMtaVBlqC5v72gqS716BX8R5oXklaFc"
                                        value={newpickup}
                                        onChange={(e) => {
                                            setNewPickUp(e.target.value);
                                        }}
                                        onPlaceSelected={(place) => pickupleg(place)}
                                        options={{
                                            types: ['(cities)'],
                                            componentRestrictions: { country: "au" }
                                        }}
                                        style={{
                                            border: "1px solid #ccc",
                                            padding: "10px",
                                            borderRadius: "5px",
                                            position: "relative",
                                            zIndex: 9999,
                                          }}/>
                                            </Col>
                                        </Row>
                                        <Row className='mb-3'>
                                            <Col lg={8}>
                                            <GoogleAutocomplete
                                        apiKey="AIzaSyB2SMtaVBlqC5v72gqS716BX8R5oXklaFc"
                                        value={newdropoff}
                                        onChange={(e) => {
                                            setNewDropOff(e.target.value);
                                        }}
                                        onPlaceSelected={(place) => dropoffleg(place)}
                                        options={{
                                            types: ['(cities)'],
                                            componentRestrictions: { country: "au" }
                                        }}

                                        style={{
                                            border: "1px solid #ccc",
                                            padding: "10px",
                                            borderRadius: "5px",
                                            position: "relative",
                                            zIndex: 9999,
                                          }}
                                        />
                                            </Col>
                                        </Row>
                                        <h6> Dispatch notesdd</h6>
                                        <Row>
                                            <Col>
                                                <textarea className='form-control' rows="2" onChange={(e) => {
                                                    setNewNotes(e.target.value);
                                                }}></textarea></Col>
                                        </Row>
                                    </Col>
                                    <Col lg={6}>
                                        <Row>
                                            <Col lg={3}>
                                                <label> <h6>Status</h6></label>
                                            </Col>
                                            <Col lang={9}>
                                                <select className="form-select mb-3" aria-label="Default select example" onChange={(e) => {
                                                    setNewStatus(e.target.value);
                                                }}>
                                                    <option value="0"></option>
                                                    {postData.job_status?.map((res) => (
                                                        <option key={res.options} value={res.options}>
                                                            {res.options}
                                                        </option>
                                                    ))}
                                                </select>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={3}>
                                                <label> <h6>Driver</h6></label>
                                            </Col>
                                            <Col lang={9}>
                                                <select className="form-select mb-3" aria-label="Default select example" onChange={(e) => {
                                                    setNewDriverId(e.target.value);
                                                }}>
                                                    <option defaultValue="0"></option>
                                                    {postData.drivers?.map((res) => (
                                                        <option key={res.id} value={res.id}>
                                                            {res.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={3}>
                                                <label> <h6>Vehicle</h6></label>
                                            </Col>
                                            <Col lang={9}>
                                                <select className="form-select mb-3" aria-label="Default select example" onChange={(e) => {
                                                    setNewVehicleId(e.target.value);
                                                }}>
                                                    <option defaultValue="0"></option>
                                                    {postData.vehicles?.map((res) => (
                                                        <option key={res.options} value={res.options}>
                                                            {res.vehicle_name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={3}>
                                                <label> <h6>Multiple Trips?</h6></label>
                                            </Col>
                                            <Col lang={9}>
                                                <Input type="checkbox" onChange={(e) => {
                                                    var checkedval = (e.target.checked) ? "1" : "0";
                                                    setIsMultipleTrips(checkedval)
                                                }} />
                                            </Col>
                                        </Row>

                                        <div className='hstack gap-3 mt-3'>
                                            <Button className='btn btn-success' onClick={() => SaveNewLeg()}>Save</Button>
                                            <Button className='btn btn-danger' onClick={t_coll1}>Cancel</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </div>
                    </Collapse>
                </div>

            </Card>
            <div>
                <h5 className='px-2'>Trips</h5>
                {/* {jobLegs?.map((res) => {
                    return (<> */}
                        <Card className='mb-1'>
                            {/* {(res.has_multiple_trips != 0) && <div className='p-2'>
                                <span>Leg #{res.leg_number}</span><br />
                                <span>{res.pickup_address}</span><br />
                                <span>{res.drop_off_address}</span><br />
                                <span>{moment(res.leg_date).format(postData.global.date_format)}</span>
                            </div>} */}
                            <Table className='table-bordered mb-0'>
                                {/* <thead>
                                    <tr>
                                        <th>Trips</th>
                                        <th>Pickup Address</th>
                                        <th>Drop off Address</th>
                                        <th>Trip Notes</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead> */}
                                <tbody>
                                    {/* {res.job_leg_trips.map((tres) => {
                                        return (<>{(tripEdit != tres.id) && <tr key={tres.id}>
                                            <td>{tres.trip_number}</td>
                                            <td>{tres.pickup_address}</td>
                                            <td>{tres.drop_off_address}</td>
                                            <td>{tres.trip_notes}</td>
                                            <td>
                                                <UncontrolledDropdown>
                                                    <DropdownToggle tag="button" className="btn btn-ghost-secondary btn-icon btn-sm fs-16">
                                                        <i className="ri-more-2-line"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-end">
                                                        <DropdownItem className='edit-item-btn' onClick={() => t_TripEdit(tres.id)}>
                                                            <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>Edit{tripEdit}</DropdownItem>
                                                        <DropdownItem className='remove-item-btn text-danger' onClick={() => t_deleteTrip(tres.id)}>
                                                            <i className="ri-delete-bin-fill align-bottom me-2 text-danger"></i>Delete</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </td>
                                        </tr>}
                                            {(tripEdit == tres.id) && <tr key={tres.id}>
                                                <td>{tres.trip_number}</td>
                                                <td><Input type='text' className='form-control' value={tres.pickup_address} onChange={(e) => {
                                                    const job_leg = [...jobLegs];
                                                    const Index = job_leg.findIndex((val) => val.id == res.id);
                                                    const subIndex = job_leg[Index].job_leg_trips.findIndex((val) => val.id == tres.id);
                                                    job_leg[Index].job_leg_trips[subIndex].pickup_address = e.target.value;
                                                    setJobLegs(job_leg);

                                                }} /></td> */}
                                                {/* <td><Input type='text' className='form-control' value={tres.drop_off_address} onChange={(e) => {
                                                    const job_leg = [...jobLegs];
                                                    const Index = job_leg.findIndex((val) => val.id == res.id);
                                                    const subIndex = job_leg[Index].job_leg_trips.findIndex((val) => val.id == tres.id);
                                                    job_leg[Index].job_leg_trips[subIndex].drop_off_address = e.target.value;
                                                    setJobLegs(job_leg);

                                                }} /></td> */}
                                                {/* <td><textarea value={tres.trip_notes} className='form-control' onChange={(e) => {
                                                    const job_leg = [...jobLegs];
                                                    const Index = job_leg.findIndex((val) => val.id == res.id);
                                                    const subIndex = job_leg[Index].job_leg_trips.findIndex((val) => val.id == tres.id);
                                                    job_leg[Index].job_leg_trips[subIndex].trip_notes = e.target.value;
                                                    setJobLegs(job_leg);

                                                }}></textarea></td> */}
                                                {/* <td>
                                                    <Button className='btn btn-sm btn-success' onClick={() => UpdateTrip(tres)}>Update</Button>
                                                    <Button className='btn btn-sm btn-danger mx-2' onClick={() => t_TripEdit()}>Cancel</Button>
                                                </td>
                                            </tr>}</>)
                                    })}
                                    {addnewTrip && <tr>
                                        <td></td>
                                        <td>
                                            <Input type='text' className='form-control' onChange={(e) => {
                                                setNewTripPickup(e.target.value)
                                            }} />
                                        </td>
                                        <td>
                                            <Input type='text' className='form-control' onChange={(e) => {
                                                setNewTripDropOff(e.target.value)
                                            }} />
                                        </td>
                                        <td>
                                            <textarea type='text' className='form-control' onChange={(e) => {
                                                setNewTripNotes(e.target.value)
                                            }}></textarea>
                                        </td>
                                        <td>
                                            <Button className='btn btn-sm btn-success' onClick={() => AddNewTrip(res.id)}>Add</Button>
                                            <Button className='btn btn-sm btn-danger mx-2' onClick={() => t_addNewTrip()}>Cancel</Button>
                                        </td>
                                    </tr>}
                                    {res.job_leg_trips.length <= 0 && <tr> */}
                                         <Row>
                                        <Col lg={12} className='border'>
                                        <td colSpan={5}>No Trip available</td>
                                        </Col>
                                  
                                    </Row>
                                    {/* </tr>}
                                    {/* <TableRows rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange={handleChange} /> */}
                                </tbody> 
                            </Table>
                        </Card>
                        {/* <button className="btn btn-primary" onClick={() => t_addNewTrip()} >+</button>
                    </>)
                })} */}
            </div>

            <hr></hr>
            <div>
                <h5> Material Issues </h5>
                <Button className='btn btn-teal mt-3 mb-3' onClick={updateinvoicepdf} >Update & Generate Invoice</Button>
                <Card className='mb-1'>
                    <Table className='table-bordered mb-0 '>
                        <thead>
                            <tr>
                                <th>Item Name & Description</th>
                                <th>Qty</th>
                                <th>Date Issues</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {postData.material_issue_items && postData?.material_issue_items?.map((res) => {
                                return (<>{(res.id != selectedIssueId) && <tr key={res.id}>
                                    <td><span>{res.name}</span><br />
                                        <span>{res.description}</span></td>
                                    <td>{res.quantity}</td>
                                    <td>{moment(res.created_date).format("MM/DD/YYYY")}</td>
                                    <td>
                                        <UncontrolledDropdown>
                                            <DropdownToggle tag="button" className="btn btn-ghost-secondary btn-icon btn-sm fs-16">
                                                <i className="ri-more-2-line"></i>
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu-end">
                                                <DropdownItem className='edit-item-btn' onClick={() => t_IssuesEdit(res.id)}>
                                                    <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>Edit{tripEdit}</DropdownItem>
                                                <DropdownItem className='remove-item-btn text-danger' onClick={() => t_deleteIssues(res.id)}>
                                                    <i className="ri-delete-bin-fill align-bottom me-2 text-danger"></i>Delete</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </td>
                                </tr>}
                                    {(res.id == selectedIssueId) && <tr>
                                        <td>
                                            <select className='form-control' onChange={(e) => {
                                                const updatedItems = [...postData.material_issue_items];
                                                const selectedIndex = e.target.selectedIndex;
                                                const selectedOption = e.target.options[selectedIndex];
                                                const type = selectedOption.getAttribute('data-product_type');
                                                const Indexval = updatedItems.findIndex((val) => val.id == res.id);
                                                updatedItems[Indexval] = { ...updatedItems[Indexval], name: e.target.value };
                                                updatedItems[Indexval] = { ...updatedItems[Indexval], type: type };
                                                setData({ ...postData, material_issue_items: updatedItems });
                                            }}>
                                                <option value=""></option>
                                                {postData.products.map((prod) => {
                                                    if (prod.stockable == "Y") {
                                                        return (<option key={prod.id} value={prod.name} data-pid={prod.id} data-desc={prod.description} data-price={prod.price} data-tax={prod.tax_id} data-type={prod.product_type} data-product_type={prod.customer_type} selected={prod.id == res.item_id}
                                                        >{prod.name}</option>)
                                                    }
                                                })}
                                            </select>
                                            <textarea type="text" className='form-control mt-2' value={res.decription} onChange={(e) => {
                                                const updatedItems = [...postData.material_issue_items];
                                                const Indexval = updatedItems.findIndex((val) => val.id == res.id);
                                                updatedItems[Indexval] = { ...updatedItems[Indexval], description: e.target.value };
                                                setData({ ...postData, material_issue_items: updatedItems });
                                            }}></textarea>
                                        </td>
                                        <td><Input type='number' className='form-control' value={res.quantity} onChange={(e) => {
                                            const updatedItems = [...postData.material_issue_items];
                                            const Indexval = updatedItems.findIndex((val) => val.id == res.id);
                                            updatedItems[Indexval] = { ...updatedItems[Indexval], quantity: parseInt(e.target.value) };
                                            setData({ ...postData, material_issue_items: updatedItems });
                                        }} /></td>
                                        <td>{moment(res.created_date).format("MM/DD/YYYY")}</td>
                                        <td>
                                            <Button className='btn btn-sm btn-light' onClick={() => { t_IssuesEdit() }}>Cancel</Button>
                                            <Button className='btn btn-sm btn-success mx-2' onClick={() => { UpdateIssues(res) }}>Update</Button>
                                        </td>
                                    </tr>}</>)
                            })}
                            {postData?.material_issue_items?.length <= 0 && <tr>
                                <td>No records available</td>
                            </tr>}
                            {AddnewIssues && <tr>
                                <td>
                                    <select className='form-control' onChange={(e) => {
                                        const selectedIndex = e.target.selectedIndex;
                                        const selectedOption = e.target.options[selectedIndex];
                                        const type = selectedOption.getAttribute('data-product_type');
                                        const pid = selectedOption.getAttribute('data-pid');
                                        const name = e.target.value;
                                        var valData = {
                                            "lead_id": "0",
                                            "description": NewIssues?.description,
                                            "job_id": job_id
                                        }
                                        axios.post("api/ajaxSetProductDescParameter", valData).then((res) => {
                                            if (res.desc != null) {
                                                setNewIssues((prevItem) => ({
                                                    ...prevItem,
                                                    name: name,
                                                    type: type,
                                                    pid: pid,
                                                    description: res.desc,
                                                    quantity: 1
                                                }));
                                            } else {
                                                setNewIssues((prevItem) => ({
                                                    ...prevItem,
                                                    name: name,
                                                    type: type,
                                                    pid: pid,
                                                    quantity: 1
                                                }));
                                            }
                                        })
                                    }}>
                                        <option value="0"></option>
                                        {postData.products.map((prod) => {
                                            if (prod.stockable == "Y") {
                                                return (<option key={prod.id} value={prod.name} data-pid={prod.id} data-desc={prod.description} data-price={prod.price} data-tax={prod.tax_id} data-type={prod.product_type} data-product_type={prod.customer_type} selected={prod.id == prod.item_id}
                                                >{prod.name}</option>)
                                            }
                                        })}
                                    </select>
                                    <textarea type="text" className='form-control mt-2' value={NewIssues?.description} onChange={(e) => {
                                        setNewIssues((prevItem) => ({
                                            ...prevItem,
                                            description: e.target.value,
                                        }));
                                    }}></textarea>
                                </td>
                                <td><Input type='number' className='form-control' value={NewIssues?.quantity} onChange={(e) => {
                                    setNewIssues((prevItem) => ({
                                        ...prevItem,
                                        quantity: e.target.value,
                                    }));
                                }} /></td>
                                <td>{moment(new Date()).format("MM/DD/YYYY")}</td>
                                <td>
                                    <Button className='btn btn-sm btn-light' onClick={() => { setAddNewIssues(!AddnewIssues) }}>Cancel</Button>
                                    <Button className='btn btn-sm btn-success mx-2' onClick={() => SaveNewIssues()}>Add</Button>
                                </td>
                            </tr>}
                        </tbody>
                    </Table>
                </Card>
                <button className="btn btn-primary" onClick={() => setAddNewIssues(!AddnewIssues)} >+</button>
            </div>
            <div className='mt-3'>
                <h5> Material Returns </h5>
                <Card className='mb-1 mt-2'>
                    <Table className='table-bordered mb-0 '>
                        <thead>
                            <tr>
                                <th>Item Name & Description</th>
                                <th>Qty</th>
                                <th>Date Issues</th>
                            </tr>
                        </thead>
                        <tbody>
                            {postData?.material_return_items?.map((res) => {
                                return (<>{(res.id != selectedReturnId) && <tr key={res.id}>
                                    <td>
                                        <span>{res.name}</span>
                                        <span>{res.description}</span>
                                    </td>
                                    <td>{res.quantity}</td>
                                    <td>{moment(res.created_date).format("MM/DD/YYYY")}</td>
                                    <td>
                                        <UncontrolledDropdown>
                                            <DropdownToggle tag="button" className="btn btn-ghost-secondary btn-icon btn-sm fs-16">
                                                <i className="ri-more-2-line"></i>
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu-end">
                                                <DropdownItem className='edit-item-btn' onClick={() => t_ReturnEdit(res.id)}>
                                                    <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{tripEdit}</DropdownItem>
                                                <DropdownItem className='remove-item-btn text-danger' onClick={() => t_deleteReturn(res.id)}>
                                                    <i className="ri-delete-bin-fill align-bottom me-2 text-danger"></i>Delete</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </td>
                                    <td>{res.quantity}</td>
                                    <td>{moment(res.created_date).format("MM/DD/YYYY")}</td>
                                    <td>
                                        <UncontrolledDropdown>
                                            <DropdownToggle tag="button" className="btn btn-ghost-secondary btn-icon btn-sm fs-16">
                                                <i className="ri-more-2-line"></i>
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu-end">
                                                <DropdownItem className='edit-item-btn' onClick={() => t_ReturnEdit(res.id)}>
                                                    <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>Edit{tripEdit}</DropdownItem>
                                                <DropdownItem className='remove-item-btn text-danger' onClick={() => t_deleteReturn(res.id)}>
                                                    <i className="ri-delete-bin-fill align-bottom me-2 text-danger"></i>Delete</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </td>
                                </tr>}
                                    {(res.id == selectedReturnId) && <tr>
                                        <td>
                                            <select className='form-control' onChange={(e) => {
                                                const updatedItems = [...postData.material_return_items];
                                                const selectedIndex = e.target.selectedIndex;
                                                const selectedOption = e.target.options[selectedIndex];
                                                const type = selectedOption.getAttribute('data-product_type');
                                                const Indexval = updatedItems.findIndex((val) => val.id == res.id);
                                                updatedItems[Indexval] = { ...updatedItems[Indexval], name: e.target.value };
                                                updatedItems[Indexval] = { ...updatedItems[Indexval], type: type };
                                                setData({ ...postData, material_return_items: updatedItems });
                                            }}>
                                                <option value="0"></option>
                                                {postData.products.map((prod) => {
                                                    if (prod.stockable == "Y") {
                                                        return (<option key={prod.id} value={res.name} data-pid={res.id} data-desc={res.description} data-price={res.price} data-tax={res.tax_id} data-type={res.product_type} data-product_type={res.customer_type} selected={prod.id == res.item_id}
                                                        >{prod.name}</option>)
                                                    }
                                                })}
                                            </select>
                                            <textarea type="text" className='form-control mt-2' value={res.decription} onChange={(e) => {
                                                const updatedItems = [...postData.material_return_items];
                                                const Indexval = updatedItems.findIndex((val) => val.id == res.id);
                                                updatedItems[Indexval] = { ...updatedItems[Indexval], description: e.target.value };
                                                setData({ ...postData, material_return_items: updatedItems });
                                            }}></textarea>
                                        </td>
                                        <td><Input type='number' className='form-control' value={res.quantity} onChange={(e) => {
                                            const updatedItems = [...postData.material_return_items];
                                            const Indexval = updatedItems.findIndex((val) => val.id == res.id);
                                            updatedItems[Indexval] = { ...updatedItems[Indexval], quantity: parseInt(e.target.value) };
                                            setData({ ...postData, material_return_items: updatedItems });
                                        }} /></td>
                                        <td>{moment(res.created_date).format("MM/DD/YYYY")}</td>
                                        <td>
                                            <Button className='btn btn-sm btn-light' onClick={() => { t_ReturnEdit() }}>Cancel</Button>
                                            <Button className='btn btn-sm btn-success mx-2' onClick={() => UpdateReturns(res)}>Update</Button>
                                        </td>
                                    </tr>}</>)
                            })}
                            {AddNewReturn && <tr>
                                <td>
                                    <select className='form-control' value={NewReturns.name} onChange={(e) => {
                                        const selectedIndex = e.target.selectedIndex;
                                        const selectedOption = e.target.options[selectedIndex];
                                        const type = selectedOption.getAttribute('data-product_type');
                                        const pid = selectedOption.getAttribute('data-pid');
                                        const name = e.target.value;
                                        var valData = {
                                            "lead_id": "0",
                                            "description": NewIssues?.description,
                                            "job_id": job_id
                                        }
                                        axios.post("api/ajaxSetProductDescParameter", valData).then((res) => {
                                            if (res.desc != null) {
                                                setNewReturns((prevItem) => ({
                                                    ...prevItem,
                                                    name: name,
                                                    type: type,
                                                    pid: pid,
                                                    description: res.desc,
                                                    quantity: 1
                                                }));
                                            } else {
                                                setNewReturns((prevItem) => ({
                                                    ...prevItem,
                                                    name: name,
                                                    type: type,
                                                    pid: pid,
                                                    quantity: 1
                                                }));
                                            }
                                        })
                                    }}>
                                        <option value="0"></option>
                                        {postData.products.map((prod) => {
                                            const matchingItems = postData?.material_issue_items.filter(
                                                (val) => prod.id === val.item_id);
                                            return matchingItems.map((val, index) => (
                                                <option key={prod.id} value={prod.name} data-pid={prod.id} data-desc={prod.description} data-price={prod.price} data-tax={prod.tax_id} data-type={prod.product_type} data-product_type={prod.customer_type}>{prod.name}</option>
                                            ));
                                        })}
                                    </select>
                                    <textarea type="text" className='form-control mt-2' value={NewReturns.description} onChange={(e) => {
                                        setNewReturns((prevItem) => ({
                                            ...prevItem,
                                            description: e.target.value,
                                        }));
                                    }}></textarea>
                                </td>
                                <td><Input type='number' className='form-control' value={NewReturns.quantity} onChange={(e) => {
                                    setNewReturns((prevItem) => ({
                                        ...prevItem,
                                        quantity: e.target.value,
                                    }));
                                }} /></td>
                                <td>{moment(new Date()).format("MM/DD/YYYY")}</td>
                                <td>
                                    <Button className='btn btn-sm btn-light' onClick={() => { setAddNewReturns(!AddNewReturn) }}>Cancel</Button>
                                    <Button className='btn btn-sm btn-success mx-2' onClick={() => SaveNewReturns()}>Add</Button>
                                </td>
                            </tr>}
                            {postData?.material_return_items?.length <= 0 && <tr>
                                <td>No records available</td>
                            </tr>}

                        </tbody>
                    </Table>
                </Card>
                <button className="btn btn-primary" onClick={() => setAddNewReturns(!AddNewReturn)} >+</button>
            </div>
            <div className='mt-3'>
                <h5> Pickup - OHS Risk Assessment</h5>
                <Card className='mb-1 mt-2'>
                    <Table className='table-bordered mb-0 '>
                        <thead>
                            <tr>
                                <th>Site Risk Assessment</th>
                                <th>Y/N/NA</th>
                                <th>Control Measures</th>
                            </tr>
                        </thead>
                        <tbody>
                            {postData?.pickup_risk_assessment?.length > 0 && postData.pickup_risk_assessment.map((res) => {
                                <tr key={res.id}>
                                    <td>{res.checklist}</td>
                                    <td>{res.risk}</td>
                                    <td>{res.control_measures}</td>
                                </tr>
                            })}
                            {postData?.pickup_risk_assessment?.length <= 0 && <tr>
                                <td colSpan={3}>No records available</td>
                            </tr>}
                        </tbody>
                    </Table>
                </Card>
            </div>
            <div className='mt-3'>
                <h5> Delivery - OHS Risk Assessment</h5>
                <Card className='mb-1 mt-2'>
                    <Table className='table-bordered mb-0 '>
                        <thead>
                            <tr>
                                <th>Site Risk Assessment</th>
                                <th>Y/N/NA</th>
                                <th>Control Measures</th>
                            </tr>
                        </thead>
                        <tbody>
                            {postData?.delivery_risk_assessment?.length > 0 && postData.delivery_risk_assessment.map((res) => {
                                <tr key={res.id}>
                                    <td>{res.checklist}</td>
                                    <td>{res.risk}</td>
                                    <td>{res.control_measures}</td>
                                </tr>
                            })}
                            {postData?.delivery_risk_assessment?.length <= 0 && <tr>
                                <td colSpan={3}>No records available</td>
                            </tr>}
                        </tbody>
                    </Table>
                </Card>
            </div>

            <h5 className='mt-3 mb-2'> Customer Signature</h5>
            <Card className='p-3 mt-2'>
                <Row>
                    {jobLegs?.map((res) => {
                        if (res.customer_sign_pre_job != null && res.customer_sign_pre_job != "") {
                            var customerSignPreJob = res.customer_sign_pre_job;
                            var publicSegment = '/public/';
                            var customerSignPreJobSubstring = customerSignPreJob.substring(customerSignPreJob.indexOf('/public/') + publicSegment.length);
                            var customerSignPreJobUrl = api.API_URL + '/' + customerSignPreJobSubstring;
                            cSignature = 1;

                            return (<Col lg={2} key={res.id}>
                                <p>Pre - Job Signature</p>
                                <div className="att_thumb">
                                    <h6 className="d-flex font-weight-normal flex-nowrap mb-0">
                                        {/* <a title={res.attachment_type} className="text-default mr-2" onClick={()=>{OpenAttach(res.id)}}> */}
                                        <img src={customerSignPreJobUrl} style={{ maxWidth: '100px', height: '100px', }} />
                                        {/* {res.attachment_type.substring(0, 30) + '...'} */}
                                        {/* </a> */}
                                    </h6>
                                </div>
                            </Col>)
                        }
                        if (res.customer_sign != null && res.customer_sign != "") {
                            var customerSignJob = res.customer_sign;
                            var publicSegment = '/public/';
                            var customerSignJobSubstring = customerSignJob.substring(customerSignJob.indexOf('/public/') + publicSegment.length);
                            var customerSignJobUrl = api.API_URL + '/' + customerSignJobSubstring;
                            cSignature = 1;
                            return (<Col lg={2} key={res.id}>
                                <p>Post - Job Signature</p>
                                <div className="att_thumb">
                                    <h6 className="d-flex font-weight-normal flex-nowrap mb-0">
                                        {/* <a title={res.attachment_type} className="text-default mr-2" onClick={()=>{OpenAttach(res.id)}}> */}
                                        <img src={customerSignJobUrl} style={{ maxWidth: '100px', height: '100px', }} />
                                        {/* {res.attachment_type.substring(0, 30) + '...'} */}
                                        {/* </a> */}
                                    </h6>
                                </div>
                            </Col>)
                        }
                    })}
                    {(cSignature === 0) &&
                        "No signatures found."
                    }

                </Row>
            </Card>
            <h5>Attachments</h5>
            <Card className='p-3 mt-2'>
                <Row>
                    {postData?.attachments?.map((res) => {
                        const getExtensionFromPath = (filePath) => {
                            const pathInfo = filePath.split('.');
                            if (pathInfo.length > 1) {
                                const fileExtension = pathInfo[pathInfo.length - 1];
                                return fileExtension.toLowerCase();
                            }
                            return '';
                        };

                        // Usage
                        const attachment = { attachment_content: '/path/to/file.pdf' };
                        const fileExtension = getExtensionFromPath(attachment.attachment_content);
                        var imageUrl = attachment.attachment_content.substring(attachment.attachment_content.lastIndexOf('/public') + 1) + "\n";
                        return (<Col lg={2} key={res.id}>
                            {(fileExtension == "pdf") ?
                                <div className="card att_thumb">
                                    <h6 className="d-flex font-weight-normal flex-nowrap mb-0">
                                        <a title={res.attachment_type} className="text-default mr-2" onClick={() => { OpenAttach(res.id) }}>
                                            <img src={api.API_URL + "public/pdf-bg.png"} style={{ maxWidth: '100px', height: '100px', }} />
                                            {res.attachment_type.substring(0, 30) + '...'}
                                        </a>
                                    </h6>
                                </div> : <>
                                    <div className="card att_thumb">
                                        <h6 className="d-flex font-weight-normal flex-nowrap mb-0">
                                            <a title={res.attachment_type} className="text-default mr-2" onClick={() => { OpenAttach(res.id) }}>
                                                <img src={imageUrl} style={{ maxWidth: '100px', height: '100px', }} />
                                                {res.attachment_type.substring(0, 30) + '...'}
                                            </a>
                                        </h6>
                                    </div>
                                </>}
                        </Col>)
                    })}
                </Row>
            </Card>
            <Modal
                id="showModal"
                className="modal-dialog-edit"
                isOpen={modal}
                toggle={Reassign_driver}
                centered
            >
                <ModalHeader
                    className="bg-soft-primary p-3"
                    toggle={Reassign_driver}
                >
                    Reassign Driver
                </ModalHeader>
                <ModalBody>
                    <select className="form-select"
                        onChange={(e) => {
                            console.log(e.target.value)
                            setReassignDriver(e.target.value)
                        }}>
                        {postData.drivers?.map((res) => (
                            <option key={res.id} value={res.id}>
                                {res.name}
                            </option>
                        ))}
                    </select>
                    <Button className='btn btn-success mt-3 float-right' onClick={() => ReassignDriver()}>Re-assign</Button>
                </ModalBody>
            </Modal>
            <Modal
                id="showModal"
                className="modal-dialog-edit"
                isOpen={notifyModal}
                // toggle={Reassign_driver}
                centered
            >
                <ModalBody className="py-2 px-3">
                    <div className="mt-2 text-center">
                        <lord-icon
                            src="https://cdn.lordicon.com/wdqztrtx.json"
                            trigger="loop"
                            colors="primary:#912a4e,secondary:#f06548"
                            style={{ width: "100px", height: "100px" }}
                        ></lord-icon>
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you sure ?</h4>
                            <p className="text-muted mx-4 mb-0">
                                Driver will be Notified!
                            </p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button
                            type="button"
                            className="btn w-sm btn-light"
                            data-bs-dismiss="modal"
                            onClick={() => notifyDriver("close")}>
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="btn w-sm btn-danger "
                            id="delete-record"
                            onClick={() => notifyDriver("confirm")}
                        >
                            Ok
                        </button>
                    </div>
                </ModalBody>
            </Modal>
            <Modal
                id="showModal"
                className="modal-dialog-edit"
                isOpen={confirmmodal}
                toggle={Reassign_driver}
                centered
            >
                <ModalBody className="py-2 px-3">
                    <div className="mt-2 text-center">
                        <lord-icon
                            src="https://cdn.lordicon.com/wdqztrtx.json"
                            trigger="loop"
                            colors="primary:#912a4e,secondary:#f06548"
                            style={{ width: "100px", height: "100px" }}
                        ></lord-icon>
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you sure ?</h4>
                            <p className="text-muted mx-4 mb-0">
                                Driver will be reassigned!
                            </p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button
                            type="button"
                            className="btn w-sm btn-light"
                            data-bs-dismiss="modal"
                            onClick={() => ReassignDriver("close")}>
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="btn w-sm btn-danger "
                            id="delete-record"
                            onClick={() => ReassignDriver("confirm")}
                        >
                            Ok
                        </button>
                    </div>
                </ModalBody>
            </Modal>
            <Modal
                id="showModal"
                className="modal-dialog-edit"
                isOpen={deletemodal}
                toggle={t_deleteLeg}
                centered
            >
                <ModalBody className="py-2 px-3">
                    <div className="mt-2 text-center">
                        <lord-icon
                            src="https://cdn.lordicon.com/wdqztrtx.json"
                            trigger="loop"
                            colors="primary:#912a4e,secondary:#f06548"
                            style={{ width: "100px", height: "100px" }}
                        ></lord-icon>
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you sure ?</h4>
                            <p className="text-muted mx-4 mb-0">
                                Related trips will be deleted with Leg!
                            </p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button
                            type="button"
                            className="btn w-sm btn-light"
                            data-bs-dismiss="modal"
                            onClick={() => t_deleteLeg()}>
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="btn w-sm btn-danger "
                            id="delete-record"
                            onClick={() => deleteOperationLeg()}
                        >
                            Ok
                        </button>
                    </div>
                </ModalBody>
            </Modal>
            <CustomDeleteModal
                show={deletetripmodal}
                onCloseClick={() => t_deleteTrip()}
                onDeleteClick={() => DeleteTrip()}
            ></CustomDeleteModal>
            <CustomDeleteModal
                show={deleteissuesmodal}
                onCloseClick={() => t_deleteTrip()}
                onDeleteClick={() => DeleteIssues()}
            ></CustomDeleteModal>
            <CustomDeleteModal
                show={deletereturnmodal}
                onCloseClick={() => t_deleteTrip()}
                onDeleteClick={() => DeleteReturns()}
            ></CustomDeleteModal>
            <ToastContainer closeButton={false} limit={1} theme="light" />
        </div>

    )
}
