import { Card, CardBody, Col, CardHeader, Table, Row } from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import axios from "axios";
import TableRows from "../TableRows";
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';

export const PricingRegions = () => {
  document.title="Pricing Regions | Onexfort";
  const [states, setStates] = useState([]);
  const [data, setData] = useState([]);
  const [regionId, setRegionId] = useState();
  const [selectedState, setSelectedState] = useState();
  const [selectedStatesId, setSelectedStatesId] = useState();

  //edit value
  const [newRegion, setNewRegion] = useState();
  const [newSuburb, setNewSuburb] = useState();
  const [newSelectedState, setNewSelectedState] = useState();

  //rowsdata
  const [rowsData, setRowsData] = useState([]);

  //list table
  const loadTable = () => {
    axios.get("/api/pricing-region")
      .then(res => {
        console.log(res);
        setData(res.region_pricings);
        setStates(res.sys_country_states);
        setNewSelectedState(res.sys_country_states[0]?.state_id)
      })
      
  }
  useEffect(() => {
    loadTable();
  }, [])

  const handleEdit = (index, item) => {
    console.log(item);
    setSelectedState(item.state_id);

    setData((prevGroupNames) => {
      const updatedGroupNames = [...prevGroupNames];
      updatedGroupNames[index].isEditing = true;
      return updatedGroupNames;
    });
  }

  //edit ->cancel
  const handleCancel = (index) => {
    setData((prevChecklistNames) => {
      const updatedChecklistNames = [...prevChecklistNames];
      updatedChecklistNames[index].isEditing = false;
      return updatedChecklistNames;
    });
  }

  //rowsdata
  const addTableRows = () => {
    const rowsInput = {
      states: '',
      regionName: '',
      suburbName: ''

    }
    setRowsData([...rowsData, rowsInput])
  }
  //edit-> save
  const handleSave = (index, item) => {
    let data = {
      "pricing_region_id": item.id,
      "state_id": selectedStatesId,
      "region_name": item.region_name,
      "region_suburb_name": item.region_suburb_name
    }
    console.log(item.id, item.region_name, item.region_suburb_name, selectedStatesId)
    axios.post("/api/ajaxUpdateRegionPricing", data)
      .then(res => {
        setData((prevGroupNames) => {
          const updatedGroupNames = [...prevGroupNames];
          updatedGroupNames[index].isEditing = false;
          return updatedGroupNames;
        });
        loadTable();
      })
      .catch(err => {
        console.log(err);

      })
  }
  // input change
  const handleChange = (index, event, inputType) => {
    const { value } = event.target;
    console.log(value);
    console.log(inputType);
    if (inputType == 'region') {
      const updatedChecklistNames = [...data];
      updatedChecklistNames[index].region_name = value;
      setData(updatedChecklistNames)

    } else if (inputType == 'suburb') {
      setData((prevChecklistNames) => {
        const updatedChecklistNames = [...prevChecklistNames];
        updatedChecklistNames[index].region_suburb_name = value;
        return updatedChecklistNames;
      });
    }
  }

  const handleNewChange = (index, event, inputType) => {
    if (inputType === "region") {
      setNewRegion(event.target.value);
      console.log(newRegion);
    }
    else if (inputType === "suburb") {
      setNewSuburb(event.target.value);
      console.log(newSuburb);
    }
  }

  const saveTableRow = (index) => {
    let data = {
      "state_id": newSelectedState,
      "region_name": newRegion,
      "region_suburb_name": newSuburb

    }
    console.log(data.state_id)

    if (data.region_name===""||data.region_name===undefined){
      toast.error("Enter the Region Name",{theme:"light"});
    }
    else if (data.region_suburb_name===""||data.region_suburb_name===undefined){
      toast.error("Enter the Region Suburb Name",{theme:"light"});
    }
    else{
    axios.post("/api/ajaxCreateRegionPricing", data)
      .then(res => {
        const rows = [...rowsData];
        rows.splice(index, 1);
        setRowsData(rows);
        loadTable();
      })
    }
  }

  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  }

  const handleDelete = (index, item) => {
    console.log(item.id)
    var postData = {
      "id": item.id,
    } ;
    axios.post('api/ajaxDestroyRegionPricing', postData)
      .then(res => {
        loadTable();
      })
      .catch(err => {
        console.log(err);
      })
  }
  console.log()
  return (
    <div>
      <Card>
        <CardHeader>
          <h5 className="mb-0">Pricing Regions</h5>
        </CardHeader>
        <CardBody>
          <div className="live-preview">
            <Row>
              <Col xl={12}>
                <div className="table-responsive mt-4 mt-xl-0">
                  <Table className="table table-bordered table-nowrap align-middle mb-3">
                    <thead>
                      <tr>
                        <th scope="col">State</th>
                        <th scope="col">Region Name</th>
                        <th scope="col">Region Suburb Name</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => (
                        <tr key={item.id}>
                          <td className="fw-medium">
                            {item.isEditing ? (
                              <select
                                value={selectedStatesId}
                                onChange={(event) => setSelectedStatesId(event.target.value)}
                                className="form-select "
                                aria-label="Default select example"
                              >
                                {states.map((item) => (
                                  <option key={item.state_id} value={item.state_id}>
                                    {item.state_code}
                                  </option>
                                ))}
                              </select>
                            ) : (
                              states.map((res) =>
                                (res.state_id == item.state_id) ? res.state_code : ""
                              )
                            )}
                          </td>
                          <td className="fw-medium">
                            {item.isEditing ? (
                              <input
                                type="text"
                                value={item.region_name}
                                onChange={(event) => handleChange(index, event, "region")}
                                className="form-control"
                              />
                            ) : (
                              item.region_name
                            )}
                          </td>
                          <td className="fw-medium">
                            {item.isEditing ? (
                              <input
                                type="text"
                                value={item.region_suburb_name}
                                onChange={(event) => handleChange(index, event, "suburb")}
                                className="form-control"
                              />
                            ) : (
                              item.region_suburb_name
                            )}
                          </td>
                          <td>
                            <div className="hstack gap-3 flex-wrap">
                              {item.isEditing ? (
                                <>
                                  <button
                                    className="btn btn-success"
                                    onClick={() => handleSave(index, item)}
                                  >
                                    Save
                                  </button>
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => handleCancel(index)}
                                  >
                                    Cancel
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    className="btn btn-sm btn-soft-info edit-list "
                                    onClick={() => handleEdit(index, item)}
                                  >
                                    <i className="bx bxs-pencil fs-12 pt-1"></i>
                                  </button>
                                  <button
                                    className="btn btn-sm btn-soft-danger  "
                                    onClick={() => handleDelete(index, item)}
                                  >
                                    <i className="ri-delete-bin-5-fill fs-16"></i>
                                  </button>
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                      {rowsData.map((data, index) => (
                        <tr key={index}>
                          <td>
                            <select
                              onChange={(event) => { setNewSelectedState(event.target.value) }}
                              className="form-select "
                              value={newSelectedState}
                              aria-label="Default select example"
                            >
                              {states.map((item) => (
                                <option key={item.state_id} value={item.state_id}>
                                  {item.state_code}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <input
                              type="text"
                              value={newRegion}
                              onChange={(event) => handleNewChange(index, event, "region")}
                              name="regionName"
                              className="form-control"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={newSuburb}
                              onChange={(event) => handleNewChange(index, event, "suburb")}
                              name="suburbName"
                              className="form-control"
                            />
                          </td>

                          <td>
                            <div className="hstack gap-2">
                              <button className="btn btn-success" onClick={() => saveTableRow(index)}
                              >
                                Save
                              </button>
                              <button className="btn btn-primary" onClick={() => deleteTableRows(index, "table1")}
                              >
                                Cancel
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <button className="btn btn-primary" onClick={addTableRows} >+</button>
                </div>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
      <ToastContainer closeButton={false} limit={1} />
    </div>
  )
}
