import React, { useEffect, useState } from 'react';
import { Col, Input, Label, Row, CardHeader, CardBody, Accordion, AccordionItem, Collapse, Card, Container, Table } from 'reactstrap';
import Flatpickr from "react-flatpickr";
import classnames from "classnames";
import axios from 'axios';
// import Components
// import * as moment from "moment";
import moment from "moment";
import BreadCrumb from '../../Components/Common/BreadCrumb';


const SalesPipeline = () => {
    const [col1, setcol1] = useState(false);
    const [data, setData] = useState([]);
    const [users, setUsers] = useState([]);
    const [unassignedData, setUnassignedData] = useState([]);
    const [org_date, setOrg_Date] = useState([]);
    const t_col1 = () => {
        setcol1(!col1);

    };

    //Created start date and end date
    const [createdDateStart, setCreatedDateStart] = useState(null);
    const [createdDateEnd, setCreatedDateEnd] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState();

    const loadData = () => {
        axios.get("api/sales-report")
            .then(res => {
                console.log(res,"res12434");
                setOrg_Date(res.organisation_settings);
                setData(res.reports);
                setUsers(res.users);
                //const unassignedArray = Object.values(res.unassignedUser);
                setUnassignedData(res.unassignedUser);
                setCreatedDateEnd(moment().format(res.organisation_settings?.date_picker_format));
                setCreatedDateStart(moment().subtract(1, 'months').format(res.organisation_settings?.date_picker_format));
            })
    }

    useEffect(() => {
        loadData();
    }, [])

    const applyFilter = (event) => {
        event.preventDefault();
        const selectedDateFormat = org_date?.date_format;
        const filterData = {
            'user_id': selectedUserId,
            'created_date_start': createdDateStart,
            'created_date_end': createdDateEnd,
            'selected_date_format': selectedDateFormat
        }
        let arr = [];
        axios.post("api/get-sales-data", filterData)
            .then(res => {
                console.log(res);
                arr.push(res);
                console.log(arr)
                setData(arr);

            })
    }


    const reset = () => {
        loadData();
        setCreatedDateEnd(moment().format(org_date.date_picker_format));
        setCreatedDateStart(moment().subtract(1, 'months').format(org_date.date_picker_format));
        t_col1();
    }
    document.title = "Sales Pipeline | Onexfort";
    console.log(data,"dat123");
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Sales Filters" pageTitle="Onexfort" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <Accordion id="default-accordion-example">
                                        <AccordionItem>
                                            <h2 className="accordion-header" id="headingOne">
                                                <button
                                                    className={classnames("accordion-button fw-semibold", { collapsed: !col1 })} type="button" onClick={t_col1} style={{ cursor: "pointer" }} >
                                                    Search Filters
                                                </button>
                                            </h2>
                                            <Collapse isOpen={col1} className="accordion-collapse" id="collapseOne" >
                                                <div className="accordion-body">

                                                    <Row>

                                                        <Col lg={3}>
                                                            <div className="mb-3">
                                                                <Label for="createdDateinput" className="form-label" >Start Date</Label>
                                                                <Flatpickr
                                                                    className="form-control" placeholder='Start Date'
                                                                    options={{
                                                                        dateFormat: org_date?.date_format,
                                                                    }}
                                                                    value={createdDateStart}
                                                                    onChange={(selectedDates) => {
                                                                        if (selectedDates.length === 1) {
                                                                            setCreatedDateStart(moment(selectedDates[0]).format(org_date?.date_picker_format));
                                                                        } else {
                                                                            setCreatedDateStart(null);
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg={3}>
                                                            <div className="mb-3">
                                                                <Label for="createdDateinput" className="form-label" >End Date</Label>
                                                                <Flatpickr
                                                                    className="form-control"
                                                                    value={createdDateEnd}
                                                                    options={{
                                                                        dateFormat: org_date?.date_format,
                                                                    }}
                                                                    onChange={(selectedDates) => {
                                                                        if (selectedDates.length === 1) {
                                                                            setCreatedDateEnd(moment(selectedDates[0]).format(org_date?.date_picker_format));
                                                                        } else {
                                                                            setCreatedDateEnd(null);
                                                                        }
                                                                    }} />
                                                            </div>
                                                        </Col>
                                                        <Col lg={3}>
                                                            <Label for="Sortingorder" className="form-label">User</Label>
                                                            <select
                                                                value={selectedUserId}
                                                                onChange={(event) => setSelectedUserId(event.target.value)}
                                                                className="form-select "
                                                                aria-label="Default select example"
                                                            >
                                                                {users.map((item) => (
                                                                    <option key={item.id} value={item.user_id}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </Col>


                                                        <Col lg={5}>
                                                            <div className="text-end">
                                                                <button type="submit" className="btn form-btn-marg btn-primary"
                                                                    onClick={applyFilter}
                                                                >Apply</button>
                                                                <button type="submit" className="btn form-btn-marg btn-primary"
                                                                    onClick={reset}
                                                                >Reset</button>
                                                            </div>
                                                        </Col>

                                                    </Row>
                                                </div>
                                            </Collapse>
                                        </AccordionItem>
                                    </Accordion>
                                </CardHeader>
                                <CardBody>

                                    <Table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>User</th>
                                                <th>Quotes Created</th>
                                                <th>Jobs Confirmed</th>
                                                <th>Quotes Lost</th>
                                                <th>Emails Sent</th>
                                                <th>Total Sales</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.map((item, index) => (
                                                <tr key={index}>
                                                    <td> {item.name}</td>
                                                    <td>{item.QuotesCreated}</td>
                                                    <td>{item.jobsConfirmed}</td>
                                                    <td>{item.QuotesLost}</td>
                                                    <td>{item.emailSend}</td>
                                                    <td>{item.totalSales}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>


                                    {/* <Table className="table table-bordered mt-5">
                                        <thead className='bg-soft-grey'>
                                            <tr>
                                                <th>Quotes Created</th>
                                                <th>Jobs Confirmed</th>
                                                <th>Quotes Lost</th>
                                                <th>Emails Sent</th>
                                                <th>Total Sales</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{unassignedData.quotes_created_count}</td>
                                                <td>{unassignedData.jobs_confirmed}</td>
                                                <td>{unassignedData.quotes_lost}</td>
                                                <td>{unassignedData.email_send}</td>
                                                <td>{unassignedData.sales}</td>
                                            </tr>
                                        </tbody>
                                    </Table> */}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default SalesPipeline;
