'use strict'
import { Button, Card, CardBody, CardFooter, Col, Row } from "reactstrap";
import Dragula from 'react-dragula';
import React, { useEffect, useState, useCallback, useMemo } from "react";
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isEmpty } from "lodash";
// import $ from 'jquery'; // Import jQuery
import {
  CardHeader,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  ModalFooter,
  Table,
  FormFeedback

} from "reactstrap";
import { SketchPicker } from 'react-color';


import reactCSS from 'reactcss'

//Import actions
import {
  getContacts as onGetContacts,
  addNewContact as onAddNewContact,
  updateContact as onUpdateContact,
  deleteContact as onDeleteContact,
} from "../../../store/actions";

//import action
import {
  getTodos as onGetTodos,
  updateTodo as onupdateTodo,
  deleteTodo as onDeleteTodo,
  addNewTodo as onAddNewTodo,

  getProjects as onGetProjects,
  addNewProject as onAddNewProject,
} from "../../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import 'react-toastify/dist/ReactToastify.css';
import DeleteModal from "../../../Components/Common/DeleteModal";
import Loader from "../../../Components/Common/Loader";


const Status = ({ status }) => {
  switch (status) {
    case "New":
      return <span className="badge badge-soft-info text-uppercase">{status}</span>;
    case "Pending":
      return <span className="badge badge-soft-warning text-uppercase">{status}</span>;
    case "Inprogress":
      return <span className="badge badge-soft-secondary text-uppercase">{status}</span>;
    case "Completed":
      return <span className="badge badge-soft-success text-uppercase">{status}</span>;
    default:
      return <span className="badge badge-soft-success text-uppercase">{status}</span>;
  }
};

export const Statuses = () => {
  document.title="Statuses | Onexfort";
  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_opportunity, setmodal_opportunity] = useState(false);
  function tog_standard() {
    setmodal_standard(!modal_standard);
  }
  function tog_opportunity() {
    setmodal_opportunity(!modal_opportunity);
  }

  const [contact, setContact] = useState([]);

  const dispatch = useDispatch();
 const [data1,setData1] =useState([]);
 const [data2,setData2] =useState([]);
 const [pipeline,setPipeline] =useState('')
 const [pipelineid,setPipelineId] =useState('')
 const [pipelinestatus,setPipelineStatus] =useState('')
 const [pipelineIdd,setPiplelineIdd] = useState('')
 const [leadstatus,setLeadStatus] = useState('')
 const [leadcolor,setleadColor] = useState('')
 const [leadid,setLeadId] =useState('')
 const [colorCust, setcolorCust] = useState("#27ADCA");
 const [display_Cust, setdisplay_Cust] = useState(false);
 const paletteColors = [
              ["#000","#444","#666","#999","#ccc","#eee","#F3F3F3","#fff"],
              ["#f00","#f90","#ff0","#0f0","#0ff","#00f","#90f","#f0f"],
              ["#F4CCCC","#FCE5CD","#FFF2CC","#D9EAD3","#D0E0E3","#CFE2F3","#D9D2E9","#EAD1DC"],
              ["#EA9999","#F9CB9C","#FFE599","#B6D7A8","#A2C4C9","#9FC5E8","#B4A7D6","#D5A6BD"],
              ["#E06666","#F6B26B","#FFD966","#93C47D","#76A5AF","#6FA8DC","#8E7CC3","#C27BA0"],
              ["#c00","#E69138","#F1C232","#6AA84F","#45818E","#3D85C6","#674EA7","#A64D79"],
              ["#900","#B45F06","#BF9000","#38761D","#134F5C","#0B5394","#351C75","#741B47"],
              ["#600","#783F04","#7F6000","#274E13","#0C343D","#073763","#20124D","#4C1130"]
 ]
 const [displayColorPicker, setDisplayColorPicker] = useState(false);
 const [color, setColor] = useState({ r: 241, g: 112, b: 19, a: 1 });
 const [colour,setColour]=useState()
 const [colors,setColors] =useState();
 const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    loaddata();

  }, []);
 

  const savelead =()=>{
    const { r, g, b } = color;
    var datavalue = {
      "lead_status_id":leadid,
      "lead_status": leadstatus,
      "colorpicker_palette": (r !== undefined && g !== undefined && b !== undefined) ? `rgb(${r}, ${g}, ${b})` : "",
    }
    if(leadstatus===''){
      toast.error('Enter Lead Status', {theme: "light"});
    }
    else{

    
    if (isEdit) {
      console.log("edit");
      axios.post('/api/statuses/leadupdate', datavalue)
      .then(res => {
        console.log(res)
        tog_standard();
        toast.success(res.message, { theme: "light" });
        loaddata();
      })
      .catch(error => {
        console.error('Error fetching lead types:', error);
      });
    }
      else{

      
    axios.post('/api/statuses/store', datavalue)
      .then(res => {
        console.log(res)
        tog_standard();
        toast.success(res.message, { theme: "light" });
        loaddata();
      })
      .catch(error => {
        console.error('Error fetching lead types:', error);
      });
    }
  }

  }
  const leadEdit = (item) => {
    // console.log(item,"shhs")
    var data={
   "id": item.id
    }
    var id=item
    setLeadId(id);
      axios.post(`${"api/statuses/edit/"}${id}`)
          .then((res) => {
              console.log(res.row.status_colour,"qwertyu");
              setLeadStatus(res.row.lead_status)

              if (res.row.status_colour) {
                
                console.log(res.row.status_colour,"res")
                const [r, g, b] = res.row.status_colour.match(/\d+/g).map(Number);
                console.log( [r, g, b])
                const colorObj = { r, g, b, a: 1 };
                console.log(colorObj, "obj");
                setColor(colorObj);
            }
            else{
            setColor(241,194,50,100);          
          }
    tog_standard();
    setIsEdit(true);
        }).catch((error) => {
            console.error('Delete error:', error);
            // Handle delete error
        });
    setTodo(todo);
  };

  const loaddata = () => {
    setisLoading(true);
    axios
        .get("/api/statuses/data")
        .then((res) => {
            console.log(res);
            setData1(res.data);
            setisLoading(false)
        })
        .catch((err) => {
            console.log(err);
        });
        axios
        .get("/api/statuses/dataPipeline")
        .then((res) => {
            console.log(res,"dataPipeline");
            setData2(res.data);
          
        })
        .catch((err) => {
            console.log(err);
        });
        axios
        .get("/api/statuses/index")
        .then((res) => {
            console.log(res.op_pipelines);
            setPipeline(res.op_pipelines);
            setPipelineId(res.op_pipelines[0].id)
        })
        .catch((err) => {
            console.log(err);
        });
      
    setisLoading(false)
    };
    // const [state,setState] =useState('')
   
  
    const handleClick = () => {
      setDisplayColorPicker(!displayColorPicker);
    };
  
    const handleClose = () => {
      setDisplayColorPicker(false);
    };
  
    const handleChange = (newColor) => {
      setColor(newColor.rgb);
    //   const { r, g, b } = newColor.rgb;
    // const rgbValue = `rgb(${r}, ${g}, ${b})`;
    // setColor(newColor.rgb)
    // setColour(rgbValue)
    };

    const styles = {
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
        background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    };
  const [isEdit, setIsEdit] = useState(false);
  //delete Conatct
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalP, setDeleteModalP] = useState(false);
  const [modal, setModal] = useState(false);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setContact(null);
    } else {
      setModal(true);
      setTag([]);
      setAssignTag([]);
    }
  }, [modal]);

  // Date & Time Format

  const dateFormat = () => {
    var d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return (d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear());
  };


  const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);

  const { todos, projects } = useSelector((state) => ({
    todos: state.Todos.todos,
    projects: state.Todos.projects,
  }));
  const [taskList, setTaskList] = useState([]);
  // Project
  const [modalProject, setModalProject] = useState(false);

  useEffect(() => {
    dispatch(onGetProjects());
  }, [dispatch]);

  const [todo, setTodo] = useState(null);
  useEffect(() => {
    dispatch(onGetTodos());
  }, [dispatch]);

  useEffect(() => {
    setTodo(todos);
    setTaskList(todos);
  }, [todos]);

  // Toggle Project Modal
  const toggleProject = () => {
    if (modalProject) {
      setModalProject(false);
    } else {
      setModalProject(true);
    }
  };
  const[statusId,setStatusId] = useState('')
  // Update To do
const onClickTodoDeleteModal = (item)=>{
  setStatusId(item.id);
setDeleteModal(true);
}

  // Delete To do
  // const handleDeleteOrder = async () => {
  const onClickTodoDelete =async () => {
    var data={
   "lead_status_id": statusId
    }
    
    console.log(data,"data")


       axios.post(`/api/statuses/leaddelete`,data)
          .then((response) => {
              console.log(response);
              toast.success("Deleted Successfully",{theme:"light"});

              loaddata();

          })
          .catch((error) => {
              console.error('Delete error:', error);
              // Handle delete error
          });
    setTodo(todo);
    setDeleteModal(false);
  };

////Pipeline
const[pipelineId,setpipelineId] = useState('')
  const onClickTodoDeleteModalP = (item)=>{
    setpipelineId(item.id)
    setDeleteModalP(true)
    }
  const onClickTodoDeleteP = async() => {
    var data={
   "id": pipelineId
    }
       axios.post(`/api/statuses/delete`,data)
          .then((response) => {
              console.log(response);
              toast.success("Deleted Successfully",{theme:"light"});
              loaddata();

          })
          .catch((error) => {
              console.error('Delete error:', error);
              // Handle delete error
          });
    setTodo(todo);
    setDeleteModalP(false);
  };
  const edit = (item) => {
    console.log(item,"weee")
    var data={
   "id": item.id
    }
    var id=item.id
      axios.post(`${"api/statuses/editPipeline/"}${id}`)
          .then((res) => {
              console.log(res);
            setPipelineStatus(res.row.pipeline_status);
            setPipelineId(res.row.pipeline_id)
            setPiplelineIdd(res.row.id)
          })
          .catch((error) => {
              console.error('Delete error:', error);
              // Handle delete error
          });
    setTodo(todo);
   
  };
  // validation
  const projectValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: '',
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Project Title"),
    }),
    onSubmit: (values) => {
    
      const newProjectData = {
        
        id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
        title: values.title,
        subItem: [{ id: 1, version: "v1.0.0", iconClass: "danger" }]
      };
    
      // save new Project Data
      dispatch(onAddNewProject(newProjectData));
      projectValidation.resetForm();
      toggleProject();
    },
  });
  const dragulaDecorator = (componentBackingInstance) => {
    if (componentBackingInstance) {
      let options = {};
      Dragula([componentBackingInstance], options);
    }
  };
  const handlePipeline = (e) => {
    e.preventDefault();
    console.log("sae")
  
    var formData = {
    "pipeline_status" :pipelinestatus,
    "pipeline_id" :pipelineid,
    "pipeline_status_id": pipelineIdd,
    
    }
    console.log(formData,"id");
  if(pipelinestatus===''){
    toast.error('Enter Pipeline Status',{theme:"light"});
  }
    else{
  if (isEdit) {
      console.log("edit");
      axios.post('/api/statuses/updatePipeline', formData)
      .then((response) => {
        // Handle success
        tog_opportunity();
        console.log('Save successful', response.data);
        toast.success("Saved Successfully",{theme:"light"});
        loaddata();
      })
      .catch((error) => {
        // Handle error
        console.error('Error saving data:', error);
      });
  
  } else {
      console.log("save");
      // Handle save logic
      axios.post('/api/statuses/storePipeline', formData)
      
      .then((response) => {
        // Handle success
        tog_opportunity();
        console.log('Save successful', response.data);
        toast.success("Saved Successfully",{theme:"light"});
        loaddata();
      })
      .catch((error) => {
        // Handle error
        console.error('Error saving data:', error);
      });
  }

}
}
// const onClickDelete = (itemId) => {
//   setDeleteItemId(itemId);
//   setDeleteModal(true);
// };
////////////////



//////////
  return (
    <div>
        <DeleteModal
  show={deleteModal}
  onDeleteClick={onClickTodoDelete}
  onCloseClick={() => setDeleteModal(false)}
/>
<DeleteModal
  show={deleteModalP}
  onDeleteClick={onClickTodoDeleteP}
  onCloseClick={() => setDeleteModalP(false)}
/>
{isLoading && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            background: 'rgba(255, 255, 255, 0.8)',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            zIndex: 9999,
                                        }}
                                    >
                                        <Loader />
                                    </div>
                                )}
    
      <Card>
        <CardHeader>
        <ToastContainer closeButton={false} limit={1} theme="light"/>

          <Row>
            <Col lg={11}>
              <h5>Lead Statuses</h5>
              <p>Lead Statuses represent a Lead's current relation to your company.</p>
            </Col>
            <Col lg={1}>
            <Button className="btn btn-brown add-btn" onClick={() =>{setIsEdit(false);setLeadStatus(''); tog_standard()} }>
              <i className="ri-add-fill me-1 align-bottom"></i> Add</Button>            </Col>
          </Row>
        </CardHeader>
        <Modal id="leadModal"
          isOpen={modal_standard}
          toggle={() => {
            setLeadStatus("");
            setleadColor('')
            tog_standard();
          }}
        >
          <ModalHeader className="bg-soft-primary p-3">
            <h5 className="text-black mb-0"> {!!isEdit ? " Edit Lead Status" : "New Lead Status"}</h5>
          </ModalHeader>

          <ModalBody>
            <Label >Lead Status</Label>
            <Row>
              <Col md={6}>
              <Input type="text"  value={leadstatus}
                      onChange={(e) => setLeadStatus(e.target.value)}></Input>
              </Col>
              <Col md={2}>
                <div>
                <div>
      <div style={styles.swatch} onClick={handleClick}>
        <div style={styles.color} />
        {/* {color} */}
      </div>
      {displayColorPicker && (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={handleClose} />
          <SketchPicker color={color} value={color} onChange={handleChange} />
        </div>
      )}
    </div>   
                </div>
              </Col>
            </Row>


          </ModalBody>
          <ModalFooter className="bg-light pt-2 pb-2">
            <Button
              color="light"
              onClick={() => {
                tog_standard();
              }}
            >
              Cancel
            </Button>
            <Button
              color="success" onClick={savelead}
            >
           {!!isEdit ? " Update" : "Save"}
            </Button>
          </ModalFooter>
        </Modal>
        <CardBody>
          <div className="table-responsive">
            <table className="table align-middle position-relative table-nowrap">
              <thead className="table-active">
                <tr>
                  <th></th>
                  <th scope="col">Status</th>

                  <th scope="col">Action</th>
                </tr>
              </thead>

              <tbody id="task-list" ref={dragulaDecorator}>
              {data1.map((item, key) => (<tr key={key}>
                  <td>
                    <div className="d-flex align-items-start">
                      <div className="flex-shrink-0 me-3">
                        <div className="task-handle px-1 bg-light rounded">: :
                        </div>
                      </div>

                    </div>
                  </td>

                  <td>
                  <span dangerouslySetInnerHTML={{__html: item.status}} />
                  </td>

                  <td>
                    <div className="hstack gap-2">
                    <button className="btn btn-sm btn-soft-info edit-list"  onClick={() => {leadEdit(item.id);}}>
                        <i className="ri-pencil-fill align-bottom" />
                      </button>
                      {/* <button className="btn btn-sm btn-soft-danger remove-list" onClick={() => onClickTodoDelete(item)}> */}
                      <button className="btn btn-sm btn-soft-danger remove-list" onClick={() => onClickTodoDeleteModal(item)}>

                        <i className="ri-delete-bin-5-fill align-bottom" />
                      </button>
                    
                    </div>
                  </td>
                </tr>))}

              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>

      {/*///////////////// Pipeline            ///////////////// */}
      <Card>
        <CardHeader>
          <Row>
            <Col lg={11}>
              <h5>Opportunity Pipeline Statuses</h5>
              <p>Opportunity Statuses describe each stage of deal in the sales process.</p>
            </Col>
            <Col lg={1}>
              <Button className="btn btn-brown add-btn" onClick={() => {setIsEdit(false); tog_opportunity()
              }}> <i className="ri-add-fill me-1 align-bottom"></i>Add</Button>
            </Col>
          </Row>
        </CardHeader>
        <Modal id="pipelineModal"
          isOpen={modal_opportunity}
          toggle={() => {
            tog_opportunity();
            setPipelineStatus("")
            setPipelineId('')
          }}
        >
          <ModalHeader className="bg-soft-primary p-3">
            <h5 className="text-black mb-0">{isEdit ? "Edit Pipeline Status" : "New Pipeline Status"}</h5>
          </ModalHeader>

          <ModalBody>

            <Row className="mb-3">
              <Label >Pipeline Status</Label>
              <Col md={6}>
                <Input type="text"  value={pipelinestatus}
                      onChange={(e) => setPipelineStatus(e.target.value)}></Input>
              </Col>

            </Row>
            <Row>
              <Label >Pipeline</Label>
              <Col md={6}>
              <select
                      value={pipelineid}
                      onChange={(e) => setPipelineId(e.target.value)}
                      className="form-select mb-3"
                      >
                          <option value="">Select Pipeline Status</option>
                      {pipeline && pipeline.length > 0 ? (
                          pipeline.map((role, index) => (
                              <option key={index} value={role.id}>{role.pipeline
                              }</option>
                          ))
                      ) : (
                          <option value="">No Company found</option>
                      )}
                    </select>
              </Col>
              <input type="hidden" value={pipelineIdd}  onChange={(e) => setPiplelineIdd(e.target.value)}/>
            </Row>
            
          </ModalBody>

          <ModalFooter className="bg-light pt-2 pb-2">
          
            <Button
              color="success"  onClick={handlePipeline} 
            >
             {!!isEdit ? " Update" : "Save"}
            </Button>
            <Button
              color="light"
              onClick={() => {
                tog_opportunity();

              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <CardBody>
          <div className="table-responsive">
            <table className="table align-middle position-relative table-nowrap">
              <thead className="table-active">
                <tr>
                  <th></th>
                  <th scope="col">Name</th>
                  <th scope="col">Status</th>

                  <th scope="col">Action</th>
                </tr>
              </thead>

              <tbody id="task-list" ref={dragulaDecorator}>
              {data2.map((item, key) => (<tr key={key}>
                  <td>
                    <div className="d-flex align-items-start">
                      <div className="flex-shrink-0 me-3">
                        <div className="task-handle px-1 bg-light rounded">: :
                        </div>
                      </div>

                    </div>
                  </td>
                  <td>
                    <div className="form-check">
                      <label className="form-check-label" htmlFor={"todo" + item.id}>{item.pipeline_status} </label>
                    </div>
                  </td>
                  <td>
                    {/* <Status status={item.pipeline_id} /> */}
                    <span dangerouslySetInnerHTML={{__html: item.pipeline_id}} />
                  </td>

                  <td>
                    <div className="hstack gap-2">
                    <button className="btn btn-sm btn-soft-info edit-list" onClick={() => {tog_opportunity(); setIsEdit(true); edit(item)}}>
                        <i className="ri-pencil-fill align-bottom" />
                      </button>
                      <button className="btn btn-sm btn-soft-danger remove-list" onClick={() => onClickTodoDeleteModalP(item)}>
                        <i className="ri-delete-bin-5-fill align-bottom" />
                      </button>
                    
                    </div>
                  </td>
                </tr>))}

              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </div>
  )
}
