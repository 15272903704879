import React, { useEffect, useState } from 'react';
import {
  Table, UncontrolledDropdown,
  DropdownToggle, Container,
  DropdownMenu, DropdownItem, Card, Row, Col, Label, CardBody, CardHeader, Input, Modal, Button, ModalBody, ModalHeader
} from 'reactstrap';
import "./AppEcommerce.css"
import BreadCrumb from '../../Components/Common/BreadCrumb';
import CountUp from "react-countup";
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import moment from 'moment';
import { get_cookie } from '../../helpers/get_cookie';
import Loader from "../../Components/Common/Loader";

function AppsEcommerce() {

  const [rowsData2, setRowsData2] = useState([]);
  const [invoicecharges, setInvoiceCharges] = useState([]);
  const [data, setData] = useState([]);
  const [amount, setAmount] = useState([]);
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [products, setProducts] = useState([]);
  const [paymentItems, setPaymentItems] = useState('');
  const [selectedinvoice, setSelectedInvoiceData] = useState([]);
  const [selectedcharges, setSelectedChargesData] = useState([]);
  const [expandedinvoiceId, setExpandedInvoiceId] = useState('');
  const [expandedInvoiceChargeId, setEditCharges] = useState("");
  const [expandedpaymentId, setExpandedPaymentId] = useState('');
  const [addnewCharges, setAddNewCharges] = useState("");
  const [newcharge, setNewCharge] = useState("");
  const [newchargesAmt, setChargesAmt] = useState("");
  const [newchargetax, setNewChargeTax] = useState(0);
  const [newchargeunit, setNewChargeUnit] = useState("");
  const [newchargeqty, setNewChargeQty] = useState(1);
  const [newchargeamt, setNewChargeAmt] = useState("");
  const [newchargetype, setNewChargetype] = useState("");
  const [newchargepid, setNewChargepid] = useState("");
  const [discounttype, setDiscountType] = useState('');
  const [discountvalue, setDiscountValue] = useState('');
  const [Addnewinvoice, setAddInvoice] = useState(false);
  const [Addnewpayment, setAddPayment] = useState(false);
  const [selectedpayment, setSelectedPaymentData] = useState([]);
  const [jobid, setJobId] = useState();
  const [invoicepid, setProductId] = useState("");
  const [invoicedesc, setInvoiceDesc] = useState("");
  const [invoiceprice, setInvoicePrice] = useState("");
  const [invoicetax, setInvoiceTax] = useState("");
  const [invoiceamt, setInvoiceAmount] = useState("");
  const [invoicename, setInvoiceName] = useState("");
  const [producttype, setProductType] = useState("");
  const [invoiceqty, setInvoiceQuantity] = useState("");
  const [paymentmode, setPaymentMode] = useState('');
  const [paymentdate, setPaymentDate] = useState('');
  const [paymentdesc, setPaymentDesc] = useState('');
  const [paymentamount, setPaymentAmount] = useState('');
  const [modal, setOpenModal] = useState(false);
  const [paymentmodal, setPaymentModal] = useState(false);
  const [invoicestatus, setInvoiceStatus] = useState();
  const [jobNum, setjobNum] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const { job_id } = useParams();
  console.log(job_id);

  var total_excl_tax = 0;
  var total_tax = 0;
  var total_payments = 0;



  const loadData = () => {
    axios.post(`api/all-invoices/show/${job_id}`)
      .then(res => {
        console.log(res);
        setData(res);
        setDiscountAmt(`$ ${res.discount.toFixed(2)}`)
        setAmount(res.totalAmount);
        setInvoiceItems(res.invoiceItems);
        setJobId(res.job.job_id);
        setjobNum(res.job.job_number);
        setProducts(res.products);
        setInvoiceStatus(res.invoice_status.status);
        setInvoiceCharges(res.invoice_charges);
        setPaymentItems(res.payment_items);
      })
      .catch(err => {
        console.log(err);
      })
  }
  useEffect(() => {
    loadData();
    console.log(tenant_id)
  }, [])
  const user = JSON.parse(get_cookie("authUser"));
  var tenant_id = user.tenant_id;
  const t_editinvoice = (val) => {
    loadData();
    setExpandedInvoiceId(val);
  }

  const t_editPayment = (val) => {
    console.log(val);
    setExpandedPaymentId(val);
  }

  const addTableRows = () => {
    if (Addnewinvoice) {
      setInvoiceName();
      setInvoiceDesc();
      setInvoicePrice();
      setInvoiceAmount();
      setInvoiceQuantity();
      setAddInvoice(false);
    } else {
      setAddInvoice(true);
    }

  }
  const addNewCharges = () => {
    if (addnewCharges) {
      setNewCharge();
      setChargesAmt();
      setNewChargeTax();
      setNewChargeUnit();
      setNewChargeAmt();
      setNewChargetype();
      setNewChargepid();
      setAddNewCharges(false)
    } else {
      setAddNewCharges(true);
    }
  }
  const UpdateCharges = (data) => {
    var postData = {
      "id": data.id,
      "job_id": jobNum,
      "invoice_id": data.invoice_id,
      "product_id": data.product_id,
      "name": data.item_name,
      "tax_id": data.tax_id,
      "unit_price": data.unit_price,
      "quantity": data.quantity,
      "type": data.type,
      "amount": data.amount
    }
    axios.post("/api/ajaxUpdateInvoice", postData)
      .then((res) => {
        toast.success("Successfully edited");
        console.log(res);
        loadData();
        t_editCharges();
      })
      .catch(err => {
        toast.error("Something went wrong")
      })
  }

  const EditInvoice = (val) => {
    var postData = {
      "id": val.id,
      "job_id": jobNum,
      "invoice_id": val.invoice_id,
      "product_id": val.product_id,
      "name": val.item_name,
      "description": val.item_summary,
      "tax_id": val.tax_id,
      "unit_price": val.unit_price,
      "quantity": val.quantity,
      "type": val.type,
      "amount": val.amount,
    }
    axios.post("api/ajaxUpdateInvoice", postData).then((res) => {
      if (res.error == 0) {
        toast.success(res.message);
        t_editinvoice();
        loadData();
      }
      else {
        toast.error("Something went wrong");
      }
    })
  }
  const SaveInvoice = () => {
    var postData = {
      "job_id": jobNum,
      "invoice_id": job_id,
      "product_id": invoicepid,
      "name": invoicename,
      "description": invoicedesc,
      "tax_id": invoicetax,
      "unit_price": invoiceprice,
      "quantity": invoiceqty,
      "type": producttype,
      "amount": invoiceamt,
    }
    axios.post("api/ajaxSaveInvoice", postData).then((res) => {
      if (res.error == 0) {
        toast.success(res.message);
        loadData();
        addTableRows();
      }
      else {
        toast.error("Something went wrong");
      }
    })
  }
  const t_deleteItem = (val, data = "") => {
    if (val == "invoice") {
      setSelectedInvoiceData(data);
      setOpenModal(true);
    }
    else if (val == "charges") {
      setSelectedChargesData(data);
      setSelectedInvoiceData();
      setOpenModal(true);
    }
    else {

      setSelectedInvoiceData();
      setOpenModal(false);
    }
  }
  const deleteInvoice = () => {
    console.log(selectedinvoice);
    if (selectedinvoice && selectedinvoice != "") {
      var postData = {
        "job_id": jobid,
        "invoice_id": selectedinvoice.invoice_id,
        "id": selectedinvoice.id
      }
      axios.post("api/ajaxDestroyInvoiceItem", postData).then((res) => {
        if (res.error == 0) {
          toast.success(res.message);
          loadData();
          t_deleteItem();
        }
        else {
          toast.error("Something went wrong");
        }
      })
    }
    else if (selectedcharges && selectedcharges != "") {
      var postData = {
        "job_id": jobNum,
        "invoice_id": selectedcharges.invoice_id,
        "id": selectedcharges.id
      }
      axios.post("api/ajaxDestroyInvoiceItem", postData).then((res) => {
        if (res.error == 0) {
          toast.success(res.message);
          loadData();
          t_deleteItem();
        }
        else {
          toast.error("Something went wrong");
        }
      })
    }
  }
  const t_editCharges = (data) => {
    setEditCharges(data)
  }
  const InvoiceChargeUpdate = (data, price) => {
    var postDatas = {
      "invoice_id": data.invoice_id.toString(),
      "price": price
    }
    //setIsUpdating(true);
    axios.post("api/ajaxCalculateChargePrice", postDatas).then((res) => {
      const updateddata = [...invoicecharges];
      const taskIndex = updateddata.findIndex((task) => task.id === data.id);
      var tax_amount = data.taxs.map((val) => (
        (data.tax_id == val.id) ? val.rate_percent : ""));
      updateddata[taskIndex].unit_price = res;

      console.log((1 + tax_amount[0] / 100));
      updateddata[taskIndex].amount = parseFloat((updateddata[taskIndex].unit_price * updateddata[taskIndex].quantity) * (1 + tax_amount[0] / 100)).toFixed(2);
      setInvoiceCharges(updateddata);
      // setTimeout(() => { setIsUpdating(false) }, 2000);
    })
  }

  const SaveCharges = (data) => {
    if (newchargepid == "" || newcharge == "" || newchargetax == "" || newchargeunit == "" || newchargeqty == "" || newchargetype == "" || newchargeamt == "") {
      toast.error("Please fill all the necessary details");
      return false;
    }
    var sendData = {
      "job_id": jobNum,
      "invoice_id": job_id,
      "product_id": newchargepid,
      "name": newcharge,
      "tax_id": newchargetax,
      "unit_price": newchargeunit,
      "quantity": newchargeqty,
      "type": newchargetype,
      "amount": newchargeamt
    }
    axios.post("/api/ajaxSaveInvoice", sendData).then((res) => {
      console.log(res);
      if (res.error == 0) {
        toast.success(res.message);
        loadData();
        addNewCharges();
      } else {
        toast.error("Something went wrong");
      }
    })
  }

  const SelectDiscount = (val) => {
    if (val == "cancel") {
      data.discount = 0;
      setDiscountType("");
    } else if (val == "save") {
      setDiscountType("");
    }
    else {
      setDiscountType(val);
    }
  }
  const t_addnewpayment = (val) => {
    //getData();
    if (Addnewpayment == true) {
      setPaymentAmount();
      setPaymentDate();
      setPaymentDesc();
      setPaymentMode();
      setAddPayment(false);
    } else {
      setAddPayment(true);
    }
  }

  const UpdatePayment = (val) => {
    console.log(val);
    var postData = {
      "id": val.id,
      "job_id": jobNum,
      "invoice_id": job_id,
      "gateway": val.gateway,
      "description": val.remarks,
      "amount": val.amount,
      "paid_on": moment(val.paid_on).format("YYYY-MM-DD"),
      "sys_job_type": "Moving_Storage"
    }
    axios.post("api/ajaxUpdatePayment", postData).then((res) => {
      if (res.error == 0) {
        toast.success(res.message);
        loadData();
        t_editPayment();
      }
      else {
        toast.error("Something went wrong");
      }
    })
  }
  const SavePayment = () => {
    var postData = {
      "job_id": jobNum,
      "invoice_id": job_id,
      "gateway": paymentmode,
      "description": paymentdesc,
      "amount": paymentamount,
      "sys_job_type": "Moving_Storage"
    }
    axios.post("api/ajaxSavePayment", postData).then((res) => {
      if (res.error == 0) {
        toast.success(res.message);
        loadData();
        t_addnewpayment();
      }
      else {
        toast.error("Something went wrong");
      }
    })
  }


  const t_deletePayment = (val = "") => {
    if (val != "") {
      setSelectedPaymentData(val);
      setPaymentModal(true);
    } else {
      setPaymentModal(false);
    }
  }
  const deletePayment = () => {
    console.log(selectedpayment);
    var postData = {
      "job_id": jobNum,
      "invoice_id": job_id,
      "id": selectedpayment.id,
      "sys_job_type": "Moving_Storage"
    }
    axios.post("api/ajaxDestroyPaymentItem", postData).then((res) => {
      if (res.error == 0) {
        toast.success(res.message);
        loadData();
        t_deletePayment();
      }
      else {
        toast.error("Something went wrong");
      }
    })
  }

  const [discountamt, setDiscountAmt] = useState()
  const [showdiscount, SetDiscountDisplay] = useState(false);
  const [discountVal, setDiscountVal] = useState()
  // const [discount,setDiscount]=useState("")
  // const [depositamt, setDepositAmt] = useState(data?.quote?.deposit_required);

  const [afterDiscountVal, setAfterDiscountVal] = useState([])


  const submitDiscount = () => {
    SetDiscountDisplay(false)
    if (discounttype === "Percent") {
      setAfterDiscountVal((parseFloat(total_excl_tax) - (parseInt(discountVal) / 100 * parseFloat(total_excl_tax))).toFixed(2))
    }
    else if (discounttype === "Fixed") {
      setAfterDiscountVal((total_excl_tax - parseInt(discountVal)).toFixed(2))
    }
    setDiscountAmt(discounttype === "Percent" ? discountVal + "%" : "$" + discountVal)
    setDiscountType('')
  }

  const handleGenerate = () => {
    setIsLoading(true);
    let req = {
      "job_id": jobid,
      "tenant_id": tenant_id,
      "manual_action": "Y"
    }
    axios.post("api/listgenerateInvoice", req)
      .then(res => {
        console.log(res);
        if (res.success === true) {
          toast.success(res.message);
        }
        else {
          toast.error(res.message);
        }
        setIsLoading(false);
      })
      .catch(err => {
        console.log(err);
      })
  }
  const handleDownload = () => {

    axios.post(`api/list-jobs/downloadInvoice/${jobid}`)
      .then(res => {
        console.log(res);
        window.open(res.url, "_blank");
      })
      .catch(err => {
        console.log(err);
      })
  }
  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title="Invoice Details" pageTitle="Onexfort" />
        <Card>
          <CardHeader className=''>
            <h4> <div className='d-flex align-items-center text-dark '><i className='ri-bill-line billIcon'></i>Invoices </div></h4>
          </CardHeader>
          <div className="ribbon-box border shadow-none mb-lg-0 ">
            <div className="ribbon ribbon-primary ribbon-shape fs-5">{invoicestatus}</div>
          </div>
          <CardBody>
            <Row className="mt-5">
              <Col lg={3} className='card ' >
                <div className="mt-3 mt-md-0 py-4 px-3 job-container  ">
                  <h5 className=" text-uppercase fs-13 text-white">Job <i className={"fs-18 float-end align-middle " + "ri-arrow-up-circle-line text-success"}></i></h5>
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <i className={"display-6  " + "ri-space-ship-line"}></i>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <h2 className="mb-0"><span className="counter-value text-white">
                        {jobNum}</span></h2>
                    </div>
                  </div>
                </div>
              </Col>

              <Col lg={3} className='card'  >
                <div className="mt-3 mt-md-0 py-4 px-3 amount-container">
                  <h5 className=" text-uppercase fs-13 text-white">Total Amount  <i className={"fs-18 float-end align-middle " + "ri-arrow-up-circle-line text-success"}></i></h5>
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <i className={"display-6 " + " ri-exchange-dollar-line"}></i>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <h2 className="mb-0"><span className="counter-value text-white">
                        {amount}</span></h2>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={3} className='card'  >
                <div className="mt-3 mt-md-0 py-4 px-3 paid-container">
                  <h5 className="text-uppercase fs-13 text-white">Total Paid  <i className={"fs-18 float-end align-middle " + "ri-arrow-up-circle-line text-success"}></i></h5>
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <i className={"display-6  " + " ri-exchange-dollar-line"}></i>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <h2 className="mb-0"><span className="counter-value text-white">
                        {data.paidAmount}</span></h2>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={3} className='card'  >
                <div className="mt-3 mt-md-0 py-4 px-3 due-container">
                  <h5 className=" text-uppercase fs-13 text-white">Total Due <i className={"fs-18 float-end align-middle " + "ri-arrow-up-circle-line text-success"}></i></h5>
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <i className={"display-6  " + " ri-exchange-dollar-line"}></i>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <h2 className="mb-0"><span className="counter-value text-white" >
                        {amount - data.paidAmount}</span></h2>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
          <div className='d-flex justify-content-end m-2'>
            <Link to={"/listjobs/listjobsdetail/" + jobNum}>
              <button className='btnJobs'>Job {jobNum}</button>
            </Link>
          </div>

          <div className='mt-3'>
            <Card >
              <CardBody className='mt-3'>
                <div className='d-flex align-items-center justify-content-between mt-3 mb-3'>
                  <h2 className='px-3'>Invoice</h2>
                  <span className='span111 px-3'>{jobNum}</span>
                </div>
                <div>
                  <hr />

                  <div className='d-flex flex-column align-items-start mt-3 px-3 pb-3'>
                    <button className='btn btn-primary'
                      onClick={handleGenerate}><i className='ri-clipboard-fill'

                      ></i>Generate Invoice PDF</button>
                    <button className='btn btn-primary mt-1'
                      onClick={handleDownload}><i className='ri-file-edit-line'></i>Download</button>
                  </div>
                </div>
              </CardBody>
              {isLoading && (
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'rgba(255, 255, 255, 0.8)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 9999,
                  }}
                >
                  <Loader />
                </div>
              )}
            </Card>
          </div>

          <CardBody className='mt-5'>

            <Table className='table-bordered'>
              <thead className='bg-soft-purple'>
                <tr>
                  <th className='text-center'>Item Name & Description</th>
                  <th className='text-center'>Tax</th>
                  <th className='text-center'>Unit Price</th>
                  <th className='text-center'>Qty</th>
                  <th className='text-center'>Total Inc Tax</th>
                  <th>Actions </th>
                </tr>
              </thead>
              <tbody>
                {invoiceItems?.map((res) => {
                  total_excl_tax += (res.unit_price * res.quantity);
                  total_tax += res.amount - (res.unit_price * res.quantity);
                  return (<>{(expandedinvoiceId != res.id) && <tr key={res.id}>
                    <td>
                      <span>{res.item_name}</span><br />
                      <span>{res.item_summary}</span>
                    </td>
                    <td>{data?.taxs?.map((val) => (
                      (res.tax_id == val.id) ? val.tax_name : ""))}</td>
                    <td> {data?.global.currency_symbol} {parseFloat(res.unit_price).toFixed(2)}</td>
                    <td>{parseFloat(res.quantity).toFixed(2)}</td>
                    <td>{data?.global.currency_symbol} {parseFloat(res.amount).toFixed(2)}</td>
                    <td>
                      <div className='hstack gap-2'>
                        <button className="btn btn-sm btn-soft-info edit-list" onClick={() => t_editinvoice(res.id)}
                        >
                          <i className="bx bxs-pencil fs-12 pt-1"></i>
                        </button>
                        <button className="btn btn-sm btn-soft-danger edit-list" onClick={() => t_deleteItem("invoice", res)}
                        >
                          <i className="bx bxs-trash fs-12 pt-1"></i>
                        </button>
                      </div>
                    </td>
                  </tr>}
                    {(expandedinvoiceId == res.id) && <tr key={res.id}>
                      <td>
                        <span><Input type="text" list="data" value={res.item_name} onChange={(e) => {
                          const enteredValue = e.target.value.split(",")
                          const updateddata = [...invoiceItems];
                          const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                          if (enteredValue.length > 1) {
                            var invoiceData = products?.filter((val) => (
                              (val.id == enteredValue[1])));
                            console.log(invoiceData);
                            if (invoiceData.length > 0) {
                              updateddata[taskIndex].item_name = invoiceData[0].name;
                              updateddata[taskIndex].item_summary = invoiceData[0].description;
                              updateddata[taskIndex].tax_id = invoiceData[0].tax_id;
                              updateddata[taskIndex].unit_price = parseFloat(invoiceData[0].price).toFixed(2);
                              updateddata[taskIndex].quantity = 1;
                              updateddata[taskIndex].amount = parseFloat(invoiceData[0].total_amount).toFixed(2);
                            }
                          } else {
                            updateddata[taskIndex].item_name = enteredValue[0]
                          }
                          setInvoiceItems(updateddata);
                        }} /></span>
                        <datalist id="data">
                          {products?.map((item, key) =>
                            <option key={key} value={"onexfort," + item.id} data-pid={item.id} data-desc={item.description} data-price={item.price} data-tax={item.tax_id} data-type={item.product_type}>{item.name}</option>
                          )}
                        </datalist>
                        <br />
                        <span><Input value={res.item_summary} type="text" placeholder="Description" onChange={(e) => {
                          const updateddata = [...invoiceItems];
                          const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                          updateddata[taskIndex].item_summary = e.target.value;
                          setInvoiceItems(updateddata);
                        }} /></span>
                      </td>
                      <td><select className="form-select" value={res.tax_id} onChange={(e) => {
                        const updateddata = [...invoiceItems];
                        const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                        updateddata[taskIndex].tax_id = e.target.value;

                        var tax_amount = products.tax.map((val) => (
                          (e.target.value == val.id) ? val : ""));
                        var taxrate = (tax_amount.length > 0 && tax_amount[0] != "") ? tax_amount[0].rate_percent : 0;
                        var totalAmount = (parseFloat(updateddata[taskIndex].unit_price).toFixed(2) * parseFloat(updateddata[taskIndex].quantity)).toFixed(2) * (1 + parseFloat(taxrate).toFixed(2) / 100);
                        updateddata[taskIndex].amount = parseFloat(totalAmount).toFixed(2);
                        setInvoiceItems(updateddata);
                      }}>
                        <option value="0"></option>
                        {data?.taxs.map((res) => {
                          return (<option key={res.id} value={res.id}>{res.tax_name}</option>)
                        })}
                      </select></td>
                      <td><Input type="number" value={res.unit_price} step={0.00} onChange={(e) => {
                        const updateddata = [...invoiceItems];
                        const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                        updateddata[taskIndex].unit_price = e.target.value;
                        var tax_amount = data.taxs.map((val) => (
                          (res.tax_id == val.id) ? val : ""));
                        if (tax_amount.length > 0) {
                          tax_amount = tax_amount[0];
                        }
                        var totalAmount = (parseFloat(updateddata[taskIndex].unit_price).toFixed(2) * parseFloat(updateddata[taskIndex].quantity)).toFixed(2) * (1 + parseFloat(tax_amount.rate_percent).toFixed(2) / 100);
                        updateddata[taskIndex].amount = parseFloat(totalAmount).toFixed(2);
                        setInvoiceItems(updateddata);
                      }} /></td>
                      <td><Input type="number" value={res.quantity} step={0.00} onChange={(e) => {
                        const updateddata = [...invoiceItems];
                        const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                        updateddata[taskIndex].quantity = e.target.value;
                        var tax_amount = data.taxs.map((val) => (
                          (res.tax_id == val.id) ? val : ""));
                        if (tax_amount.length > 0) {
                          tax_amount = tax_amount[0];
                        }
                        var totalAmount = (parseFloat(updateddata[taskIndex].unit_price).toFixed(2) * parseFloat(updateddata[taskIndex].quantity)).toFixed(2) * (1 + parseFloat(tax_amount.rate_percent).toFixed(2) / 100);
                        updateddata[taskIndex].amount = parseFloat(totalAmount).toFixed(2);
                        setInvoiceItems(updateddata);
                      }} /></td>
                      <td><Input type="number" value={res.amount} step={0.00} onChange={(e) => {
                        const updateddata = [...invoiceItems];
                        const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                        updateddata[taskIndex].amount = e.target.value;
                        var tax_amount = data.taxs.map((val) => (
                          (res.tax_id == val.id) ? val : ""));
                        if (tax_amount.length > 0) {
                          tax_amount = tax_amount[0];
                        }
                        var unitPrice = ((parseFloat(updateddata[taskIndex].amount).toFixed(2) / parseFloat(updateddata[taskIndex].quantity)).toFixed(2)) / (1 + parseFloat(tax_amount.rate_percent).toFixed(2) / 100);
                        updateddata[taskIndex].unit_price = parseFloat(unitPrice).toFixed(2);
                        setInvoiceItems(updateddata);
                      }} /></td>
                      <td>
                        <div className='hstack gap-2'>
                          <button className="btn btn-sm btn-light edit-list" onClick={() => t_editinvoice()}
                          >
                            Cancel
                          </button>
                          <button className="btn btn-sm btn-success edit-list" onClick={() => EditInvoice(res)}
                          >
                            Update
                          </button>
                        </div>
                      </td>
                    </tr>}</>)
                })}
                {(Addnewinvoice) && <tr>
                  <td>
                    <span><Input type="text" list="data" value={invoicename} onChange={(e) => {
                      const enteredValue = e.target.value.split(",")
                      if (enteredValue.length > 1) {
                        var invoiceData = products?.filter((val) => (
                          (val.id == enteredValue[1])));
                        console.log(invoiceData);
                        if (invoiceData.length > 0) {
                          setProductId(invoiceData[0].id);
                          setProductType(invoiceData[0].product_type)
                          setInvoiceName(invoiceData[0].name);
                          setInvoiceDesc(invoiceData[0].description);
                          setInvoiceTax(invoiceData[0].tax_id);
                          setInvoicePrice(parseFloat(invoiceData[0].price).toFixed(2));
                          setInvoiceQuantity(1);
                          setInvoiceAmount(parseFloat(invoiceData[0].total_amount).toFixed(2));
                        }
                      } else {
                        setInvoiceName(enteredValue[0]);
                      }
                    }} /></span>
                    <datalist id="data">
                      {products?.map((item, key) =>
                        <option key={key} value={"onexfort," + item.id} data-pid={item.id} data-desc={item.description} data-price={item.price} data-tax={item.tax_id} data-type={item.product_type}>{item.name}</option>
                      )}
                    </datalist>
                    <br />
                    <span><Input type="text" value={invoicedesc} placeholder="Description" onChange={(e) => {
                      setInvoiceDesc(e.target.value);
                    }} /></span>
                  </td>
                  <td><select className="form-select" value={invoicetax} onChange={(e) => {
                    var tax_amount = data.taxs.map((val) => (
                      (e.target.value == val.id) ? val : ""));
                    var taxrate = (tax_amount.length > 0 && tax_amount[0] != "") ? tax_amount[0].rate_percent : 0;
                    var totalAmount = (parseFloat(invoiceprice).toFixed(2) * parseFloat(invoiceqty)).toFixed(2) * (1 + parseFloat(taxrate).toFixed(2) / 100);
                    setInvoiceAmount(parseFloat(totalAmount).toFixed(2));
                    setInvoiceTax(e.target.value);
                  }}>
                    <option value="0"></option>
                    {data?.taxs?.map((res) => {
                      return (
                        <option key={res.id} value={res.id}>{res.tax_name}</option>
                      )
                    })}
                  </select></td>
                  <td><Input type="number" value={invoiceprice} onChange={(e) => {
                    setInvoicePrice(e.target.value);
                    var tax_amount = data.taxs.map((val) => (
                      (invoicetax == val.id) ? val : ""));
                    if (tax_amount.length > 0) {
                      tax_amount = tax_amount[0];
                    }
                    var totalAmount = (parseFloat(e.target.value).toFixed(2) * parseFloat(invoiceqty).toFixed(2)) * (1 + parseFloat(tax_amount.rate_percent).toFixed(2) / 100);
                    setInvoiceAmount(parseFloat(totalAmount).toFixed(2));
                  }} step={0.00} /></td>
                  <td><Input type="number" value={invoiceqty} step={0.00} onChange={(e) => {
                    setInvoiceQuantity(e.target.value);
                    var tax_amount = data.taxs.map((val) => (
                      (invoicetax == val.id) ? val : ""));
                    if (tax_amount.length > 0) {
                      tax_amount = tax_amount[0];
                    }
                    var totalAmount = (parseFloat(invoiceprice).toFixed(2) * parseFloat(e.target.value)).toFixed(2) * (1 + parseFloat(tax_amount.rate_percent).toFixed(2) / 100);
                    setInvoiceAmount(parseFloat(totalAmount).toFixed(2));
                  }} /></td>
                  <td><Input type="number" value={invoiceamt} step={0.00} onChange={(e) => {
                    setInvoiceAmount(e.target.value);
                    var tax_amount = data.taxs.map((val) => (
                      (invoicetax == val.id) ? val : ""));
                    if (tax_amount.length > 0) {
                      tax_amount = tax_amount[0];
                    }
                    var unitPrice = ((parseFloat(e.target.value).toFixed(2) / parseFloat(invoiceqty)).toFixed(2)) / (1 + parseFloat(tax_amount.rate_percent).toFixed(2) / 100);
                    setInvoicePrice(parseFloat(unitPrice).toFixed(2));
                  }} /></td>
                  <td>
                    <div className='hstack gap-2'>
                      <button className="btn btn-sm btn-light edit-list" onClick={addTableRows}
                      >
                        Cancel
                      </button>
                      <button className="btn btn-sm btn-success edit-list" onClick={() => SaveInvoice()}
                      >
                        Save
                      </button>
                    </div>
                  </td>
                </tr>}
              </tbody>
            </Table>
            <button className="btn btn-primary" onClick={addTableRows} >+</button>
          </CardBody>
          <Card>


            <div className='d-flex m-3 align-items-center'>
              <p className=''>Charges</p>
              <div className='mx-3'>
                <button className='btn btn-success'>Recalculate</button>
              </div>
            </div>
            <Table className='m-3 table-bordered col-lg-12'>
              <tbody>
                {invoicecharges.map((res) => {
                  total_excl_tax += (res.unit_price * res.quantity);
                  console.log(res.amount)
                  total_tax += res.amount - (res.unit_price * res.quantity);

                  return (<>
                    {(expandedInvoiceChargeId != res.id) && <tr key={res.id}>
                      <td>
                        <span>{res.item_name}</span><br />
                        <span>{res.description}</span>
                      </td>
                      <td>
                        {data.taxs.map((val) => (
                          (res.tax_id == val.id) ? val.tax_name : ""))}
                      </td>
                      <td>{data?.global.currency_symbol} {parseFloat(res.unit_price).toFixed(2)}</td>
                      {/* <td>{(res.quantity).toFixed(2)}</td> */}
                      <td>{data?.global.currency_symbol} {parseFloat(res.quantity).toFixed(2)}</td>
                      <td>{res.amount}</td>
                      <td>
                        <div className='hstack gap-2'>
                          <Button className="btn btn-sm btn-soft-info edit-list" onClick={(e) => t_editCharges(res.id)}>
                            <i className="bx bxs-pencil fs-12 pt-1"></i>
                          </Button>
                          <Button className=" btn btn-sm btn-soft-danger fs-13 pt-1" onClick={(e) => t_deleteItem("charges", res)}>
                            <i className="bx bxs-trash fs-12"></i>
                          </Button>
                        </div>
                      </td>
                    </tr>}
                    {(expandedInvoiceChargeId == res.id) && <tr>
                      <td>
                        <select className='form-control' value={res.item_name} onChange={(e) => {
                          const selectedIndex = e.target.selectedIndex;
                          const selectedOption = e.target.options[selectedIndex];
                          const price = selectedOption.getAttribute('data-price');
                          const updateddata = [...invoicecharges];
                          const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                          updateddata[taskIndex].item_name = e.target.value;
                          InvoiceChargeUpdate(res, price, taskIndex)
                          var tax_amount = data.taxs.map((val) => (
                            (res.tax_id == val.id) ? val.rate_percent : ""));
                          updateddata[taskIndex].amount = parseFloat((res.unit_price * res.quantity) * (1 + tax_amount[0] / 100)).toFixed(2);
                          setInvoiceCharges(updateddata);
                        }}>
                          <option value="0"></option>
                          {data.charges.map((res) => {
                            return (<option key={res.id} data-price={res.price} value={res.name}>{res.name}</option>)
                          })}
                        </select>
                      </td>
                      <td>
                        <select className='form-control' value={res.tax_id} onChange={(e) => {
                          const updateddata = [...invoicecharges];
                          const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                          updateddata[taskIndex].tax_id = e.target.value;
                          setInvoiceCharges(updateddata);
                        }}>
                          <option value="0"></option>
                          {data.taxs.map((res) => {
                            return (<option key={res.id} value={res.id}>{res.tax_name}</option>)
                          })}
                        </select>
                      </td>
                      <td>
                        <Input type='number' className='form-control' value={res.unit_price} onChange={(e) => {
                          const updateddata = [...invoicecharges];
                          const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                          updateddata[taskIndex].unit_price = e.target.value;
                          var tax_amount = data.taxs.map((val) => (
                            (res.tax_id == val.id) ? val.rate_percent : ""));
                          console.log(tax_amount);
                          updateddata[taskIndex].amount = parseFloat((res.unit_price * res.quantity) * (1 + tax_amount[0] / 100)).toFixed(2);
                          setInvoiceCharges(updateddata);
                        }} />
                      </td>
                      <td>
                        <Input type='number' className='form-control' value={res.quantity} disabled />
                      </td>
                      <td>
                        <Input type='number' className='form-control' value={res.amount} disabled />
                      </td>
                      <td>
                        <Button className='btn btn-light py-1 mx-1' onClick={() => t_editCharges()}>Cancel</Button>
                        <Button className='btn btn-success py-1 mx-1 my-1' onClick={() => UpdateCharges(res)}
                        >Update</Button>
                      </td>
                    </tr>}
                  </>)
                })}
                {addnewCharges && <tr>
                  <td>
                    <select className='form-control' onChange={(e) => {
                      const selectedIndex = e.target.selectedIndex;
                      const selectedOption = e.target.options[selectedIndex];
                      const price = selectedOption.getAttribute('data-price');
                      const type = selectedOption.getAttribute('data-type');
                      const pid = selectedOption.getAttribute('data-pid');
                      setNewChargepid(pid);
                      setNewChargetype(type);
                      setNewCharge(e.target.value);
                      var postDatas = {
                        "invoice_id": job_id.toString(),
                        "price": price
                      }
                      //setIsUpdating(true);
                      axios.post("api/ajaxCalculateChargePrice", postDatas).then((res) => {
                        var tax_amount = data.taxs.map((val) => (
                          (newchargetax == val.id) ? val.rate_percent : ""));
                        setNewChargeUnit(parseFloat(res).toFixed(2));
                        var chargemamount = parseFloat((parseFloat(res).toFixed(2) * newchargeqty) * (1 + tax_amount[0] / 100)).toFixed(2);
                        setNewChargeAmt(chargemamount);
                      })
                    }} >
                      <option value="0"></option>
                      {data.charges.map((res) => {
                        return (<option key={res.id} data-price={res.price} data-pid={res.id} data-type={res.product_type} value={res.name}>{res.name}</option>)
                      })}
                    </select>
                  </td>
                  <td>
                    <select className='form-control' onChange={(e) => {
                      var tax_amount = data.taxs.map((val) => (
                        (e.target.value == val.id) ? val.rate_percent : ""));
                      setNewChargeTax(e.target.value)
                      var chargemamount = parseFloat((newchargeunit * newchargeqty) * (1 + tax_amount[0] / 100)).toFixed(2);
                      setNewChargeAmt(chargemamount);
                    }}>
                      <option value="0"></option>
                      {data.taxs.map((res) => {
                        return (<option key={res.id} value={res.id}>{res.tax_name}</option>)
                      })}
                    </select>
                  </td>
                  <td>
                    <Input className='form-control' type='number' value={newchargeunit} />
                  </td>
                  <td>
                    <Input className='form-control' type='number' value={newchargeqty} disabled />
                  </td>
                  <td>{data?.global.currency_symbol}{newchargeamt}</td>
                  <td>
                    <Button className='btn btn-light py-1 mx-1' onClick={() => addNewCharges()}>Cancel</Button>
                    <Button className='btn btn-success py-1 mx-1 my-1' onClick={() => SaveCharges()}
                    >Save</Button>
                  </td>
                </tr>
                }
              </tbody>
              <Button className="btn btn-brown mt-3" onClick={() => addNewCharges()} >+</Button>
            </Table>
            <Row>
              <Col lg={6}></Col>
              <Col lg={6}>
                <Table className="table-bordered ">
                  <tbody>
                    <tr>
                      <th className='bg-soft-purple'><span>Total (excl tax)</span></th>
                      <td><span id="grand_total_excl_tax">{data?.global?.currency_symbol}{total_excl_tax}</span></td>
                    </tr>
                    <tr>
                      <th className='bg-soft-purple'><span>Discount {(discounttype) ? "- " + discounttype : ""}</span>
                        <UncontrolledDropdown className="dropdown d-inline-block">
                          <DropdownToggle className="btn fs-18" tag="button">
                            <i className="ri-more-fill"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem className='edit-item-btn' onClick={() => SelectDiscount("Fixed")}>
                              <i className="ri-money-dollar-circle-line align-bottom me-2 text-muted"></i>Fixed
                            </DropdownItem>
                            <DropdownItem className='remove-item-btn' onClick={() => SelectDiscount("Percent")}>
                              <i className="ri-percent-fill align-bottom me-2"></i>Percent
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </th>
                      <td>{discounttype == "" &&
                        <span id="grand_total_incl_tax">{(discountamt)}</span>}
                        {discounttype &&
                          <span>
                            <div className="input-group input-group-sm">
                              <input type="number" id="s_discount_value_field" className="form-control mb-2" onChange={(e) => {
                                console.log(e.target.value);
                                setDiscountVal(e.target.value);
                              }} />
                            </div>
                            <div className="d-flex justify-content-start align-items-center m-t-10">
                              <button id="s_cancel_discount_btn" type="button" className="btn btn-light" onClick={() => SelectDiscount("cancel")}> Cancel</button>
                              <button id="s_save_discount_btn" type="button" className="btn btn-success mx-2" onClick={() => submitDiscount()}> Save</button>
                            </div>
                          </span>}
                      </td>
                    </tr>
                    <tr>
                      <th className='bg-soft-purple'><span>Total (excl tax) after discount</span></th>
                      <td><span id="grand_total_incl_tax">{data?.global?.currency_symbol}{afterDiscountVal == 0 ? total_excl_tax : afterDiscountVal}</span></td>
                    </tr>
                    <tr>
                      <th className='bg-soft-purple'><span>Tax</span></th>
                      <td><span id="grand_total_tax">{data?.global?.currency_symbol}{parseFloat(total_tax).toFixed(2)}</span></td>
                    </tr>
                    <tr>
                      <th className='bg-soft-purple'><span>Total (incl tax)</span></th>
                      <td><span id="grand_total_incl_tax">{data?.global?.currency_symbol} {parseFloat((discounttype == "percent") ? parseFloat(total_tax + total_excl_tax - (discountvalue / 100 * total_excl_tax)).toFixed(2) : parseFloat(total_tax + total_excl_tax - discountvalue).toFixed(2)).toFixed(2)}</span></td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <div className='m-3 hstack gap-3'>
              <span className="badge badge-soft-success badge-border fs-14">PAYMENTS</span>
            </div>
            <div className='m-3'>
              <Table className='table-bordered mt-4'>
                <thead className='bg-soft-purple'>
                  <tr>
                    <th>Payment Method</th>
                    <th>Reference / Notes</th>
                    <th>Paid on</th>
                    <th>Amount</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentItems && paymentItems.map((res) => {
                    total_payments = parseFloat(total_payments) + parseFloat(res.amount);
                    return (<>{<tr key={res.id}>
                      <td>{res.gateway}</td>
                      <td>{res.remarks}</td>
                      <td>{moment(res.paid_on).format(data?.global?.date_format)}</td>
                      <td>{data?.global?.currency_symbol}{parseFloat(res.amount).toFixed(2)}</td>
                      <td>
                        <div className='hstack gap-2 justify-content-center'>
                          <Button className="btn btn-sm btn-soft-info edit-list" onClick={() => t_editPayment(res.id)}
                          >
                            <i className="bx bxs-pencil fs-12 pt-1"></i>
                          </Button>
                          <Button
                            className=" btn btn-sm btn-soft-danger fs-13 pt-1" onClick={() => t_deletePayment(res)}
                          >
                            <i className="bx bxs-trash fs-12"></i>
                          </Button>
                        </div>
                      </td>
                    </tr>}
                      {(expandedpaymentId == res.id) && <tr>
                        <td>
                          <select className='form-control' value={res.gateway} onChange={(e) => {
                            const updateddata = [...data.payment_items];
                            const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                            updateddata[taskIndex].gateway = e.target.value;
                            setPaymentItems(updateddata);
                          }}>

                            {data?.payment_methods.map((res) => {
                              return (<option key={res.id} value={res.name}>{res.name}</option>)
                            })}
                          </select>
                        </td>
                        <td>
                          <textarea className='form-control' value={res.remarks} onChange={(e) => {
                            const updateddata = [...data.payment_items];
                            const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                            updateddata[taskIndex].remarks = e.target.value;
                            setPaymentItems(updateddata);
                          }}></textarea>
                        </td>
                        <td>
                          <Flatpickr
                            className="form-control"
                            options={{
                              dateFormat: "Y-m-d",
                              defaultDate: [moment(res.paid_on).format("YYYY-MM-DD")]
                            }} onChange={(selectedDate) => {
                              const updateddata = [...data.payment_items];
                              const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                              updateddata[taskIndex].paid_on = moment(selectedDate[0]).format("YYYY-MM-DD");
                              setPaymentItems(updateddata);
                            }} />
                        </td>
                        <td>
                          <Input className='form-control' value={res.amount} onChange={(e) => {
                            console.log(parseFloat(e.target.value).toFixed(2));
                            const updateddata = [...data.payment_items];
                            const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                            updateddata[taskIndex].amount = e.target.value;
                            console.log(updateddata[taskIndex]);
                            setPaymentItems(updateddata);
                          }} />
                        </td>
                        <td>
                          <div className='hstack gap-2'>
                            <button className="btn btn-sm btn-light edit-list" onClick={() => t_editPayment()}
                            >
                              Cancel
                            </button>
                            <button className="btn btn-sm btn-success edit-list" onClick={() => UpdatePayment(res)}
                            >
                              Update
                            </button>
                          </div>
                        </td>
                      </tr>}
                    </>)
                  })
                  }
                  {data.payment_items && data.payment_items.length <= 0 && <tr>
                    <td>No record available!</td>
                  </tr>}
                  {Addnewpayment &&
                    <tr>
                      <td>
                        <select className='form-control' onChange={(e) => {
                          setPaymentMode(e.target.value);
                        }}>
                          <option></option>
                          {data?.payment_methods.map((res) => {
                            return (<option key={res.id} value={res.name}>{res.name}</option>)
                          })}
                        </select>
                      </td>
                      <td>
                        <textarea className='form-control' onChange={(e) => {
                          setPaymentDesc(e.target.value)
                        }}></textarea>
                      </td>
                      <td>
                        <Flatpickr
                          className="form-control"
                          options={{
                            dateFormat: "Y-m-d",
                            defaultDate: [moment(new Date()).format("YYYY-MM-DD")]
                          }} onChange={(selectedDates) => {
                            setPaymentDate(moment(selectedDates[0]).format('YYYY-MM-DD'))
                          }} />
                      </td>
                      <td>
                        <Input className='form-control' onChange={(e) => {
                          setPaymentAmount(e.target.value);
                        }} />
                      </td>
                      <td>
                        <div className='hstack gap-2'>
                          <button className="btn btn-sm btn-light edit-list" onClick={() => t_addnewpayment()}
                          >
                            Cancel
                          </button>
                          <button className="btn btn-sm btn-success edit-list" onClick={() => SavePayment()}
                          >
                            Save
                          </button>
                        </div>
                      </td>
                    </tr>}
                </tbody>
              </Table>
              <Button className="btn btn-brown" onClick={() => t_addnewpayment()}
              >+</Button>
            </div>
            <Row>
              <Col lg={6}></Col>
              <Col lg={6}>
                <Table className="table-bordered ">
                  <tbody>
                    <tr>
                      <th className='bg-soft-purple'><span>Payments</span></th>
                      <td><span id="grand_total_tax">{data?.global?.currency_symbol}{parseFloat(total_payments).toFixed(2)}</span></td>
                    </tr>
                    <tr>
                      <th className='bg-soft-purple'><span>Balance</span></th>
                      <td><span id="grand_total_incl_tax">{data?.global?.currency_symbol}{parseFloat(data?.totalAmount - total_payments).toFixed(2)}</span></td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card>

          <Modal
            id="showModal"
            className="modal-dialog-edit"
            isOpen={modal}
            toggle={() => t_deleteItem()}
            centered
          >
            <ModalBody className="py-2 px-3">
              <div className="mt-2 text-center">
                <lord-icon
                  src="https://cdn.lordicon.com/wdqztrtx.json"
                  trigger="loop"
                  colors="primary:#912a4e,secondary:#f06548"
                  style={{ width: "100px", height: "100px" }}
                ></lord-icon>
                <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                  <h4>Are you sure ?</h4>
                  <p className="text-muted mx-4 mb-0">
                    You will not be able to recover the deleted invoice item!
                  </p>
                </div>
              </div>
              <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                <button
                  type="button"
                  className="btn w-sm btn-light"
                  data-bs-dismiss="modal"
                  onClick={() => t_deleteItem()}>
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn w-sm btn-danger "
                  id="delete-record"
                  onClick={() => deleteInvoice()}
                >
                  Ok
                </button>
              </div>
            </ModalBody>
          </Modal>
          <Modal
            id="showModal"
            className="modal-dialog-edit"
            isOpen={paymentmodal}
            toggle={() => t_deletePayment}
            centered>
            <ModalBody className="py-2 px-3">
              <div className="mt-2 text-center">
                <lord-icon
                  src="https://cdn.lordicon.com/wdqztrtx.json"
                  trigger="loop"
                  colors="primary:#912a4e,secondary:#f06548"
                  style={{ width: "100px", height: "100px" }}
                ></lord-icon>
                <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                  <h4>Are you sure ?</h4>
                  <p className="text-muted mx-4 mb-0">
                    You will not be able to recover the deleted payment item!
                  </p>
                </div>
              </div>
              <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                <button
                  type="button"
                  className="btn w-sm btn-light"
                  data-bs-dismiss="modal"
                  onClick={() => t_deletePayment()}>
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn w-sm btn-danger "
                  id="delete-record"
                  onClick={() => deletePayment()}
                >
                  Ok
                </button>
              </div>
            </ModalBody>
          </Modal>
        </Card>
      </Container>
      <ToastContainer closeButton={false} limit={1} theme="light" />
    </div>
  );
}

export default AppsEcommerce;