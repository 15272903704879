import { Card, CardBody, Col, Row, Form } from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { toast, ToastContainer } from 'react-toastify';
import {
  CardHeader,
  Label,
  Input,

} from "reactstrap";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';

export const PricingSettings = () => {
  document.title="Pricing Settings | Onexfort";
  const [data, setData] = useState([]);

  const [dataObj, setDataObj] = useState({
    'goodsvalue': 0,
    'minvalue': 0,
    'showcbm': false,
    'bedroom': 0,
    'livingroom': 0,
    'stairaccess': 0,
    'liftaccess': 0,
    'startkm': 0,
    'maxkm': 0,
    'pricekm': 0,
    'isdeposit': false,
    'pricekmdeposit': 0,
    'depositpercent': 0

  });
  const handleChange = (event, field) => {
    const { type, checked, value } = event.target;

    if (type === 'checkbox') {
      setDataObj((prevDataObj) => ({
        ...prevDataObj,
        [field]: checked,
      }));
    } else {
      setDataObj((prevDataObj) => ({
        ...prevDataObj,
        [field]: parseFloat(value) || 0.0,
      }));
    }
  };

  const loadData = () => {
    axios.get("api/pricingSettings")
      .then(res => {
        setData(res);
        setDataObj({
          'id': res.id,
          'goodsvalue': res.goods_value_per_cbm,
          'minvalue': res.minimum_goods_value,
          'showcbm': (res.inventory_pdf_show_cbm === "Y") ? true : false,
          'bedroom': res.cbm_per_bedroom,
          'livingroom': res.cbm_per_living_room,
          'stairaccess': res.stairs_access_charge_per_floor_per_cbm,
          'liftaccess': res.lift_access_charge_per_cbm,
          'startkm': res.excess_km_range,
          'maxkm': res.excess_km_range_max,
          'pricekm': res.price_per_excess_km,
          'isdeposit': (res.is_deposit_for_fixed_pricing_fixed_amt == "N") ? false : true,
          'pricekmdeposit': res.deposit_amount_fixed_pricing,
          'depositpercent': 25

        })
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      })
  }
  useEffect(() => {
    loadData();
  }, [])


  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      "id": dataObj.id,
      "cbm_per_bedroom": dataObj.bedroom,
      "inventory_pdf_show_cbm": dataObj.showcbm,
      "minimum_goods_value": dataObj.minvalue,
      "goods_value_per_cbm": dataObj.goodsvalue,
      "cbm_per_living_room": dataObj.livingroom,
      "price_per_excess_km": dataObj.pricekm,
      "excess_km_range": dataObj.startkm,
      "excess_km_range_max": dataObj.maxkm,
      "is_deposit_for_fixed_pricing_fixed_amt": dataObj.isdeposit,
      "stairs_access_charge_per_floor_per_cbm": dataObj.stairaccess,
      "lift_access_charge_per_cbm": dataObj.liftaccess,

    }
    axios.post("api/savePricingSettingsData", formData)
      .then(res => {
        console.log("mm")
        toast.success(res.message)
      })
      .catch(err => {
        console.log(err);
      })
    console.log(dataObj);
  }
  return (
    <div>
      <Card>
        <CardHeader>
          <h5>Auto Quote - Pricing Settings</h5>
        </CardHeader>
        <CardBody>
          <form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col lg={6}>
                <div>
                  <Label htmlFor="iconInput" className="form-label">Goods Value per CBM</Label>
                  <div className="form-icon">
                    <Input type="text" className="form-control form-control-icon" value={dataObj.goodsvalue}
                      onChange={(event) => handleChange(event, 'goodsvalue')}
                      id="iconInput" placeholder="" />
                    <i className="bx bx-dollar"></i>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <Label htmlFor="iconInput" className="form-label">Minimum Goods Value</Label>
                  <div className="form-icon">
                    <Input type="text" className="form-control form-control-icon" value={dataObj.minvalue}
                      onChange={(event) => handleChange(event, 'minvalue')}
                      id="iconInput" placeholder="" />
                    <i className="bx bx-dollar"></i>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={6}>
                <Label className="form-label">Show CBM in Inventory Pdf</Label>
                <div className="form-check form-switch form-switch-lg" dir="ltr">
                  <Input type="checkbox" className="form-check-input" id="customSwitchsizelg"
                    checked={dataObj.showcbm}
                    onChange={(event) => handleChange(event, 'showcbm')}
                  />
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={6}>
                <div>
                  <Label htmlFor="iconInput" className="form-label">CBM (m3) per Bedroom</Label>
                  <div className="form-icon">
                    <Input type="text" className="form-control" value={dataObj.bedroom}
                      onChange={(event) => handleChange(event, 'bedroom')}
                      id="iconInput" placeholder="0.00" />
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={6}>
                <div>
                  <Label htmlFor="iconInput" className="form-label">CBM (m3) per Living room</Label>
                  <div className="form-icon">
                    <Input type="text" className="form-control " value={dataObj.livingroom}
                      onChange={(event) => handleChange(event, 'livingroom')}
                      id="iconInput" placeholder="0.00" />
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={6}>
                <div>
                  <Label htmlFor="iconInput" className="form-label">Stairs Access Charge per floor per CBM (m3)</Label>
                  <div className="form-icon">
                    <Input type="text" className="form-control form-control-icon" value={dataObj.stairaccess}
                      onChange={(event) => handleChange(event, 'stairaccess')}
                      id="iconInput" placeholder="0.00" />
                    <i className="bx bx-dollar"></i>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={6}>
                <div>
                  <Label htmlFor="iconInput" className="form-label">Lift Access Charge per CBM (m3)</Label>
                  <div className="form-icon">
                    <Input type="text" className="form-control form-control-icon" value={dataObj.liftaccess}
                      onChange={(event) => handleChange(event, 'liftaccess')}
                      id="iconInput" placeholder="0.00" />
                    <i className="bx bx-dollar"></i>
                  </div>
                </div>
              </Col>
            </Row>
            <h5>Fixed Rate Calculation:</h5>
            <hr></hr>
            <Row className="mb-3">
              <Col lg={6}>
                <div>
                  <Label htmlFor="iconInput" className="form-label">Excess Range - Start Km</Label>
                  <div className="form-icon">
                    <Input type="text" className="form-control " value={dataObj.startkm}
                      onChange={(event) => handleChange(event, 'startkm')}
                      id="iconInput" placeholder="0.00" />
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={6}>
                <div>
                  <Label htmlFor="iconInput" className="form-label">Excess Range - Max Km</Label>
                  <div className="form-icon">
                    <Input type="text" className="form-control " value={dataObj.maxkm}
                      onChange={(event) => handleChange(event, 'maxkm')}
                      id="iconInput" placeholder="0.00" />
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={6}>
                <div>
                  <Label htmlFor="iconInput" className="form-label">Price per Excess Km</Label>
                  <div className="form-icon">
                    <Input type="text" className="form-control form-control-icon" value={dataObj.pricekm}
                      onChange={(event) => handleChange(event, 'pricekm')}
                      id="iconInput" placeholder="0.00" />
                    <i className="bx bx-dollar"></i>
                  </div>
                </div>
              </Col>
            </Row>
            <h5>Quote Deposit: </h5>
            <hr></hr>
            <Row className="mb-3">
              <Col lg={6}>
                <Label className="form-label">Is Deposit a Fixed Amount?</Label>
                <div className="form-check form-switch form-switch-lg" dir="ltr">
                  <Input type="checkbox" className="form-check-input" id="customSwitchsizelg"
                    checked={dataObj.isdeposit}
                    onChange={(event) => handleChange(event, 'isdeposit')}
                  />
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={6}>
                <div>
                  <Label htmlFor="iconInput" className="form-label">Price per Excess Km</Label>
                  <div className="form-icon">
                    <Input type="text" className="form-control form-control-icon" value={dataObj.pricekmdeposit}
                      onChange={(event) => handleChange(event, 'pricekmdeposit')}
                      id="iconInput" placeholder="0.00" />
                    <i className="bx bx-dollar"></i>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <Label htmlFor="iconInput" className="form-label">Deposit Percent</Label>
                  <div className="form-icon">
                    <Input type="text" className="form-control " disabled value={dataObj.depositpercent}
                      //onChange={(event) => handleChange(event, 'liftaccess')}
                      id="iconInput" placeholder="0.00" />
                  </div>
                </div>
              </Col>
            </Row>
            <hr></hr>
            <div className="text-center">
              <button type="submit" className="btn btn-primary"

              >Save</button>
            </div>
          </form>
        </CardBody>
      </Card>
      <ToastContainer closeButton={false} limit={1} theme="light"/>
    </div>
  )
}
