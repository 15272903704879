import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Accordion, AccordionItem, Button, Card, CardBody, CardHeader, Col, Collapse, Table, Form, Label, Input, Container, Row, Modal, ModalBody, Spinner } from 'reactstrap';
import TableRows from "./TableRow";
import Flatpickr from "react-flatpickr";
import axios from 'axios';
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import { get_cookie } from '../../../helpers/get_cookie';
import 'react-toastify/dist/ReactToastify.css';
import { addNewInvoice, deleteInvoice } from '../../../store/actions';
import { ClipLoader } from 'react-spinners';
import { jsx } from '@emotion/react'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import moment from 'moment';
import Loader from "../../../Components/Common/Loader";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripePayment from '../../../Components/Stripe';

const override = jsx`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const stripePromise = loadStripe('pk_test_E0qVMK2JSQXGItx8GHNrlqX9');

export const Invoice = ({ data, onChangeData }) => {
  const params = useParams();
  const job_id = params.id;
  var total_excl_tax = 0;
  var total_tax = 0;
  var total_payments = 0;
  // console.log(data,"data12323");
  //rowsdata
  const [rowsData, setRowsData] = useState([]);
  const [postData, setData] = useState([]);
  const [invoice, setInvoiceList] = useState([]);
  const [invoicecharges, setInvoiceCharges] = useState([]);
  const [expandedinvoiceId, setExpandedInvoiceId] = useState('');
  const [invoiceid, setinvoiceid] = useState(data?.invoice?.id);
  const [expandedpaymentId, setExpandedPaymentId] = useState('');
  const [selectedinvoice, setSelectedInvoiceData] = useState([]);
  const [selectedcharges, setSelectedChargesData] = useState([]);
  const [selectedpayment, setSelectedPaymentData] = useState([]);
  const [oppid, setoppid] = useState(data?.job?.crm_opportunity_id);
  const [crmcontactemail,setcrmcontactemail]=useState(data?.crmcontact_email)
  const [Addnewpayment, setAddPayment] = useState(false);
  const [paymentmodal, setPaymentModal] = useState(false);
  const [modal, setOpenModal] = useState(false);
  const [Addnewinvoice, setAddInvoice] = useState(false);
  const [invoicename, setInvoiceName] = useState("");
  const [producttype, setProductType] = useState("");
  const [invoicepid, setProductId] = useState("");
  const [invoicedesc, setInvoiceDesc] = useState("");
  const [invoiceprice, setInvoicePrice] = useState("");
  const [invoicetax, setInvoiceTax] = useState("");
  const [invoiceamt, setInvoiceAmount] = useState("");
  const [invoiceqty, setInvoiceQuantity] = useState("");
  const [expandedInvoiceChargeId, setEditCharges] = useState("");
  const [addnewCharges, setAddNewCharges] = useState("");
  const [newcharge, setNewCharge] = useState("");
  const [newchargesAmt, setChargesAmt] = useState("");
  const [newchargetax, setNewChargeTax] = useState(0);
  const [newchargeunit, setNewChargeUnit] = useState("");
  const [newchargeqty, setNewChargeQty] = useState(1);
  const [newchargeamt, setNewChargeAmt] = useState("");
  const [newchargetype, setNewChargetype] = useState("");
  const [newchargepid, setNewChargepid] = useState("");
  const [discounttype, setDiscountType] = useState('');
  const [discountvalue, setDiscountValue] = useState('');
  const [paymentmode, setPaymentMode] = useState('');
  const [paymentdate, setPaymentDate] = useState('');
  const [paymentdesc, setPaymentDesc] = useState('');
  const [paymentamount, setPaymentAmount] = useState('');
  const [paymentItems, setPaymentItems] = useState('');
  const [joblegs, setJobLegs] = useState('');
  const [expandedjobLegid, setEditJobLeg] = useState('');
  const [installmentPayment, setInstallmentPayment] = useState(false);
  const [isLoader, setLoader] = useState(true);
  const [isLoading, setisLoading] = useState(false);
  const [totalPayments, setTotalPayments] = useState('');
  const [org_date, setOrg_Date] = useState([]);

  const t_editinvoice = (val) => {
    onChangeData();
    setExpandedInvoiceId(val);
  }

  const t_editCharges = (data) => {
    setEditCharges(data)
  }

  const t_deleteItem = (val, data = "") => {
    if (val == "invoice") {
      setSelectedInvoiceData(data);
      setSelectedChargesData();
      setOpenModal(true);
    } else if (val == "charges") {
      setSelectedChargesData(data);
      setSelectedInvoiceData();
      setOpenModal(true);
    } else {
      setSelectedChargesData();
      setSelectedInvoiceData();
      setOpenModal(false);
    }
  }

  const t_editPayment = (val) => {
    console.log(val);
    setExpandedPaymentId(val);
  }

  const t_deletePayment = (val = "") => {
    if (val != "") {
      setSelectedPaymentData(val);
      setPaymentModal(true);
    } else {
      setPaymentModal(false);
    }
  }

  const t_addnewpayment = (val) => {
    //getData();
    if (Addnewpayment == true) {
      setPaymentAmount();
      setPaymentDate();
      setPaymentDesc();
      setPaymentMode();
      setAddPayment(false);
    } else {
      setAddPayment(true);
    }
  }

  const SelectDiscount = (val) => {
    if (val == "cancel") {
      data.invoice.discount = 0;
      setDiscountType("");
    } else if (val == "save") {
      setDiscountType("");
    }
    else {
      setDiscountType(val);
    }
  }

  const UpdatePayment = (val) => {
    console.log(val);
    setLoader(true);
    console.log(val.paid_on,"ttt");
    var postData = {
      "id": val.id,
      "job_id": job_id,
      "invoice_id": data?.invoice.id,
      "gateway": val.gateway,
      "description": val.remarks,
      "amount": val.amount,
      "paid_on": val.paid_on,
      // "paid_on": moment(val.paid_on).format("DD/MM/YYYY"),
      // "sys_job_type": "Moving_Storage"
    }
    axios.post("api/ajaxUpdatePayment", postData).then((res) => {
      if (res.error == 0) {
        toast.success(res.message);
        onChangeData();
        t_editPayment();
      }
      else {
        toast.error("Something went wrong");
      }
    })
    .catch((error) => {
      console.error(error);
      toast.error("An error occurred while fetching data.", {
        theme: "error",
      });
    })
    .finally(() => {
      setLoader(false);
    });
  }

  const SavePayment = () => {
    setLoader(true);
    var postData = {
      "job_id": job_id,
      "invoice_id": data?.invoice.id,
      "gateway": paymentmode,
      "description": paymentdesc,
      "amount": paymentamount,
      "paid_on": paymentdate
    }
    if (postData.gateway === "" || postData.gateway === undefined) {
      toast.error("Select any Payment Method", { theme: "light" });
    }
    else if (postData.amount === "" || postData.amount === undefined) {
      toast.error("Enter the Amount", { theme: "light" });
    }
    else {
      axios.post("api/ajaxSavePayment", postData).then((res) => {
        if (res.error == 0) {
          toast.success(res.message);
          onChangeData();
          t_addnewpayment();
        }
        else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("An error occurred while fetching data.", {
          theme: "error",
        });
      })
      .finally(() => {
        setLoader(false);
      });
    }
  }

  const deletePayment = () => {
    console.log(selectedpayment);
    var postData = {
      "job_id": job_id,
      "invoice_id": selectedpayment.invoice_id,
      "id": selectedpayment.id,
      "sys_job_type": "Moving_Storage"
    }
    axios.post("api/ajaxDestroyPaymentItem", postData).then((res) => {
      if (res.error == 0) {
        toast.success(res.message);
        onChangeData();
        t_deletePayment();
      }
      else {
        toast.error("Something went wrong");
      }
    })
  }

  const addTableRows = () => {
    if (Addnewinvoice) {
      setInvoiceName();
      setInvoiceDesc();
      setInvoicePrice();
      setInvoiceAmount();
      setInvoiceQuantity();
      setAddInvoice(false);
    } else {
      setAddInvoice(true);
    }
    // const rowsInput = {
    //   fullName: '',
    //   emailAddress: '',
    //   salary: ''
    // }
    // setRowsData([...rowsData, rowsInput])
  }


  const addNewCharges = () => {
    if (addnewCharges) {
      setNewCharge();
      setChargesAmt();
      setNewChargeTax();
      setNewChargeUnit();
      setNewChargeAmt();
      setNewChargetype();
      setNewChargepid();
      setAddNewCharges(false)
    } else {
      setAddNewCharges(true);
    }

  }

  const [afterDiscountVal, setAfterDiscountVal] = useState([])

  useEffect(() => {
    getData();
    
  }, [data])

  function getData() {
    setData(data);
    console.log(data)
    setOrg_Date(data?.organisation_settings);
    setDiscountVal(data?.invoice?.discount)
    setDiscountAmt(data?.invoice?.discount)
    setInvoiceList(data?.invoice_items);
    setInvoiceCharges(data?.invoice_charges);
    setPaymentItems(data?.payment_items);
    setJobLegs(data?.job_legs);
    if (data) {
      setTimeout(function () {
        setLoader(false);
      }, 2000);
    }
  }

  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  }
  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);
  }
  const handleInvoiceSelect = (data, value) => {
    console.log(value)
  }

  const InvoiceChargeUpdate = (data, price) => {
    var postDatas = {
      "invoice_id": data.invoice_id.toString(),
      "price": price
    }
    //setIsUpdating(true);
    axios.post("api/ajaxCalculateChargePrice", postDatas).then((res) => {
      const updateddata = [...invoicecharges];
      const taskIndex = updateddata.findIndex((task) => task.id === data.id);
      var tax_amount = postData.taxs.map((val) => (
        (data.tax_id == val.id) ? val.rate_percent : ""));
      updateddata[taskIndex].unit_price = res;

      console.log((1 + tax_amount[0] / 100));
      updateddata[taskIndex].amount = parseFloat((updateddata[taskIndex].unit_price * updateddata[taskIndex].quantity) * (1 + tax_amount[0] / 100)).toFixed(2);
      setInvoiceCharges(updateddata);
      // setTimeout(() => { setIsUpdating(false) }, 2000);
    })
  }
  const EditInvoice = (val) => {
    var postData = {
      "id": val.id,
      "job_id": job_id,
      "invoice_id": val.invoice_id,
      "product_id": val.product_id,
      "name": val.item_name,
      "description": val.item_summary,
      "tax_id": val.tax_id,
      "unit_price": val.unit_price,
      "quantity": val.quantity,
      "type": val.type,
      "amount": val.amount,
    }
    axios.post("api/ajaxUpdateInvoice", postData).then((res) => {
      if (res.error == 0) {
        toast.success(res.message);
        onChangeData();
        t_editinvoice();
      }
      else {
        toast.error("Something went wrong");
      }
    })
  }
  const SaveInvoice = () => {
    var postData = {
      "job_id": job_id,
      "invoice_id": data?.invoice.id,
      "product_id": invoicepid,
      "name": invoicename,
      "description": invoicedesc,
      "tax_id": invoicetax,
      "unit_price": invoiceprice,
      "quantity": invoiceqty,
      "type": producttype,
      "amount": invoiceamt,
    }
    console.log(postData)
    if (postData.name === "" || postData.name === undefined || postData.name === null) {
      toast.error("Enter the Item Name", { theme: "light" });
    }
    else if (postData.unit_price === "" || postData.unit_price === undefined || postData.unit_price === null) {
      toast.error("Enter the Unit Price", { theme: "light" });
    }
    else if (postData.quantity === '' || postData.quantity === undefined || postData.quantity === null) {
      toast.error("Enter the Quantity", { theme: "light" });
    }
 
    else {
      axios.post("api/ajaxSaveInvoice", postData).then((res) => {
        if (res.error == 0) {
          toast.success(res.message);
          onChangeData();
          addTableRows();
        }
        else {
          toast.error("Something went wrong");
        }
      })
    }
  }


  const updateinvoicepdf = () => {

    setLoader(true);
    let req = {
      job_id: job_id,
      invoice_id: invoiceid,
    }
    axios.post("api/ajaxUpdateRegenrateInvoice", req)
      .then(res => {
        console.log(res);
        if (res.error === 0) {
          toast.success(res.message);
        }
        else {
          toast.error(res.message);
        }
        setLoader(false);
      })
      .catch(err => {
        console.log(err);
      })
  }

  const deleteInvoice = () => {
    console.log(selectedinvoice);
    if (selectedinvoice && selectedinvoice != "") {
      var postData = {
        "job_id": job_id,
        "invoice_id": selectedinvoice.invoice_id,
        "id": selectedinvoice.id
      }
      axios.post("api/ajaxDestroyInvoiceItem", postData).then((res) => {
        if (res.error == 0) {
          toast.success(res.message);
          onChangeData();
          t_deleteItem();
        }
        else {
          toast.error("Something went wrong");
        }
      })
    } else if (selectedcharges && selectedcharges != "") {
      var postData = {
        "job_id": job_id,
        "invoice_id": selectedcharges.invoice_id,
        "id": selectedcharges.id
      }
      axios.post("api/ajaxDestroyInvoiceItem", postData).then((res) => {
        if (res.error == 0) {
          toast.success(res.message);
          onChangeData();
          t_deleteItem();
        }
        else {
          toast.error("Something went wrong");
        }
      })
    }
  }
  const user = JSON.parse(get_cookie("authUser"));
  var tenant_id = user.tenant_id;
  const handleGenerate = () => {
    setLoader(true);
    let req = {
      "job_id": job_id,
      "tenant_id": tenant_id,
      "manual_action": "Y"
    }
    axios.post("api/listgenerateInvoice", req)
      .then(res => {
        console.log(res);
        if (res.success === true) {
          toast.success(res.message);
        }
        else {
          toast.error(res.message);
        }
        setLoader(false);
      })
      .catch(err => {
        console.log(err);
      })
  }

  //pod 


  const podgenerate = () => {
    console.log("hellooo");
    setLoader(true);
    let req = {
      "job_id": job_id,
      "type": "Moving",
    }
    axios.post("api/generatePodweb", req)
      .then(res => {
        console.log(res);
        if (res.success === true) {
          toast.success(res.message);
        }
        else {
          toast.error(res.message);
        }
        setLoader(false);
      })
      .catch(err => {
        console.log(err);
      })
  }


  //download pod 

  const Downloadpod = () => {

    axios.post(`api/downloadPod/${job_id}`)
      .then(res => {
        console.log(res);
        window.open(res.url, "_blank");
      })
      .catch(err => {
        console.log(err);
      })
  }



  //workorder
  const workorderpdf = () => {
    // console.log("hellooo");
    setLoader(true);
    let req = {
      "job_id": job_id,
      "type": "Moving",
    }
    axios.post("api/generateWorkOrderweb", req)
      .then(res => {
        console.log(res);
        if (res.error == 0) {
          toast.success(res.message);
        }
        else {
          toast.error(res.message);
        }
        setLoader(false);
      })
      .catch(err => {
        console.log(err);
      })
  }



  const downloadworkorder = () => {

    axios.post(`api/downloadWordOrder/${job_id}`)
      .then(res => {
        console.log(res);
        window.open(res.url, "_blank");
      })
      .catch(err => {
        console.log(err);
      })
  }

  //////////////////////



  //insurance///
  const insurancequote = () => {
    setLoader(true);
    axios.post(`api/generate-insurance-quote/${oppid}`)
      .then(res => {
        console.log(res);
        if (res.status === 1) {
          toast.success(res.message);
        }
        else {
          toast.error("error");
        }
        setLoader(false);
      })
      .catch(err => {
        console.log(err);
      })
  }



  const downnloadinsurance = () => {

    axios.post(`api/download-insurance-quote/${oppid}`)
      .then(res => {
        console.log(res);
        window.open(res.url, "_blank");
      })
      .catch(err => {
        console.log(err);
      })
  }
  ////////



  const handleDownload = () => {

    axios.post(`api/list-jobs/downloadInvoice/${job_id}`)
      .then(res => {
        console.log(res);
        window.open(res.url, "_blank");
      })
      .catch(err => {
        console.log(err);
      })
  }
  const UpdateCharges = (data) => {
    var postData = {
      "id": data.id,
      "job_id": job_id,
      "invoice_id": data.invoice_id,
      "product_id": data.product_id,
      "name": data.item_name,
      "tax_id": data.tax_id,
      "unit_price": data.unit_price,
      "quantity": data.quantity,
      "type": data.type,
      "amount": data.amount
    }
    console.log(postData)
    // if (data.drop_off_address === "" || data.drop_off_address === undefined) {
    //   toast.error("Enter the Address", { theme: "light" });
    // }
    // else if (data.delivery_suburb === "" || data.delivery_suburb === undefined) {
    //   toast.error("Enter the Suburb Value", { theme: "light" });
    // }
    // else {
    axios.post("/api/ajaxUpdateInvoice", postData).then((res) => {
      console.log(res);
      if (res.error == 0) {
        toast.success(res.message);
        onChangeData();
        t_editCharges()
      } else {
        toast.error("Something went wrong");
      }
    })
    // }
  }

  const [discountamt, setDiscountAmt] = useState()
  const [discountVal, setDiscountVal] = useState()
  // const [discount,setDiscount]=useState("")
  // const [depositamt, setDepositAmt] = useState(data?.quote?.deposit_required);




  const submitDiscount = () => {
    if (discounttype === "Percent") {
      setAfterDiscountVal((parseFloat(total_excl_tax) - (parseInt(discountVal) / 100 * parseFloat(total_excl_tax))).toFixed(2))
      console.log(parseFloat(total_tax + total_excl_tax - (discountvalue / 100 * total_excl_tax)).toFixed(2))
    }
    else if (discounttype === "Fixed") {
      setAfterDiscountVal((total_excl_tax - parseInt(discountVal)).toFixed(2))
    }
    setDiscountAmt(discounttype === "Percent" ? discountVal + "%" : "$" + discountVal)
    const form = {
      invoice_id: data?.invoice?.id,
      lead_id: data?.crmlead?.id,
      // quote_id: quoteid,
      discount: parseInt(discountamt),
      discount_type: discounttype,
      // crm_opportunity_id: oppid,
      sys_job_type: 'Moving'
    }
    console.log(form, "type")
    axios.post("api/ajaxSaveInvoiceDiscount", form).then((res) => {
      if (res.error == 0) {
        toast.success(res.message);
        console.log(res)
        setDiscountType('');

      }
      else {
        toast.error("Something went wrong");
      }
    })
  }
  const SaveCharges = (data) => {
    if (newchargepid == "" || newcharge == "" || newchargeunit == "" || newchargeqty == "" || newchargetype == "" || newchargeamt == "") {
      toast.error("Please fill all the necessary details");
      return false;
    }
    var sendData = {
      "job_id": job_id,
      "invoice_id": postData.invoice.id.toString(),
      "product_id": newchargepid,
      "name": newcharge,
      "tax_id": newchargetax,
      "unit_price": newchargeunit,
      "quantity": newchargeqty,
      "type": newchargetype,
      "amount": newchargeamt
    }
    if (sendData.name === "" || sendData.name === undefined) {
      toast.error("Select the Name", { theme: "light" });
    }
    else {
      axios.post("/api/ajaxSaveInvoice", sendData).then((res) => {
        console.log(res);
        if (res.error == 0) {
          toast.success(res.message);
          onChangeData();
          addNewCharges()
        } else {
          toast.error("Something went wrong");
        }
      })
    }
  }

  const UpdateJobLegs = (value) => {
    console.log(value);
    var postData = {
      "id": value.id,
      "job_id": value.job_id,
      "actual_start_time": value.actual_start_time,
      "actual_finish_time": value.actual_finish_time,
      "emp_travel_time": value.emp_travel_time
    }
    console.log(postData);
    if (postData.actual_start_time === null || postData.actual_start_time === undefined) {
      toast.error("Enter the Start Time", { theme: "light" });
    }
    else if (postData.actual_finish_time === null || postData.actual_finish_time === undefined) {
      toast.error("Enter the Finish Time", { theme: "light" });
    }
    else {
      axios.post("api/ajaxUpdateActualhours", postData).then((res) => {
        console.log(res);
        if (res.error == 0) {
          toast.success(res.message);
          onChangeData();
          setEditJobLeg();
        } else {
          toast.error("Something went wrong");
        }
      })
    }
  }

  function calculateTimeDuration(actualStartTime, actualFinishTime) {
    if (actualStartTime && actualFinishTime) {
      const start = moment(actualStartTime, "HH:mm:ss");
      const end = moment(actualFinishTime, "HH:mm:ss");
      const minutes = end.diff(start, 'minutes') / 60;
      let totalHours = Math.ceil(minutes * 4) / 4;
      totalHours = totalHours.toFixed(2);

      if (totalHours < 0) {
        totalHours = totalHours * -1
      }
      return parseFloat(totalHours);
    } else {
      return 0;
    }
  }

  const UpdateInstallment = () => {
    if (installmentPayment) {
      var sendData = {
        "invoice_id": postData.invoice.id.toString(),
        "installment_value": "N"
      }
      setInstallmentPayment(false);
      axios.post("api/ajaxUpdateInstallment", sendData).then((res) => {
        if (res.status == 0) {
          toast.success(res.msg);
        } else {
          toast.error("Something went wrong");
        }
      })
    } else {
      var sendData = {
        "invoice_id": postData.invoice.id.toString(),
        "installment_value": "Y"
      }
      setInstallmentPayment(true);
      axios.post("api/ajaxUpdateInstallment", sendData).then((res) => {
        if (res.status == 0) {
          toast.success(res.msg);
        } else {
          toast.error("Something went wrong");
        }
      })
    }
  }
  const recalculate = () => {
    const form = {
      "job_id": job_id,
      "invoice_id": data?.invoice.id,
    }
    console.log(form, "febhewyb")
    axios.post("api/ajaxReCalculateChargePrice", form).then((res) => {
      if (res.error == 0) {
        toast.success(res.message);
        onChangeData();
      } else {
        toast.error(res.message);
      }
    })
  }

  const [showPaymentForm, setShowPaymentForm] = useState(false);

  const handleOpenPaymentForm = () => {
    setShowPaymentForm(true);
  };

  const handleClosePaymentForm = () => {
    setShowPaymentForm(false);
  };

  return (<>
    <div >
      {isLoader && <><div
        style={{
          position: 'absolute',
          // top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(255, 255, 255, 0.8)',
          // display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 9999,
        }}
      >
        <Loader />
      </div></>}
      <Card  >
        <Row className='m-3'>
          <Col lg={3} className='hstack gap-2 p-0'>
            <Button className='btn btn-soft-dark' onClick={handleGenerate}><i className='bx bxs-file-pdf fs-15' /> Generate Invoice PDF</Button>
            <Link
              to="#"
              onClick={handleDownload}
              className=" btn btn-sm btn-success fs-13 pt-1" >
              <i className="bx bxs-download fs-20 p-1"></i>
            </Link>
          </Col>
          <Col lg={3} className='hstack gap-2 p-0'>
            <Button className='btn btn-soft-dark' onClick={() => podgenerate()}><i className='bx bxs-file-pdf fs-15' /> Generate POD PDF</Button>
            <Link
              to="#"
              className=" btn btn-sm btn-success fs-13 pt-1" onClick={() => Downloadpod()}>
              <i className="bx bxs-download fs-20 p-1"></i>
            </Link>
          </Col>
          <Col lg={3} className='hstack gap-2 p-0'>
            <Button className='btn btn-soft-dark' onClick={() => workorderpdf()}><i className='bx bxs-file-pdf fs-15' /> Generate Work Order PDF</Button>
            <Link
              to="#"
              className=" btn btn-sm btn-success fs-13 pt-1" onClick={() => downloadworkorder()}>
              <i className="bx bxs-download fs-20 p-1"></i>
            </Link>
          </Col>
          <Col lg={3} className='hstack gap-2 p-0' >
            <Button className='btn btn-soft-dark' onClick={() => insurancequote()}><i className='bx bxs-file-pdf fs-15' /> Generate Insurance Quote</Button>
            <Link
              to="#"
              className=" btn btn-sm btn-success fs-13 pt-1" onClick={() => downnloadinsurance()} >
              <i className="bx bxs-download fs-20 p-1"></i>
            </Link>
          </Col>
        </Row>
        <div className='m-3'>
          {/* <h5> Invoice #321 - 0</h5> */}
          <Table className='table-bordered mt-3'>
            <thead className='bg-soft-purple'>
              <tr>
                <th>Item Name & Description</th>
                <th>Tax</th>
                <th>Unit Price</th>
                <th>QTY</th>
                <th>Total Inc Tax</th>
                <th>Action</th>

              </tr>
            </thead>
            <tbody>
              {invoice?.map((res) => {
                total_excl_tax += (res.unit_price * res.quantity);
                total_tax += res.amount - (res.unit_price * res.quantity);
                return (<>{(expandedinvoiceId != res.id) && <tr key={res.id}>
                  <td >
                    <span>{res.item_name}</span><br />
                    <span>{res.item_summary}</span>
                  </td>
                  <td >{postData.taxs.map((val) => (
                    (res.tax_id == val.id) ? val.tax_name : ""))}</td>
                  <td style={{ textAlign: 'right' }}> {postData?.global.currency_symbol} {parseFloat(res.unit_price).toFixed(2)}</td>
                  <td style={{ textAlign: 'right' }}>{parseFloat(res.quantity).toFixed(2)}</td>
                  <td style={{ textAlign: 'right' }} >{postData?.global.currency_symbol} {parseFloat(res.amount).toFixed(2)}</td>
                  <td>
                    <div className='hstack gap-2'>
                      <button className="btn btn-sm btn-soft-info edit-list" onClick={() => t_editinvoice(res.id)}>
                        <i className="bx bxs-pencil fs-12 pt-1"></i>
                      </button>
                      <button className="btn btn-sm btn-soft-danger edit-list" onClick={() => t_deleteItem("invoice", res)}>
                        <i className="bx bxs-trash fs-12 pt-1"></i>
                      </button>
                    </div>
                  </td>
                </tr>}
                  {(expandedinvoiceId == res.id) && <tr key={res.id}>
                    <td>
                      <span><Input type="text" list="data" value={res.item_name} onChange={(e) => {
                        const enteredValue = e.target.value.split(",")
                        const updateddata = [...invoice];
                        const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                        if (enteredValue.length > 1) {
                          var invoiceData = postData?.products?.filter((val) => (
                            (val.id == enteredValue[1])));
                          console.log(invoiceData);
                          if (invoiceData.length > 0) {
                            updateddata[taskIndex].item_name = invoiceData[0].name;
                            updateddata[taskIndex].item_summary = invoiceData[0].description;
                            updateddata[taskIndex].tax_id = invoiceData[0].tax_id;
                            updateddata[taskIndex].unit_price = parseFloat(invoiceData[0].price).toFixed(2);
                            updateddata[taskIndex].quantity = 1;
                            updateddata[taskIndex].amount = parseFloat(invoiceData[0].total_amount).toFixed(2);
                          }
                        } else {
                          updateddata[taskIndex].item_name = enteredValue[0]
                        }
                        setInvoiceList(updateddata);
                      }} /></span>
                      <datalist id="data">
                        {postData?.products.map((item, key) =>
                          <option key={key} >{item.name}</option>
                        )}
                      </datalist>
                      <br />
                      <span><Input value={res.item_summary} type="text" placeholder="Description" onChange={(e) => {
                        const updateddata = [...invoice];
                        const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                        updateddata[taskIndex].item_summary = e.target.value;
                        setInvoiceList(updateddata);
                      }} /></span>
                    </td>
                    <td><select className="form-select" value={res.tax_id} onChange={(e) => {
                      const updateddata = [...invoice];
                      const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                      updateddata[taskIndex].tax_id = e.target.value;

                      var tax_amount = postData.taxs.map((val) => (
                        (e.target.value == val.id) ? val : ""));
                      var taxrate = (tax_amount.length > 0 && tax_amount[0] != "") ? tax_amount[0].rate_percent : 0;
                      var totalAmount = (parseFloat(updateddata[taskIndex].unit_price).toFixed(2) * parseFloat(updateddata[taskIndex].quantity)).toFixed(2) * (1 + parseFloat(taxrate).toFixed(2) / 100);
                      updateddata[taskIndex].amount = parseFloat(totalAmount).toFixed(2);
                      setInvoiceList(updateddata);
                    }}>
                      <option value="0"></option>
                      {postData?.taxs.map((res) => {
                        return (<option key={res.id} value={res.id}>{res.tax_name}</option>)
                      })}
                    </select></td>
                    <td><Input type="number" value={res.unit_price} step={0.00} onChange={(e) => {
                      const updateddata = [...invoice];
                      const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                      updateddata[taskIndex].unit_price = e.target.value;
                      var tax_amount = postData.taxs.map((val) => (
                        (res.tax_id == val.id) ? val : ""));
                      if (tax_amount.length > 0) {
                        tax_amount = tax_amount[0];
                      }
                      var totalAmount = (parseFloat(updateddata[taskIndex].unit_price).toFixed(2) * parseFloat(updateddata[taskIndex].quantity)).toFixed(2) * (1 + parseFloat(tax_amount.rate_percent || 0).toFixed(2) / 100);
                      updateddata[taskIndex].amount = parseFloat(totalAmount).toFixed(2);
                      setInvoiceList(updateddata);
                    }} /></td>
                    <td><Input type="number" value={res.quantity} step={0.00} onChange={(e) => {
                      const updateddata = [...invoice];
                      const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                      updateddata[taskIndex].quantity = e.target.value;
                      var tax_amount = postData.taxs.map((val) => (
                        (res.tax_id == val.id) ? val : ""));
                      if (tax_amount.length > 0) {
                        tax_amount = tax_amount[0];
                      }
                      var totalAmount = (parseFloat(updateddata[taskIndex].unit_price).toFixed(2) * parseFloat(updateddata[taskIndex].quantity)).toFixed(2) * (1 + parseFloat(tax_amount.rate_percent || 0).toFixed(2) / 100);
                      updateddata[taskIndex].amount = parseFloat(totalAmount).toFixed(2);
                      setInvoiceList(updateddata);
                    }} /></td>
                    <td><Input type="number" value={res.amount} step={0.00} onChange={(e) => {
                      const updateddata = [...invoice];
                      const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                      updateddata[taskIndex].amount = e.target.value;
                      var tax_amount = postData.taxs.map((val) => (
                        (res.tax_id == val.id) ? val : ""));
                      if (tax_amount.length > 0) {
                        tax_amount = tax_amount[0];
                      }
                      var unitPrice = ((parseFloat(updateddata[taskIndex].amount).toFixed(2) / parseFloat(updateddata[taskIndex].quantity)).toFixed(2)) / (1 + parseFloat(tax_amount.rate_percent || 0).toFixed(2) / 100);
                      updateddata[taskIndex].unit_price = parseFloat(unitPrice).toFixed(2);
                      setInvoiceList(updateddata);
                    }} /></td>
                    <td>
                      <div className='hstack gap-2'>
                        <button className="btn btn-sm btn-light edit-list" onClick={() => t_editinvoice()}>
                          Cancel
                        </button>
                        <button className="btn btn-sm btn-success edit-list" onClick={() => EditInvoice(res)}>
                          Update
                        </button>
                      </div>
                    </td>
                  </tr>}</>)
              })}
              {(Addnewinvoice) && <tr>
                <td>
                  <span>
                    <Input
                      type="text"
                      list="data"
                      value={invoicename}
                      onChange={(e) => {
                        const { value } = e.target;
                        const dataList = document.getElementById("data");
                        const selectedValue = e.target.value;
                        const selectedItem = [...dataList.querySelectorAll('option')].find(option => option.value === selectedValue);
                        const enteredValue = selectedItem ? selectedItem.value : "";
                        const itemId = selectedItem ? selectedItem.dataset.id : "";

                        if (itemId) {
                          const invoiceData = postData?.products?.find((val) => val.id === parseInt(itemId));
                          console.log(invoiceData, "invoiceData");
                          if (invoiceData) {
                            setProductId(invoiceData.id);
                            setProductType(invoiceData.product_type);
                            setInvoiceName(invoiceData.name);
                            setInvoiceDesc(invoiceData.description);
                            setInvoiceTax(invoiceData.tax_id);
                            setInvoicePrice(parseFloat(invoiceData.price).toFixed(2));
                            setInvoiceQuantity(1);
                            setInvoiceAmount(parseFloat(invoiceData.total_amount).toFixed(2));
                          }
                        } else {
                          setInvoiceName(enteredValue);
                        }
                      }}
                    />
                  </span>

                  <datalist id="data">
                    {postData?.products.map((item) => (
                      <option key={item.id} value={item.name} data-id={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </datalist>
                  <br />
                  <span><Input type="text" value={invoicedesc} placeholder="Description" onChange={(e) => {
                    setInvoiceDesc(e.target.value);
                  }} /></span>
                </td>
                <td><select className="form-select" value={invoicetax} onChange={(e) => {
                  var tax_amount = postData.taxs.map((val) => (
                    (e.target.value == val.id) ? val : ""));
                  var taxrate = (tax_amount.length > 0 && tax_amount[0] != "") ? tax_amount[0].rate_percent : 0;
                  var totalAmount = (parseFloat(invoiceprice).toFixed(2) * parseFloat(invoiceqty)).toFixed(2) * (1 + parseFloat(taxrate).toFixed(2) / 100);
                  setInvoiceAmount(parseFloat(totalAmount).toFixed(2));
                  setInvoiceTax(e.target.value);
                }}>
                  <option value="0"></option>
                  {postData?.taxs.map((res) => {
                    return (<option key={res.id} value={res.id}>{res.tax_name}</option>)
                  })}
                </select></td>
                <td><Input type="number" value={invoiceprice} onChange={(e) => {
                  setInvoicePrice(e.target.value);
                  var tax_amount = postData.taxs.map((val) => (
                    (invoicetax == val.id) ? val : ""));
                  if (tax_amount.length > 0) {
                    tax_amount = tax_amount[0];
                  }
                  var totalAmount = (parseFloat(e.target.value).toFixed(2) * parseFloat(invoiceqty).toFixed(2)) * (1 + parseFloat(tax_amount.rate_percent || 0).toFixed(2) / 100);
                  setInvoiceAmount(parseFloat(totalAmount).toFixed(2));
                }} step={0.00} /></td>
                <td><Input type="number" value={invoiceqty} step={0.00} onChange={(e) => {
                  setInvoiceQuantity(e.target.value);
                  var tax_amount = postData.taxs.map((val) => (
                    (invoicetax == val.id) ? val : ""));
                  if (tax_amount.length > 0) {
                    tax_amount = tax_amount[0];
                  }
                  var totalAmount = (parseFloat(invoiceprice).toFixed(2) * parseFloat(e.target.value)).toFixed(2) * (1 + parseFloat(tax_amount.rate_percent || 0).toFixed(2) / 100);
                  setInvoiceAmount(parseFloat(totalAmount).toFixed(2));
                }} /></td>
                <td><Input type="number" value={invoiceamt} step={0.00} onChange={(e) => {
                  setInvoiceAmount(e.target.value);
                  var tax_amount = postData.taxs.map((val) => (
                    (invoicetax == val.id) ? val : ""));
                  if (tax_amount.length > 0) {
                    tax_amount = tax_amount[0];
                  }
                  var unitPrice = ((parseFloat(e.target.value).toFixed(2) / parseFloat(invoiceqty)).toFixed(2)) / (1 + parseFloat(tax_amount.rate_percent || 0).toFixed(2) / 100);
                  setInvoicePrice(parseFloat(unitPrice).toFixed(2));
                }} /></td>
                <td>
                  <div className='hstack gap-2'>
                    <button className="btn btn-sm btn-light edit-list" onClick={addTableRows}>
                      Cancel
                    </button>
                    <button className="btn btn-sm btn-success edit-list" onClick={() => SaveInvoice()}>
                      Save
                    </button>
                  </div>
                </td>
              </tr>}
            </tbody>
          </Table>
          <button className="btn btn-primary" onClick={addTableRows} >+</button>
        </div>
        <div className='m-3 hstack gap-3'>
          <h5> Charges</h5> <Button className='btn btn-soft-dark' onClick={() => recalculate()}>Recalculate</Button>
        </div>
        <div className='m-3'>
          <Table className='table-bordered'>
            <tbody>
              {invoicecharges?.map((res) => {
                total_excl_tax += (res.unit_price * res.quantity);
                total_tax += res.amount - (res.unit_price * res.quantity);
                return (<>
                  {(expandedInvoiceChargeId != res.id) && <tr key={res.id}>
                    <td>
                      <span>{res.item_name}</span><br />
                      <span>{res.item_summary}</span>
                    </td>
                    <td>
                      {postData.taxs.map((val) => (
                        (res.tax_id == val.id) ? val.tax_name : ""))}
                    </td>
                    <td style={{ textAlign: 'right' }}>{postData?.global.currency_symbol} {parseFloat(res.unit_price).toFixed(2)}</td>
                    <td style={{ textAlign: 'right' }}>{(res.quantity).toFixed(2)}</td>
                    <td style={{ textAlign: 'right' }}>{postData?.global.currency_symbol} {parseFloat(res.amount).toFixed(2)}</td>
                    <td>
                      <div className='hstack gap-2'>
                        <Button className="btn btn-sm btn-soft-info edit-list" onClick={(e) => t_editCharges(res.id)}>
                          <i className="bx bxs-pencil fs-12 pt-1"></i>
                        </Button>
                        <Button className=" btn btn-sm btn-soft-danger fs-13 pt-1" onClick={(e) => t_deleteItem("charges", res)}>
                          <i className="bx bxs-trash fs-12"></i>
                        </Button>
                      </div>
                    </td>
                  </tr>}
                  {(expandedInvoiceChargeId == res.id) && <tr>
                    <td>
                      <select className='form-control' value={res.item_name} onChange={(e) => {
                        const selectedIndex = e.target.selectedIndex;
                        const selectedOption = e.target.options[selectedIndex];
                        const price = selectedOption.getAttribute('data-price');
                        const updateddata = [...invoicecharges];
                        const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                        updateddata[taskIndex].item_name = e.target.value;
                        InvoiceChargeUpdate(res, price, taskIndex)
                        var tax_amount = postData.taxs.map((val) => (
                          (res.tax_id == val.id) ? val.rate_percent : ""));
                        updateddata[taskIndex].amount = parseFloat((res.unit_price * res.quantity) * (1 + tax_amount[0] / 100)).toFixed(2);
                        setInvoiceCharges(updateddata);
                      }}>
                        <option value="0"></option>
                        {postData.charges.map((res) => {
                          return (<option key={res.id} data-price={res.price} value={res.name}>{res.name}</option>)
                        })}
                      </select>
                    </td>
                    <td>
                      <select className='form-control' value={res.tax_id} onChange={(e) => {
                        const updateddata = [...invoicecharges];
                        const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                        updateddata[taskIndex].tax_id = e.target.value;
                        setInvoiceCharges(updateddata);
                      }}>
                        <option value="0"></option>
                        {postData.taxs.map((res) => {
                          return (<option key={res.id} value={res.id}>{res.tax_name}</option>)
                        })}
                      </select>
                    </td>
                    <td >
                      <Input type='number' className='form-control' value={res.unit_price} onChange={(e) => {
                        const updateddata = [...invoicecharges];
                        const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                        updateddata[taskIndex].unit_price = e.target.value;
                        var tax_amount = postData.taxs.map((val) => (
                          (res.tax_id == val.id) ? val.rate_percent : ""));
                        console.log(tax_amount);
                        updateddata[taskIndex].amount = parseFloat((res.unit_price * res.quantity) * (1 + tax_amount[0] / 100)).toFixed(2);
                        setInvoiceCharges(updateddata);
                      }} />
                    </td>
                    <td>
                      <Input type='number' className='form-control' value={res.quantity} disabled />
                    </td>
                    <td>
                      <Input type='number' className='form-control' value={res.amount} disabled />
                    </td>
                    <td>
                      <Button className='btn btn-light py-1 mx-1' onClick={() => t_editCharges()}>Cancel</Button>
                      <Button className='btn btn-success py-1 mx-1 my-1' onClick={() => UpdateCharges(res)}>Update</Button>
                    </td>
                  </tr>}
                </>)
              })}
              {addnewCharges && <tr>
                <td>
                  <select className='form-control' onChange={(e) => {
                    const selectedIndex = e.target.selectedIndex;
                    const selectedOption = e.target.options[selectedIndex];
                    const price = selectedOption.getAttribute('data-price');
                    const type = selectedOption.getAttribute('data-type');
                    const pid = selectedOption.getAttribute('data-pid');
                    setNewChargepid(pid);
                    setNewChargetype(type);
                    setNewCharge(e.target.value);
                    var postDatas = {
                      "invoice_id": postData.invoice.id.toString(),
                      "price": price
                    }
                    //setIsUpdating(true);
                    axios.post("api/ajaxCalculateChargePrice", postDatas).then((res) => {
                      var tax_amount = postData.taxs.map((val) => (
                        (newchargetax == val.id) ? val.rate_percent : ""));
                      setNewChargeUnit(parseFloat(res).toFixed(2));
                      var chargemamount = parseFloat((parseFloat(res).toFixed(2) * newchargeqty) * (1 + tax_amount[0] / 100)).toFixed(2);
                      setNewChargeAmt(chargemamount);
                    })
                  }} >
                    <option value="0"></option>
                    {postData.charges.map((res) => {
                      return (<option key={res.id} data-price={res.price} data-pid={res.id} data-type={res.product_type} value={res.name}>{res.name}</option>)
                    })}
                  </select>
                </td>
                <td>
                  <select className='form-control' onChange={(e) => {
                    var tax_amount = postData.taxs.map((val) => (
                      (e.target.value == val.id) ? val.rate_percent : ""));
                    setNewChargeTax(e.target.value)
                    var chargemamount = parseFloat((newchargeunit * newchargeqty) * (1 + tax_amount[0] / 100)).toFixed(2);
                    setNewChargeAmt(chargemamount);
                  }}>
                    <option value="0"></option>
                    {postData.taxs.map((res) => {
                      return (<option key={res.id} value={res.id}>{res.tax_name}</option>)
                    })}
                  </select>
                </td>
                <td>
                  <Input className='form-control' type='number' value={newchargeunit} onChange={(e) => {
                    var tax_amount = postData.taxs.map((val) => (
                      (newchargetax == val.id) ? val.rate_percent : ""));
                    setNewChargeUnit(e.target.value);
                    var chargemamount = parseFloat((e.target.value * newchargeqty) * (1 + tax_amount[0] / 100)).toFixed(2);
                    setNewChargeAmt(chargemamount);
                  }} />
                </td>
                <td>
                  <Input className='form-control' type='number' value={newchargeqty} disabled />
                </td>
                <td>{postData?.global.currency_symbol}{newchargeamt}</td>
                <td>
                  <Button className='btn btn-light py-1 mx-1' onClick={() => addNewCharges()}>Cancel</Button>
                  <Button className='btn btn-success py-1 mx-1 my-1' onClick={() => SaveCharges()}>Save</Button>
                </td>
              </tr>

              }
              <TableRows rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange={handleChange} />
            </tbody>
          </Table>
          <Button className="btn btn-brown" onClick={() => addNewCharges()} >+</Button>
        </div>
        <Row>
          <Col lg={6}></Col>
          <Col lg={6} >
            <Table className="table-bordered m-1">
              <tbody>
                <tr>
                  <th className='bg-soft-purple'><span>Total (excl tax)</span></th>
                  <td className='text-end'><span id="grand_total_excl_tax">{postData?.global?.currency_symbol}{total_excl_tax.toFixed(2)}</span></td>
                </tr>
                <tr>
                  <th className='bg-soft-purple'><span>Discount {(discounttype) ? "- " + discounttype : ""}</span>
                    <UncontrolledDropdown className="dropdown d-inline-block">
                      <DropdownToggle className="btn fs-18" tag="button">
                        <i className="ri-more-fill"></i>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem className='edit-item-btn' onClick={() => setDiscountType("Fixed")}>
                          <i className="ri-money-dollar-circle-line align-bottom me-2 text-muted"></i>Fixed
                        </DropdownItem>
                        <DropdownItem className='remove-item-btn' onClick={() => setDiscountType("Percent")}>
                          <i className="ri-percent-fill align-bottom me-2"></i>Percent
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </th>
                  <td className='text-end'>{discounttype == "" &&
                    <span id="grand_total_incl_tax">{(discountamt?.toFixed(2))}%</span>}
                    {discounttype &&
                      <span>
                        <div className="input-group input-group-sm">
                          <input type="number" id="s_discount_value_field" value={discountVal} className="form-control mb-2" onChange={(e) => {
                            console.log(e.target.value);
                            setDiscountVal(e.target.value)
                          }} />
                        </div>
                        <div className="d-flex justify-content-start align-items-center m-t-10">
                          <button id="s_cancel_discount_btn" type="button" className="btn btn-light" onClick={() => SelectDiscount("cancel")}> Cancel</button>
                          <button id="s_save_discount_btn" type="button" className="btn btn-success mx-2" onClick={() => submitDiscount()}>
                            Save
                          </button>
                        </div>
                      </span>}
                  </td>
                </tr>
                <tr >
                  <th className='bg-soft-purple'><span>Total (excl tax) after discount</span></th>
                  <td className='text-end'><span id="grand_total_excl_tax">
                    {postData?.global?.currency_symbol}{afterDiscountVal == 0 ? total_excl_tax.toFixed(2) : afterDiscountVal.toFixed(2)}</span></td>
                </tr>
                <tr>
                  <th className='bg-soft-purple'><span>Tax</span></th>
                  <td className='text-end'><span id="grand_total_tax">{postData?.global?.currency_symbol}{parseFloat(total_tax).toFixed(2)}</span></td>
                </tr>
                <tr>
                  <th className='bg-soft-purple'><span>Total (incl tax)</span></th>
                  <td className='text-end'><span id="grand_total_incl_tax">{postData?.global?.currency_symbol}{parseFloat((discounttype == "percent") ? parseFloat(total_tax + total_excl_tax - (discountvalue / 100 * total_excl_tax)).toFixed(2) : parseFloat(total_tax + total_excl_tax - discountvalue).toFixed(2)).toFixed(2)}</span></td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card>
      <Card>
        <div className='m-3 hstack gap-3'>
          <span className="badge badge-soft-success badge-border fs-14">PAYMENTS</span>
          {/* <Button size="sm" className='btn btn-primary' onClick={handleOpenPaymentForm}> Add Stripe Payment </Button> */}
          <Elements stripe={stripePromise}>
            <StripePayment button_text={"Add Stripe Payment"} show={showPaymentForm} balance={parseFloat(postData?.totalAmount - total_payments).toFixed(2)} total={postData?.totalAmount} onChangeData={onChangeData} invoice={postData.invoice} onClose={handleClosePaymentForm} email={crmcontactemail} />
          </Elements>
          <div className="form-check form-check-info ">
            <Input className="form-check-input" type="checkbox" id="formCheck11" defaultChecked={installmentPayment} onChange={() => UpdateInstallment()} />
            <Label className="form-check-label" for="formCheck11">
              <b>Allow Installment Payments</b>
            </Label>
          </div>
        </div>
        <div className='m-3'>
          <Table className='table-bordered mt-4'>
            <thead className='bg-soft-purple'>
              <tr>
                <th>Payment Method</th>
                <th>Reference / Notes</th>
                <th>Paid on</th>
                <th>Amount</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {paymentItems && paymentItems.map((res) => {
                total_payments = parseFloat(total_payments) + parseFloat(res.amount);
                // setTotalPayments(total_payments);
                return (<>{<tr key={res.id}>
                  <td>{res.gateway}</td>
                  <td>{res.remarks}</td>
                  <td>{moment(res.paid_on).format(org_date?.date_picker_format)}</td>
                  <td style={{ textAlign: 'right' }}>{postData?.global?.currency_symbol}{parseFloat(res.amount).toFixed(2)}</td>
                  <td>
                    <div className='hstack gap-2 '>
                      <Button className="btn btn-sm btn-soft-info edit-list" onClick={() => t_editPayment(res.id)}>
                        <i className="bx bxs-pencil fs-12 pt-1"></i>
                      </Button>
                      <Button
                        className=" btn btn-sm btn-soft-danger fs-13 pt-1" onClick={() => t_deletePayment(res)}>
                        <i className="bx bxs-trash fs-12"></i>
                      </Button>
                    </div>
                  </td>
                </tr>}
                  {(expandedpaymentId == res.id) && <tr>
                    <td>
                      <select className='form-control' value={res.gateway} onChange={(e) => {
                        const updateddata = [...data.payment_items];
                        const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                        updateddata[taskIndex].gateway = e.target.value;
                        setPaymentItems(updateddata);
                      }}>
                        <option></option>
                        {data.payment_methods.map((res) => {
                          return (<option key={res.id} value={res.name}>{res.name}</option>)
                        })}
                      </select>
                    </td>
                    <td>
                      <textarea className='form-control' value={res.remarks} onChange={(e) => {
                        const updateddata = [...data.payment_items];
                        const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                        updateddata[taskIndex].remarks = e.target.value;
                        setPaymentItems(updateddata);
                      }}></textarea>
                    </td>
                    <td>
                      <Flatpickr
                        className="form-control"
                        options={{
                          dateFormat: "d/m/Y",
                          defaultDate: [moment(res.paid_on).format("DD/MM/YYYY")]
                        }} onChange={(selectedDate) => {
                          const updateddata = [...data.payment_items];
                          const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                          updateddata[taskIndex].paid_on = moment(selectedDate[0]).format("DD/MM/YYYY");
                          setPaymentItems(updateddata);
                        }} />
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      <Input className='form-control' value={res.amount} onChange={(e) => {
                        console.log(parseFloat(e.target.value).toFixed(2));
                        const updateddata = [...data.payment_items];
                        const taskIndex = updateddata.findIndex((task) => task.id === res.id);
                        updateddata[taskIndex].amount = e.target.value;
                        console.log(updateddata[taskIndex]);
                        setPaymentItems(updateddata);
                      }} />
                    </td>
                    <td>
                      <div className='hstack gap-2'>
                        <button className="btn btn-sm btn-light edit-list" onClick={() => t_editPayment()}>
                          Cancel
                        </button>
                        <button className="btn btn-sm btn-success edit-list" onClick={() => UpdatePayment(res)}>
                          Updates
                        </button>
                      </div>
                    </td>
                  </tr>}
                </>)
              })
              }
              {data.payment_items && data.payment_items.length <= 0 && <tr>
                <td>No record available!</td>
              </tr>}
              {Addnewpayment &&
                <tr>
                  <td>
                    <select className='form-control' onChange={(e) => {
                      setPaymentMode(e.target.value);
                    }}>
                      <option></option>
                      {data.payment_methods.map((res) => {
                        return (<option key={res.id} value={res.name}>{res.name}</option>)
                      })}
                    </select>
                  </td>
                  <td>
                    <textarea className='form-control' onChange={(e) => {
                      setPaymentDesc(e.target.value)
                    }}></textarea>
                  </td>
                  <td>
                    <Flatpickr
                      className="form-control"
                      options={{
                        dateFormat: "d/m/Y",
                        defaultDate: [moment(new Date()).format("DD/MM/YYYY")]
                      }} onChange={(selectedDates) => {
                        setPaymentDate(moment(selectedDates[0]).format('DD/MM/YYYY'))
                      }} />
                  </td>
                  <td>
                    <Input className='form-control' onChange={(e) => {
                      setPaymentAmount(e.target.value);
                    }} />
                  </td>
                  <td>
                    <div className='hstack gap-2'>
                      <button className="btn btn-sm btn-light edit-list" onClick={() => t_addnewpayment()}>
                        Cancel
                      </button>
                      <button className="btn btn-sm btn-success edit-list" onClick={() => SavePayment()}>
                        Save
                      </button>
                    </div>
                  </td>
                </tr>}
            </tbody>
          </Table>
          <Button className="btn btn-brown" onClick={() => t_addnewpayment()} >+</Button>
        </div>

        <Row>
          <Col lg={6}></Col>
          <Col lg={6}>
            <Table className="table-bordered ">
              <tbody>
                <tr>
                  <th className='bg-soft-purple'><span>Payments</span></th>
                  <td className='text-end'><span id="grand_total_tax">{postData?.global?.currency_symbol}{parseFloat(total_payments).toFixed(2)}</span></td>
                </tr>
                <tr>
                  <th className='bg-soft-purple'><span>Balance</span></th>
                  <td className='text-end'><span id="grand_total_incl_tax">{postData?.global?.currency_symbol}{parseFloat(postData?.totalAmount - total_payments).toFixed(2)}</span></td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card>
      <Card>
        <div className='m-3'>
          <span className="badge badge-soft-success badge-border fs-14">ACTUAL HOURS</span>
        </div>
        <div className='m-3'>
          <Table className='table-bordered'>
            <thead className='bg-soft-purple'>
              <tr>
                <th>Leg #</th>
                <th>Leg Date</th>
                <th>Employee Travel Time in mins</th>
                <th>Job Start Time</th>
                <th>Job End Time</th>
                <th>Total Hours</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {joblegs && joblegs?.map((res) => {
                return (<> {(expandedjobLegid != res.id) && <tr key={res.id}>
                  <td>{res.leg_number}</td>
                  <td>{moment(res.leg_date).format("YYYY-MM-DD")}</td>
                  <td>{res.emp_travel_time}</td>
                  <td><span>{(res.actual_start_time != null) ? moment(res.actual_start_time, 'HH:mm:ss').format("hh:mm A") : "-"}</span>
                    <p>{res.actual_start_location}</p></td>
                  <td>
                    <span>{(res.actual_finish_time != null) ? moment(res.actual_finish_time, 'HH:mm:ss').format("hh:mm A") : "-"}</span>
                    <p>{res.actual_finish_location}</p>
                  </td>
                  <td>{calculateTimeDuration(res.actual_start_time, res.actual_finish_time).toFixed(2)}</td>
                  <td>
                    <div className='hstack gap-2'>
                      <Button className="btn btn-sm btn-soft-info edit-list" onClick={() => { setEditJobLeg(res.id) }}>
                        <i className="bx bxs-pencil fs-12 pt-1"></i>
                      </Button>
                    </div>
                  </td>
                </tr>}
                  {(expandedjobLegid == res.id) && <tr>
                    <td>{res.leg_number}</td>
                    <td>{moment(res.leg_date).format("YYYY-MM-DD")}</td>
                    <td>
                      <Input type='number' className='form-control' value={res.emp_travel_time} onChange={(e) => {
                        const updatedLegs = [...joblegs];
                        const taskIndex = updatedLegs.findIndex((task) => task.id === res.id);
                        updatedLegs[taskIndex].emp_travel_time = e.target.value;
                        setJobLegs(updatedLegs)
                      }} />
                    </td>
                    <td>
                      <Flatpickr
                        className="form-control"
                        options={{
                          enableTime: true,
                          noCalendar: true

                        }}
                        value={res.actual_start_time}
                        onChange={(selectedDates) => {
                          const updatedLegs = [...joblegs];
                          const taskIndex = updatedLegs.findIndex((task) => task.id === res.id);
                          updatedLegs[taskIndex].actual_start_time = moment(selectedDates[0]).format('HH:mm:ss');
                          setJobLegs(updatedLegs)
                        }}
                      />
                    </td>
                    <td>
                      <Flatpickr
                        className="form-control"
                        options={{
                          enableTime: true,
                          noCalendar: true
                        }} value={res.actual_finish_time}
                        onChange={(selectedDates) => {
                          const updatedLegs = [...joblegs];
                          const taskIndex = updatedLegs.findIndex((task) => task.id === res.id);
                          updatedLegs[taskIndex].actual_finish_time = moment(selectedDates[0]).format('HH:mm:ss');
                          setJobLegs(updatedLegs)
                        }} />
                    </td>
                    <td>{calculateTimeDuration(res.actual_start_time, res.actual_finish_time)}</td>
                    <td>
                      <div className='hstack gap-2'>
                        <button className="btn btn-sm btn-light edit-list" onClick={() => setEditJobLeg()}>
                          Cancel
                        </button>
                        <button className="btn btn-sm btn-success edit-list" onClick={() => UpdateJobLegs(res)}>
                          Update
                        </button>
                      </div>
                    </td>
                  </tr>}
                </>)
              })}
            </tbody>
          </Table>
          <Button className='btn btn-light' onClick={updateinvoicepdf}> <i className='bx bx-clipboard' /> Update & Generate Invoice</Button>
        </div>
      </Card>
      <Modal
        id="showModal"
        className="modal-dialog-edit"
        isOpen={modal}
        toggle={() => t_deleteItem()}
        centered
      >
        <ModalBody className="py-2 px-3">
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/wdqztrtx.json"
              trigger="loop"
              colors="primary:#912a4e,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                You will not be able to recover the deleted invoice item!
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              data-bs-dismiss="modal"
              onClick={() => t_deleteItem()}>
              Cancel
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={() => deleteInvoice()}
            >
              Ok
            </button>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        id="showModal"
        className="modal-dialog-edit"
        isOpen={paymentmodal}
        toggle={() => t_deletePayment}
        centered>
        <ModalBody className="py-2 px-3">
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/wdqztrtx.json"
              trigger="loop"
              colors="primary:#912a4e,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                You will not be able to recover the deleted payment item!
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              data-bs-dismiss="modal"
              onClick={() => t_deletePayment()}>
              Cancel
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={() => deletePayment()}
            >
              Ok
            </button>
          </div>
        </ModalBody>
      </Modal>
      <ToastContainer closeButton={false} limit={1} theme="light" />
    </div>
  </>
  )
}
